import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDocumentLabelStart, updateDocumentStart } from '../../../../store/redux-slices/superAdminSettings'
import { getSAUserDocumentStart, verifyUserDocumentStart } from '../../../../store/redux-slices/fetchData'
import { useParams } from 'react-router-dom'
import { getDocumentLabel } from '../../../../utils/apiCalls'

const useKYCSettings = ({ isTenant }) => {
  const dispatch = useDispatch()
  const { userId } = useParams()
  const [name, setName] = useState('')
  const [title, setTitle] = useState('')
  const [show, setShow] = useState(false)
  const [status, setStatus] = useState('')
  const [enable, setEnable] = useState(false)
  const [docLabels, setDocLabels] = useState('')
  const [labelName, setLabelName] = useState('')
  const [userDocumentId, setUserDocumentId] = useState('')
  const [showReasonModal, setShowReasonModal] = useState(false)
  const [imageUrl, setImageUrl] = useState({ name: '', preview: [] })
  const { userData, userDocuments } = useSelector((state) => state.fetch)
  const [imagePreviewModalShow, setImagePreviewModalShow] = useState(false)
  const { documentLabels } = useSelector(state => state.superAdminSettings)

  useEffect(() => {
    dispatch(getSAUserDocumentStart({ userId, isTenant }))
    !isTenant && dispatch(getDocumentLabelStart({ userId: parseInt(userId), isTenant }))
  }, [userId])

  useEffect(() => {
    if (!docLabels) {
      async function fetchData () {
        await getDocumentLabel('', isTenant).then((res) => {
          setDocLabels(res?.data?.data?.documentLabel)
        })
      }
      !isTenant && fetchData()
    }
  }, [])

  const handleYes = (reason, request, labelName) => {
    if (request === 'request' || request === 'cancel') {
      dispatch(updateDocumentStart({ data: { labelName, documentLabelId: userDocumentId, reason, userId: parseInt(userId), reRequested: true }, isRequested: (request === 'request'), isTenant }))
    } else {
      dispatch(verifyUserDocumentStart({ userId, data: { userDocumentId, reason, status, userId: parseInt(userId) }, isTenant }))
    }
    setShow(false)
    setShowReasonModal(false)
    setStatus('')
  }

  const handleVerify = (modalStatus, userDocumentId, myName) => {
    setStatus(modalStatus)
    setName(myName)
    setUserDocumentId(userDocumentId)
    if (modalStatus === 'approved') {
      setShow(true)
      setShowReasonModal(false)
    } else {
      setShow(false)
      setShowReasonModal(true)
    }
    setTitle('Rejecting')
  }

  const handleClose = () => {
    setShowReasonModal(false)
    setEnable(false)
  }

  const handleReRequest = (docId, myStatus, myName, myLabelName) => {
    setStatus('')
    setName(myName)
    setLabelName(myLabelName)
    if (myStatus === 'cancel') {
      setShow(true)
      setShowReasonModal(false)
    } else {
      setShow(false)
      setShowReasonModal(true)
    }
    setUserDocumentId(docId)
    setTitle('Re-Requesting')
  }

  const updateDocument = ({ documentLabelId, isRequested, labelName }) => {
    dispatch(updateDocumentStart({ data: { labelName, documentLabelId, userId: parseInt(userId) }, isRequested, isTenant }))
  }

  const handleImagePreview = (documentUrl, name) => {
    setImageUrl({ name, preview: documentUrl })
    setImagePreviewModalShow(true)
  }

  return {
    show,
    name,
    title,
    status,
    enable,
    setShow,
    imageUrl,
    docLabels,
    setEnable,
    handleYes,
    labelName,
    userDetails: userData,
    handleClose,
    setImageUrl,
    handleVerify,
    userDocuments,
    documentLabels,
    updateDocument,
    showReasonModal,
    handleReRequest,
    handleImagePreview,
    imagePreviewModalShow,
    setImagePreviewModalShow
  }
}

export default useKYCSettings
