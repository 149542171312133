import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllCurrenciesStart, getCurrencyByIdSuccess } from '../../../store/redux-slices/currencies'

const useCreateCurrencies = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [codeOptions, setCodeOptions] = useState()
  const [selectedCode, setSelectedCode] = useState()
  const { tenantDetails } = useSelector(state => state.login)
  const { allCurrencies, loading, currency } = useSelector((state) => state.currencies)

  useEffect(() => {
    if (allCurrencies?.rows) {
      const opt = []
      for (const curr of allCurrencies?.rows) {
        !tenantDetails?.tenantConfig?.allowedCurrencies?.includes(curr?.code) && opt.push({ label: curr?.code, value: curr?.code, id: curr?.currencyId })
      }
      setCodeOptions(opt)
    }
  }, [allCurrencies])

  useEffect(() => {
    !allCurrencies && dispatch(getAllCurrenciesStart({ limit: '', pageNo: '' }))

    return () => {
      dispatch(getCurrencyByIdSuccess(null))
    }
  }, [])

  return {
    loading,
    dispatch,
    currency,
    navigate,
    codeOptions,
    selectedCode,
    setCodeOptions,
    setSelectedCode
  }
}

export default useCreateCurrencies
