import React from 'react'
import {
  Navbar,
  Image,
  Col,
  Row,
  Dropdown
} from '@themesberg/react-bootstrap'
import './Header.css'
import useHeader from './hooks/useHeader'

const Header = () => {
  const {
    navigate,
    tenantsList,
    tenantDetails,
    tenantChangeHandler
  } = useHeader()

  return (
    <Row className='mb-2'>
      <Col>
        <Navbar.Brand>
          {tenantDetails?.tenantConfig.logoUrl &&
            <Image
              thumbnail
              alt=''
              src={tenantDetails.tenantConfig.logoUrl}
              className='d-inline-block align-top mx-2'
            />}
          {tenantDetails?.name}
        </Navbar.Brand>
      </Col>
      <Col className='align-self-right'>
        {tenantsList && tenantsList.rows && tenantDetails?.name &&
          <Dropdown className='d-inline'>
            <Dropdown.Toggle id='dropdown-autoclose-outside' className='text-wrap' variant='white'>
              <button
                size='sm'
                className={`tenant-circle-button ${tenantDetails?.isActive ? 'active-button' : 'inactive-button'}`}
              />&nbsp;{`${tenantDetails?.name} (${tenantDetails?.domain})`}
            </Dropdown.Toggle>

            <Dropdown.Menu className='user-dropdown tenant-dropdown'>
              {tenantsList.rows.map(({ name, tenantId, domain, isActive }) => {
                return (
                  <Dropdown.Item
                    key={name}
                    onClick={(e) => {
                      tenantChangeHandler(tenantId)
                      navigate(0)
                    }}
                  >
                    <button
                      size='sm'
                      className={`tenant-circle-button ${isActive ? 'active-button' : 'inactive-button'}`}
                    />&nbsp;{name}({domain})
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>}
      </Col>
    </Row>
  )
}

export default Header
