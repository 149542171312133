import React from 'react'
import { isTenant } from '../../../utils/constants'
import TABannerTable from './components/TABannerTable'
import SABannerTable from './components/SABannerTable'
import { Col, Row } from '@themesberg/react-bootstrap'
import ClientFilter from '../../../components/ClientFilter'
import PortalFilter from '../../../components/PortalFilter'
import useBannerManagement from './hooks/useBannerManagement'

export default () => {
  const {
    loading,
    isHidden,
    banners,
    selectedPortal,
    selectedClient,
    setSelectedClient,
    setSelectedPortal
  } = useBannerManagement()

  return (
    <>
      <Row>
        <Col>
          <h3>Banner Management</h3>
        </Col>

        {!isTenant && (
          <Col xs='auto'>
            <div className='d-flex justify-content-end align-items-center'>
              <ClientFilter
                setSelectedClient={setSelectedClient}
                setSelectedPortal={setSelectedPortal}
                selectedClient={selectedClient}
              />

              {selectedClient && (
                <PortalFilter
                  setSelectedPortal={setSelectedPortal}
                  selectedPortal={selectedPortal}
                  selectedClient={selectedClient}
                />
              )}
            </div>
          </Col>
        )}
      </Row>

      {isTenant
        ? (
          <TABannerTable
            item={banners?.banners}
            loading={loading}
            isHidden={isHidden}
          />
          )
        : (
          <SABannerTable
            banners={banners}
            loading={loading}
            isHidden={isHidden}
          />
          )}

      {banners?.length === 0 && !loading && (
        <div className='text-center text-danger mt-5'>No Data Found</div>
      )}
    </>
  )
}
