import React from 'react'
import { Row, Col, Button, Form, Tabs, Tab } from '@themesberg/react-bootstrap'
import { SpinnerLoader } from '../../../../../components/Preloader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo, faCloudArrowDown } from '@fortawesome/pro-solid-svg-icons'
import { DateRangePickerWithoutInput } from '../../../../../components/DateRangePicker'
import useKPIReport from '../../hooks/useKPIReport'
import { downloadFile } from '../../../../../utils/fileDownloader'
import KPIReportTable from '../../../../../components/KPIReportTable'
import DateOptionsFilter from '../../../../../components/DateOptionsFilter'

export default ({ selectedPortal, selectedClient, accordionState, isHidden }) => {
  const {
    hover,
    state,
    sortBy,
    setHover,
    loading,
    setState,
    setSortBy,
    selectedTab,
    dateOptions,
    loadKPIReport,
    setDateOptions,
    setSelectedTab,
    getCsvDownloadUrl,
    kpiReportData: KPIReport
  } = useKPIReport({ selectedPortal, selectedClient, accordionState })

  return (
    <>
      <Row className='border-bottom d-flex align-items-center justify-content-between mb-2 pb-2'>
        <Col>
          <button onClick={() => loadKPIReport()} className='btn btn-sm btn-secondary d-flex align-items-center gap-1'>
            <span className='fs-7'>Refresh</span>
            {loading ? <SpinnerLoader /> : <FontAwesomeIcon style={{ fontSize: '15px' }} icon={faRedo} />}
          </button>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center DateRangePickerWithoutInputWrapper'>
            {dateOptions === 'custom' &&
              <DateRangePickerWithoutInput
                state={state} setState={setState}
              />}

            <Form.Label style={{ marginTop: '5px', marginRight: '10px' }}>
              Date Options
            </Form.Label>
            <DateOptionsFilter dateOptions={dateOptions} setDateOptions={setDateOptions} />

            <Button
              variant='success'
              size='sm'
              style={{ width: '150px' }}
              disabled={!KPIReport || Object.keys(KPIReport) < 1}
              hidden={isHidden({ module: { key: 'KpiReport', value: 'DR' } })}
              onClick={() => { downloadFile(getCsvDownloadUrl()) }}
            >
              Export&nbsp;<FontAwesomeIcon icon={faCloudArrowDown} />
            </Button>

          </div>
        </Col>

      </Row>

      <Tabs
        activeKey={selectedTab}
        onSelect={(tab) => setSelectedTab(tab)}
        className='nav-light dashboard'
      >
        <Tab eventKey='game' title='GAME'>
          <div className='mt-2'>
            <Row className='mt-3 d-flex flex-row-reverse text-right'>
              <KPIReportTable
                sortBy={sortBy}
                setSortBy={setSortBy}
                hover={hover}
                setHover={setHover}
                KPIReport={KPIReport || []}
              />
            </Row>
          </div>
        </Tab>

        <Tab eventKey='provider' title='PROVIDER'>
          <div className='mt-2'>
            <Row className='mt-3 d-flex flex-row-reverse text-right'>
              <KPIReportTable
                sortBy={sortBy}
                setSortBy={setSortBy}
                hover={hover}
                setHover={setHover}
                KPIReport={KPIReport || []}
              />
            </Row>
          </div>
        </Tab>
      </Tabs>
    </>

  )
}
