import { Button, Modal, Row, Col } from '@themesberg/react-bootstrap'
import React from 'react'
import { getFieldsByAggregator } from './TransactionsListConstant'

const TransactionsListInfoModal = ({
  showModal,
  selectedtransactionBankingId,
  transactionsMoreDetails,
  closeModal
}) => {
  return (
    <Modal show={showModal} onHide={closeModal} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title className='d-flex'>
          Transaction Details Report (Transaction Id: {selectedtransactionBankingId})
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className='infoModalMatchLabels'>
        {transactionsMoreDetails &&
          getFieldsByAggregator(transactionsMoreDetails.aggregator).map(
            ({ key, label }) =>
              key in transactionsMoreDetails && transactionsMoreDetails[key] && (
                <Row key={key}>
                  <Col xs={3}>
                    <h6>{label}</h6>
                  </Col>
                  <Col>
                    <span>{transactionsMoreDetails[key]}</span>
                  </Col>
                </Row>
              )
          )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-warning' onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default TransactionsListInfoModal
