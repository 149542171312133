import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTenantStart } from '../../../store/redux-slices/tenants'
import { checkIsTenant } from '../../../utils/constants'
import { updateKYCTriggerSettingStart } from '../../../store/redux-slices/admins'

const useKYCTrigger = () => {
  const dispatch = useDispatch()
  const [setting, setSetting] = useState('')
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const { tenantDetails } = useSelector((state) => state.login)
  const { updateKYCTriggerSettingLoading } = useSelector((state) => state.admins)
  const { tenantDetails: SATenantDetails } = useSelector((state) => state.tenants)

  useEffect(() => {
    if (selectedPortal && !checkIsTenant()) dispatch(getTenantStart({ tenantId: selectedPortal }))
  }, [selectedPortal])

  useEffect(() => {
    const details = checkIsTenant() ? tenantDetails : SATenantDetails
    if (details) {
      const kycSettings = details?.tenantConfig?.kycSettings?.trigger
      setSetting(typeof kycSettings === 'object' ? 'after_withdrawal' : kycSettings || '')
    }
  }, [tenantDetails, SATenantDetails])

  const updateSettings = ({ data }) => {
    dispatch(updateKYCTriggerSettingStart({ data }))
  }

  return {
    setting,
    setSetting,
    updateSettings,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal,
    loading: updateKYCTriggerSettingLoading,
    tenantDetails: checkIsTenant() ? tenantDetails : SATenantDetails
  }
}

export default useKYCTrigger
