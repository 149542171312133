import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setEmailCredsStart, updateProfileStart } from '../../store/redux-slices/login'
import { getSuperAdminDetails, getTenantOwnerDetails } from '../../utils/apiCalls'

const useProfilePage = (isTenant) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState('overview')
  const [editable, setEditable] = useState(false)
  const [editableCreds, setEditableCreds] = useState(false)
  const [details, setDetails] = useState()
  const { loading, resetPassLoading } = useSelector(state => state.login)
  const { adminDetails } = useSelector(state => state.admins)

  const updateData = (data) => {
    dispatch(updateProfileStart({
      data,
      isTenant
    }))
    setEditable(false)
  }

  const updateCredentials = ({ data }) => {
    dispatch(setEmailCredsStart({
      data: {
        sendgridKey: Buffer.from(data.sendgridKey).toString('base64'),
        sendgridEmail: Buffer.from(data.sendgridEmail).toString('base64')
      }
    }))
    setEditableCreds(false)
  }

  async function fetchData () {
    isTenant
      ? (await getTenantOwnerDetails().then((res) => {
          setDetails(res?.data?.data?.adminDetails)
        }))
      : (await getSuperAdminDetails().then((res) => {
          setDetails(res?.data?.data?.adminDetails)
        }))
  }

  useEffect(() => {
    fetchData()
  }, [])

  return {
    details,
    selectedTab,
    setSelectedTab,
    navigate,
    editable,
    setEditable,
    updateData,
    loading,
    editableCreds,
    updateCredentials,
    adminDetails,
    setEditableCreds,
    resetPassLoading,
    fetchData
  }
}

export default useProfilePage
