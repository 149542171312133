import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getActionType, isTenant } from '../../../../utils/constants'
import { getCMSLanguagesStart } from '../../../../store/redux-slices/languages'
import { getAllSABannersStart, updateSABannerStart } from '../../../../store/redux-slices/superAdminSettings'

const useEditBanners = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [type, setType] = useState('')
  const [isHovering, setIsHovering] = useState(false)
  const { bannerKey, adminId, tenantId } = useParams()
  const [key, setKey] = useState(bannerKey, '')
  const { tenantDetails } = useSelector(state => state.login)
  const [selectedBannerTab, setSelectedBannerTab] = useState(0)
  const { tenantLanguages } = useSelector(state => state.languages)
  const [selectedLanguageTab, setSelectedLanguageTab] = useState('EN')
  const [transformedBanner, setTransformedBanner] = useState([])
  const [selectedClient, setSelectedClient] = useState(isTenant ? tenantDetails?.adminUserId : adminId || '')
  const [selectedPortal, setSelectedPortal] = useState(isTenant ? tenantDetails?.tenantId : tenantId || '')
  const { loading: SALoading, SABanners } = useSelector(state => state.superAdminSettings)

  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
  }

  useEffect(() => {
    !isTenant && dispatch(getCMSLanguagesStart({ adminId: selectedClient, tenantId: selectedPortal }))
  }, [selectedClient, selectedPortal])

  useEffect(() => {
    setSelectedLanguageTab('EN')
  }, [selectedPortal])

  useEffect(() => {
    setType(getActionType())
  }, [])

  useEffect(() => {
    (isTenant
      ? tenantDetails?.tenantId
      : true) && dispatch(getAllSABannersStart({
        adminId: !selectedClient ? '' : selectedClient,
        tenantId: isTenant ? tenantDetails?.tenantId : selectedPortal
      }))
  }, [selectedPortal, selectedClient, tenantDetails])

  const createUpdate = (data) => {
    dispatch(updateSABannerStart({
      data: { ...data, banner: { [key]: transformArray(data.banner?.[key]), tenantId: selectedPortal } },
      adminId: !selectedClient ? '' : selectedClient,
      tenantId: isTenant ? tenantDetails?.tenantId : selectedPortal
    }))
  }

  const transformJSON = (originalObject) => {
    const transformedObject = {}

    for (const sectionKey in originalObject) {
      transformedObject[sectionKey] = {
        banners: []
      }

      originalObject[sectionKey][sectionKey].forEach((banner, index) => {
        const transformedBanner = {}

        for (const lang in banner) {
          transformedBanner[lang] = banner[lang]
        }

        const bannerImageObj = originalObject[sectionKey]?.bannerImage?.find(obj => Object.keys(obj)[0].includes(`[${sectionKey}][${index}][bannerImage]`))
        const bannerBackgroundObj = originalObject[sectionKey]?.bannerImage?.find(obj => Object.keys(obj)[0].includes(`[${sectionKey}][${index}][bannerBackground]`))

        transformedBanner.image = bannerImageObj ? Object.values(bannerImageObj)[0] : null
        transformedBanner.background = bannerBackgroundObj ? Object.values(bannerBackgroundObj)[0] : null

        transformedObject[sectionKey].banners.push(transformedBanner)
      })
    }

    return transformedObject
  }

  const transformArray = (originalArray) => {
    const transformedArray = []

    originalArray.forEach(item => {
      const transformedItem = {
        bannerImage: ![null, undefined].includes(item.bannerImage) ? item.bannerImage : item.image,
        bannerBackground: ![null, undefined].includes(item.bannerBackground) ? item.bannerBackground : item.background
      }

      // Add other keys
      for (const itemKey in item) {
        if (!['image', 'background', 'bannerBackground', 'bannerImage'].includes(itemKey) && item[itemKey]) {
          transformedItem[itemKey] = item[itemKey]
        }
      }

      transformedArray.push(transformedItem)
    })

    return transformedArray
  }

  const banners = useMemo(() => {
    if (isTenant) return SABanners?.banners
    else {
      if (SABanners?.[0]?.banners) {
        if (typeof SABanners?.[0]?.banners === 'string') return JSON.parse(SABanners?.[0]?.banners)
        else return SABanners?.[0]?.banners
      } else return ''
    }
  }, [SABanners])

  useEffect(() => {
    banners && Object.keys(banners)?.length && setTransformedBanner(isTenant ? banners?.[key]?.banners : transformJSON(banners)?.[key]?.banners)
  }, [key, banners])

  return {
    key,
    type,
    setKey,
    navigate,
    isHovering,
    createUpdate,
    handleMouseOut,
    selectedClient,
    selectedPortal,
    handleMouseOver,
    selectedBannerTab,
    setSelectedClient,
    setSelectedPortal,
    transformedBanner,
    loading: SALoading,
    selectedLanguageTab,
    setSelectedBannerTab,
    setSelectedLanguageTab,
    tenantLanguages: isTenant ? tenantDetails?.tenantConfig?.allowedLanguages : tenantLanguages
  }
}

export default useEditBanners
