import React from 'react'
import {
  Button,
  Form,
  Row,
  Col,
  Table,
  ButtonGroup,
  Breadcrumb
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckSquare,
  faWindowClose,
  faEdit,
  faEye
} from '@fortawesome/pro-solid-svg-icons'

import PaginationComponent from '../../../components/Pagination'
import ConfirmationModal from '../../../components/ConfirmationModal'
import useTenantListing from './hooks/useTenantListing'
import Trigger from '../../../components/OverlayTrigger'
import useCheckPermission from '../../../utils/checkPermission'

export default () => {
  const {
    totalPages,
    show,
    limit,
    page,
    allTenantList,
    handleYes,
    handleShow,
    navigate,
    setLimit,
    setPage,
    setSearch,
    search,
    setShow,
    adminId,
    active,
    loading,
    name,
    status,
    setStatus
  } = useTenantListing()
  const { isHidden } = useCheckPermission()

  return (
    <>

      <Row>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate('/super-admin/tenants/owners')}>Tenant Owner</Breadcrumb.Item>
          <Breadcrumb.Item active>Tenants</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row>
        <Col>
          <h3>Tenants</h3>
        </Col>
        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center'>
            <Form.Label style={{ marginRight: '15px', marginTop: '8px' }}>
              Status
            </Form.Label>

            <Form.Select
              onChange={(e) => { setStatus(e.target.value) }}
              value={status}
              size='sm'
              style={{ maxWidth: '230px', width: 'auto', marginRight: '10px' }}
            >
              <option value=''>All</option>
              <option value='true'>Active</option>
              <option value='false'>In-Active</option>
            </Form.Select>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              Search
            </Form.Label>

            <Form.Control
              type='search'
              placeholder='Name or Domain'
              value={search}
              size='sm'
              style={{ maxWidth: '230px', marginRight: '10px' }}
              onChange={(e) => setSearch(e.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
            />

            <Button
              variant='outline-success'
              size='sm'
              onClick={() => navigate(`/super-admin/tenants/create/${adminId}`)}
              hidden={isHidden({ module: { key: 'Tenant', value: 'C' } })}
            >
              Create
            </Button>
          </div>
        </Col>
      </Row>
      {/* </div> */}

      {/* Button to create new tenant */}

      {/* Table with tenants info */}
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {['Id', 'Name', 'Domain', 'Status', 'Action'].map((c) => (
              <th key={c}>{c}</th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {(Boolean(allTenantList) && !loading) &&
                allTenantList?.rows?.map(
                  ({
                    tenantId,
                    name: tenantName,
                    domain: tenantDomain,
                    isActive
                  }) => {
                    return (
                      <tr key={tenantId}>
                        <td>{tenantId}</td>

                        <td>
                          <Trigger message={tenantName}>
                            <span
                              style={{
                                width: '100px',
                                cursor: 'pointer'
                              }}
                              className='text-link d-inline-block text-truncate'
                              onClick={() =>
                                navigate(
                                  `/super-admin/tenants/details/${adminId}/${tenantId}`
                                )}
                            >
                              {tenantName}
                            </span>
                          </Trigger>
                        </td>

                        <td>
                          <span
                            onClick={() => window.open(tenantDomain)}
                            className='text-link'
                            style={{ cursor: 'pointer' }}
                          >
                            {tenantDomain}
                          </span>
                        </td>

                        <td>
                          {isActive
                            ? (
                              <span className='text-success'>Active</span>
                              )
                            : (
                              <span className='text-danger'>In Active</span>
                              )}
                        </td>
                        <td>
                          <ButtonGroup>
                            <Trigger message='Edit'>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='warning'
                                onClick={() =>
                                  navigate(`/super-admin/tenants/edit/${adminId}/${tenantId}`)}
                                hidden={isHidden({ module: { key: 'Tenant', value: 'U' } })}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                            </Trigger>

                            <Trigger message='View Details'>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='info'
                                onClick={() =>
                                  navigate(
                                    `/super-admin/tenants/details/${adminId}/${tenantId}`
                                  )}
                                hidden={isHidden({ module: { key: 'Tenant', value: 'R' } })}
                              >
                                <FontAwesomeIcon icon={faEye} />
                              </Button>
                            </Trigger>
                            {!isActive
                              ? (
                                <Trigger message='Set Status Active'>
                                  <Button
                                    className='m-1'
                                    size='sm'
                                    variant='success'
                                    onClick={() => handleShow(tenantId, isActive, `${tenantName} (${tenantDomain})`)}
                                    hidden={isHidden({ module: { key: 'Tenant', value: 'T' } })}
                                  >
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </Button>
                                </Trigger>
                                )
                              : (
                                <Trigger message='Set Status In-Active'>
                                  <Button
                                    className='m-1'
                                    size='sm'
                                    variant='danger'
                                    onClick={() => handleShow(tenantId, isActive, `${tenantName} (${tenantDomain})`)}
                                    hidden={isHidden({ module: { key: 'Tenant', value: 'T' } })}
                                  >
                                    <FontAwesomeIcon icon={faWindowClose} />
                                  </Button>
                                </Trigger>
                                )}
                          </ButtonGroup>
                        </td>
                      </tr>
                    )
                  }
                )}

          {allTenantList?.count === 0 && !loading && (
            <tr>
              <td colSpan={5} className='text-danger text-center'>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {allTenantList?.count !== 0 && !loading && (
        <PaginationComponent
          page={allTenantList?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}

      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={active}
          name={name}
        />
      )}
    </>
  )
}
