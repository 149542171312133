import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getCredByKeyStart } from '../../../../store/redux-slices/tenantcredentials'

const useEditCredentials = () => {
  const dispatch = useDispatch()
  const { credKey } = useParams()

  useEffect(() => {
    dispatch(getCredByKeyStart({ credKey }))
  }, [])

  const { credData, loading } = useSelector((state) => state.tenantcredentials)

  return {
    credData,
    loading,
    credKey
  }
}

export default useEditCredentials
