import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllThemesStart } from '../../../../store/redux-slices/tenantcredentials'

const useTenantThemeSettings = () => {
  const dispatch = useDispatch()
  const { tenantDetails } = useSelector((state) => state.login)
  const { allThemesData, loading } = useSelector((state) => state.tenantcredentials)

  const [preview, setPreview] = useState({
    image_preview: tenantDetails?.tenantConfig?.logoUrl,
    image_file: tenantDetails?.tenantConfig?.logoUrl
  })

  useEffect(() => {
    if (tenantDetails) {
      setPreview({
        image_preview: tenantDetails?.tenantConfig?.logoUrl,
        image_file: tenantDetails?.tenantConfig?.logoUrl
      })
    }
  }, [tenantDetails])

  const themeData = allThemesData?.rows
  const tenantTheme = themeData?.filter(
    (t) => t?.themeId === tenantDetails?.themeSettings?.themeId
  )
  const [theme, setTheme] = useState(tenantTheme?.[0])

  const [customTheme, setCustomTheme] = useState({
    isCustom: false,
    primaryColor: '#105AFB',
    secondaryColor: '#FC6065',
    themeMode: 'Light'
  })
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState('')

  const [req, setReq] = useState(false)

  useEffect(() => {
    dispatch(getAllThemesStart())
  }, [])

  const setThemeData = (theme) => {
    setTheme(theme)
    setCustomTheme({ ...customTheme, isCustom: false })
  }

  const handleImagePreview = (e) => {
    const imageAsBase64 = URL.createObjectURL(e.target.files[0])
    const imageAsFiles = e.target.files[0]
    const imageSize = imageAsFiles?.size / 1024 / 1024
    if (imageSize > 1) {
      setIsError(true)
      setError('File Size Should be Less Than 1MB')
      return
    }
    if (!['image/png', 'image/jpeg', 'image/jpg'].includes(imageAsFiles.type)) {
      setIsError(true)
      setError('Uploaded file has unsupported format')
      return
    }
    setPreview({
      image_preview: imageAsBase64,
      image_file: imageAsFiles
    })
    setIsError(false)

    setReq(false)
  }

  return {
    dispatch,
    tenantDetails,
    customTheme,
    setCustomTheme,
    preview,
    setPreview,
    theme,
    themeData,
    req,
    error,
    isError,
    setThemeData,
    handleImagePreview,
    loading
  }
}

export default useTenantThemeSettings
