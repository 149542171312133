import React from 'react'
import useCasinoTransactionsList from './hooks/useCasinoTransactionsList'
import { Button, Col, Row, Form } from '@themesberg/react-bootstrap'
import DateRangePicker from '../../../components/DateRangePicker'
import { getDateDaysAgo } from '../../../utils/dateFormatter'
import { transactionType, statusType } from './constants'
import CasinoTransactionsList from '../../../components/CasinoTransactionList'
import ClientFilter from '../../../components/ClientFilter'
import PortalFilter from '../../../components/PortalFilter'
import Trigger from '../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt, faFileDownload } from '@fortawesome/pro-solid-svg-icons'
import { handleDownloadCSV } from '../../../utils/fileDownloader'
import { AmountTypeFilter } from '../../../components/FIlters'
import LabelData from '../UserDetails/components/LabelData'
import { CASINO_SUMMED_AMOUNT_DESC } from '../../../utils/constants'
import useCheckPermission from '../../../utils/checkPermission'

const CasinoTransactions = () => {
  const {
    allCurrencies,
    setSelectedCurrency,
    setSearch,
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    setSelectedAction,
    selectedCurrency,
    selectedAction,
    search,
    state,
    setState,
    casinoTransactions,
    loading,
    status,
    setStatus,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal,
    getCsvDownloadUrl,
    sortBy,
    setSortBy,
    over,
    setOver,
    amountType,
    setAmountType,
    gameSearch,
    setGameSearch,
    allProviders,
    setProviders,
    providers,
    setShowTotalAmount,
    showTotalAmount
  } = useCasinoTransactionsList()

  const { isHidden } = useCheckPermission()

  return (
    <>
      <Row>
        <Col>
          <h3>Casino Transactions</h3>
        </Col>

        <Col>
          <div className='d-flex justify-content-end align-items-center'>
            <ClientFilter
              setSelectedClient={setSelectedClient}
              setSelectedPortal={setSelectedPortal}
              selectedClient={selectedClient}
              hasAllOptions
            />

            {selectedClient && (
              <PortalFilter
                setSelectedPortal={setSelectedPortal}
                selectedPortal={selectedPortal}
                selectedClient={selectedClient}
                hasAllOptions
              />
            )}
          </div>
        </Col>
      </Row>

      <Row className='mt-2'>

        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Currency
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            onChange={(e) => {
              if (e.target.value === '') setShowTotalAmount(false)
              setSelectedCurrency(e.target.value)
            }}
            value={selectedCurrency}
          >
            <option value=''>Select a Currency</option>
            {allCurrencies && allCurrencies?.rows?.map(
              ({ name: currName, currencyId, code }) => (
                <option key={currencyId} value={code}>
                  {currName}
                </option>
              )
            )}
          </Form.Select>
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Action Type
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            onChange={(e) => {
              if (!(['all', 'bet', 'win'].includes(e.target.value))) setShowTotalAmount(false)
              setSelectedAction(e.target.value)
            }}
            value={selectedAction}
          >
            {transactionType && transactionType?.map(
              ({ label, value }) => (
                <option key={label} value={value}>
                  {label}
                </option>
              )
            )}
          </Form.Select>
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <AmountTypeFilter amountType={amountType} setAmountType={setAmountType} />
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Search By UserId
          </Form.Label>

          <Form.Control
            type='number'
            placeholder='Search UserId'
            size='sm'
            style={{ width: '230px', marginRight: '10px', maxHeight: '15px' }}
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Search Game
          </Form.Label>

          <Form.Control
            placeholder='Search Game'
            size='sm'
            style={{ width: '230px', marginRight: '10px', maxHeight: '15px' }}
            value={gameSearch}
            onChange={(event) => setGameSearch(event.target.value)}
          />
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Providers
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '230px' }}
            onChange={(e) => setProviders(e.target.value)}
            value={providers}
          >
            <option value=''>Select Provider</option>
            {allProviders?.rows?.map(
              ({ name, masterCasinoProviderId }) => (
                <option key={name} value={masterCasinoProviderId}>
                  {name}
                </option>
              )
            )}
          </Form.Select>
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Status
          </Form.Label>

          <Form.Select
            onChange={(e) => setStatus(e.target.value)}
            value={status}
            size='sm'
            style={{ maxWidth: '230px' }}
          >
            {statusType && statusType?.map(
              ({ label, value }) => (
                <option key={label} value={value}>
                  {label}
                </option>
              )
            )}
          </Form.Select>
        </Col>

        <Col style={{ maxHeight: '25px' }} xs='auto' className='d-flex mb-3 align-items-center'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Time Period
          </Form.Label>
          <DateRangePicker
            state={state} setState={setState}
          />
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <Trigger message='Reset Filters'>
            <Button
              variant='outline-success'
              size='sm'
              onClick={() => {
                setSearch('')
                setSelectedAction('all')
                setSelectedCurrency('')
                setSelectedClient('')
                setLimit(15)
                setPage(1)
                setAmountType('')
                setProviders('')
                setState([
                  {
                    startDate: getDateDaysAgo(10),
                    endDate: new Date(),
                    key: 'selection'
                  }
                ])
                setStatus('all')
                setSortBy({
                  bonusAmount: 'desc',
                  cashAmount: 'desc',
                  Id: 'desc',
                  isBonusSorting: 2
                })
                setOver({
                  bonusAmount: false,
                  cashAmount: false,
                  Id: false
                })
                setShowTotalAmount(false)
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>

        <Col xs='auto' className='d-flex mb-3'>
          <Trigger message='Download as CSV'>
            <Button
              variant='outline-success'
              size='sm'
              hidden={isHidden({ module: { key: 'Transactions', value: 'DR' } })}
              disabled={casinoTransactions?.count === 0}
              onClick={() => {
                handleDownloadCSV(getCsvDownloadUrl(), 'Casino Transactions')
              }}
            >
              <FontAwesomeIcon icon={faFileDownload} />
            </Button>
          </Trigger>
        </Col>

        <Col style={{ maxHeight: '25px' }} xs='auto' className='d-flex mb-3 align-items-center'>
          <Form.Label style={{ paddingRight: '10px', marginBottom: '0' }}>
            Show Total Amount
          </Form.Label>
          <Form.Switch
            name='totalAmount'
            checked={showTotalAmount}
            onChange={() => setShowTotalAmount(!showTotalAmount)}
            disabled={(!(selectedCurrency && ['all', 'bet', 'win'].includes(selectedAction)))}
          />
          <LabelData
            inActive
            value=''
            desc={CASINO_SUMMED_AMOUNT_DESC}
          />
        </Col>

      </Row>
      <CasinoTransactionsList
        page={page}
        setLimit={setLimit}
        limit={limit}
        setPage={setPage}
        totalPages={totalPages}
        transactions={casinoTransactions}
        isTenant={false}
        loading={loading}
        sortBy={sortBy}
        setSortBy={setSortBy}
        over={over}
        setOver={setOver}
        showTotalAmount={showTotalAmount}
      />
    </>
  )
}

export default CasinoTransactions
