import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useDidMountEffect } from '../../../../utils/useDidMountEffect'
import { getWageringTemplateDetailsStart, updateWageringTemplateStart } from '../../../../store/redux-slices/bonus'
import { getAllMasterGamesStart } from '../../../../store/redux-slices/tenantCasino'

const useEditWageringTemplate = () => {
  const { wageringTemplateDetail } = useSelector((state) => state.bonus)
  const { loading, masterGames: casinoGamesData } = useSelector(state => state.tenantCasino)
  const { wageringTemplateId } = useParams()
  const [selectedProvider, setSelectedProvider] = useState('')
  const [gameContribution, setGameContribution] = useState({ })
  const [customValue, setCustomValue] = useState()
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [contributionDefaultValue, setContributionDefaultValue] = useState({})
  const isInitialRender = useDidMountEffect()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)

  const totalPages = Math.ceil(casinoGamesData?.count / limit)

  const customValueHandler = (value) => {
    setCustomValue(value)
    const newGameContribution = {}
    for (const key in gameContribution) {
      newGameContribution[key] = value
    }
    setGameContribution(newGameContribution)
  }

  const formSubmitHandler = (formValue) => {
    const templateData = {
      name: formValue.name,
      gameContribution: { ...contributionDefaultValue, ...gameContribution },
      wageringTemplateId: Number(wageringTemplateId)
    }
    dispatch(updateWageringTemplateStart({ isTenant: true, templateData, navigate }))
  }
  const fetchDetails = () => {
    dispatch(getWageringTemplateDetailsStart({
      isTenant: true,
      wageringTemplateId,
      limit: 15,
      pageNo: 1,
      providerId: selectedProvider
    }))
  }

  useEffect(() => {
    dispatch(getAllMasterGamesStart({
      limit,
      pageNo: page,
      search,
      casinoCategoryId: '',
      providerId: selectedProvider
    }))
  }, [limit, page, selectedProvider])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(
            getAllMasterGamesStart({
              limit,
              pageNo: page,
              search,
              casinoCategoryId: '',
              providerId: selectedProvider
            })
          )
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    fetchDetails()
  }, [selectedProvider])

  useEffect(() => {
    if (wageringTemplateDetail) {
      setContributionDefaultValue(wageringTemplateDetail?.gameContribution)
    }
  }, [wageringTemplateDetail])

  return {
    loading,
    navigate,
    selectedProvider,
    setSelectedProvider,
    gameContribution,
    casinoGamesData,
    setGameContribution,
    customValue,
    customValueHandler,
    wageringTemplateDetail,
    formSubmitHandler,
    search,
    setSearch,
    setContributionDefaultValue,
    contributionDefaultValue,
    page,
    limit,
    setLimit,
    setPage,
    totalPages
  }
}

export default useEditWageringTemplate
