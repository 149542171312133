
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isTenant } from '../../../utils/constants'
import { getAmlThresholdDataStart, setAmlThresholdDataStart } from '../../../store/redux-slices/amlModule'

export const useSetAmlThresholds = () => {
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const dispatch = useDispatch()
  const { thresholdData } = useSelector(state => state.amlModule)

  const amlForms = [
    {
      textLabel: 'Set Accumulated Deposits Amount',
      name: 'totalTransactionAmount',
      placeholder: 'Enter the Amount',
      value: 'values.totalTransactionAmount',
      disabled: (isTenant ? false : !selectedPortal),
      time: false
    },
    {
      textLabel: 'Single Deposit Amount',
      name: 'singleTransactionAmount',
      placeholder: 'Enter the Amount',
      value: 'values.singleTransactionAmount',
      disabled: (isTenant ? false : !selectedPortal),
      time: false
    },
    {
      textLabel: 'Rolling Deposit Amount',
      name: 'rollingPeriodTransactionAmount',
      placeholder: 'Enter the Amount',
      value: 'values.rollingPeriodTransactionAmount',
      disabled: (isTenant ? false : !selectedPortal),
      time: true,
      timePlaceHolder: 'Enter the Days',
      periodName: 'rollingPeriod',
      dayLabel: 'Rolling Period (in days)'
    }
  ]

  const [wagerDate, setWagerDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const [winDate, setWinDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  const setThresholdHandler = (formValues) => {
    const data = {
      rollingPeriod: formValues.rollingPeriod,
      totalTransactionAmount: formValues.totalTransactionAmount,
      singleTransactionAmount: formValues.singleTransactionAmount,
      rollingPeriodTransactionAmount: formValues.rollingPeriodTransactionAmount,
      tenantId: selectedPortal
    }
    if (isTenant || selectedPortal) dispatch(setAmlThresholdDataStart({ isTenant, data }))
  }

  useEffect(() => {
    (isTenant || selectedPortal) && dispatch(getAmlThresholdDataStart({ isTenant, tenantId: selectedPortal }))
  }, [selectedPortal])

  return {
    wagerDate,
    setWagerDate,
    winDate,
    setWinDate,
    setThresholdHandler,
    setSelectedClient,
    setSelectedPortal,
    selectedClient,
    selectedPortal,
    thresholdData,
    amlForms
  }
}
