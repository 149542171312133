import React from 'react'
import {
  Row,
  Col,
  Table,
  Form
} from '@themesberg/react-bootstrap'
import PaginationComponent from '../../../components/Pagination'
import Trigger from '../../../components/OverlayTrigger'
import useCategoryListing from './useCategoryListing'
import { formatDate } from '../../../utils/dateFormatter'

export default () => {
  const {
    limit,
    setLimit,
    page,
    setPage,
    loading,
    gameCategory,
    totalPages,
    search,
    setSearch
  } = useCategoryListing()

  return (
    <>
      <Row>
        <Col sm={7}>
          <h3>Master Casino Categories</h3>
        </Col>

        <Col>
          <div className='d-flex justify-content-end align-items-center w-100'>
            <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
              Search
            </Form.Label>

            <Form.Control
              type='search'
              value={search}
              placeholder='Search Name'
              size='sm'
              style={{ maxWidth: '230px' }}
              onChange={(event) => setSearch(event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
            />
          </div>
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {[
              'Id',
              'Name',
              'Created At',
              'Updated At'
            ].map((header, idx) => (
              <th key={idx}>{header}</th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && gameCategory &&
                      gameCategory?.rows?.map(
                        (
                          { masterGameCategoryId, name, createdAt, updatedAt }
                        ) => {
                          return (
                            <tr key={masterGameCategoryId}>
                              <td>{masterGameCategoryId}</td>
                              <td>
                                <Trigger message={name?.EN}>
                                  <span
                                    style={{
                                      width: '100px',
                                      cursor: 'pointer'
                                    }}
                                    className='d-inline-block text-truncate'
                                  >
                                    {name?.EN}
                                  </span>
                                </Trigger>
                              </td>
                              <td>{formatDate(createdAt)}</td>
                              <td>{formatDate(updatedAt)}</td>
                            </tr>
                          )
                        }
                      )}

          {gameCategory?.count === 0 && !loading &&
                      (
                        <tr>
                          <td
                            colSpan={4}
                            className='text-danger text-center'
                          >
                            No data found
                          </td>
                        </tr>
                      )}
        </tbody>
      </Table>

      {gameCategory?.count !== 0 && !loading &&
              (
                <PaginationComponent
                  page={gameCategory?.count < page ? setPage(1) : page}
                  totalPages={totalPages}
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                />
              )}
    </>
  )
}
