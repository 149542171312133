import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { editTenantStart } from '../../../../store/redux-slices/tenants'

const useTenant = ({ createTenantData }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { tenantId } = useParams()
  const { loading } = useSelector((state) => state.currencies)
  const { tenantDetails } = useSelector((state) => state.tenants)
  const { languages } = useSelector(state => state.languages)

  const editTenant = ({
    createTenantData,
    val,
    tenantId
  }) => dispatch(
    editTenantStart({
      createTenantData,
      navigate,
      val,
      tenantId
    })
  )

  return {
    tenantId,
    navigate,
    loading,
    tenantDetails,
    languages,
    editTenant
  }
}

export default useTenant
