import { Button, Col, Form, Row, Table } from '@themesberg/react-bootstrap'
import React, { useState } from 'react'
import PrepaidsModal from '../../pages/Super-Admin/Bonus/components/CreateBonus/PrepaidsModal'
import Trigger from '../OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { checkIsTenant } from '../../utils/constants'

const GamesTable = ({
  search,
  setSearch,
  gameIds,
  availableGames,
  // handleSelectAll,
  loading,
  // handleSelect,
  getData,
  setGameIds,
  isHub88 = false,
  prepaidsData,
  setPrepaidsData,
  userData,
  tenantId,
  selectedAggregator,
  setSelectedAggregator,
  resetForm = null,
  aggregators,
  tenantDetails,
  bonus = false,
  selectedPortal = '',
  setFirstCall = ''
}) => {
  const [show, setShow] = useState('')
  const [data, setData] = useState('')
  const [gameId, setGameId] = useState('')

  const selectHandler = (e, masterCasinoGameId) => {
    if (gameIds?.includes('' + masterCasinoGameId) || gameIds?.includes(masterCasinoGameId)) {
      const newObj = []
      for (const ids in gameIds) {
        if (isHub88 ? gameIds[ids] !== masterCasinoGameId : parseInt(gameIds[ids]) !== masterCasinoGameId) {
          newObj.push(gameIds[ids])
        }
      }
      setGameIds(newObj)
      if (isHub88) {
        setPrepaidsData(prepaidsData?.filter(({ game_code }) => game_code !== masterCasinoGameId))
      }
    } else {
      const newObj = []
      for (const ids in gameIds) {
        newObj.push(gameIds[ids])
      }
      newObj.push(masterCasinoGameId)
      setGameIds(newObj)
    }
  }

  return (
    <>
      <Row>
        <Table striped={false}>
          <tbody>
            <tr className='d-flex flex-wrap'>
              <td className='border-0 align-content-center'>Select Aggregator</td>
              <td className='border-0'>
                <Form.Select
                  size='sm'
                  name='aggregator'
                  className='mb-0'
                  value={selectedAggregator}
                  onChange={(e) => {
                    setSelectedAggregator(e.target.value)
                    setGameIds([])
                    resetForm && resetForm()
                    setFirstCall && setFirstCall(true)
                  }}
                >
                  <option value='' selected disabled>---Select---</option>
                  {aggregators?.count > 0 && aggregators?.rows?.map(({ name, masterGameAggregatorId }) =>
                    (tenantDetails?.tenantConfig?.aggregatorIds?.includes(String(masterGameAggregatorId)) || tenantDetails?.tenantConfig?.aggregatorIds?.includes(masterGameAggregatorId)) &&
                      <option value={name} key={name}>{name}</option>
                  )}
                </Form.Select>
              </td>

              {(bonus ? (selectedPortal || checkIsTenant()) : true) && selectedAggregator &&
                <>
                  <td className='border-0 align-content-center'>Search Game</td>
                  <td className='border-0'>
                    <Form.Control
                      type='search'
                      name='search'
                      placeholder='Search Game'
                      size='sm'
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </td>
                </>}
            </tr>
          </tbody>
        </Table>
      </Row>

      <Row>
        {(bonus ? (selectedPortal || checkIsTenant()) : true) && selectedAggregator &&
          <div className='d-flex mt-2 flex-wrap' style={{ overflow: 'none' }}>
            <div style={{ overflow: 'auto', maxHeight: '400px', marginRight: '10px' }}>
              <Col xs={8} md={4} className='mt-3'>
                <Table size='sm' className='text-center'>
                  <thead>
                    <tr className='thead-dark'>
                      <th>Game Id</th>
                      <th>Game Name</th>
                    </tr>
                  </thead>

                  <tbody>
                    {loading &&
                      <tr>
                        <td />
                        <td id='inline-spinner' />
                        <td />
                      </tr>}
                    {availableGames?.length > 0 && !loading &&
                availableGames?.map(
                  ({
                    masterCasinoGameId,
                    name,
                    identifier
                  }) => {
                    return (
                      <tr key={`games-list ${masterCasinoGameId}`}>
                        <td>
                          <input
                            className='wageering-template-checkbox'
                            type='checkbox'
                            defaultChecked={isHub88 ? gameIds?.includes(identifier) : (gameIds?.includes(masterCasinoGameId) || gameIds?.includes(String(masterCasinoGameId)))}
                            checked={isHub88 ? gameIds?.includes(identifier) : (gameIds?.includes(masterCasinoGameId) || gameIds?.includes(String(masterCasinoGameId)))}
                            name={masterCasinoGameId}
                            onChange={(e) => { selectHandler(e, isHub88 ? identifier : masterCasinoGameId) }}
                          />
                          {masterCasinoGameId}
                        </td>
                        <td>{name}
                        </td>
                      </tr>
                    )
                  }
                )}
                    {availableGames?.count === 0 && !loading && (
                      <tr>
                        <td colSpan={2} className='text-danger text-center'>
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </div>

            {!isHub88 &&
              <Col xs={8} md={4} className='mt-3'>
                <Table size='sm' className='text-center'>
                  <thead>
                    <tr className='thead-dark'>
                      <th>Selected Games</th>
                    </tr>
                  </thead>
                  <tbody>
                    {gameIds?.length > 0 &&
                gameIds?.map(
                  (gameId) => {
                    return (
                      <tr key={`games-list ${gameId}`}>
                        <td>{getData('name', gameId)} ( GameId: {getData('id', gameId)} )</td>
                      </tr>
                    )
                  }
                )}
                    {gameIds?.length === 0 && (
                      <tr>
                        <td colSpan={1} className='text-danger text-center'>
                          No Games Selected
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>}

            {isHub88 &&
              <Col xs={8} md={4} className='mt-3'>
                <Table bordered striped responsive hover size='sm' className='text-center scrollable'>
                  <thead className='thead-dark'>
                    <tr>
                      <th>Game Id &nbsp;
                        <Trigger message='Please select prepaids also for the selected games'>
                          <Button variant='outlined-info' size='sm'>
                            <FontAwesomeIcon icon={faInfoCircle} color='white' />
                          </Button>
                        </Trigger>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {gameIds && gameIds.length > 0
                      ? gameIds.map((id, idx) => {
                        return (
                          <tr key={`wagering-template ${id}`}>
                            <td
                              className={`${!prepaidsData?.filter(({ game_code }) => game_code === id)?.length ? 'cursor-pointer text-blue' : ''}`}
                            >
                              <a
                                href={() => false}
                                onClick={e => {
                                  e.stopPropagation()
                                  setData(id)
                                  setGameId(availableGames?.filter(({ identifier }) => identifier === id)?.[0]?.masterCasinoGameId)
                                  !prepaidsData?.filter(({ game_code }) => game_code === id)?.length && setShow(true)
                                }}
                              >{id}
                              </a>
                            </td>

                          </tr>
                        )
                      })
                      : (
                        <tr>
                          <td colSpan={5} className='text-danger text-center'>
                            No data found
                          </td>
                        </tr>
                        )}

                  </tbody>
                </Table>
              </Col>}
          </div>}

      </Row>

      {show &&
        <PrepaidsModal
          show={show}
          data={data}
          gameId={gameId}
          setData={setData}
          setShow={setShow}
          prepaidsData={prepaidsData}
          setPrepaidsData={setPrepaidsData}
          userData={userData}
          tenantId={tenantId}
        />}
    </>
  )
}

export default GamesTable
