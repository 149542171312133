import React from 'react'
import { Row, Col, Form } from '@themesberg/react-bootstrap'
import ReactPaginate from 'react-paginate'

const PaginationComponent = ({ page, totalPages, setPage, limit, setLimit, totalCount }) => {
  const OPTIONS_ARR = [15, 20, 50, 100]
  return (
    <Row className='mt-3 pagination align-items-center'>
      <Col xs={4}>
        <div style={{ alignItems: 'center', width: '100%', textAlign: 'left' }}>
          <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
            Per Page
          </Form.Label>

          <Form.Select
            size='sm'
            style={{ maxWidth: '80px' }}
            value={limit}
            onChange={(event) => {
              setLimit(event.target.value)
              setPage(1)
            }}
          >
            {OPTIONS_ARR.map((o) => (
              <option key={o} value={o}>
                {o}
              </option>
            ))}
          </Form.Select>
        </div>
      </Col>

      <Col className='d-flex'>
        <ReactPaginate
          breakLabel='...'
          nextLabel='>'
          onPageChange={(e) => setPage(e.selected + 1)}
          pageRangeDisplayed={3}
          pageCount={totalPages}
          previousLabel='<'
          forcePage={page - 1}
          activeClassName='active'
          renderOnZeroPageCount={null}
        />
      </Col>
      {totalCount &&
        <Col xs={4}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
            <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
              Total Count:
            </Form.Label>
            <Form.Control
              type='text'
              size='sm'
              style={{ width: '5rem', maxHeight: '15px', borderRadius: '30px', textAlign: 'center', marginRight: '1rem' }}
              value={totalCount}
              disabled
            />
          </div>
        </Col>}
    </Row>
  )
}

export default PaginationComponent
