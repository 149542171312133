import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getAllSuperAdminTransactionsStart,
    getAllSuperAdminTransactionsSuccess,
    getAllSuperAdminTransactionsFailure,
    getSuperAdminCasinoTransactionsStart,
    getSuperAdminCasinoTransactionsSuccess,
    getSuperAdminCasinoTransactionsFailure,
    updateSuperadminTransactionStatusStart,
    updateSuperadminTransactionStatusSuccess,
    updateSuperadminTransactionStatusFailure,
    getSuperAdminAllTransactionsStart,
    getSuperAdminAllTransactionsSuccess,
    getSuperAdminAllTransactionsFailure,
    getBannedGamesDetailsStart,
    getBannedGamesDetailsSuccess,
    getBannedGamesDetailsFailure,
    getAllBannedGamesStart,
    getAllBannedGamesSuccess,
    getAllBannedGamesFailure,
    createBannedGamesStart,
    createBannedGamesSuccess,
    createBannedGamesFailure,
    getBannedGamesListDetailStart,
    getBannedGamesListDetailSuccess,
    getBannedGamesListDetailFailure,
    updateBannedGamesStart,
    updateBannedGamesSuccess,
    updateBannedGamesFailure,
    removeBannedGamesStart,
    removeBannedGamesSuccess,
    removeBannedGamesFailure,
    setRemoveBannedGames,
    setRemoveBannedGameList,
    setBannedGamesName,
    auditLogsStart,
    auditLogsSuccess,
    auditLogsFailure,
    userSessionLogsStart,
    userSessionLogsSuccess,
    userSessionLogsFailure,
    bannedGamesTogglerStart,
    bannedGamesTogglerSuccess,
    bannedGamesTogglerFailure,
    setOwnerBannedGames,
    getBannedAffiliatesStart,
    getBannedAffiliatesSuccess,
    getBannedAffiliatesFailure,
    getBannedExcludedAffiliatesStart,
    getBannedExcludedAffiliatesSuccess,
    getBannedExcludedAffiliatesFailure,
    updateBannedGamesAffiliatesStart,
    updateBannedGamesAffiliatesSuccess,
    updateBannedGamesAffiliatesFailure,
    updateBannedGamesMaxBetAllowed,
    updateBannedAffiliates
  },
  reducer
} = createSlice({
  name: 'transactions',
  initialState: {
    loading: false,
    transactions: {},
    casinoTransactions: {},
    updateStatus: {},
    allTransactions: {},
    bannedGames: {},
    bannedAffiliates: [],
    bannedExcludedAffiliates: [],
    maxBetAllowed: 0,
    createBannedGames: {},
    getBannedGamesListDetail: {},
    removeBannedGames: [],
    removedBannedGamesList: [],
    listName: '',
    auditList: {},
    activeListId: 1, // default,
    userSessionLogsLoading: false,
    userSessionLogs: {}
  },
  reducers: {
    getAllSuperAdminTransactionsStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getAllSuperAdminTransactionsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      transactions: payload
    }),
    getAllSuperAdminTransactionsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getSuperAdminAllTransactionsStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getSuperAdminAllTransactionsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      allTransactions: payload
    }),
    getSuperAdminAllTransactionsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getSuperAdminCasinoTransactionsStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getSuperAdminCasinoTransactionsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      casinoTransactions: payload
    }),
    getSuperAdminCasinoTransactionsFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateSuperadminTransactionStatusStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    updateSuperadminTransactionStatusSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      updateStatus: payload
    }),
    updateSuperadminTransactionStatusFailure: (state) => ({
      ...state,
      loading: false
    }),
    getBannedGamesDetailsStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getBannedGamesDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      bannedGames: payload
    }),
    getBannedGamesDetailsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAllBannedGamesStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getAllBannedGamesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      bannedGames: payload,
      activeListId: payload.tenantBannedGameId
    }),
    getAllBannedGamesFailure: (state) => ({
      ...state,
      loading: false
    }),
    setOwnerBannedGames: (state) => ({
      ...state,
      loading: false,
      bannedGames: { count: 0, rows: [], description: 'Please Select Appropriate Tenant' }
    }),
    createBannedGamesStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    createBannedGamesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      createBannedGames: payload
    }),
    createBannedGamesFailure: (state) => ({
      ...state,
      loading: false
    }),
    getBannedGamesListDetailStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getBannedGamesListDetailSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      getBannedGamesListDetail: payload
    }),
    setBannedGamesName: (state, { payload }) => ({
      ...state,
      listName: payload
    }),
    setRemoveBannedGames: (state, { payload }) => ({
      ...state,
      removeBannedGames: payload
    }),
    setRemoveBannedGameList: (state, { payload }) => ({
      ...state,
      removedBannedGamesList: payload
    }),
    getBannedGamesListDetailFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateBannedGamesStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    updateBannedGamesSuccess: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    updateBannedGamesFailure: (state) => ({
      ...state,
      loading: false
    }),
    removeBannedGamesStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    removeBannedGamesSuccess: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    removeBannedGamesFailure: (state) => ({
      ...state,
      loading: false
    }),
    auditLogsStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    auditLogsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      auditList: payload
    }),
    auditLogsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    userSessionLogsStart: (state) => ({
      ...state,
      userSessionLogsLoading: true,
      error: null
    }),
    userSessionLogsSuccess: (state, { payload }) => ({
      ...state,
      userSessionLogsLoading: false,
      userSessionLogs: payload
    }),
    userSessionLogsFailure: (state, { payload }) => ({
      ...state,
      userSessionLogsLoading: false,
      error: payload
    }),
    bannedGamesTogglerStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    bannedGamesTogglerSuccess: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    bannedGamesTogglerFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getBannedAffiliatesStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getBannedAffiliatesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      bannedAffiliates: payload.affiliates,
      maxBetAllowed: payload.maxBetAllowed
    }),
    getBannedAffiliatesFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getBannedExcludedAffiliatesStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getBannedExcludedAffiliatesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      bannedExcludedAffiliates: payload
    }),
    getBannedExcludedAffiliatesFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    updateBannedGamesAffiliatesStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    updateBannedGamesAffiliatesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      bannedAffiliates: payload
    }),
    updateBannedGamesAffiliatesFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    updateBannedGamesMaxBetAllowed: (state, { payload }) => ({
      ...state,
      maxBetAllowed: payload
    }),
    updateBannedAffiliates: (state, { payload }) => ({
      ...state,
      bannedAffiliates: payload
    })
  }
})

export default reducer

export {
  getAllSuperAdminTransactionsStart,
  getAllSuperAdminTransactionsSuccess,
  getAllSuperAdminTransactionsFailure,
  getSuperAdminCasinoTransactionsStart,
  getSuperAdminCasinoTransactionsSuccess,
  getSuperAdminCasinoTransactionsFailure,
  updateSuperadminTransactionStatusStart,
  updateSuperadminTransactionStatusSuccess,
  updateSuperadminTransactionStatusFailure,
  getSuperAdminAllTransactionsStart,
  getSuperAdminAllTransactionsSuccess,
  getSuperAdminAllTransactionsFailure,
  getBannedGamesDetailsStart,
  getBannedGamesDetailsSuccess,
  getBannedGamesDetailsFailure,
  getAllBannedGamesStart,
  getAllBannedGamesSuccess,
  getAllBannedGamesFailure,
  createBannedGamesStart,
  createBannedGamesSuccess,
  createBannedGamesFailure,
  getBannedGamesListDetailStart,
  getBannedGamesListDetailSuccess,
  getBannedGamesListDetailFailure,
  updateBannedGamesStart,
  updateBannedGamesSuccess,
  updateBannedGamesFailure,
  removeBannedGamesStart,
  removeBannedGamesSuccess,
  removeBannedGamesFailure,
  setRemoveBannedGames,
  setRemoveBannedGameList,
  setBannedGamesName,
  auditLogsStart,
  auditLogsSuccess,
  auditLogsFailure,
  userSessionLogsStart,
  userSessionLogsSuccess,
  userSessionLogsFailure,
  bannedGamesTogglerStart,
  bannedGamesTogglerSuccess,
  bannedGamesTogglerFailure,
  setOwnerBannedGames,
  getBannedAffiliatesStart,
  getBannedAffiliatesSuccess,
  getBannedAffiliatesFailure,
  getBannedExcludedAffiliatesStart,
  getBannedExcludedAffiliatesSuccess,
  getBannedExcludedAffiliatesFailure,
  updateBannedGamesAffiliatesStart,
  updateBannedGamesAffiliatesSuccess,
  updateBannedGamesAffiliatesFailure,
  updateBannedGamesMaxBetAllowed,
  updateBannedAffiliates
}
