import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminUserDetailsStart } from '../../../../store/redux-slices/adminUser'

const useAdminDetails = () => {
  const [selectedTab, setSelectedTab] = useState('overview')
  const navigate = useNavigate()
  const { adminUserDetails, loading } = useSelector((state) => state.adminUser)

  const { adminUserId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAdminUserDetailsStart({ adminUserId, isTenant: true }))
  }, [])

  return {
    selectedTab,
    setSelectedTab,
    adminUserDetails,
    loading,
    adminUserId,
    navigate
  }
}

export default useAdminDetails
