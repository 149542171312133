import React from 'react'
import { affiliateList } from '../constants'

const Affiliate = ({ userData }) => {
  const affiliate = userData?.affiliate
  return (
    <>
      <h6 className='h4-overview text-center mt-1'>Affiliate Details <hr className='h4-hr mt-1' /></h6>

      {affiliateList.map(({ label, value }) =>
        affiliate?.[value] && (
          <div className='d-flex justify-content-between m-1' key={value}>
            <h6 style={{ fontSize: '14px' }} className='px-2'>{label}</h6>
            <span style={{ fontSize: '14px' }} className='px-2'>{affiliate?.[value]}</span>
          </div>
        )
      )}

      {affiliate?.status && (
        <div className='d-flex justify-content-between m-1'>
          <h6 style={{ fontSize: '14px' }} className='px-2'>Status</h6>
          <span style={{ fontSize: '14px' }} className='px-2 text-capitalize'>{affiliate.status}</span>
        </div>
      )}
    </>
  )
}

export default Affiliate
