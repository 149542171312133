export const tableHeaders = [
  'Document Id',
  'Name',
  'Document Preview',
  'Reason',
  'Updated At',
  'Actionee',
  'Action Performed At',
  'Status',
  'Action'
]
