import React, { useRef } from 'react'
import { ErrorMessage } from 'formik'
import { faX } from '@fortawesome/pro-duotone-svg-icons'
import { Button, Form } from '@themesberg/react-bootstrap'
import Trigger from '../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FileComponent = ({
  type,
  index,
  values,
  errors,
  fieldName,
  handleBlur,
  setFieldValue,
  pseudoFieldName
}) => {
  const fileInputRef = useRef([])
  return (
    <>
      <Form.Label>
        Banner {pseudoFieldName === 'image' ? 'Image' : 'Background'}&nbsp;&nbsp;
        {(values?.banners?.[index]?.[fieldName] || values?.banners?.[index]?.[pseudoFieldName]) && (
          <Trigger message='Clear Image'>
            <Button
              size='sm'
              variant='outline-danger'
              className='text-danger'
              hidden={type === 'view'}
              onClick={() => {
                setFieldValue(`banners[${index}][${fieldName}]`, null)
                setFieldValue(`banners[${index}][${pseudoFieldName}]`, null)
                if (fileInputRef?.current?.[index + fieldName]) {
                  fileInputRef.current[index + fieldName].value = null // Resetting the value of file input
                }
              }}
            >
              <FontAwesomeIcon icon={faX} />
            </Button>
          </Trigger>
        )}
      </Form.Label>
      <Form.Control
        type='file'
        name={`banners[${index}][${fieldName}]`}
        ref={el => { fileInputRef.current[index + fieldName] = el }}
        disabled={type === 'view'}
        style={{ minWidth: '220px' }}
        onBlur={handleBlur}
        onChange={(event) => {
          setFieldValue(`banners[${index}][${fieldName}]`, event.target.files[0])
        }}
      />
      <ErrorMessage
        name={`banners[${index}][${fieldName}]`}
        component='div'
        className='text-danger'
      />
      {!errors?.banners?.[index]?.[fieldName] &&
        (values?.banners?.[index]?.[fieldName] &&
        typeof values?.banners?.[index]?.[fieldName] !== 'string' &&
        URL.createObjectURL(values?.banners?.[index]?.[fieldName])
          ? (
            <img
              src={URL.createObjectURL(values?.banners?.[index]?.[fieldName])}
              width='230'
              height='230'
              className='mt-2 border-0'
              alt='banner-img'
            />
            )
          : (
              values?.banners?.[index]?.[pseudoFieldName] && (
                <img
                  src={
                process.env.REACT_APP_S3_IMAGE_HEADER +
                values?.banners?.[index]?.[pseudoFieldName]
              }
                  width='230'
                  height='230'
                  className='mt-2 border-0'
                  alt='banner-img'
                />
              )
            ))}
    </>
  )
}

export default React.memo(FileComponent)
