import React from 'react'
import {
  Card,
  Col,
  Row,
  Form as BForm,
  Button,
  Spinner
} from '@themesberg/react-bootstrap'
import { Form, Formik } from 'formik'
import useAffiliateForm from '../hooks/useAffiliateForm'
import { faRedo } from '@fortawesome/pro-solid-svg-icons'
import Trigger from '../../../../components/OverlayTrigger'
import { removeEmptyData } from '../../../../utils/helpers'
import { InlineLoader } from '../../../../components/Preloader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DateRangePicker from '../../../../components/DateRangePicker'

const AffiliateForm = ({
  state,
  userData,
  setState,
  isHidden,
  isTenant = false
}) => {
  const {
    showData,
    oldKeyData,
    getOldData,
    getNewData,
    updateData,
    affiliateOldData,
    affiliateNewData,
    affiliateConstant,
    affiliateNewLoading,
    affiliateOldLoading
  } = useAffiliateForm({ isHidden, isTenant, state })

  return (
    <Row>
      <Col>
        <Card className='card-overview'>
          <h6 className='h4-overview text-center mt-1'>
            Affiliate Data
            <Trigger message='Refetch Data'>
              <Button
                variant='success'
                className='mx-2'
                size='sm'
                onClick={() => {
                  getOldData()
                }}
              >
                <FontAwesomeIcon icon={faRedo} />
              </Button>
            </Trigger>
            <hr className='h4-hr mt-1' />
          </h6>

          <div className='div-overview'>
            <div className='d-flex align-items-center'>
              <BForm.Label className='mx-2'>Select Date</BForm.Label>
              <DateRangePicker
                width='auto'
                state={state}
                setState={setState}
                size='md'
              />
            </div>
            {affiliateOldLoading
              ? (
                <InlineLoader />
                )
              : (

                <div className='mt-3'>
                  {Object.keys(affiliateConstant)?.map((key, index) => (
                    <div
                      key={index}
                      className='d-flex justify-content-between m-1'
                    >
                      <h6 style={{ fontSize: '14px' }}>{key}</h6>
                      <div>
                        <span style={{ fontSize: '14px' }}>
                          {affiliateOldData?.[affiliateConstant[key]] || '0'}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                )}
          </div>
        </Card>
      </Col>

      {showData && (
        <Col>
          <Card className='card-overview'>
            <h6 className='h4-overview text-center mt-1'>
              Affiliate Updated Data
              <Trigger message='Refetch Data'>
                <Button
                  variant='success'
                  className='mx-2'
                  size='sm'
                  onClick={() => {
                    getNewData()
                  }}
                >
                  <FontAwesomeIcon icon={faRedo} />
                </Button>
              </Trigger>
              <hr className='h4-hr mt-1' />
            </h6>
            <div className='div-overview'>
              {affiliateNewLoading
                ? (
                  <InlineLoader />
                  )
                : (
                  <Formik
                    enableReinitialize
                    initialValues={{
                      ...oldKeyData,
                      ...affiliateNewData?.affiliateData,
                      isChecked: affiliateNewData?.isChecked
                    }}
                    onSubmit={(formValues) => {
                      formValues.userId = userData?.userId
                      formValues.tenantId = userData?.tenantId
                      updateData(removeEmptyData(formValues))
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      resetForm,
                      setFieldValue
                    }) => (
                      <Form>
                        <div className='d-flex'>
                          <h6 style={{ fontSize: '14px' }} className='mx-3'>
                            Send Data To MyAffiliates
                          </h6>
                          <BForm.Check
                            name='isChecked'
                            type='checkbox'
                            checked={values?.isChecked}
                            onChange={() =>
                              setFieldValue('isChecked', !values?.isChecked)}
                          />
                        </div>
                        <div className='d-flex flex-wrap align-items-center mt-3'>
                          {Object.keys(affiliateConstant)?.map(
                            (key, index) => (
                              <div key={index} className='m-2'>
                                <h6 style={{ fontSize: '14px' }}>{key}</h6>
                                <BForm.Control
                                  type='number'
                                  placeholder='Enter Value'
                                  name={affiliateConstant[key]}
                                  value={values?.[affiliateConstant[key]]}
                                  onChange={handleChange}
                                />
                              </div>
                            )
                          )}
                        </div>
                        <div className='d-flex justify-content-between'>
                          <Button
                            className='m-2'
                            variant='outline-warning'
                            onClick={() => {
                              resetForm({})
                            }}
                          >
                            Reset
                          </Button>
                          <Button
                            className='m-2'
                            variant='outline-success'
                            onClick={handleSubmit}
                          >
                            Submit
                            {affiliateNewLoading && (
                              <Spinner
                                as='span'
                                animation='border'
                                size='sm'
                                role='status'
                                aria-hidden='true'
                              />
                            )}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  )}
            </div>
          </Card>
        </Col>
      )}
    </Row>
  )
}

export default React.memo(AffiliateForm)
