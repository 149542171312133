import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { formatDateYMD } from '../../../../utils/dateFormatter'
import { getPlayerGameReportStart } from '../../../../store/redux-slices/dashboard'
import { getLoginToken } from '../../../../utils/storageUtils'
import { useParams } from 'react-router'

const useGameReport = ({
  isTenant,
  limit,
  state,
  selectedTab,
  dateOptions
}) => {
  const dispatch = useDispatch()
  const { userId } = useParams()
  const { gameReportLoadingPlayer: loading, gameReportPlayer } = useSelector((state) => state.dashboard)

  const totalPages = Math.ceil(gameReportPlayer?.count / limit)

  const fetchDetails = () => {
    dispatch(getPlayerGameReportStart({
      isTenant,
      startDate: formatDateYMD(state.map(a => a.startDate)),
      endDate: formatDateYMD(state.map(a => a.endDate)),
      dateOptions,
      selectedTab,
      userId,
      limit
    }))
  }

  useEffect(() => {
    fetchDetails()
  }, [state, selectedTab, limit])

  useEffect(() => {
    if (dateOptions !== 'custom') fetchDetails()
  }, [dateOptions])

  const getCsvDownloadUrl = () =>
    `${process.env.REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/report/game?userId=${userId}&limit=${limit}&dateOptions=${dateOptions}&customStartDate=${formatDateYMD(state.map(a => a.startDate))}&customEndDate=${formatDateYMD(state.map(a => a.endDate))}&tab=${selectedTab}&csvDownload=true&token=${getLoginToken()}`

  return {
    loading,
    totalPages,
    fetchDetails,
    gameReportPlayer,
    getCsvDownloadUrl
  }
}

export default useGameReport
