import React, { useCallback, useEffect, useState } from 'react'
import {
  Row,
  Col,
  InputGroup,
  Form as BForm,
  Button
} from '@themesberg/react-bootstrap'
import { ErrorMessage } from 'formik'
import Select from 'react-select'
import { safeStringify } from '../../../utils/helpers'
import { getTenantLanguagesStart } from '../../../store/redux-slices/bonus'
import ActionTypeSelect from '../../ActionTypeSelect'
import './Tournament.scss'
import { getDateTimeDaysAfter } from '../../../utils/dateFormatter'
import { tourneyMechanics } from './constants'
import Fields from './Fields'
import ReactQuillField from './ReactQuillField'

const General = ({
  isTenant,
  values,
  dispatch,
  touched,
  errors,
  data,
  setData,
  handleChange,
  handleBlur,
  type,
  setFieldValue,
  clientsFilterData,
  portalOptions,
  setSelectedClient,
  tournamentDetails,
  preview,
  handleImagePreview,
  allCurrencies,
  countries,
  handleSubmit,
  navigate,
  languages,
  allowedCurr = '',
  setValues,
  initialValues,
  setSubmitting
}) => {
  const [err, setErr] = useState('')
  const [errDesc, setErrDesc] = useState('')

  const currData = isTenant ? allowedCurr : allCurrencies?.rows

  useEffect(() => {
    !isTenant && values?.tenantIds?.length &&
      dispatch(
        getTenantLanguagesStart({
          isTenant,
          tenantId: safeStringify(values?.tenantIds?.map((id) => id.value))
        })
      )
  }, [values.tenantIds])

  useEffect(() => {
    if (initialValues) {
      setValues(initialValues)
      setSubmitting(false)
    }
  }, [initialValues])

  const GamesSelect = useCallback(() => {
    return (
      <ActionTypeSelect
        selectedAction={safeStringify(JSON.parse(values.games).reduce((result, currentValue) => {
          result.push({ label: currentValue, value: currentValue })
          return result
        }, []))}
        selectOptions={JSON.parse(values.games).reduce((result, currentValue) => {
          result.push({ label: currentValue, value: currentValue })
          return result
        }, [])}
        placeholder='Games'
        fieldName='games'
        setFieldValue={setFieldValue}
        customWidth='100%'
        disableOptions
        values={values.games}
      />
    )
  }, [values?.games])

  const CountriesSelect = useCallback(() => {
    return (
      <ActionTypeSelect
        selectedAction={safeStringify(
          countries?.reduce((result, currentValue) => {
            JSON.parse(values.countries)?.includes(currentValue?.code) &&
              result.push({
                label: currentValue?.name?.EN,
                value: currentValue?.code
              })
            return result
          }, [])
        )}
        selectOptions={countries?.reduce((result, currentValue) => {
          result.push({
            label: currentValue?.name?.EN,
            value: currentValue?.code
          })
          return result
        }, [])}
        placeholder='Countries'
        fieldName='countries'
        setFieldValue={setFieldValue}
        customWidth='100%'
        disableOptions
      />
    )
  }, [values?.countries])

  return (
    <>
      <Row className=' mt-3 mb-3'>
        {/* Title */}
        <Fields
          handleBlur={handleBlur}
          handleChange={handleChange}
          values={values}
          errors={errors}
          touched={touched}
          label='Title'
          fieldName='title'
          type='text'
          placeholder='Enter Title'
          disabled={type === 'view'}
        />

        {/* Owner and Tenant */}
        {!isTenant && (
          <Col style={{ minWidth: '220px' }} className='mb-3'>
            <label
              htmlFor='adminId'
              className={touched.adminId && errors.adminId ? 'text-danger' : ''}
            >
              Owner<span className='text-danger'> *</span>
            </label>
            <InputGroup
              className={
                touched.adminId && errors.adminId ? 'border border-danger' : ''
              }
            >
              <BForm.Select
                disabled={['edit', 'view'].includes(type)}
                name='adminId'
                value={values.adminId}
                onChange={(e) => {
                  handleChange(e)
                  setSelectedClient(e.target.value)
                }}
              >
                <option value='' disabled key=''>
                  Select Owner
                </option>
                {clientsFilterData?.rows?.map((admin) => (
                  <option value={admin.adminUserId} key={admin.adminUserId}>
                    {`${admin.email} (${admin?.firstName} ${admin?.lastName})`}
                  </option>
                ))}
              </BForm.Select>
            </InputGroup>
            <ErrorMessage
              component='div'
              name='adminId'
              className='text-danger'
            />
          </Col>
        )}

        <Col style={{ minWidth: '220px' }} className='mb-3'>
          <label
            htmlFor='tenantIds'
            className={
              touched.tenantIds && errors.tenantIds ? 'text-danger' : ''
            }
          >
            {['edit', 'clone'].includes(type) ? 'Portal' : 'Portals'}
            <span className='text-danger'> *</span>
          </label>
          <Select
            isDisabled={['edit', 'view'].includes(type)}
            isMulti
            isClearable={false}
            name='tenantIds'
            value={values.tenantIds}
            options={portalOptions}
            className={
              'basic-multi-select' && touched.tenantIds && errors.tenantIds
                ? 'border border-danger'
                : ''
            }
            classNamePrefix='select'
            onChange={(option, e) => {
              setFieldValue('tenantIds', option)
            }}
          />
          <ErrorMessage
            component='div'
            name='tenantIds'
            className='text-danger'
          />
        </Col>
      </Row>

      <Row className='tournaments'>
        {/* Currencies */}
        <Fields
          wrapper
          touched={touched}
          errors={errors}
          fieldName='currencies'
          label='Currencies'
          wrapperElement={() => {
            return (
              currData?.length > 0 && (
                <ActionTypeSelect
                  selectedAction={safeStringify(
                    currData.reduce((result, currentValue) => {
                      JSON.parse(values.currencies).includes(currentValue?.code || currentValue) &&
                          result.push({
                            label: isTenant ? currentValue : currentValue?.name,
                            value: isTenant ? currentValue : currentValue?.code
                          })
                      return result
                    }, [])
                  )}
                  selectOptions={currData.reduce(
                    (result, currentValue) => {
                      result.push({
                        label: isTenant ? currentValue : currentValue?.name,
                        value: isTenant ? currentValue : currentValue?.code
                      })
                      return result
                    },
                    []
                  )}
                  placeholder='Currencies'
                  fieldName='currencies'
                  setFieldValue={setFieldValue}
                  customWidth='100%'
                />
              )
            )
          }}
        />

        {/* Games */}
        <Fields
          wrapper
          touched={touched}
          errors={errors}
          fieldName='games'
          label='Games'
          wrapperElement={() => {
            return (values.games?.length > 0 &&
              <GamesSelect />
            )
          }}
        />

        {/* Countries */}
        <Fields
          wrapper
          touched={touched}
          errors={errors}
          fieldName='countries'
          label='Countries'
          wrapperElement={() => {
            return (
              countries?.length > 0 && JSON.parse(values.countries)?.length > 0 && (
                <CountriesSelect />
              )
            )
          }}
        />

        {/* Leaderboard ID */}
        <Fields
          handleBlur={handleBlur}
          handleChange={handleChange}
          values={values}
          errors={errors}
          touched={touched}
          label='Leader Board ID'
          fieldName='leaderBoardId'
          type='text'
          placeholder='Enter Leader Board ID'
          disabled={type === 'view'}
        />
      </Row>

      <Row className='mt-3 mb-3'>
        {/* Min Bet */}
        <Fields
          handleBlur={handleBlur}
          handleChange={handleChange}
          values={values}
          errors={errors}
          touched={touched}
          label='Min Bet'
          fieldName='minBetAmount'
          type='number'
          placeholder='Enter Min Bet'
          disabled={type === 'view'}
        />

        {/* Start Date */}
        <Fields
          handleBlur={handleBlur}
          handleChange={handleChange}
          values={values}
          errors={errors}
          touched={touched}
          label='Start Date'
          minimum={getDateTimeDaysAfter(0)}
          fieldName='startAt'
          type='date-time'
          disabled={type === 'view'}
        />

        {/* End Date */}
        <Fields
          handleBlur={handleBlur}
          handleChange={handleChange}
          values={values}
          errors={errors}
          touched={touched}
          label='End Date'
          minimum={getDateTimeDaysAfter(2)}
          fieldName='endAt'
          type='date-time'
          disabled={type === 'view'}
        />

        {/* Tournament Mechanics */}
        <Fields
          wrapper
          touched={touched}
          errors={errors}
          fieldName='tournamentMechanics'
          label='Tournament Mechanics'
          wrapperElement={() => {
            return (
              <BForm.Select
                name='tournamentMechanics'
                value={values.tournamentMechanics}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={type === 'view'}
              >
                <option value='' disabled>
                  ---Select---
                </option>
                {tourneyMechanics?.map((mechanics) => {
                  return (
                    <option value={mechanics?.toUpperCase()} key={mechanics}>
                      {mechanics}
                    </option>
                  )
                })}
              </BForm.Select>
            )
          }}
        />
      </Row>

      <Row>
        {/* Payouts */}
        <Fields
          wrapper
          touched={touched}
          errors={errors}
          fieldName='payouts'
          label='Payouts'
          wrapperElement={() => {
            return (
              <BForm.Select
                type='text'
                name='payouts'
                value={values.payouts}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={type === 'view'}
              >
                <option value='' disabled>
                  ---Select---
                </option>
                <option value='AUTOMATIC'>Automatic</option>
                <option value='MANUAL'>Manual</option>
              </BForm.Select>
            )
          }}
        />

        {/* Pool Prize */}
        <Fields
          handleBlur={handleBlur}
          handleChange={handleChange}
          values={values}
          errors={errors}
          touched={touched}
          label='Pool Prize'
          fieldName='amount'
          type='number'
          placeholder='Enter Pool Prize'
          disabled={type === 'view'}
        />

        {/* Prize Places */}
        <Fields
          handleBlur={handleBlur}
          handleChange={handleChange}
          values={values}
          errors={errors}
          touched={touched}
          label='Prize Places'
          fieldName='prizePlaces'
          type='number'
          placeholder='Enter Prize Places'
          disabled={type === 'view'}
        />

        {/* Status */}
        <Fields
          wrapper
          touched={touched}
          errors={errors}
          fieldName='isActive'
          label='Status'
          wrapperElement={() => {
            return (
              <BForm.Check
                type='switch'
                name='isActive'
                disabled={type === 'view'}
                checked={values.isActive}
                value={values.isActive}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )
          }}
        />
      </Row>

      {/* Description */}
      <ReactQuillField
        touched={touched}
        errors={errors}
        errDesc={errDesc}
        setFieldValue={setFieldValue}
        setData={setData}
        data={data}
        values={values}
        setErrDesc={setErrDesc}
        fieldName='description'
        label='Description'
        dataKey='desc'
        disabled={type === 'view'}
      />

      {/* Rules */}
      <ReactQuillField
        touched={touched}
        errors={errors}
        errDesc={err}
        setFieldValue={setFieldValue}
        setData={setData}
        data={data}
        values={values}
        setErrDesc={setErr}
        fieldName='rules'
        label='Rules'
        dataKey='rules'
        disabled={type === 'view'}
      />

      <Row>
        {/* Banner Image */}
        <Fields
          wrapper
          touched={touched}
          errors={errors}
          fieldName='bannerUrl'
          label='Banner Image'
          wrapperElement={() => {
            return (
              <BForm.Control
                name='bannerUrl'
                type='file'
                disabled={type === 'view'}
                placeholder='Image'
                onInput={handleChange}
                onBlur={handleBlur}
                onChange={(event) => {
                  setFieldValue('bannerUrl', event.currentTarget.files[0])
                  handleImagePreview(event)
                }}
              />
            )
          }}
        />
        <Col>
          {type !== 'clone' &&
          !errors.bannerUrl &&
            (preview?.image_preview
              ? (
                <img
                  src={preview?.image_preview}
                  width='150'
                  height='150'
                  className='mt-2 border-0'
                  alt='bonus-img'
                />
                )
              : (tournamentDetails && tournamentDetails?.bannerUrl && type !== 'clone' && (
                <img
                  src={tournamentDetails?.bannerUrl}
                  width='150'
                  height='150'
                  alt='bonus-img'
                  className='mt-2 border-0'
                />
                )
                ))}
        </Col>
      </Row>
      <div className='mt-4 d-flex justify-content-between'>
        <Button
          variant='outline-warning'
          onClick={() => navigate(-1)}
          className='ml-2'
        >
          Cancel
        </Button>
        <Button
          className='ml-2'
          variant='outline-success'
          onClick={() => {
            if (!errors.termCondition || !errors.desc) {
              if (touched?.termCondition && !values?.termCondition?.replace(/<[^>]+>/g, '')?.length) {
                setErr('Terms and Conditions Required')
                setFieldValue('termCondition', '')
              } else {
                setErr('')
                if (touched?.description && !values?.description?.replace(/<[^>]+>/g, '')?.length) {
                  setErrDesc('Description Required')
                  setFieldValue('description', '')
                } else {
                  setErrDesc('')
                  handleSubmit()
                }
              }
            } else {
              setErr('Terms and Conditions Required')
            }
          }}
        >
          {languages?.length ? 'Next' : 'Submit'}
        </Button>
      </div>
    </>
  )
}

export default General
