
import { Table } from '@themesberg/react-bootstrap'
import React from 'react'
import { currencySymbols } from '../DemographicWIthMap/demographicConstant'
import { internationalNumberFormatter } from '../../utils/helpers'

export default ({ playerLiabilityData, totalSummedAmount, sortedPlayerLiabilityData }) => {
  const getPrimaryCurrencyAmount = (name, currencyCode) => {
    const key = `${name}${currencyCode}${'toEUR'}`
    const value = playerLiabilityData[name][key]
    return value?.toFixed(2)
  }

  return (
    <>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            <th>Portal</th>
            <th>Currency</th>
            <th>Liability</th>
            <th>Liability in EUR</th>
          </tr>
        </thead>

        <tbody>
          {sortedPlayerLiabilityData && sortedPlayerLiabilityData?.map(({
            liability, currencyCode, name
          }, index) => {
            return (
              <tr key={index}>
                <td>{name}</td>
                <td> {currencyCode} </td>
                <td>{currencySymbols?.[currencyCode]}{' '}{internationalNumberFormatter(liability?.toFixed(2))}</td>
                <td>{`${currencySymbols?.EUR} ${' '}  ${currencyCode === 'EUR' ? internationalNumberFormatter(liability?.toFixed(2)) : internationalNumberFormatter(getPrimaryCurrencyAmount(name, currencyCode))}`}</td>
              </tr>
            )
          })}
          {sortedPlayerLiabilityData?.length >= 1 &&
            <tr>
              <td colSpan={3}><b>Total Amount</b></td>
              <td colSpan={1}>
                <div className={`amount ${(totalSummedAmount > 0) ? 'text-success' : 'text-danger'} `}>
                  <b>{`${currencySymbols?.EUR} ${' '} ${internationalNumberFormatter(totalSummedAmount.toFixed(2))} `}</b>
                </div>
              </td>
            </tr>}
        </tbody>

        <tbody>
          <tr>
            {sortedPlayerLiabilityData?.length < 1 && (
              <td className='text-danger' colSpan={4}>No Data Found.</td>
            )}
          </tr>
        </tbody>
      </Table>
    </>
  )
}
