import React from 'react'
import { ErrorMessage, Form, Formik } from 'formik'
import { Col, Row, Form as BForm, Button, Spinner, Tabs, Tab } from '@themesberg/react-bootstrap'

import BannedGamesList from './BannedGamesList'
import Preloader from '../../../../components/Preloader'
import useCreateBannedGames from '../hooks/useCreateBannedGames'
import ProvidersFilter from '../../../../components/ProviderFilter'
import BannedGamesAffiliates from './BannedGamesAffiliates'

const CreateBannedGames = () => {
  const {
    loading,
    navigate,
    gameContribution,
    casinoGamesData,
    setGameContribution,
    formSubmitHandler,
    isClone,
    state,
    setState,
    search,
    setSearch,
    contributionDefaultValue,
    page,
    limit,
    setLimit,
    setPage,
    totalPages,
    bannedGamesListId,
    getBannedGamesListDetail,
    isTenant,
    selectedProvider,
    setSelectedProvider,
    allProviders,
    selectedTab,
    setSelectedTab,
    tenantId,
    formGameData
  } = useCreateBannedGames()
  // const { clientsFilterData } = useClientFilter({ refetch: false })
  return (
    <>
      <Row>
        <Col>
          <h3>{isClone ? 'Clone' : bannedGamesListId ? 'Add' : 'Create'} Banned Games{(isClone || bannedGamesListId) ? `: ${getBannedGamesListDetail?.bannedGamesListName}` : ''}</h3>
        </Col>
      </Row>

      <Tabs
        activeKey={selectedTab}
        onSelect={(tab) => setSelectedTab(tab)}
        className='nav-light'
      >
        <Tab eventKey='games' title='Games' disabled>
          <div className='mt-4'>
            {loading
              ? <Preloader />
              : (
                <Formik
                  initialValues={{ name: state.name || '' }}
                  onSubmit={(formValues) => {
                    formSubmitHandler(formValues)
                  }}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    handleBlur
                  }) => (
                    <Form>
                      <Row>
                        {!(isClone || bannedGamesListId) && (
                          <Col xs='auto'>
                            <BForm.Label>
                              Banned Games List Name <span className='text-danger'>*</span>
                            </BForm.Label>

                            <BForm.Control
                              type='text'
                              name='name'
                              size='sm'
                              style={{ maxWidth: '400px' }}
                              placeholder='Enter Name'
                              value={state?.name}
                              onChange={(e) => {
                                handleChange(e)
                                setState({
                                  ...state,
                                  name: e.target.value
                                })
                              }}
                              onBlur={handleBlur}
                            />

                            <ErrorMessage
                              component='div'
                              name='name'
                              className='text-danger small-size-error'
                            />
                          </Col>
                        )}

                        {/* <Col xs={3}>
                          <BForm.Label>
                            Owner
                          </BForm.Label>
                          <Select
                            isMulti
                            isClearable={false}
                            name='tenantIds'
                            options={clientsFilterData?.rows || []}
                            classNamePrefix='select'
                            value={state.owner}
                            getOptionValue={option => option.adminUserId}
                            getOptionLabel={option => `${option.firstName} ${option.lastName}`}
                            onChange={(option, e) => {
                              multiAdminHandler(option)
                              setState({
                                ...state,
                                owner: option
                              })
                            }}
                          />
                        </Col> */}
                        <Col xs='auto' className='align-items-center provider'>
                          <ProvidersFilter
                            selectedProvider={selectedProvider}
                            setSelectedProvider={setSelectedProvider}
                            isTenantRoute={isTenant}
                          />
                        </Col>
                        <Col xs='auto'>
                          <BForm.Label>
                            Search
                          </BForm.Label>

                          <BForm.Control
                            type='search'
                            size='sm'
                            style={{ maxWidth: '400px' }}
                            placeholder='Search Game Name'
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onBlur={handleBlur}
                          />
                        </Col>
                      </Row>

                      <Row>
                        {casinoGamesData &&
                          <BannedGamesList
                            gameContribution={gameContribution}
                            casinoGamesData={casinoGamesData || []}
                            setGameContribution={setGameContribution}
                            defaultGames={contributionDefaultValue || {}}
                            page={page}
                            limit={limit}
                            setLimit={setLimit}
                            setPage={setPage}
                            totalPages={totalPages}
                          />}
                      </Row>

                      <div
                        className='mt-4 d-flex justify-content-between align-items-center'
                      >
                        <Button
                          variant='outline-warning'
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </Button>

                        <div>
                          <Button
                            variant='outline-success'
                            hidden={!(allProviders?.rows?.length > 0 || allProviders?.length > 0)}
                            onClick={() => {
                              handleSubmit()
                            }}
                          >
                            {tenantId ? 'Next' : 'Submit'}
                            {loading && (
                              <Spinner
                                as='span'
                                animation='border'
                                size='sm'
                                role='status'
                                aria-hidden='true'
                              />
                            )}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>)}
          </div>
        </Tab>

        {Boolean(tenantId) && (
          <Tab eventKey='affiliates' title='Settings' disabled>
            <div className='mt-4'>
              <BannedGamesAffiliates create gameData={formGameData} />
            </div>
          </Tab>
        )}
      </Tabs>
    </>
  )
}

export default CreateBannedGames
