import { useDispatch, useSelector } from 'react-redux'
import { createParentGroupStart, updateParentGroupStart } from '../../../store/redux-slices/admins'
import { checkIsTenant } from '../../../utils/constants'

/**
 * @typedef UseParentGroupFormReturnType
 *
 * @property {Function} handleSubmit
 * @property {Boolean}  groupLoading
 */

/**
 * @returns {UseParentGroupFormReturnType}
 */
const useParentGroupForm = () => {
  const dispatch = useDispatch()
  const groupLoading = useSelector(state => state?.admins?.groupLoading || false)

  const isTenant = checkIsTenant()

  /**
   * Form submit handler
   *
   * @param {{ parentGroupName: String }} formValues
   * @param {Function}                    onSuccess
   */
  const handleSubmit = (formValues, onSuccess) => {
    if (formValues.parentId) {
      dispatch(updateParentGroupStart({ data: formValues, isTenant, onSuccess }))
    } else {
      dispatch(createParentGroupStart({ data: formValues, isTenant, onSuccess }))
    }
  }

  return {
    handleSubmit,
    groupLoading
  }
}

export default useParentGroupForm
