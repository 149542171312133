import { Button, Modal, Row, Col, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import useInfoModals from './useInfoModals'
import './style.css'

export const BonusInfoModal = ({
  bonusData,
  show,
  setShow,
  isTenant = false,
  currencyCode
}) => {
  const {
    bonusDetail,
    keys
  } = useInfoModals({ isTenant, bonusId: bonusData?.bonus?.bonusId, currencyCode, userBonusId: bonusData?.userBonusId, bonusData })

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title className='d-flex'>Title: {bonusData?.other?.bonusTitle?.EN || bonusData?.other?.bonusTitle || bonusData?.bonus?.promotionTitle?.EN} <p className='m-1'>(Bonus Id: {bonusData?.bonus?.bonusId})</p></Modal.Title>
      </Modal.Header>

      <Modal.Body className='infoModalMatchLabels'>
        {bonusData?.bonusType !== 'freespins'
          ? keys?.map(({ label, value }) => {
            return (bonusData?.other?.bonusTitle ? label !== 'Bonus Percentage' : true) && (
              <Row key={label}>
                <Col xs={7}><h6>{label}</h6></Col>
                <Col><span>{value}</span></Col>
              </Row>
            )
          })
          : (
            <>
              <Row>
                <Col xs={7}><h6>Bet Level</h6></Col>
                <Col><span>{bonusData?.betLevel}</span></Col>
              </Row>
              <Row>
                <Col xs={7}><h6>Free Spins</h6></Col>
                <Col><span>{bonusDetail?.quantity}</span></Col>
              </Row>
              <Row>
                <Col xs={7}><h6>Days To Clear</h6></Col>
                <Col><span>{bonusDetail?.daysToClear}</span></Col>
              </Row>
              {bonusDetail?.isSticky &&
                <>
                  <Row>
                    <Col xs={7}><h6>Wagering Type</h6></Col>
                    <Col><span>BONUS</span></Col>
                  </Row>
                  <Row>
                    <Col xs={7}><h6>Wagering Multiplier</h6></Col>
                    <Col><span>{bonusData?.other?.wageringRequirement || bonusDetail?.wageringMultiplier}</span></Col>
                  </Row>
                </>}
              <div>
                <Table bordered striped hover size='sm' className='text-center mt-2'>
                  <thead className='thead-dark'>
                    <tr><th>Games</th></tr>
                  </thead>
                  <tbody>
                    {bonusDetail?.games?.map(({ name }) => {
                      return (
                        <tr key={name}>
                          <td>{name}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </>)}
      </Modal.Body>

      <Modal.Footer>
        <Button variant='outline-warning' onClick={() => setShow(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
