import React from 'react'
import { ErrorMessage, Form, Formik } from 'formik'
import useAffiliateSystem from '../hooks/useAffiliateSystem'
import { Form as BForm, Button, Col, Row } from '@themesberg/react-bootstrap'
import { affiliateKeysDesc, affiliateSystemLabels, checkIsTenant } from '../../../../utils/constants'
import { affiliateSystemSchema } from '../schemas'

const AffiliateSystem = ({ tenantDetails = null }) => {
  const {
    myKeys,
    updateData,
    configData,
    affiliateKeys,
    affiliateSystem,
    setAffiliateSystem
  } = useAffiliateSystem(tenantDetails)

  const tenantConfig = tenantDetails?.tenantConfig
  const configAffiliate = tenantConfig?.affiliateIntegrated

  return (
    <Formik
      enableReinitialize={affiliateSystem || configData || tenantDetails}
      initialValues={myKeys?.length
        ? {
            affiliateSystem: configAffiliate || configData?.affiliateIntegrated || affiliateSystem || '',
            ...myKeys.reduce((acc, currValue) => ({
              ...acc,
              [currValue]: (tenantConfig?.affiliateKeys || configData?.affiliateKeys)?.[currValue]
            }), {})
          }
        : { affiliateSystem: configAffiliate || configData?.affiliateIntegrated || affiliateSystem || '' }}
      validationSchema={affiliateSystemSchema(myKeys)}
      onSubmit={(formValues) => {
        const { affiliateSystem, ...rest } = formValues
        updateData({
          data: {
            affiliate: affiliateSystem,
            keys: rest
          }
        })
      }}
    >
      {({ values, handleChange, handleSubmit }) => (
        <Form className='align-items-center mt-3'>
          <Row className='d-flex align-items-center'>
            <Col xs={8} md={4}>
              <BForm.Label>Affiliate System</BForm.Label>
            </Col>
            <Col>
              <BForm.Select
                size='sm'
                style={{ width: '220px' }}
                name='affiliateSystem'
                value={values.affiliateSystem || !checkIsTenant()}
                disabled={configData?.affiliateIntegrated || !checkIsTenant()}
                onChange={(e) => {
                  setAffiliateSystem(e.target.value)
                  handleChange(e)
                }}
              >
                <option value='' selected>---Select---</option>
                {checkIsTenant()
                  ? affiliateKeys && Object.keys(affiliateKeys)?.map(affKey => (
                    <option key={affKey} value={affKey}>{affiliateSystemLabels?.[affKey] || affKey}</option>
                  ))
                  : configAffiliate && <option key={configAffiliate} value={configAffiliate}>{affiliateSystemLabels?.[configAffiliate] || configAffiliate}</option>}
              </BForm.Select>
            </Col>
          </Row>

          {myKeys?.length && myKeys?.map(credKey => (
            <Row key={credKey} className='mt-3 d-flex align-items-center'>
              <Col xs={8} md={4}>
                <BForm.Label>{affiliateKeysDesc[credKey] || credKey}</BForm.Label>
              </Col>
              <Col>
                <BForm.Control
                  type='text'
                  disabled={!checkIsTenant()}
                  style={{ maxWidth: '420px', minWidth: '220px' }}
                  name={credKey}
                  placeholder='Enter Value'
                  value={values[credKey]}
                  onChange={handleChange}
                />
                <ErrorMessage
                  component='div'
                  name={credKey}
                  className='text-danger'
                />
              </Col>
            </Row>
          ))}

          {values?.affiliateSystem &&
            <div
              className='mt-4 d-flex justify-content-center'
            >
              <Button
                variant='outline-success'
                onClick={handleSubmit}
                hidden={configAffiliate}
              >
                Submit
              </Button>
            </div>}
        </Form>)}
    </Formik>
  )
}

export default AffiliateSystem
