import * as Yup from 'yup'

const amountGLEError = 'Amount should be greater than 0.'
const daysGLEError = 'Days should be greater than 0.'
const checkValue = (value) => {
  if (value <= 0) {
    return false
  } else {
    return true
  }
}

export const rgThresholdSchema = Yup.object().shape({
  wageringThresholdAmount: Yup.number()
    .required('Wagering Threshold is Required')
    .test('isSmall', amountGLEError, checkValue),
  winThresholdAmount: Yup.number()
    .required('Win Threshold Required')
    .test('isSmall', amountGLEError, checkValue),
  wageringThresholdDays: Yup.number()
    .required('Time Period is required')
    .test('isSmall', daysGLEError, checkValue),
  winThresholdDays: Yup.number()
    .required('Time Period is required')
    .test('isSmall', daysGLEError, checkValue)
})

export const amlThresholdSchema = Yup.object().shape({
  totalTransactionAmount: Yup.number()
    .required('Aggregated Deposit Amount is Required')
    .test('isSmall', amountGLEError, checkValue),
  singleTransactionAmount: Yup.number()
    .required('Single Deposit Amount is Required')
    .test('isSmall', amountGLEError, checkValue),
  rollingPeriodTransactionAmount: Yup.number()
    .required('Rolling Threshold Required')
    .test('isSmall', amountGLEError, checkValue),
  rollingPeriod: Yup.number()
    .required('Time Period is required')
    .test('isSmall', daysGLEError, checkValue)
})
