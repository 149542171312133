import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isTenant } from '../../../../utils/constants'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { getPaymentAggregatorsStart, getPaymentGroupsStart, getPaymentProviderStart, restrictPlayerMethodsStart } from '../../../../store/redux-slices/payment'

const useRestrictMethods = ({ userData }) => {
  const { userId } = useParams()
  const dispatch = useDispatch()
  const [type, setType] = useState('')
  const [status, setStatus] = useState('')
  const isInitialRender = useDidMountEffect()
  const [category, setCategory] = useState('')
  const [aggregator, setAggregator] = useState('')
  const [paymentGroup, setPaymentGroup] = useState('')
  const [paymentSearch, setPaymentSearch] = useState('')
  const [depositProviders, setDepositProviders] = useState([])
  const [withdrawProviders, setWithdrawProviders] = useState([])
  const { providerLoading, paymentProviders, paymentGroups, paymentAggregators } = useSelector(state => state.payment)

  const fetchData = () => {
    dispatch(getPaymentProviderStart({
      isTenant,
      paymentType: type,
      aggregator,
      tenant: '',
      group: paymentGroup,
      status,
      tenantIds: [],
      adminId: '',
      tenantId: userData?.tenantId,
      userId,
      category,
      search: paymentSearch
    }))
  }

  useEffect(() => {
    if (paymentProviders?.count) {
      const deposit = [...depositProviders]
      const withdraw = [...withdrawProviders]

      for (const provider of paymentProviders?.rows) {
        if (provider?.supportsDeposit && !deposit?.includes(provider?.paymentProviderId) && provider?.depositRestrictPlayers && JSON.parse(provider.depositRestrictPlayers).map(Number).includes(parseInt(userId))) {
          deposit.push(provider?.paymentProviderId)
        }
        if (provider?.supportsWithdrawal && !withdraw?.includes(provider?.paymentProviderId) && provider?.withdrawRestrictPlayers && JSON.parse(provider.withdrawRestrictPlayers).map(Number).includes(parseInt(userId))) {
          withdraw.push(provider?.paymentProviderId)
        }
      }
      setDepositProviders(deposit)
      setWithdrawProviders(withdraw)
    }
  }, [paymentProviders])

  useEffect(() => {
    dispatch(getPaymentAggregatorsStart({ isTenant, tenantId: userData?.tenantId }))
  }, [])

  useEffect(() => {
    fetchData()
    !paymentGroups && dispatch(getPaymentGroupsStart({ isTenant }))
  }, [paymentGroup, type, status, category, aggregator])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      !isInitialRender && fetchData()
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [paymentSearch])

  const updateData = () => {
    const removeDepositProviders = []
    const removeWithdrawProviders = []

    for (const provider of paymentProviders?.rows) {
      if (provider?.supportsDeposit && !depositProviders?.includes(provider?.paymentProviderId)) {
        removeDepositProviders.push(provider?.paymentProviderId)
      }
      if (provider?.supportsWithdrawal && !withdrawProviders?.includes(provider?.paymentProviderId)) {
        removeWithdrawProviders.push(provider?.paymentProviderId)
      }
    }
    dispatch(restrictPlayerMethodsStart({
      isTenant,
      data: {
        userId: parseInt(userId),
        depositProviderIds: depositProviders,
        withdrawProviderIds: withdrawProviders,
        removeDepositProviders,
        removeWithdrawProviders
      },
      userId,
      tenantId: userData?.tenantId,
      group: paymentGroup,
      type,
      status,
      category,
      search: paymentSearch,
      aggregator
    }))
  }

  const selectAll = (selectType, check) => {
    if (check) {
      selectType === 'deposit'
        ? setDepositProviders([...new Set([...paymentProviders?.rows?.map(provider => provider.supportsDeposit && provider.paymentProviderId).filter(val => val), ...depositProviders])])
        : setWithdrawProviders([...new Set([...paymentProviders?.rows?.map(provider => provider.supportsWithdrawal && provider.paymentProviderId).filter(val => val), ...withdrawProviders])])
    } else {
      selectType === 'deposit'
        ? setDepositProviders([...new Set(depositProviders.filter(id => !paymentProviders?.rows?.some(provider => provider.paymentProviderId === id)))])
        : setWithdrawProviders([...new Set(withdrawProviders.filter(id => !paymentProviders?.rows?.some(provider => provider.paymentProviderId === id)))])
    }
  }

  return {
    type,
    status,
    setType,
    category,
    setStatus,
    fetchData,
    selectAll,
    updateData,
    setCategory,
    aggregator,
    paymentGroup,
    paymentGroups,
    setAggregator,
    paymentSearch,
    providerLoading,
    setPaymentGroup,
    paymentProviders,
    depositProviders,
    setPaymentSearch,
    withdrawProviders,
    paymentAggregators,
    setDepositProviders,
    setWithdrawProviders
  }
}

export default useRestrictMethods
