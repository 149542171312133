import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTournamentStart, getAllTournamentsStart, updateTournamentStatusStart } from '../../../store/redux-slices/tournaments'
import { useNavigate } from 'react-router-dom'
import useCheckPermission from '../../../utils/checkPermission'
import useDidMountEffect from '../../../utils/useDidMountEffect'

const useTournaments = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isTenant = window.location.href.includes('tenant')

  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [active, setActive] = useState('')
  const [status, setStatus] = useState('')
  const [statusShow, setStatusShow] = useState('')
  const [deleteShow, setDeleteShow] = useState('')
  const [name, setName] = useState('')
  const [tournamentId, setTournamentId] = useState('')
  const { allTournaments, loading } = useSelector(state => state.tournaments)
  const { isHidden } = useCheckPermission()
  const isInitialRender = useDidMountEffect()

  const totalPages = Math.ceil(allTournaments?.count / limit)

  const handleStatusShow = (tournamentId, isActive, title, status) => {
    setStatus(!isActive)
    setTournamentId(tournamentId)
    setName(title)
    status ? setStatusShow(true) : setDeleteShow(true)
  }

  const handleYes = () => {
    dispatch(
      updateTournamentStatusStart({
        data: {
          code: 'TOURNAMENT',
          status,
          tournamentId
        },
        limit,
        pageNo: page,
        adminId: selectedClient,
        tenantId: selectedPortal,
        tournamentId: search,
        isTenant
      })
    )
    setStatusShow(false)
  }

  const handleDeleteYes = () => {
    setDeleteShow(false)
    dispatch(deleteTournamentStart({
      data: { tournamentId },
      limit,
      pageNo: page,
      adminId: selectedClient,
      tenantId: selectedPortal,
      tournamentId: search,
      isTenant
    }))
  }

  useEffect(() => {
    dispatch(getAllTournamentsStart({ isTenant, limit, pageNo: page, adminId: selectedClient, tenantId: selectedPortal, tournamentId: search }))
  }, [limit, page, selectedPortal])

  useEffect(() => {
    if (!isInitialRender) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(getAllTournamentsStart({ isTenant, limit, pageNo: page, adminId: selectedClient, tenantId: selectedPortal, tournamentId: search }))
      }, 1000)

      return () => clearTimeout(delayDebounceFn)
    }
  }, [search])

  return {
    allTournaments,
    loading,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal,
    search,
    setSearch,
    isHidden,
    navigate,
    page,
    setPage,
    limit,
    setLimit,
    totalPages,
    active,
    setActive,
    handleStatusShow,
    handleYes,
    setStatusShow,
    statusShow,
    status,
    name,
    deleteShow,
    setDeleteShow,
    handleDeleteYes
  }
}

export default useTournaments
