import { Button, Form, InputGroup, Modal, Spinner } from '@themesberg/react-bootstrap'
import { ErrorMessage, Formik } from 'formik'
import React from 'react'
import { paymentSchema } from './schema'
import { managePaymentStart } from '../../store/redux-slices/tenantUsers'
import { formatDateYMD } from '../../utils/dateFormatter'
import usePaymentModal from '../../pages/Super-Admin/UserDetails/hooks/usePaymentModal'

const PaymentModal = ({
  show,
  setShow,
  user,
  isTenant = false
}) => {
  const {
    userId,
    loading,
    aggregators,
    providers,
    providerMethods,
    selectedProvider,
    handleAggregatorChange,
    handleProviderChange,
    handleProviderMethodChange,
    dispatch
  } = usePaymentModal(isTenant, user)

  return (
    <>
      <Formik
        initialValues={{
          amount: '',
          transactionType: '',
          paymentProviderId: '',
          paymentTransactionId: '',
          date: formatDateYMD(new Date()),
          aggregatorMethod: '',
          paymentMethod: '',
          comment: ''
        }}
        validationSchema={paymentSchema}
        onSubmit={(formValues, { resetForm }) => {
          dispatch(managePaymentStart(({
            isTenant,
            data: { ...formValues, userId: parseInt(userId) },
            setShow,
            resetForm
          })))
        }}
      >
        {({ values, touched, errors, setFieldValue, handleChange, handleBlur, handleSubmit, resetForm }) => (
          <Form>
            <Modal
              show={show}
              onHide={() => {
                resetForm({ formValues: '' })
                setShow(false)
              }}
              backdrop='static'
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Manage Payment for '{user?.firstName} {user?.lastName}'</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='form-group'>
                  <label
                    htmlFor='aggregatorMethod'
                    className={touched.aggregator && errors.aggregator ? 'text-danger' : ''}
                  >
                    Aggregator
                  </label>

                  <InputGroup
                    className={touched.aggregator && errors.aggregator
                      ? 'border border-danger'
                      : ''}
                  >

                    <Form.Select
                      name='aggregatorMethod'
                      onChange={(e) => handleAggregatorChange(e, setFieldValue)}
                      value={values.aggregatorMethod}
                    >
                      <option value='' disabled>--------Select--------</option>
                      {aggregators && aggregators.map((data) => (
                        <option value={data} key={data}>
                          {data}
                        </option>
                      ))}
                    </Form.Select>
                  </InputGroup>

                  <ErrorMessage
                    component='div'
                    name='aggregatorMethod'
                    className='text-danger'
                  />
                </div>

                <div className='form-group mt-3'>
                  <label
                    htmlFor='transactionType'
                    className={touched.transactionType && errors.transactionType ? 'text-danger' : ''}
                  >
                    Transaction Type
                  </label>

                  <InputGroup
                    className={touched.transactionType && errors.transactionType
                      ? 'border border-danger'
                      : ''}
                  >

                    <Form.Select
                      name='transactionType'
                      onChange={handleChange}
                      value={values.transactionType}
                    >
                      <option value='' disabled>--------Select--------</option>
                      <option value='manualDeposit'>Deposit</option>
                      <option value='manualWithdraw'>Withdraw</option>
                    </Form.Select>
                  </InputGroup>

                  <ErrorMessage
                    component='div'
                    name='transactionType'
                    className='text-danger'
                  />
                </div>

                <div className='form-group mt-3'>
                  <label
                    htmlFor='paymentTransactionId'
                    className={touched.paymentTransactionId && errors.paymentTransactionId ? 'text-danger' : ''}
                  >
                    Transaction ID
                  </label>

                  <InputGroup
                    className={touched.paymentTransactionId && errors.paymentTransactionId
                      ? 'border border-danger'
                      : ''}
                  >

                    <Form.Control
                      name='paymentTransactionId'
                      type='text'
                      placeholder='Enter TX ID'
                      value={values.paymentTransactionId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </InputGroup>

                  <ErrorMessage
                    component='div'
                    name='paymentTransactionId'
                    className='text-danger'
                  />
                </div>

                <div className='form-group mt-3'>
                  <label
                    htmlFor='paymentProviderId'
                    className={touched.paymentProviderId && errors.paymentProviderId ? 'text-danger' : ''}
                  >
                    Payment Provider
                  </label>

                  <InputGroup
                    className={touched.paymentProviderId && errors.paymentProviderId
                      ? 'border border-danger'
                      : ''}
                  >

                    <Form.Select
                      name='paymentProviderId'
                      onChange={(e) => handleProviderChange(e, setFieldValue)}
                      value={selectedProvider}
                    >
                      <option value='' disabled>--------Select--------</option>
                      {providers && providers?.map(
                        (name) => name !== null && (
                          <option key={name} value={name}>
                            {name}
                          </option>
                        )
                      )}
                    </Form.Select>
                  </InputGroup>

                  <ErrorMessage
                    component='div'
                    name='paymentProviderId'
                    className='text-danger'
                  />
                </div>

                <div className='form-group mt-3'>
                  <label
                    htmlFor='paymentMethod'
                    className={touched.paymentMethod && errors.paymentMethod ? 'text-danger' : ''}
                  >
                    Payment Method
                  </label>

                  <InputGroup
                    className={touched.paymentMethod && errors.paymentMethod
                      ? 'border border-danger'
                      : ''}
                  >

                    <Form.Select
                      name='paymentMethod'
                      onChange={(e) => handleProviderMethodChange(e, setFieldValue)}
                      value={values.paymentMethod}
                    >
                      <option value='' disabled>--------Select--------</option>
                      {providerMethods && providerMethods?.map(
                        (name) => name !== null && (
                          <option key={name} value={name}>
                            {name}
                          </option>
                        )
                      )}
                    </Form.Select>
                  </InputGroup>

                  <ErrorMessage
                    component='div'
                    name='paymentMethod'
                    className='text-danger'
                  />
                </div>

                <div className='form-group mt-3'>
                  <label
                    htmlFor='amount'
                    className={touched.amount && errors.amount ? 'text-danger' : ''}
                  >
                    Amount
                  </label>

                  <InputGroup
                    className={touched.amount && errors.amount
                      ? 'border border-danger'
                      : ''}
                  >
                    <InputGroup.Text>
                      {user?.currencyCode}
                    </InputGroup.Text>

                    <Form.Control
                      name='amount'
                      type='number'
                      step='any'
                      placeholder='Enter amount'
                      value={values.amount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </InputGroup>

                  <ErrorMessage
                    component='div'
                    name='amount'
                    className='text-danger'
                  />
                </div>

                <div className='form-group mt-3'>
                  <label
                    htmlFor='date'
                    className={touched.date && errors.date ? 'text-danger' : ''}
                  >
                    Date
                  </label>

                  <Form.Control
                    name='date'
                    type='date'
                    value={values.date}
                    min={formatDateYMD(new Date('01 Jan 2023'))}
                    max={formatDateYMD(new Date())}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component='div'
                    name='date'
                    className='text-danger'
                  />
                </div>

                <div className='form-group mt-3'>
                  <label
                    htmlFor='comment'
                    className={touched.comment && errors.comment ? 'text-danger' : ''}
                  >
                    Add Comment
                  </label>

                  <Form.Control
                    type='text'
                    as='textarea'
                    name='comment'
                    placeholder='Enter comment'
                    value={values.comment}
                    onChange={handleChange}
                  />

                  <ErrorMessage
                    component='div'
                    name='comment'
                    className='text-danger'
                  />
                </div>

              </Modal.Body>
              <div className='mt-4 '>
                <Modal.Footer className='d-flex justify-content-between align-items-center'>
                  <Button
                    variant='outline-warning'
                    onClick={() => {
                      resetForm({ formValues: '' })
                      setShow(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='outline-success'
                    onClick={handleSubmit}
                    className='ml-2'
                  >
                    Submit
                    {loading && (
                      <Spinner
                        as='span'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    )}
                  </Button>
                </Modal.Footer>
              </div>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default PaymentModal
