import { Button, Modal, Spinner } from '@themesberg/react-bootstrap'
import React from 'react'

const DeleteBannedUser = ({
  showModal,
  handleDeleteYes,
  name,
  handleClose,
  loading
}) => {
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Body>
        <div className='fs-5'>
          Are you sure you want to delete {name || ''}?
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={handleDeleteYes}>
          Yes
          {loading && (
            <Spinner
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          )}
        </Button>
        <Button variant='outline-primary' onClick={handleClose}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteBannedUser
