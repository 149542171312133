import React from 'react'
import { Form, Formik } from 'formik'
import ClientFilter from '../ClientFilter'
import { Col, Row, Form as BForm, Button } from '@themesberg/react-bootstrap'
import useBannedPlayerSetting from './useBannedPlayerSetting'
import PortalFilter from '../PortalFilter'

const BannedPlayerSetting = ({ isTenant }) => {
  const {
    isHidden,
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal,
    updateBannedPlayerSetting,
    tenantDetails,
    loginTenantDetails
  } = useBannedPlayerSetting(isTenant)

  const moduleConfig = !isTenant
    ? { module: { key: 'BannedPlayer', value: 'U' } }
    : { module: { key: 'TenantSettings', value: 'U' } }

  return (
    <>
      <Row>
        <Col>
          <h3>Banned Player Setting</h3>
        </Col>

        {!isTenant && (
          <Col className='d-flex align-self-center mt-2' lg={3}>
            <ClientFilter
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              setSelectedPortal={setSelectedPortal}
            />
          </Col>
        )}
        {selectedClient && (
          <Col className='d-flex align-self-center mt-2' lg={3}>
            <PortalFilter
              selectedClient={selectedClient}
              selectedPortal={selectedPortal}
              setSelectedPortal={setSelectedPortal}
            />
          </Col>
        )}
      </Row>
      <Formik
        enableReinitialize
        initialValues={{
          tenantId: isTenant ? loginTenantDetails?.tenantId : selectedPortal,
          banPlayer: isTenant
            ? loginTenantDetails?.tenantConfig?.banPlayerSetting
            : tenantDetails?.tenantConfig?.banPlayerSetting || false
        }}
        onSubmit={(formValues) => {
          updateBannedPlayerSetting(formValues)
        }}
      >
        {({ values, handleChange, handleSubmit, handleBlur, handleReset }) => {
          return (selectedClient && selectedPortal) || isTenant
            ? (
              <Form>
                <Row className='mt-3'>
                  <Col className='d-flex align-items-center'>
                    <BForm.Label className='me-3 mb-0'>
                      Enable Check<span className='text-danger'> *</span>
                    </BForm.Label>

                    <BForm.Check
                      type='switch'
                      name='banPlayer'
                      disabled={isHidden(moduleConfig)}
                      checked={values.banPlayer}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>

                <div className='mt-4 d-flex justify-content-between'>
                  <Button
                    variant='outline-warning'
                    hidden={isHidden(moduleConfig)}
                    onClick={handleReset}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='outline-success'
                    onClick={handleSubmit}
                    hidden={isHidden(moduleConfig)}
                    className='ml-2'
                  >
                    Submit
                  </Button>
                </div>
              </Form>
              )
            : (
              <div className='text-danger d-flex justify-content-center mt-4'>
                {selectedClient ? 'Please Select Portal' : 'Please Select Client'}
              </div>
              )
        }}
      </Formik>

    </>
  )
}

export default BannedPlayerSetting
