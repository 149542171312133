import React from 'react'
import { useNavigate } from 'react-router-dom'
import Trigger from '../../../../components/OverlayTrigger'
import { Button, Table } from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { bannerType, isTenant } from '../../../../utils/constants'
import { faEdit, faEye } from '@fortawesome/pro-regular-svg-icons'

const TABannerTable = ({
  item,
  loading,
  isHidden,
  selectedClient,
  selectedPortal
}) => {
  const navigate = useNavigate()
  return (
    <Table
      bordered
      striped
      responsive
      hover
      size='sm'
      className='text-center mt-2'
    >
      <thead className='thead-dark'>
        <tr>
          {['Pages', 'Action'].map((h) => (
            <th key={h}>{h}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {!loading && item && Object.keys(item)?.map((key, indx) => {
          return (
            <tr key={indx}>
              <td>
                {bannerType?.map((item) => {
                  if (key === item?.value) {
                    return item?.label
                  }
                  return null
                })}
              </td>
              <td>
                {isHidden({
                  module: { key: 'CasinoManagement', value: 'R' }
                })
                  ? (
                      '-'
                    )
                  : (
                    <>
                      <Trigger message='Update Banner'>
                        <Button
                          size='sm'
                          variant='warning'
                          className='m-1'
                          hidden={isHidden({
                            module: {
                              key: 'BannerManagement',
                              value: 'U'
                            }
                          })}
                          onClick={() =>
                            navigate(
                              isTenant
                                ? `/tenant/banner-management/edit/${key}`
                                : `/super-admin/banner-management/edit/${key}/${selectedClient}/${selectedPortal}`
                            )}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </Trigger>
                      <Trigger message='View Banner'>
                        <Button
                          size='sm'
                          variant='info'
                          className='m-1'
                          hidden={isHidden({
                            module: {
                              key: 'CasinoManagement',
                              value: 'R'
                            }
                          })}
                          onClick={() =>
                            navigate(
                              isTenant
                                ? `/tenant/banner-management/view/${key}`
                                : `/super-admin/banner-management/view/${key}/${selectedClient}/${selectedPortal}`
                            )}
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </Button>
                      </Trigger>
                    </>
                    )}
              </td>
            </tr>
          )
        })}

        {(!item || item?.length === 0) && (
          <tr>
            <td colSpan={4} className='text-danger text-center'>
              No data found
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

export default TABannerTable
