import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isTenant } from '../../../../utils/constants'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { getCMSLanguagesStart } from '../../../../store/redux-slices/languages'
import { getCountriesStart, getTenantCountriesStart } from '../../../../store/redux-slices/fetchData'
import { configProviderSettingsStart, getProviderDetailsStart, getTransactionPaymentCategoriesStart } from '../../../../store/redux-slices/payment'

const useEditProvider = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()
  const [search, setSearch] = useState('')
  const isInitialRender = useDidMountEffect()
  const [amtOptions, setAmtOptions] = useState({})
  const [myCountries, setMyCountries] = useState([])
  const [logoCheck, setLogoCheck] = useState('false')
  const [footerLogoCheck, setFooterLogoCheck] = useState('false')
  const [depositCount, setDepositCount] = useState({})
  const [nameCountryWise, setNameCountryWise] = useState({})
  const [kycCountries, setKycCountries] = useState([])
  const { providerId, tenantId, adminId } = useParams()
  const details = window.location.pathname.includes('view')
  const { tenantDetails } = useSelector(state => state.login)
  const [selectedKeyTab, setSelectedKeyTab] = useState('general')
  const [recommendedDeposit, setRecommendedDeposit] = useState([])
  const [recommendedWithdraw, setRecommendedWithdraw] = useState([])
  const { tenantLanguages } = useSelector((state) => state.languages)
  const { transactionPaymentCategories } = useSelector((state) => state.payment)
  const { countries: SACountries, tenantCountries } = useSelector(state => state.fetch)
  const { providerDetails, detailsLoading: loading, loading: submitLoading } = useSelector(state => state.payment)
  const [data, setData] = useState(providerDetails?.regions?.blocked)

  const countries = isTenant ? tenantCountries?.rows : SACountries

  useEffect(() => {
    providerId && tenantId && adminId && dispatch(getProviderDetailsStart({ isTenant, providerId, adminId, tenantId }))
  }, [providerId])

  useEffect(() => {
    providerDetails?.paymentProviderId === parseInt(providerId) &&
      setData(providerDetails?.regions?.blocked || [])
  }, [providerDetails?.regions?.blocked])

  useEffect(() => {
    setKycCountries(providerDetails?.kycCountries || [])
    setDepositCount(providerDetails?.settings?.depositCount || {})
    setNameCountryWise(providerDetails?.settings?.nameCountryWise || {})
    setRecommendedDeposit(providerDetails?.regions?.recommendedDeposit || [])
    setRecommendedWithdraw(providerDetails?.regions?.recommendedWithdraw || [])
    providerDetails?.amountKeys && setAmtOptions(typeof providerDetails?.amountKeys === 'string' ? JSON.parse(providerDetails?.amountKeys) : providerDetails?.amountKeys)
  }, [providerDetails])

  const setCountries = () => {
    if (countries && countries?.length) {
      if (providerDetails?.regions?.supported) {
        const country = []
        countries && countries.forEach(({ name, code }) => {
          if (providerDetails?.regions?.supported[code] !== undefined && name?.EN?.toLowerCase().includes(search.toLowerCase())) {
            if (providerDetails?.regions?.blocked?.includes(code)) {
              country.unshift({ name: name?.EN, code })
            } else {
              country.push({ name: name?.EN, code })
            }
          }
          setMyCountries(country)
        })
      } else {
        const country = []
        countries && countries.forEach(({ name, code }) => {
          if (name?.EN?.toLowerCase().includes(search.toLowerCase())) {
            if (providerDetails?.regions?.blocked?.includes(code)) {
              country.unshift({ name: name?.EN, code })
            } else {
              country.push({ name: name?.EN, code })
            }
          }
          setMyCountries(country)
        })
      }
    }
  }

  useEffect(() => {
    setCountries()
  }, [countries, providerDetails?.regions])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        setCountries()
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    isTenant ? dispatch(getTenantCountriesStart()) : dispatch(getCountriesStart({ limit: '', name: '', pageNo: '', isTenant }))
    !isTenant && dispatch(getCMSLanguagesStart({ adminId: adminId || '', tenantId: tenantId || '', isTenant }))
    dispatch(getTransactionPaymentCategoriesStart({ isTenant, adminId: adminId || '', tenantId: tenantId || '' }))
  }, [])

  const updateData = ({ data }) => {
    dispatch(configProviderSettingsStart({
      data: {
        ...data,
        providerId,
        adminId,
        tenantId,
        key: selectedKeyTab
      },
      isTenant,
      providerId,
      tenantId,
      adminId
    }))
    setSearch('')
    setLogoCheck('false')
    setFooterLogoCheck('false')
  }

  return {
    data,
    state,
    search,
    setData,
    adminId,
    loading,
    details,
    isTenant,
    navigate,
    tenantId,
    logoCheck,
    countries,
    setSearch,
    updateData,
    providerId,
    amtOptions,
    myCountries,
    kycCountries,
    depositCount,
    setLogoCheck,
    submitLoading,
    selectedKeyTab,
    footerLogoCheck,
    setDepositCount,
    nameCountryWise,
    providerDetails,
    setKycCountries,
    setSelectedKeyTab,
    setNameCountryWise,
    recommendedDeposit,
    setFooterLogoCheck,
    recommendedWithdraw,
    setRecommendedDeposit,
    setRecommendedWithdraw,
    transactionPaymentCategories,
    tenantLanguages: isTenant ? tenantDetails?.tenantConfig?.allowedLanguages : tenantLanguages
  }
}

export default useEditProvider
