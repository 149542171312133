import React, { useEffect } from 'react'
import {
  Row,
  Col,
  Form as BForm,
  InputGroup,
  Button
} from '@themesberg/react-bootstrap'
import BannedGamesDetailsListing from '../../../../BannedGames/components/BannedGamesDetailsListing'
import useCreateBonusBannedGames from './hooks/useCreateBonusBannedGames'
import { useDispatch } from 'react-redux'
import { getBannedGamesListDetailSuccess } from '../../../../../../store/redux-slices/superAdminTransactions'

const CreateBonusBannedGames = ({
  values,
  setFieldValue,
  setSelectedTab,
  handleSubmit,
  isEdit
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getBannedGamesListDetailSuccess({}))
  }, [])
  const {
    loading,
    bannedGames,
    getBannedGamesListDetail,
    totalPages,
    limit,
    pageNo,
    setLimit,
    setPageNo,
    isTenant,
    listBannedGames,
    setListBannedGames,
    handleBannedGamesListSelection,
    selectedPortal,
    handlePortalSelection,
    bannedGamesSetting,
    getBannedGamesSettingValue
  } = useCreateBonusBannedGames(values.bannedGamesListId, values)

  const previousTab = (values) => {
    if (values.bonusType === 'freespins') {
      return 'games'
    } else if (['match', 'wagering', 'deposit'].includes(values.bonusType)) {
      return 'wageringContribution'
    } else if (['balance', 'promotion'].includes(values.bonusType)) {
      return 'languages'
    } else if (values.bonusType === 'winBoost' && !isEdit) {
      return 'loyalty'
    } else {
      return 'general'
    }
  }

  return (
    <div>
      <Row className='mt-3 mb-3'>
        <Col sm='auto'>
          <label htmlFor='bannedGamesListId' className='mb-1'>
            Portals
          </label>
          <InputGroup>
            <BForm.Select
              show
              name='bannedGamesListId'
              size='sm'
              style={{ minWidth: '300px' }}
              value={selectedPortal || ''}
              onChange={(e) => {
                handlePortalSelection(e.target.value)
              }}
            >
              <option value='' disabled>
                Select Portals
              </option>{' '}
              {/* Default option */}
              {Array.isArray(values.tenantIds) &&
                values.tenantIds.map((list) => {
                  return (
                    <option
                      value={list.value}
                      key={list.value}
                    >
                      {list.label}
                    </option>
                  )
                })}
            </BForm.Select>
          </InputGroup>
        </Col>
        {
          selectedPortal &&
            <Col sm='auto'>
              <label htmlFor='bannedGamesListId' className='mb-1'>
                Banned Games List
              </label>
              <InputGroup>
                <BForm.Select
                  show
                  name='bannedGamesListId'
                  size='sm'
                  style={{ minWidth: '300px' }}
                  value={getBannedGamesSettingValue(selectedPortal, 'bannedGamesListId')} // Set '' if undefined
                  onChange={(e) => {
                    handleBannedGamesListSelection(e.target.value, setFieldValue, 'bannedGamesListId')
                  }}
                >
                  <option value='' disabled>
                    Select a banned game
                  </option>{' '}
                  {/* Default option */}
                  {Array.isArray(bannedGames.rows) &&
                   bannedGames.rows.map((list) => {
                     return (
                       <option
                         value={list.bannedGamesListId}
                         key={list.bannedGamesListId}
                       >
                         {list.name}
                       </option>
                     )
                   })}
                </BForm.Select>
              </InputGroup>
            </Col>
        }
        {bannedGamesSetting?.[selectedPortal]?.bannedGamesListId && (
          <Col
            sm='auto'
            className='d-flex align-items-center justify-content-center mt-4'
          >
            <BForm.Check
              type='checkbox'
              label='Restrict Game Play'
              checked={!!getBannedGamesSettingValue(selectedPortal, 'restrictBannedGame')} // Coerce to boolean
              onChange={(e) => {
                handleBannedGamesListSelection(e.target.checked, setFieldValue, 'restrictBannedGame') // Pass the boolean
              }}
              style={{ minWidth: '15px', fontSize: '16px' }}
            />
          </Col>
        )}
      </Row>

      <BannedGamesDetailsListing
        pageNo={pageNo}
        setPageNo={setPageNo}
        totalPages={totalPages}
        limit={limit}
        setLimit={setLimit}
        getBannedGamesListDetail={getBannedGamesListDetail?.gameDetail}
        isTenant={isTenant}
        listBannedGames={listBannedGames}
        setListBannedGames={setListBannedGames}
        loading={loading}
      />

      <div className='mt-4 d-flex justify-content-between align-items-center'>
        <Button
          variant='outline-warning'
          onClick={() => setSelectedTab(previousTab(values))}
        >
          Previous
        </Button>

        <div>
          <Button variant='outline-success' onClick={handleSubmit}>
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CreateBonusBannedGames
