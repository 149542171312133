import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createCasinoCategoryStart,
  updateCasinoCategoryStart
} from '../../../../store/redux-slices/tenantCasino'

const useCreateCasinoCategory = () => {
  const dispatch = useDispatch()
  const [createName, setCreateName] = useState({ EN: '' })
  const [language, setLanguage] = useState('EN')
  const { tenantDetails } = useSelector(state => state.login)

  const { loading } = useSelector((state) => state.tenantCasino)

  const updateCasinoCategory = (data) =>
    dispatch(
      updateCasinoCategoryStart(data)
    )

  const createCasinoCategory = (data) =>
    dispatch(
      createCasinoCategoryStart(data)
    )

  return {
    loading,
    updateCasinoCategory,
    createCasinoCategory,
    languages: tenantDetails?.tenantConfig?.allowedLanguages,
    language,
    setLanguage,
    createName,
    setCreateName
  }
}

export default useCreateCasinoCategory
