import { Col, Row } from '@themesberg/react-bootstrap'
import React, { useState, useEffect } from 'react'
import ReactQuill from 'react-quill'

const MultiLanguage = ({
  selectedLang,
  data,
  setData,
  disabled
}) => {
  const [rules, setRules] = useState('')

  useEffect(() => {
    data?.rules?.[selectedLang] ? setRules(data?.rules?.[selectedLang]) : setRules('')
  }, [selectedLang])

  const [desc, setDesc] = useState('')

  useEffect(() => {
    data?.desc?.[selectedLang] ? setDesc(data?.desc?.[selectedLang]) : setDesc('')
  }, [selectedLang])

  return (
    <>
      <Row className='mb-3'>
        <Col>
          <label>
            Description
          </label>
          <ReactQuill
            name='description'
            placeholder='Enter Description'
            value={desc}
            readOnly={disabled}
            onChange={(e) => {
              setDesc(e)
              setData({
                ...data,
                desc: {
                  ...data?.desc,
                  [selectedLang]: e
                }
              })
            }}
          />
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <label>
            Rules
          </label>
          <ReactQuill
            name='rules'
            placeholder='Enter Rules'
            readOnly={disabled}
            value={rules}
            onChange={(e) => {
              setRules(e)
              setData({
                ...data,
                rules: {
                  ...data?.rules,
                  [selectedLang]: e
                }
              })
            }}
          />
        </Col>
      </Row>
    </>
  )
}

export default MultiLanguage
