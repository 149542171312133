import { takeLatest, put } from 'redux-saga/effects'
import {
  getAdminUsers,
  getAdminUserDetails,
  createAdminUser,
  createSuperAdminUser,
  superAdminViewToggleStatus,
  updateSuperAdminUser,
  updateTenantAdminUser,
  getAllGroups,
  getUserStatus
} from '../../utils/apiCalls'
import { toast } from '../../components/Toast'

import {
  getAdminUsersStart,
  getAdminUsersSuccess,
  getAdminUsersFailure,
  createAdminUserStart,
  createAdminUserSuccess,
  createAdminUserFailure,
  updateSuperAdminUserStart,
  updateSuperAdminUserComplete,
  getAdminUserDetailsStart,
  getAdminUserDetailsSuccess,
  getAdminUserDetailsFailure,
  createSuperAdminUserStart,
  createSuperAdminUserComplete,
  updateSuperAdminStatusStart,
  updateSuperAdminStatusComplete,
  updateTenantAdminUserStart,
  updateTenantAdminUserComplete,
  getAllGroupsStart,
  getAllGroupsSuccess,
  getAllGroupsFailure,
  getUserStatusStart,
  getUserStatusSuccess,
  getUserStatusFailure
} from '../redux-slices/adminUser'
import { SuperAdminRoutes, TenantRoutes } from '../../routes'
import { getAllAdminsStart } from '../redux-slices/admins'

export default function * adminUserWatcher () {
  yield takeLatest(getAdminUsersStart.type, getAdminUsersWorker)
  yield takeLatest(getAdminUserDetailsStart.type, getAdminUserDetailsWorker)
  yield takeLatest(createAdminUserStart.type, createAdminUserWorker)
  yield takeLatest(createSuperAdminUserStart.type, createSuperAdminUserWorker)
  yield takeLatest(updateSuperAdminUserStart.type, updateSuperAdminUserWorker)
  yield takeLatest(
    updateSuperAdminStatusStart.type,
    updateSuperAdminStatusWorker
  )
  yield takeLatest(updateTenantAdminUserStart.type, updateTenantAdminUserWorker)
  yield takeLatest(getAllGroupsStart.type, getAllGroupsWorker)
  yield takeLatest(getUserStatusStart.type, getUserStatusWorker)
}

function * getAdminUserDetailsWorker (action) {
  try {
    const { adminUserId, isTenant = false, isAggregatorDetail = false } = action && action.payload
    const { data } = yield getAdminUserDetails({ adminUserId, isTenant, isAggregatorDetail })

    yield put(getAdminUserDetailsSuccess(data?.data?.adminDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAdminUserDetailsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * createAdminUserWorker (action) {
  try {
    const { data, navigate } = action && action.payload

    yield createAdminUser(data)

    yield put(createAdminUserSuccess())

    yield toast('Manager Created Successfully', 'success')

    navigate(TenantRoutes.Admins)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(createAdminUserFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAdminUsersWorker (action) {
  const { limit, pageNo, sort, roleId } = action && action.payload
  try {
    const { data } = yield getAdminUsers(limit, pageNo, sort, roleId)

    yield put(getAdminUsersSuccess(data?.data?.adminDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAdminUsersFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * createSuperAdminUserWorker (action) {
  try {
    const { data, navigate } = action && action.payload

    yield createSuperAdminUser(data)

    yield put(createSuperAdminUserComplete())

    yield toast(`${data?.role} Created Successfully`, 'success')

    navigate(SuperAdminRoutes.Admins)
  } catch (e) {
    yield put(createSuperAdminUserComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * updateSuperAdminUserWorker (action) {
  try {
    const { data, navigate, profile } = action && action.payload

    yield updateSuperAdminUser(data)

    profile
      ? (setTimeout(() => {
          navigate(SuperAdminRoutes.Profile)
        }
        , 7000))
      : navigate(SuperAdminRoutes.Admins)

    yield put(updateSuperAdminUserComplete())

    yield toast(`${data?.role} Updated Successfully`, 'success')
  } catch (e) {
    yield put(updateSuperAdminUserComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * updateSuperAdminStatusWorker (action) {
  try {
    const {
      data,
      limit,
      pageNo,
      orderBy,
      sort,
      search,
      superAdminId,
      superRoleId,
      status
    } = action && action.payload

    yield superAdminViewToggleStatus(data)

    yield put(updateSuperAdminStatusComplete())

    yield put(
      getAllAdminsStart({
        limit,
        pageNo,
        orderBy,
        sort,
        search,
        superAdminId,
        superRoleId,
        status
      })
    )

    yield toast('Status Updated Successfully', 'success')
  } catch (e) {
    yield put(updateSuperAdminStatusComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * updateTenantAdminUserWorker (action) {
  try {
    const { data, navigate, profile } = action && action.payload

    yield updateTenantAdminUser(data)

    yield put(updateTenantAdminUserComplete())

    yield toast(`${data?.role} Updated Successfully`, 'success')
    profile ? navigate(TenantRoutes.Profile) : navigate(TenantRoutes.Admins)
  } catch (e) {
    yield put(updateTenantAdminUserComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getAllGroupsWorker (action) {
  try {
    const { isTenant } = action && action.payload
    const { data } = yield getAllGroups(isTenant)

    yield put(getAllGroupsSuccess(data?.data?.groupNames))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllGroupsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getUserStatusWorker (action) {
  try {
    const { isTenant, userId } = action && action.payload

    const { data } = yield getUserStatus({ isTenant, userId })

    yield put(getUserStatusSuccess(data?.data?.currentStatus))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getUserStatusFailure(e?.response?.data?.errors[0]?.description))
  }
}
