import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from '../../../components/Toast'
import { addRestrictedCountriesStart, deleteRestrictedCountriesStart, getRestrictedCountriesStart, getRestrictedCountriesSuccess, getUnRestrictedCountriesStart, getUnRestrictedCountriesSuccess } from '../../../store/redux-slices/fetchData'
import useDidMountEffect from '../../../utils/useDidMountEffect'

const useProviderCountries = (game) => {
  const dispatch = useDispatch()
  const { itemId } = useParams()
  const navigate = useNavigate()
  const isInitialRender = useDidMountEffect()

  const [selectedTab, setSelectedTab] = useState('restricted-countries')
  const [restrictedCountriesLimit, setRestrictedCountriesLimit] = useState(15)
  const [restrictedCountriesPage, setRestrictedCountriesPage] = useState(1)

  const [unRestrictedCountriesLimit, setUnRestrictedCountriesLimit] = useState(15)
  const [unRestrictedCountriesPage, setUnRestrictedCountriesPage] = useState(1)

  const [selectedCountries, setSelectedCountries] = useState({ count: 0, rows: [] })
  const [removedCountries, setRemovedCountries] = useState({ count: 0, rows: [] })
  const [search, setSearch] = useState('')

  const { loading, restrictedCountries, unRestrictedCountries } = useSelector(state => state.fetch)

  const restrictedCountriesTotalPages = Math.ceil(restrictedCountries?.count / restrictedCountriesLimit)
  const unRestrictedCountriesTotalPages = Math.ceil(unRestrictedCountries?.count / unRestrictedCountriesLimit)

  useEffect(() => {
    dispatch(getRestrictedCountriesStart({
      limit: restrictedCountriesLimit,
      pageNo: restrictedCountriesPage,
      type: game ? 'games' : 'providers',
      itemId
    }))
  }, [restrictedCountriesLimit, restrictedCountriesPage])

  useEffect(() => {
    dispatch(getUnRestrictedCountriesStart({
      limit: unRestrictedCountriesLimit,
      pageNo: unRestrictedCountriesPage,
      type: game ? 'games' : 'providers',
      itemId,
      search
    }))
  }, [unRestrictedCountriesLimit, unRestrictedCountriesPage])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        dispatch(getUnRestrictedCountriesStart({
          limit: unRestrictedCountriesLimit,
          pageNo: unRestrictedCountriesPage,
          type: game ? 'games' : 'providers',
          itemId,
          search
        }))
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  // updating un-restricted countries list when user changes page number
  useEffect(() => {
    const rows = []
    let count = 0
    unRestrictedCountries && unRestrictedCountries.rows.map(item => {
      if (selectedCountries?.count > 0 && selectedCountries?.rows?.find(ele => (ele?.countryId === item?.countryId))) {
        return null
      } else {
        rows[count++] = item
      }
      return null
    })
    dispatch(getUnRestrictedCountriesSuccess({ count: unRestrictedCountries?.count, rows }))
  }, [unRestrictedCountriesPage])

  // updating restricted countries list when user changes page number
  useEffect(() => {
    const rows = []
    let count = 0
    restrictedCountries && restrictedCountries.rows.map(item => {
      if (removedCountries?.count > 0 && removedCountries?.rows?.find(ele => (ele?.countryId === item?.countryId))) {
        return null
      } else {
        rows[count++] = item
      }
      return null
    })
    dispatch(getRestrictedCountriesSuccess({ count: restrictedCountries?.count, rows }))
  }, [restrictedCountriesPage])

  const addCountries = (country) => {
    const countryExists = ({ ...selectedCountries }
      .rows
      .findIndex(g => g.countryId === country.countryId))

    if (countryExists === -1) {
      setSelectedCountries(() => ({ count: selectedCountries.count + 1, rows: [...selectedCountries.rows, country] }))
      // updating un-restricted countries list
      const rows = []
      let count = 0
      unRestrictedCountries && unRestrictedCountries.rows.map((item, i) => {
        if (item?.countryId === country?.countryId) {
          return null
        } else {
          rows[count++] = item
        }
        return null
      })
      dispatch(getUnRestrictedCountriesSuccess({ count: unRestrictedCountries?.count, rows }))
    } else {
      toast('Already added this Country', 'error')
    }
  }

  const removeCountries = (country) => {
    const updatedCountriesRows = [...selectedCountries.rows].filter(g => g.countryId !== country?.countryId)
    setSelectedCountries(() => ({ count: selectedCountries.count - 1, rows: updatedCountriesRows }))

    // updating un-restricted countries list
    dispatch(getUnRestrictedCountriesSuccess({ count: unRestrictedCountries?.count, rows: [country, ...unRestrictedCountries?.rows] }))
  }

  const addRestrictedCountries = () => {
    const countries = [...selectedCountries.rows].map(g => g.countryId)

    dispatch(addRestrictedCountriesStart({ type: game ? 'games' : 'providers', countries, itemId: parseInt(itemId), navigate, game }))
  }

  const addDeleteCountries = (country) => {
    const countryExists = ({ ...removedCountries }
      .rows
      .findIndex(g => g.countryId === country.countryId))

    if (countryExists === -1) {
      setRemovedCountries(() => ({ count: removedCountries.count + 1, rows: [...removedCountries.rows, country] }))
      // updating restricted countries list
      const rows = []
      let count = 0
      restrictedCountries && restrictedCountries.rows.map((item, i) => {
        if (item?.countryId === country?.countryId) {
          return null
        } else {
          rows[count++] = item
        }
        return null
      })
      dispatch(getRestrictedCountriesSuccess({ count: restrictedCountries?.count, rows }))
    } else {
      toast('Already added this Country', 'error')
    }
  }

  const removeDeleteCountries = (country) => {
    const updatedCountriesRows = [...removedCountries.rows].filter(g => g.countryId !== country?.countryId)
    setRemovedCountries(() => ({ count: removedCountries.count - 1, rows: updatedCountriesRows }))

    // updating restricted countries list
    dispatch(getRestrictedCountriesSuccess({ count: restrictedCountries?.count, rows: [country, ...restrictedCountries?.rows] }))
  }

  const removeRestrictedCountries = () => {
    const countries = [...removedCountries.rows].map(g => g.countryId)

    dispatch(deleteRestrictedCountriesStart({ type: game ? 'games' : 'providers', countries, itemId: parseInt(itemId), navigate, game }))
  }

  const reset = () => {
    dispatch(getRestrictedCountriesStart({
      limit: restrictedCountriesLimit,
      pageNo: restrictedCountriesPage,
      type: game ? 'games' : 'providers',
      itemId
    }))
    dispatch(getUnRestrictedCountriesStart({
      limit: unRestrictedCountriesLimit,
      pageNo: unRestrictedCountriesPage,
      type: game ? 'games' : 'providers',
      itemId,
      search
    }))
    setSelectedCountries(() => ({ count: 0, rows: [] }))
    setRemovedCountries(() => ({ count: 0, rows: [] }))
  }
  return {
    selectedTab,
    setSelectedTab,
    loading,
    restrictedCountries,
    restrictedCountriesLimit,
    restrictedCountriesPage,
    restrictedCountriesTotalPages,
    search,
    setSearch,
    setRestrictedCountriesLimit,
    setRestrictedCountriesPage,
    unRestrictedCountries,
    unRestrictedCountriesLimit,
    unRestrictedCountriesPage,
    unRestrictedCountriesTotalPages,
    setUnRestrictedCountriesLimit,
    setUnRestrictedCountriesPage,
    addCountries,
    selectedCountries,
    removeCountries,
    addRestrictedCountries,
    addDeleteCountries,
    removedCountries,
    removeDeleteCountries,
    removeRestrictedCountries,
    reset
  }
}

export default useProviderCountries
