import { ErrorMessage, Formik } from 'formik'
import React, { useEffect } from 'react'
import { Button, Col, Form, FormCheck, Modal, Row } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2'
import { useDispatch, useSelector } from 'react-redux'
import { formatDateYMD } from '../../../../utils/dateFormatter'
import { countryMasks } from './countryMasks'
import './phoneInput.scss'
import { updateUserCurrPermissionStart, updateUserStart } from '../../../../store/redux-slices/tenantUsers'
import { getTenantStart } from '../../../../store/redux-slices/tenants'
import { userSchema } from './schema'
import { getCountriesStart, getTenantCountriesStart } from '../../../../store/redux-slices/fetchData'
import { getCMSLanguagesStart } from '../../../../store/redux-slices/languages'
import { languageCode } from '../../../Tenant/TenantConfigurations/Languages/constants'

const EditUserModal = ({
  show, setShow, userData, isTenant = false
}) => {
  const { countries, tenantCountries } = useSelector((state) => state.fetch)
  const { userCurrUpdatePermission } = useSelector((state) => state.tenantUsers)
  const { adminPermissions } = useSelector(state => state.admins)
  const { tenantDetails } = useSelector(state => state.tenants)
  const { tenantDetails: TADetails } = useSelector((state) => state.login)
  const { tenantLanguages } = useSelector((state) => state.languages)
  const currencies = isTenant ? TADetails?.tenantConfig?.allowedCurrencies : tenantDetails?.tenantConfig?.allowedCurrencies
  const languages = isTenant ? TADetails?.tenantConfig?.allowedLanguages : tenantLanguages;

  const dispatch = useDispatch()

  useEffect(() => {
    !isTenant && dispatch(getTenantStart({ tenantId: userData?.tenantId }))
    if (isTenant) dispatch(getTenantCountriesStart())
    else dispatch(getCountriesStart({ limit: '', name: '', pageNo: '' }))
  }, [userData?.tenantId])

  useEffect(() => {
    dispatch(getCMSLanguagesStart({ adminId: '', tenantId: userData?.tenantId || '' }))
  }, [userData?.tenantId])

  useEffect(() => {
    dispatch(updateUserCurrPermissionStart({ isTenant, userId: userData?.userId }))
  }, [])

  const country = isTenant ? tenantCountries?.rows : countries

  const showData = [
    { label: 'Email', value: 'email' },
    { label: 'First Name', value: 'firstName' },
    { label: 'Last Name', value: 'lastName' },
    { label: 'User Name', value: 'username' },
    { label: 'Address', value: 'address' },
    { label: 'City', value: 'city' },
    { label: 'ZipCode', value: 'zipCode' }
  ]

  return (
    <>
      <Formik
        initialValues={{
          userId: userData?.userId,
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          username: userData?.username,
          email: userData?.email,
          countryCode: userData?.countryCode,
          address: userData?.address,
          city: userData?.city,
          zipCode: userData?.zipCode,
          dateOfBirth: formatDateYMD(userData?.dateOfBirth),
          gender: userData?.gender,
          currencyCode: userData?.currencyCode,
          locale: userData?.locale,
          phoneCode: userData?.phoneCode || '',
          phone: userData?.phone || '',
          preferredLanguage: userData?.preferredLanguage || '',
          newsLetter: userData?.newsLetter || false,
          sms: userData?.sms || false
        }}
        validationSchema={userSchema(adminPermissions?.Users?.includes('RUI'))}
        onSubmit={(formValues, { resetForm }) => {
          dispatch(updateUserStart({ data: formValues, isTenant }))
          resetForm({ formValues: '' })
          setShow(false)
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit, touched, setFieldValue, setFieldTouched }) => (
          <Modal
            show={show}
            onHide={() => setShow(false)}
            backdrop='static'
            keyboard={false}
            size='xl'
          >
            <Modal.Header closeButton>
              <h5>Update {userData?.firstName} {userData?.lastName} ({userData?.email || '-'}) Info</h5>
            </Modal.Header>
            <Modal.Body>
              <Row>
                {showData?.map(({ label, value }) => {
                  return (value === 'email' ? adminPermissions?.Users?.includes('RUI') : true) && (
                    <Col xs={6} key={value} className='mt-3'>
                      <Form.Label>{label}</Form.Label>
                      <Form.Control
                        name={value}
                        type='text'
                        placeholder={`Enter ${label}`}
                        value={values[value]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        component='div'
                        name={value}
                        className='text-danger'
                      />
                    </Col>
                  )
                })}
                {adminPermissions?.Users?.includes('RUI') && (
                  <Col xs={6} className='mt-3'>
                    <Form.Label>Phone Number</Form.Label>
                    <PhoneInput
                      masks={countryMasks}
                      name='phone'
                      type='text'
                      aria-describedby='emailHelp'
                      alwaysDefaultMask={false}
                      enableSearch
                      placeholder='Enter Phone Number'
                      enableLongNumbers
                      value={`${values?.phoneCode?.substring(1)}${values?.phone}`}
                      country='us'
                      isValid={(value, country) => {
                        if (touched?.phone && (value?.charAt(
                          country?.dialCode.length
                        ) === '0' || country?.format?.replace(/[+ ()-]/g, '').length !== value?.length)) {
                          return 'Invalid Number'
                        }
                      }}
                      onChange={(phone, code) => {
                        code = String(code?.dialCode)
                        const newPhone = phone.substring(code.length)
                        const newCode = '+' + code
                        setFieldValue('phone', newPhone)
                        setFieldValue('phoneCode', newCode)
                      }}
                      onBlur={() => setFieldTouched('phone', true)}
                      buttonStyle={{ backgroundColor: '#22214b' }}
                      inputStyle={{ width: '100%', height: '43px' }}
                    />
                    <ErrorMessage
                      component='div'
                      name='phone'
                      className='error-message'
                    />
                  </Col>)}
              </Row>

              <Row>
                <Col xs={6} className='mt-3'>
                  <Form.Label>Currency</Form.Label>
                  <Form.Select
                    name='currencyCode'
                    value={values?.currencyCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!userCurrUpdatePermission}
                  >
                    {currencies?.map(
                      (code) => (
                        <option value={code} key={code}>{code}</option>
                      ))}
                  </Form.Select>

                  <ErrorMessage
                    component='div'
                    name='countryCode'
                    className='text-danger'
                  />
                </Col>
                <Col xs={6} className='mt-3'>
                  <Form.Label>Preferred Language</Form.Label>
                  <Form.Select
                    name='locale'
                    value={values?.locale}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {languages?.map(
                      (code) => (
                        <option value={code} key={code}>{languageCode[code]}</option>
                      ))}
                  </Form.Select>

                  <ErrorMessage
                    component='div'
                    name='locale'
                    className='text-danger'
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6} className='mt-3'>
                  <Form.Label>Country</Form.Label>
                  <Form.Select
                    name='countryCode'
                    value={values?.countryCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {country?.map(
                      ({ code, name: countryName }) => (
                        <option value={code} key={code}>{countryName?.EN}</option>
                      ))}
                  </Form.Select>

                  <ErrorMessage
                    component='div'
                    name='countryCode'
                    className='text-danger'
                  />
                </Col>

                <Col xs={6} className='mt-3'>
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control
                    name='dateOfBirth'
                    type='date'
                    value={values.dateOfBirth}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component='div'
                    name='dateOfBirth'
                    className='text-danger'
                  />
                </Col>
              </Row>

              <Row className='mt-3'>
                <Col>
                  <Form.Label className='me-2'>Gender</Form.Label>
                  <FormCheck.Input
                    type='radio'
                    id='Male'
                    name='gender'
                    label='Male'
                    className='mx-2 cursor-pointer'
                    aria-label='Male'
                    value='Male'
                    checked={values?.gender === 'Male'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormCheck.Label className='me-3' htmlFor='Male'>Male</FormCheck.Label>

                  <FormCheck.Input
                    type='radio'
                    id='Female'
                    name='gender'
                    label='Female'
                    className='mx-2 cursor-pointer'
                    aria-label='Female'
                    value='Female'
                    checked={values?.gender === 'Female'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormCheck.Label className='me-3' htmlFor='Female'>Female</FormCheck.Label>

                  <FormCheck.Input
                    type='radio'
                    id='Other'
                    name='gender'
                    label='Other'
                    className='mx-2 cursor-pointer'
                    aria-label='Other'
                    checked={values?.gender === 'Other'}
                    value='Other'
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormCheck.Label className='me-3' htmlFor='Other'>Other</FormCheck.Label>

                  <ErrorMessage
                    component='div'
                    name='gender'
                    className='text-danger'
                  />
                </Col>

                <Col className='d-flex'>
                  <Col>
                    <FormCheck.Input
                      type='checkbox'
                      name='newsLetter'
                      className='mx-2 cursor-pointer'
                      checked={values?.newsLetter}
                      onChange={(e) => setFieldValue('newsLetter', e.target.checked)}
                      onBlur={handleBlur}
                    />
                    <FormCheck.Label className='me-3' htmlFor='NewsLetter'>NewsLetter</FormCheck.Label>
                  </Col>
                  <Col>
                    <FormCheck.Input
                      type='checkbox'
                      name='gender'
                      className='mx-2 cursor-pointer'
                      checked={values?.sms}
                      onChange={(e) => setFieldValue('sms', e.target.checked)}
                      onBlur={handleBlur}
                    />
                    <FormCheck.Label className='me-3' htmlFor='SMS'>SMS</FormCheck.Label>
                  </Col>
                </Col>

              </Row>

            </Modal.Body>
            <div className='mt-4 '>
              <Modal.Footer className='d-flex justify-content-between align-items-center'>
                <Button
                  variant='outline-warning'
                  onClick={() => setShow(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant='outline-success'
                  onClick={handleSubmit}
                  className='ml-2'
                >
                  Submit
                </Button>
              </Modal.Footer>
            </div>
          </Modal>
        )}

      </Formik>
    </>
  )
}

export default EditUserModal
