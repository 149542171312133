import {
  Button,
  Col,
  Row,
  Spinner,
  Form as BForm
} from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import Preloader from '../../../../components/Preloader'
import CredentialsSchema from '../../../Tenant/TenantCredentials/schemas'
import useCreateCredentials from '../hooks/useCreateCredentials'
import { credKeyNames } from '../../../../utils/constants'

const CreateCredential = () => {
  const {
    navigate,
    location,
    loading,
    createComponent,
    tenantId,
    updateCreds,
    createCreds
  } = useCreateCredentials()

  return (
    <>
      {loading
        ? (
          <Preloader />
          )
        : (
          <>
            <Row className='mt-3'>
              <Col sm={8}>
                <h3>Credentials  {location.state?.key ? 'Edit' : 'Create'}</h3>
              </Col>
            </Row>

            <Formik
              initialValues={{
                credKey: location.state?.key ? location.state.key : '',
                value: location?.state?.value ? location.state.value : '',
                description: location?.state?.description
                  ? location.state.description
                  : ''
              }}
              validationSchema={CredentialsSchema}
              onSubmit={({ credKey, value, description }) => {
                location.state?.key
                  ? updateCreds({
                    tenantId,
                    key: credKey,
                    value: value.trim(),
                    description: description.trim()
                  })

                  : createCreds({
                    tenantId,
                    key: credKey,
                    value: value.trim(),
                    description: description.trim()
                  })
              }}
            >
              {({ values, handleChange, handleSubmit, handleBlur }) => (
                <Form>
                  <Row>
                    <Col>
                      <BForm.Label>
                        Key <span className='text-danger'>*</span>
                      </BForm.Label>

                      <BForm.Select
                        name='credKey'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.credKey}
                        disabled={!!location.state?.key}
                      >
                        {location.state?.key
                          ? (
                            <option
                              value={location.state?.key}
                              key={location.state?.key}
                            >
                              {credKeyNames?.[location.state?.key] || location.state?.key}
                            </option>
                            )
                          : (
                              createComponent()
                            )}
                      </BForm.Select>

                      <ErrorMessage
                        component='div'
                        name='credKey'
                        className='text-danger'
                      />
                    </Col>
                    <Col>
                      <BForm.Label>
                        Value <span className='text-danger'>*</span>
                      </BForm.Label>

                      <BForm.Control
                        type='text'
                        name='value'
                        placeholder='Enter Value'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.value}
                      />

                      <ErrorMessage
                        component='div'
                        name='value'
                        className='text-danger'
                      />
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col>
                      <BForm.Label>
                        Description <span className='text-danger'>*</span>
                      </BForm.Label>

                      <BForm.Control
                        type='text'
                        name='description'
                        placeholder='Enter Description'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                      />

                      <ErrorMessage
                        component='div'
                        name='description'
                        className='text-danger'
                      />
                    </Col>
                  </Row>

                  <div className='mt-4 d-flex justify-content-between align-items-center'>
                    <Button
                      variant='outline-warning'
                      onClick={() =>
                        navigate(-1)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='outline-success'
                      onClick={handleSubmit}
                      className='ml-2'
                    >
                      {location.state?.key ? 'Update' : 'Submit'}
                      {loading && (
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
          )}
    </>
  )
}

export default CreateCredential
