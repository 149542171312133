import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { auditLogsStart } from '../../../../store/redux-slices/superAdminTransactions'
import { formatDateYMD } from '../../../../utils/dateFormatter'
import { useParams } from 'react-router-dom'

const useAudits = ({ limit, page, search, state }) => {
  const dispatch = useDispatch()

  const isInitialRender = useDidMountEffect()
  const { userId } = useParams()
  const url = window.location.href
  const isTenant = url.includes('/tenant/')

  const { loading, auditList } = useSelector((state) => state.superAdminTransactions)

  const totalPages = Math.ceil(auditList?.count / limit)

  const fetchAudits = () => {
    dispatch(auditLogsStart({
      search,
      userId,
      isTenant,
      createdStartDate: formatDateYMD(state.map(a => a.startDate)),
      createdEndDate: formatDateYMD(state.map(a => a.endDate)),
      limit,
      pageNo: page
    }))
  }

  useEffect(() => {
    fetchAudits()
  }, [limit, page, state])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        fetchAudits()
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  return {
    loading,
    auditList,
    totalPages
  }
}

export default useAudits
