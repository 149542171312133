export const tableHeaders = [
  { label: 'ID', value: 'tenantGameSubCategoryId' },
  { label: 'Name', value: 'name' },
  { label: 'Order Id', value: 'orderId' },
  { label: 'Status', value: 'status' },
  { label: 'Image Url', value: 'image_url' },
  { label: 'Category Name', value: 'categoryname' },
  { label: 'Actions', value: 'Actions' }
]

export const allowedKeysforOrder = [
  'tenantGameSubCategoryId',
  'name'
]
