import { Spinner } from '@themesberg/react-bootstrap'
import React from 'react'
import { Button } from 'react-bootstrap'
import CountryForm from '../../../CustomPayment/components/CountryForm'
import useBonusCountry from '../hooks/useBonusCountry'

const BonusCountry = ({
  data,
  setData,
  isTenant = false,
  details = false,
  values,
  setSelectedTab,
  handleSubmit,
  isEdit = false,
  bonusDetail
}) => {
  const { search, setSearch, myCountries, loading } = useBonusCountry(isTenant)

  const getPreviousTab = (values, bonusDetail) => {
    if (isEdit && bonusDetail?.claimedCount) {
      return bonusDetail?.bonusType === 'match' ? 'payment' : 'bannedGames'
    }
    if (values?.bonusType === 'balance' || values?.bonusType === 'promotions') {
      return 'languages'
    }
    return values?.bonusType === 'cashfreespins' ? 'games' : 'bannedGames'
  }

  return (
    <>
      <CountryForm
        data={data}
        setData={setData}
        search={search}
        setSearch={setSearch}
        myCountries={myCountries}
        isTenant={isTenant}
        loading={loading}
        details={details || values?.bonusType === 'balance'}
      />
      {!details &&
        <div
          className='mt-4 d-flex justify-content-between align-items-center'
        >
          <Button
            variant='outline-warning'
            onClick={() => setSelectedTab(getPreviousTab(values, bonusDetail))}
          >
            Previous
          </Button>

          <div>
            <Button
              variant='outline-success'
              onClick={() => {
                handleSubmit()
              }}
            >
              Submit
              {loading && (
                <Spinner
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              )}
            </Button>
          </div>
        </div>}
    </>
  )
}

export default BonusCountry
