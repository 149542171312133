import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllRGPlayerListingStart } from '../../../store/redux-slices/rgModule'
import { isTenant } from '../../../utils/constants'
import useDidMountEffect from '../../../utils/useDidMountEffect'

const useRGModule = ({ selfExcluded }) => {
  const heading = `${selfExcluded ? 'Excluded' : ''} Flagged Players`
  const tabs = [
    { label: 'Wins Threshold', value: 'win' },
    { label: 'Wagering Threshold', value: 'bet' }
  ]

  const dispatch = useDispatch()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const [search, setSearch] = useState('')
  const [userId, setUserId] = useState('')
  const { RGPlayersDetails, loading, rgThresholdValue } = useSelector(state => state.RGModule)
  const [selectedTab, setSelectedTab] = useState('win')
  const totalPages = Math.ceil(RGPlayersDetails?.count / limit)
  const isInitialRender = useDidMountEffect()

  const fetchUserListing = () => {
    dispatch(getAllRGPlayerListingStart({
      limit,
      page,
      tenantId: selectedPortal,
      isTenant,
      selectedTab,
      search,
      userId,
      selfExcluded
    }))
  }

  useEffect(() => {
    (isTenant || selectedPortal) && fetchUserListing()
  }, [selectedPortal, limit, selectedTab])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          (isTenant || selectedPortal) && fetchUserListing()
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search, userId])

  useEffect(() => {
    !isInitialRender && fetchUserListing()
  }, [page])

  return {
    page,
    limit,
    setPage,
    setLimit,
    RGPlayersDetails,
    loading,
    totalPages,
    setSelectedClient,
    setSelectedPortal,
    selectedClient,
    selectedPortal,
    selectedTab,
    setSelectedTab,
    search,
    setSearch,
    userId,
    setUserId,
    heading,
    tabs,
    rgThresholdValue
  }
}

export default useRGModule
