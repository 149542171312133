import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getAllTenantAdminsStart,
    getAllTenantAdminsSuccess,
    getAllTenantAdminsFailure,
    getAllTenantAffiliatesStart,
    getAllTenantAffiliatesSuccess,
    getAllTenantAffiliatesFailure,
    getAllTenantCMSStart,
    getAllTenantCMSSuccess,
    getAllTenantCMSFailure,
    getTenantAffiliateByIdStart,
    getTenantAffiliateByIdSuccess,
    getTenantAffiliateByIdFailure,
    createCmsStart,
    createCmsSuccess,
    createCmsFailure,
    getCmsDetailsStart,
    getCmsDetailsSuccess,
    getCmsDetailsFailure,
    updateCmsStart,
    updateCmsComplete,
    updateCmsStatusStart,
    updateCmsStatusComplete,
    getUserDocumentStart,
    getUserDocumentSuccess,
    getUserDocumentFailure,
    updateLoyaltyLimitStart,
    updateLoyaltyLimitComplete,
    getCommentsStart,
    getCommentsSuccess,
    getCommentsFailure,
    addCommentsStart,
    addCommentsSuccess,
    addCommentsFailure,
    updateCommentStart,
    updateCommentSuccess,
    updateCommentFailure
  },
  reducer
} = createSlice({
  name: 'tenantsFetchData',
  initialState: {
    loading: false,
    error: null,
    success: false,
    tenantCMSData: null,
    tenantAffiliatesData: null,
    tenantAdminsData: null,
    tenantAffiliatesByIdData: null,
    cmsDetails: null,
    userDocuments: null,
    loyaltyPoint: null,
    commentsList: null
  },
  reducers: {
    getAllTenantAdminsStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllTenantAdminsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      tenantAdminsData: payload
    }),
    getAllTenantAdminsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getAllTenantAffiliatesStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllTenantAffiliatesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      tenantAffiliatesData: payload
    }),
    getAllTenantAffiliatesFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getAllTenantCMSStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllTenantCMSSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      tenantCMSData: payload
    }),
    getAllTenantCMSFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    getTenantAffiliateByIdStart: (state) => ({
      ...state,
      loading: true
    }),
    getTenantAffiliateByIdSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      tenantAffiliatesByIdData: payload
    }),
    getTenantAffiliateByIdFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    createCmsStart: (state) => ({
      ...state,
      loading: true
    }),
    createCmsSuccess: (state) => ({
      ...state,
      loading: false
    }),
    createCmsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getCmsDetailsStart: (state) => ({
      ...state,
      loading: true,
      cmsDetails: null
    }),
    getCmsDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      cmsDetails: payload
    }),
    getCmsDetailsFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateCmsStart: (state) => ({
      ...state,
      loading: true
    }),
    updateCmsComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateCmsStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateCmsStatusComplete: (state) => ({
      ...state,
      loading: false
    }),
    getUserDocumentStart: (state) => ({
      ...state,
      loading: true
    }),
    getUserDocumentSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userDocuments: payload
    }),
    getUserDocumentFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateLoyaltyLimitStart: (state) => ({
      ...state,
      loading: true,
      loyaltyPoint: null
    }),
    updateLoyaltyLimitComplete: (state, { payload }) => ({
      ...state,
      loading: false,
      loyaltyPoint: payload
    }),
    getCommentsStart: (state) => ({
      ...state,
      loading: true
    }),
    getCommentsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      commentsList: payload
    }),
    getCommentsFailure: (state) => ({
      ...state,
      loading: false
    }),
    addCommentsStart: (state) => ({
      ...state,
      loading: true
    }),
    addCommentsSuccess: (state) => ({
      ...state,
      loading: false
    }),
    addCommentsFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateCommentStart: (state) => ({
      ...state,
      loading: true
    }),
    updateCommentSuccess: (state) => ({
      ...state,
      loading: false
    }),
    updateCommentFailure: (state) => ({
      ...state,
      loading: false
    })
  }
})

export default reducer

export {
  getAllTenantAdminsStart,
  getAllTenantAdminsSuccess,
  getAllTenantAdminsFailure,
  getAllTenantAffiliatesStart,
  getAllTenantAffiliatesSuccess,
  getAllTenantAffiliatesFailure,
  getAllTenantCMSStart,
  getAllTenantCMSSuccess,
  getAllTenantCMSFailure,
  getTenantAffiliateByIdStart,
  getTenantAffiliateByIdSuccess,
  getTenantAffiliateByIdFailure,
  createCmsStart,
  createCmsSuccess,
  createCmsFailure,
  getCmsDetailsStart,
  getCmsDetailsSuccess,
  getCmsDetailsFailure,
  updateCmsStart,
  updateCmsComplete,
  updateCmsStatusStart,
  updateCmsStatusComplete,
  getUserDocumentStart,
  getUserDocumentSuccess,
  getUserDocumentFailure,
  updateLoyaltyLimitStart,
  updateLoyaltyLimitComplete,
  getCommentsStart,
  getCommentsSuccess,
  getCommentsFailure,
  addCommentsStart,
  addCommentsSuccess,
  addCommentsFailure,
  updateCommentStart,
  updateCommentSuccess,
  updateCommentFailure
}
