import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getAllCasinoProvidersStart,
    getAllCasinoProvidersSuccess,
    getAllCasinoProvidersFailure,
    createCasinoProviderStart,
    createCasinoProviderComplete,
    updateCasinoProviderStart,
    updateCasinoProviderComplete,
    getAllCasinoGamesStart,
    getAllCasinoGamesSuccess,
    getAllCasinoGamesFailure,
    createCasinoGameStart,
    createCasinoGameComplete,
    updateCasinoGameStart,
    updateCasinoGameComplete,
    resetCasinoGameStart,
    resetCasinoGameSuccess,
    updateCasinoStatusStart,
    updateCasinoStatusComplete,
    getSuperAdminAggregatorsStart,
    getSuperAdminAggregatorsSuccess,
    getSuperAdminAggregatorsFailure,
    createSuperAdminAggregatorStart,
    createSuperAdminAggregatorComplete,
    updateSuperAdminAggregatorStatusStart,
    updateSuperAdminAggregatorStatusComplete,
    getSuperAdminGameCategoryStart,
    getSuperAdminGameCategorySuccess,
    getSuperAdminGameCategoryFailure,
    getSuperAdminGameSubCategoryStart,
    getSuperAdminGameSubCategorySuccess,
    getSuperAdminGameSubCategoryFailure,
    updateSACasinoGamesStatusStart,
    updateSACasinoGamesStatusComplete,
    getAllSAProvidersStart,
    getAllSAProvidersSuccess,
    getAllSAProvidersFailure,
    getFreeSpinGamesStart,
    getFreeSpinGamesSuccess,
    getFreeSpinGamesFailure,
    updateReorderGamesStart,
    updateReorderGamesSuccess,
    updateReorderGamesFailure,
    getCasinoThemesStart,
    getCasinoThemesSuccess,
    getCasinoThemesFailure,
    getUniqueGameIdsStart,
    getUniqueGameIdsSuccess,
    getUniqueGameIdsFailure,
    getSuperAdminTAGameSubCategoryStart,
    getSuperAdminTAGameSubCategorySuccess,
    getAllExcludedCasinoGamesStart,
    getAllExcludedCasinoGamesSuccess,
    getAllExcludedCasinoGamesFailure,
    addAggregatorsStart,
    addAggregatorsComplete,
    getBonusPrepaidListStart,
    getBonusPrepaidListComplete,
    updatePrepaidsStore,
    resetPrepaidsStore
  },
  reducer
} = createSlice({
  name: 'fetchCasinoData',
  initialState: {
    loading: false,
    casinoProvidersData: null,
    casinoGamesData: null,
    aggregators: null,
    gameCategory: null,
    gameSubCategory: null,
    allProviders: null,
    uniqueGameIds: null,
    casinoThemes: null,
    idLoading: false,
    tenantGameSubCategories: null,
    bonusPrepaidList: null,
    bonusPrepaidListLoading: false,
    gameLoading: false,
    prepaidsStore: null
  },
  reducers: {
    getAllCasinoProvidersStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllCasinoProvidersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      casinoProvidersData: payload
    }),
    getAllCasinoProvidersFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAllCasinoGamesStart: (state) => ({
      ...state,
      loading: true,
      gameLoading: true
    }),
    resetCasinoGameStart: (state) => ({
      ...state,
      loading: true
    }),
    resetCasinoGameSuccess: (state) => ({
      ...state,
      casinoGamesData: null
    }),
    getAllCasinoGamesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      gameLoading: false,
      casinoGamesData: payload
    }),
    getAllCasinoGamesFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      gameLoading: false
    }),
    createCasinoGameStart: (state) => ({
      ...state,
      loading: true
    }),
    createCasinoGameComplete: (state) => ({
      ...state,
      loading: false
    }),
    createCasinoProviderStart: (state) => ({
      ...state,
      loading: true
    }),
    createCasinoProviderComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateCasinoGameStart: (state) => ({
      ...state,
      loading: true
    }),
    updateCasinoGameComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateCasinoProviderStart: (state) => ({
      ...state,
      loading: true
    }),
    updateCasinoProviderComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateCasinoStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateCasinoStatusComplete: (state) => ({
      ...state,
      loading: false
    }),
    getSuperAdminAggregatorsStart: (state) => ({
      ...state,
      loading: true
    }),
    getSuperAdminAggregatorsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      aggregators: payload
    }),
    getSuperAdminAggregatorsFailure: (state) => ({
      ...state,
      loading: false
    }),
    createSuperAdminAggregatorStart: (state) => ({
      ...state,
      loading: true
    }),
    createSuperAdminAggregatorComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateSuperAdminAggregatorStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateSuperAdminAggregatorStatusComplete: (state) => ({
      ...state,
      loading: false
    }),
    getSuperAdminGameCategoryStart: (state) => ({
      ...state,
      loading: true
    }),
    getSuperAdminGameCategorySuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      gameCategory: payload
    }),
    getSuperAdminGameCategoryFailure: (state) => ({
      ...state,
      loading: false
    }),
    getSuperAdminGameSubCategoryStart: (state) => ({
      ...state,
      loading: true
    }),
    getSuperAdminGameSubCategorySuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      gameSubCategory: payload
    }),
    getSuperAdminGameSubCategoryFailure: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    updateSACasinoGamesStatusStart: (state) => ({
      ...state,
      loading: true
    }),
    updateSACasinoGamesStatusComplete: (state) => ({
      ...state,
      loading: false
    }),
    getAllSAProvidersStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllSAProvidersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      allProviders: payload
    }),
    getAllSAProvidersFailure: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    getFreeSpinGamesStart: (state) => ({
      ...state,
      loading: true
    }),
    getFreeSpinGamesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      casinoGamesData: payload
    }),
    getFreeSpinGamesFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateReorderGamesStart: (state) => ({
      ...state,
      loading: true
    }),
    updateReorderGamesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      reorderGames: payload
    }),
    updateReorderGamesFailure: (state) => ({
      ...state,
      loading: false
    }),
    getCasinoThemesStart: (state) => ({
      ...state,
      TASubcatLoading: true
    }),
    getCasinoThemesSuccess: (state, { payload }) => ({
      ...state,
      TASubcatLoading: false,
      casinoThemes: payload
    }),
    getCasinoThemesFailure: (state) => ({
      ...state,
      TASubcatLoading: false
    }),
    getUniqueGameIdsStart: (state) => ({
      ...state,
      idLoading: true
    }),
    getUniqueGameIdsSuccess: (state, { payload }) => ({
      ...state,
      idLoading: false,
      uniqueGameIds: payload
    }),
    getUniqueGameIdsFailure: (state) => ({
      ...state,
      idLoading: false
    }),
    getSuperAdminTAGameSubCategoryStart: (state) => ({
      ...state,
      TASubcatLoading: true
    }),
    getSuperAdminTAGameSubCategorySuccess: (state, { payload }) => ({
      ...state,
      TASubcatLoading: false,
      tenantGameSubCategories: payload
    }),
    getAllExcludedCasinoGamesStart: (state, { payload }) => ({
      ...state,
      loading: true
    }),
    getAllExcludedCasinoGamesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      casinoGamesData: payload
    }),
    getAllExcludedCasinoGamesFailure: (state) => ({
      ...state,
      loading: false
    }),
    addAggregatorsStart: (state) => ({
      ...state,
      loading: true
    }),
    addAggregatorsComplete: (state) => ({
      ...state,
      loading: false
    }),
    getBonusPrepaidListStart: (state) => ({
      ...state,
      bonusPrepaidListLoading: true
    }),
    getBonusPrepaidListComplete: (state, { payload }) => ({
      ...state,
      bonusPrepaidListLoading: false,
      bonusPrepaidList: payload
    }),
    updatePrepaidsStore: (state, { payload }) => ({
      ...state,
      prepaidsStore: state?.prepaidsStore ? { ...state?.prepaidsStore, ...payload } : payload
    }),
    resetPrepaidsStore: (state) => ({
      ...state,
      prepaidsStore: null
    })
  }
})

export default reducer

export {
  getAllCasinoProvidersStart,
  getAllCasinoProvidersSuccess,
  getAllCasinoProvidersFailure,
  createCasinoProviderStart,
  createCasinoProviderComplete,
  updateCasinoProviderStart,
  updateCasinoProviderComplete,
  getAllCasinoGamesStart,
  getAllCasinoGamesSuccess,
  getAllCasinoGamesFailure,
  createCasinoGameStart,
  createCasinoGameComplete,
  updateCasinoGameStart,
  updateCasinoGameComplete,
  resetCasinoGameStart,
  resetCasinoGameSuccess,
  updateCasinoStatusStart,
  updateCasinoStatusComplete,
  getSuperAdminAggregatorsStart,
  getSuperAdminAggregatorsSuccess,
  getSuperAdminAggregatorsFailure,
  createSuperAdminAggregatorStart,
  createSuperAdminAggregatorComplete,
  updateSuperAdminAggregatorStatusStart,
  updateSuperAdminAggregatorStatusComplete,
  getSuperAdminGameCategoryStart,
  getSuperAdminGameCategorySuccess,
  getSuperAdminGameCategoryFailure,
  getSuperAdminGameSubCategoryStart,
  getSuperAdminGameSubCategorySuccess,
  getSuperAdminGameSubCategoryFailure,
  updateSACasinoGamesStatusStart,
  updateSACasinoGamesStatusComplete,
  getAllSAProvidersStart,
  getAllSAProvidersSuccess,
  getAllSAProvidersFailure,
  getFreeSpinGamesStart,
  getFreeSpinGamesSuccess,
  getFreeSpinGamesFailure,
  updateReorderGamesStart,
  updateReorderGamesSuccess,
  updateReorderGamesFailure,
  getCasinoThemesStart,
  getCasinoThemesSuccess,
  getCasinoThemesFailure,
  getUniqueGameIdsStart,
  getUniqueGameIdsSuccess,
  getUniqueGameIdsFailure,
  getSuperAdminTAGameSubCategoryStart,
  getSuperAdminTAGameSubCategorySuccess,
  getAllExcludedCasinoGamesStart,
  getAllExcludedCasinoGamesSuccess,
  getAllExcludedCasinoGamesFailure,
  addAggregatorsStart,
  addAggregatorsComplete,
  getBonusPrepaidListStart,
  getBonusPrepaidListComplete,
  updatePrepaidsStore,
  resetPrepaidsStore
}
