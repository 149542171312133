import { takeLatest, put } from 'redux-saga/effects'
import { disableAuthenticator, getQrCode, verifyAuthCode } from '../../utils/apiCalls'
import { toast } from '../../components/Toast'
import {
  getAuthQrCodeStart,
  getAuthQrCodeSuccess,
  getAuthQrCodeFailure,
  verifyAuthCodeStart,
  disableAuthenticatorStart,
  verifyAuthCodeFailure,
  disableAuthenticatorSuccess,
  disableAuthenticatorFailure,
  qrModalCloseHandler
} from '../redux-slices/auth'

export default function * authWather () {
  yield takeLatest(getAuthQrCodeStart.type, getAuthQrCodeStartWorker)
  yield takeLatest(verifyAuthCodeStart.type, verifyAuthCodeStartWorker)
  yield takeLatest(disableAuthenticatorStart.type, disableAuthenticatorWorker)
}

function * getAuthQrCodeStartWorker (action) {
  try {
    const { userType, generateAuthQrImage, isTenant } = action && action.payload

    const { data } = yield getQrCode({ userType, isTenant })

    yield put(getAuthQrCodeSuccess(data?.data?.result))
    yield generateAuthQrImage(data?.data?.result)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getAuthQrCodeFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * verifyAuthCodeStartWorker (action) {
  try {
    const { token, fetchData, isTenant } = action && action.payload

    const { data } = yield verifyAuthCode({ token, isTenant })
    yield put(getAuthQrCodeSuccess(data?.data?.result))
    yield toast('2 FA Authentication Enabled', 'success')
    yield put(qrModalCloseHandler())
    fetchData()
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(verifyAuthCodeFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * disableAuthenticatorWorker (action) {
  const { isTenant, fetchData } = action && action.payload
  try {
    const { data } = yield disableAuthenticator({ isTenant })
    yield put(disableAuthenticatorSuccess(data?.data?.result))
    yield toast('2 FA Authentication Disabled', 'success')
    yield put(qrModalCloseHandler())
    fetchData()
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(disableAuthenticatorFailure(e?.response?.data?.errors[0]?.description))
  }
}
