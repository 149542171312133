import * as Yup from 'yup'

export const tournamentSchema = ({ tournamentDetails, isTenant }) => Yup.object().shape({
  title: Yup.string().required('Title Required').nullable(),
  minBetAmount: Yup.string().required('Min Bet Required').nullable(),
  leaderBoardId: Yup.string().required('Leaderboard ID Required').nullable(),
  tournamentMechanics: Yup.string().required('Tournament Mechanics Required').nullable(),
  payouts: Yup.string().required('Payout Required').nullable(),
  adminId: isTenant ? Yup.number().nullable() : Yup.number().required('Owner Id Required').nullable(),
  tenantIds: Yup.array().min(1, 'Select At Least One Portal'),
  currencies: Yup.array().min(1, 'Select At Least One Currency'),
  games: Yup.array().min(1, 'Add At Least One Game'),
  countries: Yup.array().min(1, 'Add At Least One Country'),
  rules: Yup.string().required('Rules Required').nullable(),
  description: Yup.string().required('Description Required').nullable(),
  bannerUrl: !tournamentDetails
    ? Yup.mixed().required('A file is required').test('File Size',
      'File Size Should be Less Than 1MB', (value) => !value || (value && value.size <= 1024 * 1024))
      .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
        (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg'].includes(value.type))).nullable()
    : Yup.mixed().test('File Size',
      'File Size Should be Less Than 1MB', (value) => !value || (value && value.size <= 1024 * 1024))
      .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
        (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg'].includes(value.type))).nullable()
})
