import { takeLatest, put } from 'redux-saga/effects'
import {
  getAllRGPlayerListingStart,
  getAllRGPlayerListingSuccess,
  getAllRGPlayerListingFailure,
  getRgThresholdDataStart,
  getRgThresholdDataSuccess,
  getRgThresholdDataFailure,
  setRgThresholdDataStart,
  setRgThresholdDataFailure,
  setRgThresholdDataSuccess,
  getExcludedPortalDetailsStart,
  getExcludedPortalDetailsSuccess,
  getExcludedPortalDetailsFailure
} from '../redux-slices/rgModule'
import { toast } from '../../components/Toast'
import { getExcludedPortalDetails, getRGPlayers, getRGThreshold, setRGThreshold } from '../../utils/apiCalls'

export default function * rgModuleWatcher () {
  yield takeLatest(getAllRGPlayerListingStart.type, getAllRGPlayerListingWorker)
  yield takeLatest(getRgThresholdDataStart.type, getThresholdDataWorker)
  yield takeLatest(setRgThresholdDataStart.type, setRgThresholdDataWorker)
  yield takeLatest(getExcludedPortalDetailsStart.type, getExcludedPortalDetailsWorker)
}

function * getAllRGPlayerListingWorker (action) {
  try {
    const { isTenant = false, page = '', limit = '', tenantId, selectedTab, search, userId, selfExcluded } = action && action.payload
    const { data } = yield getRGPlayers({ isTenant, page, limit, tenantId, selectedTab, search, userId, selfExcluded })

    yield put(getAllRGPlayerListingSuccess(data?.data))
  } catch (e) {
    yield put(getAllRGPlayerListingFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getThresholdDataWorker (action) {
  try {
    const { isTenant = false, tenantId = '' } = action && action.payload
    const { data } = yield getRGThreshold({ isTenant, tenantId })

    yield put(getRgThresholdDataSuccess(data?.data?.rgDetails))
  } catch (e) {
    yield put(getRgThresholdDataFailure(e?.response?.data?.errors[0].description))
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * setRgThresholdDataWorker (action) {
  try {
    const { isTenant = false, data } = action && action.payload
    yield setRGThreshold({ isTenant, data })

    yield toast('Threshold Set Successfully', 'success')
    yield put(setRgThresholdDataSuccess(data))
  } catch (e) {
    yield put(setRgThresholdDataFailure(e?.response?.data?.errors[0].description))
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getExcludedPortalDetailsWorker (action) {
  try {
    const { data } = yield getExcludedPortalDetails(action.payload)

    yield put(getExcludedPortalDetailsSuccess(data?.data?.exclusionDetails))
  } catch (e) {
    yield put(getExcludedPortalDetailsFailure(e?.response?.data?.errors[0].description))
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}
