
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRgThresholdDataStart, setRgThresholdDataStart } from '../../../store/redux-slices/rgModule'
import { isTenant } from '../../../utils/constants'

export const useSetThresholds = () => {
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const dispatch = useDispatch()
  const { thresholdData } = useSelector(state => state.RGModule)
  const rgForms = [
    {
      textLabel: 'Set Wagering Thresholds',
      name: 'wageringThresholdAmount',
      placeholder: 'Enter the amount',
      value: 'values.wageringThresholdAmount',
      disabled: (isTenant ? false : !selectedPortal),
      time: true,
      timePlaceHolder: 'Enter the Days',
      periodName: 'wageringThresholdDays',
      dayLabel: 'Set Wagering days'
    },
    {
      textLabel: 'Set Win Thresholds',
      name: 'winThresholdAmount',
      placeholder: 'Enter the amount',
      value: 'values.winThresholdAmount',
      disabled: (isTenant ? false : !selectedPortal),
      time: true,
      timePlaceHolder: 'Enter the Days',
      periodName: 'winThresholdDays',
      dayLabel: 'Set Win days'
    }
  ]
  const setThresholdHandler = (formValues) => {
    const data = {
      wageringThresholdAmount: formValues.wageringThresholdAmount,
      winThresholdAmount: formValues.winThresholdAmount,
      wageringThresholdDays: formValues.wageringThresholdDays,
      winThresholdDays: formValues.winThresholdDays,
      tenantId: selectedPortal
    }
    if (isTenant || selectedPortal) {
      dispatch(setRgThresholdDataStart({ isTenant, data }))
    }
  }

  useEffect(() => {
    (isTenant || selectedPortal) && dispatch(getRgThresholdDataStart({ isTenant, tenantId: selectedPortal }))
  }, [selectedPortal])

  return {
    setThresholdHandler,
    setSelectedClient,
    setSelectedPortal,
    selectedClient,
    selectedPortal,
    thresholdData,
    rgForms
  }
}
