import React from 'react'
import Preloader from '../../../../components/Preloader'
import CreateTenantAdmin from './CreateTenantAdmin'
import useEditTenantAdmin from '../hooks/useEditTenantAdmin'

const EditTenantAdmin = () => {
  const { loading, adminUserDetails } = useEditTenantAdmin()

  return (
    <div>
      {loading
        ? (
          <Preloader />
          )
        : (
          <CreateTenantAdmin tenantData={adminUserDetails} />
          )}
    </div>
  )
}

export default EditTenantAdmin
