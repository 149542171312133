import { Button, Col, Form, Row } from '@themesberg/react-bootstrap'
import { Formik } from 'formik'
import React from 'react'
import useCheckPermission from '../../utils/checkPermission'
import { toast } from '../Toast'
import LoyaltyPoint from './LoyaltyPoint'
import { loyaltyLevelSchema } from './schema'
import useLoyalty from './useLoyalty'

export default ({ isTenant, tenant = false, setEdit, tenantLoyalty = false }) => {
  const {
    loading,
    myLevels,
    updateloyaltyLevel,
    addLevels,
    deleteLevel
  } = useLoyalty(isTenant, tenant, setEdit, tenantLoyalty)
  const { isHidden } = useCheckPermission()

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          loyaltyLevel: myLevels
        }}
        validationSchema={loyaltyLevelSchema}
        onSubmit={(formValues) => updateloyaltyLevel(formValues)}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          touched,
          errors
        }) => (
          <Form>
            <Row>
              <Col>
                <h3>Loyalty Management</h3>
              </Col>
              <Col className='d-flex justify-content-end align-items-center'>
                <Button
                  variant='outline-success'
                  size='sm'
                  hidden={isHidden({ module: { key: 'LoyaltyManagement', value: 'U' } })}
                  onClick={() => {
                    addLevels(values?.loyaltyLevel)
                  }}
                >Add Levels
                </Button>
                {setEdit &&
                  <Button
                    className='btn-sm ms-2'
                    variant='outline-danger'
                    onClick={() => setEdit(false)}
                    hidden={isHidden({ module: { key: 'Tenant', value: 'U' } })}
                  >
                    Cancel
                  </Button>}
              </Col>
            </Row>
            <LoyaltyPoint
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              deleteLevel={deleteLevel}
              touched={touched}
              errors={errors}
              loading={loading}
              tenantLoyalty={tenantLoyalty}
            />
            {!loading &&
              <div className='mt-4 d-flex justify-content-end'>
                <Button
                  variant='outline-success'
                  hidden={isHidden({ module: { key: 'LoyaltyManagement', value: 'U' } })}
                  onClick={() => {
                    const error = () => {
                      for (const arr in errors?.loyaltyLevel) {
                        if (errors?.loyaltyLevel?.[arr]) {
                          if (errors?.loyaltyLevel?.[arr]?.startPoint) { return 'startPoint' }
                        }
                      }
                      return false
                    }
                    const myError = error()
                    if (myError === 'startPoint') {
                      toast('Start Point Must Be Greater Than Previous Level End Point and Must be in Continuation', 'error')
                    } else {
                      handleSubmit()
                    }
                  }}
                  className='ml-2'
                >
                  Submit
                </Button>
              </div>}
          </Form>
        )}
      </Formik>
    </>
  )
}
