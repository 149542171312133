import React from 'react'
import { Button, Row, Col, Form } from '@themesberg/react-bootstrap'

import GamesList from '../GamesList'

const RemoveRestrictedGames = ({
  restrictedItems,
  limit,
  page,
  setLimit,
  setPage,
  totalPages,
  addDeleteGame,
  removedGames,
  removeDeleteGame,
  removeRestrictedGame,
  search,
  setSearch
}) => {
  return (
    <>
      <Row>
        <Col xs={4}>
          <Form.Label>
            <h5>Games you remove will appear here</h5>
          </Form.Label>
        </Col>

        <Col className='text-right'>
          <Button
            variant='outline-success'
            className='f-right'
            disabled={removedGames.count === 0}
            onClick={removeRestrictedGame}
          >
            Submit
          </Button>
        </Col>
      </Row>

      <GamesList
        disablePagination
        games={removedGames}
        hasActions
        hasRemoveGamesAction
        removeGame={removeDeleteGame}
      />

      <Row className='mt-3'>
        <Col xs={4}>
          <Form.Label>
            <h5>Restricted Games</h5>
          </Form.Label>
        </Col>
        <Col className='d-flex justify-content-end'>
          <Form.Control
            type='search'
            name='search'
            placeholder='Search Game'
            style={{ width: '270px' }}
            size='sm'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
      </Row>

      <GamesList
        limit={limit}
        setLimit={setLimit}
        page={page}
        setPage={setPage}
        games={restrictedItems}
        totalPages={totalPages}
        hasActions
        hasAddGamesAction
        addGame={addDeleteGame}
      />
    </>
  )
}

export default RemoveRestrictedGames
