import { Modal } from '@themesberg/react-bootstrap'
import React from 'react'
import FreeSpinForm from './FreeSpinForm'
import GamesTable from './GamesTable'
import useFreeSpinBonusModal from './useFreeSpinBonusModal'

export default ({ isTenant = false, show, setShow, userData }) => {
  const {
    search,
    gameIds,
    getData,
    loading,
    setSearch,
    submitData,
    handleSelect,
    bonusOptions,
    availableGames,
    handleSelectAll,
    tenantDetails,
    aggregators,
    selectedAggregator,
    setSelectedAggregator,
    setGameIds,
    prepaidsData,
    setPrepaidsData,
    setFirstCall
  } = useFreeSpinBonusModal({
    isTenant,
    countryCode: userData?.countryCode,
    userData
  })

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setShow(false)
        }}
        size='xl'
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>
              Issue Freespins to '{userData?.firstName} {userData?.lastName}'
            </h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <GamesTable
            search={search}
            setSearch={setSearch}
            gameIds={gameIds}
            availableGames={availableGames}
            handleSelectAll={handleSelectAll}
            loading={loading}
            handleSelect={handleSelect}
            getData={getData}
            setGameIds={setGameIds}
            isHub88={selectedAggregator?.includes('hub88')}
            prepaidsData={prepaidsData}
            setPrepaidsData={setPrepaidsData}
            userData={userData}
            selectedAggregator={selectedAggregator}
            setSelectedAggregator={setSelectedAggregator}
            aggregators={aggregators}
            tenantDetails={tenantDetails}
            setFirstCall={setFirstCall}
          />

          {selectedAggregator &&
            <FreeSpinForm
              setShow={setShow}
              gameIds={gameIds}
              submitData={submitData}
              bonusOptions={bonusOptions}
              selectedAggregator={selectedAggregator}
              prepaidsData={prepaidsData}
            />}
        </Modal.Body>
      </Modal>
    </>
  )
}
