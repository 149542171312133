import { Table } from '@themesberg/react-bootstrap'
import React from 'react'
import { getTextColor } from '../../utils/dashboardEffects'
import { internationalNumberFormatter } from '../../utils/helpers'
import sortArrowIcon from '../SortArrowsIcon'

export default ({ KPIReport, sortBy, setSortBy, hover, setHover }) => {
  const tableHeaders = ['Provider/Client', 'GGR', 'GGR Diff.', 'Real Bet', 'Real Win', 'Bonus Bet', 'Bonus Win', 'Bonus GGR', 'Total GGR', 'Total Bets', 'Total Bets Diff.']
  return (
    <div style={{ overflow: 'auto', maxHeight: '385px' }}>
      <Table bordered striped hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, idx) => (
              <th key={`KPI-table_heading ${idx}`}>
                {h}
                {sortArrowIcon(h, sortBy, setSortBy, hover, setHover, 'kpiReport')}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {KPIReport && Object.keys(KPIReport)?.map((key, index) => {
            return (
              <tr key={index}>
                <td className='text-left'>{key}</td>
                <td className={getTextColor(KPIReport[key]?.GGR)}>€ {internationalNumberFormatter(KPIReport[key]?.GGR)}</td>
                <td className={getTextColor(KPIReport[key]?.deltaGGR)}>
                  {KPIReport[key]?.deltaGGR}
                </td>
                <td className={getTextColor(KPIReport[key]?.realBet)}>€ {internationalNumberFormatter(KPIReport[key]?.realBet)}</td>
                <td className={getTextColor(KPIReport[key]?.realWin)}>€ {internationalNumberFormatter(KPIReport[key]?.realWin)}</td>
                <td className={getTextColor(KPIReport[key]?.bonusBet)}>€ {internationalNumberFormatter(KPIReport[key]?.bonusBet)}</td>
                <td className={getTextColor(KPIReport[key]?.bonusWin)}>€ {internationalNumberFormatter(KPIReport[key]?.bonusWin)}</td>
                <td className={getTextColor(KPIReport[key]?.bonusGGR)}>€ {internationalNumberFormatter(KPIReport[key]?.bonusGGR)}</td>
                <td className={getTextColor(parseFloat(String(KPIReport[key]?.bonusGGR)?.replaceAll(',', '')) + parseFloat(String(KPIReport[key]?.GGR)?.replaceAll(',', '')))}>
                  € {internationalNumberFormatter(KPIReport[key]?.totalGGR)}
                </td>
                <td className={getTextColor(KPIReport[key]?.totalBets)}>€ {internationalNumberFormatter(KPIReport[key]?.totalBets)}</td>
                <td className={getTextColor(KPIReport[key]?.deltaTotalBets)}>{KPIReport[key]?.deltaTotalBets}</td>
              </tr>
            )
          })}

          {
            (!KPIReport || Object.keys(KPIReport) < 1) && (
              <tr><td className='text-danger' colSpan={11}>No Data Found.</td></tr>
            )
          }
        </tbody>

      </Table>
    </div>
  )
}
