import { takeLatest, put } from 'redux-saga/effects'
import {
  getAllTenantUsersStart,
  getAllTenantUsersSuccess,
  getAllTenantUsersFailure,
  updateTenantUserStatusStart,
  updateTenantUserStatusSuccess,
  updateTenantUserStatusFailure,
  getUserFieldsStart,
  getUserFieldsSuccess,
  getUserFieldsFailure,
  updateUserFieldsStart,
  updateUserFieldsSuccess,
  updateUserFieldsFailure,
  updateTagsStart,
  updateTagsComplete,
  getDuplicateUsersStart,
  getDuplicateUsersSuccess,
  getDuplicateUsersFailure,
  updateLimitsStart,
  updateLimitsComplete,
  disableUserStart,
  disableUserComplete,
  updateUserAffiliateStart,
  updateUserAffiliateComplete,
  addAffiliateUserStart,
  addAffiliateUserComplete,
  verifyUserEmailStart,
  verifyUserEmailComplete,
  resetPasswordEmailStart,
  resetPasswordEmailComplete,
  resetUserPasswordStart,
  resetUserPasswordComplete,
  updateUserComplete,
  updateUserStart,
  getCashbackDataStart,
  getCashbackDataComplete,
  getCashbackDataFailure,
  sendUserEmailStart,
  sendUserEmailComplete,
  getEmailLogsStart,
  getEmailLogsComplete,
  getEmailLogsFailure,
  updateUserCurrPermissionStart,
  updateUserCurrPermissionComplete,
  updateUserCurrPermissionFailure,
  getCurrentCashbackDataStart,
  getCurrentCashbackDataComplete,
  getCurrentCashbackDataFailure,
  managePaymentStart,
  managePaymentComplete,
  getUserAffiliateOldDataStart,
  getUserAffiliateOldDataComplete,
  getUserAffiliateNewDataStart,
  getUserAffiliateNewDataComplete,
  updateUserAffiliateDataStart,
  updateUserAffiliateDataComplete
} from '../redux-slices/tenantUsers'
import {
  getAllTenantUsers,
  getUserFields,
  tenantViewToggleStatus,
  updateUserFields,
  updateTags,
  getDuplicateUsers,
  setDailyLimit,
  setDepositLimit,
  setLossLimit,
  disableUser,
  setSessionLimit,
  updateUserAffiliate,
  addUserAffiliate,
  verifyPlayerEmail,
  resetUserPassword,
  resetPasswordEmail,
  updateUser,
  getCashbackData,
  sendUserEmail,
  getEmailLogs,
  updateUserCurrPermission,
  getCurrentCashbackData,
  managePayment,
  getUserAffiliateOldData,
  getUserAffiliateNewData,
  updateUserAffiliateData,
  setGameLimit,
  verifyPlayerPhone
} from '../../utils/apiCalls'
import { toast } from '../../components/Toast'
import { getUserDocumentStart } from '../redux-slices/tenantsFetchData'
import { getUserStart, getSAUserDocumentStart, getTagsDataStart } from '../redux-slices/fetchData'

export default function * tenantUsersWatcher () {
  yield takeLatest(getAllTenantUsersStart.type, getAllTenantUsersWorker)
  yield takeLatest(updateTenantUserStatusStart.type, updateTenantUserStatusWorker)
  yield takeLatest(getUserFieldsStart.type, getUserFieldsWorker)
  yield takeLatest(updateUserFieldsStart.type, updateUserFieldsWorker)
  yield takeLatest(updateTagsStart.type, updateTagsWorker)
  yield takeLatest(getDuplicateUsersStart.type, getDuplicateUsersWorker)
  yield takeLatest(updateLimitsStart.type, updateLimitsWorker)
  yield takeLatest(disableUserStart.type, disableUserWorker)
  yield takeLatest(updateUserAffiliateStart.type, updateUserAffiliateWorker)
  yield takeLatest(addAffiliateUserStart.type, addAffiliateUserWorker)
  yield takeLatest(verifyUserEmailStart.type, verifyUserEmailWorker)
  yield takeLatest(resetPasswordEmailStart.type, resetPasswordEmailWorker)
  yield takeLatest(resetUserPasswordStart.type, resetUserPasswordWorker)
  yield takeLatest(updateUserStart.type, updateUserWorker)
  yield takeLatest(getCashbackDataStart.type, getCashbackDataWorker)
  yield takeLatest(getCurrentCashbackDataStart.type, getCurrentCashbackDataWorker)
  yield takeLatest(sendUserEmailStart.type, sendUserEmailWorker)
  yield takeLatest(getEmailLogsStart.type, getEmailLogsWorker)
  yield takeLatest(updateUserCurrPermissionStart.type, updateUserCurrPermissionWorker)
  yield takeLatest(managePaymentStart.type, managePaymentWorker)
  yield takeLatest(getUserAffiliateOldDataStart.type, getUserAffiliateOldDataWorker)
  yield takeLatest(getUserAffiliateNewDataStart.type, getUserAffiliateNewDataWorker)
  yield takeLatest(updateUserAffiliateDataStart.type, updateUserAffiliateDataWorker)
}

function * getAllTenantUsersWorker (action) {
  try {
    const {
      limit, search, pageNo, isActive,
      affiliateId, kycStatus, affiliateSearch,
      dobStart, dobEnd, myUserId,
      orderBy = '', sortBy = '',
      registrationDateStart, registrationDateEnd, isInternal, country = '', ftdTo, ftdFrom,
      usersType
    } =
      action && action.payload

    const { data } = yield getAllTenantUsers({
      limit,
      search,
      pageNo,
      isActive,
      affiliateId: affiliateId || '',
      kycStatus,
      affiliateSearch,
      dobStart,
      dobEnd,
      userId: myUserId,
      orderBy,
      sortBy,
      registrationDateStart,
      registrationDateEnd,
      isInternal,
      country,
      ftdTo,
      ftdFrom,
      usersType
    })

    yield put(getAllTenantUsersSuccess(data?.data?.users))
  } catch (e) {
    yield put(getAllTenantUsersFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateTenantUserStatusWorker (action) {
  try {
    const {
      kycStatus, userId, status, limit, pageNo, search, isActive, code, reason = false,
      isPlayersPage = false, isUserPage = false, affiliateSearch,
      myUserId, dobStart, dobEnd, orderBy, sortBy, description = '',
      registrationDateStart, registrationDateEnd, isInternal, country = ''
    } =
      action && action.payload

    reason && (yield tenantViewToggleStatus({ userId, status, code, reason, description }))
    !reason && (yield tenantViewToggleStatus({ userId, status, code }))
    yield put(updateTenantUserStatusSuccess())
    yield toast('User Status Updated Successfully', 'success')
    isPlayersPage && (yield put(getAllTenantUsersStart({
      limit,
      pageNo,
      search,
      isActive,
      kycStatus,
      affiliateSearch,
      myUserId,
      dobStart,
      dobEnd,
      orderBy,
      sortBy,
      registrationDateStart,
      registrationDateEnd,
      isInternal,
      country
    })))
    isUserPage && (yield put(getUserStart({ userId, isTenant: true })))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateTenantUserStatusFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getUserFieldsWorker () {
  try {
    const { data } = yield getUserFields()

    yield put(getUserFieldsSuccess(data?.data?.tenantRegistration))
  } catch (e) {
    yield put(getUserFieldsFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateUserFieldsWorker (action) {
  try {
    const data = action && action.payload

    yield updateUserFields(data)

    yield put(updateUserFieldsSuccess())

    yield toast('Fields updated', 'success')

    yield put(getUserFieldsStart())
  } catch (e) {
    yield put(updateUserFieldsFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateTagsWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    yield updateTags({ data, isTenant })
    yield put(updateTagsComplete())

    yield put(getUserStart({ userId: data?.userId, isTenant }))
    yield put(getTagsDataStart({ adminUserId: data?.parentId }))

    if (isTenant) yield put(getUserDocumentStart({ userId: data?.userId }))
    else yield put(getSAUserDocumentStart({ userId: data?.userId, isTenant }))

    yield toast('Tags Updated Successfully', 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateTagsComplete(e?.response?.data?.errors[0]?.description))
  }
}

function * getDuplicateUsersWorker (action) {
  try {
    const { limit, pageNo, userId, isTenant } = action && action.payload
    const { data } = yield getDuplicateUsers({ limit, pageNo, userId, isTenant })

    yield put(getDuplicateUsersSuccess(data?.data?.users))
  } catch (e) {
    yield put(getDuplicateUsersFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateLimitsWorker (action) {
  try {
    const { isTenant, data } = action && action.payload

    data?.type === 'wager'
      ? yield setDailyLimit({ isTenant, data })
      : (
          data?.type === 'deposit'
            ? yield setDepositLimit({ isTenant, data })
            : data?.type === 'game-play'
              ? yield setGameLimit({ data })
              : yield setLossLimit({ isTenant, data })
        )
    yield put(updateLimitsComplete())

    data?.reset
      ? yield toast('Limits Reset Successfully', 'success')
      : yield toast('Limits Updated Successfully', 'success')

    yield put(getUserStart({ userId: data.userId, isTenant }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateLimitsComplete(e?.response?.data?.errors[0]?.description))
  }
}

function * disableUserWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    data?.type
      ? yield disableUser({ isTenant, data })
      : yield setSessionLimit({ isTenant, data })

    yield put(disableUserComplete())

    yield toast(`Account ${data?.reset ? 'Enabled' : 'Disabled'} Successfully`, 'success')

    yield put(getUserStart({ userId: data.userId, isTenant }))
  } catch (e) {
    yield put(disableUserComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateUserAffiliateWorker (action) {
  try {
    const { data, isSuperAdmin, setShowRemoveAffiliate } = action && action.payload

    yield updateUserAffiliate({ isSuperAdmin, data })

    yield setShowRemoveAffiliate(prev => false)

    yield put(updateUserAffiliateComplete())

    yield put(getUserStart({ userId: data?.userId, isTenant: !isSuperAdmin }))

    yield toast('Affiliate removed successfully', 'success')
  } catch (e) {
    const { setShowRemoveAffiliate } = action && action.payload

    yield setShowRemoveAffiliate(prev => false)

    yield put(updateUserAffiliateComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * addAffiliateUserWorker (action) {
  try {
    const { data, isSuperAdmin, setShowAddAffiliate } = action && action.payload

    yield addUserAffiliate({ isSuperAdmin, data })

    yield setShowAddAffiliate(prev => false)

    yield put(addAffiliateUserComplete())

    yield put(getUserStart({ userId: data?.userId, isTenant: !isSuperAdmin }))

    yield toast('Affiliate added successfully', 'success')
  } catch (e) {
    const { setShowAddAffiliate } = action && action.payload

    yield put(addAffiliateUserComplete())

    yield setShowAddAffiliate(prev => false)

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * verifyUserEmailWorker (action) {
  try {
    const { data, isTenant, phone = false } = action && action.payload

    yield phone ? verifyPlayerPhone({ isTenant, data }) : verifyPlayerEmail({ isTenant, data })

    yield put(verifyUserEmailComplete())

    yield put(getUserStart({ userId: data.userId, isTenant }))

    yield toast(`${phone ? 'Phone' : 'Email'} Verified successfully`, 'success')
  } catch (e) {
    yield put(verifyUserEmailComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * resetPasswordEmailWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    yield resetPasswordEmail({ isTenant, data })

    yield put(resetPasswordEmailComplete())

    yield put(getUserStart({ userId: data.userId, isTenant }))

    yield toast('Reset Link Sent Successfully', 'success')
  } catch (e) {
    yield put(resetPasswordEmailComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * resetUserPasswordWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    yield resetUserPassword({ isTenant, data })

    yield put(resetUserPasswordComplete())

    yield toast('Password Reset Successfully', 'success')
  } catch (e) {
    yield put(resetUserPasswordComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateUserWorker (action) {
  try {
    const { isTenant, data } = action && action.payload

    yield updateUser({ data, isTenant })
    yield put(updateUserComplete())
    yield put(getDuplicateUsersStart({
      limit: 15,
      pageNo: 1,
      userId: data?.userId,
      isTenant
    }))
    yield toast('User Data Updated Successfully', 'success')

    yield put(getUserStart({ userId: data?.userId, isTenant }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateUserComplete(e?.response?.data?.errors[0]?.description))
  }
}

function * getCashbackDataWorker (action) {
  try {
    const { isTenant, userId, level, tenantId, currencyCode } = action && action.payload

    const { data } = yield getCashbackData({ isTenant, userId, level, tenantId, currencyCode })

    yield put(getCashbackDataComplete(data?.data))
  } catch (e) {
    yield put(getCashbackDataFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getCurrentCashbackDataWorker (action) {
  try {
    const { data } = yield getCurrentCashbackData(action.payload)

    yield put(getCurrentCashbackDataComplete(data?.data))
  } catch (e) {
    yield put(getCurrentCashbackDataFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * sendUserEmailWorker (action) {
  try {
    const { data, isTenant, resetForm } = action && action.payload

    yield sendUserEmail({ data, isTenant, resetForm })

    yield put(sendUserEmailComplete())

    yield resetForm()

    yield toast('Email Sent SuccessFully', 'success')
  } catch (e) {
    yield put(sendUserEmailComplete(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getEmailLogsWorker (action) {
  try {
    const { isTenant, limit, pageNo, search, role, userId } = action && action.payload

    const { data } = yield getEmailLogs({ isTenant, limit, pageNo, search, role, userId })

    yield put(getEmailLogsComplete(data?.data?.emailLogs))
  } catch (e) {
    yield put(getEmailLogsFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateUserCurrPermissionWorker (action) {
  try {
    const { userId, isTenant } = action && action.payload

    const { data } = yield updateUserCurrPermission({ isTenant, userId })

    yield put(updateUserCurrPermissionComplete(data?.data?.currencyEditable))
  } catch (e) {
    yield put(updateUserCurrPermissionFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * managePaymentWorker (action) {
  try {
    const { data, isTenant, setShow, resetForm } = action && action.payload

    yield managePayment({ isTenant, data })

    yield put(managePaymentComplete())
    setShow && setShow(false)
    resetForm && resetForm({ formValues: '' })

    yield toast('Payment Successful', 'success')
  } catch (e) {
    yield put(managePaymentComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getUserAffiliateOldDataWorker (action) {
  try {
    const { userId, isTenant, startDate, endDate } = action && action.payload

    const { data } = yield getUserAffiliateOldData({ isTenant, userId, startDate, endDate })

    yield put(getUserAffiliateOldDataComplete(data?.data?.affiliateData))
  } catch (e) {
    yield put(getUserAffiliateOldDataComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getUserAffiliateNewDataWorker (action) {
  try {
    const { userId, isTenant } = action && action.payload

    const { data } = yield getUserAffiliateNewData({ userId, isTenant })

    yield put(getUserAffiliateNewDataComplete(data?.data))
  } catch (e) {
    yield put(getUserAffiliateNewDataComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateUserAffiliateDataWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    const { data: resData } = yield updateUserAffiliateData({ isTenant, data })

    yield put(updateUserAffiliateDataComplete())
    yield put(getUserAffiliateNewDataComplete(resData?.data))

    yield toast('Affiliate Data Updated Successful', 'success')
  } catch (e) {
    yield put(updateUserAffiliateDataComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}
