import React from 'react'
import useEmailLogs from '../hooks/useEmailLogs'
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  Modal
} from '@themesberg/react-bootstrap'
import Parser from 'html-react-parser'
import { useDispatch } from 'react-redux'
import Trigger from '../../../../components/OverlayTrigger'
import { getDateTime } from '../../../../utils/dateFormatter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PaginationComponent from '../../../../components/Pagination'
import { faEye, faRedoAlt } from '@fortawesome/pro-solid-svg-icons'
import { getEmailLogsStart } from '../../../../store/redux-slices/tenantUsers'

const EmailLogs = ({
  role,
  limit,
  search,
  pageNo,
  setLimit,
  setPageNo,
  setSearch,
  setRole,
  isTenant = false
}) => {
  const {
    userId,
    emailLogs,
    totalPages,
    emailLoading,
    emailLogDetails,
    setEmailLogDetails,
    isEmailDetailsPopupShown,
    setEmailDetailsPopupStatus
  } = useEmailLogs({ isTenant, limit, role, setPageNo, search, pageNo })

  const tableHeaders = [
    'Id',
    'Subject Title',
    'Send By',
    'Role',
    'Status',
    'Sent At',
    'Action'
  ]
  const dispatch = useDispatch()

  return (
    <>
      <Row xs='auto'>
        <Col className='d-flex mt-2'>
          <Form.Label
            style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}
          >
            Search
          </Form.Label>

          <Form.Control
            type='search'
            placeholder='Search by Title / E-mail'
            size='sm'
            style={{ width: '230px', marginRight: '10px', maxHeight: '15px' }}
            value={search}
            onChange={(event) =>
              setSearch(event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
          />

          <Form.Label
            style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}
          >
            Role
          </Form.Label>

          <Form.Select
            type='search'
            placeholder='Search'
            size='sm'
            style={{ width: '230px' }}
            value={role}
            onChange={(event) => setRole(event.target.value)}
          >
            <option value=''>All</option>
            <option value='superadmin'>Super Admin</option>
            <option value='admin'>Admin</option>
            <option value='SYSTEM'>System</option>
            <option value='Customer.io'>Customer.io</option>
          </Form.Select>
        </Col>

        <Col className='d-flex mt-2'>
          <Trigger message='Reset Filters'>
            <Button
              variant='outline-success'
              className='align-self-center'
              size='sm'
              onClick={() => {
                setSearch('')
                setLimit(15)
                setPageNo(1)
                setRole('')
                dispatch(
                  getEmailLogsStart({
                    limit,
                    pageNo,
                    search,
                    userId,
                    role,
                    isTenant
                  })
                )
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
      </Row>

      <Table
        bordered
        striped
        responsive
        hover
        size='sm'
        className='text-center mt-4'
      >
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, idx) => (
              <th key={idx}>{h}</th>
            ))}
          </tr>
        </thead>

        <tbody id={emailLoading ? 'cover-spin' : ''}>
          {!emailLoading &&
            Boolean(emailLogs) &&
            emailLogs?.rows?.map((item, index) => {
              const { id, subjectTitle, senderEmail, role, status, createdAt } =
                item
              return (
                <tr key={index}>
                  <td>{id || 'NA'}</td>
                  <td>{subjectTitle || 'NA'}</td>
                  <td>{senderEmail || 'NA'}</td>
                  <td>{role?.toUpperCase() || 'NA'}</td>
                  <td
                    className={
                      status === 'success' ? 'text-success' : 'text-danger'
                    }
                  >
                    {status === 'success'
                      ? 'Sent Successfully'
                      : status || 'NA'}
                  </td>
                  <td>{createdAt ? getDateTime(createdAt) : 'NA'}</td>
                  <td>
                    <Trigger message='View Details'>
                      <Button
                        className='m-1'
                        size='sm'
                        variant='success'
                        onClick={() => {
                          setEmailLogDetails(item)
                          setEmailDetailsPopupStatus(true)
                        }}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                    </Trigger>
                  </td>
                </tr>
              )
            })}

          {(emailLogs?.count === 0 || !emailLogs?.count) && !emailLoading && (
            <tr>
              <td colSpan={8} className='text-danger text-center'>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {emailLogs?.count !== 0 && emailLogs?.count !== null && !emailLoading && (
        <PaginationComponent
          page={emailLogs?.count < pageNo ? setPageNo(1) : pageNo}
          totalPages={totalPages}
          setPage={setPageNo}
          limit={limit}
          setLimit={setLimit}
        />
      )}

      <Modal show={isEmailDetailsPopupShown} size='lg'>
        <Modal.Header>
          <h4>Email Details</h4>
        </Modal.Header>
        <Modal.Body>
          <p>
            Email Subject: <b>{emailLogDetails?.subjectTitle}</b>
            <br />
            Email Sent by: <b>{emailLogDetails?.senderEmail}</b> <br />
            Email Sender Role: <b>{emailLogDetails?.role}</b>
            <br />
            Email Sent at:{' '}
            <b>
              {emailLogDetails?.createdAt &&
                getDateTime(emailLogDetails?.createdAt)}
            </b>
            <br />
            Email Status:{' '}
            <b
              className={
                emailLogDetails?.status === 'success'
                  ? 'text-success'
                  : 'text-danger'
              }
            >
              {emailLogDetails?.status === 'success'
                ? 'Sent Successfully'
                : emailLogDetails?.status || 'NA'}
            </b>
            <br />
            {emailLogDetails?.content &&
              <>
                Email Content:- <br />
                <span>
                  {Parser(emailLogDetails?.content)}
                </span>
              </>}
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className='m-2'
            type='submit'
            variant='outline-primary'
            style={{ width: '75px' }}
            onClick={() => {
              setEmailDetailsPopupStatus(false)
              setEmailLogDetails({})
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default React.memo(EmailLogs)
