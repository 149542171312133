import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPaymentProviderStart } from '../../../../store/redux-slices/payment'
import { useParams } from 'react-router-dom'

const usePaymentModal = (isTenant, user) => {
  const [aggregators, setAggregators] = useState([])
  const [providers, setProviders] = useState([])
  const [providerMethods, setProviderMethods] = useState([])
  const [selectedAggregator, setSelectedAggregator] = useState('')
  const [selectedProvider, setSelectedProvider] = useState('')
  const { paymentProviders } = useSelector((state) => state.payment)
  const { managePaymentLoading: loading } = useSelector((state) => state.tenantUsers)

  const dispatch = useDispatch()
  const { userId } = useParams()
  useEffect(() => {
    if (user?.tenantId) {
      dispatch(getPaymentProviderStart({
        isTenant,
        paymentType: '',
        aggregator: '',
        tenant: '',
        group: '',
        status: '',
        tenantIds: [],
        category: '',
        adminId: user.parentId,
        tenantId: user.tenantId || ' '
      }))
    }
  }, [dispatch, user?.tenantId, isTenant])

  useEffect(() => {
    if (paymentProviders) {
      const uniqueAggregators = [...new Set(paymentProviders.map(provider => provider.aggregator))]
      setAggregators(uniqueAggregators)
    }
  }, [paymentProviders])

  useEffect(() => {
    if (selectedAggregator) {
      const filteredProviders = [...new Set(paymentProviders
        .filter(provider => provider.aggregator === selectedAggregator)
        .map(provider => provider.group)
      )]
      setProviders(filteredProviders)
    }
  }, [selectedAggregator, paymentProviders])

  useEffect(() => {
    if (selectedProvider) {
      const filteredProviderMethods = paymentProviders
        .filter(provider => provider.aggregator === selectedAggregator && provider.group === selectedProvider)
        .map(provider => provider.name)
      setProviderMethods(filteredProviderMethods)
    }
  }, [selectedProvider, paymentProviders, selectedAggregator])

  const handleAggregatorChange = (event, setFieldValue) => {
    setFieldValue('aggregatorMethod', event.target.value)
    setSelectedAggregator(event.target.value)
    setProviders([])
    setSelectedProvider('')
    setFieldValue('paymentProviderId', '')
    setProviderMethods([])
    setFieldValue('paymentMethod', '')
  }

  const handleProviderChange = (event, setFieldValue) => {
    setSelectedProvider(event.target.value)
    setProviderMethods([])
    setFieldValue('paymentMethod', '')
  }

  const handleProviderMethodChange = (event, setFieldValue) => {
    setFieldValue('paymentMethod', event.target.value)
    const selectedProviderMethodId = paymentProviders
      .filter(provider => provider.aggregator === selectedAggregator && provider.group === selectedProvider).filter(providerMethod => providerMethod.name === event.target.value)[0]?.paymentProviderId
    setFieldValue('paymentProviderId', selectedProviderMethodId)
  }

  return {
    userId,
    loading,
    aggregators,
    providers,
    providerMethods,
    selectedProvider,
    handleAggregatorChange,
    handleProviderChange,
    handleProviderMethodChange,
    dispatch
  }
}

export default usePaymentModal
