import React from 'react'
import { Table } from '@themesberg/react-bootstrap'

const Tenants = ({ tenantData }) => {
  return (
    <>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {['Id', 'Name', 'Domain', 'Primary Currency'].map((c) => (
              <th key={c}>{c}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {tenantData && (
            <tr key={tenantData.tenantId}>
              <td>{tenantData.tenantId}</td>
              <td>{tenantData.name}</td>
              <td>{tenantData.domain}</td>
              <td>EUR</td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {['Name', 'Primary', 'Value'].map((c) => (
              <th key={c}>{c}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {tenantData && (
            <tr key={tenantData.tenantId}>
              <td>Allowed Currencies</td>
              <td>{tenantData.primaryCurrency}</td>
              <td>
                {tenantData?.tenantConfig.allowedCurrencies.map(
                  (currency) => `${currency} `
                ).join(', ')}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {['Name', 'Value'].map((c) => (
              <th key={c}>{c}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {tenantData && (
            <tr key={tenantData.tenantId}>
              <td>Allowed Languages</td>
              <td>
                {tenantData?.tenantConfig.allowedLanguages.map(
                  (languages) => `${languages} `
                ).join(', ')}
              </td>
            </tr>
          )}
        </tbody>
      </Table> */}
    </>
  )
}
export default Tenants
