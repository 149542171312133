import React from 'react'
import { Card, Col, Row, Form, Button } from '@themesberg/react-bootstrap'
import useCredentials from '../hooks/useCredentials'
import { credKeyDiff, credKeyNames } from '../../../../utils/constants'

const FormGroup = ({
  createTenantData,
  setCreateTenantData,
  field,
  setValidation,
  validation,
  setAllValidation
}) => {
  const {
    isValueError,
    setIsValueError,
    isDescriptionError,
    setIsDescriptionError
  } = useCredentials({ createTenantData, setCreateTenantData })

  return (
    <Form.Group className='mb-3'>
      <Row>
        <Col>
          <Form.Label className='mt-2'>{credKeyNames?.[field] || field}</Form.Label>
        </Col>
        <Col>
          <InputElement
            field={field}
            createTenantData={createTenantData}
            setCreateTenantData={setCreateTenantData}
            isValue
            isValueError={isValueError}
            setIsValueError={setIsValueError}
            validation={validation}
            setValidation={setValidation}
            setAllValidation={setAllValidation}
          />
        </Col>
        <Col>
          <InputElement
            field={field}
            createTenantData={createTenantData}
            setCreateTenantData={setCreateTenantData}
            isValue={false}
            isDescriptionError={isDescriptionError}
            setIsDescriptionError={setIsDescriptionError}
            validation={validation}
            setValidation={setValidation}
            setAllValidation={setAllValidation}
          />
        </Col>
      </Row>
    </Form.Group>
  )
}

const InputElement = ({
  createTenantData,
  setCreateTenantData,
  isValue,
  field,
  setValidation,
  isValueError,
  setIsValueError,
  isDescriptionError,
  setIsDescriptionError,
  validation,
  setAllValidation
}) => {
  return (
    <>
      <Form.Control
        type='text'
        placeholder={isValue ? 'Enter Value' : 'Enter Description'}
        value={
          isValue
            ? createTenantData &&
              createTenantData?.value &&
              Object.keys(createTenantData?.value).includes(field) &&
              createTenantData.value[field]
            : createTenantData &&
              createTenantData?.value &&
              Object.keys(createTenantData?.description).includes(field) &&
              createTenantData.description[field]
        }
        onChange={(e) => {
          if (isValue) {
            if (e.target.value.length > 200) {
              setValidation({ ...validation, [field]: true })
              setAllValidation(true)
              setIsValueError(true)
              return
            } else {
              setValidation({ ...validation, [field]: false })
              setIsValueError(false)
            }
          } else {
            if (e.target.value.length > 200) {
              setValidation({ ...validation, [field]: true })
              setAllValidation(true)
              setIsDescriptionError(true)
              return
            } else {
              setValidation({ ...validation, [field]: false })
              setIsDescriptionError(false)
            }
          }
          isValue
            ? setCreateTenantData({
              ...createTenantData,
              value: {
                ...createTenantData.value,
                [field]: e.target.value.trim()
              }
            })
            : setCreateTenantData({
              ...createTenantData,
              description: {
                ...createTenantData.description,
                [field]: e.target.value
              }
            })
        }}
      />
      {isValueError && (
        <div className='text-danger'>length should be less than 200</div>
      )}
      {isDescriptionError && (
        <div className='text-danger'>length should be less than 200</div>
      )}
    </>
  )
}

const Credentials = ({
  createTenantData,
  setCreateTenantData,
  setSelectedTab,
  setFieldValidation,
  setMyData
}) => {
  const {
    validation,
    setAllValidation,
    setValidation,
    allValidation,
    tenantId,
    editTenant,
    createTenant
  } = useCredentials({ createTenantData, setCreateTenantData })

  return (
    <>
      <Card body>
        <Form>
          {Object.keys(credKeyDiff).map(credLabel => (
            <div key={credLabel}>
              <div className='mb-2' style={{ fontWeight: '800', textDecoration: 'underline' }}>{credLabel}</div>
              {credKeyDiff[credLabel].map((f, index) => (
                <FormGroup
                  key={index}
                  field={f}
                  createTenantData={createTenantData}
                  setCreateTenantData={setCreateTenantData}
                  validation={validation}
                  setValidation={setValidation}
                  setAllValidation={setAllValidation}
                />
              ))}
            </div>
          ))}

        </Form>
      </Card>
      <div className='d-flex justify-content-between mt-3'>
        <Button
          onClick={() => !tenantId ? setSelectedTab('providers') : setMyData()} variant='outline-warning'
        >
          {tenantId ? 'Cancel' : 'Previous'}
        </Button>
        <Button
          onClick={() => {
            setFieldValidation && setFieldValidation(true)
            tenantId
              ? editTenant({
                createTenantData,
                val: true,
                tenantId
              })
              : createTenant(createTenantData, setCreateTenantData, setSelectedTab)
          }}
          variant='outline-success'
          disabled={allValidation}
        >
          Submit
        </Button>
      </div>
    </>
  )
}
export default Credentials
