import React from 'react'
import { Tab, Tabs, Form, Col, Row } from '@themesberg/react-bootstrap'
import { createBannerKeys } from '../../../../utils/constants'
import { ErrorMessage } from 'formik'

const BannerForm = ({
  type,
  index,
  values,
  handleChange,
  tenantLanguages,
  selectedLanguageTab,
  setSelectedLanguageTab
}) => {
  const checkOneFilled = (language) => {
    const data = values?.banners?.[index]?.[language]
    return data?.bannerHeading || data?.bannerBtn || data?.bannerFooter
  }

  return (
    <Tabs
      className='nav-light'
      activeKey={selectedLanguageTab}
      onSelect={(tab) => setSelectedLanguageTab(tab)}
    >
      <Tab
        eventKey='EN'
        key='EN'
        title='EN'
        tabClassName={
          selectedLanguageTab !== 'EN'
            ? checkOneFilled('EN')
              ? 'email'
              : ''
            : 'email-active'
        }
      >
        <Row className='mt-4'>
          {Object.keys(createBannerKeys)?.map((bannerKey) => (
            <Col key={bannerKey}>
              <Form.Label style={{ fontSize: '14px' }} className='mx-2'>
                {bannerKey}:
              </Form.Label>
              <Form.Control
                type='text'
                as='textarea'
                disabled={type === 'view'}
                style={{ minWidth: '200px' }}
                name={`banners[${index}][${selectedLanguageTab}][${bannerKey}]`}
                value={values?.banners?.[index]?.[selectedLanguageTab]?.[bannerKey] || ''}
                onChange={handleChange}
              />
              <ErrorMessage
                component='div'
                name={`banners[${index}][${selectedLanguageTab}][${bannerKey}]`}
                className='text-danger'
              />
            </Col>))}
        </Row>
      </Tab>

      {tenantLanguages?.length > 0 &&
        tenantLanguages?.map((code) => code !== 'EN' && (
          <Tab
            eventKey={code}
            key={code}
            title={code}
            tabClassName={selectedLanguageTab !== code
              ? checkOneFilled(code) ? 'email' : ''
              : 'email-active'}
          >
            <Row className='mt-4'>
              {Object.keys(createBannerKeys)?.map((bannerKey) => (
                <Col key={bannerKey}>
                  <Form.Label style={{ fontSize: '14px' }} className='mx-2'>
                    {bannerKey}:
                  </Form.Label>
                  <Form.Control
                    type='text'
                    disabled={type === 'view'}
                    as='textarea'
                    style={{ minWidth: '200px' }}
                    name={`banners[${index}][${selectedLanguageTab}][${bannerKey}]`}
                    value={values?.banners?.[index]?.[selectedLanguageTab]?.[bannerKey] || ''}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    component='div'
                    name={`banners[${index}][${selectedLanguageTab}][${bannerKey}]`}
                    className='text-danger'
                  />
                </Col>
              ))}
            </Row>
          </Tab>
        )
        )}
    </Tabs>
  )
}

export default React.memo(BannerForm)
