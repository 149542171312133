import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPaymentCategoriesStart, reOrderPaymentCategoriesStart } from '../../../../store/redux-slices/payment'

const useCategoryReorder = ({ adminId, tenantId }) => {
  const isTenant = window.location.href.includes('tenant')
  const [paymentCategory, setPaymentCategory] = useState('')
  const dispatch = useDispatch()
  const { paymentCategoriesLoading, paymentCategories } = useSelector(state => state.payment)

  const [state, setState] = useState([])
  // const [categoryFilter, setCategoryFilter] = useState('')

  const reorder = (paymentCategories, startIndex, endIndex) => {
    const result = Array.from(paymentCategories)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  useEffect(() => {
    if (paymentCategories) {
      setState(paymentCategories)
    }

    return () => setState([])
  }, [paymentCategories])

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const rows = reorder(
      state,
      result.source.index,
      result.destination.index
    )
    setState(rows)
  }

  const handleSave = () => {
    dispatch(reOrderPaymentCategoriesStart({
      data: { order: state, paymentType: paymentCategory, adminId, tenantId },
      isTenant,
      adminId,
      tenantId
    }))
  }

  useEffect(() => {
    paymentCategory && dispatch(getPaymentCategoriesStart({ paymentType: paymentCategory, isTenant, adminId, tenantId }))
  }, [paymentCategory])

  return {
    paymentCategory,
    setPaymentCategory,
    handleSave,
    state,
    onDragEnd,
    loading: paymentCategoriesLoading
  }
}

export default useCategoryReorder
