import { faEdit, faRedo } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@themesberg/react-bootstrap'
import React from 'react'
import Trigger from '../OverlayTrigger'
import DailyLimit from './DailyLimit'
import './style.css'
import useResponsibleGaming from './useResponsibleGaming'
import useCheckPermission from '../../utils/checkPermission'
import SelfExclusion from './SelfExclusion'
import { ResetConfirmationModal } from '../ConfirmationModal'

export default ({ userLimits, isTenant, user = {}, currencyCode, pdfDownload }) => {
  const {
    data,
    limit,
    setData,
    setLimit,
    handleYes,
    resetModal,
    limitModal,
    updateLimit,
    limitLabels,
    setLimitModal,
    setResetModal,
    exclusionModal,
    setDisableUser,
    setExclusionModal
  } = useResponsibleGaming({ userLimits, isTenant })

  const { isHidden } = useCheckPermission()

  return (
    <>
      <h6 className='h4-overview text-center mt-3'>
        Limits <hr className='h4-hr mt-1' />
      </h6>
      <div className='div-overview limit'>
        {limitLabels?.map(({ label, value, minimum }) => {
          return (
            <div key={label}>
              <h6 className='px-2' style={{ fontSize: '14px' }}>
                {label}
              </h6>
              <div>
                <span>{value || 'Not Set'}</span>
                <Trigger message='Set Limit'>
                  <Button
                    variant='warning'
                    size='sm'
                    onClick={() => {
                      setLimit({ label, value, minimum })
                      setLimitModal(true)
                    }}
                    hidden={pdfDownload || isHidden({ module: { key: 'Users', value: 'SR' } })}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </Trigger>

                <Trigger message='Reset Limit'>
                  <Button
                    variant='danger'
                    size='sm'
                    disabled={!value}
                    onClick={() => {
                      setData(label)
                      setResetModal(true)
                    }}
                    hidden={pdfDownload || isHidden({ module: { key: 'Users', value: 'SR' } })}
                  >
                    <FontAwesomeIcon icon={faRedo} />
                  </Button>
                </Trigger>
              </div>
            </div>
          )
        })}

        <div key='Take A Break'>
          <h6 className='px-2' style={{ fontSize: '14px' }}>
            Take A Break
          </h6>
          <div>
            <span className='px-2'>
              {user?.selfExclusion
                ? `${Math.ceil(
                    Math.abs(new Date(user?.selfExclusion) - new Date()) /
                      (1000 * 60 * 60 * 24)
                  )} Days`
                : 'Not Set'}
            </span>
            <Trigger message='Set Take A Break'>
              <Button
                variant='warning'
                size='sm'
                onClick={() => {
                  setLimit({
                    label: 'Take A Break',
                    name: 'Time Period',
                    value: user?.selfExclusion
                      ? Math.ceil(
                        Math.abs(new Date(user?.selfExclusion) - new Date()) /
                            (1000 * 60 * 60 * 24)
                      )
                      : ''
                  })
                  setLimitModal(true)
                }}
                hidden={pdfDownload || isHidden({ module: { key: 'Users', value: 'SR' } })}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            </Trigger>

            <Trigger message='Reset Take A Break'>
              <Button
                variant='danger'
                size='sm'
                disabled={!user?.selfExclusion}
                onClick={() => {
                  setData('Take A Break')
                  setResetModal(true)
                }}
                hidden={pdfDownload || isHidden({ module: { key: 'Users', value: 'SR' } })}
              >
                <FontAwesomeIcon icon={faRedo} />
              </Button>
            </Trigger>
          </div>
        </div>

        <div key='Self Exclusion'>
          <h6 className='px-2' style={{ fontSize: '14px' }}>
            Self Exclusion
          </h6>
          <div>
            <span className='px-2'>
              {userLimits?.isSelfExclusionPermanent
                ? 'Permanent'
                : userLimits?.selfExclusion
                  ? `${Math.ceil(
                    Math.abs(new Date(userLimits?.selfExclusion) - new Date()) /
                      (1000 * 60 * 60 * 24 * 30)
                  )} Months (${userLimits?.selfExclusionType?.toUpperCase()})`
                  : 'Not Set'}
            </span>
            <Trigger message='Set Self Exclusion'>
              <Button
                variant='warning'
                size='sm'
                onClick={() => {
                  setLimit({
                    type: 'SELF_EXCLUSION',
                    days: userLimits?.isSelfExclusionPermanent
                      ? -1
                      : userLimits?.selfExclusion
                        ? Math.ceil(
                          Math.abs(
                            new Date(userLimits?.selfExclusion) - new Date()
                          ) /
                            (1000 * 60 * 60 * 24 * 30)
                        )
                        : '',
                    portal: userLimits?.selfExclusionType
                  })
                  setExclusionModal(true)
                }}
                hidden={pdfDownload || isHidden({ module: { key: 'Users', value: 'SR' } })}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            </Trigger>

            <Trigger message='Reset Self Exclusion'>
              <Button
                variant='danger'
                size='sm'
                disabled={
                  !userLimits?.isSelfExclusionPermanent &&
                  !userLimits?.selfExclusion
                }
                onClick={() => {
                  setData('Self Exclusion')
                  setResetModal(true)
                }}
                hidden={pdfDownload || isHidden({ module: { key: 'Users', value: 'SR' } })}
              >
                <FontAwesomeIcon icon={faRedo} />
              </Button>
            </Trigger>
          </div>
        </div>
        <div key='Session Limit'>
          <h6 className='px-2' style={{ fontSize: '14px' }}>
            Session Limit
          </h6>
          <div>
            <span className='px-2'>{userLimits?.timeLimit || 'Not Set'}</span>
            <Trigger message='Set Limit'>
              <Button
                variant='warning'
                size='sm'
                onClick={() => {
                  setLimit({
                    label: 'Session Limit',
                    name: 'Time Period',
                    value: userLimits?.timeLimit || ''
                  })
                  setLimitModal(true)
                }}
                hidden={pdfDownload || isHidden({ module: { key: 'Users', value: 'SR' } })}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            </Trigger>

            <Trigger message='Reset Limit'>
              <Button
                variant='danger'
                size='sm'
                disabled={!userLimits?.timeLimit}
                onClick={() => {
                  setData('Session Limit')
                  setResetModal(true)
                }}
                hidden={pdfDownload || isHidden({ module: { key: 'Users', value: 'SR' } })}
              >
                <FontAwesomeIcon icon={faRedo} />
              </Button>
            </Trigger>
          </div>
        </div>

        {user?.userLimit?.realityLimit !== null &&
          <div key='Game Play Limit'>
            <h6 className='px-2' style={{ fontSize: '14px' }}>
              Game Play Limit
            </h6>
            <div>
              <span className='px-2'>
                {user?.userLimit?.realityLimit !== null
                  ? `${user?.userLimit?.realityLimit} Minute(s)`
                  : 'Not Set'}
              </span>
              <Trigger message='Set Game Play Limit'>
                <Button
                  variant='warning'
                  size='sm'
                  onClick={() => {
                    setLimit({
                      label: 'Game Play Limit',
                      name: 'Time Period',
                      value: user?.userLimit?.realityLimit !== null
                        ? user?.userLimit?.realityLimit
                        : ''
                    })
                    setLimitModal(true)
                  }}
                  hidden={pdfDownload || isHidden({ module: { key: 'Users', value: 'SR' } })}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              </Trigger>
            </div>
          </div>}
      </div>

      {limitModal && (
        <DailyLimit
          show={limitModal}
          setShow={setLimitModal}
          limit={limit}
          updateLimit={
            limit?.label === 'Take A Break' || limit?.label === 'Session Limit'
              ? setDisableUser
              : updateLimit
          }
          currencyCode={currencyCode}
        />
      )}

      {exclusionModal && (
        <SelfExclusion
          show={exclusionModal}
          setShow={setExclusionModal}
          limit={limit}
          updateLimit={setDisableUser}
        />
      )}

      {resetModal && (
        <ResetConfirmationModal
          show={resetModal}
          setShow={setResetModal}
          handleYes={handleYes}
          data={data}
        />
      )}
    </>
  )
}
