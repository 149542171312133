import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getAdminUserDetailsStart } from '../../../../store/redux-slices/adminUser'

const useEditTenantAdmin = () => {
  const { adminUserId } = useParams()
  const [selectedTab, setSelectedTab] = useState('overview')
  const { adminUserDetails, loading } = useSelector((state) => state.adminUser)
  const dispatch = useDispatch()
  const ownerDetailConstants = [
    { key: 'ID', value: 'adminUserId' },
    { key: 'First Name', value: 'firstName' },
    { key: 'Last Name', value: 'lastName' },
    { key: 'Email', value: 'email' },
    { key: 'Phone', value: 'phone' },
    { key: 'Username', value: 'agentName' }
  ]

  useEffect(() => {
    dispatch(getAdminUserDetailsStart({ adminUserId }))
  }, [adminUserId])

  return {
    loading,
    adminUserDetails,
    selectedTab,
    setSelectedTab,
    ownerDetailConstants
  }
}

export default useEditTenantAdmin
