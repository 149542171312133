import * as Yup from 'yup'

export const uploadBannerSchema = () => Yup.object().shape({
  adminId: Yup.string().required('Owner Id Required'),
  tenantId: Yup.string().required('Tenant ID Required'),
  key: Yup.string().required('Banner Type Required'),
  banners: Yup.array().of(
    Yup.object().shape({
      bannerImage: Yup.mixed().test('File Size',
        'File Size Should be Less Than 1MB', (value) => !value || (value && value.size <= 1024 * 1024))
        .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
          (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml', 'image/svg'].includes(value.type))).nullable(),
      bannerBackground: Yup.mixed().test('File Size',
        'File Size Should be Less Than 1MB', (value) => !value || (value && value.size <= 1024 * 1024))
        .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
          (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml', 'image/svg'].includes(value.type))).nullable()
    }))
})

export const uploadTABannerSchema = () => Yup.object().shape({
  key: Yup.string().required('Banner Type Required'),
  banners: Yup.array().of(
    Yup.object().shape({
      bannerImage: Yup.mixed().test('File Size',
        'File Size Should be Less Than 1MB', (value) => !value || (value && value.size <= 1024 * 1024))
        .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
          (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml', 'image/svg'].includes(value.type))).nullable(),
      bannerBackground: Yup.mixed().test('File Size',
        'File Size Should be Less Than 1MB', (value) => !value || (value && value.size <= 1024 * 1024))
        .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
          (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml', 'image/svg'].includes(value.type))).nullable()
    }))
})
