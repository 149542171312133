import * as Yup from 'yup'

export const createWageringTemplate = Yup.object().shape({
  name: Yup.string().required('Template Name Required'),
  customValue: Yup.string().required('Custom Value Required')
})

export const editWageringTemplate = Yup.object().shape({
  name: Yup.string().required('Template Name Required')
})
