import {
  Card,
  Button,
  Form,
  Col,
  Row,
  InputGroup,
  Spinner
} from '@themesberg/react-bootstrap'
import React from 'react'
import { updateTenantThemeStart } from '../../../store/redux-slices/tenantcredentials'
import useTenantThemeSettings from './hooks/useTenantThemeSettings'
import Preloader from '../../../components/Preloader'
import useCheckPermission from '../../../utils/checkPermission'

const TenantThemeSettings = () => {
  const {
    dispatch,
    customTheme,
    setCustomTheme,
    preview,
    theme,
    themeData,
    error,
    isError,
    req,
    setReq,
    setThemeData,
    handleImagePreview,
    loading
  } = useTenantThemeSettings()
  const { isHidden } = useCheckPermission()
  return (
    <>
      {loading
        ? <Preloader />
        : (
          <>
            <Row>
              <Col sm={8}>
                <h3>Theme Settings</h3>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form>
                  <Form.Group id='name' className='m-4 file-loading'>
                    <Form.Label>logo</Form.Label>
                    <span className='text-danger'>*</span>
                    <InputGroup className='w-50'>
                      <Form.Control
                        type='file'
                        onChange={(e) => {
                          handleImagePreview(e)
                        }}
                      />
                    </InputGroup>
                    {isError && (
                      <span className='text-danger mx-auto'>{error}</span>
                    )}
                    {req && (
                      <span className='text-danger'>Logo is Required</span>
                    )}
                  </Form.Group>
                </Form>
              </Col>

              <Col>
                {preview?.image_preview && (
                  <img
                    src={preview?.image_preview}
                    width='300'
                    height='300'
                    className='mt-2 border-0'
                  />
                )}
              </Col>
            </Row>

            {themeData && (
              <>
                <Card>
                  <Card.Header>Select Theme</Card.Header>
                  <Card body>
                    <div className='row'>
                      {themeData?.map((item) => {
                        return (
                          <div
                            className='text-center theme-container align-items-center'
                            key={item.themeId}
                          >
                            <label
                              className={`color-label ${theme?.themeId === item.themeId &&
                                !customTheme.isCustom
                                ? 'active'
                                : ''
                              }`}
                            >
                              {item.themeName}
                            </label>
                            <div
                              className={`theme-item ${theme?.themeId === item.themeId &&
                                !customTheme.isCustom
                                ? 'active'
                                : ''
                              }`}
                              key={item?.themeName}
                            >
                              <div className='theme-back'>
                                <div
                                  className='theme'
                                  onClick={() => setThemeData(item)}
                                >
                                  <div className='circle rounded-circle d-flex'>
                                    <div
                                      className='half left'
                                      style={{
                                        backgroundColor: item.primaryColor
                                      }}
                                    />
                                    <div
                                      className='half right'
                                      style={{
                                        backgroundColor: item.secondaryColor
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}

                      {/* custom theme */}
                      <div className='text-center theme-container align-items-center'>
                        <label className='color-label'>Custom Theme</label>
                        <div
                          className={`theme-item ${customTheme.isCustom ? 'active' : ''
                          }`}
                        >
                          <div className='theme-back'>
                            <div
                              className='theme'
                              onClick={() =>
                                setCustomTheme({
                                  ...customTheme,
                                  isCustom: true
                                })}
                            >
                              <div className='circle rounded-circle d-flex'>
                                <div
                                  className='half left'
                                  style={{ backgroundColor: '#105AFB' }}
                                />
                                <div
                                  className='half right'
                                  style={{ backgroundColor: '#FC6065' }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Card>
                <Card.Header className='mt-2'>
                  <Card.Header>Theme</Card.Header>
                  <Card body>
                    <Form>
                      <Form.Group>
                        <Row className='g-2'>
                          <Col xs={6}>
                            <Form.Label>Theme Mode</Form.Label>
                          </Col>
                          <Col xs={6}>
                            <Form.Group
                              className='mb-3'
                              controlId='formBasicCheckbox'
                            >
                              {customTheme?.isCustom
                                ? (
                                  <>
                                    <Form.Check
                                      inline
                                      label='Light'
                                      name='themeMode'
                                      type='radio'
                                      id='inline-radio-1'
                                      onClick={() =>
                                        setCustomTheme({
                                          ...customTheme,
                                          themeMode: 'Light'
                                        })}
                                    />
                                    <Form.Check
                                      inline
                                      label='Dark'
                                      name='themeMode'
                                      type='radio'
                                      id='inline-radio-2'
                                      onClick={() =>
                                        setCustomTheme({
                                          ...customTheme,
                                          themeMode: 'Dark'
                                        })}
                                    />
                                  </>
                                  )
                                : (
                                  <Form.Check
                                    type='checkbox'
                                    checked
                                    disabled
                                    label={theme?.themeMode}
                                  />
                                  )}
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form.Group>

                      <Form.Group className='input-color'>
                        <Row>
                          <Col xs={6}>
                            <Form.Label>Primary Color</Form.Label>
                          </Col>
                          <Col xs={6}>
                            <Form.Control
                              type='color'
                              value={theme?.primaryColor}
                              key={theme?.primaryColor}
                              disabled={!customTheme.isCustom}
                              onChange={(e) =>
                                setCustomTheme({
                                  ...customTheme,
                                  primaryColor: e.target.value
                                })}
                            />
                          </Col>
                        </Row>
                      </Form.Group>

                      <Form.Group className='input-color'>
                        <Row>
                          <Col md={6} xs={6}>
                            <Form.Label>Secondary Color</Form.Label>
                          </Col>
                          <Col md={6} xs={6}>
                            <Form.Control
                              type='color'
                              disabled={!customTheme.isCustom}
                              value={theme?.secondaryColor}
                              key={theme?.secondaryColor}
                              onChange={(e) =>
                                setCustomTheme({
                                  ...customTheme,
                                  secondaryColor: e.target.value
                                })}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </Form>
                  </Card>
                </Card.Header>
              </>
            )}
            <div className='d-flex justify-content-between mt-3'>
              {customTheme?.isCustom
                ? (
                  <Button
                    variant='success'
                    hidden={isHidden({ module: { key: 'TenantSettings', value: 'U' } })}
                    onClick={() => {
                      if (preview?.image_preview === undefined) {
                        setReq(true)
                      }
                      preview.image_preview &&
                      dispatch(
                        updateTenantThemeStart({
                          logo: preview?.image_file,
                          themeId: null,
                          primaryColor: customTheme.primaryColor,
                          secondaryColor: customTheme.secondaryColor,
                          themeMode: customTheme.themeMode
                        })
                      )
                    }}
                  >
                    Submit
                  </Button>
                  )
                : (
                  <Button
                    variant='outline-success'
                    disabled={isError}
                    hidden={isHidden({ module: { key: 'TenantSettings', value: 'U' } })}
                    onClick={() => {
                      if (preview?.image_preview === undefined) {
                        setReq(true)
                      }
                      preview?.image_preview &&
                      dispatch(
                        updateTenantThemeStart({
                          logo: preview?.image_file,
                          themeId: theme.themeId,
                          primaryColor: theme.primaryColor,
                          secondaryColor: theme.secondaryColor,
                          themeMode:
                            theme.themeMode[0] +
                            theme.themeMode.slice(1).toLowerCase()
                        })
                      )
                    }}
                  >
                    Submit
                    {loading && (
                      <Spinner
                        as='span'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    )}
                  </Button>
                  )}
            </div>
          </>
          )}
    </>
  )
}

export default TenantThemeSettings
