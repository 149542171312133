import React from 'react'
import { Col, Row, Table, Form, Button } from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/pro-solid-svg-icons'
import useHub88PrepaidList from './hooks/useHub88PrepaidList'
import { checkIsTenant } from '../../../utils/constants'
import ClientFilter from '../../../components/ClientFilter'
import PortalFilter from '../../../components/PortalFilter'
import { InlineLoader } from '../../../components/Preloader'
import PaginationComponent from '../../../components/Pagination'
import ActionTypeSelect from '../../../components/ActionTypeSelect'
import { safeStringify } from '../../../utils/helpers'
import Trigger from '../../../components/OverlayTrigger'
import { toast } from '../../../components/Toast'
import CopyToClipboard from 'react-copy-to-clipboard'

const Hub88PrepaidList = () => {
  const {
    page,
    limit,
    search,
    setPage,
    loading,
    setLimit,
    setSearch,
    allCurrencies,
    selectedClient,
    selectedPortal,
    selectedGameIds,
    selectedCurrency,
    setSelectedClient,
    allowedCurrencies,
    setSelectedPortal,
    setSelectedGameIds,
    selectedGamesArray,
    setSelectedCurrency,
    hub88PrepaidListData,
    setSelectedGamesArray
  } = useHub88PrepaidList()

  return (
    <>
      <Row>
        <Col>
          <h3>
            Hub88 Prepaids List
          </h3>
        </Col>

        {!checkIsTenant() && (
          <Col className='d-flex align-self-center mt-2' lg={3}>
            <ClientFilter
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              setSelectedPortal={setSelectedPortal}
            />
          </Col>
        )}
        {selectedClient && (
          <Col className='d-flex align-self-center mt-2' lg={3}>
            <PortalFilter
              selectedClient={selectedClient}
              selectedPortal={selectedPortal}
              setSelectedPortal={setSelectedPortal}
            />
          </Col>
        )}
      </Row>

      {!(!checkIsTenant() && !selectedPortal) &&
        <Row className='d-flex justify-content-between'>
          <Col xs='auto'>
            <Row>
              <Col xs='auto' className='mt-3'>
                <Form.Control
                  type='search'
                  name='search'
                  placeholder='Search'
                  size='sm'
                  style={{ maxWidth: '220px' }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Col>

              <Col xs='auto' className='mt-3'>
                {(checkIsTenant()
                  ? allowedCurrencies
                  : allCurrencies?.count > 0) &&
                    <ActionTypeSelect
                      selectedAction={safeStringify(selectedCurrency)}
                      selectOptions={(checkIsTenant()
                        ? allowedCurrencies
                        : allCurrencies?.rows)?.reduce((result, currentValue) => {
                        currentValue !== null && result.push({ label: checkIsTenant() ? currentValue : currentValue?.code, value: checkIsTenant() ? currentValue : currentValue?.code })
                        return result
                      }, [])}
                      setSelectedAction={setSelectedCurrency}
                      placeholder='Currency'
                    />}
              </Col>
            </Row>
          </Col>

          <Col className='mt-3 d-flex justify-content-end' xs='auto'>
            <Trigger message='Copy This Game Array to ClipBoard'>
              <CopyToClipboard
                text={safeStringify(selectedGamesArray)}
                onCopy={() => {
                  toast('Copied To ClipBoard', 'success')
                }}
              >
                <Button
                  className='copy d-flex align-items-center'
                  variant='light'
                >
                  <FontAwesomeIcon icon={faCopy} />
                </Button>
              </CopyToClipboard>
            </Trigger>
          </Col>
        </Row>}

      {!checkIsTenant() && !selectedPortal
        ? <p className='text-danger d-flex justify-content-center mt-3'>Please Select Tenant</p>
        : loading
          ? <InlineLoader />
          : (
            <Table
              bordered
              striped
              responsive
              hover
              size='sm'
              className='text-center mt-4'
            >
              <thead className='thead-dark'>
                <tr>
                  <th>Select</th>
                  <th>Name</th>
                  <th>Game Code</th>
                  <th>Currency Code</th>
                  <th>Master ID</th>
                  <th>Bet Value</th>
                  <th>Prepaid UUID</th>
                </tr>
              </thead>

              <tbody id={loading ? 'cover-spin' : ''}>
                {hub88PrepaidListData &&
                !loading &&
                hub88PrepaidListData?.rows?.map(
                  ({
                    id,
                    gameCode,
                    currency,
                    betCount,
                    betValue,
                    prepaidUuid,
                    name,
                    masterCasinoGameId
                  }) => {
                    return (
                      <tr key={`transaction-list ${id}`}>
                        <td>
                          <input
                            defaultChecked={selectedGameIds.includes(prepaidUuid)}
                            checked={selectedGameIds.includes(prepaidUuid)}
                            type='checkbox'
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSelectedGameIds(ids => [...ids, prepaidUuid])
                                setSelectedGamesArray(ids => [...ids, {
                                  game_code: gameCode,
                                  currency,
                                  bet_count: betCount,
                                  bet_value: betValue,
                                  prepaid_uuid: prepaidUuid
                                }])
                              } else {
                                setSelectedGameIds(ids => ids.filter(ids => ids !== prepaidUuid))
                                setSelectedGamesArray(ids => ids.filter(({ prepaid_uuid }) => prepaid_uuid !== prepaidUuid))
                              }
                            }}
                          />
                        </td>
                        <td>{name}</td>
                        <td>{gameCode}</td>
                        <td>{currency}</td>
                        <td>{masterCasinoGameId || '-'}</td>
                        <td>{betValue / 100000}</td>
                        <td>{prepaidUuid}</td>
                      </tr>
                    )
                  })}

                {hub88PrepaidListData?.count === 0 && !loading && (
                  <tr>
                    <td colSpan={10} className='text-danger text-center'>
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            )}

      {hub88PrepaidListData?.count > 0 && !loading && (
        <PaginationComponent
          page={hub88PrepaidListData?.count < page ? setPage(1) : page}
          totalPages={Math.ceil(hub88PrepaidListData?.count / limit)}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}

      {selectedGamesArray?.length > 0 &&
        <>
          <h3 className='mt-4'>Selected Games Array</h3>
          <Table
            bordered
            striped
            responsive
            hover
            size='sm'
            className='text-center mt-2'
          >
            <thead className='thead-dark'>
              <tr>
                <th>Select</th>
                <th>Game Code</th>
                <th>Currency Code</th>
                <th>Bet Count</th>
                <th>Bet Value</th>
                <th>Prepaid UUID</th>
              </tr>
            </thead>

            <tbody id={loading ? 'cover-spin' : ''}>
              {selectedGamesArray &&
                selectedGamesArray?.map(
                  ({
                    game_code,
                    currency,
                    bet_count,
                    bet_value,
                    prepaid_uuid
                  }) => {
                    return (
                      <tr key={`transaction-list ${prepaid_uuid}`}>
                        <td>
                          <input
                            defaultChecked={selectedGameIds.includes(prepaid_uuid)}
                            checked={selectedGameIds.includes(prepaid_uuid)}
                            type='checkbox'
                            onClick={(e) => {
                              if (e.target.checked) {
                                setSelectedGameIds(ids => [...ids, prepaid_uuid])
                                setSelectedGamesArray(ids => [...ids, {
                                  game_code,
                                  currency,
                                  bet_count,
                                  bet_value,
                                  prepaid_uuid
                                }])
                              } else {
                                setSelectedGameIds(ids => ids.filter(ids => ids !== prepaid_uuid))
                                setSelectedGamesArray(ids => ids.filter(({ prepaid_uuid: code }) => code !== prepaid_uuid))
                              }
                            }}
                          />
                        </td>
                        <td>{game_code}</td>
                        <td>{currency}</td>
                        <td>{bet_count || '-'}</td>
                        <td>{bet_value / 100000}</td>
                        <td>{prepaid_uuid}</td>
                      </tr>
                    )
                  })}
            </tbody>
          </Table>
        </>}
    </>
  )
}

export default Hub88PrepaidList
