import React, { useState } from 'react'
import { Tabs, Tab, Row, Col } from '@themesberg/react-bootstrap'
import Tenants from '../CreateTenant/components/Tenant'
import Credentials from '../CreateTenant/components/Credentials'
import useEditTenant from './hooks/useEditTenant'
import Preloader from '../../../components/Preloader'
import { toast } from '../../../components/Toast'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft } from '@fortawesome/pro-solid-svg-icons'
// import Providers from '../CreateTenant/components/Providers'

const UpdateTenant = () => {
  const {
    selectedTab,
    setSelectedTab,
    tenantDetails,
    createTenantData,
    setCreateTenantData,
    loading,
    adminUserDetails,
    navigate,
    setMyData
  } = useEditTenant()

  const [fieldsValidation, setFieldValidation] = useState(true)
  const [fieldListner, setFieldListner] = useState(true)

  const checkFormState = (tab) => {
    if (fieldsValidation && fieldListner) {
      setSelectedTab(tab)
    } else {
      toast('Please submit the form or resolve errors before moving to another tab', 'error')
    }
  }

  return (
    <>
      <Row>
        <Col className='d-flex'>
          <FontAwesomeIcon icon={faArrowCircleLeft} className='mt-1 cursor-pointer' onClick={() => navigate(-1)} />
          &nbsp;<h3>Tenant:</h3>
          <h3> &nbsp;{tenantDetails && tenantDetails?.name}</h3>
        </Col>
        <Col className='d-flex justify-content-end'>
          <h3>Owner : {adminUserDetails?.firstName}{' '}{adminUserDetails?.lastName}</h3>
        </Col>
      </Row>

      {loading
        ? <Preloader />
        : (
          <Tabs
            activeKey={selectedTab}
            onSelect={(k) => selectedTab !== k && checkFormState(k)}
            className='nav-light'
          >
            <Tab eventKey='tenant' title='Tenant'>
              <div className='mt-5'>
                <Tenants
                  createTenantData={createTenantData}
                  setCreateTenantData={setCreateTenantData}
                  setSelectedTab={setSelectedTab}
                  setFieldValidation={setFieldValidation}
                  setMyData={setMyData}
                />
              </div>
            </Tab>

            {/* <Tab eventKey='providers' title='Providers'>
              <div className='mt-5'>
                <Providers
                  createTenantData={createTenantData}
                  setCreateTenantData={setCreateTenantData}
                  setSelectedTab={setSelectedTab}
                />
              </div>
            </Tab> */}

            <Tab eventKey='credentials' title='Credentials'>
              <div className='mt-5'>
                <Credentials
                  createTenantData={createTenantData}
                  setCreateTenantData={(e) => {
                    setCreateTenantData(e)
                  }}
                  setSelectedTab={setSelectedTab}
                  setFieldValidation={setFieldListner}
                  setMyData={setMyData}
                />
              </div>
            </Tab>

          </Tabs>)}
    </>
  )
}

export default UpdateTenant
