import React from 'react'
import { Button, Row, Col, Form } from '@themesberg/react-bootstrap'

import CountriesList from '../CountriesList'

const AddRestrictPCountries = ({
  unRestrictedCountries,
  limit,
  page,
  setLimit,
  setPage,
  search,
  setSearch,
  totalPages,
  addCountries,
  selectedCountries,
  removeCountries,
  addRestrictedCountries
}) => {
  return (
    <>
      <Row>
        <Col xs={4}>
          <Form.Label>
            <h5>Countries you add will appear here</h5>
          </Form.Label>
        </Col>

        <Col className='text-right'>
          <Button
            variant='outline-success'
            className='f-right'
            disabled={selectedCountries.count === 0}
            onClick={addRestrictedCountries}
          >
            Submit
          </Button>
        </Col>
      </Row>

      <CountriesList
        disablePagination
        countries={selectedCountries}
        hasActions
        hasRemoveGamesAction
        removeCountries={removeCountries}
      />

      <Row className='mt-3'>
        <Col xs={4}>
          <Form.Label>
            <h5>Unrestricted Countries</h5>
          </Form.Label>
        </Col>
        <Col>
          <div className='d-flex align-items-center justify-content-end'>
            <Form.Label className='mb-0 mx-2'>
              Search
            </Form.Label>
            <Form.Control
              type='search'
              name='search'
              size='sm'
              value={search}
              style={{ maxWidth: '230px' }}
              placeholder='Search'
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </Col>
      </Row>

      <CountriesList
        limit={limit}
        setLimit={setLimit}
        page={page}
        setPage={setPage}
        search={search}
        setSearch={setSearch}
        countries={unRestrictedCountries}
        totalPages={totalPages}
        hasActions
        hasAddGamesAction
        addCountries={addCountries}
      />
    </>
  )
}

export default AddRestrictPCountries
