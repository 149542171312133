import React from 'react'
import {
  Button,
  Form as BForm,
  Row,
  Col,
  InputGroup
} from '@themesberg/react-bootstrap'
import { Formik, Form, ErrorMessage } from 'formik'
import ClientFilter from '../ClientFilter'
import PortalFilter from '../PortalFilter'
import { rgThresholdSchema, amlThresholdSchema } from './scheme'
import './SetThreshold.scss'
import { isTenant } from '../../utils/constants'

const SetThresholds = ({
  setThresholdHandler,
  setSelectedClient,
  setSelectedPortal,
  selectedClient,
  selectedPortal,
  thresholdData,
  type,
  forms
}) => {
  return (
    <>
      <Row className='rg-header'>
        <Col>
          <h3>Set Thresholds</h3>
        </Col>
        {!isTenant && (
          <Col className='d-flex align-items-center'>
            <ClientFilter
              setSelectedClient={setSelectedClient}
              setSelectedPortal={setSelectedPortal}
              selectedClient={selectedClient}
              hasAllOptions={false}
            />

            <PortalFilter
              setSelectedPortal={setSelectedPortal}
              selectedPortal={selectedPortal}
              selectedClient={selectedClient}
              cms={selectedClient === ''}
              hasAllOptions={false}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Formik
          enableReinitialize
          initialValues={{
            wageringThresholdAmount: thresholdData?.wageringThresholdAmount || '',
            winThresholdAmount: thresholdData?.winThresholdAmount || '',
            winThresholdDays: thresholdData?.winThresholdDays || '',
            wageringThresholdDays: thresholdData?.wageringThresholdDays || '',
            totalTransactionAmount: thresholdData?.totalTransactionAmount || '',
            rollingPeriodTransactionAmount: thresholdData?.rollingPeriodTransactionAmount || '',
            singleTransactionAmount: thresholdData?.singleTransactionAmount || '',
            rollingPeriod: thresholdData?.rollingPeriod || ''
          }}
          validationSchema={type === 'amlModule' ? amlThresholdSchema : rgThresholdSchema}
          onSubmit={(formValues) => {
            setThresholdHandler(formValues)
          }}
        >
          {({ values, handleChange, handleSubmit, handleBlur }) => (
            <Form className='threshold-form'>
              {forms.map(({ textLabel, name, placeholder, disabled, time, timePlaceHolder, periodName, dayLabel }) => (
                <div key={name} className='parent-div'>
                  <div style={{ marginBottom: name === 'singleTransactionAmount' && '3rem' }} className='child-div'>
                    <BForm.Label>{textLabel}</BForm.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        EUR
                      </InputGroup.Text>
                      <BForm.Control
                        type='number'
                        name={name}
                        className='m-0'
                        placeholder={placeholder}
                        value={values[name]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={disabled}
                      />
                      <ErrorMessage component='div' name={name} className='text-danger' />
                    </InputGroup>
                  </div>
                  {time && (
                    <div className='child-div'>
                      <BForm.Label>{dayLabel}</BForm.Label>
                      <InputGroup>
                        <BForm.Control
                          type='number'
                          className='m-0'
                          name={periodName}
                          placeholder={timePlaceHolder}
                          value={values[periodName]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={disabled}
                        />
                        <ErrorMessage component='div' name={periodName} className='text-danger' />
                      </InputGroup>
                    </div>)}
                </div>
              ))}
              <Row>
                <Col className='d-flex justify-content-end align-items-end mb-3'>
                  <Button
                    className='m-2'
                    variant='outline-success'
                    disabled={isTenant ? false : !selectedPortal}
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Row>
    </>
  )
}

export default SetThresholds
