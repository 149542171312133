import React from 'react'
import { Tabs, Tab, Row, Col, Button, Breadcrumb } from '@themesberg/react-bootstrap'
import Tenant from './components/Tenant'
import Credentials from './components/Credentials'
import useTenantDetails from './hooks/useTenantDetails'
import Preloader from '../../../components/Preloader'
import Casino from './components/Casino'
import useCheckPermission from '../../../utils/checkPermission'
import Loyalty from './components/Loyalty'
import AffiliateSystem from '../../Tenant/TenantCredentials/components/AffiliateSystem'

const TenantDetails = () => {
  const {
    selectedTab,
    tenantDetails,
    tenantId,
    navigate,
    setSelectedTab,
    loading,
    adminId,
    edit,
    setEdit
  } = useTenantDetails()
  const { isHidden } = useCheckPermission()
  return (
    <>
      {loading
        ? <Preloader />
        : (
          <>
            <Row>
              <Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate('/super-admin/tenants/owners')}>Tenant Owner</Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate(`/super-admin/tenants/${adminId}`)}>Tenants</Breadcrumb.Item>
                <Breadcrumb.Item active>Tenant:- {tenantDetails && tenantDetails?.name}</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
            <Row>
              <Col className='d-flex'>
                <h3>Tenant:</h3>
                <h3> &nbsp;{tenantDetails && tenantDetails?.name}</h3>
              </Col>
            </Row>

            <Tabs
              activeKey={selectedTab}
              onSelect={(tab) => setSelectedTab(tab)}
              className='nav-light'
            >
              <Tab eventKey='tenant' title='Tenant'>
                <div className='mt-5'>
                  <Row className='mt-3 d-flex flex-row-reverse text-right'>
                    <Col>
                      <Button
                        className='mb-2 btn-sm'
                        variant='outline-warning'
                        onClick={() =>
                          navigate(`/super-admin/tenants/edit/${adminId}/${tenantId}`)}
                        hidden={isHidden({ module: { key: 'Tenant', value: 'U' } })}
                      >
                        Edit
                      </Button>
                    </Col>
                  </Row>
                  <Tenant tenantData={tenantDetails} />
                </div>
              </Tab>

              {!isHidden({ module: { key: 'TenantCredentials', value: 'R' } }) &&
                <Tab eventKey='credentials' title='Credentials'>
                  <div className='mt-5'>
                    <Row className='mt-3 d-flex flex-row-reverse text-right'>
                      <Col>
                        <Button
                          className='mb-2'
                          variant='outline-success'
                          size='sm'
                          onClick={() =>
                            navigate(`/super-admin/tenant/${tenantId}/credential`, {
                              state: null
                            })}
                          hidden={isHidden({ module: { key: 'TenantCredentials', value: 'C' } })}
                        >
                          Create
                        </Button>
                      </Col>
                    </Row>
                    <Credentials tenantData={tenantDetails} adminId={adminId} />
                  </div>
                </Tab>}

              {!isHidden({ module: { key: 'CasinoManagement', value: 'R' } }) &&
                <Tab eventKey='casino' title='Casino'>
                  <div className='mt-5'>
                    <Casino />
                  </div>
                </Tab>}

              <Tab eventKey='affiliate' title='Affiliate System'>
                <div className='mt-5'>
                  <AffiliateSystem tenantDetails={tenantDetails} />
                </div>
              </Tab>

              <Tab eventKey='loyalty' title='Loyalty Points'>
                <div className='mt-4'>
                  <Row className='d-flex flex-row-reverse text-right'>
                    <Col>
                      {!edit &&
                        <Button
                          className='btn-sm'
                          variant='outline-warning'
                          onClick={() => setEdit(true)}
                          hidden={isHidden({ module: { key: 'Tenant', value: 'U' } })}
                        >
                          Edit
                        </Button>}
                    </Col>
                  </Row>
                  <Loyalty
                    tenantData={tenantDetails?.tenantConfig?.loyaltyLevel}
                    edit={edit}
                    setEdit={setEdit}
                  />
                </div>
              </Tab>
            </Tabs>
          </>
          )}
    </>
  )
}

export default TenantDetails
