import { takeLatest, put } from 'redux-saga/effects'
import {
  getAllAmlPlayerListingStart,
  getAllAmlPlayerListingSuccess,
  getAllAmlPlayerListingFailure,
  getAmlThresholdDataStart,
  getAmlThresholdDataSuccess,
  getAmlThresholdDataFailure,
  setAmlThresholdDataStart,
  setAmlThresholdDataFailure,
  setAmlThresholdDataSuccess
} from '../redux-slices/amlModule'
import { toast } from '../../components/Toast'
import { getAmlPlayers, getAmlThreshold, setAmlThreshold } from '../../utils/apiCalls'

export default function * rgModuleWatcher () {
  yield takeLatest(getAllAmlPlayerListingStart.type, getAllAmlPlayerListingWorker)
  yield takeLatest(getAmlThresholdDataStart.type, getThresholdDataWorker)
  yield takeLatest(setAmlThresholdDataStart.type, setAmlThresholdDataWorker)
}

function * getAllAmlPlayerListingWorker (action) {
  try {
    const { isTenant = false, page = '', limit = '', tenantId, selectedTab, search, userId } = action && action.payload
    const { data } = yield getAmlPlayers({ isTenant, page, limit, tenantId, selectedTab, search, userId })

    yield put(getAllAmlPlayerListingSuccess(data?.data))
  } catch (e) {
    yield put(getAllAmlPlayerListingFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getThresholdDataWorker (action) {
  try {
    const { isTenant = false, tenantId = '' } = action && action.payload
    const { data } = yield getAmlThreshold({ isTenant, tenantId })
    yield put(getAmlThresholdDataSuccess(data?.data?.amlThresholds))
  } catch (e) {
    yield put(getAmlThresholdDataFailure(e?.response?.data?.errors[0].description))
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * setAmlThresholdDataWorker (action) {
  try {
    const { isTenant = false, data } = action && action.payload
    yield setAmlThreshold({ isTenant, data })

    yield toast('Threshold Set Successfully', 'success')
    yield put(setAmlThresholdDataSuccess(data))
  } catch (e) {
    yield put(setAmlThresholdDataFailure(e?.response?.data?.errors[0].description))
    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}
