import React from 'react'
import { Row, Col, Card } from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDesktop, faMobileAlt, faTabletAlt, faRedo, faUsers } from '@fortawesome/pro-solid-svg-icons'

import useLivePlayers from '../../hooks/useLivePlayers'
import { CircleChart } from '../../../../../components/CircleChart'
import { SpinnerLoader } from '../../../../../components/Preloader'
import { getTextColor } from '../../../../../utils/dashboardEffects'

import './livePlayer.css'

export default ({ selectedPortal = '', selectedClient = '' }) => {
  const {
    loading,
    livePlayerData,
    fetchDetails
  } = useLivePlayers({ selectedPortal, selectedClient })

  const icons = { desktop: faDesktop, mobile: faMobileAlt, tablet: faTabletAlt }
  const color = { desktop: 'secondary', mobile: 'primary', tablet: 'tertiary' }

  return (livePlayerData &&
    <Row>
      <Col xs={12} xl={9}>
        <Card className='p-2 mb-2 graph-container'>
          <Row className='border-bottom d-flex align-items-center justify-content-between mb-2 pb-2'>

            <Col className='d-flex align-items-center justify-content-between'>
              <h3 className='mb-0'><FontAwesomeIcon icon={faUsers} />&nbsp;Live Report</h3>
              <button onClick={() => fetchDetails()} className='btn btn-sm btn-secondary d-flex align-items-center gap-1'>
                <span className='fs-7'>Refresh</span>
                {loading ? <SpinnerLoader /> : <FontAwesomeIcon style={{ fontSize: '15px' }} icon={faRedo} />}
              </button>
            </Col>
          </Row>

          <Row>
            <Col className='text-center'>
              <h3 className={getTextColor(livePlayerData.todayTotalGgr)}>€ {livePlayerData.todayTotalGgr || 0}</h3>
              <p className='gray-text'>TODAY GGR</p>
            </Col>
            <Col className='text-center'>
              <h3 className={getTextColor(livePlayerData.totalPlayers)}>{livePlayerData.totalPlayers}</h3>
              <p className='gray-text'>Total Players</p>
            </Col>
            <Col className='text-center'>
              <h3 className={getTextColor(livePlayerData.registrationConvRate)}>{livePlayerData.registrationConvRate || 0} %</h3>
              <p className='gray-text'>Registration Conv. Rate</p>
            </Col>
            <Col className='text-center'>
              <h3 className={getTextColor(livePlayerData.depositConvRate)}>{livePlayerData.depositConvRate || 0} %</h3>
              <p className='gray-text'>Deposit Conv. Rate</p>
            </Col>
          </Row>
        </Card>
      </Col>

      <Col xs={12} xl={3}>
        <Card className='p-2 mb-2 graph-container'>
          <Row className='d-block d-xl-flex align-items-center'>
            <Col xs={12} xl={5} className='px-xl-0 text-center'>
              <h3 className='green-text'>{livePlayerData?.loggedInPlayer || 0} </h3>
              <p className='gray-text'>Logged In Players</p>
              <CircleChart series={livePlayerData?.deviceLoggedIn.filter(d => d.device_type !== null).map(d => d.count) || []} />
            </Col>
            <Col xs={12} xl={7} className='px-xl-0 text-center'>

              {livePlayerData?.deviceLoggedIn.filter(d => d.device_type !== null).map(d => (
                <p key={`circle-element-${d.device_type}`} className='text-gray device-report'>
                  <FontAwesomeIcon icon={icons[d.device_type]} className={`icon icon-xs text-${color[d.device_type]} w-20 me-1`} />
                  {` ${d.device_type} `}{`${d.count}`}
                </p>
              ))}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>

  )
}
