import * as Yup from 'yup'

export const userSchema = (permission) =>
  Yup.object().shape({
    email: permission ? Yup.string().email('Invalid Email').max(50).required('Email Required') : Yup.string().nullable(),
    firstName: Yup.string().min(3, 'Minimum 3 Characters Required').max(50, 'Maximum 50 Characters Allowed').required('First Name Required'),
    lastName: Yup.string().min(3, 'Minimum 3 Characters Required').max(50, 'Maximum 50 Characters Allowed').required('Last Name Required'),
    phone: Yup.string().nullable(),
    dateOfBirth: Yup.date().max(new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate() - 1), 'Must Be 18 Years Old').required('DOB Required'),
    gender: Yup.string().required('Gender Required'),
    username: Yup.string().max(20, 'Maximum 20 Characters Allowed').min(3, 'Minimum 3 Characters Required').required('User Name Required'),
    address: Yup.string().max(100, 'Maximum 100 Characters Allowed').required('Address Required'),
    city: Yup.string().max(50, 'Maximum 50 Characters Allowed').required('City Required'),
    zipCode: Yup.string().required('ZipCode Required'),
    currencyCode: Yup.string(),
    countryCode: Yup.string().nullable()
  })

export const sendEmailSchema = () =>
  Yup.object().shape({
    subject: Yup.string().required('Subject Required'),
    content: Yup.string().required('Content Required')
  })

export const reasonSchema = () =>
  Yup.object().shape({
    reason: Yup.string()
      .max(100, 'Max 100 characters')
      .required('Reason Required')
  })

export const tagSchema = () =>
  Yup.object().shape({
    tags: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string()
            .matches(/^[A-Za-z0-9_]*$/, 'Only Alphabets, Numbers and UnderScore(_) Allowed')
            .nullable()
        })
      ).nullable()
  })
