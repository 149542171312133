import React from 'react'
import { Table } from '@themesberg/react-bootstrap'
import LoyaltyPoints from '../../../../components/LoyaltyPoints'

const Loyalty = ({ tenantData, edit, setEdit }) => {
  return (
    <>
      {edit
        ? (
          <div className='mt-2'>
            <LoyaltyPoints
              setEdit={setEdit}
              tenant
              tenantLoyalty
            />
          </div>
          )
        : (
          <Table bordered striped responsive hover size='sm' className='text-center mt-3'>
            <thead className='thead-dark'>
              <tr>
                {['Level', 'Start Point', 'End Point', 'Cashback Multiplier', 'Bonus ID'].map((c) => (
                  <th key={c}>{c}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {tenantData && tenantData?.map(({ level, startPoint, endPoint, cashback_multiplier, bonusId }) =>
                <tr key={level}>
                  <td>{level}</td>
                  <td>{startPoint}</td>
                  <td>{endPoint}</td>
                  <td>{cashback_multiplier}</td>
                  <td>{bonusId || '-'}</td>
                </tr>
              )}
            </tbody>
          </Table>)}
    </>
  )
}
export default Loyalty
