export const countryMasks = {
  af: '..-...-....',
  ax: '(...)...-..-..',
  al: '(...)...-...',
  dz: '..-...-....',
  as: '(684)...-....',
  ad: '...-...',
  ao: '(...)...-...',
  ai: '(264)...-....',
  aq: '1..-...',
  ag: '(268)...-....',
  ar: '(...)...-....',
  am: '..-...-...',
  aw: '...-....',
  ac: '....',
  au: '.-....-....',
  at: '(...)...-....',
  az: '..-...-..-..',
  bs: '(242)...-....',
  bh: '....-....',
  bd: '1...-......',
  bb: '(246)...-....',
  by: '(..)...-..-..',
  be: '(...)...-...',
  bz: '...-....',
  bj: '..-..-....',
  bm: '(441)...-....',
  bt: [
    '17-...-...',
    '77-...-...',
    '.-...-...'
  ],
  bo: '.-...-....',
  ba: [
    '..-....',
    '..-.....'
  ],
  bw: '..-...-...',
  br: [
    '(..)....-....',
    '(..).....-....'
  ],
  io: '...-....',
  bn: '...-....',
  bg: '(...)...-...',
  bf: '..-..-....',
  bi: '..-..-....',
  kh: '..-...-...',
  cm: '....-....',
  ca: '(...)...-....',
  cv: '(...)..-..',
  ky: '(345)...-....',
  cf: '..-..-....',
  td: '..-..-..-..',
  cl: '.-....-....',
  cn: [
    '(...)....-...',
    '(...)....-....',
    '..-.....-.....'
  ],
  cx: '.-....-....',
  cc: '.-....-....',
  co: '(...)...-....',
  km: '..-.....',
  cg: '..-.....',
  ck: '..-...',
  cr: '....-....',
  hr: '..-...-...',
  cu: '.-...-....',
  cy: '..-...-...',
  cz: '(...)...-...',
  cd: '(...)...-...',
  dk: '..-..-..-..',
  dj: '..-..-..-..',
  dm: '(767)...-....',
  do: [
    '(809)...-....',
    '(829)...-....',
    '(849)...-....'
  ],
  ec: [
    '.-...-....',
    '..-...-....'
  ],
  eg: '(...)...-....',
  sv: '..-..-....',
  gq: '..-...-....',
  er: '.-...-...',
  ee: [
    '...-....',
    '....-....'
  ],
  sz: '..-..-....',
  et: '..-...-....',
  fk: '.....',
  fo: '...-...',
  fj: '..-.....',
  fi: '(...)...-..-..',
  fr: '(...)...-...',
  gf: '.....-....',
  pf: '..-..-..',
  ga: '.-..-..-..',
  gm: '(...)..-..',
  ge: '(...)...-...',
  de: [
    '...-...',
    '(...)..-..',
    '(...)..-...',
    '(...)..-....',
    '(...)...-....',
    '(....)...-....'
  ],
  gh: '(...)...-...',
  gi: '...-.....',
  gr: '(...)...-....',
  gl: '..-..-..',
  gd: '(473)...-....',
  gp: '(...)...-...',
  gu: '(671)...-....',
  gt: '.-...-....',
  gg: '(....)......',
  gn: '..-...-...',
  gw: '.-......',
  gy: '...-....',
  ht: '..-..-....',
  va: '06 698.....',
  hn: '....-....',
  hk: '....-....',
  hu: '(...)...-...',
  is: '...-....',
  in: '...-...-....',
  id: [
    '..-...-..',
    '..-...-...',
    '..-...-....',
    '(8..)...-...',
    '(8..)...-..-...'
  ],
  ir: '(...)...-....',
  iq: '(...)...-....',
  ie: '(...)...-...',
  im: '(....)......',
  il: [
    '.-...-....',
    '5.-...-....'
  ],
  it: '(...)....-...',
  ci: '..-...-...',
  jm: '(876)...-....',
  jp: [
    '(...)...-...',
    '..-....-....'
  ],
  je: '(....)....-......',
  jo: '.-....-....',
  kz: [
    '(6..)...-..-..',
    '(7..)...-..-..'
  ],
  ke: '...-......',
  ki: '..-...',
  kp: [
    '...-...',
    '....-....',
    '..-...-...',
    '...-....-...',
    '191-...-....',
    '....-.............'
  ],
  kr: '..-...-....',
  xk: [
    '..-...-...',
    '...-...-...'
  ],
  kw: '....-....',
  kg: '(...)...-...',
  la: [
    '..-...-...',
    '(20..)...-...'
  ],
  lv: '..-...-...',
  lb: [
    '.-...-...',
    '..-...-...'
  ],
  ls: '.-...-....',
  lr: '..-...-...',
  ly: [
    '..-...-...',
    '21-...-....'
  ],
  li: '(...)...-....',
  lt: '(...)..-...',
  lu: '(...)...-...',
  mo: '....-....',
  mg: '..-..-.....',
  mw: [
    '1-...-...',
    '.-....-....'
  ],
  my: [
    '.-...-...',
    '..-...-...',
    '(...)...-...',
    '..-...-....'
  ],
  mv: '...-....',
  ml: '..-..-....',
  mt: '...-...-..',
  mh: '...-....',
  mq: '(...)..-..-..',
  mr: '..-..-....',
  mu: '...-....',
  yt: '.....-....',
  mx: [
    '..-..-....',
    '(...)...-....'
  ],
  fm: '...-....',
  md: '....-....',
  mc: [
    '..-...-...',
    '(...)...-...'
  ],
  mn: '..-..-....',
  me: '..-...-...',
  ms: '(664)...-....',
  ma: '..-....-...',
  mz: '..-...-...',
  mm: [
    '...-...',
    '.-...-...',
    '..-...-...'
  ],
  na: '..-...-....',
  nr: '...-....',
  np: '..-...-...',
  nl: '..-...-....',
  an: [
    '...-....',
    '9...-....'
  ],
  nc: '..-....',
  nz: [
    '.-...-...',
    '(...)...-...',
    '(...)...-....'
  ],
  ni: '....-....',
  ne: '..-..-....',
  ng: [
    '..-...-..',
    '..-...-...',
    '(...)...-....'
  ],
  nu: '....',
  nf: '3..-...',
  mk: '..-...-...',
  mp: '(670)...-....',
  no: '(...)..-...',
  om: '..-...-...',
  pk: '(...)...-....',
  pw: '...-....',
  ps: '..-...-....',
  pa: '...-....',
  pg: '(...)..-...',
  py: '(...)...-...',
  pe: '(...)...-...',
  ph: '(...)...-....',
  pn: '...-...-...',
  pl: '(...)...-...',
  pt: '..-...-....',
  pr: [
    '(787) ... ....',
    '(939) ... ....'
  ],
  qa: '....-....',
  re: '.....-....',
  ro: '..-...-....',
  ru: '(...)...-..-..',
  rw: '(...)...-...',
  bl: '...-..-..-..',
  sh: '....',
  kn: '(869)...-....',
  lc: '(758)...-....',
  mf: '(...)...-...',
  pm: '..-....',
  vc: '(784)...-....',
  ws: '..-....',
  sm: '....-......',
  st: '..-.....',
  sa: [
    '.-...-....',
    '5.-....-....'
  ],
  sn: '..-...-....',
  rs: '..-...-....',
  sc: '.-...-...',
  sl: '..-......',
  sg: '....-....',
  sx: '(721)...-....',
  sk: '(...)...-...',
  si: '..-...-...',
  sb: [
    '.....',
    '...-....'
  ],
  so: [
    '.-...-...',
    '..-...-...'
  ],
  za: '..-...-....',
  gs: '.....',
  ss: '..-...-....',
  es: '(...)...-...',
  lk: '..-...-....',
  sd: '..-...-....',
  sr: [
    '...-...',
    '...-....'
  ],
  sj: '(...)..-...',
  se: '..-...-....',
  ch: '..-...-....',
  sy: '..-....-...',
  tw: [
    '....-....',
    '.-....-....'
  ],
  tj: '..-...-....',
  tz: '..-...-....',
  th: [
    '..-...-...',
    '..-...-....'
  ],
  tl: [
    '...-....',
    '77.-.....',
    '78.-.....'
  ],
  tg: '..-...-...',
  tk: '....',
  to: '.....',
  tt: '(868)...-....',
  tn: '..-...-...',
  tr: '(...)...-....',
  tm: '.-...-....',
  tc: '(249)...-...',
  tv: [
    '2....',
    '90....'
  ],
  ug: '(...)...-...',
  ua: '(..)...-..-..',
  ae: [
    '.-...-....',
    '5.-...-....'
  ],
  gb: '..-....-....',
  us: '(...)...-....',
  uy: '.-...-..-..',
  uz: '..-...-....',
  vu: [
    '.....',
    '..-.....'
  ],
  ve: '(...)...-....',
  vn: [
    '..-....-...',
    '(...)....-...'
  ],
  vg: '(284)...-....',
  vi: '(340)...-....',
  wf: '..-....',
  ye: [
    '.-...-...',
    '..-...-...',
    '...-...-...'
  ],
  zm: '..-...-....',
  zw: '.-......'
}
