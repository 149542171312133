import { Row, Col, Button, Tabs, Tab, Form as BForm } from '@themesberg/react-bootstrap'
import React from 'react'
import useCreateTournament from '../hooks/useCreateTournament'
import { Form, Formik } from 'formik'
import Language from './Language'
import General from './General'
import { tournamentSchema } from './schema'

const CreateTournament = () => {
  const path = window.location.pathname
  const type = path.includes('edit') ? 'edit' : path.includes('view') ? 'view' : path.includes('clone') ? 'clone' : 'create'
  const {
    handleCSV,
    handleTournaments,
    selectedTab,
    setSelectedTab,
    languages,
    isTenant,
    dispatch,
    data,
    setData,
    clientsFilterData,
    portalOptions,
    setSelectedClient,
    tournamentDetails,
    preview,
    handleImagePreview,
    allCurrencies,
    countries,
    initialValues,
    navigate,
    handleJSON,
    jsonRef,
    csvRef,
    tenantDetails
  } = useCreateTournament({ type })

  return (
    <>
      <Row>
        <Col>
          <h3>{type.charAt(0).toUpperCase() + type.slice(1)} Tournament</h3>
        </Col>

        <Col className='d-flex justify-content-end'>
          <Button
            variant='outline-success'
            className='mx-2'
            size='sm'
            onClick={() => document.getElementById('JSONData').click()}
          >
            Upload JSON
          </Button>
          <BForm.Control
            hidden
            id='JSONData'
            type='file'
            ref={jsonRef}
            name='JSONData'
            accept='.json'
            onChange={(e) => handleJSON(e)}
          />

          <Button
            variant='outline-success'
            size='sm'
            onClick={() => document.getElementById('languageCSV').click()}
          >
            Upload CSV
          </Button>
          <BForm.Control
            hidden
            id='languageCSV'
            type='file'
            ref={csvRef}
            name='languageCsv'
            accept='.csv'
            onChange={(e) => handleCSV(e)}
          />
        </Col>
      </Row>

      <Formik
        initialValues={{ ...initialValues }}
        enableReinitialize
        validationSchema={tournamentSchema({ tournamentDetails, isTenant })}
        onSubmit={(formValues) => handleTournaments(formValues)}
      >
        {({
          touched,
          errors,
          values,
          setSubmitting,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          setValues
        }) => (
          <Form>
            <Tabs
              key={selectedTab}
              activeKey={selectedTab}
              className='nav-light'
              mountOnEnter
              unmountOnExit
              onSelect={(k) => setSelectedTab(k)}
            >
              <Tab disabled eventKey='general' title='General'>
                <div className='mt-5'>
                  <General
                    setSelectedTab={setSelectedTab}
                    touched={touched}
                    errors={errors}
                    values={values}
                    setSubmitting={setSubmitting}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    isTenant={isTenant}
                    dispatch={dispatch}
                    type={type}
                    data={data}
                    setData={setData}
                    clientsFilterData={clientsFilterData}
                    portalOptions={portalOptions}
                    setSelectedClient={setSelectedClient}
                    tournamentDetails={tournamentDetails}
                    preview={preview}
                    handleImagePreview={handleImagePreview}
                    allCurrencies={allCurrencies}
                    countries={countries}
                    navigate={navigate}
                    languages={languages}
                    allowedCurr={tenantDetails?.tenantConfig?.allowedCurrencies || ''}
                    setValues={setValues}
                    initialValues={initialValues}
                  />
                </div>
              </Tab>

              {languages?.length > 0 &&
                <Tab disabled eventKey='languages' title='Languages'>
                  <div className='mt-5'>
                    <Language
                      languages={languages}
                      handleSubmit={handleSubmit}
                      setSelectedTab={setSelectedTab}
                      selectedTab={selectedTab}
                      values={values}
                      type={type}
                      data={data}
                      setData={setData}
                    />
                  </div>
                </Tab>}
            </Tabs>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default CreateTournament
