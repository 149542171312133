import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCheckPermission from '../../utils/checkPermission'
import { bannedPlayerSettingStart } from '../../store/redux-slices/bannedPlayer'
import { getTenantStart } from '../../store/redux-slices/tenants'

const useBannedPlayerSetting = () => {
  const dispatch = useDispatch()
  const { isHidden } = useCheckPermission()
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const { tenantDetails } = useSelector((state) => state.tenants)
  const { tenantDetails: loginTenantDetails } = useSelector((state) => state.login)

  const updateBannedPlayerSetting = (data) => dispatch(bannedPlayerSettingStart({ data }))

  useEffect(() => {
    if (selectedPortal) { dispatch(getTenantStart({ tenantId: selectedPortal })) }
  }, [selectedPortal])

  return {
    isHidden,
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal,
    updateBannedPlayerSetting,
    tenantDetails,
    loginTenantDetails
  }
}

export default useBannedPlayerSetting
