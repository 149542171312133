export const profileConstants = [
  { key: 'First Name', value: 'firstName', edit: true },
  { key: 'Last Name', value: 'lastName', edit: true },
  { key: 'Email', value: 'email' },
  { key: 'User Name', value: 'superAdminUsername' },
  { key: 'Role', value: 'SuperadminRole', subValue: 'name' },
  { key: 'Group', value: 'group' }
]

export const profileConstantsTA = [
  { key: 'First Name', value: 'firstName', edit: true },
  { key: 'Last Name', value: 'lastName', edit: true },
  { key: 'Email', value: 'email' },
  { key: 'Phone', value: 'phone', edit: true },
  { key: 'Role', value: 'AdminRole', subValue: 'name' },
  { key: 'Username', value: 'agentName' },
  { key: 'Group', value: 'group' }
]

export const permissionLabel = (label, key) => {
  switch (label) {
    case 'C':
      return key === 'Transactions' ? 'Manage Payment' : key === 'CashierManagement' ? 'Add Method' : 'Create'
    case 'R':
      return 'Read'
    case 'U':
      return 'Update'
    case 'D':
      return 'Delete'
    case 'T':
      return 'Toggle Status'
    case 'A':
      return 'Apply'
    case 'CC':
      return 'Create Custom'
    case 'AB':
      return 'Manage Money'
    case 'SR':
      return 'Limit'
    case 'TE':
      return 'Test Email'
    case 'EV':
      return 'Verify Email'
    case 'UP':
      return 'Reset Password'
    case 'RUI':
      return 'Read Player Info'
    case 'SM':
      return 'Sent Mails'
    case 'AT':
      return 'Audit Trail'
    case 'UA':
      return 'Affiliates Data'
    case 'SL':
      return 'Session Logs'
    case 'DR':
      return 'Download Report'
    case 'RA':
      return 'Remove Affiliated User'
    case 'PV':
      return 'Phone Verification'
    case 'ATL':
      return 'Tenant Login'
    case 'MT':
      return 'Manage Tags'
    default:
      return label
  }
}
