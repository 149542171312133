export const isTenant = !window.location.href.match(/super/g)
export const checkIsTenant = () => !window.location.href.match(/super/g)

export const getActionType = () => window.location.pathname.includes('create') ? 'create' : window.location.pathname.includes('edit') ? 'edit' : 'view'

export const getSortingValues = {
  0: 'transactionBankingId',
  1: 'amount',
  2: 'primaryCurrencyAmount',
  3: 'targetId',
  4: 'id',
  5: 'roundCount',
  6: 'playerCount',
  7: 'totalBet',
  8: 'totalWin',
  9: 'GGR',
  10: 'totalBets',
  11: 'bonusGGR',
  12: 'GGR',
  13: 'payout'
}

export const getTransactionSortedValues = { // 2
  0: 'transactionBankingId',
  1: 'moreDetails?.withdrawId' || 'paymentTransactionId',
  2: 'targetId',
  3: 'successFee',
  4: 'declinedFee',
  5: 'mdr',
  6: 'transactionFee',
  7: 'primaryCurrencyAmount'
}

export const getGameReportSortedValue = {
  1: 'id',
  2: 'roundCount',
  3: 'playerCount',
  4: 'totalBet',
  5: 'totalWin',
  6: 'GGR',
  7: 'payout'
}

export const getJackpotReportSortedValue = {
  1: 'id',
  2: 'jackpotContribution',
  3: 'jackpotWin',
  4: 'jackpotRevenue'
}
export const getKPIReportSortedValue = {
  1: 'GGR',
  2: 'deltaGGR',
  3: 'realBet',
  4: 'realWin',
  5: 'bonusBet',
  6: 'bonusWin',
  7: 'bonusGGR',
  8: 'totalGGR',
  9: 'totalBets',
  10: 'deltaTotalBets'
}

export const getCasinoTransactionSortedValue = {
  1: 'casinoTransactionId',
  2: 'amount',
  3: 'nonCashAmount',
  4: 'afterBalance',
  5: 'beforeBalance',
  6: 'userId'
}

export const getDemographicSortedValue = {
  1: 'signUpCount',
  2: 'newDepositors',
  3: 'acquisition',
  4: 'reDepositors',
  5: 'totalDepositors',
  6: 'totalDeposit'
}

export const CASINO_SUMMED_AMOUNT_DESC = 'Total Amounts must need Currency and Action type filter. Internal players calculation are excluded.'

export const bannerType = [
  { label: 'Home ', value: 'HOME' },
  { label: 'Casino ', value: 'CASINO' },
  { label: 'Promotion ', value: 'PROMOTIONS' },
  { label: 'Loyalty ', value: 'LOYALTY' },
  { label: 'Cashback ', value: 'CASHBACK' },
  { label: 'Tournament ', value: 'TOURNAMENT' },
  { label: 'Affiliate ', value: 'AFFILIATE' },
  { label: 'VIP ', value: 'VIP' }
]

export const createBannerKeys = { bannerHeading: '', bannerloggedOutBtn: '', bannerloggedInBtn: '', bannerFooter: '', bannerDesc: '' }

export const credKeyNames = {
  APP_SENDGRID_API_KEY: 'Sendgrid API Key',
  APP_SENDGRID_EMAIL: 'Sendgrid Email',
  SUPPORT_EMAIL_ADDRESS: 'Support Email Address',

  PAYMENTIQ_MERCHANT_ID: 'Payment IQ Merchant ID',
  PAYMENTIQ_CLIENT_ID: 'Payment IQ Client ID',
  PAYMENTIQ_CLIENT_SECRET: 'Payment IQ Client Secret',
  PRAXIS_MERCHANT_ID: 'Praxis Merchant ID',
  PRAXIS_MERCHANT_SECRET: 'Praxis Merchant Secret',
  PRAXIS_APPLICATION_KEY: 'Praxis Application Key',
  SIQURO_TOKEN: 'Siquro Token',
  SIQURO_BRAND_ID: 'Siquro Brand ID',
  FLAXPAY_USER_ID: 'Flaxpay User ID',
  FLAXPAY_PASSWORD: 'Flaxpay User Password',
  FLAXPAY_API_KEY: 'Flaxpay API Key',
  NODA_PAY_API_KEY: 'NodaPay API Key',
  NODA_PAY_SECRET_KEY: 'NodaPay Secret Key',
  NODA_PAY_SHOP_ID: 'NodaPay Shop ID',
  COINSPAID_PUBLIC_KEY: 'CoinsPaid Public Key',
  COINSPAID_SECRET_KEY: 'CoinsPaid Secret Key',
  UTORG_PUBLIC_KEY: 'UTORG Public Key',
  UTORG_ID: 'UTORG ID',
  I_GAMING_DECK_API_KEY: 'IGaming Deck API Key',
  I_GAMING_DECK_WALLET_KEY: 'IGaming Deck Wallet Key',
  I_GAMING_DECK_BRAND_ID: 'IGaming Deck Brand ID',

  CIO_SITE_ID: 'Customer.Io Site ID',
  CIO_TRACK_API_KEY: 'Customer.Io Track API Key',
  CIO_APP_API_KEY: 'Customer.Io App API Key',
  RMQ_CONNECTION_URL: 'RabbitMQ Connection URL',
  UNIBO_SECRET_KEY: 'Unibo Secret Key',

  TWILIO_SID: 'Twilio Security Identifier',
  TWILIO_SERVICE_ID: 'Twilio Service ID',
  TWILIO_AUTH_TOKEN: 'Twilio Authentication Token',

  SUMSUB_SECRET_KEY: 'Sumsub Secret Key',
  SUMSUB_APP_TOKEN: 'Sumsub Application Token',

  SWISS_SOFT_TOURNAMENT_CASINO_ID: 'Swiss Soft Tournament Casino Id',
  SWISS_SOFT_CASINO_ID: 'Swiss Soft Casino Id',
  HUB88_OPERATOR_ID: 'Hub88 Operator Id',

  INTERKASSA_USER_ID: 'Interkassa User ID',
  INTERKASSA_API_KEY: 'Interkassa API Key',
  INTERKASSA_ACCOUNT_ID: 'Interkassa Account ID'

}

export const credKeyDiff = {
  Email: ['APP_SENDGRID_API_KEY', 'APP_SENDGRID_EMAIL', 'SUPPORT_EMAIL_ADDRESS'],
  Payments: [
    'PAYMENTIQ_MERCHANT_ID',
    'PAYMENTIQ_CLIENT_ID',
    'PAYMENTIQ_CLIENT_SECRET',
    'PRAXIS_MERCHANT_ID',
    'PRAXIS_MERCHANT_SECRET',
    'PRAXIS_APPLICATION_KEY',
    'SIQURO_TOKEN',
    'SIQURO_BRAND_ID',
    'FLAXPAY_USER_ID',
    'FLAXPAY_PASSWORD',
    'FLAXPAY_API_KEY',
    'NODA_PAY_API_KEY',
    'NODA_PAY_SECRET_KEY',
    'NODA_PAY_SHOP_ID',
    'COINSPAID_PUBLIC_KEY',
    'COINSPAID_SECRET_KEY',
    'UTORG_PUBLIC_KEY',
    'UTORG_ID',
    'INTERKASSA_USER_ID',
    'INTERKASSA_API_KEY',
    'INTERKASSA_ACCOUNT_ID'
  ],
  CRM: ['CIO_SITE_ID',
    'CIO_TRACK_API_KEY',
    'CIO_APP_API_KEY',
    'RMQ_CONNECTION_URL',
    'UNIBO_SECRET_KEY'],

  SMS: ['TWILIO_SERVICE_ID', 'TWILIO_AUTH_TOKEN', 'TWILIO_SID'],

  CASINO: [
    'SWISS_SOFT_TOURNAMENT_CASINO_ID',
    'SWISS_SOFT_CASINO_ID',
    'HUB88_OPERATOR_ID',
    'I_GAMING_DECK_API_KEY',
    'I_GAMING_DECK_WALLET_KEY',
    'I_GAMING_DECK_BRAND_ID'
  ],

  Verification: ['SUMSUB_SECRET_KEY', 'SUMSUB_APP_TOKEN']
}

export const affiliateKeysDesc = {
  MY_AFFILIATE_HOST: 'FTP Host',
  MY_AFFILIATE_PORT: 'FTP Port',
  MY_AFFILIATE_USER: 'Username',
  MY_AFFILIATE_PASSWORD: 'Password',
  MAP_AFFILIATE_HOST: 'FTP Host',
  MAP_AFFILIATE_PORT: 'FTP Port',
  MAP_AFFILIATE_USER: 'Username',
  MAP_AFFILIATE_PASSWORD: 'Password',
  GOFILIATE_AUTHORIZATION_KEY: 'Authorization Key',
  GOFILIATE_URL: 'URL',
  GOFILIATE_USERNAME: 'Username',
  GOFILIATE_PASSWORD: 'Password',
  MY_AFFILIATE_API_USERNAME: 'API Username',
  MY_AFFILIATE_API_PASSWORD: 'API Password'
}

export const affiliateSystemLabels = {
  gofiliate: 'GoFilliate',
  mapAffiliate: 'MAP Affiliate',
  myAffiliate: 'MY Affiliate'
}
