import { Button, Col, Form, Row } from '@themesberg/react-bootstrap'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import useProviderReorder from '../hooks/useProviderReorder'
import Preloader from '../../../../components/Preloader'
import { providerCategory } from './constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyCheckDollarPen } from '@fortawesome/pro-solid-svg-icons'
import { SuperAdminRoutes, TenantRoutes } from '../../../../routes'

export default () => {
  const {
    type,
    state,
    setType,
    adminId,
    loading,
    isHidden,
    navigate,
    isTenant,
    tenantId,
    onDragEnd,
    handleSave,
    providerLoading,
    locationState = {}
  } = useProviderReorder()

  const ReOrderComponent = () => {
    return (
      <>
        <div className='reorder-heading'>
          {[
            'ID',
            'Name',
            'Aggregator',
            'Group',
            'Category'
          ].map((h) => (
            <p key={h}>{h}</p>
          ))}
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='list text-left'>
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {state?.length > 0 &&
                    state?.map(
                      ({
                        name,
                        paymentProviderId,
                        aggregator,
                        group,
                        category
                      }, idx) => (
                        <Draggable draggableId={`id-${idx}`} key={idx} index={idx}>
                          {provided => (
                            <div
                              className='reorder-content'
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <p>{paymentProviderId}</p>
                              <p>
                                {name}
                              </p>
                              <p>{aggregator.toUpperCase()}</p>
                              <p>{group}</p>
                              <p>{providerCategory?.[category || 'OTHER']}</p>
                            </div>
                          )}
                        </Draggable>
                      ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </>
    )
  }

  return (
    <>
      <Row className='mb-3'>
        <Col>
          <h3><FontAwesomeIcon icon={faMoneyCheckDollarPen} />&nbsp;Cashier Management</h3>
        </Col>
        <Col className='d-flex justify-content-end align-items-center'>
          <label>Select Type: &nbsp;</label>
          <Form.Select
            size='sm'
            value={type}
            className='w-25'
            onChange={(e) => setType(e.target.value)}
          >
            <option value='deposit'>Deposit</option>
            <option value='withdraw'>Withdraw</option>
          </Form.Select>
        </Col>
      </Row>

      {loading || providerLoading
        ? <Preloader />
        : <ReOrderComponent />}
      {state?.length === 0
        ? <p className='text-danger text-center'>No data found</p>
        : (
          <div className='mt-3 d-flex justify-content-between mb-3'>
            <Button
              variant='outline-warning'
              onClick={() => navigate(isTenant ? TenantRoutes.PaymentAggregators : SuperAdminRoutes.PaymentAggregators, { state: { ...locationState, adminId, tenantId } })}
            >
              Cancel
            </Button>
            <Button
              variant='outline-success'
              onClick={handleSave}
              hidden={isHidden({ module: { key: 'CashierManagement', value: 'U' } })}
            >
              Submit
            </Button>
          </div>
          )}
    </>
  )
}
