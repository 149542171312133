import React from 'react'
import useCreateGroup from '../hooks/useCreateGroup'
import Preloader from '../../Preloader'
import { Row, Col, Form as BForm, Badge, Card, Button, Spinner } from '@themesberg/react-bootstrap'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { permissionLabel } from '../../ProfilePage/constants'
import { toast } from '../../Toast'
import { SuperAdminRoutes, TenantRoutes } from '../../../routes'
import Select from 'react-select'

const CreateGroup = () => {
  const {
    groupDetail,
    type,
    groupLoading,
    adminDetails,
    handleSave,
    navigate,
    isTenant,
    parentGroupOptions
  } = useCreateGroup()

  return (
    <>
      {groupLoading
        ? <Preloader />
        : (
          <>
            <Row>
              <Col>
                <h3>{type === 'view' ? 'View' : type === 'create' ? 'Create' : 'Edit'} Group {type !== 'create' ? `: ${groupDetail?.groupName}` : ''}</h3>
              </Col>
            </Row>

            <Formik
              initialValues={{
                groupName: groupDetail?.groupName || '',
                parentId: parentGroupOptions.find(
                  (parentGroupOption) => parentGroupOption.value === groupDetail?.parentGroupId
                ),
                permissions: { ...groupDetail?.permissions } || {}
              }}
              enableReinitialize
              onSubmit={(formValues) => {
                handleSave(formValues)
              }}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                handleBlur,
                setFieldValue
              }) => (
                <Form>
                  <Row>
                    <Col md={6} sm={12}>
                      <BForm.Label>Group Name<span className='text-danger'> *</span></BForm.Label>

                      <BForm.Control
                        type='text'
                        name='groupName'
                        placeholder='Enter Group Name'
                        value={values.groupName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={type === 'view'}
                      />

                      <ErrorMessage
                        component='div'
                        name='groupName'
                        className='text-danger'
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} sm={12}>
                      <BForm.Label>Parent Group<span className='text-danger'> *</span></BForm.Label>

                      <Select
                        isDisabled={type === 'view'}
                        name='parentId'
                        value={values.parentId}
                        options={parentGroupOptions}
                        classNamePrefix='select'
                        onChange={(option) => {
                          setFieldValue('parentId', option)
                        }}
                      />
                    </Col>
                  </Row>

                  <Card className='mt-3'>
                    <Card.Header>
                      <div className='d-flex'>
                        <span className='col-4 header-font-weight'>
                          Module
                        </span>
                        <span className='ms-3 header-font-weight'>
                          Permissions
                        </span>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      {Object.keys(
                        adminDetails?.userPermission?.permission
                      ).map((key, index) => {
                        return type === 'view' && !values?.permissions?.[key]
                          ? null
                          : (
                            <Row className='border-bottom mt-3' key={index}>
                              <Col lg={4}>
                                <BForm.Label>{key}</BForm.Label>
                              </Col>

                              <Col className='d-flex flex-wrap'>
                                {adminDetails?.userPermission?.permission[
                                  key
                                ].map((value, index) => {
                                  return (
                                    <label key={index}>
                                      {adminDetails?.userPermission?.permission[
                                        key
                                      ].includes('R')
                                        ? (
                                          <Field
                                            className='d-none'
                                            type='checkbox'
                                            name={`permissions[${key}]`}
                                            value={value}
                                            onChange={(e) => {
                                              if (type !== 'view') {
                                                if (e.target.value === 'R' || values?.permissions?.[key]?.includes('R')) {
                                                  if (e.target.value === 'R' && !e.target.checked) {
                                                    const permission = { ...values?.permissions }
                                                    delete permission?.[key]
                                                    setFieldValue(
                                                      'permissions',
                                                      permission
                                                    )
                                                  } else {
                                                    handleChange(e)
                                                  }
                                                } else {
                                                  toast(
                                                    'Please Select Read Permission Before Selecting Other For This Module',
                                                    'error'
                                                  )
                                                }
                                              }
                                            }}
                                          />
                                          )
                                        : (
                                          <Field
                                            className='d-none'
                                            type='checkbox'
                                            name={`permissions[${key}]`}
                                            value={value}
                                            onChange={(e) => type !== 'view' && handleChange(e)}
                                          />
                                          )}

                                      <h3>
                                        <Badge
                                          className='p-2 mx-2 '
                                          type='button'
                                          hidden={type === 'view' && !values?.permissions?.[key]?.includes(value)}
                                          bg={values?.permissions?.[key]?.includes(value)
                                            ? 'success'
                                            : 'primary'}
                                        >
                                          {permissionLabel(value, key)}
                                        </Badge>
                                      </h3>
                                    </label>
                                  )
                                })}
                              </Col>
                            </Row>
                            )
                      })}

                    </Card.Body>
                  </Card>

                  <div className='mt-4 d-flex justify-content-between align-items-center'>
                    <Button
                      variant='outline-warning'
                      onClick={() => navigate(!isTenant ? SuperAdminRoutes.Groups : TenantRoutes.Groups)}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant='outline-success'
                      onClick={() => {
                        if (!values.groupName) {
                          toast('Please add Group Name', 'error')
                        } else if (!values.parentId) {
                          toast('Please select parent group', 'error')
                        } else if (!Object.keys(values.permissions)?.length) {
                          toast('Please add Permissions', 'error')
                        } else {
                          handleSubmit()
                        }
                      }}
                      className='ml-2'
                      hidden={type === 'view'}
                    >
                      Submit
                      {groupLoading && (
                        <Spinner
                          as='span'
                          animation='border'
                          role='status'
                          aria-hidden='true'
                        />
                      )}
                    </Button>

                  </div>
                </Form>
              )}
            </Formik>
          </>
          )}
    </>
  )
}

export default CreateGroup
