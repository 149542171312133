export const tableHeaders = [
  { label: 'ID', value: 'adminUserId' },
  { label: 'Email', value: 'email' },
  { label: 'Full name', value: 'firstName' },
  { label: 'Phone Number', value: 'email' },
  { label: 'Role', value: 'email' },
  { label: 'Group', value: 'email' },
  { label: 'Status', value: 'email' },
  { label: 'Action', value: 'action' }
]
