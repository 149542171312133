import React from 'react'
import LanguageMgmt from '../../../components/LanguageMgmt'

const LanguageManagement = ({ errorCodes = false }) => {
  return (
    <>
      <LanguageMgmt isTenant errorCodes={errorCodes} />
    </>
  )
}

export default LanguageManagement
