import { Col, Row, Spinner, Form as BForm, Button } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import ProviderFilter from '../../../../../components/ProviderFilter'
import { InlineLoader } from '../../../../../components/Preloader'
import { getAllCasinoGamesStart, getFreeSpinGamesStart, resetCasinoGameSuccess } from '../../../../../store/redux-slices/superAdminCasinoManagement'
import GamesListEdit from './GamesListEdit'
import useDidMountEffect from '../../../../../utils/useDidMountEffect'
import { getAllMasterGamesStart, getTAFreeSpinGamesStart, resetAllMasterGamesSuccess } from '../../../../../store/redux-slices/tenantCasino'
import { toast } from '../../../../../components/Toast'

const Games = ({
  gameIds,
  setGameIds,
  setLimit,
  setPageNo,
  setSearch,
  search,
  totalPages,
  limit,
  pageNo,
  handleSubmit,
  handleBlur,
  casinoGamesData,
  selectedProvider,
  setSelectedProvider,
  setSelectedTab,
  isEdit,
  errors,
  isTenant,
  details,
  isClone,
  values,
  bonus = false,
  prepaidsData,
  setPrepaidsData
}) => {
  const dispatch = useDispatch()
  const { bonusId } = useParams()
  const isInitialRender = useDidMountEffect()
  const [change, setChange] = useState(false)
  const { bonusDetail } = useSelector(state => state.bonus)
  const { loading: SALoading, aggregators, gamesLoading } = useSelector(state => state.superAdminCasino)
  const { loading: TALoading, gamesLoading: TAGamesLoading } = useSelector(state => state.tenantCasino)
  const loading = isTenant ? TALoading : SALoading
  const isHub88 = aggregators?.rows?.length && aggregators?.rows?.find(({ masterGameAggregatorId }) => masterGameAggregatorId === parseInt(values?.aggregatorId))?.name?.includes('hub88')

  useEffect(() => {
    if (bonusDetail?.gameIds && !bonusDetail?.other?.hub88PrepaidDetails?.length) {
      setGameIds((window.location.pathname.includes('create') || window.location.pathname.includes('clone')) ? [] : bonusDetail?.gameIds)
    }

    // if (bonusDetail?.other?.hub88PrepaidDetails?.length) {
    //   isTenant
    //     ? TAGames()
    //     : SAGames()
    // }
  }, [bonusDetail])

  const TAFreeSpin = () => {
    (values?.bonusType === 'freespins' || values?.bonusType === 'cashfreespins') &&
    dispatch(
      getTAFreeSpinGamesStart({
        limit,
        pageNo,
        search,
        providerId: selectedProvider,
        bonusId,
        tenantIds: JSON.stringify(details ? [bonusDetail?.tenantId] : values?.tenantIds?.map(({ value }) => value))
      })
    )
  }
  const SAFreeSpin = () => {
    (values?.bonusType === 'freespins' || values?.bonusType === 'cashfreespins') &&
    dispatch(
      getFreeSpinGamesStart({
        limit,
        pageNo,
        search,
        providerId: selectedProvider,
        bonusId,
        tenantIds: JSON.stringify(details ? [bonusDetail?.tenantId] : values?.tenantIds?.map(({ value }) => value))
      }))
  }
  const SAGames = () => {
    if ((values?.bonusType === 'freespins' || values?.bonusType === 'cashfreespins')) {
      dispatch(resetCasinoGameSuccess())
      dispatch(
        getAllCasinoGamesStart({
          limit,
          pageNo,
          casinoCategoryId: '',
          search,
          isActive: true,
          tenantId: '',
          selectedProvider,
          freespins: true,
          bonusId: isEdit ? window?.location?.pathname?.includes('clone') ? '' : bonusId : '',
          tenantIds: JSON.stringify(details ? [bonusDetail?.tenantId] : values?.tenantIds?.map(({ value }) => value)),
          aggregatorId: values?.aggregatorId || ''
        })
      )
    }
  }

  const TAGames = () => {
    if ((values?.bonusType === 'freespins' || values?.bonusType === 'cashfreespins')) {
      dispatch(resetAllMasterGamesSuccess())
      dispatch(
        getAllMasterGamesStart({
          limit,
          pageNo,
          search,
          casinoCategoryId: '',
          providerId: selectedProvider,
          freespins: true,
          bonusId: isEdit ? window?.location?.pathname?.includes('clone') ? '' : bonusId : '',
          tenantIds: JSON.stringify(details ? [bonusDetail?.tenantId] : values?.tenantIds?.map(({ value }) => value)),
          aggregatorId: values?.aggregatorId || ''
        })
      )
    }
  }

  useEffect(() => {
    setPageNo(1)
    if ((isEdit || details) && bonusId && (bonusDetail?.other?.hub88PrepaidDetails?.length ? false : !change)) {
      isTenant
        ? TAFreeSpin()
        : SAFreeSpin()
    } else {
      isTenant
        ? TAGames()
        : SAGames()
    }
  }, [limit, bonusId, selectedProvider])

  useEffect(() => {
    if (!isInitialRender) {
      if ((isEdit || details) && bonusId && (bonusDetail?.other?.hub88PrepaidDetails?.length ? false : !change)) {
        isTenant
          ? TAFreeSpin()
          : SAFreeSpin()
      } else {
        isTenant
          ? TAGames()
          : SAGames()
      }
    }
  }, [pageNo])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (pageNo === 1) {
          if ((isEdit || details) && bonusId && (bonusDetail?.other?.hub88PrepaidDetails?.length ? false : !change)) {
            !isTenant
              ? SAFreeSpin()
              : TAFreeSpin()
          } else {
            isTenant
              ? TAGames()
              : SAGames()
          }
        } else {
          setPageNo(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  return (
    <>
      <Row className='d-flex'>
        <Col xs='auto' className='d-flex align-items-center provider'>
          <ProviderFilter
            selectedProvider={selectedProvider}
            setSelectedProvider={setSelectedProvider}
            isTenantRoute={isTenant}
            bonus
            tenantIds={details ? [bonusDetail?.tenantId] : values?.tenantIds?.map(({ value }) => value)}
          />
        </Col>
        <Col xs='auto' className='d-flex align-items-center'>
          <BForm.Label>
            Search
          </BForm.Label>

          <BForm.Control
            type='search'
            size='sm'
            style={{ marginLeft: '10px', maxWidth: '230px' }}
            placeholder='Search Game Name'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onBlur={handleBlur}
          />
        </Col>
        {isEdit && bonusId && !bonusDetail?.other?.hub88PrepaidDetails?.length &&
          <Col className='d-flex justify-content-end'>
            <Button
              variant='success'
              onClick={() => {
                setChange(true)
                setPageNo(1)
                setLimit(15)
                isTenant
                  ? TAGames()
                  : SAGames()
              }}
            >Add
            </Button>
          </Col>}
      </Row>

      <Row />

      {(loading || gamesLoading || TAGamesLoading)
        ? <InlineLoader />
        : (
          <Row>
            {casinoGamesData &&
              <GamesListEdit
                gameIds={gameIds}
                casinoGamesData={casinoGamesData || []}
                setGameIds={setGameIds}
                page={pageNo}
                limit={limit}
                setLimit={setLimit}
                setPage={setPageNo}
                totalPages={totalPages}
                isEdit={isEdit}
                isTenant={isTenant}
                details={details}
                values={values}
                isHub88={isHub88}
                prepaidsData={prepaidsData}
                setPrepaidsData={setPrepaidsData}
              />}
          </Row>)}

      {!details &&
        <div
          className='mt-4 d-flex justify-content-between align-items-center'
        >
          <Button
            variant='outline-warning'
            onClick={() => setSelectedTab(values?.bonusType === 'freespins' && values?.isSticky ? 'wageringContribution' : values?.bonusType !== 'winBoost' ? 'currency' : 'general')}
          >
            Previous
          </Button>

          <div>
            <Button
              variant='outline-success'
              onClick={() => {
                if (isHub88) {
                  if (Array.from(new Set(prepaidsData?.map(({ gameId }) => gameId)))?.length !== gameIds?.length) {
                    toast('Please Select Prepaids', 'error')
                  } else {
                    handleSubmit()
                  }
                } else { handleSubmit() }
              }}
            >
              Next
              {loading && (
                <Spinner
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              )}
            </Button>
          </div>
        </div>}
    </>
  )
}

export default Games
