import { Col, Form as BForm, Row, Table, Button, Spinner } from '@themesberg/react-bootstrap'
import debounce from 'lodash/debounce'
import React from 'react'
import { useDispatch } from 'react-redux'
import useBannedGamesAffiliates from '../hooks/useBannedGamesAffliates'

/**
 * @typedef {Object} BannedGamesAffiliatesProps
 *
 * @property {Boolean}  readonly
 * @property {Boolean}  create
 * @property {Object}   gameData
 */

/**
 * @param {BannedGamesAffiliatesProps} props
 */
const BannedGamesAffiliates = ({ readonly, create, gameData }) => {
  const dispatch = useDispatch()
  const {
    affiliates,
    navigate,
    setSearch,
    maxBet,
    setMaxBet,
    loading,
    localBannedAffiliates,
    handleSubmit,
    onChangeAffiliateSelect,
    onChangeAffiliateSelectAll
  } = useBannedGamesAffiliates(create)

  const getOnSelectChangeCallback = (affiliateId) => () => {
    onChangeAffiliateSelect(affiliateId)
  }

  const onSearchChange = React.useRef(
    debounce((event) => {
      setSearch(event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))
    }, 500)
  ).current

  const onChangeMaxBetAllowed = (e) => {
    const value = e?.target?.value
    dispatch(setMaxBet(value ? Number(value) : value))
  }

  const onClickSubmit = () => {
    handleSubmit({
      create,
      gameData
    })
  }

  return (
    <>
      <Row>
        <Col>
          <BForm.Label>
            Max. Allowed Bet
          </BForm.Label>
          <BForm.Control
            type='number'
            disabled={readonly}
            placeholder='Enter max. allowed bet'
            size='sm'
            value={maxBet}
            style={{ maxWidth: '250px', marginRight: '10px', maxHeight: '15px' }}
            onChange={onChangeMaxBetAllowed}
          />
        </Col>
      </Row>

      <hr />

      <Row>
        <Col xs='auto'>
          <BForm.Label>
            Search Affiliates
          </BForm.Label>
          <BForm.Control
            type='search'
            placeholder='Search Username'
            size='sm'
            style={{ maxWidth: '230px', marginRight: '10px', maxHeight: '15px' }}
            onChange={onSearchChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='banned-games-table'>
            <Table bordered striped responsive hover size='sm' className='text-center scrollable'>
              <thead className='thead-dark'>
                <tr>
                  {[
                    'ID',
                    'Username',
                    'First Name',
                    'Last Name',
                    'Email',
                    'Active'
                  ].map((h) => (
                    h === 'ID'
                      ? (
                        <th key={h}>
                          {affiliates && affiliates?.length > 0 &&
                            <input
                              disabled={readonly}
                              className='banned-games-checkbox' type='checkbox'
                              onChange={onChangeAffiliateSelectAll}
                              checked={affiliates.length === localBannedAffiliates.length}
                            />}{h}
                        </th>)
                      : <th key={h}>{h}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {
                  affiliates && affiliates.length
                    ? affiliates.map(({
                      username,
                      affiliateId,
                      firstName,
                      lastName,
                      email,
                      isActive,
                      userIdAffiliate
                    }) => {
                      return (
                        <tr key={affiliateId}>
                          <td>
                            <input
                              disabled={readonly}
                              className='banned-games-checkbox'
                              type='checkbox'
                              checked={Boolean(localBannedAffiliates.find((bannedAffiliate) => bannedAffiliate.affiliateId === affiliateId))}
                              name={`affiliate-${affiliateId}`}
                              onChange={getOnSelectChangeCallback(affiliateId)}
                            />
                            {userIdAffiliate}
                          </td>
                          <td>{username || 'NA'}</td>
                          <td>{firstName || 'NA'}</td>
                          <td>{lastName || 'NA'}</td>
                          <td>{email || 'NA'}</td>
                          <td>{isActive ? 'Yes' : 'No'}</td>
                        </tr>
                      )
                    })
                    : (
                      <tr>
                        <td colSpan={7} className='text-danger text-center'>
                          No data found
                        </td>
                      </tr>)
                }
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>

      {!readonly && (
        <Row>
          <div
            className='mt-4 d-flex justify-content-between align-items-center'
          >
            <Button
              variant='outline-warning'
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>

            <div>
              <Button
                variant='outline-success'
                onClick={onClickSubmit}
              >
                Submit
                {loading && (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                )}
              </Button>
            </div>
          </div>
        </Row>
      )}
    </>
  )
}

export default BannedGamesAffiliates
