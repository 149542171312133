
export const getNextTabForBalanceAndPromotionBonus = (selectedTab, languages) => {
  switch (selectedTab) {
    case 'general':
      // If 'general', go to 'languages' if available, otherwise to 'bannedGames'
      return languages?.length ? 'languages' : 'countries'

    case 'languages':
      // If 'languages', move to 'bannedGames'
      return 'countries'

    default:
      // Default case: move to 'countries'
      return 'countries'
  }
}

const getNextTabForFreeSpinBonus = (selectedTab, formValues, languages) => {
  switch (selectedTab) {
    case 'general':
      return languages.length ? 'languages' : 'currency'
    case 'languages':
      return 'currency'
    case 'currency':
      return formValues?.isSticky === 'true' || formValues?.isSticky ? 'wageringContribution' : 'games'
    case 'wageringContribution':
      return 'games'
    case 'games':
      return formValues?.bonusType === 'freespins' ? 'bannedGames' : 'countries'
    case 'bannedGames':
      return 'countries'
    default:
      return 'countries'
  }
}

const getNextTab = (selectedTab, formValues, languages) => {
  const { bonusType } = formValues || {}
  switch (selectedTab) {
    case 'general':
      return languages.length ? 'languages' : (bonusType !== 'winBoost' ? 'currency' : 'loyalty')
    case 'languages':
      return bonusType !== 'winBoost' ? 'currency' : 'loyalty'
    case 'currency':
      if (['deposit', 'wagering', 'winBoost'].includes(bonusType)) {
        return 'loyalty'
      }
      return bonusType === 'zero_deposit' ? 'wageringContribution' : 'payment'
    case 'loyalty':
      return bonusType === 'winBoost' ? 'bannedGames' : 'wageringContribution'
    case 'wageringContribution':
      return 'bannedGames'
    case 'bannedGames' :
      return 'countries'
    default:
      return 'wageringContribution' // Default case if none of the conditions match
  }
}

export const updateSelectedTab = (bonusType, selectedTab, formValues, languages) => {
  let nextTab // Variable to hold the next tab

  // Check if the bonus type is either 'promotion' or 'general'
  if (bonusType === 'promotion' || bonusType === 'balance') {
    // Determine the next tab based on the current selected tab
    nextTab = getNextTabForBalanceAndPromotionBonus(selectedTab, languages)
  } else if (bonusType === 'freespins' || bonusType === 'cashfreespins') {
    // For 'freespins' or 'cashfreespins', use a separate function to determine the next tab
    nextTab = getNextTabForFreeSpinBonus(selectedTab, formValues, languages)
  } else {
    // For other bonus types, use another function to determine the next tab
    nextTab = getNextTab(selectedTab, formValues, languages)
  }
  return nextTab
  // Update the selected tab with the determined next tab
//   setSelectedTab(nextTab)
}

export const determineNextTabForEditBonus = (selected, languages, formValues, bonusDetail) => {
  switch (selected) {
    case 'general':
      if (languages.length) return 'languages'
      if (formValues?.bonusType !== 'winBoost') return 'currency'
      return bonusDetail?.claimedCount ? 'bannedGames' : 'loyalty'

    case 'languages':
      if (formValues?.bonusType !== 'winBoost') {
        if (formValues?.bonusType === 'match' && bonusDetail?.claimedCount) {
          return 'payment'
        }
        if (['deposit', 'wagering'].includes(formValues?.bonusType) && bonusDetail?.claimedCount) {
          return 'bannedGames'
        }
        return 'currency'
      }
      return bonusDetail?.claimedCount ? 'bannedGames' : 'loyalty'

    case 'currency':
      if (['deposit', 'wagering', 'winBoost'].includes(formValues?.bonusType)) {
        return 'loyalty'
      }
      if (formValues?.bonusType === 'zero_deposit') {
        return 'wageringContribution'
      }
      return 'payment'

    case 'loyalty':
      if (formValues?.bonusType === 'winBoost') {
        return 'bannedGames'
      }
      return 'wageringContribution'

    case 'wageringContribution':
      return 'bannedGames'

    case 'bannedGames':
      return 'countries'

    default:
      if (formValues?.bonusType === 'match' && bonusDetail?.claimedCount) {
        return 'bannedGames'
      }
      return 'wageringContribution'
  }
}

export const getNextTabForFreeSpinEditBonus = (selectedTab, languages, bonusDetail, formValues) => {
  if (selectedTab === 'general') {
    if (languages.length) return 'languages'
    return bonusDetail?.claimedCount ? 'bannedGames' : 'currency'
  }

  if (selectedTab === 'languages') {
    return bonusDetail?.claimedCount ? 'bannedGames' : 'currency'
  }

  if (selectedTab === 'currency') {
    if (formValues?.isSticky === 'true' || formValues?.isSticky) {
      return 'wageringContribution'
    }
    return 'games'
  }

  if (selectedTab === 'wageringContribution' || selectedTab === 'currency') {
    return 'games'
  }

  if (selectedTab === 'games') {
    return formValues?.bonusType === 'freespins' ? 'bannedGames' : 'countries'
  }

  if (selectedTab === 'bannedGames') {
    return 'countries'
  }

  // Default fallback
  return 'countries'
}
