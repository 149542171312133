import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  getAllCasinoGamesStart,
  getSuperAdminGameCategoryStart, getSuperAdminGameSubCategoryStart
} from '../../../../store/redux-slices/superAdminCasinoManagement'
import { checkIsTenant } from '../../../../utils/constants'

const useCasino = () => {
  const dispatch = useDispatch()
  const { tenantId } = useParams()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [active, setActive] = useState('')
  const [categoryId, setCategoryId] = useState('')
  const [subCategoryId, setSubCategoryId] = useState('')
  const [selectedProvider, setSelectedProvider] = useState('')

  const { gameSubCategory, gameCategory, casinoGamesData } = useSelector((state) => state.superAdminCasino)
  const { allProviders } = useSelector((state) => checkIsTenant() ? state.tenantCasino : state.superAdminCasino)
  const totalPages = Math.ceil(casinoGamesData?.count / limit)

  const getProviderName = (id) => allProviders && (allProviders?.rows?.length ? allProviders?.rows : allProviders)?.filter(({ masterCasinoProviderId }) => masterCasinoProviderId === id)?.[0]?.name

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (subCategoryId !== '') {
        if (page === 1) {
          dispatch(
            getAllCasinoGamesStart({
              limit,
              pageNo: page,
              casinoCategoryId: subCategoryId,
              search,
              isActive: active,
              tenantId,
              selectedProvider
            })
          )
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    if (subCategoryId !== '') {
      dispatch(
        getAllCasinoGamesStart({
          limit,
          pageNo: page,
          casinoCategoryId: subCategoryId,
          search,
          isActive: active,
          tenantId,
          selectedProvider
        })
      )
    }
  }, [limit, page, active, subCategoryId, selectedProvider])

  useEffect(() => {
    dispatch(getSuperAdminGameCategoryStart({ limit: '', pageNo: '', search: '', tenantId }))
  }, [tenantId])

  useEffect(() => {
    if (categoryId !== '') {
      setSubCategoryId('')
      dispatch(getSuperAdminGameSubCategoryStart({ limit: '', pageNo: '', search: '', isActive: '', gameCategoryId: categoryId, tenantId }))
    }
  }, [tenantId, categoryId])

  return {
    page,
    limit,
    active,
    search,
    setPage,
    setLimit,
    tenantId,
    setSearch,
    setActive,
    totalPages,
    categoryId,
    gameCategory,
    setCategoryId,
    subCategoryId,
    getProviderName,
    gameSubCategory,
    casinoGamesData,
    selectedProvider,
    setSubCategoryId,
    setSelectedProvider
  }
}

export default useCasino
