import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllClientsStart } from '../../../../store/redux-slices/tenants'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { updateTenantAdminStatusStart } from '../../../../store/redux-slices/admins'

const useTenantOwner = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [show, setShow] = useState(false)
  const [active, setActive] = useState()
  const [name, setName] = useState()
  const [status, setStatus] = useState('')
  const [adminId, setAdminId] = useState()

  const { loading, clientsFilterData } = useSelector(state => state.tenants)
  const totalPages = Math.ceil(clientsFilterData?.count / limit)

  const handleShow = (id, active, myName) => {
    setAdminId(id)
    setActive(!active)
    setShow(true)
    setName(myName)
  }

  const handleYes = () => {
    dispatch(
      updateTenantAdminStatusStart({
        limit,
        pageNo: page,
        adminId,
        search: search.replace('+', '%2B'),
        status: active,
        orderBy: 'adminUserId',
        sort: 'asc',
        active: status
      })
    )
    setShow(false)
  }

  const isInitialRender = useDidMountEffect()
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(getAllClientsStart({
            limit,
            pageNo: page,
            search: search.replace('+', '%2B'),
            orderBy: 'adminUserId',
            sort: 'asc',
            status
          }))
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    dispatch(getAllClientsStart({
      limit,
      pageNo: page,
      search: search.replace('+', '%2B'),
      orderBy: 'adminUserId',
      sort: 'asc',
      status
    }))
  }, [limit, page, status])
  return {
    totalPages,
    limit,
    page,
    show,
    setShow,
    clientsFilterData,
    handleYes,
    handleShow,
    navigate,
    setLimit,
    setPage,
    setSearch,
    search,
    loading,
    active,
    name,
    status,
    setStatus,
    dispatch
  }
}

export default useTenantOwner
