import { Form } from '@themesberg/react-bootstrap'
import React from 'react'
import { AMOUNT_TYPE } from '../../pages/Super-Admin/CasinoTransactions/constants'

export const AmountTypeFilter = ({ setAmountType, amountType }) => {
  return (
    <>
      <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
        Amount Type
      </Form.Label>

      <Form.Select
        onChange={(e) => setAmountType(e.target.value)}
        value={amountType}
        size='sm'
        style={{ maxWidth: '230px' }}
      >
        <option value=''>
          All
        </option>
        {AMOUNT_TYPE?.map(
          ({ label, value }) => (
            <option key={label} value={value}>
              {label}
            </option>
          )
        )}
      </Form.Select>
    </>
  )
}
