import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteBonusStart, getBonusStart } from '../../store/redux-slices/bonus'

const useDeleteBonus = (isTenant, setShowDeleteModal) => {
  const dispatch = useDispatch()
  const [showText, setShowText] = useState(false)
  const [name, setName] = useState(false)
  const [bonus, setBonus] = useState({})
  const [type, setType] = useState(false)
  const { loading, bonusDetail } = useSelector(state => state.bonus)

  const handleDelete = (bonusId, type, name) => {
    setShowDeleteModal(true)
    setName(name)
    setType(type)
    setBonus({ bonusId })
    type !== 'balance' && dispatch(getBonusStart({ bonusId, isTenant, userBonusId: '' }))
  }

  const handleDeleteYes = () => {
    dispatch(deleteBonusStart({ data: { ...bonus, balanceBonus: bonusDetail?.balanceBonus }, isTenant, handleClose }))
  }

  const handleClose = () => {
    setShowDeleteModal(false)
    setBonus('')
    setType('')
    setName('')
    setShowText(false)
  }

  useEffect(() => {
    if (bonusDetail?.bonusId === bonus?.bonusId && bonusDetail?.balanceBonus && type !== 'balance') {
      setShowText(true)
    }
  }, [bonusDetail])

  return {
    handleDelete,
    handleDeleteYes,
    handleClose,
    deleteName: name,
    showText,
    loading
  }
}

export default useDeleteBonus
