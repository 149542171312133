import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGlobalRegistrationStart, updateGlobalRegistrationStart } from '../../../store/redux-slices/fetchData'
import { checkIsTenant } from '../../../utils/constants'
import { getUserFieldsStart, updateUserFieldsStart } from '../../../store/redux-slices/tenantUsers'

const useFormFields = () => {
  const dispatch = useDispatch()

  const { formFields, loading } = useSelector((state) => state.fetch)
  const { userFields, loading: TAloading } = useSelector((state) => state.tenantUsers)

  const [data, setData] = useState(null)

  const handleChange = (e, check) => {
    const isVerificationOrDuplicate = (name) => name === 'phoneVerification' || name === 'duplicateUser'
    if (!checkIsTenant()) {
      setData({
        ...data,
        [e.target.name]: isVerificationOrDuplicate(e.target.name)
          ? e.target.checked
          : (e.target.checked ? 2 : 0)
      })
    } else {
      if (check === '') {
        setData({
          ...data,
          [e.target.name]: isVerificationOrDuplicate(e.target.name)
            ? e.target.checked
            : (e.target.checked ? 1 : 0)
        })
      } else {
        setData({
          ...data,
          [e.target.name]: isVerificationOrDuplicate(e.target.name)
            ? e.target.checked
            : (check ? 2 : 1)
        })
      }
    }
  }

  const updateFields = () => {
    const apiData = { ...data }

    if (apiData.newsletterAndSms) {
      apiData.newsLetter = 0
      apiData.sms = 0
    }

    dispatch(checkIsTenant() ? updateUserFieldsStart(apiData) : updateGlobalRegistrationStart({ apiData }))
  }

  useEffect(() => {
    setData(checkIsTenant() ? userFields : formFields)
  }, [formFields, userFields])

  useEffect(() => {
    dispatch(checkIsTenant() ? getUserFieldsStart() : getGlobalRegistrationStart())
  }, [])

  return {
    formFields,
    loading: checkIsTenant() ? TAloading : loading,
    data,
    setData,
    handleChange,
    updateFields
  }
}

export default useFormFields
