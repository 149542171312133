import React, { Suspense, useState } from 'react'
import SimpleBar from 'simplebar-react'
import { CSSTransition } from 'react-transition-group'
import { Nav, Button, Navbar } from '@themesberg/react-bootstrap'
import { useSelector } from 'react-redux'
import { getItem } from '../../utils/storageUtils'
import PlatformLogo from '../../../src/assets/img/brand/AMG_Platform_Logo_no_bg.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import Trigger from '../OverlayTrigger'
const SuperAdminNavItems = React.lazy(() => import('./components/SuperAdminNavItems'))
const TenantNavItems = React.lazy(() => import('./components/TenantNavItems'))

export default (props = {}) => {
  const [show, setShow] = useState(false)
  const showClass = show ? 'show' : ''

  let { role, adminRole, tenantAdminRole } = useSelector((state) => state.login)
  const { adminRoleId, superAdminUser, adminDetails } = useSelector(state => state.admins)

  if (!role) {
    role = getItem('role')
  }

  const roleData =
    adminRole
      ? adminRole?.find(val => val.superRoleId !== 1 && val.superRoleId === parseInt(adminRoleId))?.name
      : tenantAdminRole?.find(val => val.adminRoleId !== 1 && val.adminRoleId === parseInt(superAdminUser?.adminRoleId))?.name

  const onCollapse = () => setShow(!show)

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant='dark'
        className='navbar-theme-primary p-2 d-md-none'
      >
        <div className='d-flex align-items-center justify-content-between w-100'>
          <img style={{ maxWidth: '70px', height: '45px' }} src={PlatformLogo} />
          <Navbar.Toggle
            as={Button}
            aria-controls='main-navbar'
            onClick={onCollapse}
          >
            <span className='navbar-toggler-icon' />
          </Navbar.Toggle>
        </div>

      </Navbar>
      <CSSTransition timeout={300} in={show} classNames='sidebar-transition'>
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white pb-4`}>
          <Navbar.Toggle
            as={Button}
            aria-controls='main-navbar'
            onClick={onCollapse}
            className='navbar-close'
          >
            <FontAwesomeIcon icon={faXmark} />
          </Navbar.Toggle>
          <div className='sidebar-inner px-4 pt-3'>
            <Nav className='flex-column pt-3 pt-md-0'>
              <div className='text-center mb-3'>
                <img style={{ maxWidth: '100px', height: '60px' }} src={PlatformLogo} />
              </div>
              <div className='d-flex flex-column w-100'>
                <h5 className='d-flex m-auto mb-0 align-items-center'>
                  {role}&nbsp;
                  {roleData && <span style={{ fontSize: '15px' }}>({roleData})</span>}
                </h5>
                {(superAdminUser?.email || adminDetails?.email) &&
                  <Trigger message={superAdminUser?.email || adminDetails?.email}>
                    <span
                      style={{
                        fontSize: '15px',
                        textAlign: 'center',
                        whitespace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        cursor: 'pointer'
                      }}
                    >
                      {superAdminUser?.email || adminDetails?.email}
                    </span>
                  </Trigger>}
              </div>
              <hr />

              {role === 'Super Admin' && (
                <Suspense fallback={null}>
                  <SuperAdminNavItems setShow={setShow} />
                </Suspense>
              )}

              {role === 'Admin' &&
                <Suspense fallback={null}>
                  <TenantNavItems setShow={setShow} />
                </Suspense>}
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  )
}
