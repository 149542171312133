import React from 'react'
import BannerForm from './BannerForm'
import { uploadBannerSchema, uploadTABannerSchema } from '../schema'
import { ErrorMessage, Form, Formik } from 'formik'
import useEditBanners from '../hooks/useEditBanners'
import ClientFilter from '../../../../components/ClientFilter'
import PortalFilter from '../../../../components/PortalFilter'
import {
  bannerType,
  checkIsTenant,
  createBannerKeys,
  isTenant
} from '../../../../utils/constants'
import {
  Col,
  Row,
  Form as BForm,
  Button,
  Tabs,
  Tab,
  Spinner
} from '@themesberg/react-bootstrap'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Preloader from '../../../../components/Preloader'
import FileComponent from './FileComponent'
import Trigger from '../../../../components/OverlayTrigger'

const EditBanners = () => {
  const {
    key,
    type,
    setKey,
    loading,
    navigate,
    isHovering,
    createUpdate,
    selectedClient,
    selectedPortal,
    handleMouseOut,
    handleMouseOver,
    tenantLanguages,
    transformedBanner,
    setSelectedPortal,
    setSelectedClient,
    selectedBannerTab,
    selectedLanguageTab,
    setSelectedBannerTab,
    setSelectedLanguageTab
  } = useEditBanners()

  return (
    <>
      <Row>
        <Col sm={8}>
          <h3>Edit Banners</h3>
        </Col>
      </Row>

      {loading
        ? <Preloader />
        : (
          <Formik
            enableReinitialize
            initialValues={{
              key: key || '',
              adminId: selectedClient || '',
              tenantId: selectedPortal || '',
              banners: transformedBanner?.length
                ? transformedBanner
                : [
                    {
                      bannerImage: '',
                      bannerBackground: '',
                      EN: { ...createBannerKeys }
                    }
                  ]
            }}
            validationSchema={checkIsTenant() ? uploadTABannerSchema : uploadBannerSchema}
            onSubmit={(formValues) => {
              const keysToPick = [
                'bannerImage',
                'bannerBackground',
                'image',
                'background'
              ].concat(tenantLanguages || [])
              createUpdate({
                banner: {
                  [formValues.key]: formValues?.banners.map((obj) => {
                    const newObj = {}
                    keysToPick.forEach((key) => {
                      newObj[key] = obj[key]
                    })
                    return newObj
                  }),
                  tenantId: formValues.tenantId
                }
              })
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
              handleBlur
            }) => (
              <Form className='align-items-center'>
                <Row className='mt-3 d-flex'>
                  {!isTenant && (
                    <Col xs='auto'>
                      <ClientFilter
                        disabled={type === 'view'}
                        setFieldValue={setFieldValue}
                        selectedClient={selectedClient}
                        setSelectedClient={setSelectedClient}
                        setSelectedPortal={setSelectedPortal}
                      />
                      <ErrorMessage
                        component='div'
                        name='adminId'
                        className='text-danger'
                      />
                    </Col>
                  )}

                  {selectedClient && !isTenant && (
                    <Col xs='auto'>
                      <PortalFilter
                        disabled={type === 'view'}
                        setFieldValue={setFieldValue}
                        selectedClient={selectedClient}
                        selectedPortal={selectedPortal}
                        setSelectedPortal={setSelectedPortal}
                      />
                      <ErrorMessage
                        component='div'
                        name='tenantId'
                        className='text-danger'
                      />
                    </Col>
                  )}

                  {/* Key */}
                  <Col>
                    <BForm.Label>Key</BForm.Label>

                    <BForm.Select
                      size='sm'
                      name='key'
                      value={values.key}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setKey(e.target.value)
                        handleChange(e)
                      }}
                      style={{ maxWidth: '200px', marginRight: '10px' }}
                    >
                      <option value='' disabled>
                        ---Select---
                      </option>
                      {bannerType?.map(({ label, value }) => {
                        return (
                          <option value={value} key={value}>
                            {label}
                          </option>
                        )
                      })}
                    </BForm.Select>
                    <ErrorMessage
                      component='div'
                      name='key'
                      className='text-danger'
                    />
                  </Col>
                </Row>

                {/* Banners Data */}
                <div className='mt-2'>
                  <div className='d-flex align-items-center'>
                    <h5 className='mb-0'>Banners</h5>

                    <Trigger message='Add More Banners'>
                      <Button
                        size='sm'
                        className='m-2 max-content f-right'
                        variant='outline-success'
                        hidden={type === 'view'}
                        onClick={() =>
                          setFieldValue('banners', [
                            ...values?.banners,
                            {
                              bannerImage: '',
                              bannerBackground: '',
                              EN: { ...createBannerKeys }
                            }
                          ])}
                      >
                        Add <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </Trigger>
                  </div>
                  <Tabs
                    className='nav-light mt-0'
                    activeKey={selectedBannerTab}
                    onSelect={(tab) => !isHovering && setSelectedBannerTab(tab)}
                  >
                    {values?.banners && Object.keys(values?.banners)?.map((data, index) => (
                      <Tab
                        eventKey={index}
                        key={index}
                        title={
                          <div className='d-flex align-items-center justify-content-around'>
                            <span>Banner {index + 1}</span>{' '}
                            {Object.keys(values?.banners).length > 1 && (
                              <Button
                                size='sm'
                                className='text-danger'
                                variant='outline-danger'
                                hidden={type === 'view'}
                                onMouseOut={handleMouseOut}
                                onMouseOver={handleMouseOver}
                                onClick={() => {
                                  setSelectedBannerTab(0)
                                  setSelectedLanguageTab('EN')
                                  setFieldValue(
                                    'banners',
                                    values?.banners.filter(
                                      (key, indx) => indx !== index
                                    )
                                  )
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            )}
                          </div>
                  }
                      >
                        <Row className='mt-4 d-flex p-2'>
                          <Col>
                            <FileComponent
                              type={type}
                              index={index}
                              values={values}
                              errors={errors}
                              key='bannerImage'
                              fieldName='bannerImage'
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              pseudoFieldName='image'
                            />
                          </Col>
                          <Col>
                            <FileComponent
                              type={type}
                              index={index}
                              values={values}
                              errors={errors}
                              key='bannerBackground'
                              fieldName='bannerBackground'
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              pseudoFieldName='background'
                            />
                          </Col>
                          <div className='mt-5 p-2'>
                            <h5>Languages</h5>
                            <BannerForm
                              type={type}
                              key={index}
                              index={index}
                              values={values}
                              handleChange={handleChange}
                              tenantLanguages={tenantLanguages}
                              selectedLanguageTab={selectedLanguageTab}
                              setSelectedLanguageTab={setSelectedLanguageTab}
                            />
                          </div>
                        </Row>
                      </Tab>
                    ))}
                  </Tabs>
                </div>

                {type !== 'view' &&
                  <div className='mt-4 d-flex justify-content-between'>
                    <Button
                      variant='outline-warning'
                      onClick={() => navigate(-1)}
                      className='ml-2'
                    >
                      Cancel
                    </Button>
                    <Button
                      className='ml-2'
                      variant='outline-success'
                      disabled={loading}
                      onClick={handleSubmit}
                    >
                      Submit
                      {loading && (
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      )}
                    </Button>
                  </div>}
              </Form>
            )}
          </Formik>
          )}
    </>
  )
}

export default React.memo(EditBanners)
