import { Button, Col, Form, Modal, Row } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'

const IDModal = ({
  show,
  setShow,
  handleYes,
  supportsWithdrawal = false,
  supportsDeposit = false
}) => {
  const [data, setData] = useState('')
  const [deposit, setDeposit] = useState(supportsDeposit)
  const [withdraw, setWithdraw] = useState(supportsWithdrawal)
  const [select, setSelect] = useState(true)
  const [showError, setShowError] = useState({
    typeError: false,
    dataError: ''
  })

  const reset = () => {
    setData('')
    setDeposit(false)
    setWithdraw(false)
    setShowError({
      typeError: false,
      dataError: ''
    })
    setShow(false)
    setSelect(true)
  }

  useEffect(() => {
    if (show) {
      setData('')
      setDeposit(supportsDeposit)
      setWithdraw(supportsWithdrawal)
      setShowError({
        typeError: false,
        dataError: ''
      })
      setSelect(true)
    }
  }, [show])

  return (
    <Modal show={show} onHide={() => setShow(false)} size='lg'>
      <Modal.Header><h4>User IDS to Restrict/Un-Restrict</h4></Modal.Header>

      <Modal.Body>
        <Row className='d-flex mb-3'>
          {(supportsDeposit || supportsWithdrawal) && <h6>Select Type</h6>}
          {supportsDeposit &&
            <Col>
              <Form.Check
                readOnly
                type='checkbox'
                label='Deposit'
                checked={deposit}
                onClick={() => {
                  setDeposit(!deposit)
                  data?.typeError && setShowError({
                    typeError: deposit || withdraw,
                    dataError: data?.dataError
                  })
                }}
              />
            </Col>}

          {supportsWithdrawal &&
            <Col>
              <Form.Check
                readOnly
                type='checkbox'
                label='Withdraw'
                checked={withdraw}
                onClick={() => {
                  setWithdraw(!withdraw)
                  data?.typeError && setShowError({
                    typeError: deposit || withdraw,
                    dataError: data?.dataError
                  })
                }}
              />
            </Col>}

          {showError?.typeError && !(deposit || withdraw) && <p className='text-danger'>Please Select a type</p>}
        </Row>

        <Row>
          <Col>
            <label className='d-flex'><h6>Enter User IDS to Restrict/Un-Restrict (<small className='text-danger'>Add IDs comma separated like 1,2,3</small>)</h6></label>
            <div className='d-flex'>
              <Form.Check
                readOnly
                size='sm'
                className='me-2'
                type='checkbox'
                label='Restrict'
                checked={select}
                onClick={() => setSelect(true)}
              />
              <Form.Check
                readOnly
                size='sm'
                type='checkbox'
                label='Un-Restrict '
                checked={!select}
                onClick={() => setSelect(false)}
              />
            </div>
            <Form.Control
              name='data'
              type='text'
              as='textarea'
              className='mt-3'
              placeholder='Enter IDS'
              value={data}
              onChange={(e) => {
                setData(e.target.value)
                data?.dataError !== '' && setShowError({
                  typeError: data?.typeError,
                  dataError: ''
                })
              }}
            />
          </Col>

          {showError?.dataError && <p className='text-danger'>{showError?.dataError}</p>}
        </Row>
      </Modal.Body>

      <Modal.Footer className='d-flex justify-content-between'>
        <Button
          variant='outline-primary' onClick={() => {
            setShow(false)
            reset()
          }}
        >
          No
        </Button>
        <Button
          variant='outline-secondary' onClick={() => {
            const myData = data ? data.split(',') : ''
            const newData = Array.isArray(myData) ? myData.filter(el => el.length && Number(el) === +Number(el)) : ''
            if ((deposit || withdraw) && newData?.length) {
              handleYes(newData, deposit ? withdraw ? 'both' : 'deposit' : 'withdraw', select)
              reset()
            } else {
              setShowError({
                typeError: true,
                dataError: !newData ? 'IDs Required' : false
              })
            }
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default IDModal
