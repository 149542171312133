import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTenantDetailsStart } from '../../../../../store/redux-slices/login'
import { getTALanguageStart, setTALanguageStart } from '../../../../../store/redux-slices/languages'
import useCheckPermission from '../../../../../utils/checkPermission'

const useLanguages = () => {
  const dispatch = useDispatch()
  const { isHidden } = useCheckPermission()
  const [showCreate, setShowCreate] = useState(false)
  const [name, setName] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const { tenantDetails, languageLoading } = useSelector(state => state?.login)
  const { TALanguages } = useSelector(state => state?.languages)

  useEffect(() => {
    if (!tenantDetails?.tenantConfig?.allowedLanguages) {
      dispatch(fetchTenantDetailsStart())
    }
  }, [tenantDetails?.tenantConfig?.allowedLanguages])

  const handleCreate = (data) => {
    dispatch(setTALanguageStart({ data: { type: 'add', languageCode: data?.map(({ value }) => value) } }))
    setShowCreate(false)
  }

  const handleDeleteYes = () => {
    setShowDeleteModal(false)
    dispatch(setTALanguageStart({ data: { type: 'remove', languageCode: name } }))
  }

  useEffect(() => {
    if (!TALanguages) {
      dispatch(getTALanguageStart())
    }
  }, [])

  const languageOptions = []
  TALanguages &&
    TALanguages.rows.forEach(function (language) {
      !tenantDetails?.tenantConfig?.allowedLanguages?.includes(language?.code) && languageOptions.push({ label: language.languageName, value: language.code })
    })

  return {
    name,
    setName,
    isHidden,
    showCreate,
    handleCreate,
    tenantDetails,
    setShowCreate,
    languageLoading,
    languageOptions,
    showDeleteModal,
    handleDeleteYes,
    setShowDeleteModal
  }
}

export default useLanguages
