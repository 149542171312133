import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getBannedPlayersStart,
  addBannedPlayerStart,
  editBannedPlayerStart,
  deleteBannedPlayerStart
} from '../../../store/redux-slices/bannedPlayer'
// Custom hook for managing banned players
const useBannedUsers = ({ isTenant }) => {
  const dispatch = useDispatch() // Hook to access Redux dispatch

  // State variables for pagination and filtering
  const [limit, setLimit] = useState(15) // Number of banned players to display per page

  const [page, setPage] = useState(1) // Current page number

  const [search, setSearch] = useState('') // Search query for filtering banned players

  const [selectedClient, setSelectedClient] = useState('') // Selected client ID for filtering

  const [selectedPortal, setSelectedPortal] = useState('') // Selected portal ID for filtering

  const [isBannedPlayerFormVisible, setIsBannedPlayerFormVisible] = useState(false) // State for showing the banned player form

  const [showBannedPlayerDeleteModal, setShowBannedPlayerDeleteModal] = useState(false) // State for showing delete confirmation modal

  const [selectedBannedPlayer, setSelectedBannedPlayer] = useState('') // Currently selected banned player for editing or deletion

  const [isEditing, setIsEditing] = useState(false)

  const [isMounted, setIsMounted] = useState(false)

  // Selector to retrieve banned players and loading state from Redux store
  const { bannedPlayers, loading } = useSelector((state) => state.bannedPlayer)

  const { tenantDetails } = useSelector((state) => state.login)
  // Calculate total number of pages based on the count of banned players and the limit

  const totalPages = Math.ceil(bannedPlayers?.count / limit)

  // Parameters for fetching banned players

  // Function to dispatch action to fetch banned players
  const getBannedPlayer = () => {
    const bannedPlayerParams = {
      ownerId: !isTenant ? selectedClient : (tenantDetails?.adminUserId || ''),
      tenantId: !isTenant ? selectedPortal : [tenantDetails?.tenantId],
      limit,
      pageNo: page,
      search
    }
    dispatch(
      getBannedPlayersStart({
        ...bannedPlayerParams
      })
    )
  }

  // Effect to handle search input with debounce
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isMounted) {
        if (page === 1) {
          // Fetch banned players when on the first page and search input changes
          getBannedPlayer()
        } else {
          // Reset to first page if search input changes
          setPage(1)
        }
      }
    }, 1000) // Delay of 1000ms for debounce

    return () => clearTimeout(delayDebounceFn) // Cleanup on unmount
  }, [search])

  useEffect(() => {
    setIsMounted(true)
  }, [])

  // Effect to fetch banned players when dependencies change
  useEffect(() => {
    getBannedPlayer() // Fetch banned players on limit, page, client, or portal change
  }, [limit, page, selectedClient, selectedPortal, tenantDetails])

  // Opens the edit modal for the selected banned player
  const openEditBannedPlayerModal = (bannedPlayer) => {
    setSelectedBannedPlayer(bannedPlayer) // Set the selected banned player for editing
    setIsEditing(true)
    setIsBannedPlayerFormVisible(true) // Show the banned player form modal
  }

  // Handles the submission of the banned player form for both adding and editing
  const handleBannedPlayerSubmit = (playerData) => {
    if (playerData.bannedPlayerId) {
      dispatch(
        editBannedPlayerStart({
          formValues: playerData,
          setIsBannedPlayerFormVisible,
          setSelectedBannedPlayer,
          getBannedPlayer
        })
      )
    } else {
      const BAN_ACROSS_MULTIPLE_BRANDS = 'ban_across_multiple_brands'
      const BAN_ACROSS_LICENSE = 'ban_across_license'

      const modifiedPlayerData = { ...playerData }

      if (playerData.bannedType === BAN_ACROSS_MULTIPLE_BRANDS) {
        modifiedPlayerData.ownerId = null // Replace ownerId with null
      }

      if (isTenant) {
        modifiedPlayerData.ownerId = null // Replace ownerId with null
      }

      if (playerData.bannedType === BAN_ACROSS_LICENSE) {
        modifiedPlayerData.tenantIds = [] // Replace tenantIds with an empty array
      }

      delete modifiedPlayerData.bannedType
      dispatch(
        addBannedPlayerStart({
          formValues: modifiedPlayerData,
          setIsBannedPlayerFormVisible,
          setSelectedBannedPlayer,
          getBannedPlayer
        })
      )
    }
  }

  // Handles the selection and confirmation of deleting a banned player
  const confirmDeleteBannedPlayer = (playerDetail) => {
    setSelectedBannedPlayer(playerDetail) // Set the selected banned player for deletion
    setShowBannedPlayerDeleteModal(true) // Open the delete confirmation modal
  }

  // Dispatches action to start deleting the selected banned player by ID
  const deleteBannedPlayer = () => {
    const formValues = { bannedPlayerId: selectedBannedPlayer.id }
    dispatch(deleteBannedPlayerStart({ formValues, setShowBannedPlayerDeleteModal, getBannedPlayer }))
  }

  // Return values and functions to be used in the component
  return {
    limit,
    setLimit,
    page,
    setPage,
    search,
    setSearch,
    totalPages,
    selectedClient,
    selectedPortal,
    setSelectedPortal,
    setSelectedClient,
    loading,
    bannedPlayers,
    isBannedPlayerFormVisible,
    setIsBannedPlayerFormVisible,
    openEditBannedPlayerModal,
    handleBannedPlayerSubmit,
    showBannedPlayerDeleteModal,
    setShowBannedPlayerDeleteModal,
    selectedBannedPlayer,
    setSelectedBannedPlayer,
    confirmDeleteBannedPlayer,
    deleteBannedPlayer,
    isEditing,
    setIsEditing
  }
}

export default useBannedUsers // Exporting the custom hook for use in other components
