import * as Yup from 'yup'

export const passwordValidation = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
      'Invalid Password'
    )
    .max(50)
    .required('Password Required')
})

export const paymentSchema = Yup.object().shape({
  amount: Yup.number()
    .typeError('Only numbers are allowed')
    .min(0, 'Amount should be greater than 0')
    .required('Amount Required'),
  transactionType: Yup.string()
    .required('Transaction type required'),
  paymentTransactionId: Yup.string()
    .required('Transaction Id required'),
  paymentProviderId: Yup.string()
    .required('Payment Provider required'),
  paymentMethod: Yup.string()
    .required('Payment Method required'),
  aggregatorMethod: Yup.string()
    .required('Aggregator Required'),
  comment: Yup.string()
    .required('Command is Required.')
})
