import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getAdminUserDetails } from '../../../../utils/apiCalls'

const useEditAdminUser = () => {
  const { tenantAdminUserId } = useParams()
  const adminUserId = parseInt(tenantAdminUserId)
  const [data, setData] = useState()

  useEffect(() => {
    function fetchData () {
      getAdminUserDetails({ adminUserId, isTenant: true })
        .then((res) => {
          setData(res?.data?.data?.adminDetails)
        })
    }
    fetchData()
  }, [tenantAdminUserId])

  return {
    data,
    adminUserId
  }
}

export default useEditAdminUser
