import React from 'react'
import { useSearchParams } from 'react-router-dom'
import useEditBannedGames from '../hooks/useEditBannedGames'
import BannedGamesDetailsListing from './BannedGamesDetailsListing'
import { Col, Row, Form as BForm, Button, Spinner, Tabs, Tab } from '@themesberg/react-bootstrap'
import { toast } from '../../../../components/Toast'
import { setBannedGamesName } from '../../../../store/redux-slices/superAdminTransactions'
import ProvidersFilter from '../../../../components/ProviderFilter'
import BannedGamesAffiliates from './BannedGamesAffiliates'

const EditBannedGames = () => {
  const [searchParams] = useSearchParams()
  const {
    loading,
    navigate,
    page,
    limit,
    setLimit,
    setPage,
    getBannedGamesListDetail,
    totalBannedGamesPages,
    bannedGamesListId,
    isTenant,
    isClone,
    listBannedGames,
    setListBannedGames,
    listName,
    dispatch,
    handleSubmit,
    selectedProvider,
    setSelectedProvider,
    search,
    setSearch,
    allProviders,
    selectedTab,
    setSelectedTab
  } = useEditBannedGames()

  const tenantId = searchParams.get('tenantId')

  return (
    <>
      <Row>
        <Col xs='auto'>
          <h3>{isClone ? 'Clone' : 'Edit'} Banned Games: {loading ? '' : getBannedGamesListDetail?.bannedGamesListName || ''}</h3>
        </Col>
      </Row>

      <Row>
        <Col>
          <Tabs
            activeKey={selectedTab}
            onSelect={(tab) => setSelectedTab(tab)}
            className='nav-light'
          >
            <Tab eventKey='games' title='Games'>
              <div className='mt-4'>
                <Row>
                  <Col>
                    <Row>
                      <Col xs='auto'>
                        <BForm.Label>
                          Banned Games List Name <span className='text-danger'>*</span>
                        </BForm.Label>

                        <BForm.Control
                          type='text'
                          name='name'
                          size='sm'
                          style={{ maxWidth: '400px' }}
                          placeholder='Enter Name'
                          value={listName}
                          onChange={(e) => dispatch(setBannedGamesName(e.target.value))}
                        />
                      </Col>

                      <Col xs='auto' className='align-items-center provider'>
                        <ProvidersFilter
                          selectedProvider={selectedProvider}
                          setSelectedProvider={setSelectedProvider}
                          isTenantRoute={isTenant}
                        />
                      </Col>

                      <Col xs='auto'>
                        <BForm.Label>
                          Search
                        </BForm.Label>
                        <BForm.Control
                          type='search'
                          placeholder='Search Banned Games'
                          size='sm'
                          style={{ maxWidth: '230px', marginRight: '10px', maxHeight: '15px' }}
                          value={search}
                          onChange={(event) =>
                            setSearch(
                              event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                            )}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col className='d-flex justify-content-end align-items-start'>
                    <Button
                      className='banned-games-save-button'
                      size='sm'
                      variant='outline-success'
                      onClick={() => {
                        if (!listName) {
                          return toast('Please Enter Name', 'error')
                        }
                        navigate(`/${isTenant ? 'tenant' : 'super-admin'}/banned-games/${isClone ? 'clone' : 'edit'}/add-games/${bannedGamesListId}`)
                      }}
                    >
                      ADD GAMES
                    </Button>
                  </Col>
                </Row>

                <BannedGamesDetailsListing
                  pageNo={page}
                  setPageNo={setPage}
                  totalPages={totalBannedGamesPages}
                  limit={limit}
                  setLimit={setLimit}
                  getBannedGamesListDetail={getBannedGamesListDetail.gameDetail}
                  actionButton
                  bannedGamesListId={bannedGamesListId}
                  isTenant={isTenant}
                  isClone={isClone}
                  listBannedGames={listBannedGames}
                  setListBannedGames={setListBannedGames}
                  selectedProvider={selectedProvider}
                />

                <div
                  className='mt-4 d-flex justify-content-between align-items-center'
                >
                  <Button
                    variant='outline-warning'
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>

                  <div>
                    <Button
                      variant='outline-success'
                      hidden={!(allProviders?.rows?.length > 0 || allProviders?.length > 0)}
                      onClick={() => { handleSubmit() }}
                    >
                      Submit
                      {loading && (
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </Tab>

            {Boolean(tenantId) && (
              <Tab eventKey='affiliates' title='Settings'>
                <div className='mt-4'>
                  <BannedGamesAffiliates />
                </div>
              </Tab>
            )}
          </Tabs>
        </Col>
      </Row>
    </>
  )
}

export default EditBannedGames
