import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isTenant } from '../../../../utils/constants'
import useCheckPermission from '../../../../utils/checkPermission'
import { getAllSABannersStart } from '../../../../store/redux-slices/superAdminSettings'

const useBannerManagement = () => {
  const dispatch = useDispatch()
  const { isHidden } = useCheckPermission()
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const { tenantDetails } = useSelector(state => state.login)
  const { clientsFilterData, portalsFilterData } = useSelector(state => state.tenants)
  const { loading: SALoading, SABanners } = useSelector(state => state.superAdminSettings)

  useEffect(() => {
    (isTenant
      ? tenantDetails?.tenantId
      : true) && dispatch(getAllSABannersStart({
      adminId: selectedPortal ? '' : selectedClient,
      tenantId: isTenant ? tenantDetails?.tenantId : selectedPortal
    }))
  }, [selectedPortal, selectedClient, tenantDetails])

  return {
    dispatch,
    isHidden,
    selectedPortal,
    selectedClient,
    setSelectedClient,
    setSelectedPortal,
    clientsFilterData,
    portalsFilterData,
    banners: SABanners,
    loading: SALoading
  }
}

export default useBannerManagement
