import React from 'react'
import { Table } from '@themesberg/react-bootstrap'
import { getPercentageColor, getTextColor } from '../../utils/dashboardEffects'
import { internationalNumberFormatter } from '../../utils/helpers'
import sortArrowIcon from '../SortArrowsIcon'

const GameReportTable = ({ tableData, isPlayer, sortBy, setSortBy, hover, setHover }) => {
  return (
    <div style={{ overflow: 'auto', maxHeight: '385px' }}>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {isPlayer
              ? ['Id',
                  'Name',
                  'Number of Rounds',
                  'Total Bets',
                  'Total Wins',
                  'Game Revenue',
                  'Payout'
                ].map((h) => (
                  <th key={h}>{h}</th>
                ))
              : [
                  'Id',
                  'Name',
                  'Number of Rounds',
                  'Number of Player',
                  'Total Bets',
                  'Total Wins',
                  'Game Revenue',
                  'Payout'
                ].map((h) => (
                  <th key={h}>
                    {h}
                    {sortArrowIcon(h, sortBy, setSortBy, hover, setHover, 'gameReport')}
                  </th>
                ))}
          </tr>
        </thead>

        <tbody>
          {tableData && tableData.length > 0
            ? tableData.map(({ id, name, roundCount, playerCount, totalBet, totalWin, GGR, payout }, idx) => {
              return (
                <tr key={`top-loosers ${id}`}>
                  <td>{id}</td>
                  <td style={{ textAlign: 'start' }}>
                    {name}
                  </td>
                  <td>{internationalNumberFormatter(roundCount) || '0'}</td>
                  {!isPlayer && <td>{internationalNumberFormatter(playerCount) || '0'}</td>}
                  <td style={{ color: 'green' }}>€ {internationalNumberFormatter(totalBet) || '0'}</td>
                  <td style={{ color: 'red' }}>€ {internationalNumberFormatter(totalWin) || '0'}</td>
                  <td className={`${getTextColor(GGR)}`}>€ {internationalNumberFormatter(GGR) || '0'}</td>
                  <td className={getPercentageColor(payout)}>{payout || '0'}</td>
                </tr>
              )
            })
            : (
              <tr>
                <td colSpan={8} className='text-danger text-center'>
                  No data found
                </td>
              </tr>
              )}
        </tbody>
      </Table>
    </div>
  )
}

export default GameReportTable
