import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, InputGroup, Spinner } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Col, Row, Form as BForm } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetProfilePasswordStart } from '../../store/redux-slices/login'
import { profilePasswordSchema } from './schema'

const Password = ({
  loading,
  isTenant
}) => {
  const formData = {
    OLD: 'password',
    NEW: 'newPassword',
    CONFIRM: 'confirmPassword'
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showPass, setShowPass] = useState({
    OLD: false,
    NEW: false,
    CONFIRM: false
  })

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          password: '',
          newPassword: '',
          confirmPassword: ''
        }}
        validationSchema={profilePasswordSchema}
        onSubmit={(formValues) => {
          dispatch(resetProfilePasswordStart({
            data: {
              password: formValues?.password ? Buffer.from(formValues?.password).toString('base64') : '',
              newPassword: formValues?.newPassword ? Buffer.from(formValues?.newPassword).toString('base64') : ''
            },
            navigate,
            isTenant
          }))
        }}
      >
        {({ values, handleChange, handleSubmit, handleBlur }) => {
          return (
            <Form className='mt-5'>
              {Object.keys(formData)?.map((key, index) => (
                <Row key={index} className='mt-3'>
                  <Col xs={6}>
                    <BForm.Label>
                      {key} PASSWORD
                      <span className='text-danger'> *</span>
                    </BForm.Label>
                    <InputGroup>
                      <BForm.Control
                        type={showPass?.[key] ? 'text' : 'password'}
                        name={formData[key]}
                        value={values?.[formData[key]]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <InputGroup.Text style={{ border: '0.0625rem solid #d1d7e0' }}>
                        {!showPass?.[key]
                          ? <FontAwesomeIcon
                              icon={faEye} style={{ cursor: 'pointer' }}
                              onClick={() => { setShowPass({ ...showPass, [key]: true }) }}
                            />
                          : <FontAwesomeIcon
                              icon={faEyeSlash} style={{ cursor: 'pointer' }}
                              onClick={() => { setShowPass({ ...showPass, [key]: false }) }}
                            />}
                      </InputGroup.Text>
                    </InputGroup>

                    <ErrorMessage
                      component='div'
                      name={formData[key]}
                      className='text-danger'
                    />
                  </Col>
                </Row>))}
              <div className='mt-4 mb-3'>
                <Button
                  variant='outline-success'
                  onClick={handleSubmit}
                  className='ml-2'
                >
                  Submit
                  {loading && (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  )}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default Password
