import React from 'react'
import {
  Button,
  Form,
  Row,
  Col,
  Table,
  ButtonGroup,
  Modal
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckSquare,
  faWindowClose,
  faBan
} from '@fortawesome/pro-solid-svg-icons'
import PaginationComponent from '../../../components/Pagination'
import CreateCasinoGames from './components/CreateCasinoGames'
import ConfirmationModal from '../../../components/ConfirmationModal'
import useCasinoGamesListing from './hooks/useCasinoGamesListing'
import Trigger from '../../../components/OverlayTrigger'
import { tableHeaders } from './constants'
import ProviderFilter from '../../../components/ProviderFilter'
import useCheckPermission from '../../../utils/checkPermission'
import GameIdsModal from './components/GameIdsModal'
import { formatDateYMD } from '../../../utils/dateFormatter'

export default () => {
  const {
    limit,
    page,
    selectedSubCategoryId,
    show,
    data,
    type,
    statusShow,
    setLimit,
    setPage,
    setSelectedSubCategoryId,
    setStatusShow,
    gameSubCategory,
    casinoGamesData,
    totalPages,
    handleStatusShow,
    handleYes,
    handleClose,
    // handleShow,
    loading,
    getCategoryName,
    search,
    setSearch,
    active,
    setActive,
    status,
    getProviderName,
    navigate,
    setSelectedProvider,
    selectedProvider,
    name,
    showGameIds,
    setShowGameIds
  } = useCasinoGamesListing()
  const { isHidden } = useCheckPermission()

  return (
    <>
      <Row>
        <Col sm={8}>
          <h3>Master Casino Games</h3>
        </Col>
      </Row>

      <Row className='mt-2 mb-3'>
        <Col xs='auto'>
          <div className='d-flex justify-content-start align-items-center w-100 mb-3'>
            <ProviderFilter
              selectedProvider={selectedProvider}
              setSelectedProvider={setSelectedProvider}
            />
          </div>
        </Col>
        <Col xs='auto'>
          <div className='d-flex justify-content-start align-items-center w-100 mb-3'>
            <Form.Label column='sm' style={{ marginRight: '15px', minWidth: 'fit-content' }}>
              Sub Category
            </Form.Label>

            <Form.Select
              onChange={(e) => {
                setSelectedSubCategoryId(e.target.value)
              }}
              value={selectedSubCategoryId || 'all'}
              size='sm'
              style={{ maxWidth: '230px' }}
            >
              <option value=''>All</option>
              {gameSubCategory?.rows?.map(
                ({ masterGameSubCategoryId, name }) => (
                  <option key={masterGameSubCategoryId} value={masterGameSubCategoryId}>
                    {name?.EN}
                  </option>
                )
              )}
            </Form.Select>
          </div>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-start align-items-center w-100'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              Search
            </Form.Label>
            <Form.Control
              type='search'
              value={search}
              placeholder='Search Name'
              size='sm'
              style={{ maxWidth: '230px' }}
              onChange={(event) => setSearch(event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
            />
          </div>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-start align-items-center w-100'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              Status
            </Form.Label>

            <Form.Select
              onChange={(e) => { setActive(e.target.value) }}
              value={active}
              size='sm'
              style={{ maxWidth: '230px' }}
            >
              <option value=''>All</option>
              <option value='true'>Active</option>
              <option value='false'>In-Active</option>
            </Form.Select>
          </div>
        </Col>

        <Col>
          <Button variant='outline-success' size='sm' onClick={() => setShowGameIds(true)}>
            Get Game IDS
          </Button>
        </Col>

      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, idx) => (
              <th
                key={idx}
                className={`${h.value === 'returnToPlayer' ? 'text-left' : ''}`}
              >
                {h.label}
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && Boolean(casinoGamesData) &&
                      casinoGamesData?.rows?.map(
                        (
                          {
                            name,
                            masterCasinoGameId,
                            thumbnailUrl,
                            masterGameSubCategoryId,
                            masterCasinoProviderId,
                            isActive,
                            operatorStatus,
                            devices,
                            returnToPlayer,
                            moreDetails,
                            featureGroup,
                            MasterCasinoProvider
                          },
                          indx
                        ) => {
                          return (
                            <tr key={masterCasinoGameId}>
                              <td>{masterCasinoGameId}</td>

                              <td>
                                {name}
                              </td>
                              <td className='text-capitalize'>{MasterCasinoProvider?.MasterGameAggregator?.name}</td>

                              <td className='text-capitalize'>{getProviderName(masterCasinoProviderId)}</td>

                              <td className='text-left'>{returnToPlayer || '-'}</td>

                              <td>{getCategoryName(masterGameSubCategoryId)}</td>

                              <td>
                                <span
                                  onClick={() => window.open(thumbnailUrl)}
                                  className='text-link'
                                  style={{ cursor: 'pointer' }}
                                >
                                  View Here
                                </span>
                              </td>

                              <td className='text-capitalize'>{devices?.length ? devices?.map(type => type).join(', ') : 'None'}</td>

                              <td>
                                {isActive
                                  ? (
                                    <span className='text-success'>Active</span>
                                    )
                                  : (
                                    <span className='text-danger'>In Active</span>
                                    )}
                              </td>
                              <td>{featureGroup || '-'}</td>
                              <td>{moreDetails?.released_at ? formatDateYMD(moreDetails.released_at) : '-'}</td>

                              <td>
                                {operatorStatus
                                  ? (
                                    <span className='text-success'>Active</span>
                                    )
                                  : (
                                    <span className='text-danger'>In Active</span>
                                    )}
                              </td>

                              <td>
                                {(!isHidden({ module: { key: 'CasinoManagement', value: 'U' } }) || !isHidden({ module: { key: 'CasinoManagement', value: 'T' } }))
                                  ? (
                                    <ButtonGroup>

                                      {!isActive
                                        ? (
                                          <Trigger message='Set Status Active'>
                                            <Button
                                              className='m-1'
                                              size='sm'
                                              variant='success'
                                              onClick={() =>
                                                handleStatusShow(masterCasinoGameId, isActive, name)}
                                              hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                            >
                                              <FontAwesomeIcon icon={faCheckSquare} />
                                            </Button>
                                          </Trigger>
                                          )
                                        : (
                                          <Trigger message='Set Status In-Active'>
                                            <Button
                                              className='m-1'
                                              size='sm'
                                              variant='danger'
                                              onClick={() =>
                                                handleStatusShow(masterCasinoGameId, isActive, name)}
                                              hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                            >
                                              <FontAwesomeIcon icon={faWindowClose} />
                                            </Button>
                                          </Trigger>
                                          )}

                                      <Trigger message='View Blocked Countries'>
                                        <Button
                                          className='m-1'
                                          size='sm'
                                          variant='secondary'
                                          hidden={isHidden({ module: { key: 'CasinoManagement', value: 'U' } })}
                                          onClick={() => navigate(`/super-admin/casino-games/restrict-countries/${masterCasinoGameId}`)}
                                        >
                                          <FontAwesomeIcon icon={faBan} />
                                        </Button>
                                      </Trigger>
                                    </ButtonGroup>)
                                  : 'NA'}
                              </td>
                            </tr>
                          )
                        }
                      )}

          {casinoGamesData?.count === 0 && !loading &&
                      (
                        <tr>
                          <td
                            colSpan={8}
                            className='text-danger text-center'
                          >
                            No data found
                          </td>
                        </tr>
                      )}
        </tbody>
      </Table>

      {casinoGamesData?.count !== 0 && !loading &&
              (
                <PaginationComponent
                  page={casinoGamesData?.count < page ? setPage(1) : page}
                  totalPages={totalPages}
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                />
              )}

      <ConfirmationModal
        setShow={setStatusShow}
        show={statusShow}
        handleYes={handleYes}
        active={status}
        name={name}
      />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{type} Casino Game</Modal.Title>
        </Modal.Header>

        <CreateCasinoGames
          handleClose={handleClose}
          data={data}
          limit={limit}
          pageNo={page}
        />
      </Modal>

      {showGameIds &&
        <GameIdsModal
          setShow={setShowGameIds}
          show={showGameIds}
        />}
    </>
  )
}
