import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDidMountEffect from '../../../utils/useDidMountEffect'
import { getSuperAdminGameCategoryStart } from '../../../store/redux-slices/superAdminCasinoManagement'

const useCategoryListing = () => {
  const dispatch = useDispatch()
  const isInitialRender = useDidMountEffect()

  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')

  const { loading, gameCategory } = useSelector((state) => state.superAdminCasino)
  const totalPages = Math.ceil(gameCategory?.count / limit)

  useEffect(() => {
    dispatch(getSuperAdminGameCategoryStart({ limit, pageNo: page, search, tenantId: '' }))
  }, [limit, page])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(getSuperAdminGameCategoryStart({ limit, pageNo: page, search, tenantId: '' }))
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  return {
    limit,
    setLimit,
    page,
    setPage,
    loading,
    gameCategory,
    totalPages,
    search,
    setSearch
  }
}

export default useCategoryListing
