import React from 'react'
import useGameReport from '../hooks/useGameReport'
import { handleDownloadCSV } from '../../../../utils/fileDownloader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowDown } from '@fortawesome/pro-solid-svg-icons'
import GameReportTable from '../../../../components/GameReportTable'
import DateOptionsFilter from '../../../../components/DateOptionsFilter'
import { Row, Col, Tabs, Tab, Form, Button } from '@themesberg/react-bootstrap'
import { DateRangePickerWithoutInput } from '../../../../components/DateRangePicker'
import { limitConstants } from '../../../../components/DateOptionsFilter/dateConstants'
import useCheckPermission from '../../../../utils/checkPermission'

const GameReport = ({
  limit,
  state,
  isTenant,
  userData,
  setLimit,
  setState,
  dateOptions,
  selectedTab,
  setSelectedTab,
  setDateOptions
}) => {
  const { gameReportPlayer, getCsvDownloadUrl } = useGameReport({
    isTenant,
    limit,
    state,
    selectedTab,
    dateOptions
  })

  const { isHidden } = useCheckPermission()

  return (
    <>
      <Row>
        <Col />

        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center mb-1 DateRangePickerWithoutInputWrapper'>
            {dateOptions === 'custom' && (
              <DateRangePickerWithoutInput state={state} setState={setState} />
            )}

            <Form.Label
              style={{ width: '50px', marginLeft: '20px', marginTop: '5px' }}
            >
              Limit
            </Form.Label>

            <Form.Select
              onChange={(e) => setLimit(e.target.value)}
              value={limit}
              style={{ width: 'auto', marginRight: '10px' }}
              size='sm'
            >
              {limitConstants.map(({ label, value }) => (
                <option value={value} key={value}>
                  {label}
                </option>
              ))}
            </Form.Select>

            <Form.Label
              style={{
                width: '100px',
                marginTop: '5px',
                marginRight: '10px'
              }}
            >
              Date Options
            </Form.Label>
            <DateOptionsFilter
              dateOptions={dateOptions}
              setDateOptions={setDateOptions}
            />
            <Button
              variant='success'
              size='sm'
              style={{ width: '150px' }}
              hidden={isHidden({ module: { key: 'Transactions', value: 'DR' } })}
              disabled={gameReportPlayer?.length < 1}
              onClick={() => {
                handleDownloadCSV(getCsvDownloadUrl(), userData ? `Game_Report${userData?.email}` : 'Game_Report')
              }}
            >
              Export&nbsp;
              <FontAwesomeIcon icon={faCloudArrowDown} />
            </Button>
          </div>
        </Col>
      </Row>

      <Tabs
        activeKey={selectedTab}
        onSelect={(tab) => setSelectedTab(tab)}
        className='nav-light dashboard'
      >
        <Tab eventKey='game' title='GAME'>
          <div className='mt-2'>
            <Row className='mt-3 d-flex flex-row-reverse text-right'>
              <GameReportTable tableData={gameReportPlayer || []} isPlayer />
            </Row>
          </div>
        </Tab>

        <Tab eventKey='provider' title='PROVIDER'>
          <div className='mt-2'>
            <Row className='mt-3 d-flex flex-row-reverse text-right'>
              <GameReportTable tableData={gameReportPlayer || []} isPlayer />
            </Row>
          </div>
        </Tab>
      </Tabs>
    </>
  )
}

export default GameReport
