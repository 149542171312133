import React from 'react'
import {
  Button,
  Row,
  Col,
  Table,
  ButtonGroup,
  Form
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faArrowCircleUp,
  faArrowCircleDown,
  faEdit,
  faCheckSquare,
  faWindowClose
} from '@fortawesome/pro-solid-svg-icons'
import PaginationComponent from '../../../components/Pagination'
import { tableHeaders } from './constants'
import { TenantRoutes } from '../../../routes'
import useAdminsListing from './hooks/useAdminsListing'
import ConfirmationModal from '../../../components/ConfirmationModal'
import Trigger from '../../../components/OverlayTrigger'

export default () => {
  const {
    navigate,
    limit,
    page,
    sort,
    over,
    setLimit,
    setPage,
    setOrderBy,
    setSort,
    setOver,
    tenantAdminsData,
    totalPages,
    getRole,
    selected,
    show,
    handleShow,
    handleYes,
    setShow,
    active,
    search,
    setSearch,
    name,
    loading,
    status,
    setStatus
  } = useAdminsListing()

  return (
    <>
      <>
        <Row>
          <Col>
            <h3>Managers</h3>
          </Col>
          <Col>
            <div className='d-flex justify-content-end align-items-center'>
              <Form.Label style={{ marginRight: '15px', marginTop: '8px' }}>
                Status
              </Form.Label>

              <Form.Select
                onChange={(e) => { setStatus(e.target.value) }}
                value={status}
                size='sm'
                style={{ maxWidth: '230px', width: 'auto', marginRight: '10px' }}
              >
                <option value=''>All</option>
                <option value='true'>Active</option>
                <option value='false'>In-Active</option>
              </Form.Select>

              <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
                Search
              </Form.Label>
              <Form.Control
                size='sm'
                style={{ maxWidth: '230px', marginRight: '10px' }}
                type='search'
                value={search}
                placeholder='Search By Email, Name, Group'
                onChange={(e) =>
                  setSearch(e.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
              />

              <Button
                variant='outline-success'
                className='f-right'
                size='sm'
                onClick={() => navigate(TenantRoutes.CreateAdminUser)}
              >
                Create
              </Button>
            </div>
          </Col>
        </Row>

        <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
          <thead className='thead-dark'>
            <tr>
              {tableHeaders.map((h, idx) => (
                <th
                  key={idx}
                  onClick={() => setOrderBy(h.value)}
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  {h.label}
                  {selected(h) &&
                    (sort === 'asc'
                      ? (
                        <FontAwesomeIcon
                          style={over ? { color: 'red' } : {}}
                          icon={faArrowCircleUp}
                          onClick={() => setSort('desc')}
                          onMouseOver={() => setOver(true)}
                          onMouseLeave={() => setOver(false)}
                        />
                        )
                      : (
                        <FontAwesomeIcon
                          style={over ? { color: 'red' } : {}}
                          icon={faArrowCircleDown}
                          onClick={() => setSort('asc')}
                          onMouseOver={() => setOver(true)}
                          onMouseLeave={() => setOver(false)}
                        />
                        ))}
                </th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? 'cover-spin' : ''}>
            {!loading && Boolean(tenantAdminsData) &&
              tenantAdminsData?.rows?.map(
                (
                  {
                    adminUserId,
                    email,
                    firstName,
                    lastName,
                    phone,
                    adminRoleId,
                    isActive,
                    group
                  },
                  index
                ) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{email}</td>
                      <td>
                        <Trigger message={[firstName, lastName]}>
                          <span
                            style={{
                              width: '100px',
                              cursor: 'pointer'
                            }}
                            className='text-link d-inline-block text-truncate'
                            onClick={() =>
                              navigate(
                                `/tenant/admins/details/${adminUserId}`
                              )}
                          >
                            {firstName + ' ' + lastName}
                          </span>
                        </Trigger>
                      </td>
                      <td>{phone || 'N/A'}</td>
                      <td>{getRole(adminRoleId)}</td>

                      <td>{group || '-'}</td>

                      <td>
                        {isActive
                          ? (
                            <span className='text-success'>Active</span>
                            )
                          : (
                            <span className='text-danger'>In Active</span>
                            )}
                      </td>

                      <td>
                        <ButtonGroup>
                          <Trigger message='Edit'>
                            <Button
                              className='m-1'
                              size='sm'
                              variant='warning'
                              onClick={() =>
                                navigate(
                                  `/tenant/admins/edit/${adminUserId}`
                                )}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                          </Trigger>

                          <Trigger message='View Details'>
                            <Button
                              className='m-1'
                              size='sm'
                              variant='info'
                              onClick={() =>
                                navigate(
                                  `/tenant/admins/details/${adminUserId}`
                                )}
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </Button>
                          </Trigger>

                          {!isActive
                            ? (
                              <Trigger message='Set Status Active'>
                                <Button
                                  className='m-1'
                                  size='sm'
                                  variant='success'
                                  onClick={() =>
                                    handleShow(adminUserId, isActive, `${firstName} ${lastName} (${email})`)}
                                >
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </Button>
                              </Trigger>
                              )
                            : (
                              <Trigger message='Set Status In-Active'>
                                <Button
                                  className='m-1'
                                  size='sm'
                                  variant='danger'
                                  onClick={() =>
                                    handleShow(adminUserId, isActive, `${firstName} ${lastName} (${email})`)}
                                >
                                  <FontAwesomeIcon icon={faWindowClose} />
                                </Button>
                              </Trigger>
                              )}

                        </ButtonGroup>
                      </td>
                    </tr>
                  )
                }
              )}

            {tenantAdminsData?.count === 0 && !loading &&
              (
                <tr>
                  <td
                    colSpan={8}
                    className='text-danger text-center'
                  >
                    No data found
                  </td>
                </tr>
              )}
          </tbody>
        </Table>
        {tenantAdminsData?.count !== 0 && !loading &&
          (
            <PaginationComponent
              page={tenantAdminsData?.count < page ? setPage(1) : page}
              totalPages={totalPages}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
          )}

        {show && (
          <ConfirmationModal
            setShow={setShow}
            show={show}
            handleYes={handleYes}
            active={active}
            name={name}
          />
        )}
      </>
    </>
  )
}
