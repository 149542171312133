import {
  Tabs,
  Tab,
  Row,
  Col,
  Form as BForm
} from '@themesberg/react-bootstrap'
import { Formik } from 'formik'
import React from 'react'
import GeneralForm from '../../../../Super-Admin/Bonus/components/CreateBonus/general'
import useCreateBonus from '../hooks/useCreateBonus'
import CurrenciesForm from '../../../../Super-Admin/Bonus/components/CreateBonus/currencies'
import useCloneBonus from '../hooks/useCloneBonus'
import { tabLabels } from '../CreateBonus/constants'
import { bonusSchema } from '../CreateBonus/schema'
import PaymentMethod from '../../../../Super-Admin/Bonus/components/CreateBonus/PaymentMethod'
import WageringContributionForm from '../../../../Super-Admin/Bonus/components/CreateBonus/wageringContribution'
import Preloader from '../../../../../components/Preloader'
import Games from '../../../../Super-Admin/Bonus/components/CreateBonus/Games'
import LoyaltySettings from '../../../../Super-Admin/Bonus/components/CreateBonus/LoyaltySettings'
import Languages from '../../../../Super-Admin/Bonus/components/CreateBonus/Languages'
import BonusCountry from '../../../../Super-Admin/Bonus/components/CreateBonus/BonusCountry'
import CreateBonusBannedGames from '../../../../Super-Admin/Bonus/components/CreateBonus/BannedGames'

const CloneBonus = () => {
  const {
    handleImagePreview,
    selectedTab,
    setSelectedTab,
    preview,
    setActiveTab,
    setCurr,
    enableSubmit,
    setEnableSubmit,
    getBalanceBonusesOptions,
    balanceBonusOptions,
    wageringTemplateDetail,
    wageringTemplateList,
    limit,
    setLimit,
    pageNo,
    setPageNo,
    totalPages,
    gameIds,
    setGameIds,
    search,
    setSearch,
    selectedProvider,
    setSelectedProvider,
    casinoGamesData,
    gamesLimit,
    gamesPageNo,
    setGamesLimit,
    setGamesPageNo,
    appliedBonusOptions,
    languages,
    loyaltyCount,
    setLoyaltyCount,
    prepaidsData,
    setPrepaidsData
  } = useCreateBonus()

  const {
    state,
    setState,
    bonusDetail,
    navigate,
    loading,
    portalOptions,
    dispatch,
    data,
    setData,
    handelCreateBonus,
    set,
    countryData,
    setCountryData
  } = useCloneBonus({ prepaidsData })

  return (
    <>
      <Row>
        <Col>
          <h3>Clone Bonus </h3>
        </Col>
      </Row>
      {!bonusDetail && loading && !set
        ? (
          <Preloader />
          )
        : (
          <Formik
            enableReinitialize
            initialValues={{
              promotionTitle: bonusDetail?.promotionTitle?.EN || '',
              bonusType: bonusDetail?.bonusType || '',
              validFrom: state.startDate,
              validTo: state.endDate,
              termCondition: bonusDetail?.termCondition?.EN || '',
              quantity: bonusDetail?.quantity || 0,
              daysToClear: bonusDetail?.daysToClear || 0,
              currency: { EUR: bonusDetail?.currency?.EUR } || {},
              maxBonusThreshold: '',
              status: bonusDetail?.status || '',
              minDeposit: '',
              wageringRequirementType: bonusDetail?.wageringRequirementType || '',
              maxWinAmount: '',
              isWinCashAmt: '',
              isBonusWagering: '',
              depositBonusPercent: bonusDetail?.depositBonusPercent,
              tenantIds: [],
              validOnDays: bonusDetail?.validOnDays || [],
              bonusImage: null,
              isActive: bonusDetail?.isActive || false,
              showBonusValidity: bonusDetail?.other?.showBonusValidity,
              visibleInPromotions: bonusDetail?.visibleInPromotions || false,
              isSticky: bonusDetail?.isSticky || false,
              wageringMultiplier: bonusDetail?.wageringMultiplier || 1,
              paymentMethods: {},
              wageringTemplateId: bonusDetail?.wageringTemplateId || '',
              appliedBonusId: '',
              appliedBonusVal: '',
              description: bonusDetail?.description?.EN || '',
              promotionalOverview: bonusDetail?.promotionalOverview?.EN || '',
              loyaltyLevel: bonusDetail?.other?.loyaltyLevel || [{}],
              minBalance: '',
              timePeriod: bonusDetail?.other?.timePeriod || '1',
              other: bonusDetail?.other?.loyaltyLevel || null,
              betLevel: bonusDetail?.other?.betLevel || 1,
              bannedGamesSetting: null
            }}
            validationSchema={
            bonusSchema({ EUR: bonusDetail?.currency?.EUR }, { bonusDetail: null })[
              tabLabels.findIndex((val) => val === selectedTab)
            ]
          }
            onSubmit={(formValues) => handelCreateBonus({ formValues, gameIds, languages, selectedTab, setSelectedTab })}
          >
            {({
              touched,
              errors,
              values,
              setSubmitting,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue
            }) => (
              <BForm>
                <Tabs
                  key={selectedTab}
                  activeKey={selectedTab}
                  className='nav-light'
                  mountOnEnter
                  unmountOnExit
                  onSelect={(k) => setSelectedTab(k)}
                >
                  <Tab disabled eventKey='general' title='General'>
                    <div className='mt-5'>
                      <GeneralForm
                        isClone
                        state={state}
                        setState={setState}
                        setSelectedTab={setSelectedTab}
                        touched={touched}
                        errors={errors}
                        values={values}
                        setSubmitting={setSubmitting}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        preview={preview}
                        handleImagePreview={handleImagePreview}
                        navigate={navigate}
                        portalOptions={portalOptions}
                        getBalanceBonusesOptions={getBalanceBonusesOptions}
                        balanceBonusOptions={balanceBonusOptions}
                        isTenant
                        bonusDetail={bonusDetail}
                        wageringTemplateList={wageringTemplateList}
                        setEnableSubmit={setEnableSubmit}
                        appliedBonusOptions={appliedBonusOptions}
                        setGameIds={setGameIds}
                        data={data}
                        setData={setData}
                        languages={languages}
                        setCountryData={setCountryData}
                        setPrepaidIds={setPrepaidsData}
                      />
                    </div>
                  </Tab>
                  {languages.length > 0 &&
                    <Tab disabled eventKey='languages' title='Languages'>
                      <div className='mt-5'>
                        <Languages
                          languages={languages}
                          handleSubmit={handleSubmit}
                          setSelectedTab={setSelectedTab}
                          selectedTab={selectedTab}
                          data={data}
                          setData={setData}
                          values={values}
                        />
                      </div>
                    </Tab>}
                  {!['balance', 'promotion', 'winBoost'].includes(values?.bonusType) && (
                    <Tab disabled eventKey='currency' title='Currency'>
                      <div className='mt-5'>
                        <CurrenciesForm
                          isClone
                          touched={touched}
                          errors={errors}
                          values={values}
                          setSubmitting={setSubmitting}
                          handleChange={handleChange}
                          handleSubmit={handleSubmit}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          setCurr={setCurr}
                          setSelectedTab={setSelectedTab}
                          setActiveTab={setActiveTab}
                          enableSubmit={enableSubmit}
                          setEnableSubmit={setEnableSubmit}
                          isTenant
                        />
                      </div>
                    </Tab>
                  )}

                  {values.bonusType === 'match' && (
                    <Tab
                      disabled
                      eventKey='payment'
                      title='Applies to Payment method'
                    >
                      <div className='mt-3 hhhhh'>
                        <PaymentMethod
                          isClone
                          touched={touched}
                          errors={errors}
                          values={values}
                          setSubmitting={setSubmitting}
                          handleChange={handleChange}
                          handleSubmit={handleSubmit}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          setCurr={setCurr}
                          setSelectedTab={setSelectedTab}
                          setActiveTab={setActiveTab}
                          isTenant
                          selectedTab={selectedTab}
                        />
                      </div>
                    </Tab>
                  )}
                  {['deposit', 'wagering', 'winBoost'].includes(values.bonusType) && (
                    <Tab
                      disabled
                      eventKey='loyalty'
                      title='Loyalty Settings'
                    >
                      <div className='mt-3 hhhhh'>
                        <LoyaltySettings
                          values={values}
                          handleChange={handleChange}
                          handleSubmit={handleSubmit}
                          handleBlur={handleBlur}
                          setSelectedTab={setSelectedTab}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          selectedTab={selectedTab}
                          isTenant
                          loyaltyCount={loyaltyCount}
                          setLoyaltyCount={setLoyaltyCount}
                        />
                      </div>
                    </Tab>
                  )}
                  {bonusDetail?.bonusType !== 'balance' && (
                    !['freespins', 'promotion', 'winBoost'].includes(values.bonusType)
                      ? (
                        <Tab
                          disabled
                          eventKey='wageringContribution'
                          title='Wagering contribution'
                        >
                          <div className='mt-5'>
                            <WageringContributionForm
                              isClone
                              state={state}
                              setState={setState}
                              setSelectedTab={setSelectedTab}
                              touched={touched}
                              errors={errors}
                              values={values}
                              setSubmitting={setSubmitting}
                              handleChange={handleChange}
                              handleSubmit={handleSubmit}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              preview={preview}
                              handleImagePreview={handleImagePreview}
                              navigate={navigate}
                              dispatch={dispatch}
                              wageringTemplateList={wageringTemplateList}
                              wageringTemplateDetail={wageringTemplateDetail}
                              setActiveTab={setActiveTab}
                              isTenant
                              limit={limit}
                              pageNo={pageNo}
                              setLimit={setLimit}
                              setPageNo={setPageNo}
                              totalPages={totalPages}
                              selectedTab={selectedTab}
                            />
                          </div>
                        </Tab>
                        )
                      : bonusDetail?.isSticky &&
                        <Tab
                          disabled
                          eventKey='wageringContribution'
                          title='Wagering contribution'
                        >
                          <div className='mt-5'>
                            <WageringContributionForm
                              isClone
                              state={state}
                              setState={setState}
                              setSelectedTab={setSelectedTab}
                              touched={touched}
                              errors={errors}
                              values={values}
                              setSubmitting={setSubmitting}
                              handleChange={handleChange}
                              handleSubmit={handleSubmit}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              preview={preview}
                              handleImagePreview={handleImagePreview}
                              navigate={navigate}
                              dispatch={dispatch}
                              wageringTemplateList={wageringTemplateList}
                              wageringTemplateDetail={wageringTemplateDetail}
                              setActiveTab={setActiveTab}
                              isTenant
                              limit={limit}
                              pageNo={pageNo}
                              setLimit={setLimit}
                              setPageNo={setPageNo}
                              totalPages={totalPages}
                              selectedTab={selectedTab}
                            />
                          </div>
                        </Tab>

                  )}
                  {(values.bonusType === 'freespins') && (
                    <Tab disabled eventKey='games' title='Games'>
                      <div className='mt-5'>
                        <Games
                          gameIds={gameIds}
                          setGameIds={setGameIds}
                          setLimit={setGamesLimit}
                          setPageNo={setGamesPageNo}
                          setSearch={setSearch}
                          search={search}
                          totalPages={totalPages}
                          limit={gamesLimit}
                          pageNo={gamesPageNo}
                          loading={loading}
                          handleSubmit={handleSubmit}
                          handleBlur={handleBlur}
                          casinoGamesData={casinoGamesData}
                          selectedProvider={selectedProvider}
                          setSelectedProvider={setSelectedProvider}
                          setSelectedTab={setSelectedTab}
                          isClone
                          errors={errors}
                          isTenant
                          values={values}
                          prepaidsData={prepaidsData}
                          setPrepaidsData={setPrepaidsData}
                        />
                      </div>
                    </Tab>
                  )}
                  {
                    values.bonusType !== 'cashfreespins' && values.bonusType !== 'balance' && values.bonusType !== 'promotion' &&
                      <Tab disabled eventKey='bannedGames' title='Banned Games'>
                        <div className='mt-5'>
                          <CreateBonusBannedGames
                            values={values}
                            setFieldValue={setFieldValue}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                            handleSubmit={handleSubmit}
                          />
                        </div>
                      </Tab>
                  }
                  <Tab disabled eventKey='countries' title='Countries'>
                    <div className='mt-5'>
                      <BonusCountry
                        data={countryData}
                        setData={setCountryData}
                        values={values}
                        setSelectedTab={setSelectedTab}
                        handleSubmit={handleSubmit}
                        languages={languages}
                        isTenant
                      />
                    </div>
                  </Tab>
                </Tabs>
              </BForm>
            )}
          </Formik>
          )}
    </>
  )
}

export default CloneBonus
