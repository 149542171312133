import React from 'react'
import './phoneInput.scss'
import Trigger from '../../../../components/OverlayTrigger'
import useRestrictMethods from '../hooks/useRestrictMethods'
import { faRedo, faX } from '@fortawesome/pro-solid-svg-icons'
import { SpinnerLoader } from '../../../../components/Preloader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Form, Row, Table } from '@themesberg/react-bootstrap'
import { providerCategory } from '../../CustomPayment/components/constants'

const RestrictMethods = ({
  userData
}) => {
  const {
    type,
    status,
    setType,
    category,
    setStatus,
    fetchData,
    selectAll,
    updateData,
    setCategory,
    aggregator,
    paymentGroup,
    paymentGroups,
    setAggregator,
    paymentSearch,
    providerLoading,
    setPaymentGroup,
    paymentProviders,
    depositProviders,
    setPaymentSearch,
    withdrawProviders,
    paymentAggregators,
    setDepositProviders,
    setWithdrawProviders
  } = useRestrictMethods({ userData })

  return (
    <>
      <Row className='mb-2 justify-content-end'>
        <Col>
          <Row>
            <Col className='d-flex align-items-center m-1'>
              <Form.Label className='mb-0'>Group:&nbsp;</Form.Label>
              <Form.Select
                size='sm'
                name='paymentGroup'
                value={paymentGroup}
                onChange={(e) => {
                  setPaymentGroup(e.target.value)
                }}
              >
                <option value='' key=''>
                  All
                </option>
                {paymentGroups && paymentGroups?.map((data) => (
                  <option value={data} key={data}>
                    {data}
                  </option>
                ))}
              </Form.Select>
            </Col>

            <Col className='d-flex align-items-center m-1'>
              <Form.Label className='mb-0'>Aggregator:&nbsp;</Form.Label>
              <Form.Select
                size='sm'
                name='aggregator'
                value={aggregator}
                onChange={(e) => {
                  setAggregator(e.target.value)
                }}
              >
                <option value='' key=''>
                  All
                </option>
                {paymentAggregators && Object.keys(paymentAggregators)?.map((data) => (
                  <option value={data} key={data}>
                    {data}
                  </option>
                ))}
              </Form.Select>
            </Col>

            <Col className='d-flex align-items-center m-1'>
              <Form.Label className='mb-0'>Type:&nbsp;</Form.Label>
              <Form.Select
                size='sm'
                style={{ minWidth: '120px' }}
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value=''>All</option>
                <option value='deposit'>Deposit</option>
                <option value='withdraw'>Withdraw</option>
              </Form.Select>
            </Col>

            <Col className='d-flex align-items-center m-1'>
              <Form.Label className='mb-0'>Category:&nbsp;</Form.Label>
              <Form.Select
                size='sm'
                style={{ minWidth: '145px' }}
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value=''>All</option>
                {Object.keys(providerCategory).map(categoryKey =>
                  <option value={categoryKey} key={categoryKey}>{providerCategory[categoryKey]}</option>)}
              </Form.Select>
            </Col>

            <Col className='d-flex align-items-center m-1'>
              <Form.Label className='mb-0'>Status:&nbsp;</Form.Label>
              <Form.Select
                size='sm'
                style={{ minWidth: '120px' }}
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value=''>All</option>
                <option value='true'>Active</option>
                <option value='false'>In-Active</option>
              </Form.Select>
            </Col>

            <Col className='d-flex align-items-center m-1'>
              <Form.Label className='mb-0'>Search:&nbsp;</Form.Label>
              <Form.Control
                size='sm'
                type='text'
                name='paymentSearch'
                value={paymentSearch}
                style={{ minWidth: '220px' }}
                onChange={(e) => {
                  setPaymentSearch(e.target.value)
                }}
              />
            </Col>

            <Col className='d-flex align-items-center m-1' xs='auto'>
              <Trigger message='Reset Filters'>
                <Button
                  size='sm'
                  variant='outline-danger'
                  onClick={() => {
                    setType('')
                    setStatus('')
                    setCategory('')
                    setAggregator('')
                    setPaymentGroup('')
                    setPaymentSearch('')
                  }}
                >
                  <FontAwesomeIcon icon={faX} />
                </Button>
              </Trigger>
            </Col>

            <Col>
              <button onClick={() => fetchData()} className='btn btn-sm btn-secondary d-flex align-items-center gap-1 m-1'>
                <span className='fs-7'>Refresh</span>
                {providerLoading ? <SpinnerLoader /> : <FontAwesomeIcon style={{ fontSize: '15px' }} icon={faRedo} />}
              </button>
            </Col>
          </Row>
        </Col>
        <Col xs='auto'>
          <Button
            className='text-success'
            variant='outline-success'
            style={{ maxHeight: '45px' }}
            onClick={() => updateData()}
          >
            Submit
          </Button>
        </Col>
      </Row>
      <div style={{ overflow: 'auto', maxHeight: '70vh' }}>
        <Table
          bordered
          striped
          hover
          size='sm'
          className='text-center'
        >
          <thead className='fixed-header-container'>
            <tr className='thead-dark'>
              <th>ID</th>
              <th>Name</th>
              <th>Display Name</th>
              <th>Aggregator</th>
              <th>Group</th>
              <th>
                <input
                  type='checkbox'
                  name='deposit'
                  checked={paymentProviders?.rows?.every(provider => provider?.supportsDeposit ? depositProviders.includes(provider.paymentProviderId) : true)}
                  onClick={(e) => selectAll('deposit', e.target.checked)}
                />
                &nbsp;Restrict Deposit
              </th>
              <th>
                <input
                  type='checkbox'
                  name='withdraw'
                  checked={paymentProviders?.rows?.every(provider => provider?.supportsWithdrawal ? withdrawProviders.includes(provider.paymentProviderId) : true)}
                  onClick={(e) => selectAll('withdraw', e.target.checked)}
                />
                &nbsp;Restrict Withdraw
              </th>
            </tr>
          </thead>

          <tbody className='table-content' id={providerLoading ? 'cover-spin' : ''}>
            {paymentProviders?.rows &&
            !providerLoading &&
            paymentProviders?.rows?.map(
              ({
                paymentProviderId,
                supportsDeposit,
                supportsWithdrawal,
                name,
                displayName,
                group,
                aggregator
              }) => {
                return (
                  <tr key={`payment-list ${paymentProviderId}`}>
                    <td>{paymentProviderId}</td>
                    <td>{name || '-'}</td>
                    <td>{displayName || '-'}</td>
                    <td>{aggregator}</td>
                    <td>{group || '-'}</td>
                    <td>{supportsDeposit
                      ? (
                        <input
                          type='checkbox'
                          name={'deposit' + paymentProviderId}
                          checked={depositProviders?.includes(paymentProviderId)}
                          onChange={() => {
                            if (depositProviders.includes(paymentProviderId)) {
                              setDepositProviders(depositProviders.filter(id => id !== paymentProviderId))
                            } else {
                              setDepositProviders([paymentProviderId, ...depositProviders])
                            }
                          }}
                        />
                        )
                      : '-'}
                    </td>
                    <td>{supportsWithdrawal
                      ? (
                        <input
                          type='checkbox'
                          name={'withdraw' + paymentProviderId}
                          checked={withdrawProviders?.includes(paymentProviderId)}
                          onChange={() => {
                            if (withdrawProviders.includes(paymentProviderId)) {
                              setWithdrawProviders(withdrawProviders.filter(id => id !== paymentProviderId))
                            } else {
                              setWithdrawProviders([paymentProviderId, ...withdrawProviders])
                            }
                          }}
                        />
                        )
                      : '-'}
                    </td>
                  </tr>
                )
              }
            )}

            {paymentProviders?.rows?.length === 0 && !providerLoading && (
              <tr>
                <td colSpan={10} className='text-danger text-center'>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default RestrictMethods
