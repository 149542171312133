import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import useParentGroupForm from '../hooks/useParentGroupForm'

/**
 * @typedef ParentGroupType
 *
 * @property  {Number}    id
 * @property  {String}    parentGroupName
 * @property  {Object[]}  groupDetails
 */

/**
 * @typedef ParentGroupFormPropsType
 *
 * @type {Object}
 * @property {Function} closeModal
 * @property {Function} parentGroup
 */

/**
 * @param   {ParentGroupFormPropsType} props
 * @returns {React.JSX}
 */
const ParentGroupForm = ({ closeModal, parentGroup }) => {
  const {
    groupLoading,
    handleSubmit
  } = useParentGroupForm(parentGroup)

  const formSchema = Yup.object().shape({
    parentGroupName: Yup.string().required('Parent group name is required')
  })

  return (
    <Formik
      validationSchema={formSchema}
      validateOnChange
      validateOnMount
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false)
        handleSubmit(values, closeModal)
      }}
      initialValues={{ parentId: parentGroup?.id || null, parentGroupName: parentGroup?.parentGroupName || '' }}
    >
      {({ isSubmitting, getFieldProps, handleSubmit, isValid }) => (
        <Modal
          show
          backdrop='static'
          keyboard={false}
          onHide={closeModal}
        >
          <Modal.Header closeButton>
            <h5>Create Parent Group</h5>
          </Modal.Header>
          <Modal.Body>
            <Form id='parent-group-form' noValidate onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Label>Parent Group Name<span className='text-danger'> *</span></Form.Label>
                  <Form.Control
                    name='parentGroupName'
                    type='text'
                    placeholder='Enter parent group name'
                    {...getFieldProps('parentGroupName')}
                  />
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-between align-items-center'>
            <Button
              variant='outline-warning'
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              variant='outline-success'
              type='submit'
              form='parent-group-form'
              disabled={isSubmitting || groupLoading || !isValid}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  )
}

export default ParentGroupForm
