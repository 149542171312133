import * as Yup from 'yup'

const CredentialsSchema = Yup.object().shape({
  credKey: Yup.string().required('Key Required')
  // value: Yup.string().max(100).required('Value Required'),
  // description: Yup.string().max(200).required('Description Required')
})

export const affiliateSystemSchema = (myKeys) => {
  const validationObject = {}
  if (myKeys && Object.keys(myKeys)?.length) {
    for (const file of myKeys) {
      validationObject[file] = Yup.string().required('Value Required.')
    }
  }
  return Yup.object(validationObject)
}

export const whiteListedDomainsSchema = () => {
  return Yup.object().shape({
    domains: Yup.array().of(Yup.object().shape({
      name: Yup.string().required('Domain name required')
    })).required('Add domains in the list')
  })
}

export default CredentialsSchema
