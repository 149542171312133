import React from 'react'
import {
  Button,
  ButtonGroup,
  Form,
  Table
} from '@themesberg/react-bootstrap'
import useCredentialsListing from '../hooks/useCredentialsListing'
import Trigger from '../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/pro-solid-svg-icons'
import { credKeyNames } from '../../../../utils/constants'
import { TenantRoutes } from '../../../../routes'

const TenantCredentials = () => {
  const {
    data,
    loading,
    navigate
  } = useCredentialsListing()

  return (
    <>
      <div className='text-right'>
        <Button
          size='sm'
          className='text-right'
          variant='outline-success'
          onClick={() => navigate(TenantRoutes.CreateCredentials)}
        >
          Create
        </Button>
      </div>
      <Table bordered responsive hover className='mt-4'>
        <thead className='thead-dark'>
          <tr>
            <th>ID</th>
            <th>Key</th>
            <th>Value</th>
            <th>Description</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && data &&
                  data.map(({ credentialId, key, value, description }) => {
                    return (
                      <tr key={credentialId}>
                        <td>{credentialId}</td>
                        <td>{credKeyNames?.[key] || key}</td>
                        <td>{value || 'NA'}</td>
                        <td>
                          <Trigger message={description || 'NA'}>
                            <span
                              style={{
                                width: '100px',
                                cursor: 'pointer'
                              }}
                              className='d-inline-block text-truncate'
                            >
                              {description || 'NA'}
                            </span>
                          </Trigger>
                        </td>

                        <td className='text-center'>
                          <ButtonGroup>
                            <Trigger message='Edit'>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='warning'
                                onClick={() =>
                                  navigate(
                                    `/tenant/update-credentials/${key}`
                                  )}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                            </Trigger>
                          </ButtonGroup>
                        </td>
                      </tr>
                    )
                  })}
        </tbody>
      </Table>

      {(data?.length < 1) && !loading && (
      // If no data found
        <Form.Label className='text-danger d-flex justify-content-center'>
          No data found.
        </Form.Label>
      )}
    </>
  )
}

export default TenantCredentials
