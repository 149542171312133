import React, { useEffect } from 'react'
import Tree from 'react-hierarchy-tree-graph'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAdminChildrenStart,
  getAdminChildrenSuccess
} from '../../store/redux-slices/admins'
import './Hierarchy.css'
import NodeLabel from './NodeLabel'

export default ({ adminDetails, isTenant }) => {
  const dispatch = useDispatch()
  const { adminChildren } = useSelector((state) => state.admins)

  const getChildren = async (id, superRoleId) => {
    dispatch(getAdminChildrenStart({ superAdminId: id, parentAdmin: adminDetails.name, superRoleId }))
  }

  const containerStyles = {
    width: '100%',
    height: '100vh'
  }
  useEffect(() => {
    dispatch(getAdminChildrenSuccess(adminDetails))
  }, [])

  return (
    <div style={containerStyles}>
      {adminDetails && adminChildren && (
        <Tree
          data={adminChildren}
          translate={{ x: 550, y: 50 }}
          orientation='vertical'
          collapsible={false}
          onClick={(e) => {
            getChildren(e.id, e.data?.superRoleId)
          }}
          separation={{ siblings: 1.3, nonSiblings: 2 }}
          allowForeignObjects
          nodeLabelComponent={{
            render: <NodeLabel isTenant={isTenant} />,
            foreignObjectWrapper: {
              y: -13,
              x: 18,
              height: '25px',
              width: '200px'
            }
          }}
        />
      )}
    </div>
  )
}
