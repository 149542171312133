import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createCmsStart } from '../../../../store/redux-slices/tenantsFetchData'
import { useEffect, useState } from 'react'
import { deleteCMSLanguageStart, getCMSDynamicKeysStart, updateSuperAdminCMSStart } from '../../../../store/redux-slices/fetchData'
import { getImageGalleryStart } from '../../../../store/redux-slices/emailTemplate'
import { getCMSLanguagesStart } from '../../../../store/redux-slices/languages'
import { checkIsTenant } from '../../../../utils/constants'
import { getItem } from '../../../../utils/storageUtils'

const useCreateCms = (cmsData) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { cmsPageId } = useParams()
  const { tenantDetails } = useSelector((state) => state.login)

  const [selectedClient, setSelectedClient] = useState(cmsData ? cmsData?.adminUserId : '')
  const [selectedPortal, setSelectedPortal] = useState(cmsData ? cmsData?.tenantId : '')
  const [err, setErr] = useState('')
  const [selectedTab, setSelectedTab] = useState('EN')
  const [template, setTemplate] = useState('')
  const [galleryModal, setGalleryModal] = useState('')
  const [isTouched, setIsTouched] = useState({ client: false, portal: true })

  const { loading } = useSelector((state) => state.tenantsFetch)
  const { cmsKeys } = useSelector((state) => state.fetch)
  const { tenantLanguages } = useSelector((state) => state.languages)

  const getGalleryData = () => {
    dispatch(getImageGalleryStart({ isTenant: false, tenantId: checkIsTenant() ? getItem('tenant-id') : selectedPortal }))
  }

  useEffect(() => {
    dispatch(getCMSDynamicKeysStart({ isTenant: false }))
  }, [])

  useEffect(() => {
    if (!tenantLanguages?.length || !tenantLanguages.includes(selectedTab)) {
      setSelectedTab('EN')
    }
  }, [tenantLanguages])

  useEffect(() => {
    dispatch(getCMSLanguagesStart({ adminId: selectedClient || '', tenantId: checkIsTenant() ? getItem('tenant-id') : selectedPortal }))
  }, [selectedClient, selectedPortal])

  const createCms = (data) => dispatch(createCmsStart({ cmsData: data, navigate }))
  const editCms = (data) => dispatch(updateSuperAdminCMSStart(data))
  const deleteCms = (data) => dispatch(deleteCMSLanguageStart({ isTenant: false, data }))

  return {
    navigate,
    loading,
    createCms,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal,
    isTouched,
    setIsTouched,
    err,
    setErr,
    editCms,
    cmsPageId,
    template,
    setTemplate,
    galleryModal,
    setGalleryModal,
    getGalleryData,
    cmsKeys,
    selectedTab,
    setSelectedTab,
    languages: checkIsTenant() ? tenantDetails?.tenantConfig?.allowedLanguages : tenantLanguages,
    deleteCms
  }
}

export default useCreateCms
