import React from 'react'
import {
  Button,
  Modal,
  Form as BForm,
  Row,
  Col,
  Spinner
} from '@themesberg/react-bootstrap'
import { Formik, Form, ErrorMessage } from 'formik'
import { casinoSchema, editCasinoSchema } from '../schema'
import useCreateCasinoGame from '../hooks/useCreateCasinoGame'

const CreateCasinoGames = ({
  handleClose,
  data,
  selectedCasinoProviderId,
  limit,
  pageNo
}) => {
  const {
    casinoProvidersData, loading, updateCasinoGame, createCasinoGame
  } = useCreateCasinoGame()

  return (
    <div>
      <Formik
        initialValues={
          data?.casinoGameId
            ? {
                casinoGameId: data.casinoGameId,
                gameName: data?.gameName,
                operatorGameId: data?.operatorGameId,
                casinoProviderId: data?.casinoProviderId,
                thumbnail: null,
                isActive: data?.isActive,
                operatorStatus: data?.operatorStatus
              }
            : {
                gameName: '',
                operatorGameId: '',
                casinoProviderId: null,
                thumbnail: null,
                isActive: false,
                operatorStatus: false
              }
        }
        validationSchema={data?.casinoGameId ? editCasinoSchema : casinoSchema}
        onSubmit={(formValues) => {
          data?.casinoGameId
            ? updateCasinoGame({
              data: {
                ...formValues
              },
              casinoProviderId: parseInt(selectedCasinoProviderId),
              limit,
              pageNo
            })

            : createCasinoGame({
              data: {
                ...formValues
              },
              casinoProviderId: parseInt(selectedCasinoProviderId),
              limit,
              pageNo
            })
          handleClose()
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue
        }) => (
          <Form>
            <Modal.Body>
              <Row>
                <Col>
                  <BForm.Label>Game Name</BForm.Label>

                  <BForm.Control
                    type='text'
                    name='gameName'
                    placeholder='Enter Game Name'
                    value={values.gameName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component='div'
                    name='gameName'
                    className='text-danger'
                  />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col>
                  <BForm.Label>Operator Game Id</BForm.Label>

                  <BForm.Control
                    type='text'
                    name='operatorGameId'
                    placeholder='Enter Operator Game Id'
                    value={values.operatorGameId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component='div'
                    name='operatorGameId'
                    className='text-danger'
                  />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col>
                  <BForm.Label>Casino Provider </BForm.Label>
                  <BForm.Select
                    name='casinoProviderId'
                    value={values.casinoProviderId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option selected disabled>
                      Select Provider
                    </option>
                    {casinoProvidersData &&
                      casinoProvidersData?.rows?.map((provider, index) => {
                        return (
                          <option key={index} value={provider.casinoProviderId}>
                            {provider.casinoName}
                          </option>
                        )
                      })}
                  </BForm.Select>
                  <ErrorMessage
                    component='div'
                    name='casinoProviderId'
                    className='text-danger'
                  />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col className='d-grid'>
                  <BForm.Label>Thumbnail</BForm.Label>
                  <BForm.Text>
                    <input
                      id='file'
                      name='thumbnail'
                      type='file'
                      onChange={(event) => {
                        setFieldValue('thumbnail', event.currentTarget.files[0])
                      }}
                    />
                    {values?.thumbnail && (
                      <img
                        alt='not fount'
                        width='50px'
                        src={URL.createObjectURL(values.thumbnail)}
                      />
                    )}
                    {!values?.thumbnail && data?.thumbnailUrl && (
                      <img
                        alt='not fount'
                        width='50px'
                        src={data.thumbnailUrl}
                      />
                    )}
                  </BForm.Text>

                  <ErrorMessage
                    component='div'
                    name='thumbnail'
                    className='text-danger'
                  />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col className='d-flex'>
                  <BForm.Label>Status</BForm.Label>

                  <BForm.Check
                    className='mx-auto'
                    type='checkbox'
                    name='isActive'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.isActive}
                    defaultChecked={values.isActive}
                  />
                </Col>
                <Col className='d-flex'>
                  <BForm.Label>Operator Status</BForm.Label>

                  <BForm.Check
                    className='mx-auto'
                    type='checkbox'
                    name='operatorStatus'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.operatorStatus}
                    defaultChecked={values.operatorStatus}
                  />
                </Col>
              </Row>
            </Modal.Body>

            <div className='mt-4'>
              <Modal.Footer className='d-flex justify-content-between align-items-center'>
                <Button variant='warning' onClick={() => handleClose()}>
                  Cancel
                </Button>
                <Button
                  variant='success'
                  onClick={handleSubmit}
                  className='ml-2'
                >
                  Submit
                  {loading && (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  )}
                </Button>
              </Modal.Footer>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateCasinoGames
