import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import { Button, Col, Form as BForm, Row } from '@themesberg/react-bootstrap'
import React from 'react'
import { ErrorMessage, FieldArray, Form, Formik } from 'formik'
import useWhiteListDomains from '../hooks/useWhiteListDomains'
import { whiteListedDomainsSchema } from '../schemas'
import Trigger from '../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const WhiteListDomains = () => {
  const {
    editing,
    loading,
    onSubmit,
    setEditing,
    whiteListedDomains
  } = useWhiteListDomains()

  return (
    <Formik
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{
        domains: whiteListedDomains.map((whiteListedDomain) => ({
          name: whiteListedDomain
        }))
      }}
      validationSchema={whiteListedDomainsSchema}
      onSubmit={(formValues) => {
        onSubmit({
          domains: formValues.domains.map(({ name }) => name)
        })
      }}
    >
      {({ values, handleBlur, handleChange, isSubmitting }) => (
        <>
          <Form>
            <FieldArray name='domains'>
              {({ push, remove }) => (
                <>
                  <Row className='mt-2'>
                    <Col>
                      <h3>Whitelisted Domains</h3>
                    </Col>
                    <Col className='d-flex justify-content-end align-items-center'>
                      <Button
                        variant='outline-success'
                        size='sm'
                        hidden={editing || loading}
                        onClick={() => setEditing(true)}
                      >
                        Edit
                      </Button>

                      <Button
                        variant='outline-success'
                        size='sm'
                        hidden={!editing || loading}
                        onClick={() => push({ name: '' })}
                      >
                        Add Domain
                      </Button>
                    </Col>
                  </Row>

                  {values?.domains?.map((domain, index) => (
                    <Row key={`domain_input_${index}`} className='mb-2'>
                      <Col className='col-xs-12 col-md-12 col-lg-9'>
                        <div className='d-flex justify-content-between align-items-start gap-5'>
                          <div className='d-flex flex-column w-75'>
                            <BForm.Control
                              name={`domains.${index}.name`}
                              type='text'
                              placeholder='Enter domain eg. gmail.com, yahoo.com'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={domain.name}
                              disabled={!editing}
                            />
                            <ErrorMessage
                              name={`domains.${index}.name`}
                              component='div'
                              className='field-error text-danger'
                            />
                          </div>
                          <div className='pt-2'>
                            <Trigger message='Delete this domain'>
                              <Button
                                variant='danger'
                                size='sm'
                                onClick={() => remove(index)}
                                disabled={!editing}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </Trigger>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ))}

                  {
                    !loading && !values?.domains?.length && (
                      <div className='text-center text-danger'>
                        <p>No domains added</p>
                      </div>
                    )
                  }

                  <Row className='mt-5'>
                    <Col className='d-flex justify-content-between'>
                      <Button
                        variant='outline-warning'
                      >
                        Cancel
                      </Button>

                      <Button
                        type='submit'
                        variant='outline-success'
                        hidden={loading || !values?.domains.length}
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </FieldArray>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default WhiteListDomains
