import { Button, Col, Form, Row, Tab, Tabs } from '@themesberg/react-bootstrap'
import { Formik } from 'formik'
import React from 'react'
import { SuperAdminRoutes, TenantRoutes } from '../../../routes'
import { keysNotToBeShown } from '../hooks/constant'
import useAddLanguage from '../hooks/useAddLanguage'
import { languageWiseSchema } from './schema'
import UserEndPages from './UserEndPages'
import ClientFilter from '../../ClientFilter'
import PortalFilter from '../../PortalFilter'
import { checkIsTenant } from '../../../utils/constants'

const AddLangauge = ({
  isTenant = checkIsTenant(),
  errorCodes = window.location.href.includes('/err/')
}) => {
  const {
    languageKeyObj,
    selectedKeyTab,
    navigate,
    languageTabs,
    setSelectedKeyTab,
    myKeys,
    lang,
    updateData,
    isError,
    setIsError,
    keys,
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal
  } = useAddLanguage({ isTenant, errorCodes })

  return (
    <>
      {!checkIsTenant() &&
        <Row className='mb-3 mt-2'>
          <Col className='d-flex align-items-center'>
            <ClientFilter
              setSelectedClient={setSelectedClient}
              setSelectedPortal={setSelectedPortal}
              selectedClient={selectedClient}
            />

            <PortalFilter
              setSelectedPortal={setSelectedPortal}
              selectedPortal={selectedPortal}
              selectedClient={selectedClient}
              cms={selectedClient === ''}
              hasAllOptions={false}
            />
          </Col>
        </Row>}
      <Formik
        enableReinitialize
        initialValues={
            { languageKeys: { ...languageKeyObj } }
        }
        validationSchema={errorCodes ? null : languageWiseSchema(myKeys)}
        onSubmit={(formValues) => {
          if (errorCodes) {
            const data = []
            for (const key in formValues?.languageKeys) {
              data.push({ key, value: { ...keys?.[key], [lang]: formValues?.languageKeys?.[key] } })
            }
            updateData({
              type: 'create',
              data,
              tenantId: selectedPortal || ''
            })
          } else {
            const data = Object.assign({ }, formValues?.languageKeys)
            for (const key in data) {
              if (keysNotToBeShown.includes(key)) {
                delete data[key]
              }
            }
            data.language = lang
            updateData({
              type: 'create',
              data,
              tenantId: selectedPortal || ''
            })
          }
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors
        }) => (
          <Form>
            <Tabs
              activeKey={selectedKeyTab}
              onSelect={(tab) => setSelectedKeyTab(tab)}
              className='nav-light'
            >
              {languageTabs && Object.keys(languageTabs)?.map(key => {
                return (
                  <Tab
                    disabled={isError && Object.keys(isError).length}
                    eventKey={key}
                    key={key}
                    title={key}
                  >
                    <div className='mt-5'>
                      <UserEndPages
                        myKeys={myKeys}
                        selectedTab={selectedKeyTab}
                        values={values}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        languageTabs={languageTabs[selectedKeyTab]}
                        isError={isError}
                        setIsError={setIsError}
                      />
                    </div>
                  </Tab>

                )
              })}
            </Tabs>

            <div className='mt-4 d-flex justify-content-between'>
              <Button
                variant='outline-warning'
                onClick={() =>
                  isTenant
                    ? navigate(TenantRoutes.LanguageManagement)
                    : navigate(SuperAdminRoutes.LanguageManagement, { state: { myAdminId: selectedClient || '', myTenantId: selectedPortal || '' } })}
                className='ml-2'
              >
                Cancel
              </Button>
              <Button
                className='ml-2'
                variant='outline-success'
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default AddLangauge
