import * as Yup from 'yup'

export const createAdminUserSchema = (userDetails, adminRole) =>
  Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email Required'),
    password: userDetails
      ? Yup.string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
          'Invalid Password'
        )
        .max(50)
      : Yup.string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
          'Invalid Password'
        )
        .max(50)
        .min(8)
        .required('Password Required'),
    firstName: Yup.string().min(3, 'First Name must be atleast 3 characters')
      .max(200)
      .matches(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/, 'Only Alphabets and Space Allowed and Must Start with Alphabet')
      .required('First Name Required'),
    lastName: Yup.string().min(3, 'Last Name must be atleast 3 characters')
      .max(200)
      .matches(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/, 'Only Alphabets and Space Allowed and Must Start with Alphabet')
      .required('Last Name Required'),
    agentName: Yup.string()
      .min(3, 'Minimum 3 Characters Required')
      .required('Username Required'),
    phone: Yup.string()
      .min(10, 'Phone must be at least 10 digits')
      .max(10, 'Phone must be at most 10 digits')
      .matches(
        /^((\\+[1-9]{1,10}[ \\-]*)|(\\([0-9]{1,10}\\)[ \\-]*)|([0-9]{1,10})[ \\-]*)*?[0-9]{1,10}?[ \\-]*[0-9]{1,10}?$/,
        'Enter a valid phone number'
      ),
    group: Yup.string().min(3, 'Group Name must be atleast 3 characters')
      .max(200)
      .matches(/^[A-Za-z0-9 ]+$/, 'Only Alphabets, Numbers and Space Allowed')
      .required('Group Name Required').nullable(),
    tenantIds: Yup.array().min(1, 'Tenant Required!').required('Tenant Required!'),
    role: Yup.string().required('Role Required').nullable(),
    adminId: Yup.string().when('role', {
      is: (role) => role === 'Support' && adminRole === 'Owner',
      then: Yup.string().required('Manager is required').nullable(),
      otherwise: Yup.string().nullable()
    })
  })
