import React from 'react'
import UserDocsList from '../../../../components/UserDocsList/UserDocsList'
import { ApproveRejectModal } from '../../../../components/ConfirmationModal'
import Reason from './Reason'
import { ImagePreviewModal } from '../../../../components/ImagePreviewModal'
import SumsubKYCTable from './SumsubKYCTable'
import useKYCSettings from '../hooks/useKYCSettings'

const Settings = ({
  isTenant = false
}) => {
  const {
    show,
    name,
    title,
    status,
    enable,
    setShow,
    imageUrl,
    docLabels,
    setEnable,
    handleYes,
    labelName,
    userDetails,
    handleClose,
    setImageUrl,
    handleVerify,
    userDocuments,
    documentLabels,
    updateDocument,
    showReasonModal,
    handleReRequest,
    handleImagePreview,
    imagePreviewModalShow,
    setImagePreviewModalShow
  } = useKYCSettings({ isTenant })

  return (
    <>
      {userDetails?.kycMethod === 1
        ? (
          <SumsubKYCTable
            userDetails={userDetails}
            userDocuments={userDocuments}
          />
          )
        : (
          <UserDocsList
            userDetails={userDetails}
            userDocuments={userDocuments}
            documentLabels={documentLabels}
            updateDocument={updateDocument}
            handleVerify={handleVerify}
            docLabels={docLabels}
            handleReRequest={handleReRequest}
            handleImagePreview={handleImagePreview}
            isTenant={isTenant}
          />
          )}
      {show && (
        <ApproveRejectModal
          show={show}
          setShow={setShow}
          handleYes={handleYes}
          status={status}
          name={name}
        />
      )}
      {showReasonModal && (
        <Reason
          show={showReasonModal}
          handleYes={handleYes}
          handleClose={handleClose}
          title={title}
          enable={enable}
          setEnable={setEnable}
          name={name}
          labelName={labelName}
        />
      )}
      {imagePreviewModalShow && (
        <ImagePreviewModal
          imagePreviewModalShow={imagePreviewModalShow}
          setImagePreviewModalShow={setImagePreviewModalShow}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
        />
      )}
    </>
  )
}

export default React.memo(Settings)
