import * as Yup from 'yup'

export const bonusSchema = (curr, { bonusDetail }) =>
  [Yup.object().shape({
    promotionTitle: Yup.string().required('Promotion Title Required').nullable(),
    bonusType: Yup.string().required('Bonus Type Required').nullable(),
    appliedBonusVal: Yup.string().when(['bonusType'], {
      is: (bonusType) => {
        if (bonusType === 'balance') {
          return true
        } else {
          return false
        }
      },
      then: Yup.string().required('Applied Bonus Required')
    }).nullable(),
    aggregatorId: Yup.string().when(['bonusType'], {
      is: (bonusType) => {
        if (bonusType === 'freespins' || bonusType === 'cashfreespins') {
          return true
        } else {
          return false
        }
      },
      then: Yup.string().required('Aggregator Required')
    }).nullable(),
    betLevel: Yup.number().when(['bonusType'], {
      is: (bonusType) => {
        if (bonusType === 'freespins' || bonusType === 'cashfreespins') {
          return true
        } else {
          return false
        }
      },
      then: Yup.number()
        .min(1, 'Minimum value must be 1').required('Bet Level Required')
        .integer('Only Integer Values Allowed')
    }).nullable(),
    adminId: Yup.number().required('Owner Id Required').nullable(),
    tenantIds: Yup.array().min(1, 'Select At Least One Portal'),
    termCondition: Yup.string().required('Terms and Conditions Required').nullable(),
    description: Yup.string().required('Description Required').nullable(),
    promotionalOverview: Yup.string().required('Promotion Overview Required').nullable(),
    bonusImage: !bonusDetail
      ? Yup.mixed().required('A file is required').test('File Size',
        'File Size Should be Less Than 1MB', (value) => !value || (value && value.size <= 1024 * 1024))
        .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
          (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg'].includes(value.type))).nullable()
      : Yup.mixed().test('File Size',
        'File Size Should be Less Than 1MB', (value) => !value || (value && value.size <= 1024 * 1024))
        .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
          (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg'].includes(value.type))).nullable(),
    validOnDays: Yup.array().when(['visibleInPromotions', 'bonusType'], {
      is: (visibleInPromotions, bonusType) => {
        if (visibleInPromotions && bonusType !== 'promotion') {
          return true
        } else {
          return false
        }
      },
      then: Yup.array().min(1, 'Select At Least One Day').nullable()
    }).nullable(),
    quantity: Yup.number().when(['bonusType'], {
      is: (bonusType) => {
        if (bonusType === 'freespins') {
          return true
        } else {
          return false
        }
      },
      then: Yup.number()
        .min(1, 'Minimum Value Must be One')
        .typeError('Only Numbers Allowed')
        .required('Quantity Required')
        .integer('Only Integer Values Allowed')
    }),

    wageringRequirementType: Yup.string().when(['bonusType'], {
      is: (bonusType) => {
        if (bonusType !== 'balance' && bonusType !== 'winBoost') {
          return true
        } else {
          return false
        }
      },
      then: Yup.string().required('Wagering Type Required').nullable()
    }),
    depositBonusPercent: Yup.number().when(['bonusType'], {
      is: (bonusType) => {
        if (bonusType === 'match' || bonusType === 'balance') {
          return true
        } else {
          return false
        }
      },
      then: Yup.number()
        .min(1, '% Must be greater than or equal to 1')
        .typeError('Bonus Percent must be a Number')
        .required('Bonus Percentage Required').nullable()
    }),

    wageringMultiplier: Yup.number().when(['bonusType'], {
      is: (bonusType) => {
        if (bonusType !== 'balance' && bonusType !== 'winBoost' && bonusType !== 'promotion') {
          return true
        } else {
          return false
        }
      },
      then: Yup.number().min(0, 'Minimum Value Must be Zero').typeError('Only Numbers Allowed').required('Wagering Multiplier Required')
    }).test('testBonusFreespins',
      'Minimum Value Must be 1',
      (value, context) => {
        if (value < 1 && context.parent.bonusType === 'freespins' && context.parent.wageringRequirementType === 'bonus') {
          return false
        } else {
          return true
        }
      }
    ),
    daysToClear: Yup.number().when(['bonusType'], {
      is: (bonusType) => {
        if (bonusType !== 'promotion' && bonusType !== 'winBoost') {
          return true
        } else {
          return false
        }
      },
      then: Yup.number()
        .min(1, 'Minimum Value Must be One').typeError('Only Numbers Allowed')
        .integer('Only Integer Values Allowed')
        .required('Days To Clear Required')
    })
  }),
  Yup.object().shape({
    currency: Yup.object().when(['bonusType', 'isSticky'], (bonusType, isSticky) => {
      if (!['balance', 'winBoost'].includes(bonusType)) {
        return currencyValidate({ curr, bonusType, isSticky })
      } else {
        return false
      }
    }
    )
  }),
  Yup.object().shape({
  }),
  Yup.object().shape({
    wageringTemplateId: Yup.string().required('Please Select Wagering Template').nullable()
  }),
  Yup.object().shape({
  }),
  Yup.object().shape({
    bonusType: Yup.string(),
    loyaltyLevel: Yup.array().when('bonusType', {
      is: (bonusType) => bonusType !== 'winBoost',
      then: Yup.array().of(Yup.object().shape({
        bonusPercentage: Yup.number().typeError('Only Numbers Allowed').required('Value Required.').min(0, 'Must be Greater Than 0').max(9999, 'Must be Less Than 9999'),
        cashback_multiplier: Yup.number().typeError('Only Numbers Allowed').required('Value Required.').min(0, 'Must be Greater Than 0').max(9999, 'Must be Less Than 9999'),
        maxBonusThreshold: Yup.number().typeError('Only Numbers Allowed').positive('Value Must be Positive').required('Value Required.'),
        maxWinAmount: Yup.number().typeError('Only Numbers Allowed').positive('Value Must be Positive').required('Required.')
      })),
      otherwise: Yup.array().of(Yup.object().shape({
        bonusPercentage: Yup.number().typeError('Only Numbers Allowed').required('Value Required.').min(0, 'Must be Greater Than 0').max(9999, 'Must be Less Than 9999'),
        maxWinAmount: Yup.number().typeError('Only Numbers Allowed').positive('Value Must be Positive').required('Value Required.')
      })
      )
    })
  })
  ]

const currencyValidate = ({ curr, bonusType, isSticky }) => {
  const validationObject = {}
  if (bonusType === 'match') {
    for (const file in curr) {
      validationObject[file] = Yup.object().shape({
        maxBonusThreshold: Yup.number().typeError('Only Numbers Allowed').required('Required.'),
        minDeposit: Yup.number().typeError('Only Numbers Allowed').required('Required.'),
        maxWinAmount: Yup.number().typeError('Only Numbers Allowed').required('Required.'),
        zeroOutThreshold: Yup.number().typeError('Only Numbers Allowed').required('Required.'),
        maxBetAllowed: Yup.number().typeError('Only Numbers Allowed').required('Required.')
      })
    }
  } else if (bonusType === 'deposit' || bonusType === 'wagering') {
    for (const file in curr) {
      validationObject[file] = Yup.object().shape({
        zeroOutThreshold: Yup.number().typeError('Only Numbers Allowed').required('Required.'),
        minBalance: Yup.number().typeError('Only Numbers Allowed').required('Required.'),
        maxBetAllowed: Yup.number().typeError('Only Numbers Allowed').required('Required.')
      })
    }
  } else if (bonusType === 'freespins' && (isSticky === 'true' || isSticky)) {
    for (const file in curr) {
      validationObject[file] = Yup.object().shape({
        maxWinAmount: Yup.number().typeError('Only Numbers Allowed').required('Required.'),
        zeroOutThreshold: Yup.number().typeError('Only Numbers Allowed').required('Required.'),
        minDeposit: Yup.number().typeError('Only Numbers Allowed').required('Required.'),
        maxBetAllowed: Yup.number().typeError('Only Numbers Allowed').required('Required.')
      })
    }
  } else {
    for (const file in curr) {
      validationObject[file] = Yup.object().shape({
        maxWinAmount: Yup.number().typeError('Only Numbers Allowed').required('Required.'),
        minDeposit: Yup.number().typeError('Only Numbers Allowed').required('Required.'),
        maxBetAllowed: Yup.number().typeError('Only Numbers Allowed').required('Required.')
      })
    }
  }
  return Yup.object(validationObject)
}
