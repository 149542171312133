import React from 'react'
import useEditAdminUser from '../hooks/useEditAdminUser'
import CreateTenantRoles from './CreateAdminUser'

const EditAdminUser = () => {
  const { data, adminUserId } = useEditAdminUser()

  return (
    <>
      {data && data.adminUserId === adminUserId && (
        <CreateTenantRoles userDetails={data} />
      )}
    </>
  )
}

export default EditAdminUser
