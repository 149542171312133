import { faUser } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './Hierarchy.css'

class NodeLabel extends React.PureComponent {
  render () {
    const { nodeData, isTenant } = this.props

    return (
      <span className='tree-container'>
        <p className='tree-name'>{nodeData.name} &nbsp; {nodeData?.isInitial ? '' : <a href={`/${isTenant ? 'tenant' : 'super-admin'}/admin-details/${nodeData.id}`} target='_blank' rel='noopener noreferrer'><FontAwesomeIcon icon={faUser} /></a>}</p>
      </span>
    )
  }
}

export default NodeLabel
