import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isTenant } from '../../../../utils/constants'
import { getElasticHealthStart } from '../../../../store/redux-slices/dashboard'
import useCheckPermission from '../../../../utils/checkPermission'

const useDashboard = () => {
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const { adminPermissions, loading } = useSelector(state => state.admins)
  const { elasticHealth } = useSelector(state => state.dashboard)
  const { isHidden } = useCheckPermission()

  const [accordionState, setAccordionState] = useState({
    demographicReport: true,
    playerManagementReport: false,
    playerLiabilityReport: false,
    kpiSummaryReport: false,
    kpiReport: false,
    gameReport: false,
    JackpotReport: false
  })
  const reportsToShow = ['GameReport', 'KpiReport', 'DemographReport', 'PlayerManagementReport', 'KpiSummaryReport', 'LivePlayerReport', 'PlayerLiabilityReport', 'JackpotReport']
  const permissionKeys = adminPermissions && Object.keys(adminPermissions)
  const dispatch = useDispatch()
  const nonElasticReports = ['DemographReport', 'LivePlayerReport', 'PlayerManagementReport', 'JackpotReport']

  useEffect(() => {
    dispatch(getElasticHealthStart({ isTenant }))
  }, [])

  return {
    loading,
    isHidden,
    elasticHealth,
    reportsToShow,
    selectedClient,
    selectedPortal,
    accordionState,
    permissionKeys,
    adminPermissions,
    setSelectedClient,
    setSelectedPortal,
    nonElasticReports,
    setAccordionState
  }
}

export default useDashboard
