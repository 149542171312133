import {
  Row,
  Col,
  Table,
  Form,
  Button,
  ButtonGroup
} from '@themesberg/react-bootstrap'

import React from 'react'
import PaginationComponent from '../../../components/Pagination'
import ConfirmationModal from '../../../components/ConfirmationModal'
import Trigger from '../../../components/OverlayTrigger'
import useAggregatorListing from './useAggregatorListing'
import AddAggregator from './components/AddAggregator'
import useCheckPermission from '../../../utils/checkPermission'
import { checkIsTenant } from '../../../utils/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faWindowClose } from '@fortawesome/pro-solid-svg-icons'

export default () => {
  const {
    name,
    data,
    page,
    limit,
    show,
    status,
    search,
    loading,
    setPage,
    setData,
    setLimit,
    handleYes,
    setSearch,
    totalPages,
    handleShow,
    statusShow,
    aggregators,
    handleClose,
    setStatusShow,
    createAggregator,
    handleStatusShow,
    aggregatorOptions
  } = useAggregatorListing()
  const { isHidden } = useCheckPermission()

  return (
    <>
      <Row>
        <Col sm={8}>
          <h3>Master Casino Aggregators</h3>
        </Col>

        <Col>
          <div className='d-flex justify-content-end text-right mb-2'>
            <Form.Control
              type='search'
              name='search'
              className='mx-2'
              placeholder='Search Aggregator'
              size='sm'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />

            {checkIsTenant() &&
              <Button
                variant='outline-success'
                className='f-right'
                size='sm'
                onClick={() => {
                  handleShow()
                }}
                hidden={isHidden({ module: { key: 'CasinoManagement', value: 'C' } })}
              >
                Create
              </Button>}
          </div>
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            <th>ID</th>
            <th>Name</th>
            {checkIsTenant() && <th>Status</th>}
            {checkIsTenant() && <th>Action</th>}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {aggregators && !loading &&
                        aggregators?.rows?.map(
                          (
                            {
                              name,
                              masterGameAggregatorId,
                              isActive
                            }
                          ) => {
                            return (
                              <tr key={masterGameAggregatorId}>
                                <td>{masterGameAggregatorId}</td>
                                <td>
                                  <Trigger message={name}>
                                    <span
                                      style={{
                                        width: '200px',
                                        cursor: 'pointer'
                                      }}
                                      className='d-inline-block text-truncate'
                                    >
                                      {name}
                                    </span>
                                  </Trigger>
                                </td>

                                {checkIsTenant() &&
                                  <td>
                                    {isActive
                                      ? (
                                        <span className='text-success'>Active</span>
                                        )
                                      : (
                                        <span className='text-danger'>In Active</span>
                                        )}
                                  </td>}

                                {checkIsTenant() &&
                                  <td>
                                    {!isHidden({ module: { key: 'CasinoManagement', value: 'T' } })
                                      ? (
                                        <ButtonGroup>
                                          {!isActive
                                            ? (
                                              <Trigger message='Set Status Active'>
                                                <Button
                                                  className='m-1'
                                                  size='sm'
                                                  variant='success'
                                                  onClick={() =>
                                                    handleStatusShow(
                                                      masterGameAggregatorId,
                                                      isActive,
                                                      name
                                                    )}
                                                  hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                                >
                                                  <FontAwesomeIcon icon={faCheckSquare} />
                                                </Button>
                                              </Trigger>
                                              )
                                            : (
                                              <Trigger message='Set Status In-Active'>
                                                <Button
                                                  className='m-1'
                                                  size='sm'
                                                  variant='danger'
                                                  onClick={() =>
                                                    handleStatusShow(
                                                      masterGameAggregatorId,
                                                      isActive,
                                                      name
                                                    )}
                                                  hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                                >
                                                  <FontAwesomeIcon icon={faWindowClose} />
                                                </Button>
                                              </Trigger>
                                              )}
                                        </ButtonGroup>)
                                      : 'NA'}
                                  </td>}

                              </tr>
                            )
                          }
                        )}

          {aggregators?.count === 0 && !loading &&
                          (
                            <tr>
                              <td
                                colSpan={5}
                                className='text-danger text-center'
                              >
                                No data found
                              </td>
                            </tr>
                          )}
        </tbody>
      </Table>

      {aggregators?.count !== 0 && !loading &&
              (
                <PaginationComponent
                  page={aggregators?.count < page ? setPage(1) : page}
                  totalPages={totalPages}
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                />
              )}

      <ConfirmationModal
        setShow={setStatusShow}
        show={statusShow}
        handleYes={handleYes}
        active={status}
        name={name}
      />
      {/* <CreateAggregator
        handleClose={handleClose}
        show={show}
        createAggregator={createAggregator}
        loading={loading}
        data={data?.masterGameAggregatorId ? data : null}
      /> */}

      {show &&
        <AddAggregator
          show={show}
          loading={loading}
          setData={setData}
          data={data}
          handleClose={handleClose}
          createAggregator={createAggregator}
          aggregatorOptions={aggregatorOptions}
        />}
    </>
  )
}
