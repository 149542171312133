import React from 'react'
import LabelData from './LabelData'
import Affiliate from './Affiliate'
import {
  Button,
  Card,
  Col,
  Dropdown,
  Spinner
} from '@themesberg/react-bootstrap'
import Trigger from '../../../../components/OverlayTrigger'
import { SpinnerLoader } from '../../../../components/Preloader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faRedo } from '@fortawesome/pro-solid-svg-icons'
import ResponsibleGaming from '../../../../components/ResponsibleGaming'

const Overview = ({
  user,
  addTag,
  loading,
  isHidden,
  isTenant,
  moreInfo,
  basicInfo,
  userLimits,
  setShowModal,
  setEditModal,
  duplicateUsers,
  setUserPassword,
  reloadUserStatus,
  setPasswordEmail,
  setFreeSpinModal,
  adminPermissions,
  getDuplicateUsers,
  userCurrentStatus,
  setIsInternalModal,
  setVerifyPhoneModal,
  setShowPaymentModal,
  setVerifyEmailModal,
  setShowAddAffiliate,
  pdfDownload = false,
  setActiveInactiveModal,
  setShowRemoveAffiliate,
  addUserAffiliateLoading,
  setShowManageMoneyModal,
  updateUserAffiliateLoading
}) => {
  return (
    <>
      <Col xs={12} md={6} className='mb-2'>
        <Card className='card-overview'>
          <h6 className='h4-overview text-center mt-1'>
            Basic Info <hr className='h4-hr mt-1' />
          </h6>
          <div className='div-overview'>
            {basicInfo?.map(({ label, value, subValue }) => {
              return user?.kycMethod !== 1 && label === 'Applicant Id'
                ? (
                    ''
                  )
                : label === 'In-Active Reason'
                  ? (
                      value
                        ? (
                          <LabelData
                            label={label}
                            inActive
                            subValue={subValue}
                            value={value}
                            desc={user?.disableReason}
                            adminPermissions={adminPermissions}
                          />
                          )
                        : (
                            '-'
                          )
                    )
                  : (
                      (label === 'Reason' && value ? true : label !== 'Reason') && (
                        <LabelData
                          label={label}
                          subValue={subValue}
                          value={value}
                          desc='You were not allowed to view this content'
                          adminPermissions={adminPermissions}
                        />
                      )
                    )
            })}

            <div className='d-flex justify-content-between m-1'>
              <h6 style={{ fontSize: '14px' }}>User Current Status</h6>
              <div className='d-flex justify-content-between'>
                <span
                  className={`${
                    userCurrentStatus === 'Active'
                      ? 'text-success'
                      : userCurrentStatus === 'Away'
                      ? 'text-warning'
                      : 'text-danger'
                  }`}
                >
                  {userCurrentStatus || 'Offline'}
                </span>
                {loading
                  ? (
                    <SpinnerLoader />
                    )
                  : !pdfDownload && (
                    <FontAwesomeIcon
                      onClick={() => reloadUserStatus()}
                      style={{
                        fontSize: '15px',
                        color: 'gray',
                        cursor: 'pointer',
                        marginTop: '5px',
                        marginLeft: '10px'
                      }}
                      icon={faRedo}
                    />
                    )}
              </div>
            </div>
            <div className='d-flex justify-content-between m-1'>
              <h6 style={{ fontSize: '14px' }}>Affiliate Token</h6>
              <span style={{ fontSize: '14px' }}>
                {user?.trackingToken || 'NA'}
              </span>
            </div>
            <div className='d-flex justify-content-between m-1'>
              <h6 style={{ fontSize: '14px' }}>Affiliate Status</h6>
              {user?.affiliateStatus
                ? (
                  <span className='text-success '>Linked</span>
                  )
                : (
                  <span className='text-danger '>Not Linked</span>
                  )}
            </div>
          </div>
          {!pdfDownload &&
            <h6 className='h4-overview text-center'>
              More Info
              <hr className='h4-hr' />
            </h6>}
          <div className='div-overview'>
            {moreInfo?.map(({ label, value, subValue }) => {
              return (
                <div key={label} className='d-flex justify-content-between m-1'>
                  <h6 style={{ fontSize: '14px' }}>{label}</h6>&nbsp;
                  <div>
                    <span style={{ fontSize: '14px' }} className={subValue}>
                      {value || 'NA'}
                    </span>
                    {!adminPermissions?.Users?.includes('RUI') && !pdfDownload &&
                      label === 'Phone Number' && (
                        <Trigger message='You were not allowed to view this content'>
                          <Button
                            variant='outline-warning'
                            size='xs'
                            className='m-1'
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </Button>
                        </Trigger>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
          {user?.affiliate && !pdfDownload && <Affiliate userData={user} />}
        </Card>
      </Col>

      <Col xs={12} md={6} className='mb-2'>
        <Card className='card-overview'>
          {!pdfDownload &&
            <>
              <h6 className='h4-overview text-center'>
                Account Actions <hr className='h4-hr' />
              </h6>
              <div className='div-overview d-flex justify-content-center flex-wrap'>
                <Button
                  className='actionButton'
                  variant={user?.status ? 'outline-danger' : 'outline-success'}
                  hidden={isHidden({ module: { key: 'Users', value: 'T' } })}
                  onClick={() => setActiveInactiveModal(true)}
                >
                  {user && user?.status ? 'In-Active' : 'Active'}
                </Button>

                <Button
                  className='actionButton'
                  variant='outline-success'
                  hidden={
                isHidden({ module: { key: 'Users', value: 'EV' } }) ||
                user?.isEmailVerified
              }
                  onClick={() => setVerifyEmailModal(true)}
                >
                  Verify Email
                </Button>

                <Button
                  className='actionButton'
                  variant='outline-success'
                  hidden={
                isHidden({ module: { key: 'Users', value: 'PV' } }) ||
                user?.isPhoneVerified
              }
                  onClick={() => setVerifyPhoneModal(true)}
                >
                  Verify Phone
                </Button>

                <Button
                  className='actionButton'
                  variant='outline-warning'
                  hidden={
                isHidden({ module: { key: 'Users', value: 'MT' } }) ||
                user?.tags?.includes('Internal')
              }
                  onClick={() => setIsInternalModal(true)}
                >
                  Internal
                </Button>

                <Button
                  variant='outline-warning'
                  hidden={isHidden({ module: { key: 'Users', value: 'MT' } })}
                  onClick={() => {
                    addTag()
                  }}
                  className='actionButton'
                >
                  Manage Tag
                </Button>

                <Button
                  variant='outline-secondary'
                  onClick={() => {
                    getDuplicateUsers()
                  }}
                  className='actionButton'
                >
                  Duplicates ({duplicateUsers?.count})
                </Button>

                <Button
                  className='actionButton'
                  variant='outline-secondary'
                  hidden={isHidden({ module: { key: 'Bonus', value: 'Issue' } })}
                  onClick={() => setShowModal(true)}
                >
                  Give Bonus
                </Button>

                <Button
                  className='actionButton'
                  variant='outline-secondary'
                  hidden={isHidden({ module: { key: 'Bonus', value: 'Issue' } })}
                  onClick={() => setFreeSpinModal(true)}
                >
                  Issue Freespins
                </Button>

                <Button
                  className='actionButton'
                  variant='outline-success'
                  hidden={isHidden({ module: { key: 'Users', value: 'AB' } })}
                  onClick={() => setShowManageMoneyModal(true)}
                >
                  Manage Money
                </Button>

                {user?.trackingToken && user?.isAffiliateUpdated === false && (
                  <Button
                    className='actionButton'
                    variant='outline-success'
                    onClick={() => setShowAddAffiliate((prev) => true)}
                  >
                    Add Affiliate
                    {addUserAffiliateLoading && (
                      <Spinner
                        as='span'
                        animation='border'
                        role='status'
                        aria-hidden='true'
                      />
                    )}
                  </Button>
                )}

                {user?.trackingToken &&
              user?.isAffiliateUpdated &&
              user?.affiliateStatus && (
                <Button
                  className='actionButton'
                  variant='outline-danger'
                  hidden={isHidden({ module: { key: 'Users', value: 'RA' } })}
                  onClick={() => setShowRemoveAffiliate(true)}
                >
                  Remove Affiliate
                  {updateUserAffiliateLoading && (
                    <Spinner
                      as='span'
                      animation='border'
                      role='status'
                      aria-hidden='true'
                    />
                  )}
                </Button>
                )}

                <Button
                  className='actionButton'
                  variant='outline-warning'
                  hidden={isHidden({ module: { key: 'Users', value: 'U' } })}
                  onClick={() => setEditModal(true)}
                >
                  Edit Info
                </Button>

                <Button
                  className='actionButton'
                  variant='outline-success'
                  hidden={isHidden({ module: { key: 'Transactions', value: 'C' } })}
                  onClick={() => setShowPaymentModal(true)}
                >
                  Manage Payment
                </Button>

                {!isHidden({ module: { key: 'Users', value: 'UP' } }) && (
                  <Dropdown className='d-inline'>
                    <Dropdown.Toggle
                      id='dropdown-autoclose-outside'
                      className='actionButton'
                      variant='outline-success'
                    >
                      Reset Password
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='user-dropdown user'>
                      <Dropdown.Item onClick={() => setPasswordEmail(true)}>
                        Send Email
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setUserPassword(true)}>
                        Reset Password
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </>}
          <ResponsibleGaming
            user={user}
            isTenant={isTenant}
            userLimits={userLimits}
            pdfDownload={pdfDownload}
            currencyCode={user?.currencyCode}
          />
        </Card>
      </Col>
    </>
  )
}

export default Overview
