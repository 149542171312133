import { Table, Button, Modal, Form } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import Trigger from '../../../../components/OverlayTrigger'
import { useDispatch, useSelector } from 'react-redux'
import {
  getKycLabelsStart,
  updateKycLabelsStart
} from '../../../../store/redux-slices/fetchData'
import { useParams } from 'react-router-dom'
import { toast } from '../../../../components/Toast'
import { faRedo } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ResetConfirmationModal } from '../../../../components/ConfirmationModal'

const SumsubKYCTable = ({ userDocuments, userDetails }) => {
  const dispatch = useDispatch()
  const { userId } = useParams()
  const [open, setOpen] = useState(false)
  const [kycLabels, setKycLabels] = useState('')
  // const [imageSrc, setImageSrc] = useState(null)
  const [resetModal, setResetModal] = useState(false)
  // const [showImage, setShowImage] = useState(false)
  const [resetDocData, setResetDocData] = useState({})
  const [allKycLabels, setAllKycLabels] = useState([])
  const isTenant = !window.location.href.match(/super/g)
  const { kycLabels: kycLabelsData } = useSelector((state) => state.fetch)
  const checkNullDocKeys = userDocuments && Object.values(userDocuments).every((value) => value === null)

  useEffect(() => {
    const newData = kycLabelsData?.filter(
      (item) => item?.name !== userDetails?.other?.kycLevelName
    )
    setAllKycLabels(newData)
  }, [kycLabelsData])

  const handleYes = () => {
    dispatch(
      updateKycLabelsStart({
        userId: parseInt(userId),
        isTenant,
        type: 'resetKycLabels',
        applicationId: userDetails.applicantId,
        docName: resetDocData.name,
        setResetDocData,
        setResetModal,
        tenantId: parseInt(userDetails?.tenantId)
      })
    )
  }

  // const viewImage = (imageId) => {
  //   dispatch(updateKycLabelsStart({
  //     userId,
  //     isTenant,
  //     type: 'viewImage',
  //     applicationId: userDetails.applicantId,
  //     imageId,
  //     setResetDocData,
  //     setResetModal
  //   }))
  //   setShowImage(true)
  // }

  // const fetchData = async () => {
  //   const { signature, url, method = 'get' } = imageBuffer
  //   const timeStamp = Math.floor(Date.now() / 1000)

  //   try {
  //     const response = await axios.get(`https://api.sumsub.com${url}`, {
  //       headers: {
  //         'X-App-Token': 'sbx:XLfCuCitOeWSX7PzPR6YCDT4.Wqc5HFUdPjx7imPrBCodmpidGoUQrtD7', // env
  //         'X-App-Access-Sig': signature,
  //         Accept: 'application/json',
  //         'X-App-Access-Ts': `${timeStamp}`
  //       },
  //       responseType: 'blob'
  //     })

  //     const urlCreator = window.URL || window.webkitURL
  //     const fileUrl = urlCreator.createObjectURL(response.data)
  //     setImageSrc(fileUrl)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // useEffect(() => {
  //   if (imageBuffer) fetchData()
  // }, [imageBuffer])

  return (
    <>
      <div className='d-flex justify-content-between'>
        <div className='d-flex justify-content-center'>
          <Card
            body
            className='w-100 text-start'
            style={{ maxWidth: '500px', margin: 'auto auto 2rem 1rem' }}
          >
            <div>
              SumSub Applicant Id:
              {userDetails?.applicantId
                ? (
                  <span
                    onClick={() => window.open(`https://cockpit.sumsub.com/checkus#/applicant/${userDetails.applicantId}`, '_blank')}
                    style={{ color: '#0948b3' }}
                    className='text-link text-truncate cursor-pointer'
                  >
                    {' '}
                    {userDetails?.applicantId}
                  </span>
                  )
                : (
                  <span className='header-font-weight'> NA </span>
                  )}
            </div>
            <div>
              KYC Method:{' '}
              <span className='header-font-weight'>
                {' '}
                {userDetails?.kycMethod === 1
                  ? 'Sumsub'
                  : 'System KYC' || 'NA'}{' '}
              </span>
            </div>
            <div>
              KYC Status:{' '}
              <span className='header-font-weight'>
                {' '}
                {userDetails?.kycMethod === 1
                  ? userDetails?.sumsubKycStatus?.toUpperCase()
                  : userDetails?.kycStatus || 'NA'}{' '}
              </span>
            </div>
            <div>
              KYC Label Name:{' '}
              <span className='header-font-weight'>
                {' '}
                {userDetails?.other?.kycLevelName || 'NA'}{' '}
              </span>
            </div>
          </Card>
        </div>
        {userDetails?.applicantId &&
          !['pending', 'queued', 'onHold'].includes(userDetails?.sumsubKycStatus) &&
          (
            <div className='m-4'>
              <Button
                variant='outline-success'
                onClick={() => {
                  setOpen(true)
                  dispatch(
                    getKycLabelsStart({ userId, isTenant, type: 'getLabels', tenantId: userDetails?.tenantId })
                  )
                }}
                className='actionButton'
              >
                Change label
              </Button>
            </div>
          )}
      </div>

      <Modal show={open} onHide={() => setOpen(false)} size='md'>
        <Modal.Header closeButton>
          <Modal.Title className='fs-2'>Change KYC labels</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <center>
            <span className='d-flex justify-content-center'>
              <Form.Label
                style={{
                  marginBottom: '0',
                  marginTop: '5px',
                  minWidth: '92px'
                }}
              >
                Kyc labels
              </Form.Label>
              <Form.Select
                onChange={(e) => setKycLabels(e.target.value)}
                value={kycLabels}
                style={{
                  maxWidth: '180px',
                  marginRight: '10px',
                  height: '36px'
                }}
                size='sm'
              >
                <option value=''>Select KYC Label</option>
                {allKycLabels?.map((item) => {
                  return (
                    <option value={item.name} key={item.name}>
                      {item.name}
                    </option>
                  )
                })}
              </Form.Select>
            </span>
          </center>
        </Modal.Body>
        <div className='mt-4 '>
          <Modal.Footer className='d-flex justify-content-between align-items-center'>
            <Button variant='outline-warning' onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              variant='outline-success'
              onClick={() => {
                if (!kycLabels) return toast('Please Select label', 'error')
                dispatch(
                  updateKycLabelsStart({
                    userId: parseInt(userId),
                    isTenant,
                    type: 'updateKycLabels',
                    applicationId: userDetails.applicantId,
                    kycLabels,
                    setOpen,
                    setKycLabels,
                    tenantId: parseInt(userDetails?.tenantId)
                  })
                )
              }}
              className='ml-2'
            >
              Submit
            </Button>
          </Modal.Footer>
        </div>
      </Modal>

      <Table
        bordered
        striped
        responsive
        hover
        size='sm'
        className='text-center'
      >
        <thead className='thead-dark'>
          <tr>
            <th>Document ID</th>
            <th>Name</th>
            <th>Reason</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {userDocuments &&
          Object.keys(userDocuments)?.length > 0 &&
          !checkNullDocKeys
            ? (
                Object.keys(userDocuments)?.map(
                  (docs, index) =>
                    userDocuments[docs] &&
                (userDocuments[docs]?.imageIds
                  ? (
                      userDocuments[docs]?.imageIds?.map((imgId, indx) => (
                        <tr key={`${userDocuments[docs]}-${indx}`}>
                          <td>{imgId}</td>
                          <td>{`${docs} ${
                        userDocuments[docs].imageIds?.length > 1
                          ? indx === 0
                            ? '(FRONT)'
                            : '(BACK)'
                          : ''
                      }`}
                          </td>
                          <td>
                            <Trigger
                              message={
                            userDocuments[docs]?.imageReviewResults?.[imgId]
                              ?.clientComment ||
                            userDocuments[docs]?.imageReviewResults?.[imgId]
                              ?.moderationComment ||
                            '-'
                          }
                            >
                              <span
                                style={{
                                  width: '300px',
                                  cursor: 'pointer'
                                }}
                                className='d-inline-block text-truncate'
                              >
                                {userDocuments[docs]?.imageReviewResults?.[imgId]
                                  ?.clientComment ||
                              userDocuments[docs]?.imageReviewResults?.[imgId]
                                ?.moderationComment ||
                              '-'}
                              </span>
                            </Trigger>
                          </td>
                          {/* <td
                      className='text-link text-truncate cursor-pointer' onClick={() => {
                        viewImage(imgId)
                        setResetDocData({ ...resetDocData, name: docs })
                      }}
                    >View Image
                    </td> */}
                          <td>
                            {userDocuments[docs]?.imageReviewResults?.[imgId]
                              ?.reviewAnswer
                              ? userDocuments[docs]?.imageReviewResults?.[imgId]
                                ?.reviewAnswer === 'RED'
                                ? 'Rejected'
                                : 'Accepted'
                              : 'Requested'}
                          </td>
                          <td>
                            {userDocuments[docs]?.imageReviewResults?.[imgId]
                              ?.reviewAnswer === 'GREEN' &&
                        userDetails?.sumsubKycStatus === 'completed'
                              ? (
                                <Trigger message='Reset Document'>
                                  <Button
                                    variant='danger'
                                    size='sm'
                                    onClick={() => {
                                      setResetModal(true)
                                      setResetDocData({
                                        name: docs,
                                        applicationId: userDetails.applicantId
                                      })
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faRedo} />
                                  </Button>
                                </Trigger>
                                )
                              : (
                                  'NA'
                                )}
                          </td>
                        </tr>
                      ))
                    )
                  : (
                    <tr key={`${userDocuments[docs]}-${index}`}>
                      <td>N/A</td>
                      <td>{docs}</td>
                      <td>-</td>
                      {/* <td className='text-link d-inline-block text-truncate' onClick={() => viewImage()}>NA</td> */}
                      {/* <td>NA</td> */}
                      <td>
                        {userDocuments[docs]?.reviewResult
                          ? userDocuments[docs]?.reviewResult === 'RED'
                            ? 'Rejected'
                            : 'Accepted'
                          : 'Requested'}
                      </td>
                      <td>
                        {userDocuments[docs]?.reviewResult === 'GREEN' &&
                      userDetails?.sumsubKycStatus === 'completed'
                          ? (
                            <Trigger message='Reset Document'>
                              <Button
                                variant='danger'
                                size='sm'
                                onClick={() => {
                                  setResetModal(true)
                                  setResetDocData({
                                    ...resetDocData,
                                    name: docs,
                                    applicationId: userDetails.applicantId
                                  })
                                }}
                              >
                                <FontAwesomeIcon icon={faRedo} />
                              </Button>
                            </Trigger>
                            )
                          : (
                              'NA'
                            )}
                      </td>
                    </tr>
                    ))
                )
              )
            : (
              <tr>
                <td colSpan={6} className='text-center text-danger'>
                  No data found
                </td>
              </tr>
              )}
        </tbody>
      </Table>
      {resetModal && (
        <ResetConfirmationModal
          show={resetModal}
          setShow={setResetModal}
          handleYes={handleYes}
          data={
            resetDocData.name === 'IDENTITY'
              ? `both ${resetDocData.name}`
              : resetDocData.name
          }
        />
      )}

      {/* <Modal show={showImage} onHide={() => setShowImage(false)} size='md'>
        <Modal.Header closeButton>
          <Modal.Title className='fs-2'>{resetDocData?.name} IMAGE VIEW</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <center>
            <img
              src={imageSrc}
              alt='sum-sub-img'
              style={{ border: '2px solid aliceblue', borderRadius: '12px', cursor: 'pointer' }}
              className='mb-2'
            />
          </center>
        </Modal.Body>
      </Modal> */}
    </>
  )
}

export default SumsubKYCTable
