import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllTenantsStart,
  updateTenantStatusStart
} from '../../../../store/redux-slices/tenants'
import { getAllCurrenciesStart } from '../../../../store/redux-slices/currencies'
import { getAdminUserDetailsStart } from '../../../../store/redux-slices/adminUser'
import useDidMountEffect from '../../../../utils/useDidMountEffect'

const useTenantListing = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { adminId } = useParams()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [tenantId, setTenantId] = useState()
  const [active, setActive] = useState()
  const [show, setShow] = useState(false)
  const { loading, allTenantList } = useSelector((state) => state.tenants)
  const { adminUserDetails } = useSelector((state) => state.adminUser)
  const isInitialRender = useDidMountEffect()
  const [name, setName] = useState()
  const [status, setStatus] = useState('')

  const handleShow = (id, active, myName) => {
    setTenantId(id)
    setActive(!active)
    setShow(true)
    setName(myName)
  }

  const handleYes = () => {
    dispatch(
      updateTenantStatusStart({
        limit,
        page,
        search,
        tenantId,
        status: active,
        adminId,
        isActive: status
      })
    )
    setShow(false)
  }

  const totalPages = Math.ceil(allTenantList?.count / limit)

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(getAllTenantsStart({ limit, pageNo: page, search, adminId, status }))
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    dispatch(getAdminUserDetailsStart({ adminUserId: adminId }))
  }, [adminId])

  useEffect(() => {
    dispatch(getAllTenantsStart({ limit, pageNo: page, search, adminId, status }))
  }, [limit, page, status])

  useEffect(() => {
    dispatch(getAllCurrenciesStart({ limit: '', pageNo: '' }))
  }, [])

  return {
    totalPages,
    show,
    limit,
    page,
    allTenantList,
    handleYes,
    handleShow,
    navigate,
    setLimit,
    setPage,
    setShow,
    active,
    adminId,
    adminUserDetails,
    loading,
    search,
    setSearch,
    name,
    status,
    setStatus
  }
}

export default useTenantListing
