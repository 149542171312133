import { Button, Tabs, Tab } from '@themesberg/react-bootstrap'
import { Form, Formik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { SuperAdminRoutes, TenantRoutes } from '../../../routes'
import Preloader from '../../Preloader'
import { languageWiseSchema } from './schema'
import UserEndPages from './UserEndPages'
import { checkIsTenant } from '../../../utils/constants'

const EditLangWise = ({
  languageKeyObj,
  selectedTab,
  myKeys,
  isTenant = checkIsTenant(),
  languageTabs,
  selectedKeyTab,
  setSelectedKeyTab,
  updateData,
  isError,
  setIsError,
  check,
  errorCodes = window.location.href.includes('/err/'),
  myKeyData,
  selectedClient,
  selectedPortal
}) => {
  const navigate = useNavigate()

  return (
    <>
      {!check
        ? (
          <>
            <Formik
              enableReinitialize
              initialValues={
            { languageKeys: { ...languageKeyObj } || myKeys }
        }
              validationSchema={errorCodes ? null : languageWiseSchema(myKeys)}
              onSubmit={(formValues) => {
                if (errorCodes) {
                  const data = []
                  for (const key in formValues?.languageKeys) {
                    data.push({ key, value: { ...myKeyData?.[key], [selectedTab]: formValues?.languageKeys?.[key] } })
                  }
                  updateData({
                    type: 'create',
                    data,
                    tenantId: selectedPortal || ''
                  })
                } else {
                  const data = Object.assign({ }, formValues?.languageKeys)
                  for (const key in data) {
                    if (data[key] === '' || data?.[key] === languageKeyObj?.[key]) {
                      delete data[key]
                    }
                  }
                  data.language = selectedTab
                  updateData({
                    type: 'language',
                    data,
                    tenantId: selectedPortal || ''
                  })
                }
              }}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                handleBlur
              }) => (
                <Form>
                  <Tabs
                    activeKey={selectedKeyTab}
                    onSelect={(tab) => setSelectedKeyTab(tab)}
                    className='nav-light'
                  >
                    {languageTabs && Object.keys(languageTabs)?.map(key => {
                      return (
                        <Tab
                          eventKey={key}
                          key={key}
                          title={key}
                          disabled={isError && Object.keys(isError).length}
                        >
                          <div className='mt-5'>
                            <UserEndPages
                              myKeys={myKeys}
                              selectedTab={selectedKeyTab}
                              values={values}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              languageTabs={languageTabs[selectedKeyTab]}
                              setIsError={setIsError}
                              isError={isError}
                              errorCodes={errorCodes}
                            />
                          </div>
                        </Tab>

                      )
                    })}
                  </Tabs>

                  <div className='mt-4 d-flex justify-content-between'>
                    <Button
                      variant='outline-warning'
                      onClick={() =>
                        isTenant
                          ? navigate(errorCodes ? TenantRoutes.ErrLanguageManagement : TenantRoutes.LanguageManagement)
                          : navigate(errorCodes ? SuperAdminRoutes.ErrLanguageManagement : SuperAdminRoutes.LanguageManagement, { state: { myAdminId: selectedClient || '', myTenantId: selectedPortal || '' } })}
                      className='ml-2'
                    >
                      Cancel
                    </Button>
                    <Button
                      className='ml-2'
                      variant='outline-success'
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
          )
        : <Preloader />}
    </>
  )
}

export default EditLangWise
