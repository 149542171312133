import * as Yup from 'yup'

export const createTenantAdminSchema = (tenantData) => Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email Required!'),
  password: tenantData
    ? Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
        'Invalid Password'
      )
    : Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
        'Invalid Password'
      )
      .required('Password Required!'),
  firstName: Yup.string().min(3, 'First Name must be atleast 3 characters').max(200)
    .matches(/^[A-Za-z]+$/, 'Only Alphabets Allowed').required('First Name Required!'),
  lastName: Yup.string().min(3, 'Last Name must be atleast 3 characters').max(200)
    .matches(/^[A-Za-z]+$/, 'Only Alphabets Allowed').required('Last Name Required!'),
  agentName: Yup.string().min(3, 'Username must be atleast 3 characters').required('Username Required!'),
  phone: Yup.string()
    .min(10, 'Phone must be at least 10 digits')
    .max(20, 'Phone must be at most 20 digits')
    .matches(
      /^((\\+[1-9]{1,10}[ \\-]*)|(\\([0-9]{1,10}\\)[ \\-]*)|([0-9]{1,10})[ \\-]*)*?[0-9]{1,10}?[ \\-]*[0-9]{1,10}?$/,
      'Enter a valid phone number'
    )
})
