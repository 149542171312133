import { InputGroup, Row, Form as BForm, Button, Spinner } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { TenantRoutes } from '../../../../routes'
import useCreateCurrencies from '../useCreateCurrencies'
import CreatableSelect from 'react-select/creatable'
import { createCurrenciesStart, getCurrencyByIdStart } from '../../../../store/redux-slices/currencies'
import { createCurrencySchema } from '../schema'

const CreateCurrencies = () => {
  const {
    loading,
    dispatch,
    currency,
    navigate,
    codeOptions,
    selectedCode,
    setSelectedCode
  } = useCreateCurrencies()

  return (
    <>
      <Row>
        <h5>Create Currencies</h5>
      </Row>

      <Formik
        enableReinitialize={!!selectedCode?.id}
        initialValues={{
          name: currency?.name || '',
          code: selectedCode?.value || '',
          symbol: currency?.symbol || '',
          exchangeRate: currency?.exchangeRate || '',
          type: currency?.type || 0,
          loyaltyPoint: currency?.exchangeRate ? String(currency?.exchangeRate) : '0'
        }}
        validationSchema={createCurrencySchema()}
        onSubmit={(formValues) => {
          delete formValues?.code1
          dispatch(createCurrenciesStart({ ...formValues, type: parseInt(formValues?.type), navigate }))
        }}
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue
        }) => (
          <div className='m-4'>
            <Form>

              {/* Div for code */}
              <div className='form-group pt-2 mb-2'>
                <label
                  htmlFor='code'
                  className={touched.code && errors.code ? 'text-danger' : ''}
                >
                  <b>Code</b>
                  <span className='text-danger'> *</span>
                </label>

                <InputGroup
                  className={
              touched.code && errors.code ? 'border border-danger' : ''
            }
                >
                  <CreatableSelect
                    isClearable
                    name='code1'
                    className='w-100'
                    placeholder='Select or Create Code'
                    onCreateOption={(option) => {
                      // codeOptions?.length > 0
                      //   ? setCodeOptions([
                      //     ...codeOptions,
                      //     { label: option, value: option }
                      //   ])
                      //   : setCodeOptions([
                      //     { label: option, value: option }
                      //   ])
                      setSelectedCode({ label: option, value: option })
                      setFieldValue('code', option)
                    }}
                    options={codeOptions}
                    value={selectedCode}
                    classNamePrefix='select'
                    onChange={(option, e) => {
                      if (option === null) {
                        setSelectedCode('')
                        setFieldValue('code', '')
                      } else {
                        if (option?.id) dispatch(getCurrencyByIdStart({ currencyId: option?.id }))
                        setSelectedCode(option)
                        setFieldValue('code', option?.value)
                      }
                    }}
                  />
                </InputGroup>

                <ErrorMessage
                  component='div'
                  name='code'
                  className='text-danger'
                />
              </div>

              {/* Div for name */}
              <div className='form-group mb-2'>
                <label
                  htmlFor='name'
                  className={touched.name && errors.name ? 'text-danger' : ''}
                >
                  <b>Name</b>
                  <span className='text-danger'> *</span>
                </label>

                <InputGroup
                  className={
              touched.name && errors.name ? 'border border-danger' : ''
            }
                >
                  <BForm.Control
                    name='name'
                    required
                    type='text'
                    disabled={!!selectedCode?.id}
                    placeholder='Enter name of Currency'
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </InputGroup>

                <ErrorMessage
                  component='div'
                  name='name'
                  className='text-danger'
                />
              </div>

              {/* Div for Symbol */}
              <div className='form-group pt-2 mb-2'>
                <label
                  htmlFor='symbol'
                  className={
              touched.symbol && errors.symbol ? 'text-danger' : ''
            }
                >
                  <b>Symbol</b>
                  <span className='text-danger'> * </span>
                </label>

                <InputGroup
                  className={
              touched.symbol && errors.symbol
                ? 'border border-danger'
                : ''
            }
                >
                  <BForm.Control
                    name='symbol'
                    required
                    type='text'
                    disabled={!!selectedCode?.id}
                    placeholder='Enter currency symbol'
                    value={values.symbol}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </InputGroup>

                <ErrorMessage
                  component='div'
                  name='symbol'
                  className='text-danger'
                />
              </div>

              {/* Div for exchangeRate */}
              <div className='form-group pt-2 mb-3'>
                <label
                  htmlFor='exchangeRate'
                  className={
              touched.exchangeRate && errors.exchangeRate
                ? 'text-danger'
                : ''
            }
                >
                  <b>Exchange Rate</b>
                  <span className='text-danger'> *</span>
                  (with base currency Euro)
                </label>

                <InputGroup
                  className={
              touched.exchangeRate && errors.exchangeRate
                ? 'border border-danger'
                : ''
            }
                >
                  <BForm.Control
                    name='exchangeRate'
                    required
                    type='text'
                    placeholder='Enter currency Exchange Rate'
                    value={values.exchangeRate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </InputGroup>

                <ErrorMessage
                  component='div'
                  name='exchangeRate'
                  className='text-danger'
                />
              </div>

              {/* Div for loyaltyPoint */}
              <div className='form-group pt-2 mb-3'>
                <label
                  htmlFor='loyaltyPoint'
                  className={
              touched.loyaltyPoint && errors.loyaltyPoint
                ? 'text-danger'
                : ''
            }
                >
                  <b>Loyalty Point</b>
                  <span className='text-danger'> *</span>
                </label>

                <InputGroup
                  className={
              touched.loyaltyPoint && errors.loyaltyPoint
                ? 'border border-danger'
                : ''
            }
                >
                  <BForm.Control
                    name='loyaltyPoint'
                    required
                    type='text'
                    min={0}
                    placeholder='Enter Loyalty Point'
                    value={values.loyaltyPoint}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </InputGroup>

                <ErrorMessage
                  component='div'
                  name='loyaltyPoint'
                  className='text-danger'
                />
              </div>

              {/* Div for type */}
              <div className='form-group mb-2'>
                <label
                  htmlFor='type'
                  className={touched.type && errors.type ? 'text-danger' : ''}
                >
                  <b>Type</b>
                  <span className='text-danger'> *</span>
                </label>

                <InputGroup
                  className={
              touched.type && errors.type ? 'border border-danger' : ''
            }
                >
                  <BForm.Select
                    name='type'
                    disabled={!!selectedCode?.id}
                    value={values.type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value='' disabled>
                      ---Select Type---
                    </option>
                    <option value={0}>Crypto</option>
                    <option value={1}>Fiat</option>
                  </BForm.Select>
                </InputGroup>

                <ErrorMessage
                  component='div'
                  name='type'
                  className='text-danger'
                />
              </div>

              <div className='mt-4 d-flex justify-content-between align-items-center'>
                <Button
                  variant='outline-warning'
                  onClick={() => navigate(TenantRoutes.TenantConfigurations)}
                >
                  Cancel
                </Button>
                <Button
                  variant='outline-success'
                  onClick={handleSubmit}
                  className='ml-2'
                >
                  Submit
                  {loading && (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  )}
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </>
  )
}

export default CreateCurrencies
