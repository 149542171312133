import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { createCredentialsStart, getAllCredentialsStart, getTenantStart, updateCredentialsStart } from '../../../../store/redux-slices/tenants'
import { credKeyNames } from '../../../../utils/constants'

const useCreateCredentials = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { tenantDetails, allCredentialsKey, loading } = useSelector(
    (state) => state.tenants
  )
  const { tenantId } = useParams()
  const adminId = tenantDetails?.adminUserId
  const keys = tenantDetails && tenantDetails.credentials.map(({ key }) => key)
  const myKeys =
      allCredentialsKey &&
      keys &&
      allCredentialsKey.filter((cred) => !keys.includes(cred.name))

  useEffect(() => {
    if (!location.state) {
      if (JSON.parse(tenantId) !== tenantDetails?.tenantId) {
        dispatch(getTenantStart({ tenantId }))
      }

      dispatch(getAllCredentialsStart())
    }
  }, [])

  function createComponent () {
    return (
      <>
        <option value='' disabled>
          ---Select Key---
        </option>
        {myKeys === null || myKeys.length < 1
          ? (
            <option value='' disabled>
              No Credentials To Create
            </option>
            )
          : (
              allCredentialsKey &&
            myKeys.map(({ credentialKeyId, name }) => {
              return (
                <option key={credentialKeyId} value={name}>
                  {credKeyNames?.[name] || name}
                </option>
              )
            })
            )}
      </>
    )
  }

  const updateCreds = ({
    tenantId,
    key,
    value,
    description
  }) => dispatch(
    updateCredentialsStart({
      tenantId,
      key,
      value,
      description,
      adminId,
      navigate
    }))

  const createCreds = ({
    tenantId,
    key,
    value,
    description
  }) => dispatch(
    createCredentialsStart({
      tenantId,
      key,
      value,
      description,
      adminId,
      navigate
    }))

  return {
    navigate,
    location,
    loading,
    createComponent,
    tenantId,
    updateCreds,
    createCreds
  }
}

export default useCreateCredentials
