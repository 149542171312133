import { InputGroup, Form as BForm, Col } from '@themesberg/react-bootstrap'
import { ErrorMessage } from 'formik'
import React from 'react'

const Fields = ({
  touched,
  errors,
  fieldName,
  values,
  label,
  handleChange,
  handleBlur,
  minimum,
  type,
  wrapper = false,
  wrapperElement,
  placeholder = '',
  disabled = false
}) => {
  const DateTimeField = () => {
    return (
      <BForm.Control
        type='datetime-local'
        name={fieldName}
        min={minimum}
        value={values[fieldName]}
        onInput={handleChange}
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={disabled}
      />
    )
  }

  const BasicInputField = () => {
    return (
      <BForm.Control
        type={type}
        placeholder={placeholder}
        name={fieldName}
        value={values[fieldName]}
        onInput={handleChange}
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={disabled}
      />
    )
  }

  return (
    <Col style={{ minWidth: '220px' }} className='mb-3'>
      <label
        htmlFor={fieldName}
        className={touched[fieldName] && errors[fieldName]
          ? 'text-danger'
          : ''}
      >
        {label}<span className='text-danger'> *</span>
      </label>
      <InputGroup
        className={touched[fieldName] && errors[fieldName]
          ? 'border border-danger'
          : ''}
      >
        {wrapper
          ? wrapperElement()
          : (type === 'date-time'
              ? DateTimeField()
              : BasicInputField())}
      </InputGroup>
      <ErrorMessage
        component='div'
        name={fieldName}
        className='text-danger'
      />
    </Col>
  )
}

export default Fields
