import React from 'react'
import CreateCredentials from './CreateCredentials'
import Preloader from '../../../../components/Preloader'
import useEditCredentials from '../hooks/useEditCredentials'

const EditCredentials = () => {
  const { loading, credData, credKey } = useEditCredentials()

  return (
    <>
      {loading
        ? <Preloader />
        : (
            credData &&
            credData.key === credKey && (
              <CreateCredentials
                credKey={credData.key}
                value={credData.value}
                description={credData.description}
              />
            )
          )}
    </>
  )
}

export default EditCredentials
