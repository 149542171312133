import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllReviewStart } from '../../../store/redux-slices/fetchData'
import useDidMountEffect from '../../../utils/useDidMountEffect'

const useReviewManagement = () => {
  const dispatch = useDispatch()
  const isInitialRender = useDidMountEffect()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [active, setActive] = useState('')

  const { reviewList, loading } = useSelector((state) => state.fetch)
  const totalPages = Math.ceil(reviewList?.count / limit)

  useEffect(() => {
    dispatch(getAllReviewStart({ limit, pageNo: page, search, status: active, isTenant: true }))
  }, [limit, page, active])

  useEffect(() => {
    if (!isInitialRender) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(getAllReviewStart({ limit, pageNo: page, search, status: active, isTenant: true }))
      }, 1000)

      return () => clearTimeout(delayDebounceFn)
    }
  }, [search])

  return {
    limit,
    setLimit,
    page,
    setPage,
    reviewList,
    totalPages,
    loading,
    search,
    setSearch,
    active,
    setActive
  }
}

export default useReviewManagement
