import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { resetPasswordStart, sendMailStart } from '../../../../store/redux-slices/login'
import { getItem, setItem } from '../../../../utils/storageUtils'

const useSendMail = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { role } = useParams()

  const { sendMailLoading, resetPassLoading } = useSelector(state => state?.login)

  const [isSuperAdmin, setIsSuperAdmin] = useState(state?.isSuperAdmin)
  const [emailStatus, setEmailStatus] = useState({
    takeUserMail: true,
    emailSent: false,
    resetPassword: false
  })
  const [userEmail, setUserEmail] = useState('')
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false
  })

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const param = searchParams.get('newPasswordKey')

    if (param) {
      setItem('newPasswordKey', param)
      searchParams.delete('newPasswordKey')

      setSearchParams(searchParams)
    }
  }, [])

  useEffect(() => {
    if (role) {
      setEmailStatus(prev => ({ ...prev, takeUserMail: false, emailSent: false, resetPassword: true }))
    }
    if (role === 'super-admin') {
      setIsSuperAdmin(prev => true)
    }
    if (role === 'tenant') {
      setIsSuperAdmin(prev => false)
    }
  }, [role])

  const handleSendMail = ({ email }) => {
    setUserEmail(prev => email)
    dispatch(sendMailStart({ email, isSuperAdmin: state?.isSuperAdmin, setEmailStatus }))
  }

  const handleResetPassword = ({ password }) => {
    const encryptedPass = Buffer.from(password).toString('base64')
    dispatch(resetPasswordStart({ isSuperAdmin, password: encryptedPass, token: getItem('newPasswordKey'), navigate }))
  }

  return {
    emailStatus,
    resetPassLoading,
    sendMailLoading,
    userEmail,
    showPassword,
    setShowPassword,
    handleResetPassword,
    setEmailStatus,
    handleSendMail
  }
}

export default useSendMail
