import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { checkIsTenant } from '../../../utils/constants'
import useDidMountEffect from '../../../utils/useDidMountEffect'
import { getLanguagesStart } from '../../../store/redux-slices/languages'
import { getCountriesStart, resetRestrictedItemsStart, updateCountryStatusStart, updateKycMethodStart } from '../../../store/redux-slices/fetchData'

const useCountriesListing = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isInitialRender = useDidMountEffect()

  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [name, setName] = useState('')
  const [status, setStatus] = useState(false)
  const [myName, setMyName] = useState('')
  const [id, setId] = useState('')
  const [statusShow, setStatusShow] = useState(false)
  const [active, setActive] = useState('')
  const [countryData, setCountryData] = useState('')
  const [showKycUpdateModal, setShowKycUpdateModal] = useState(false)
  const [KYCMethod, setKYCMethod] = useState('')
  const { languages } = useSelector(state => state?.languages)
  const { countries, loading } = useSelector((state) => state.fetch)
  const totalPages = Math.ceil(countries?.count / limit)

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(getCountriesStart({ limit, name, pageNo: page, isActive: active }))
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [name])

  useEffect(() => {
    !checkIsTenant() && dispatch(getLanguagesStart({ limit: '', pageNo: '', name: '' }))
  }, [])

  const handleYes = () => {
    dispatch(
      updateCountryStatusStart({
        data: checkIsTenant()
          ? {
              countryCode: id,
              status
            }
          : {
              code: 'COUNTRY',
              countryId: id,
              status
            },
        limit,
        pageNo: page,
        isActive: active,
        name,
        kycMethod: KYCMethod
      })
    )
    setTimeout(() => {
      setStatusShow(false)
    }, 500)
  }

  const handleStatusShow = (id, status, myName) => {
    setId(id)
    setStatus(!status)
    setStatusShow(true)
    setMyName(myName)
  }

  useEffect(() => {
    dispatch(getCountriesStart({ limit, name, pageNo: page, isActive: active, kycMethod: KYCMethod }))
    dispatch(resetRestrictedItemsStart())
  }, [limit, page, active, KYCMethod])

  const handleKycUpdateMethod = ({ countryData }) => {
    dispatch(updateKycMethodStart({
      data: checkIsTenant()
        ? {
            kycMethod: countryData?.kycMethod && parseInt(countryData?.kycMethod),
            countryId: countryData?.countryId,
            countryCode: countryData?.code,
            languageId: countryData?.languageId && parseInt(countryData?.languageId)
          }
        : {
            kycMethod: countryData?.kycMethod && parseInt(countryData?.kycMethod),
            countryId: countryData?.countryId,
            languageId: countryData?.languageId && parseInt(countryData?.languageId)
          },
      setShowKycUpdateModal,
      kycMethod: KYCMethod,
      name,
      limit,
      pageNo: page
    }))
  }

  return {
    limit,
    countries,
    totalPages,
    loading,
    page,
    name,
    showKycUpdateModal,
    countryData,
    handleKycUpdateMethod,
    setCountryData,
    setShowKycUpdateModal,
    setLimit,
    setPage,
    setName,
    navigate,
    status,
    statusShow,
    myName,
    id,
    handleStatusShow,
    handleYes,
    active,
    setActive,
    setStatusShow,
    KYCMethod,
    setKYCMethod,
    languages
  }
}

export default useCountriesListing
