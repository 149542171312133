import * as Yup from 'yup'

export const superAdminLoginSchema = Yup.object().shape({
  user: Yup.string().required('Email or Username Required'),
  password: Yup.string().required('Password Required')
})

export const superAdminAuthenticate = Yup.object().shape({
  token: Yup.number()
    .min(6, 'Minimum 6 digit Required')
    .required('Authentication Code is Required')
})
