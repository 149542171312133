import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCheckPermission from '../../../../utils/checkPermission'
import { getAllemailTemplatesStart, getImageGalleryStart, makePrimaryEmailTemplatesStart, resetEmailTemplate } from '../../../../store/redux-slices/emailTemplate'
import { getItem } from '../../../../utils/storageUtils'

const useEmailTemplate = () => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [type, setType] = useState(false)
  const [lang, setLang] = useState('EN')
  const tenantId = getItem('tenant-id')
  const [templateData, setTemplateData] = useState('')
  const { tenantDetails } = useSelector(state => state.login)
  const { isHidden } = useCheckPermission()
  const setModalData = (template, templateCode) => {
    setType(template)
    setTemplateData(templateCode)
    setShow(true)
  }

  const { emailTemplate, emailTemplates, templateCount, loading, emailTemplateOrder } = useSelector((state) => state.emailTemplate)

  useEffect(() => {
    dispatch(getAllemailTemplatesStart({ isTenant: true }))
    !isHidden({ module: { key: 'ImageGallery', value: 'R' } }) && dispatch(getImageGalleryStart({ isTenant: true, tenantId }))
  }, [])

  const resetEmail = () => dispatch(resetEmailTemplate())

  return {
    emailTemplate,
    emailTemplates,
    loading,
    show,
    setShow,
    setModalData,
    templateData,
    templateCount,
    makePrimaryEmailTemplatesStart,
    dispatch,
    isHidden,
    emailTemplateOrder,
    languages: tenantDetails?.tenantConfig?.allowedLanguages,
    lang,
    setLang,
    resetEmail,
    type
  }
}

export default useEmailTemplate
