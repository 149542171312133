import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getAllUsersStart, clearAllUsers, getCountriesStart, getTenantCountriesStart } from '../../../store/redux-slices/fetchData'
import { formatDateYMD } from '../../../utils/dateFormatter'
import useDidMountEffect from '../../../utils/useDidMountEffect'

const useAllUserListing = ({ isTenant = false, restrict = false }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isInitialRender = useDidMountEffect()
  const { adminId, tenantId } = useParams()

  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [affiliateSearch, setAffiliateSearch] = useState('')
  const [showIDModal, setShowIDModal] = useState(false)
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const [kycOptions, setKycOptions] = useState('')
  const [isInternal, setIsInternal] = useState(false)
  const [userId, setUserId] = useState('')
  const [orderBy, setOrderBy] = useState('userId')
  const [sortBy, setSortBy] = useState('desc')
  const [over, setOver] = useState(false)
  const { countries, tenantCountries } = useSelector((state) => state.fetch)
  const [selectedCountry, setSelectedCountry] = useState('')
  const [regPlayersType, setRegPlayersType] = useState('all')
  const [transactionType, setTransactionType] = useState('none')
  const [state, setState] = useState([
    {
      startDate: new Date('01 July 1923'),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const [depositRestrictPlayers, setRestrictDeposit] = useState([])
  const [withdrawRestrictPlayers, setRestrictWithdrawal] = useState([])
  const startDate = new Date()

  startDate.setDate(startDate.getDate() - 20)

  const [regState, setRegState] = useState([
    {
      startDate: new Date('01 Jan 2023'),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const [regPlayerDate, setRegPlayerDate] = useState([
    {
      startDate,
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const { userData, loading } = useSelector((state) => state.fetch)
  const totalPages = Math.ceil(userData?.count / limit)

  const selected = (h) =>
    orderBy === h.value &&
  (h.value === 'email' || h.value === 'userId' || h.value === 'username')

  const handleIDModal = (data, type, select) => {
    if (['deposit', 'both'].includes(type)) {
      if (select) setRestrictDeposit([...depositRestrictPlayers, ...data])
      else {
        setRestrictDeposit(depositRestrictPlayers?.filter(id => !data?.includes(String(id))))
      }
    }
    if (['withdraw', 'both'].includes(type)) {
      if (select) setRestrictWithdrawal([...withdrawRestrictPlayers, ...data])
      else {
        setRestrictWithdrawal(withdrawRestrictPlayers?.filter(id => !data?.includes(String(id))))
      }
    }
  }

  const params = {
    limit,
    pageNo: page,
    tenantId: selectedPortal || tenantId || '',
    adminId: selectedClient || adminId || '',
    search: search.replace('+', '%2B'),
    kycStatus: kycOptions,
    affiliateSearch,
    dobStart: formatDateYMD(state.map(a => a.startDate)),
    dobEnd: formatDateYMD(state.map(a => a.endDate)),
    userId,
    orderBy,
    sortBy,
    registrationDateStart: formatDateYMD(regState.map(a => a.startDate)),
    registrationDateEnd: formatDateYMD(regState.map(a => a.endDate)),
    isInternal,
    country: selectedCountry,
    ftdTo: regPlayersType === 'newDepositors' && formatDateYMD(regPlayerDate.map(a => a.endDate)),
    ftdFrom: regPlayersType === 'newDepositors' && formatDateYMD(regPlayerDate.map(a => a.startDate)),
    usersType: regPlayersType,
    isTenant
  }

  const fetchData = () => {
    dispatch(
      getAllUsersStart({
        ...params
      })
    )
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(
            getAllUsersStart({
              ...params
            })
          )
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search, affiliateSearch, userId])

  useEffect(() => {
    if (!isInitialRender) {
      if (transactionType === 'all') {
        fetchData()
      } else {
        dispatch(
          getAllUsersStart({
            ...params,
            restrictedPlayers: transactionType === 'withdraw' ? withdrawRestrictPlayers : transactionType !== 'none' && depositRestrictPlayers
          })
        )
      }
    }
  }, [transactionType])

  useEffect(() => {
    !isInitialRender && fetchData()

    return () => dispatch(clearAllUsers())
  }, [page])

  useEffect(() => {
    setPage(1)
    fetchData()

    return () => dispatch(clearAllUsers())
  }, [limit, selectedPortal, kycOptions, selectedClient, state, orderBy, sortBy, regState, isInternal, selectedCountry, regPlayerDate, regPlayersType])

  useEffect(() => {
    !restrict && (isTenant ? dispatch(getTenantCountriesStart()) : dispatch(getCountriesStart({ limit: '', name: '', pageNo: '', isActive: '', kycMethod: '' })))
  }, [])

  return {
    limit,
    setLimit,
    page,
    setPage,
    search,
    setSearch,
    userData,
    totalPages,
    navigate,
    affiliateSearch,
    setAffiliateSearch,
    selectedClient,
    selectedPortal,
    setSelectedPortal,
    setSelectedClient,
    loading,
    kycOptions,
    setKycOptions,
    state,
    setState,
    userId,
    setUserId,
    orderBy,
    setOrderBy,
    sortBy,
    setSortBy,
    over,
    setOver,
    selected,
    regState,
    setRegState,
    isInternal,
    setIsInternal,
    countries: isTenant ? tenantCountries?.rows : countries,
    setSelectedCountry,
    selectedCountry,
    regPlayerDate,
    setRegPlayerDate,
    regPlayersType,
    setRegPlayersType,
    transactionType,
    setTransactionType,
    depositRestrictPlayers,
    setRestrictDeposit,
    withdrawRestrictPlayers,
    setRestrictWithdrawal,
    showIDModal,
    setShowIDModal,
    handleIDModal
  }
}

export default useAllUserListing
