import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { DateRange } from 'react-date-range'
import { formatDateYMD } from '../../utils/dateFormatter'
import useOutsideClick from '../../utils/useOutsideClick'
import './DateRangePicker.scss'

const DateRangePicker = ({ state, setState, size, width = 'auto', disabled = false, bonus = false, transaction = false, player = false, max = false, reg = false, className }) => {
  const { ref, isVisible, setIsVisible } = useOutsideClick(false)

  return (
    <div className='' style={{ marginTop: (bonus || player) && '0px' }}>
      <input
        readOnly
        disabled={disabled}
        className={
          size ? `form-control custom-input-field form-control-${size} ${player ? 'player' : ''} ${className}` : `form-control custom-input-field ${className} ${player ? 'player' : ''}`
        }
        style={{ width }}
        onClick={() => setIsVisible(!isVisible)}
        value={`${formatDateYMD(state[0]?.startDate)} to ${formatDateYMD(
          state[0]?.endDate
        )}`}
      />

      {isVisible && (
        <div className='parent-center-screen'>
          <div ref={ref} className='center-screen' style={{ zIndex: '9999' }}>
            {player
              ? reg
                ? <DateRange
                    editableDateInputs
                    onChange={(item) => {
                      setState([item.selection])
                    }}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                    maxDate={new Date()}
                    minDate={new Date('01-01-2022')}
                  />
                : (
                    max
                      ? <DateRange
                          editableDateInputs
                          onChange={(item) => {
                            setState([item.selection])
                          }}
                          moveRangeOnFirstSelection={false}
                          ranges={state}
                          maxDate={new Date()}
                        />
                      : <DateRange
                          editableDateInputs
                          onChange={(item) => {
                            setState([item.selection])
                          }}
                          moveRangeOnFirstSelection={false}
                          ranges={state}
                        />
                  )
              : (
                <DateRange
                  editableDateInputs
                  onChange={(item) => {
                    setState([item.selection])
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                  minDate={bonus ? (transaction ? new Date(1999) : new Date()) : new Date(1999)}
                />
                )}
          </div>
        </div>
      )}
    </div>
  )
}

export default DateRangePicker

export const DateRangePickerWithoutInput = ({ state, setState }) => {
  const { ref, isVisible, setIsVisible } = useOutsideClick(false)
  return (
    <div className='custom-container date d-flex align-items-center mt-0'>
      <span
        className='d-flex '
        style={{ cursor: 'pointer', fontSize: '14px', minWidth: '200px' }}
        onClick={() => setIsVisible(!isVisible)}
      >
        {formatDateYMD(state?.[0].startDate)} to {formatDateYMD(state?.[0].endDate)} <FontAwesomeIcon icon={faCalendarAlt} className='' style={{ cursor: 'pointer', marginLeft: '5px' }} />{' '}
      </span>

      {isVisible && (
        <div className='parent-center-screen'>
          <div ref={ref} className='center-screen' style={{ zIndex: '9999' }}>
            <DateRange
              editableDateInputs
              onChange={(item) => {
                setState([item.selection])
              }}
              moveRangeOnFirstSelection={false}
              ranges={state}
            />
          </div>
        </div>
      )}
    </div>
  )
}
