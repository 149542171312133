import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { createTenantStart, editTenantStart } from '../../../../store/redux-slices/tenants'

const useCredentials = ({ createTenantData, setCreateTenantData }) => {
  const { tenantId } = useParams()
  const [isValueError, setIsValueError] = useState(false)
  const [isDescriptionError, setIsDescriptionError] = useState(false)
  const [validation, setValidation] = useState({})
  const [allValidation, setAllValidation] = useState(false)
  const { allCredentialsKey } = useSelector((state) => state.tenants)
  const fieldNames = allCredentialsKey?.map((feild) => feild.name)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  let flag = false

  Object.keys(validation).forEach((key) => {
    if (validation[key] === true) {
      flag = true
    }
    return flag
  })

  useEffect(() => {
    if (flag === false) {
      setAllValidation(false)
    }
  }, [flag, setAllValidation])

  useEffect(() => {
    if ((!createTenantData?.value || !createTenantData?.description) && allCredentialsKey && Object.keys(allCredentialsKey)?.length > 0) {
      const keys = allCredentialsKey.map((key) => key.name)
      const data = Object.fromEntries(keys.map(k => [k, '']))
      setCreateTenantData({
        ...createTenantData,
        value: { ...data },
        description: { ...data }
      })
    }
  }, [allCredentialsKey, createTenantData])

  const editTenant = ({
    createTenantData,
    val,
    tenantId
  }) => dispatch(
    editTenantStart({
      createTenantData,
      navigate,
      val,
      tenantId
    })
  )

  const createTenant = (createTenantData, setCreateTenantData, setSelectedTab) =>
    dispatch(createTenantStart({ createTenantData, navigate, setCreateTenantData, setSelectedTab }))

  return {
    isValueError,
    setIsValueError,
    isDescriptionError,
    setIsDescriptionError,
    fieldNames,
    validation,
    setAllValidation,
    setValidation,
    allValidation,
    editTenant,
    tenantId,
    navigate,
    createTenant
  }
}

export default useCredentials
