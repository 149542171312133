export const tableHeadersRGModule = (type, selectedTab, selfExcluded) => {
  return (selectedTab === 'singleTransaction' || selfExcluded
    ? [
        { label: 'Id', value: 'userId' },
        { label: 'UserName', value: 'username' },
        { label: 'First Name', value: 'firstName' },
        { label: 'Last Name', value: 'lastName' },
        { label: 'IP', value: 'ipAddress' },
        { label: 'Country', value: 'country' },
        { label: 'KYC Status', value: 'kycStatus' },
        { label: 'Status', value: 'status' },
        { label: 'action', value: 'action' }
      ]
    : [
        { label: 'Id', value: 'userId' },
        { label: 'UserName', value: 'username' },
        { label: 'First Name', value: 'firstName' },
        { label: 'Last Name', value: 'lastName' },
        { label: 'IP', value: 'ipAddress' },
        { label: 'Country', value: 'country' },
        { label: type === 'aml' ? 'Total Amount' : selectedTab === 'bet' ? 'Total Wager Amount' : 'Total Win Amount', value: 'totalSummedAmount' },
        { label: 'Exceeded By', value: 'exceededAmount' },
        { label: 'KYC Status', value: 'kycStatus' },
        { label: 'Status', value: 'status' },
        { label: 'action', value: 'action' }
      ]

  )
}
