import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createSubCategoryStart,
  updateSubCategoryStart
} from '../../../../store/redux-slices/tenantCasino'

const useCreateSubCategory = (editIconName, editIconColor) => {
  const dispatch = useDispatch()
  const [createName, setCreateName] = useState({ EN: '' })
  const [language, setLanguage] = useState('EN')
  const { tenantDetails } = useSelector(state => state.login)

  const { loading, casinoCategories } = useSelector((state) => state.tenantCasino)

  const updateCasinoMenu = (data) =>
    dispatch(
      updateSubCategoryStart(data)
    )

  const createCasinoMenu = (data) =>
    dispatch(
      createSubCategoryStart(data)
    )

  return {
    loading,
    updateCasinoMenu,
    createCasinoMenu,
    casinoCategories,
    languages: tenantDetails?.tenantConfig?.allowedLanguages,
    language,
    setLanguage,
    createName,
    setCreateName
  }
}

export default useCreateSubCategory
