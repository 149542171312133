import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faEye, faUser } from '@fortawesome/pro-solid-svg-icons'
import { Button, Table } from '@themesberg/react-bootstrap'
import { faFilePdf } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { toast } from '../Toast'
import Trigger from '../OverlayTrigger'
import UserDataModal from './UserDataModal'
import PaginationComponent from '../Pagination'
import { isTenant } from '../../utils/constants'
import { tableHeadersRGModule } from './constants'
import { countryFilter } from '../../utils/countryFilter'
import { getUserStart } from '../../store/redux-slices/fetchData'
import { internationalNumberFormatter } from '../../utils/helpers'
import { DuplicateUserModal, PortalDetailsModal } from '../ConfirmationModal'
import { getDuplicateUsersStart } from '../../store/redux-slices/tenantUsers'
import { getExcludedPortalDetailsStart } from '../../store/redux-slices/rgModule'

export const PlayerListing = ({
  playersDetails,
  loading,
  totalPages,
  setPage,
  limit,
  setLimit,
  page,
  selectedPortal,
  type,
  selfExcluded,
  setSelectedClient,
  setSelectedPortal,
  selectedTab,
  rgThresholdValue,
  amlThresholdValue
}) => {
  const { userData } = useSelector((state) => state.fetch)
  const tableHeader = tableHeadersRGModule(type, selectedTab, selfExcluded)

  // Duplicates
  const dispatch = useDispatch()
  const [data, setData] = useState('')
  const [userId, setUserId] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [duplicateUsersLimit, setDuplicateUsersLimit] = useState(15)
  const [duplicateUsersPageNo, setDuplicateUsersPageNo] = useState(1)
  const [showDuplicateModal, setShowDuplicateModal] = useState(false)

  // Portal Details
  const [showUserPortalModal, setShowUserPortalModal] = useState(false)
  const [userPortalLimit, setUserPortalLimit] = useState(15)
  const [userPortalPageNo, setUserPortalPageNo] = useState(1)

  const fetchData = () => {
    dispatch(
      getDuplicateUsersStart({
        limit: duplicateUsersLimit,
        pageNo: duplicateUsersPageNo,
        userId,
        isTenant
      })
    )
  }

  useEffect(() => {
    return () => {
      setSelectedClient('')
      setSelectedPortal('')
    }
  }, [selfExcluded])

  useEffect(() => {
    userId && fetchData()
  }, [userId, duplicateUsersLimit, duplicateUsersPageNo])

  // Download PDF
  const downloadData = (userId) => {
    try {
      dispatch(getUserStart({ userId, isTenant }))
      setShowModal(true)
    } catch (error) {
      console.log(error)
      toast('Something went wrong', 'error')
    }
  }

  // PDF download options
  const options = {
    margin: 0.8,
    format: 'a4',
    image: { type: 'jpeg', quality: 0.98 },
    pagebreak: { before: ['.wallet'] },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'mm', format: 'letter', orientation: 'portrait' }
  }

  useEffect(() => {
    // Check for the exact data
    if (parseInt(userData?.userId) === parseInt(userId) && userData?.userWallet && showModal) {
      const htmlElement = document.getElementById('container')
      // Show Preview and then download
      setTimeout(() => {
        window.html2pdf().set(options).from(htmlElement).save(`${userData?.firstName + userData?.lastName + '_' + userData?.userId + '_' + new Date().toJSON()}.pdf`)
        setShowModal(false)
        toast('PDF downloaded successfully', 'success')
      }, 2000)
    }
  }, [userData, showModal])

  return (
    <>
      <Table
        bordered
        striped
        responsive
        hover
        size='sm'
        className='text-center mt-4'
      >
        <thead className='thead-dark'>
          <tr>
            {tableHeader.map((h, idx) => <th key={idx}>{h.label} </th>)}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading &&
            (isTenant ? true : selectedPortal) &&
            playersDetails?.rows?.map(
              ({
                userId,
                countryCode,
                username,
                kycMethod,
                firstName,
                lastName,
                signInIp,
                sumsubKycStatus,
                kycStatus,
                duplicateCount,
                totalPrimaryCurrencyAmount,
                phone,
                email,
                dateOfBirth,
                parentId,
                totalAmount,
                status
              }) => {
                const { countryImage, countryName } =
                  countryFilter(countryCode)
                return (
                  <tr key={userId}>
                    <td>{userId}</td>
                    <td>
                      <Trigger message={username}>
                        <span
                          style={{
                            width: '100px',
                            cursor: 'pointer'
                          }}
                          onClick={() =>
                            window.open(
                              `/${isTenant ? 'tenant' : 'super-admin'
                              }/users/details/${userId}`,
                              '_blank'
                            )}
                          className='text-link d-inline-block text-truncate'
                        >
                          {username}
                        </span>
                      </Trigger>
                    </td>

                    <td>{firstName}</td>
                    <td>{lastName}</td>

                    <td>{signInIp || '-'}</td>

                    <td>
                      <Trigger message={countryName}>
                        <img
                          width='25px'
                          src={countryImage}
                          alt={countryImage}
                        />
                      </Trigger>
                    </td>

                    {type === 'rg' && !selfExcluded && (
                      <>
                        <td>
                          {`${internationalNumberFormatter(totalPrimaryCurrencyAmount?.toFixed(2)) || 0} EUR` || '-'}
                        </td>
                        <td>
                          {`${internationalNumberFormatter((parseFloat(totalPrimaryCurrencyAmount) - parseFloat(rgThresholdValue))?.toFixed(2))} EUR` || '-'}
                        </td>
                      </>
                    )}

                    {type === 'aml' && totalAmount && (
                      <>
                        <td>
                          {`${internationalNumberFormatter(totalAmount?.toFixed(2))} EUR` || '-'}
                        </td>
                        <td>
                          {`${internationalNumberFormatter((parseFloat(totalAmount) - parseFloat(amlThresholdValue))?.toFixed(2))} EUR` || '-'}
                        </td>
                      </>
                    )}

                    <td>
                      {kycMethod === 1
                        ? `${sumsubKycStatus?.toUpperCase()} (Sumsub)` || '-'
                        : `${kycStatus} (System KYC)` || '-'}
                    </td>

                    <td>
                      {status
                        ? (
                          <span className='text-success'>Active</span>
                          )
                        : (
                          <span className='text-danger'>{status === null ? 'Temp. In-Active' : 'In Active'}</span>
                          )}
                    </td>

                    <td>
                      <Trigger message='View Duplicates'>
                        <Button
                          className='m-1'
                          size='sm'
                          variant='info'
                          onClick={() => {
                            setShowDuplicateModal(true)
                            setUserId(userId)
                            setData({
                              userId,
                              countryCode,
                              username,
                              firstName,
                              lastName,
                              signInIp
                            })
                          }}
                        >
                          <FontAwesomeIcon icon={faUser} />
                        </Button>
                      </Trigger>

                      <Trigger message='Download PDF'>
                        <Button
                          className='m-1'
                          size='sm'
                          variant='danger'
                          onClick={() => {
                            setUserId(userId)
                            downloadData(userId)
                          }}
                        >
                          <FontAwesomeIcon icon={faFilePdf} />
                        </Button>
                      </Trigger>

                      {selfExcluded && (
                        <Trigger message='View Portals'>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='info'
                            onClick={() => {
                              dispatch(getExcludedPortalDetailsStart({
                                email,
                                parentId
                              }))
                              setShowUserPortalModal(true)
                              setData({
                                userId,
                                countryCode,
                                username,
                                firstName,
                                lastName,
                                signInIp,
                                phone,
                                email,
                                dateOfBirth
                              })
                            }}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Button>
                        </Trigger>
                      )}
                    </td>
                  </tr>
                )
              }
            )}

          {!isTenant && !selectedPortal && !loading && (
            <tr>
              <td colSpan={13} className='text-danger text-center'>
                Please Select Tenant
              </td>
            </tr>
          )}

          {playersDetails?.count === 0 &&
            !loading &&
            (isTenant ? true : selectedPortal) && (
              <tr>
                <td colSpan={13} className='text-danger text-center'>
                  No data found
                </td>
              </tr>
          )}
        </tbody>
      </Table>

      {userData && showModal && (
        <UserDataModal
          showModal={showModal}
          userData={userData}
        />
      )}

      {playersDetails?.count > 0 && !loading && (isTenant ? true : selectedPortal) && (
        <PaginationComponent
          page={playersDetails?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalCount={playersDetails?.count}
        />
      )}

      {/* Duplicate Users */}
      {showDuplicateModal && (
        <DuplicateUserModal
          data={data}
          show={showDuplicateModal}
          limit={duplicateUsersLimit}
          pageNo={duplicateUsersPageNo}
          setShow={setShowDuplicateModal}
          setLimit={setDuplicateUsersLimit}
          setPageNo={setDuplicateUsersPageNo}
        />
      )}

      {/* Portal Details */}
      {showUserPortalModal && (
        <PortalDetailsModal
          data={data}
          show={showUserPortalModal}
          limit={userPortalLimit}
          pageNo={userPortalPageNo}
          setShow={setShowUserPortalModal}
          setLimit={setUserPortalLimit}
          setPageNo={setUserPortalPageNo}
        />
      )}

    </>
  )
}
