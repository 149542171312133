import { ErrorMessage } from 'formik'
import React from 'react'
import Select from 'react-select'

/**
 * CustomReactSelect - A reusable custom select component that supports both single and multi-select options
 * @param {string} labelText - The label displayed above the select dropdown
 * @param {string} formFieldName - The name of the field in Formik, used to identify the input field
 * @param {Array} selectOptions - Array of objects containing 'label' and 'value' keys for the select options
 * @param {string|Array} selectedValues - The selected value(s), can be a string (single select) or an array (multi-select)
 * @param {Function} setSelectedValues - Function to update the selected value(s) in the parent component
 * @param {string} selectClassName - CSS class applied to the select dropdown
 * @param {string} selectClassPrefix - Class name prefix for React Select internal components (default: 'select')
 * @param {boolean} allowMultiple - Determines if the select allows multiple selections (multi-select)
 * @param {Function} setFieldValue - Formik's setFieldValue function to update the form value
 * @param {boolean} showError - Controls if the error message should be shown
 */
const CustomReactSelect = ({
  labelText, // Label for the select component
  formFieldName, // Formik field name (used for form state management)
  selectOptions, // Array of options for the select dropdown (label-value pairs)
  selectedValues, // Selected value(s) from the options (single or multi-select)
  setSelectedValues, // Function to update the selected value(s)
  selectClassName = '', // CSS class applied to the Select component
  selectClassPrefix = 'select', // Class prefix for styling React Select (default is 'select')
  allowMultiple = false, // Determines if multi-selection is allowed
  setFieldValue, // Formik's setFieldValue function for form integration
  showError = false, // Boolean flag to show error message
  isDisabled = false
}) => {
  /**
   * getSelectedOptions - Helper function to determine selected options for single or multi-select
   * @returns {object|Array|null} - Returns the selected option(s) in a format React Select can understand
   */
  const getSelectedOptions = () => {
    if (allowMultiple) {
      // If multi-select is enabled, filter options that match selectedValues (an array)
      return Array.isArray(selectedValues)
        ? selectOptions.filter(option => selectedValues.includes(option.value)) // Returns matching options
        : []
    } else {
      // For single select, find the matching option in selectOptions
      return selectOptions.find(option => option.value === selectedValues) || null
    }
  }

  /**
   * handleSelectChange - Handles the selection change event
   * @param {object|Array} selectedOptions - The selected option(s) from the React Select component
   */
  const handleSelectChange = (selectedOptions) => {
    if (allowMultiple) {
      // When multi-select is enabled, map the selectedOptions to an array of values
      const newSelectedValues = selectedOptions
        ? selectedOptions.map(option => option.value)
        : [] // If no options are selected, set an empty array

      // Update the selected values and set the form value using Formik's setFieldValue
      setSelectedValues(newSelectedValues)
      setFieldValue && setFieldValue(formFieldName, newSelectedValues)
    } else {
      // For single select, set the selected value (or null if none is selected)
      const newSelectedValue = selectedOptions ? selectedOptions.value : null

      // Update the selected value and form value in Formik
      setSelectedValues(newSelectedValue)
      setFieldValue && setFieldValue(formFieldName, newSelectedValue)
    }
  }

  return (
    <>
      {labelText && (
        // Display the label for the select dropdown if labelText is provided
        <label htmlFor={formFieldName}>
          {labelText}
          {showError && <span className='text-danger'> *</span>} {/* Show an asterisk if showError is true */}
        </label>
      )}

      {/* Render the React Select dropdown */}
      <Select
        isMulti={allowMultiple} // Enables multi-select if allowMultiple is true
        isClearable={false} // Prevents the user from clearing all selected options
        value={getSelectedOptions()} // Set the current selected value(s) from getSelectedOptions
        options={selectOptions} // Available options for selection
        className={selectClassName} // CSS class for styling
        classNamePrefix={selectClassPrefix} // Prefix for internal styling classes
        onChange={handleSelectChange} // Calls handleSelectChange when selection changes
        isDisabled={isDisabled}
      />

      {showError && (
        // If showError is true, display a Formik error message
        <ErrorMessage
          component='div'
          name={formFieldName} // The form field name for the error message
          className='text-danger' // CSS class to style the error message in red
        />
      )}
    </>
  )
}

export default CustomReactSelect
