import React from 'react'
import { Tabs, Tab, Row, Col, Card } from '@themesberg/react-bootstrap'

import Tenants from './components/Tenant'
import Credentials from './components/Credentials'
import useCreateTenant from './hooks/useCreateTenant'
import Preloader from '../../../components/Preloader'
// import Providers from './components/Providers'

const CreateTenant = () => {
  const { selectedTab, setSelectedTab, createTenantData, setCreateTenantData, adminUserDetails, loading } = useCreateTenant()

  return (
    <>
      <Row>
        <Col>
          <h3>Tenants</h3>
        </Col>
        <Col className='d-flex justify-content-end'>
          <h3>Owner : {adminUserDetails?.firstName}{' '}{adminUserDetails?.lastName}</h3>
        </Col>
      </Row>

      <Card body>
        {loading
          ? <Preloader />
          : (
            <Tabs activeKey={selectedTab} className='nav-light'>
              <Tab disabled eventKey='tenant' title='Tenant'>
                <div className='mt-5'>
                  <Tenants
                    createTenantData={createTenantData}
                    setCreateTenantData={setCreateTenantData}
                    setSelectedTab={setSelectedTab}
                  />
                </div>
              </Tab>

              {/* <Tab disabled eventKey='providers' title='Providers'>
                <div className='mt-5'>
                  <Providers
                    createTenantData={createTenantData}
                    setCreateTenantData={setCreateTenantData}
                    setSelectedTab={setSelectedTab}
                  />
                </div>
              </Tab> */}

              <Tab disabled eventKey='credentials' title='Credentials'>
                <div className='mt-5'>
                  <Credentials
                    createTenantData={createTenantData}
                    setCreateTenantData={setCreateTenantData}
                    setSelectedTab={setSelectedTab}
                  />
                </div>
              </Tab>
            </Tabs>)}
      </Card>
    </>
  )
}

export default CreateTenant
