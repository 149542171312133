import React from 'react'
import FlaggedPlayerListing from '../../../components/FlaggedPlayerListing'
import useAmlListing from './useAmlListing'

const AmlPlayerListing = () => {
  const {
    page,
    limit,
    setPage,
    setLimit,
    amlPlayersDetails,
    loading,
    totalPages,
    setSelectedClient,
    setSelectedPortal,
    selectedClient,
    selectedPortal,
    selectedTab,
    setSelectedTab,
    search,
    setSearch,
    userId,
    setUserId,
    heading,
    tabs,
    amlThresholdValue
  } = useAmlListing()

  return (
    <FlaggedPlayerListing
      heading={heading}
      setSelectedClient={setSelectedClient}
      setSelectedPortal={setSelectedPortal}
      selectedClient={selectedClient}
      selectedPortal={selectedPortal}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={tabs}
      search={search}
      setSearch={setSearch}
      userId={userId}
      setUserId={setUserId}
      playersDetails={amlPlayersDetails}
      loading={loading}
      totalPages={totalPages}
      setPage={setPage}
      limit={limit}
      setLimit={setLimit}
      page={page}
      type='aml'
      amlThresholdValue={amlThresholdValue}
    />
  )
}

export default AmlPlayerListing
