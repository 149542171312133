import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  createCredsStart,
  getAllCredKeysStart,
  updateCredsStart
  , getAllCredStart
} from '../../../../store/redux-slices/tenantcredentials'

const useCreateCredentials = (editKey) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { credKeys, loading, data } = useSelector(
    (state) => state.tenantcredentials
  )
  const keys = data && data.map(({ key }) => key)
  const myKeys =
    credKeys && keys && credKeys.filter((cred) => !keys.includes(cred.name))

  useEffect(() => {
    if (!editKey) {
      dispatch(getAllCredKeysStart())
      dispatch(getAllCredStart())
    }
  }, [])

  const createCredentials = (data) =>
    dispatch(
      createCredsStart(data)
    )

  const updateCredentials = (data) =>
    dispatch(
      updateCredsStart(data)
    )

  return {
    navigate,
    credKeys,
    loading,
    data,
    keys,
    myKeys,
    createCredentials,
    updateCredentials
  }
}

export default useCreateCredentials
