import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPortalsStart } from '../../store/redux-slices/tenants'

const usePortalFilter = (selectedClient, isTenant = false) => {
  const dispatch = useDispatch()
  const { portalsFilterData } = useSelector(state => state.tenants)

  useEffect(() => {
    if (selectedClient && !isTenant) {
      dispatch(getAllPortalsStart({ adminId: selectedClient }))
    }
  }, [selectedClient])

  return {
    portalsFilterData
  }
}

export default usePortalFilter
