import React from 'react'
import SetThresholds from '../../../components/SetThresholds'
import { useSetAmlThresholds } from './useSetAmlThresholds'

const SetAMLThresholds = () => {
  const {
    setThresholdHandler,
    setSelectedClient,
    setSelectedPortal,
    selectedClient,
    selectedPortal,
    thresholdData,
    amlForms
  } = useSetAmlThresholds()

  return (
    <SetThresholds
      setThresholdHandler={setThresholdHandler}
      setSelectedClient={setSelectedClient}
      setSelectedPortal={setSelectedPortal}
      selectedClient={selectedClient}
      selectedPortal={selectedPortal}
      thresholdData={thresholdData}
      type='amlModule'
      forms={amlForms}
    />
  )
}

export default SetAMLThresholds
