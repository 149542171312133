import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkIsTenant } from '../../utils/constants'
import useCheckPermission from '../../utils/checkPermission'
import { getRealityCheckDataStart, setRealityCheckDataStart } from '../../store/redux-slices/admins'

const useRealityCheck = () => {
  const dispatch = useDispatch()
  const { isHidden } = useCheckPermission()
  const [selectedClient, setSelectedClient] = useState('')
  const { tenantDetails } = useSelector((state) => state.login)
  const { realityCheckData, realityCheckLoading, adminDetails } = useSelector(state => state.admins)

  useEffect(() => {
    (checkIsTenant() ? tenantDetails?.adminUserId : selectedClient) && dispatch(getRealityCheckDataStart({ adminId: (checkIsTenant() ? tenantDetails?.adminUserId : selectedClient) || '' }))
  }, [selectedClient, tenantDetails])

  const updateRealityCheck = (data) => dispatch(setRealityCheckDataStart({ data }))

  return {
    isHidden,
    adminDetails,
    selectedClient,
    realityCheckData,
    setSelectedClient,
    updateRealityCheck,
    realityCheckLoading
  }
}

export default useRealityCheck
