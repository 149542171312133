import React from 'react'
import {
  Row,
  Col
} from '@themesberg/react-bootstrap'

import useFormFields from './useFormFields'
import RegistrationFormFields from '../../../components/RegistrationFormField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenField } from '@fortawesome/pro-solid-svg-icons'
import { checkIsTenant } from '../../../utils/constants'

const FormFields = () => {
  const { loading, data, handleChange, updateFields } = useFormFields()

  return (
    <>
      <Row>
        <Col sm={8}>
          <h3><FontAwesomeIcon icon={faPenField} />&nbsp;Registration</h3>
        </Col>
      </Row>
      <RegistrationFormFields
        loading={loading}
        data={data}
        handleChange={handleChange}
        updateFields={updateFields}
        isTenant={checkIsTenant()}
      />
    </>
  )
}

export default FormFields
