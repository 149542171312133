import { takeLatest, put } from 'redux-saga/effects'
import {
  getAllTournamentsStart,
  getAllTournamentsSuccess,
  getAllTournamentsFailure,
  getTournamentDetailsStart,
  getTournamentDetailsSuccess,
  getTournamentDetailsFailure,
  updateTournamentStart,
  updateTournamentComplete,
  updateTournamentStatusStart,
  updateTournamentStatusComplete,
  deleteTournamentStart,
  deleteTournamentComplete
} from '../redux-slices/tournaments'
import { createTournament, getTournamentDetails, getTournaments, superAdminViewToggleStatus, tenantViewToggleStatus, updateTournament, deleteTournament } from '../../utils/apiCalls'
import { toast } from '../../components/Toast'
import { serialize } from 'object-to-formdata'
import { SuperAdminRoutes, TenantRoutes } from '../../routes'

export default function * tenantUsersWatcher () {
  yield takeLatest(getAllTournamentsStart.type, getAllTournamentsWorker)
  yield takeLatest(getTournamentDetailsStart.type, getTournamentDetailsWorker)
  yield takeLatest(updateTournamentStart.type, updateTournamentWorker)
  yield takeLatest(updateTournamentStatusStart.type, updateTournamentStatusWorker)
  yield takeLatest(deleteTournamentStart.type, deleteTournamentWorker)
}

function * getAllTournamentsWorker (action) {
  try {
    const { isTenant, adminId, tenantId, limit, pageNo, tournamentId } = action && action.payload

    const { data } = yield getTournaments({ isTenant, adminId, tenantId, limit, pageNo, tournamentId })

    yield put(getAllTournamentsSuccess(data?.data?.getAllTournamentDetail))
  } catch (e) {
    yield put(getAllTournamentsFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getTournamentDetailsWorker (action) {
  try {
    const { isTenant, tournamentId } = action && action.payload

    const { data } = yield getTournamentDetails({ isTenant, tournamentId })

    yield put(getTournamentDetailsSuccess(data?.data?.getTournamentDetail))
  } catch (e) {
    yield put(getTournamentDetailsFailure(e?.response?.data?.errors[0].description))

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateTournamentWorker (action) {
  try {
    let { data, isTenant, create = false, navigate } = action && action.payload
    data = serialize(data)

    create ? yield createTournament({ isTenant, data }) : yield updateTournament({ isTenant, data })

    yield put(updateTournamentComplete())
    yield toast(`Tournament ${create ? 'Created' : 'Edited'} Successfully`, 'success')
    navigate(isTenant ? TenantRoutes.Tournaments : SuperAdminRoutes.Tournaments)
  } catch (e) {
    yield put(updateTournamentComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * updateTournamentStatusWorker (action) {
  try {
    const {
      adminId,
      limit,
      pageNo,
      tenantId,
      tournamentId,
      isTenant = false,
      data
    } = action && action.payload

    isTenant
      ? yield tenantViewToggleStatus(data)
      : yield superAdminViewToggleStatus(data)

    yield put(updateTournamentStatusComplete())

    yield put(getAllTournamentsStart({ limit, pageNo, adminId, tenantId, tournamentId, isTenant }))
    yield toast('Status Updated Successfully', 'success')
  } catch (e) {
    yield put(updateTournamentStatusComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * deleteTournamentWorker (action) {
  try {
    const {
      adminId,
      limit,
      pageNo,
      tenantId,
      tournamentId,
      isTenant = false,
      data
    } = action && action.payload

    yield deleteTournament({ data, isTenant })

    yield put(deleteTournamentComplete())

    yield put(getAllTournamentsStart({ limit, pageNo, adminId, tenantId, tournamentId, isTenant }))
    yield toast('Tournament Deleted Successfully', 'success')
  } catch (e) {
    yield put(deleteTournamentComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}
