import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { addDepositToOtherStart } from '../../../store/redux-slices/tenants'
import { getWalletDataStart } from '../../../store/redux-slices/fetchData'
import { useEffect } from 'react'

const useManageMoney = ({ isTenant, user, show }) => {
  const dispatch = useDispatch()
  const { userId } = useParams()

  const fetchWalletData = (fetch) => {
    (fetch
      ? true
      : user?.userWallet?.ownerId !== user?.userId) && show &&
    dispatch(
      getWalletDataStart({
        userId: user?.userId,
        isTenant,
        tenantId: user?.tenantId
      })
    )
  }

  useEffect(() => {
    fetchWalletData()
  }, [user?.userId, show])

  const deposit = (data) => {
    dispatch(addDepositToOtherStart({
      data: {
        addAmount: data?.transactionType === 'add-money' ? parseFloat(data?.addAmount.toFixed(2)) : (parseFloat(data?.addAmount?.toFixed(2)) * (-1)),
        walletType: data?.walletType === 'cash' ? 'CASH' : 'NONCASH',
        userId
      },
      isTenant
    }))
  }

  return {
    deposit
  }
}

export default useManageMoney
