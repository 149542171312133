import React from 'react'
import useAudits from '../hooks/useAudits'
import Trigger from '../../../../components/OverlayTrigger'
import { faRedoAlt } from '@fortawesome/pro-solid-svg-icons'
import { getDateDaysAgo, getDateTime } from '../../../../utils/dateFormatter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DateRangePicker from '../../../../components/DateRangePicker'
import { Button, Col, Row, Form, Table } from '@themesberg/react-bootstrap'
import PaginationComponent from '../../../../components/Pagination'

const AuditLogs = ({
  page,
  setPage,
  limit,
  setLimit,
  search,
  setSearch,
  state,
  setState
}) => {
  const {
    loading,
    auditList,
    totalPages
  } = useAudits({ limit, page, search, state })

  const tableHeaders = ['Id', 'Role', 'Admin', 'Before Value', 'After Value', 'Action Description', 'Action Time']

  return (
    <>
      <Row>
        <Col style={{ maxHeight: '25px' }} xs='auto' className='d-flex mb-3'>
          {/* <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Search
          </Form.Label>
          <Form.Control
            type='search'
            size='sm'
            value={search}
            style={{ width: '260px' }}
            placeholder='Search by Title / Description / Agent'
            onChange={e => setSearch(e.target.value)}
          /> */}

          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Action Time
          </Form.Label>
          <DateRangePicker className='mb-3' state={state} setState={setState} transaction bonus />
        </Col>
        <Col xs='auto' className='d-flex mb-3'>
          <Trigger message='Reset Filters'>
            <Button
              variant='outline-success'
              className=''
              size='sm'
              onClick={() => {
                setSearch('')
                setLimit(15)
                setPage(1)
                setState([{
                  startDate: getDateDaysAgo(10),
                  endDate: new Date(),
                  key: 'selection'
                }])
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
          {/* <Trigger message='Download as CSV'>
            <Button
              variant='outline-success'
              size='sm'
              style={{ marginLeft: '10px' }}
              disabled={auditList?.count < 0}
              onClick={() => { downloadFile(getCsvDownloadUrl('allTransactions')) }}
            >
              <FontAwesomeIcon icon={faFileDownload} />
            </Button>
          </Trigger> */}
        </Col>

      </Row>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, idx) => (
              <th key={`T-table_heading ${idx}`}>
                {h}
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {auditList && !loading &&
            auditList?.rows?.map(
              ({
                id,
                adminId,
                createdAt,
                description,
                role,
                name = '',
                beforeValue,
                afterValue
              }) => {
                return (
                  <tr key={`audit-list ${id}`}>
                    <td>{id}</td>
                    <td>{role === 1 ? 'Superadmin' : 'Admin'}</td>
                    <td>{name || ''}{' ( '}{`${adminId}`}</td>
                    <td>{beforeValue || 'NA'}</td>
                    <td>{afterValue || 'NA'}</td>
                    <td>{description || 'NA'}</td>
                    <td>{createdAt ? getDateTime(createdAt) : 'NA'}</td>
                  </tr>
                )
              })}

          {auditList?.count === 0 && !loading && (
            <tr>
              <td colSpan={7} className='text-danger text-center'>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {auditList?.count > 0 && !loading && (
        <PaginationComponent
          page={auditList?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}
    </>
  )
}

export default React.memo(AuditLogs)
