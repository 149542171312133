import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { addCommentsStart, getCommentsStart, updateCommentStart } from '../../../../store/redux-slices/tenantsFetchData'
import useDidMountEffect from '../../../../utils/useDidMountEffect'

const useComments = ({
  page,
  limit,
  search,
  role,
  status,
  isTenant
}) => {
  const dispatch = useDispatch()
  const { userId } = useParams()
  const [title, setTitle] = useState('')
  const isInitialRender = useDidMountEffect()
  const [description, setDescription] = useState('')
  const [isCommentAddEnabled, setCommentAddStatus] = useState(false)

  const { loading, commentsList } = useSelector((state) => state.tenantsFetch)
  const totalPages = Math.ceil(commentsList?.count / limit)

  const payload = {
    isTenant,
    pageNo: page,
    limit,
    userId,
    search,
    role,
    status
  }

  const getCommentsListHandler = async () => {
    await dispatch(getCommentsStart({ ...payload }))
  }

  useEffect(() => {
    getCommentsListHandler()
  }, [role, status, limit, page])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        dispatch(getCommentsStart({ ...payload }))
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const addCommentHandler = async (e) => {
    e.preventDefault()
    await dispatch(addCommentsStart({
      isTenant,
      data: {
        title,
        comment: description,
        userId
      },
      getComment: { ...payload }
    }))
    setCommentAddStatus(false)
    setDescription('')
    setTitle('')
  }

  const statusInactiveHandler = async (commentId, isActive) => {
    await dispatch(updateCommentStart({
      isTenant,
      data: {
        commentId,
        status: isActive,
        userId
      },
      getComment: { ...payload }
    }))
  }

  return {
    title,
    loading,
    setTitle,
    totalPages,
    description,
    commentsList,
    setDescription,
    addCommentHandler,
    setCommentAddStatus,
    isCommentAddEnabled,
    statusInactiveHandler
  }
}

export default useComments
