import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCountriesStart, getTenantCountriesStart } from '../../../../../store/redux-slices/fetchData'

const useBonusCountry = (isTenant) => {
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const [myCountries, setMyCountries] = useState([])
  const { tenantCountries, countries, loading } = useSelector((state) => state.fetch)

  useEffect(() => {
    isTenant
      ? tenantCountries?.length !== myCountries?.length && dispatch(getTenantCountriesStart())
      : countries?.length !== myCountries?.length && dispatch(getCountriesStart({ limit: '', name: '', pageNo: '' }))
  }, [])

  const setCountries = () => {
    if (countries?.length || tenantCountries?.count) {
      const ctry = isTenant ? tenantCountries?.rows : countries
      const country = []
      ctry.forEach(({ name, code }) => {
        if (name?.EN?.toLowerCase().includes(search.toLowerCase())) {
          country.push({ name: name?.EN, code })
        }
        setMyCountries(country)
      })
    }
  }

  useEffect(() => {
    setCountries()
  }, [countries, tenantCountries, search])

  return {
    myCountries,
    search,
    setSearch,
    loading
  }
}

export default useBonusCountry
