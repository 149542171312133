import React from 'react'
import useSendMail from './hooks/useSendMail'
import TakeUserMail from './components/TakeUserMail'
import EmailSent from './components/EmailSent'
import ResetPassword from './components/ResetPassword'

const ForgotPassword = () => {
  const {
    sendMailLoading,
    resetPassLoading,
    emailStatus,
    userEmail,
    showPassword,
    setShowPassword,
    handleResetPassword,
    handleSendMail
  } = useSendMail()

  return (
    <>
      {emailStatus?.takeUserMail && <TakeUserMail handleSendMail={handleSendMail} sendMailLoading={sendMailLoading} />}
      {emailStatus?.emailSent && <EmailSent userEmail={userEmail} />}
      {emailStatus?.resetPassword &&
        <ResetPassword
          resetPassLoading={resetPassLoading}
          handleResetPassword={handleResetPassword}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
        />}
    </>

  )
}

export default ForgotPassword
