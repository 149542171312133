import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { formatDateYMD, getDateDaysAgo } from '../../../../utils/dateFormatter'
import { getPlayerManagementStart } from '../../../../store/redux-slices/dashboard'
import { checkIsTenant } from '../../../../utils/constants'

const useTopPlayers = ({ selectedPortal = '', selectedClient = '', accordionState }) => {
  const dispatch = useDispatch()
  const isTenant = checkIsTenant()

  const { playerLoading: loading, playerData } = useSelector((state) => state.dashboard)
  const [selectedTab, setSelectedTab] = useState('winners')
  const [dateOptions, setDateOptions] = useState('today')
  const [limit, setLimit] = useState(10)

  const [state, setState] = useState([
    {
      startDate: getDateDaysAgo(0),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const isInitialRender = useDidMountEffect()

  const fetchDetails = () => {
    accordionState.playerManagementReport && dispatch(getPlayerManagementStart({
      isTenant,
      startDate: formatDateYMD(state.map(a => a.startDate)),
      endDate: formatDateYMD(state.map(a => a.endDate)),
      tenantId: selectedPortal,
      adminId: selectedClient,
      limit,
      dateOptions,
      tab: selectedTab
    }))
  }

  useEffect(() => {
    fetchDetails()
  }, [state, selectedClient, selectedPortal, limit, accordionState.playerManagementReport, selectedTab])

  useEffect(() => {
    if (dateOptions !== 'custom' && !isInitialRender) fetchDetails()
  }, [dateOptions])

  return {

    fetchDetails,
    loading,
    setState,
    state,
    selectedTab,
    setSelectedTab,
    playerData,
    limit,
    setLimit,
    dateOptions,
    setDateOptions
  }
}

export default useTopPlayers
