import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateLoyaltyLimitStart } from '../../../store/redux-slices/tenantsFetchData'
import useCheckPermission from '../../../utils/checkPermission'
import { useNavigate } from 'react-router-dom'

const useConfigurations = () => {
  const dispatch = useDispatch()
  const { tenantDetails } = useSelector((state) => state.login)
  const { loading } = useSelector((state) => state.tenantsFetch)
  const [pointsObject, setPointsObject] = useState([])
  const [enableEdit, setEnableEdit] = useState(false)
  const { isHidden } = useCheckPermission()
  const navigate = useNavigate()

  const updateLoyaltyLimit = (loyaltyLimit) => {
    dispatch(updateLoyaltyLimitStart({ loyaltyLimit }))
    setEnableEdit(false)
  }

  useEffect(() => {
    const pointObject = tenantDetails?.tenantConfig?.loyaltyPoint
    const exchangeRates = tenantDetails?.tenantConfig?.exchangeRates
    const newData = []
    pointObject && Object.keys(pointObject).map((key, index) => {
      if (tenantDetails?.tenantConfig?.allowedCurrencies?.includes(key)) {
        newData.push({ code: key, loyaltyPoint: pointObject?.[key], exchangeRates: exchangeRates?.[key] || 0 })
      }
    })
    setPointsObject(newData)
  }, [tenantDetails])

  return {
    loading,
    isHidden,
    navigate,
    enableEdit,
    pointsObject,
    tenantDetails,
    setEnableEdit,
    updateLoyaltyLimit
  }
}

export default useConfigurations
