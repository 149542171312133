import { faCheckSquare, faCopy, faEdit, faEye, faTrash, faWindowClose } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import { formatDate } from '../../utils/dateFormatter'
import ConfirmationModal from '../ConfirmationModal'
import Trigger from '../OverlayTrigger'
import PaginationComponent from '../Pagination'
import { bonusType } from '../../pages/Super-Admin/Bonus/components/CreateBonus/constants'
import useCheckPermission from '../../utils/checkPermission'
import useDeleteBonus from './useDeleteBonus'
import DeleteModal from './DeleteModal'

export default ({
  bonusList,
  limit,
  setLimit,
  page,
  setPage,
  totalPages,
  tableHeaders,
  show,
  setShow,
  handleYes,
  active,
  isTenant = false,
  handleShow,
  navigate,
  name,
  loading,
  showDeleteModal,
  setShowDeleteModal
}) => {
  const { isHidden } = useCheckPermission()
  const {
    handleDelete,
    handleDeleteYes,
    handleClose,
    deleteName,
    showText
  } = useDeleteBonus(isTenant, setShowDeleteModal)

  return (
    <>
      <Table
        bordered
        striped
        responsive
        hover
        size='sm'
        className='text-center mt-4'
      >
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, idx) => (
              <th key={`T-table_heading ${idx}`}>{h.label}</th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {bonusList && !loading &&
            bonusList?.rows?.map(
              ({
                bonusId,
                promotionTitle,
                tenant,
                isActive,
                bonusType: type,
                adminUser,
                validTo,
                claimedCount,
                isSticky
              }) => {
                return (
                  <tr key={`bonus-list ${bonusId}`}>
                    <td>{bonusId}</td>
                    <td>{promotionTitle?.EN || 'NA'}</td>
                    <td>
                      {adminUser?.firstName}{' '}
                      {adminUser?.lastName}
                    </td>
                    <td>{tenant?.name}</td>
                    <td>{(type === 'freespins' && !isSticky) ? 'CASH FREESPINS' : bonusType.find(val => val.value === type)?.label}</td>
                    <td>{(type === 'deposit' || type === 'wagering') ? '-' : formatDate(validTo)}</td>
                    <td>{(type === 'deposit' || type === 'wagering') ? 'No' : (formatDate(validTo) < formatDate(new Date()) ? 'Yes' : 'No')}</td>
                    <td>{claimedCount ? 'Yes' : 'No'}</td>
                    <td>
                      {isActive
                        ? (
                          <span className='text-success'>Active</span>
                          )
                        : (
                          <span className='text-danger'>In-Active</span>
                          )}
                    </td>
                    <td className='text-left'>
                      <ButtonGroup>
                        <Trigger message='View'>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='info'
                            hidden={isHidden({ module: { key: 'Bonus', value: 'R' } })}
                            onClick={() =>
                              navigate(`/${isTenant ? 'tenant' : 'super-admin'}/bonus/details/${bonusId}`)}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Button>
                        </Trigger>
                      </ButtonGroup>
                      <ButtonGroup>
                        <Trigger message='Edit'>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='info'
                            hidden={isHidden({ module: { key: 'Bonus', value: 'U' } })}
                            onClick={() =>
                              navigate(`/${isTenant ? 'tenant' : 'super-admin'}/bonus/edit/${bonusId}`)}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                        </Trigger>
                      </ButtonGroup>
                      <ButtonGroup>
                        <Trigger message='Clone'>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='danger'
                            hidden={isHidden({ module: { key: 'Bonus', value: 'C' } })}
                            onClick={() =>
                              navigate(`/${isTenant ? 'tenant' : 'super-admin'}/bonus/clone/${bonusId}`)}
                          >
                            <FontAwesomeIcon icon={faCopy} />
                          </Button>
                        </Trigger>

                        {!isActive
                          ? (
                            <Trigger message='Set Status Active'>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='success'
                                hidden={isHidden({ module: { key: 'Bonus', value: 'T' } })}
                                onClick={() => handleShow(bonusId, isActive, `${promotionTitle?.EN}(${tenant?.name})`)}
                              >
                                <FontAwesomeIcon icon={faCheckSquare} />
                              </Button>
                            </Trigger>
                            )
                          : (
                            <Trigger message='Set Status In-Active'>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='danger'
                                hidden={isHidden({ module: { key: 'Bonus', value: 'T' } })}
                                onClick={() => handleShow(bonusId, isActive, `${promotionTitle?.EN} (${tenant?.name})`)}
                              >
                                <FontAwesomeIcon icon={faWindowClose} />
                              </Button>
                            </Trigger>
                            )}
                        <Trigger message='Delete'>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='danger'
                            disabled={claimedCount}
                            hidden={isHidden({ module: { key: 'Bonus', value: 'D' } })}
                            onClick={() => handleDelete(bonusId, bonusType, `${promotionTitle?.EN}(${tenant?.name})`)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </Trigger>
                      </ButtonGroup>
                    </td>
                  </tr>
                )
              }
            )}

          {bonusList?.count === 0 && !loading && (
            <tr>
              <td colSpan={10} className='text-danger text-center'>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {bonusList?.count > 0 && !loading && (
        <PaginationComponent
          page={bonusList?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}

      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={active}
          name={name}
        />
      )}

      {showDeleteModal &&
        <DeleteModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          handleDeleteYes={handleDeleteYes}
          name={deleteName}
          handleClose={handleClose}
          showText={showText}
          loading={loading}
        />}
    </>
  )
}
