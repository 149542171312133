export const safeStringify = (object) => {
  return JSON.stringify(object)?.replace(/</g, '\\u003c')
}

export const isEqual = (newObj, prevObj) => {
  for (const key in newObj) {
    if (newObj[key] !== prevObj[key]) return false
  }
  return true
}

export const capitalizeMyText = (name, delemiter) => {
  return (name && name?.length > 0 && name.split(delemiter).map(ele =>
    ele.substr(0, 1).toUpperCase().concat(ele.substr(1))
  ).join(' ')) || name
}

export function getMatchingValues (obj1, obj2) {
  const result = {}

  for (const key in obj1) {
    if (obj2?.hasOwnProperty(key)) {
      const arr1 = obj1?.[key]
      const arr2 = obj2?.[key]

      const matchingValues = arr1?.filter(value => arr2?.includes(value))

      if (matchingValues.length > 0) {
        result[key] = matchingValues
      }
    }
  }

  return result
}

export const internationalNumberFormatter = (number = 0) => {
  return new Intl.NumberFormat('en-EU').format(number)
}

export const handleImagePreview = (file, setPreview, route) => {
  if (file) {
    const imageAsBase64 = URL.createObjectURL(file)
    const imageAsFiles = file
    setPreview && setPreview({
      image_preview: imageAsBase64,
      image_file: imageAsFiles
    })
    route && window.open(imageAsBase64, '_blank')
  }
}

export const spreadKeysWithValues = (obj) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value && typeof value !== 'string') {
      acc[key] = value
    }
    return acc
  }, {})
}

export const removeEmptyData = (obj) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== '') {
      acc[key] = value
    }
    return acc
  }, {})
}

export const getCountryNames = (countryOptions = [], countryValues) => {
  return countryValues
    .map(value => {
      const match = countryOptions.find(option => option.value === value)
      return match ? match.label : null // Return null if no match is found
    })
    .filter(name => name !== null) // Filter out any null values
    .join(', ') // Concatenate names with a comma and space
}

export const getCountryDetailsByCodes = (countryOptions = [], countryValues) => {
  return countryValues
    .map(value => {
      const match = countryOptions.find(option => option.value === value)
      return match ? { value: match.value, label: match.label } : null // Return object with value and label
    })
    .filter(country => country !== null) // Filter out any null values
}

export const getLabelByValue = (array, value) => {
  const foundItem = array.find(item => item.value === value)
  return foundItem ? foundItem.label : null // Returns null if not found
}
