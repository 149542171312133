import React from 'react'
import { Row, Col, Button } from '@themesberg/react-bootstrap'
import { SpinnerLoader } from '../../../../../components/Preloader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo, faCloudArrowDown } from '@fortawesome/pro-solid-svg-icons'
import { DateRangePickerWithoutInput } from '../../../../../components/DateRangePicker'
import useKPISummary from '../../hooks/useKPISummary'
import { downloadFile } from '../../../../../utils/fileDownloader'
import KPISummaryTable from '../../../../../components/KPISummaryTable'

export default ({ selectedPortal = '', selectedClient = '', accordionState, isHidden }) => {
  const {
    state,
    loading,
    setState,
    KPISummary,
    loadKPISummary,
    getCsvDownloadUrl
  } = useKPISummary({ selectedPortal, selectedClient, accordionState })

  return (
    <>
      <Row className='border-bottom d-flex justify-content-between mb-2 pb-2'>
        <Col>
          <button onClick={() => loadKPISummary()} className='btn btn-sm btn-secondary d-flex align-items-center gap-1'>
            <span className='fs-7'>Refresh</span>
            {loading ? <SpinnerLoader /> : <FontAwesomeIcon style={{ fontSize: '15px' }} icon={faRedo} />}
          </button>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center DateRangePickerWithoutInputWrapper noOption'>
            <DateRangePickerWithoutInput
              state={state} setState={setState}
            />

            <Button
              variant='success'
              size='sm'
              style={{ width: '150px' }}
              disabled={KPISummary?.length < 1}
              hidden={isHidden({ module: { key: 'KpiSummaryReport', value: 'DR' } })}
              onClick={() => { downloadFile(getCsvDownloadUrl()) }}
            >
              Export&nbsp;<FontAwesomeIcon icon={faCloudArrowDown} />
            </Button>
          </div>
        </Col>

      </Row>

      <KPISummaryTable KPISummary={KPISummary} />
    </>

  )
}
