import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { downloadFile } from '../../../../../utils/fileDownloader'
import { SpinnerLoader } from '../../../../../components/Preloader'
import { Button, Row, Col, Form } from '@themesberg/react-bootstrap'
import useDemographicReport from './../../hooks/useDemographicReport'
import DateOptionsFilter from '../../../../../components/DateOptionsFilter'
import { faRedo, faCloudArrowDown } from '@fortawesome/pro-solid-svg-icons'
import DemographicWithMap from '../../../../../components/DemographicWIthMap'
import { DateRangePickerWithoutInput } from '../../../../../components/DateRangePicker'

export default ({ selectedPortal = '', selectedClient = '', accordionState, isHidden }) => {
  const {
    state,
    hover,
    sortBy,
    loading,
    options,
    setState,
    setHover,
    setSortBy,
    demographData: demogData,
    dateOptions,
    formatedData,
    loadDemogData,
    setDateOptions,
    getCsvDownloadUrl
  } = useDemographicReport({ selectedPortal, selectedClient, accordionState })

  // const MemoizedDemographicWithMap = React.memo(DemographicWithMap)

  return (
    <>
      <Row className='border-bottom d-flex align-items-center justify-content-between mb-2 pb-2'>
        <Col>
          <button onClick={() => loadDemogData()} className='btn btn-sm btn-secondary d-flex align-items-center gap-1'>
            <span className='fs-7'>Refresh</span>
            {loading ? <SpinnerLoader /> : <FontAwesomeIcon style={{ fontSize: '15px' }} icon={faRedo} />}
          </button>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center DateRangePickerWithoutInputWrapper'>
            {dateOptions === 'custom' &&
              <DateRangePickerWithoutInput
                state={state} setState={setState}
              />}

            <Form.Label style={{ marginTop: '5px', marginRight: '10px' }}>
              Date Options
            </Form.Label>
            <DateOptionsFilter dateOptions={dateOptions} setDateOptions={setDateOptions} />

            <Button
              variant='success'
              size='sm'
              style={{ width: '150px' }}
              disabled={demogData?.length < 1}
              hidden={isHidden({ module: { key: 'DemographReport', value: 'DR' } })}
              onClick={() => { downloadFile(getCsvDownloadUrl()) }}
            >
              Export <FontAwesomeIcon icon={faCloudArrowDown} />
            </Button>
          </div>
        </Col>

      </Row>
      <DemographicWithMap
        hover={hover}
        sortBy={sortBy}
        options={options}
        setHover={setHover}
        setSortBy={setSortBy}
        demogData={demogData}
        formatedData={formatedData}
      />

    </>

  )
}
