import { useDispatch, useSelector } from 'react-redux'
import { getGroupDetailStart, getGroupsStart, getSuperAdminDetailsStart, updateGroupStart } from '../../../store/redux-slices/admins'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useMemo } from 'react'

const useCreateGroup = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isTenant = window.location.pathname.includes('tenant')
  const { groupDetail, groupsList, groupLoading, adminDetails } = useSelector(state => state.admins)
  const pathName = window.location.pathname
  const { groupId } = useParams()
  const type = pathName.includes('create') ? 'create' : pathName.includes('edit') ? 'edit' : 'view'

  const parentGroupOptions = useMemo(() => {
    const parentGroups = groupsList?.rows || []

    return parentGroups.reduce((currentValue, parentGroup) => {
      if (parentGroup.id) {
        return [
          ...currentValue,
          {
            label: parentGroup?.parentGroupName || '',
            value: parentGroup?.id
          }
        ]
      }

      return currentValue
    }, [])
  }, [groupsList])

  useEffect(() => {
    if (['edit', 'view'].includes(type)) {
      dispatch(getGroupDetailStart({ groupId, isTenant }))
    }
  }, [type, groupId])

  useEffect(() => {
    !isTenant && dispatch(getSuperAdminDetailsStart({ isTenant: false }))
  }, [])

  // Check if parent groups are available in store, if not call the API to fetch the parent groups
  useEffect(() => {
    if (!groupsList.rows) {
      dispatch(getGroupsStart({ isTenant }))
    }
  }, [groupsList, dispatch, getGroupsStart, isTenant])

  const handleSave = (formValues) => {
    dispatch(updateGroupStart({
      data: {
        ...formValues,
        parentId: formValues.parentId.value,
        groupId: parseInt(groupId)
      },
      isTenant,
      type,
      navigate
    }))
  }

  return {
    groupDetail: type === 'create' ? {} : groupDetail,
    type,
    groupLoading,
    adminDetails,
    handleSave,
    navigate,
    isTenant,
    parentGroupOptions
  }
}

export default useCreateGroup
