import React, { useEffect, useState } from 'react'
import { transactionType } from '../../pages/Super-Admin/TransactionsBanking/constants'
import Multiselect from 'multiselect-react-dropdown'
import './MultiSelect.scss'

const ActionTypeSelect = ({
  selectedAction,
  setSelectedAction,
  selectOptions = transactionType,
  placeholder = 'Action Type',
  setFieldValue,
  fieldName,
  disabled = false,
  customWidth = '255px',
  disableOptions = false
}) => {
  const [selectedActionType, setSelectedActionType] = useState(selectedAction ? JSON.parse(selectedAction) : [])

  useEffect(() => {
    fieldName
      ? setFieldValue && setFieldValue(fieldName, JSON.stringify(selectedActionType?.map(action => action.value)))
      : setSelectedAction && setSelectedAction(JSON.stringify(selectedActionType?.map(action => action.value)))
  }, [selectedActionType])

  useEffect(() => {
    !JSON.parse(selectedAction).length && setSelectedActionType([])
  }, [selectedAction])

  return (
    <div style={{ width: customWidth }}>
      <Multiselect
        options={selectOptions}
        selectedValues={selectedActionType}
        placeholder={selectedActionType?.length ? `Selected ${placeholder} ${selectedActionType?.length}` : `Select ${placeholder}`}
        onSelect={(option) => {
          setSelectedActionType(option)
        }}
        onRemove={(option) => {
          setSelectedActionType(option)
        }}
        displayValue='label'
        showCheckbox
        selectionType='counter'
        hideSelectedList
        avoidHighlightFirstOption
        disable={disabled}
        disablePreSelectedValues={disableOptions}
      />
    </div>
  )
}

export default ActionTypeSelect
