export const SuperAdminRoutes = {
  SuperAdminSignin: '/super-admin/signin',
  Dashboard: '/super-admin/dashboard',
  TenantOwner: '/super-admin/tenants/owners',
  TOManagers: '/super-admin/tenants/managers/:ownerId',
  Tenants: '/super-admin/tenants/:adminId',
  CreateTenant: '/super-admin/tenants/create/:adminId',
  TenantDetails: '/super-admin/tenants/details/:adminId/:tenantId',
  Admins: '/super-admin/admins',
  AdminDetails: '/super-admin/admins/details/:adminId',
  Currencies: '/super-admin/currencies',
  Languages: '/super-admin/languages',
  CreateCurrencies: '/super-admin/create-currencies',
  EditCurrency: '/super-admin/currencies/edit/:currencyId',
  CreateTenantAdmin: '/super-admin/tenants/owners/create',
  EditTenant: '/super-admin/tenants/edit/:adminId/:tenantId',
  EditTheme: '/super-admin/edit-theme/:themeId',
  CreateTheme: '/super-admin/create-theme',
  Themes: '/super-admin/theme',
  Credential: '/super-admin/tenant/:tenantId/credential',
  CMS: '/super-admin/CMS',
  Users: '/super-admin/users',
  UserDetails: '/super-admin/users/details/:userId',
  BannedUsers: '/super-admin/banned-users',
  CreateCurrency: '/super-admin/create-currency',
  AffiliateDetail: '/super-admin/affiliate-details/:affiliateId',
  CMSDetail: '/super-admin/CMS/details/:cmsPageId',
  CasinoProviders: '/super-admin/casino-providers',
  CasinoGames: '/super-admin/casino-games',
  Countries: '/super-admin/countries',
  CreateSuperAdminUser: '/super-admin/create-superadmin-user',
  EditSuperAdminUser: '/super-admin/admins/edit/:adminId',
  EditTenantAdmin: '/super-admin/tenants/owners/edit/:adminUserId',
  TransactionsBanking: '/super-admin/transactions-banking',
  Aggregators: '/super-admin/casino-aggregators',
  CasinoCategory: '/super-admin/casino-category',
  CasinoSubCategory: '/super-admin/casino-sub-category',
  RestrictedGames: '/super-admin/countries/restricted-games/:countryId',
  RestrictedProviders: '/super-admin/countries/restricted-providers/:countryId',
  RestrictedProviderCountries: '/super-admin/casino-providers/restrict-countries/:itemId',
  RestrictedGameCountries: '/super-admin/casino-games/restrict-countries/:itemId',
  CasinoTransactions: '/super-admin/casino-transactions',
  CreateCMS: '/super-admin/CMS/create',
  EditCMS: '/super-admin/CMS/edit/:cmsPageId',
  FormFields: '/super-admin/form-fields',
  CreateBonus: '/super-admin/bonus/create',
  EditBonus: '/super-admin/bonus/edit/:bonusId',
  Bonus: '/super-admin/bonus',
  BonusDetail: '/super-admin/bonus/details/:bonusId',
  CloneBonus: '/super-admin/bonus/clone/:bonusId',
  KYCLabels: '/super-admin/kyc-labels',
  BannedGames: '/super-admin/banned-games',
  CreateBannedGames: '/super-admin/banned-games/create',
  BannedGamesDetail: '/super-admin/banned-games/details/:bannedGamesListId',
  EditBannedGames: '/super-admin/banned-games/edit/:bannedGamesListId',
  AddBannedGames: '/super-admin/banned-games/edit/add-games/:bannedGamesListId',
  AddClonedBannedGames: '/super-admin/banned-games/clone/add-games/:bannedGamesListId',
  ClonedBannedGames: '/super-admin/banned-games/clone/:bannedGamesListId',
  WageringTemplate: '/super-admin/wagering-template',
  WageringEdit: '/super-admin/wagering-template/edit/:wageringTemplateId',
  WageringDetails: '/super-admin/wagering-template/details/:wageringTemplateId',
  CreateWagering: '/super-admin/wagering-template/create',
  Loyalty: '/super-admin/loyalty-management',
  EmailTemplate: '/super-admin/email-templates',
  EditEmailTemplate: '/super-admin/email-templates/edit/:emailTemplateId',
  CreateEmailTemplate: '/super-admin/create-email-template',
  Profile: '/super-admin/profile',
  TenantOwnerDetail: 'super-admin/tenants/owners/details/:adminUserId',
  ImageGallery: '/super-admin/image-gallery',
  BannerManagement: '/super-admin/banner-management',
  EditBanner: '/super-admin/banner-management/edit/:bannerKey/:adminId/:tenantId',
  CreateBanner: '/super-admin/banner-management/create',
  ViewBanner: '/super-admin/banner-management/view/:bannerKey/:adminId/:tenantId',
  LanguageManagement: '/super-admin/language-management',
  EditSupportLanguageKeys: '/super-admin/language-management/edit/:keys',
  AddSupportLanguageKeys: '/super-admin/language-management/add/keys',
  EditSupportLanguage: '/super-admin/language-management/edit',
  AddLanguage: '/super-admin/language-management/add-language/:lang',
  PaymentAggregators: '/super-admin/cashier-management',
  EditCustomProviders: '/super-admin/cashier-management/edit/:adminId/:tenantId/:providerId',
  ViewCustomProviders: '/super-admin/cashier-management/view/:adminId/:tenantId/:providerId',
  BonusReorder: '/super-admin/bonus/reorder',
  ReviewManagement: '/super-admin/review-management',
  ErrLanguageManagement: '/super-admin/err/language-management',
  EditErrSupportLanguageKeys: '/super-admin/err/language-management/edit/:keys',
  EditErrSupportLanguage: '/super-admin/err/language-management/edit',
  AddErrLanguage: '/super-admin/err/language-management/add-language/:lang',
  Groups: '/super-admin/group-management',
  CreateGroup: '/super-admin/group-management/create',
  EditGroup: '/super-admin/group-management/edit/:groupId',
  ViewGroup: '/super-admin/group-management/details/:groupId',
  CashierReorder: '/super-admin/cashier-management/reorder/:adminId/:tenantId',
  Tournaments: '/super-admin/tournaments',
  CreateTournaments: '/super-admin/tournaments/create',
  EditTournaments: '/super-admin/tournaments/edit/:tournamentId',
  CloneTournament: '/super-admin/tournaments/clone/:tournamentId',
  ViewTournament: '/super-admin/tournaments/view/:tournamentId',
  GamblingPlayerListing: '/super-admin/responsible-gambling-players',
  GamblingSEPlayerListing: '/super-admin/responsible-gambling-self-excluded-players',
  SetThresholds: '/super-admin/responsible-gambling-set-thresholds',
  SetAMLThresholds: '/super-admin/set-aml-thresholds',
  AMLPlayerListing: '/super-admin/aml-players',
  RealityCheck: '/super-admin/reality-check',
  KYCTrigger: '/super-admin/kyc-trigger-settings',
  BannedPlayerSetting: '/super-admin/banned-player-setting',
  Hub88PrepaidList: '/super-admin/bonus/hub88-prepaid-list',
  CountryTranslation: '/super-admin/countries/edit/:keys',
  CountryTranslationLangWise: '/super-admin/countries/edit-language/:lang',
  Credentials: '/super-admin/credentials'
}

export const TenantRoutes = {
  TenantSignin: '/tenant/signin',
  Dashboard: '/tenant/dashboard',
  CMS: '/tenant/CMS',
  CreateCMS: '/tenant/CMS/create',
  CmsDetails: '/tenant/CMS/details/:cmsPageId',
  EditCms: '/tenant/CMS/edit/:cmsPageId',
  Admins: '/tenant/admins',
  AdminUserDetails: '/tenant/admins/details/:adminUserId',
  CreateAdminUser: '/tenant/admins/create',
  Credentials: '/tenant/credentials',
  Languages: '/tenant/languages',
  CreateCredentials: '/tenant/create-credentials',
  UpdateCredentials: '/tenant/update-credentials/:credKey',
  Users: '/tenant/users',
  UserDetails: '/tenant/users/details/:userId',
  BannedUsers: '/tenant/banned-users',
  TenantConfigurations: '/tenant/currencies',
  TenantConfigCurrency: '/tenant/currencies/create',
  FormFields: '/tenant/form-fields',
  TenantThemeSettings: '/tenant/settings/themeSettings',
  CasinoCategory: '/tenant/casino-category',
  CasinoSubCategory: '/tenant/casino-sub-category',
  CasinoGames: '/tenant/casino-games',
  AddSubCategoryGames: '/tenant/casino-sub-category/add-games/:tenantGameSubCategoryId',
  EditAdminUser: '/tenant/admins/edit/:tenantAdminUserId',
  ReorderSubCategory: '/tenant/casino-sub-category/reorder',
  ReorderCategory: '/tenant/casino-category/reorder',
  TransactionsBanking: '/tenant/transactions-banking',
  CasinoTransactions: '/tenant/casino-transactions',
  WithdrawRequests: '/tenant/withdraw-requests',
  BannedGames: '/tenant/banned-games',
  CreateBannedGames: '/tenant/banned-games/create',
  BannedGamesDetail: '/tenant/banned-games/details/:bannedGamesListId',
  EditBannedGames: '/tenant/banned-games/edit/:bannedGamesListId',
  AddBannedGames: '/tenant/banned-games/edit/add-games/:bannedGamesListId',
  AddClonedBannedGames: '/tenant/banned-games/clone/add-games/:bannedGamesListId',
  ClonedBannedGames: '/tenant/banned-games/clone/:bannedGamesListId',
  WageringTemplate: '/tenant/wagering-template',
  WageringEdit: '/tenant/wagering-template/edit/:wageringTemplateId',
  WageringDetails: '/tenant/wagering-template/details/:wageringTemplateId',
  CreateWagering: '/tenant/wagering-template/create',
  CreateBonus: '/tenant/bonus/create',
  EditBonus: '/tenant/bonus/edit/:bonusId',
  Bonus: '/tenant/bonus',
  BonusDetail: '/tenant/bonus/details/:bonusId',
  Countries: '/tenant/countries',
  CloneBonus: '/tenant/bonus/clone/:bonusId',
  Loyalty: '/tenant/loyalty-management',
  EmailTemplate: '/tenant/email-templates',
  EditEmailTemplate: '/tenant/email-templates/edit/:emailTemplateId',
  CreateEmailTemplate: '/tenant/email-templates/create',
  Profile: '/tenant/profile',
  ImageGallery: '/tenant/image-gallery',
  BannerManagement: '/tenant/banner-management',
  EditBanner: '/tenant/banner-management/edit/:bannerKey',
  CreateBanner: '/tenant/banner-management/create',
  ViewBanner: '/tenant/banner-management/view/:bannerKey',
  GameReorder: '/tenant/casino-games/reorder',
  LanguageManagement: '/tenant/language-management',
  EditSupportLanguageKeys: '/tenant/language-management/edit/:keys',
  AddSupportLanguageKeys: '/tenant/language-management/add/keys',
  EditSupportLanguage: '/tenant/language-management/edit',
  AddLanguage: '/tenant/language-management/add-language/:lang',
  PaymentAggregators: '/tenant/cashier-management',
  EditCustomProviders: '/tenant/cashier-management/edit/:adminId/:tenantId/:providerId',
  ViewCustomProviders: '/tenant/cashier-management/view/:adminId/:tenantId/:providerId',
  BonusReorder: '/tenant/bonus/reorder',
  ReviewManagement: '/tenant/review-management',
  Groups: '/tenant/group-management',
  CreateGroup: '/tenant/group-management/create',
  EditGroup: '/tenant/group-management/edit/:groupId',
  ViewGroup: '/tenant/group-management/details/:groupId',
  ErrLanguageManagement: '/tenant/err/language-management',
  CashierReorder: '/tenant/cashier-management/reorder/:adminId/:tenantId',
  Tournaments: '/tenant/tournaments',
  CreateTournaments: '/tenant/tournaments/create',
  EditTournaments: '/tenant/tournaments/edit/:tournamentId',
  CloneTournament: '/tenant/tournaments/clone/:tournamentId',
  ViewTournament: '/tenant/tournaments/view/:tournamentId',
  CasinoProviders: '/tenant/casino-providers',
  GamblingPlayerListing: '/tenant/responsible-gambling-players',
  GamblingSEPlayerListing: '/tenant/responsible-gambling-self-excluded-players',
  SetThresholds: '/tenant/responsible-gambling-set-thresholds',
  SetAMLThresholds: '/tenant/set-aml-thresholds',
  AMLPlayerListing: '/tenant/aml-players',
  RealityCheck: '/tenant/reality-check',
  CasinoAggregators: '/tenant/casino-aggregators',
  EditErrSupportLanguageKeys: '/tenant/err/language-management/edit/:keys',
  EditErrSupportLanguage: '/tenant/err/language-management/edit',
  AddErrLanguage: '/tenant/err/language-management/add-language/:lang',
  KYCTrigger: '/tenant/kyc-trigger-settings',
  BannedPlayerSetting: '/tenant/banned-player-setting',
  Hub88PrepaidList: '/tenant/bonus/hub88-prepaid-list',
  CountryTranslation: '/tenant/countries/edit/:keys',
  CountryTranslationLangWise: '/tenant/countries/edit-language/:lang'
}

// theme routes
export const CommonRoutes = {
  ForgotPassword: '/forgot-password',
  ResetPassword: '/:role/reset-password',
  Lock: '/lock',
  NotFound: '/404',
  ServerError: '/500',
  TenantNotFound: '/406'
}
