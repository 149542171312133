import React from 'react'
import {
  Row,
  Col
} from '@themesberg/react-bootstrap'
import { Formik } from 'formik'
import { createAdminUserSchema } from '../schema'
import { toast } from '../../../../components/Toast'
import useCreateAdminUser from '../hooks/useCreateAdminUser'
import AdminForm from './AdminForm'

const CreateTenantRoles = ({ userDetails }) => {
  const {
    navigate,
    loading,
    getPermissionLabel,
    updateTenantAdminUser,
    createTenantAdminUser,
    adminDetails,
    type,
    setType,
    groupOptions,
    setGroupOptions,
    selectedGroup,
    setSelectedGroup,
    groupDetail,
    dispatch,
    tenantIdOptions,
    getUpdatedData,
    tenantAdminRole,
    tenantAdminsData
  } = useCreateAdminUser({ group: userDetails?.group, tenantIds: userDetails?.tenantIds })

  return (
    <div>
      <Row>
        <Col className='d-flex'>
          <h3>{userDetails ? 'Edit' : 'Create'} Manager</h3>
          {userDetails && (
            <h3>
              {`: ${userDetails?.firstName} ${userDetails?.lastName}`}
            </h3>
          )}
        </Col>
      </Row>

      <Formik
        enableReinitialize
        initialValues={{
          email: userDetails ? userDetails?.email : '',
          password: '',
          firstName: userDetails ? userDetails?.firstName : '',
          lastName: userDetails ? userDetails?.lastName : '',
          agentName: userDetails ? userDetails?.agentName : '',
          phone: userDetails ? userDetails?.phone : '',
          role: userDetails ? userDetails?.AdminRole?.name : '',
          permission: userDetails
            ? userDetails?.userPermission?.permission
            : {},
          adminId: userDetails ? userDetails?.parentId : '',
          group: userDetails
            ? userDetails?.group
            : '',
          tenantIds: userDetails ? getUpdatedData() : []
        }}
        validationSchema={createAdminUserSchema(userDetails, adminDetails?.AdminRole?.name)}
        onSubmit={(formValues) => {
          ([undefined, null].includes(formValues.permission) || Object.keys(formValues.permission).length < 1)
            ? toast('Please Select At Least One Permission', 'error')
            : (userDetails
                ? updateTenantAdminUser({
                  data: {
                    ...formValues,
                    adminUserId: parseInt(userDetails?.adminUserId),
                    password: Buffer.from(formValues.password).toString(
                      'base64'
                    ),
                    tenantIds: formValues?.tenantIds?.map(({ value }) => value)
                  },
                  navigate
                })
                : createTenantAdminUser({
                  data: {
                    ...formValues,
                    adminId: formValues?.role !== 'Manager'
                      ? adminDetails?.AdminRole?.name === 'Manager'
                        ? parseInt(adminDetails?.adminUserId)
                        : parseInt(formValues?.adminId)
                      : parseInt(adminDetails?.adminUserId),
                    password: Buffer.from(formValues.password).toString('base64'),
                    tenantIds: formValues?.tenantIds?.map(({ value }) => value)
                  },
                  navigate
                }))
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue
        }) => (
          <AdminForm
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            userDetails={userDetails}
            groupOptions={groupOptions}
            setGroupOptions={setGroupOptions}
            setFieldValue={setFieldValue}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            type={type}
            setType={setType}
            dispatch={dispatch}
            adminDetails={adminDetails}
            getPermissionLabel={getPermissionLabel}
            navigate={navigate}
            loading={loading}
            groupDetail={groupDetail}
            tenantIdOptions={tenantIdOptions}
            tenantAdminRole={tenantAdminRole}
            tenantAdminsData={tenantAdminsData}
          />
        )}
      </Formik>
    </div>
  )
}

export default CreateTenantRoles
