import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { formatDateYMD } from '../../../../utils/dateFormatter'
import { affiliateDataConstants, goffiliateDataConstant, mapffiliateDataConstant } from '../constants'
import { getUserAffiliateNewDataStart, getUserAffiliateOldDataStart, updateUserAffiliateDataStart } from '../../../../store/redux-slices/tenantUsers'

const useAffiliateForm = ({ isTenant = false, isHidden, state }) => {
  const dispatch = useDispatch()
  const { userId } = useParams()
  const {
    affiliateNewLoading,
    affiliateOldLoading,
    affiliateOldData,
    affiliateNewData
  } = useSelector(state => state.tenantUsers)
  const [oldKeyData, setOldKeyData] = useState({})
  const showData = !isHidden({ module: { key: 'Users', value: 'UA' } })

  const { userData } = useSelector((state) => state.fetch)

  const affiliateConstant = userData?.affiliate?.parentName?.toLowerCase() === 'gofiliate' ? goffiliateDataConstant : userData?.affiliate?.parentName?.toLowerCase() === 'mapaffiliate' ? mapffiliateDataConstant : affiliateDataConstants
  useEffect(() => {
    getOldData()
  }, [state])

  useEffect(() => {
    showData && !affiliateNewData && getNewData()
  }, [showData])

  useEffect(() => {
    if (affiliateOldData) {
      const newData = Object.entries(affiliateOldData).reduce((acc, [key]) => {
        acc[key] = ''
        return acc
      }, {})
      setOldKeyData(newData)
    }
  }, [affiliateOldData])

  const getNewData = () => dispatch(getUserAffiliateNewDataStart({ isTenant, userId }))
  const getOldData = () => dispatch(getUserAffiliateOldDataStart({ isTenant, userId, startDate: formatDateYMD(state.map(a => a.startDate)), endDate: formatDateYMD(state.map(a => a.endDate)) }))

  const updateData = (data) => dispatch(updateUserAffiliateDataStart({ isTenant, data }))

  return {
    showData,
    oldKeyData,
    getOldData,
    getNewData,
    updateData,
    affiliateOldData,
    affiliateNewData,
    affiliateConstant,
    affiliateNewLoading,
    affiliateOldLoading
  }
}

export default useAffiliateForm
