import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from '../../../../components/Toast'
import { createWageringTemplateStart, getWageringTemplateDetailsStart } from '../../../../store/redux-slices/bonus'
import { getAllMasterGamesStart } from '../../../../store/redux-slices/tenantCasino'
import useDidMountEffect from '../../../../utils/useDidMountEffect'

const useCreateWegringTemplate = () => {
  const [selectedProvider, setSelectedProvider] = useState('')
  const { wageringTemplateDetail } = useSelector((state) => state.bonus)
  const { loading, masterGames: casinoGamesData } = useSelector(state => state.tenantCasino)

  const [gameContribution, setGameContribution] = useState({})
  const [customValue, setCustomValue] = useState()
  const [isClone, setIsClone] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [search, setSearch] = useState('')
  const [state, setState] = useState({ name: '', customValue: '' })
  const [contributionDefaultValue, setContributionDefaultValue] = useState({})
  const isInitialRender = useDidMountEffect()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)

  const totalPages = Math.ceil(casinoGamesData?.count / limit)

  const customValueHandler = (value) => {
    setCustomValue(value)
    setState({
      ...state,
      customValue: value
    })
    const newGameContribution = {}
    for (const key in gameContribution) {
      newGameContribution[key] = value
    }
    setGameContribution(newGameContribution)
  }

  const formSubmitHandler = (formValue) => {
    const templateData = {
      name: formValue.name,
      gameContribution: { ...contributionDefaultValue, ...gameContribution }
    }
    if (Object.keys(gameContribution).length < 1) {
      toast('Select At Least One Game', 'error')
    } else {
      dispatch(createWageringTemplateStart({ isTenant: true, templateData, navigate }))
    }
  }

  const fetchDetails = (wageringTemplateId) => {
    dispatch(getWageringTemplateDetailsStart({
      isTenant: true,
      wageringTemplateId,
      limit: 15,
      pageNo: 1,
      providerId: selectedProvider
    }))
  }

  useEffect(() => {
    if (location?.state?.isClone) {
      setIsClone(true)
      fetchDetails(location.state.wageringTemplateId)
    } else {
      setGameContribution({})
    }
  }, [location])

  useEffect(() => {
    if (location?.state?.isClone && wageringTemplateDetail) {
      setContributionDefaultValue(wageringTemplateDetail?.gameContribution)
    }
  }, [wageringTemplateDetail])

  useEffect(() => {
    dispatch(getAllMasterGamesStart({
      limit,
      pageNo: page,
      search,
      casinoCategoryId: '',
      providerId: selectedProvider
    }))
  }, [limit, page, selectedProvider])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(
            getAllMasterGamesStart({
              limit,
              pageNo: page,
              search,
              casinoCategoryId: '',
              providerId: selectedProvider
            })
          )
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  return {
    loading,
    navigate,
    selectedProvider,
    setSelectedProvider,
    gameContribution,
    casinoGamesData,
    setGameContribution,
    customValue,
    customValueHandler,
    formSubmitHandler,
    isClone,
    wageringTemplateDetail,
    state,
    setState,
    search,
    setSearch,
    setContributionDefaultValue,
    contributionDefaultValue,
    page,
    limit,
    setLimit,
    setPage,
    totalPages
  }
}

export default useCreateWegringTemplate
