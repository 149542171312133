export const tableHeaders = [
  { label: 'Id', value: 'id' },
  { label: 'Tenant', value: 'Tenant' },
  { label: 'Name', value: 'firstName' },
  { label: 'Email', value: 'email' },
  { label: 'Date Of Birth', value: 'dateOfBirth' },
  { label: 'action', value: 'action' }
]

export const banOptions = [
  { label: 'Ban Across License', value: 'ban_across_license' },
  { label: 'Ban Across Multiple Brands', value: 'ban_across_multiple_brands' }
]

export const personalInfoFields = [
  { label: 'First Name', value: 'firstName' },
  { label: 'Last Name', value: 'lastName' },
  { label: 'Email', value: 'email' }
]
