import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getAllCredentialsStart, getTenantStart } from '../../../../store/redux-slices/tenants'
import { getAdminUserDetailsStart } from '../../../../store/redux-slices/adminUser'

const useEditTenant = () => {
  const [selectedTab, setSelectedTab] = useState('tenant')
  const { tenantDetails, loading } = useSelector((state) => state.tenants)
  const { allCredentialsKey } = useSelector((state) => state.tenants)
  const { tenantId, adminId } = useParams()
  const dispatch = useDispatch()
  const { adminUserDetails } = useSelector((state) => state.adminUser)
  const navigate = useNavigate()

  const [createTenantData, setCreateTenantData] = useState({
    tenantId,
    name: '',
    domain: '',
    primaryCurrency: '',
    allowedCurrencies: [],
    themeAttr: null,
    logo: '',
    code: 'jap',
    languageName: 'English',
    themeName: '',
    themeMode: '',
    themeId: '',
    primaryColor: null,
    secondaryColor: null,
    value: null,
    description: null,
    allowedLanguages: []
    // providerIds: []
  })

  const setMyData = () => {
    if (tenantDetails?.name && allCredentialsKey) {
      const allKeys = allCredentialsKey.map((key) => key.name)
      const data = Object.fromEntries(allKeys.map((k) => [k, '']))
      const keys = tenantDetails.credentials.map((cred) => cred.key)
      let value = tenantDetails.credentials.map((cred) => cred.value)
      let description = tenantDetails.credentials.map(
        (cred) => cred.description
      )
      value = Object.fromEntries(keys.map((k, index) => [k, value[index]]))
      description = Object.fromEntries(
        keys.map((k, index) => [k, description[index]])
      )
      value = { ...data, ...value }
      description = { ...data, ...description }

      setCreateTenantData({
        ...createTenantData,
        name: tenantDetails?.name,
        domain: tenantDetails?.domain,
        primaryCurrency: tenantDetails?.primaryCurrency,
        // allowedCurrencies: tenantDetails?.tenantConfig?.allowedCurrencies,
        // allowedLanguages: tenantDetails?.tenantConfig?.allowedLanguages,
        logo: tenantDetails?.tenantConfig?.logoUrl,
        value,
        description
        // providerIds: tenantDetails?.tenantConfig?.gameProviders,
        // aggregatorIds: tenantDetails?.tenantConfig?.aggregatorIds ? tenantDetails?.tenantConfig?.aggregatorIds?.map(val => parseInt(val)) : []
      })
    }
  }

  useEffect(() => {
    dispatch(getAdminUserDetailsStart({ adminUserId: adminId, isAggregatorDetail: true }))
  }, [adminId])

  useEffect(() => {
    const keys = allCredentialsKey.map((key) => key.name)
    const data = Object.fromEntries(keys.map((k) => [k, '']))
    setCreateTenantData({
      ...createTenantData,
      value: { ...data, ...createTenantData.value },
      description: { ...data, ...createTenantData.description }
    })
  }, [allCredentialsKey])

  useEffect(() => {
    dispatch(getAllCredentialsStart())
  }, [])

  useEffect(() => {
    dispatch(getTenantStart({ tenantId }))
  }, [tenantId])

  useEffect(() => {
    if (tenantDetails?.tenantId === parseInt(tenantId)) {
      setMyData()
    }
  }, [tenantDetails, tenantId])

  return {
    selectedTab,
    setSelectedTab,
    tenantDetails,
    allCredentialsKey,
    tenantId,
    createTenantData,
    setCreateTenantData,
    loading,
    adminUserDetails,
    navigate,
    setMyData
  }
}

export default useEditTenant
