import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllCredStart } from '../../../../store/redux-slices/tenantcredentials'

const useCredentialsListing = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { tenantDetails } = useSelector((state) => state.login)
  const { data, loading } = useSelector((state) => state.tenantcredentials)

  useEffect(() => {
    dispatch(getAllCredStart())
  }, [])

  return {
    navigate,
    data,
    loading,
    tenantDetails
  }
}

export default useCredentialsListing
