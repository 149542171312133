import React from 'react'
import { Card } from 'react-bootstrap'
import './CasinoTransaction.scss'

export const TotalSumAmount = ({
  transactions,
  loading = false,
  // userDetail = false,
  bannedGames,
  showBannedGameAmount,
  userData,
  showTotalAmount
}) => {
  return (
    <>
      {showBannedGameAmount && (
        <Card body className='w-100 text-center card-container'>
          <div>
            <small> TOTAL BANNED GAMES WAGERING </small>
          </div>
          <div className='header-font-weight'>
            {typeof bannedGames?.totalWageringAmount === 'number' && !loading && userData
              ? (
                <div className={(bannedGames.totalWageringAmount > 0) ? 'text-success' : 'text-danger'}>
                  {`${bannedGames.totalWageringAmount.toFixed(2)} ${userData?.currencyCode || 'EUR'}`}
                </div>
                )
              : (
                <small className='text-danger text-center'>
                  No data found
                </small>
                )}
          </div>
        </Card>
      )}
      {!showBannedGameAmount && (
        <div className='total-cards d-flex gap-2'>
          <Card body className='w-100 text-center card-container'>
            <div>
              <p> Total Bet Amount </p>
            </div>
            <div className='header-font-weight'>
              {typeof transactions.totalBetAmount === 'number' && !loading && (userData || showTotalAmount)
                ? (
                  <div className={`amount ${(transactions.totalBetAmount > 0) ? 'text-success' : 'text-danger'}`}>
                    {`${transactions.totalBetAmount.toFixed(2)} ${userData?.currencyCode || 'EUR'}`}
                  </div>
                  )
                : (
                  <small className='text-danger text-center'>
                    No data found
                  </small>
                  )}
            </div>
          </Card>
          <Card body className='w-100 text-center card-container'>
            <div>
              <p> Total Win Amount </p>
            </div>
            <div className='header-font-weight'>
              {typeof transactions.totalWinAmount === 'number' && !loading && (userData || showTotalAmount)
                ? (
                  <div className={`amount ${(transactions.totalWinAmount > 0) ? 'text-success' : 'text-danger'}`}>
                    {`${transactions.totalWinAmount.toFixed(2)} ${userData?.currencyCode || 'EUR'}`}
                  </div>
                  )
                : (
                  <small className='text-danger text-center'>
                    No data found
                  </small>
                  )}
            </div>
          </Card>
          <Card body className='w-100 text-center card-container'>
            <div>
              <p> Total Bonus GGR </p>
            </div>
            <div className='header-font-weight'>
              {typeof transactions.totalBonusGGR === 'number' && !loading && (userData || showTotalAmount)
                ? (
                  <div className={`amount ${(transactions.totalBonusGGR > 0) ? 'text-success' : 'text-danger'}`}>
                    {`${transactions.totalBonusGGR.toFixed(2)} ${userData?.currencyCode || 'EUR'}`}
                  </div>
                  )
                : (
                  <small className='text-danger text-center'>
                    No data found
                  </small>
                  )}
            </div>
          </Card>
          <Card body className='w-100 text-center card-container'>
            <div>
              <p> Total Cash GGR </p>
            </div>
            <div className='header-font-weight'>
              {typeof transactions.totalCashGGR === 'number' && !loading && (userData || showTotalAmount)
                ? (
                  <div className={`amount ${(transactions.totalCashGGR > 0) ? 'text-success' : 'text-danger'}`}>
                    {`${transactions.totalCashGGR.toFixed(2)} ${userData?.currencyCode || 'EUR'}`}
                  </div>
                  )
                : (
                  <small className='text-danger text-center'>
                    No data found
                  </small>
                  )}
            </div>
          </Card>
        </div>
      )}
    </>
  )
}
