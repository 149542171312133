import React from 'react'
import ActionTypeSelect from '.'

const TransactionSelects = ({ transactionReportingPaymentCategories, paymentMethod, setPaymentMethod, placeholder }) => {
  return (
    <>
      {transactionReportingPaymentCategories &&
        <ActionTypeSelect
          selectedAction={paymentMethod}
          selectOptions={transactionReportingPaymentCategories?.reduce((result, currentValue) => {
            currentValue !== null && result.push({ label: currentValue, value: currentValue })
            return result
          }, [])}
          setSelectedAction={setPaymentMethod}
          placeholder={placeholder}
        />}
    </>
  )
}

export default TransactionSelects
