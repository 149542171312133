import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGroupsStart } from '../../../store/redux-slices/admins'
import useCheckPermission from '../../../utils/checkPermission'
import { useNavigate } from 'react-router-dom'

/**
 * @typedef SelectedParentGroupType
 *
 * @property  {Number}    id
 * @property  {String}    parentGroupName
 * @property  {Object[]}  groupDetails
 */

const useGroups = () => {
  const { groupsList, groupLoading } = useSelector(state => state.admins)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isTenant = window.location.pathname.includes('tenant')
  const { isHidden } = useCheckPermission()
  /**
   * @type {[Boolean, Function]}
   */
  const [parentGroupFormVisible, setParentGroupFormVisible] = useState(false)
  /**
   * @type {[SelectedParentGroupType | null, Function]}
   */
  const [selectedParentGroup, setSelectedParentGroup] = useState(null)
  const getGroups = useCallback(() => dispatch(getGroupsStart({ isTenant })), [getGroupsStart, isTenant])

  useEffect(() => {
    getGroups()
  }, [getGroups])

  return {
    groupsList,
    isHidden,
    groupLoading,
    navigate,
    isTenant,
    parentGroupFormVisible,
    setParentGroupFormVisible,
    selectedParentGroup,
    setSelectedParentGroup
  }
}

export default useGroups
