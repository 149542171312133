import { Table } from '@themesberg/react-bootstrap'
import React from 'react'
import { currencySymbols } from '../DemographicWIthMap/demographicConstant'
import { internationalNumberFormatter } from '../../utils/helpers'

export default ({ currencyWiseWithdrawal, withdrawalTotalAmount }) => {
  return (
    <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
      <thead className='thead-dark'>
        <tr>
          <th>Portal</th>
          <th>Currency</th>
          <th>Pending Withdrawals</th>
          <th>Pending Withdrawals in EUR</th>
        </tr>
      </thead>

      <tbody>
        {currencyWiseWithdrawal && currencyWiseWithdrawal?.map(({
          data, portalName, primaryCurrencyAmount
        }, index) => {
          return (
            <tr key={index}>
              <td>{portalName}</td>
              <td>
                {data?.currency}
              </td>
              <td>{currencySymbols[data?.currency]}{' '}{internationalNumberFormatter(data?.amount?.toFixed(2))}</td>
              <td>{currencySymbols.EUR}{' '}{internationalNumberFormatter(primaryCurrencyAmount?.toFixed(2))}</td>
            </tr>
          )
        })}
        {currencyWiseWithdrawal?.length >= 1 &&
          <tr>
            <td colSpan={3}><b>Total Amount</b></td>
            <td colSpan={1}>
              <div className={`amount ${(withdrawalTotalAmount > 0) ? 'text-success' : 'text-danger'} `}>
                <b>{`${currencySymbols.EUR} ${' '} ${internationalNumberFormatter(withdrawalTotalAmount?.toFixed(2))} `}</b>
              </div>
            </td>
          </tr>}
      </tbody>

      <tbody>
        <tr>
          {
            currencyWiseWithdrawal?.length < 1 && (
              <td className='text-danger' colSpan={4}>No Data Found.</td>
            )
          }
        </tr>
      </tbody>
    </Table>
  )
}
