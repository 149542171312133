import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateCasinoStatusStart,
  createCasinoProviderStart,
  updateCasinoProviderStart,
  getAllCasinoProvidersStart,
  getSuperAdminAggregatorsStart
} from '../../../store/redux-slices/superAdminCasinoManagement'
import useDidMountEffect from '../../../utils/useDidMountEffect'
import { resetRestrictedCountriesStart } from '../../../store/redux-slices/fetchData'
import { isTenant } from '../../../utils/constants'

const useProviderListing = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [name, setName] = useState()
  const [data, setData] = useState()
  const [page, setPage] = useState(1)
  const [type, setType] = useState('')
  const [limit, setLimit] = useState(15)
  const [status, setStatus] = useState()
  const [show, setShow] = useState(false)
  const [search, setSearch] = useState('')
  const isInitialRender = useDidMountEffect()
  const [providerId, setProviderId] = useState()
  const [aggregatorId, setAggregatorId] = useState('')
  const [statusShow, setStatusShow] = useState(false)

  const { casinoProvidersData, aggregators, loading } = useSelector((state) => state.superAdminCasino)
  const { allAggregators } = useSelector((state) => state.tenantCasino)
  const totalPages = Math.ceil(casinoProvidersData?.count / limit)

  const handleClose = () => setShow(false)
  const handleShow = (type, data) => {
    setType(type)
    setData(data)
    setShow(true)
  }

  const handleStatusShow = (id, status, myName) => {
    setProviderId(id)
    setStatus(!status)
    setStatusShow(true)
    setName(myName)
  }

  const handleYes = () => {
    dispatch(
      updateCasinoStatusStart({
        data: isTenant
          ? {
              code: 'TENANT_CASINO_PROVIDER',
              tenantCasinoProviderId: providerId,
              status
            }
          : {
              code: 'CASINO_PROVIDER',
              masterCasinoProviderId: providerId,
              status
            },
        limit,
        pageNo: page,
        search,
        aggregatorId
      })
    )
    setTimeout(() => {
      setStatusShow(false)
    }, 500)
  }

  useEffect(() => {
    dispatch(getAllCasinoProvidersStart({ limit, pageNo: page, search, aggregatorId }))
    !isTenant && dispatch(resetRestrictedCountriesStart())
  }, [limit, page, aggregatorId])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(getAllCasinoProvidersStart({ limit, pageNo: page, search, aggregatorId }))
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    !isTenant && dispatch(getSuperAdminAggregatorsStart({ limit: '', pageNo: '', search: '' }))
  }, [])

  const updateProvider = (data, { masterCasinoProviderId, tenantCasinoProviderId }) =>
    dispatch(
      updateCasinoProviderStart({
        data: isTenant
          ? {
              ...data,
              tenantCasinoProviderId
            }
          : {
              ...data,
              masterCasinoProviderId
            },
        limit,
        pageNo: page,
        search,
        aggregatorId
      }))

  const createProvider = (data) =>
    dispatch(
      createCasinoProviderStart({
        data,
        limit,
        pageNo: page,
        search,
        aggregatorId
      })
    )

  return {
    page,
    show,
    data,
    type,
    name,
    limit,
    status,
    search,
    loading,
    setPage,
    setLimit,
    navigate,
    setSearch,
    handleYes,
    handleShow,
    aggregatorId,
    statusShow,
    totalPages,
    aggregators,
    handleClose,
    setStatusShow,
    allAggregators,
    updateProvider,
    createProvider,
    setAggregatorId,
    handleStatusShow,
    casinoProvidersData
  }
}

export default useProviderListing
