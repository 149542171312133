import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { tenantLoginStart } from '../../../store/redux-slices/login'
import { getItem, getLoginToken, removeLoginToken } from '../../../utils/storageUtils'
import { TenantRoutes } from '../../../routes'

const useTenantSignin = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading, twoStepsEnabled } = useSelector((state) => state.login)
  const [showPassword, setShowPassword] = useState(false)
  const [loginData, setLoginData] = useState({ user: '', password: '' })

  useEffect(() => {
    if (getLoginToken() && getItem('role') === 'Admin') {
      navigate(TenantRoutes.Dashboard)
    } else {
      removeLoginToken()
    }
  }, [])

  const handleSignIn = (data) => {
    setLoginData({ ...data })
    dispatch(tenantLoginStart({ ...data, navigate }))
  }

  return {
    loading,
    showPassword,
    setShowPassword,
    navigate,
    twoStepsEnabled,
    loginData,
    handleSignIn
  }
}

export default useTenantSignin
