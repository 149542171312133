import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const useAddAggregator = (data, setData) => {
  const dispatch = useDispatch()
  const { tenantDetails } = useSelector(state => state.login)
  const { aggregators } = useSelector((state) => state.superAdminCasino)
  const { allSystemProviders, allAggregators } = useSelector(state => state.tenantCasino)

  const getAggregatorName = (id) =>
    allAggregators?.filter(({ masterGameAggregatorId }) => masterGameAggregatorId === id)?.[0]?.name

  const filteredProviders = useMemo(() => {
    if (allSystemProviders && data?.aggregatorIds) {
      return allSystemProviders?.filter(({ masterGameAggregatorId }) => data?.aggregatorIds?.includes(masterGameAggregatorId))
    }
  }, [allSystemProviders, data?.aggregatorIds])

  const myAggregators = useMemo(() =>
    aggregators?.rows?.map(({ masterGameAggregatorId }) => parseInt(masterGameAggregatorId)), [aggregators])

  useEffect(() => {
    return () => setData(null)
  }, [])

  return {
    dispatch,
    aggregators,
    myAggregators,
    tenantDetails,
    filteredProviders,
    getAggregatorName,
    allSystemProviders
  }
}

export default useAddAggregator
