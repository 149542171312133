import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkIsTenant } from '../../../../utils/constants'
import { getHub88PrepaidsStart } from '../../../../store/redux-slices/fetchData'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { getAllCurrenciesStart } from '../../../../store/redux-slices/currencies'
import { getItem } from '../../../../utils/storageUtils'

const useHub88PrepaidList = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(15)
  const [search, setSearch] = useState('')
  const isInitialRender = useDidMountEffect()
  const [selectedPortal, setSelectedPortal] = useState('')
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedGameIds, setSelectedGameIds] = useState([])
  const [selectedCurrency, setSelectedCurrency] = useState([])
  const [selectedGamesArray, setSelectedGamesArray] = useState([])
  const { allCurrencies } = useSelector((state) => state.currencies)
  const { loading, hub88PrepaidListData } = useSelector(state => state.fetch)
  const { allowedCurrencies } = useSelector((state) => state.login.tenantDetails?.tenantConfig || {})

  useEffect(() => {
    !checkIsTenant() && dispatch(getAllCurrenciesStart({ limit: '', pageNo: '' }))
  }, [])

  const getData = () => (checkIsTenant() || selectedPortal) &&
  dispatch(getHub88PrepaidsStart({ tenantId: getItem('tenant-id') || selectedPortal || '', currency: selectedCurrency, gameIdentifier: search, limit, pageNo: page }))

  useEffect(() => {
    getData()
  }, [selectedPortal, limit])

  useEffect(() => {
    !isInitialRender && getData()
  }, [page, selectedCurrency])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          getData()
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  return {
    page,
    limit,
    search,
    setPage,
    loading,
    setLimit,
    setSearch,
    allCurrencies,
    selectedClient,
    selectedPortal,
    selectedGameIds,
    selectedCurrency,
    setSelectedClient,
    allowedCurrencies,
    setSelectedPortal,
    setSelectedGameIds,
    selectedGamesArray,
    setSelectedCurrency,
    hub88PrepaidListData,
    setSelectedGamesArray
  }
}

export default useHub88PrepaidList
