import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOwnerManagersStart } from '../../../../store/redux-slices/tenants'
import { useNavigate, useParams } from 'react-router-dom'

const useManagersList = () => {
  const tableHeaders = [
    { label: 'ID', value: 'adminUserId' },
    { label: 'Email', value: 'email' },
    { label: 'Full name', value: 'firstName' },
    { label: 'Phone Number', value: 'email' },
    { label: 'Role', value: 'email' },
    { label: 'Group', value: 'email' },
    { label: 'Status', value: 'email' },
    { label: 'Action', value: 'email' }
  ]

  const { ownerId } = useParams()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [limit, setLimit] = useState(15)
  const [selectedClient, setSelectedClient] = useState(ownerId)
  const [page, setPage] = useState(1)
  const { loading, TOManagersData } = useSelector(state => state.tenants)
  const totalPages = Math.ceil(TOManagersData?.count / limit)

  useEffect(() => {
    dispatch(getOwnerManagersStart({
      limit,
      pageNo: page,
      ownerId: selectedClient
    }))
  }, [limit, page, selectedClient])

  useEffect(() => {
    setSelectedClient(ownerId)
  }, [ownerId])

  return {
    tableHeaders,
    loading,
    TOManagersData,
    limit,
    setLimit,
    page,
    setPage,
    totalPages,
    navigate,
    selectedClient,
    setSelectedClient
  }
}

export default useManagersList
