import { takeLatest, put } from 'redux-saga/effects'
import {
  getPaymentAggregators,
  getPaymentProvider,
  getProviderDetails,
  configProviderSettings,
  reOrderPaymentProviders,
  superAdminViewToggleStatus,
  getPaymentCategories,
  orderPaymentCategories,
  getTransactionPaymentCategories,
  getTransactionReportingPaymentCategories,
  tenantViewToggleStatus,
  addPaymentMethod,
  getPaymentGroups,
  restrictPlayerMethods
} from '../../utils/apiCalls'

import {
  getPaymentAggregatorsStart,
  getPaymentAggregatorsSuccess,
  getPaymentAggregatorsFailure,
  getPaymentProviderStart,
  getPaymentProviderSuccess,
  getPaymentProviderFailure,
  getProviderDetailsStart,
  getProviderDetailsSuccess,
  getProviderDetailsFailure,
  configProviderSettingsStart,
  configProviderSettingsComplete,
  updatePaymentProviderStatusStart,
  updatePaymentProviderStatusComplete,
  reOrderPaymentProvidersStart,
  reOrderPaymentProvidersComplete,
  getPaymentCategoriesStart,
  getPaymentCategoriesSuccess,
  getPaymentCategoriesFailure,
  reOrderPaymentCategoriesStart,
  reOrderPaymentCategoriesComplete,
  getTransactionPaymentCategoriesStart,
  getTransactionPaymentCategoriesSuccess,
  getTransactionPaymentCategoriesFailure,
  getTransactionReportingPaymentCategoriesStart,
  getTransactionReportingPaymentCategoriesSuccess,
  getTransactionReportingPaymentCategoriesFailure,
  addPaymentMethodStart,
  addPaymentMethodComplete,
  getPaymentGroupsStart,
  getPaymentGroupsComplete,
  restrictPlayerMethodsStart,
  restrictPlayerMethodsComplete
} from '../redux-slices/payment'
import { toast } from '../../components/Toast'
import { serialize } from 'object-to-formdata'

export default function * paymentWatcher () {
  yield takeLatest(getPaymentAggregatorsStart.type, getPaymentAggregatorsWorker)
  yield takeLatest(getPaymentProviderStart.type, getPaymentProviderWorker)
  yield takeLatest(getProviderDetailsStart.type, getProviderDetailsWorker)
  yield takeLatest(configProviderSettingsStart.type, configProviderSettingsWorker)
  yield takeLatest(updatePaymentProviderStatusStart.type, updatePaymentProviderStatusWorker)
  yield takeLatest(reOrderPaymentProvidersStart.type, reOrderPaymentProvidersWorker)
  yield takeLatest(getPaymentCategoriesStart.type, getPaymentCategoriesWorker)
  yield takeLatest(reOrderPaymentCategoriesStart.type, orderPaymentCategoriesWorker)
  yield takeLatest(getTransactionPaymentCategoriesStart.type, getTransactionPaymentCategoriesWorker)
  yield takeLatest(getTransactionReportingPaymentCategoriesStart.type, getTransactionReportingPaymentCategoriesWorker)
  yield takeLatest(addPaymentMethodStart.type, addPaymentMethodWorker)
  yield takeLatest(getPaymentGroupsStart.type, getPaymentGroupsWorker)
  yield takeLatest(restrictPlayerMethodsStart.type, restrictPlayerMethodsWorker)
}

function * getPaymentAggregatorsWorker (action) {
  try {
    const { isTenant, tenantId } = action && action.payload

    const { data } = yield getPaymentAggregators({ isTenant, tenantId })

    yield put(getPaymentAggregatorsSuccess(data?.data?.aggregatorGroups))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getPaymentAggregatorsFailure(e.response.data.message))
  }
}

function * getPaymentProviderWorker (action) {
  try {
    const { isTenant, paymentType = '', aggregator = '', tenant = '', group = '', status = '', tenantIds = '', category = '', adminId = '', tenantId = '', reorder = '', userId = '', search = '' } = action && action.payload

    const { data } = yield getPaymentProvider({
      isTenant, paymentType, aggregator, tenant, group, status, tenantIds, category, adminId, tenantId, reorder, userId, search
    })

    yield put(getPaymentProviderSuccess(data?.data?.paymentMethods))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getPaymentProviderFailure(e.response.data.message))
  }
}

function * getProviderDetailsWorker (action) {
  try {
    const { isTenant, providerId, tenantId, adminId } = action && action.payload

    const { data } = yield getProviderDetails({ isTenant, providerId, tenantId, adminId })

    yield put(getProviderDetailsSuccess(data?.data?.provider))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getProviderDetailsFailure(e.response.data.message))
  }
}

function * configProviderSettingsWorker (action) {
  try {
    let { data, isTenant, providerId, tenantId, adminId } = action && action.payload

    if (data?.key !== 'restrict') data = serialize(data, { allowEmptyArrays: true })

    yield configProviderSettings({ data, isTenant })

    yield put(configProviderSettingsComplete())
    yield put(getProviderDetailsStart({ isTenant, providerId, tenantId, adminId }))

    yield toast('Settings Updated Successfully', 'success')
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(configProviderSettingsComplete(e.response.data.message))
  }
}

function * updatePaymentProviderStatusWorker (action) {
  try {
    const { data, isTenant, status, category, paymentType, adminId, tenantId } =
        action && action.payload

    yield isTenant ? tenantViewToggleStatus(data) : superAdminViewToggleStatus(data)

    yield put(updatePaymentProviderStatusComplete())

    yield toast('Payment Provider Status Updated Successfully', 'success')
    yield put(getPaymentProviderStart({
      isTenant,
      paymentType,
      aggregator: '',
      tenant: '',
      group: '',
      status,
      tenantIds: [],
      category,
      adminId,
      tenantId
    }))
  } catch (e) {
    yield put(updatePaymentProviderStatusComplete())

    yield toast(e.response.data.message, 'error')
  }
}

function * reOrderPaymentProvidersWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    yield reOrderPaymentProviders({ data, isTenant })

    yield put(reOrderPaymentProvidersComplete())

    yield toast('Payment Provider ReOrdered Successfully', 'success')
  } catch (e) {
    const { isTenant, adminId, tenantId } = action && action.payload
    yield put(reOrderPaymentProvidersComplete())

    yield toast(e.response.data.message, 'error')

    yield put(getPaymentProviderStart({
      isTenant,
      paymentType: '',
      aggregator: '',
      tenant: '',
      group: '',
      status: '',
      tenantIds: [],
      adminId,
      tenantId
    }))
  }
}

function * getPaymentCategoriesWorker (action) {
  try {
    const { paymentType, isTenant, adminId, tenantId } = action && action.payload

    const { data } = yield getPaymentCategories({ paymentType, isTenant, adminId, tenantId })

    yield put(getPaymentCategoriesSuccess(data?.data?.categories))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getPaymentCategoriesFailure(e?.response?.data?.message))
  }
}

function * orderPaymentCategoriesWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    yield orderPaymentCategories({ data, isTenant })

    yield put(reOrderPaymentCategoriesComplete())

    yield toast('Payment Categories Re-Ordered Successfully', 'success')
  } catch (e) {
    const { data, isTenant, adminId, tenantId } = action && action.payload

    yield put(reOrderPaymentCategoriesComplete())

    yield toast(e?.response?.data?.message, 'error')
    yield put(getPaymentCategoriesStart({ paymentType: data?.paymentType, isTenant, adminId, tenantId }))
  }
}

function * getTransactionPaymentCategoriesWorker (action) {
  try {
    const { isTenant, adminId = '', tenantId = '' } = action && action.payload

    const { data } = yield getTransactionPaymentCategories({ isTenant, adminId, tenantId })

    yield put(getTransactionPaymentCategoriesSuccess(data?.data?.providers))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getTransactionPaymentCategoriesFailure(e?.response?.data?.message))
  }
}

function * getTransactionReportingPaymentCategoriesWorker (action) {
  try {
    const { isTenant, adminId = '', tenantId = '' } = action && action.payload

    const { data } = yield getTransactionReportingPaymentCategories({ isTenant, adminId, tenantId })

    yield put(getTransactionReportingPaymentCategoriesSuccess(data?.data?.methods))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getTransactionReportingPaymentCategoriesFailure(e?.response?.data?.message))
  }
}

function * addPaymentMethodWorker (action) {
  try {
    const { data, isTenant, setShow, resetForm } = action && action.payload

    yield addPaymentMethod({ data, isTenant })

    yield put(addPaymentMethodComplete())

    yield toast('Payment Method Created Successfully', 'success')
    yield resetForm({ formValues: '' })
    yield setShow(false)
  } catch (e) {
    yield put(addPaymentMethodComplete())

    yield toast(e?.response?.data?.message, 'error')
  }
}

function * getPaymentGroupsWorker (action) {
  try {
    const { isTenant } = action && action.payload

    const { data } = yield getPaymentGroups({ isTenant })

    yield put(getPaymentGroupsComplete(data?.data?.groups))
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(getPaymentGroupsComplete())
  }
}

function * restrictPlayerMethodsWorker (action) {
  try {
    const { data, isTenant, userId, tenantId, group, type, status, category, search, aggregator } = action && action.payload

    yield restrictPlayerMethods({ data, isTenant })

    yield put(restrictPlayerMethodsComplete())
    yield put(getPaymentProviderStart({ isTenant, userId, tenantId, group, type, status, category, search, aggregator }))

    yield toast('Settings Updated Successfully', 'success')
  } catch (e) {
    yield toast(e.response.data.message, 'error')

    yield put(restrictPlayerMethodsComplete(e.response.data.message))
  }
}
