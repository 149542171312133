import React from 'react'
import './demographicReport.css'
import Highcharts from 'highcharts'
import HighchartsMap from 'highcharts/modules/map'
import { countryFilter } from '../../utils/countryFilter'
import { Row, Col, Table } from '@themesberg/react-bootstrap'
import { currencySymbols, tableHeaders } from './demographicConstant'
import sortArrowIcon from '../SortArrowsIcon'
import { internationalNumberFormatter } from '../../utils/helpers'

const DemographicWithMap = ({ demogData, sortBy, setSortBy, hover, setHover }) => {
  HighchartsMap(Highcharts)

  return (
    <>
      <Row>
        {/* <Col sm={6}>
          <BarChart series={demogData || []} />
        </Col> */}
        <Col sm={12}>
          <div style={{ overflow: 'auto', maxHeight: '400px ' }}>
            <Table hover size='sm' className='text-center mt-4 fixTableHead'>
              <thead className='thead-dark'>
                <tr>
                  {tableHeaders.map((h, idx) => (
                    <th
                      key={`T-table_heading ${idx}`}
                      className={`${h.value === 'country' ? 'text-left' : ''}`}
                    >
                      {h.label}
                      {sortArrowIcon(h.label, sortBy, setSortBy, hover, setHover, 'demographicReport')}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {demogData && demogData.map(({ depositAttempted, country_code: countryCode, signUpCount, newDepositors, acquisition, reDepositors, totalDepositors, totalDeposit, failedDeposits, successDeposits }) => {
                  const { countryName } = countryFilter(countryCode)
                  return (
                    <tr key={`demo-g-data ${countryCode}`}>
                      <td className='text-left'>{countryName || 'NA'}</td>
                      <td>{internationalNumberFormatter(signUpCount) || '0'}</td>
                      <td>{internationalNumberFormatter(newDepositors) || '0'}</td>
                      <td>{internationalNumberFormatter(isNaN(acquisition) ? '0.00' : parseFloat(acquisition)?.toFixed(2))} %</td>
                      <td>{internationalNumberFormatter(reDepositors) || '0'}</td>
                      <td>{internationalNumberFormatter(totalDepositors) || '0'}</td>
                      <td>{internationalNumberFormatter(totalDeposit) || '00'}&nbsp;{currencySymbols.EUR}</td>
                      <td>{(depositAttempted)} / {successDeposits} / {failedDeposits}</td>
                    </tr>
                  )
                })}

                {demogData.length === 0 && (
                  <tr>
                    <td colSpan={8} className='text-danger text-center'>
                      No data found
                    </td>
                  </tr>
                )}

              </tbody>
            </Table>
          </div>
        </Col>
      </Row>

    </>
  )
}

export default DemographicWithMap
