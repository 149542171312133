import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isTenant } from '../../../../utils/constants'
import useCheckPermission from '../../../../utils/checkPermission'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getPaymentProviderStart, reOrderPaymentProvidersStart } from '../../../../store/redux-slices/payment'

const useProviderReorder = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [state, setState] = useState([])
  const { adminId, tenantId } = useParams()
  const { isHidden } = useCheckPermission()
  const [type, setType] = useState('deposit')
  const { state: locationState } = useLocation()
  const [reorderPayment, setReorderPayment] = useState(false)
  const { paymentProviders, providerLoading } = useSelector(state => state.payment)

  useEffect(() => {
    fetchProviders()
  }, [type])

  const fetchProviders = () => {
    dispatch(getPaymentProviderStart({
      isTenant,
      paymentType: type,
      aggregator: '',
      tenant: true,
      group: '',
      status: '',
      tenantIds: [],
      adminId,
      tenantId,
      reorder: true
    }))
  }

  useEffect(() => {
    if (paymentProviders?.length) {
      setState(paymentProviders)
    }
  }, [paymentProviders, type])

  const reorder = (providers, startIndex, endIndex) => {
    const result = Array.from(providers)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const rows = reorder(
      state,
      result.source.index,
      result.destination.index
    )
    setState(rows)
  }

  const handleSave = () => {
    const row = []
    state.map(list => row.push(list?.paymentProviderId))
    dispatch(reOrderPaymentProvidersStart({
      data: {
        order: row,
        paymentType: type,
        tenantId,
        adminId
      },
      isTenant,
      adminId,
      tenantId
    }))
    setReorderPayment(false)
  }

  return {
    type,
    state,
    adminId,
    setType,
    isTenant,
    tenantId,
    navigate,
    isHidden,
    onDragEnd,
    handleSave,
    locationState,
    reorderPayment,
    providerLoading,
    setReorderPayment
  }
}

export default useProviderReorder
