import { Form as BForm } from '@themesberg/react-bootstrap'
import React from 'react'
class Otpinput extends React.Component {
  constructor (props) {
    super(props)
    this.state = { otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '' }
    this.handleChange = this.handleChange.bind(this)
  }

  changeHandler () {
    const newObj = { ...this.state }

    let token = ''

    Object.keys(newObj)
      .flatMap(function (key) {
        token += newObj[key]
        return null
      })
    this.props.onChange(token)
  }

  handleChange (value1, event) {
    this.setState({ [value1]: event.target.value }, this.changeHandler)
  }

  inputfocus = (elmnt) => {
    if (elmnt.key === 'Delete' || elmnt.key === 'Backspace') {
      const next = elmnt.target.tabIndex - 2
      if (next > -1) {
        elmnt.target.form.elements[next].focus()
      }
    } else if (elmnt.target.value) {
      const next = elmnt.target.tabIndex
      if (next < 6) {
        elmnt.target.form.elements[next].focus()
      }
    }
  }

  render () {
    return (
      <form autoComplete='off' className='d-flex justify-content-between align-items-center text-center'>

        <BForm.Control
          name='otp1'
          type='text'
          className='otpInput text-center'
          onChange={e => this.handleChange('otp1', e)}
          style={{ maxWidth: '50px' }}
          tabIndex='1' maxLength='1' onKeyUp={e => this.inputfocus(e)}
        />
        <span>-</span>
        <BForm.Control
          name='otp2'
          type='text'
          className='otpInput text-center'
          style={{ maxWidth: '50px' }}
          onChange={e => this.handleChange('otp2', e)}
          tabIndex='2' maxLength='1' onKeyUp={e => this.inputfocus(e)}
        />
        <span>-</span>
        <BForm.Control
          name='otp3'
          type='text'
          className='otpInput text-center'
          style={{ maxWidth: '50px' }}
          onChange={e => this.handleChange('otp3', e)}
          tabIndex='3' maxLength='1' onKeyUp={e => this.inputfocus(e)}
        />
        <span>-</span>
        <BForm.Control
          name='otp4'
          type='text'
          className='otpInput text-center'
          style={{ maxWidth: '50px' }}
          onChange={e => this.handleChange('otp4', e)}
          tabIndex='4' maxLength='1' onKeyUp={e => this.inputfocus(e)}
        />
        <span>-</span>
        <BForm.Control
          name='otp5'
          type='text'
          className='otpInput text-center'
          style={{ maxWidth: '50px' }}
          onChange={e => this.handleChange('otp5', e)}
          tabIndex='5' maxLength='1' onKeyUp={e => this.inputfocus(e)}
        />
        <span>-</span>
        <BForm.Control
          name='otp6'
          type='text'
          className='otpInput text-center'
          style={{ maxWidth: '50px' }}
          onChange={e => this.handleChange('otp6', e)}
          tabIndex='6' maxLength='1' onKeyUp={e => this.inputfocus(e)}
        />
      </form>
    )
  }
}

export default Otpinput
