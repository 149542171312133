import React from 'react'
import {
  Button,
  Form,
  Row,
  Col,
  Tabs,
  Tab
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt } from '@fortawesome/pro-solid-svg-icons'
import ClientFilter from '../ClientFilter'
import PortalFilter from '../PortalFilter'
import Trigger from '../OverlayTrigger'
import { PlayerListing } from '../PlayerListingTable'
import { isTenant } from '../../utils/constants'

const FlaggedPlayerListing = ({
  heading,
  setSelectedClient,
  setSelectedPortal,
  selectedClient,
  selectedPortal,
  selectedTab,
  setSelectedTab,
  tabs,
  search,
  setSearch,
  userId,
  setUserId,
  playersDetails,
  loading,
  totalPages,
  setPage,
  limit,
  setLimit,
  page,
  selfExcluded,
  type,
  rgThresholdValue,
  amlThresholdValue
}) => {
  return (
    <>
      <Row>
        <Col>
          <Row>
            <h3>{heading}</h3>
          </Row>
        </Col>

        {!isTenant && (
          <Col className='d-flex align-items-center'>
            <ClientFilter
              setSelectedClient={setSelectedClient}
              setSelectedPortal={setSelectedPortal}
              selectedClient={selectedClient}
              hasAllOptions={false}
            />

            <PortalFilter
              setSelectedPortal={setSelectedPortal}
              selectedPortal={selectedPortal}
              selectedClient={selectedClient}
              cms={selectedClient === ''}
              hasAllOptions={false}
            />
          </Col>
        )}
      </Row>
      <Row>
        {!selfExcluded && (
          <Tabs
            activeKey={selectedTab}
            onSelect={(tab) => {
              setLimit(15)
              setPage(1)
              setSelectedTab(tab)
            }}
            className='nav-light mt-1 mb-1 ms-1'
            mountOnEnter
            unmountOnExit
          >
            {tabs?.map((item) => {
              return (
                <Tab
                  eventKey={item.value}
                  title={item.label}
                  key={item.value}
                  mountOnEnter
                  disabled={isTenant ? false : !selectedPortal}
                  tabClassName='ms-3 me-3'
                />
              )
            })}
          </Tabs>
        )}
      </Row>
      <Row xs='auto'>
        <Col className='d-flex align-self-center mt-2'>
          <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}>
            Search
          </Form.Label>

          <Form.Control
            type='search'
            placeholder='Search Username, Email'
            size='sm'
            style={{ width: '230px', marginRight: '10px', maxHeight: '15px' }}
            value={search}
            onChange={(event) =>
              setSearch(
                event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
              )}
          />
        </Col>

        <Col className='d-flex mt-2'>
          <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px', minWidth: '90px' }}>
            Search By UserId
          </Form.Label>

          <Form.Control
            type='number'
            placeholder='Search UserId'
            size='sm'
            style={{ width: '230px', marginRight: '10px', maxHeight: '15px' }}
            value={userId}
            onChange={(event) =>
              setUserId(event.target.value)}
          />
        </Col>
        <Col className='d-flex mt-2'>
          <Trigger message='Reset Filters'>
            <Button
              variant='outline-success'
              className='align-self-center'
              size='sm'
              onClick={() => {
                setSearch('')
                setUserId('')
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
        </Col>
      </Row>
      <PlayerListing
        playersDetails={playersDetails}
        type={type}
        loading={loading}
        totalPages={totalPages}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
        page={page}
        selectedPortal={selectedPortal}
        selfExcluded={selfExcluded}
        setSelectedClient={setSelectedClient}
        setSelectedPortal={setSelectedPortal}
        selectedTab={selectedTab}
        rgThresholdValue={rgThresholdValue}
        amlThresholdValue={amlThresholdValue}
      />
    </>
  )
}

export default FlaggedPlayerListing
