import {
  Button,
  Col,
  Row,
  Form as BForm,
  InputGroup,
  Tabs,
  Tab
} from '@themesberg/react-bootstrap'
import { ErrorMessage, Formik } from 'formik'
import React, { useEffect } from 'react'
import Preloader from '../../../components/Preloader'
import useEditEmailTemplate from './hooks/useEditEmailTemplate'
import { emailTemplateSchema } from './templateSchema'
import Trigger from '../../../components/OverlayTrigger'
import { DeleteConfirmationModal, GalleryModal } from '../../../components/ConfirmationModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages } from '@fortawesome/pro-regular-svg-icons'
import EditEmailTemplate from '../../../components/EditEmailTemplate'
import { languageCode } from '../TenantConfigurations/Languages/constants'
import { getTenantLanguagesStart } from '../../../store/redux-slices/bonus'
import { toast } from '../../../components/Toast'

export default ({ isEdit }) => {
  const {
    emailTemplate,
    loading,
    updateTemplate,
    tenantsList,
    createTemplate,
    emailTemplateId,
    getDynamicKeysStart,
    dynamicKeys,
    dispatch,
    template,
    setTemplate,
    galleryModal,
    setGalleryModal,
    navigate,
    setIsTestTemplateModalVisible,
    isTestTemplateModalVisible,
    testEmailTemplateHandler,
    testEmail,
    setTestEmail,
    testTemplateLoading,
    isHidden,
    selectedTab,
    setSelectedTab,
    languages,
    deleteEmailTemplate,
    show,
    setShow,
    handleDeleteYes,
    emailTypes,
    resetEmail
  } = useEditEmailTemplate({ isEdit })

  useEffect(() => {
    if (isEdit && emailTemplate?.tenantId) {
      dispatch(getTenantLanguagesStart({ isTenant: true, tenantId: emailTemplate?.tenantId }))
    }
  }, [emailTemplate?.tenantId])

  return (
    <>
      {loading
        ? (
          <Preloader />
          )
        : (
          <Row>
            <Col sm={8}>
              <h3>{`Email Template${isEdit ? `: ${emailTemplate?.label}` : ''}`}</h3>
            </Col>
            <Col className='d-flex justify-content-end align-items-center'>
              <Trigger message='Gallery'>
                <Button
                  hidden={isHidden({ module: { key: 'ImageGallery', value: 'R' } })}
                  onClick={() => setGalleryModal(true)}
                  variant='outline-secondary'
                >
                  <FontAwesomeIcon icon={faImages} />
                </Button>
              </Trigger>
            </Col>
            <Formik
              enableReinitialize={emailTemplate}
              initialValues={{
                label: emailTemplate?.label || '',
                type: emailTemplate?.type || 0,
                tenantId: emailTemplate?.tenantId || ''
              }}
              validationSchema={emailTemplateSchema}
              onSubmit={(formValues) => {
                isEdit
                  ? updateTemplate({
                    data: {
                      ...formValues,
                      emailTemplateId,
                      type: parseInt(formValues.type),
                      templateCode: template,
                      language: selectedTab
                    }
                  })
                  : (selectedTab !== 'EN'
                      ? updateTemplate({
                        data: {
                          ...formValues,
                          emailTemplateId: emailTemplate?.emailTemplateId,
                          type: parseInt(formValues.type),
                          templateCode: template,
                          language: selectedTab,
                          create: true
                        }
                      })
                      : createTemplate({
                        data: {
                          ...formValues,
                          type: parseInt(formValues.type),
                          templateCode: template,
                          language: selectedTab
                        }
                      })
                    )
              }}
            >
              {({
                touched,
                errors,
                values,
                handleChange,
                handleSubmit,
                handleBlur,
                setFieldValue
              }) => (
                <BForm>
                  <Row className=' mt-3 mb-3'>
                    <Col sm={4} className='mb-3'>
                      <label
                        htmlFor='label'
                        className={
                        touched.label && errors.label ? 'text-danger' : ''
                      }
                      >
                        Template Label<span className='text-danger'> *</span>
                      </label>
                      <InputGroup
                        className={
                        touched.label && errors.label
                          ? 'border border-danger'
                          : ''
                      }
                      >
                        <BForm.Control
                          name='label'
                          placeholder='Enter Promotion Title'
                          value={values.label}
                          onInput={handleChange}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </InputGroup>
                      <ErrorMessage
                        component='div'
                        name='label'
                        className='text-danger'
                      />
                    </Col>
                    <Col sm={4} className='mb-3'>
                      <label
                        htmlFor='type'
                        className={
                        touched.type && errors.type ? 'text-danger' : ''
                      }
                      >
                        Template Type<span className='text-danger'> *</span>
                      </label>
                      <InputGroup
                        className={
                        touched.type && errors.type
                          ? 'border border-danger'
                          : ''
                      }
                      >
                        <BForm.Select
                          name='type'
                          onInput={handleChange}
                          onBlur={handleBlur}
                          disabled={isEdit}
                          onChange={(e) => {
                            dispatch(
                              getDynamicKeysStart({
                                type: e.target.value,
                                isTenant: true,
                                emailTypes
                              })
                            )
                            handleChange(e)
                            emailTemplate && setFieldValue('label', '')
                            emailTemplate && resetEmail()
                            setSelectedTab('EN')
                          }}
                          value={values.type}
                        >
                          <option value='' disabled key=''>
                            Select Template Type
                          </option>
                          {emailTypes &&
                          Object.keys(emailTypes?.emailTypes?.INT_TO_VALUE).map((item) => {
                            return (
                              <option
                                key={`type ${item}`}
                                value={item}
                              >
                                {emailTypes?.emailTypes?.INT_TO_VALUE?.[item]}
                              </option>
                            )
                          })}
                        </BForm.Select>
                      </InputGroup>
                      <ErrorMessage
                        component='div'
                        name='type'
                        className='text-danger'
                      />
                    </Col>
                    <Col sm={4} className='mb-3'>
                      <label
                        htmlFor='tenantId'
                        className={
                        touched.type && errors.type ? 'text-danger' : ''
                      }
                      >
                        Portal <span className='text-danger'> *</span>
                      </label>
                      <InputGroup
                        className={
                        touched.tenantId && errors.tenantId
                          ? 'border border-danger'
                          : ''
                      }
                      >
                        <BForm.Select
                          name='tenantId'
                          onInput={handleChange}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e)
                            emailTemplate && resetEmail()
                            setSelectedTab('EN')
                            dispatch(getTenantLanguagesStart({ isTenant: true, tenantId: e.target.value }))
                          }}
                          value={values.tenantId}
                          disabled={isEdit}
                        >
                          <option value='' disabled key=''>
                            Select Tenant
                          </option>
                          {tenantsList?.rows.map((item) => {
                            return (
                              <option
                                key={`tenant ${item.domain}`}
                                value={item.tenantId}
                              >
                                {`${item.name} (${item.domain})`}
                              </option>
                            )
                          })}
                        </BForm.Select>
                      </InputGroup>
                      <ErrorMessage
                        component='div'
                        name='tenantId'
                        className='text-danger'
                      />
                    </Col>
                  </Row>
                  <div>
                    <Tabs
                      activeKey={selectedTab}
                      onSelect={(tab) => {
                        if (isEdit && emailTemplate?.templateCode?.EN !== undefined) {
                          setSelectedTab(tab)
                        } else {
                          toast('You must enter data for English language and Submit before switching to another language ', 'error')
                        }
                      }}
                      className='nav-light'
                    >
                      <Tab
                        eventKey='EN'
                        title={
                          <Trigger message='English'>
                            <span>EN</span>
                          </Trigger>
                         }
                        tabClassName={selectedTab !== 'EN' ? emailTemplate?.templateCode?.EN !== undefined ? 'email' : '' : 'email-active'}
                      >
                        <div className='mt-5'>
                          <EditEmailTemplate
                            dynamicKeys={dynamicKeys}
                            isHidden={isHidden}
                            setGalleryModal={setGalleryModal}
                            emailTemplate={emailTemplate}
                            setIsTestTemplateModalVisible={setIsTestTemplateModalVisible}
                            isTestTemplateModalVisible={isTestTemplateModalVisible}
                            updateTemplate={updateTemplate}
                            galleryModal={galleryModal}
                            testTemplateLoading={testTemplateLoading}
                            testEmailTemplateHandler={testEmailTemplateHandler}
                            testEmail={testEmail}
                            setTestEmail={setTestEmail}
                            selectedTab={selectedTab}
                            deleteEmailTemplate={deleteEmailTemplate}
                            handleSubmit={handleSubmit}
                            setTemp={setTemplate}
                            create={!isEdit}
                            values={values}
                            isTenant
                          />
                        </div>
                      </Tab>
                      {values?.tenantId && languages?.length
                        ? languages?.map((code) => {
                          return code !== 'EN' && (
                            <Tab
                              eventKey={code}
                              title={
                                <Trigger message={languageCode[code]}>
                                  <span>{code}</span>
                                </Trigger>
                            }
                              key={code}
                              tabClassName={selectedTab !== code ? emailTemplate?.templateCode?.[code] !== undefined ? 'email' : '' : 'email-active'}
                            >
                              <div className='mt-5'>
                                <EditEmailTemplate
                                  dynamicKeys={dynamicKeys}
                                  isHidden={isHidden}
                                  setGalleryModal={setGalleryModal}
                                  emailTemplate={emailTemplate}
                                  setIsTestTemplateModalVisible={setIsTestTemplateModalVisible}
                                  isTestTemplateModalVisible={isTestTemplateModalVisible}
                                  updateTemplate={updateTemplate}
                                  galleryModal={galleryModal}
                                  testTemplateLoading={testTemplateLoading}
                                  testEmailTemplateHandler={testEmailTemplateHandler}
                                  testEmail={testEmail}
                                  setTestEmail={setTestEmail}
                                  selectedTab={selectedTab}
                                  deleteEmailTemplate={deleteEmailTemplate}
                                  handleSubmit={handleSubmit}
                                  setTemp={setTemplate}
                                  create={!isEdit}
                                  values={values}
                                  isTenant
                                />
                              </div>
                            </Tab>
                          )
                        })
                        : null}
                    </Tabs>
                  </div>
                </BForm>
              )}
            </Formik>
          </Row>
          )}

      {show &&
        <DeleteConfirmationModal
          handleDeleteYes={handleDeleteYes}
          setDeleteModalShow={setShow}
          deleteModalShow={show}
        />}

      {galleryModal &&
        <GalleryModal
          galleryModal={galleryModal}
          setGalleryModal={setGalleryModal}
          navigate={navigate}
          isTenant
        />}

    </>
  )
}
