import * as Yup from 'yup'

export const realityCheckSchema = () => Yup.object().shape({
  isActive: Yup.boolean(),
  time: Yup.number().when('isActive', {
    is: (isActive) => isActive,
    then: Yup.number()
      .min(0, 'Time must not be negative number')
      .integer('Time must be an integer').nullable(),
    otherwise: Yup.number().nullable()
  })

})
