import {
  Tabs,
  Tab,
  Row,
  Col,
  Form as BForm
} from '@themesberg/react-bootstrap'
import { Formik } from 'formik'
import React from 'react'
import GeneralForm from './general'
import useCreateBonus from '../hooks/useCreateBonus'
import CurrenciesForm from './currencies'
import { bonusSchema } from './schema'
import { tabLabels } from './constants'
import { formatDate } from '../../../../../utils/dateFormatter'
import PaymentMethod from './PaymentMethod'
import WageringContributionForm from './wageringContribution'
import Games from './Games'
import Preloader from '../../../../../components/Preloader'
import LoyaltySettings from './LoyaltySettings'
import Languages from './Languages'
import BonusCountry from './BonusCountry'
import CreateBonusBannedGames from './BannedGames'

const CreateBonus = () => {
  const {
    curr,
    setCurr,
    selectedTab,
    setSelectedTab,
    state,
    setState,
    preview,
    handleImagePreview,
    setActiveTab,
    dispatch,
    navigate,
    enableSubmit,
    setEnableSubmit,
    loading,
    handelCreateBonus,
    clientsFilterData,
    setSelectedClient,
    portalOptions,
    getBalanceBonusesOptions,
    balanceBonusOptions,
    wageringTemplateList,
    wageringTemplateDetail,
    pageNo,
    setPageNo,
    limit,
    setLimit,
    totalPages,
    search,
    setSearch,
    selectedProvider,
    setSelectedProvider,
    gameIds,
    setGameIds,
    casinoGamesData,
    selectedClient,
    gamesLimit,
    gamesPageNo,
    setGamesLimit,
    setGamesPageNo,
    appliedBonusOptions,
    languages,
    data,
    setData,
    loyaltyCount,
    setLoyaltyCount,
    countryData,
    setCountryData,
    prepaidsData,
    setPrepaidsData
  } = useCreateBonus()

  return (
    <>
      <Row>
        {loading && <Preloader />}

        <Col>
          <h3>Create Bonus </h3>
        </Col>
      </Row>
      <Formik
        initialValues={{
          promotionTitle: '',
          bonusType: 'match',
          validFrom: formatDate(state?.[0].startDate),
          validTo: formatDate(state?.[0].endDate),
          termCondition: '',
          quantity: 1,
          wageringMultiplier: 0,
          currency: {
            EUR: {
              maxBonusThreshold: '',
              minDeposit: '',
              maxWinAmount: '',
              zeroOutThreshold: '',
              minBalance: '',
              maxBetAllowed: '',
              bonusAmount: ''
            }
          },
          providers: '',
          daysToClear: 1,
          games: '',
          maxBonusThreshold: '',
          status: '',
          minDeposit: '',
          wageringRequirementType: 'bonus',
          maxWinAmount: '',
          isWinCashAmt: '',
          isBonusWagering: '',
          depositBonusPercent: 1,
          isMultipleAllowed: '',
          tenantIds: [],
          validOnDays: [],
          bonusImage: null,
          isActive: false,
          showBonusValidity: true,
          visibleInPromotions: false,
          isSticky: false,
          paymentMethods: {},
          wageringTemplateId: wageringTemplateList?.[0]?.wageringTemplateId,
          appliedBonusId: '',
          appliedBonusVal: '',
          adminId: '',
          description: '',
          promotionalOverview: '',
          loyaltyLevel: null,
          other: null,
          minBalance: '',
          timePeriod: '1',
          betLevel: 1,
          aggregatorId: '',
          bannedGamesSetting: null
        }}
        validationSchema={
          bonusSchema(curr, { bonusDetail: null })[
            tabLabels.findIndex((val) => val === selectedTab)
          ]
        }
        onSubmit={(formValues) => handelCreateBonus(formValues)}
      >
        {({
          touched,
          errors,
          values,
          setSubmitting,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue
        }) => (
          <BForm>
            <Tabs
              key={selectedTab}
              activeKey={selectedTab}
              className='nav-light'
              mountOnEnter
              unmountOnExit
              onSelect={(k) => setSelectedTab(k)}
            >
              <Tab disabled eventKey='general' title='General'>
                <div className='mt-5'>
                  <GeneralForm
                    state={state}
                    setState={setState}
                    setSelectedTab={setSelectedTab}
                    touched={touched}
                    errors={errors}
                    values={values}
                    setSubmitting={setSubmitting}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    preview={preview}
                    handleImagePreview={handleImagePreview}
                    navigate={navigate}
                    clientsFilterData={clientsFilterData}
                    setSelectedClient={setSelectedClient}
                    portalOptions={portalOptions}
                    getBalanceBonusesOptions={getBalanceBonusesOptions}
                    balanceBonusOptions={balanceBonusOptions}
                    selectedClient={selectedClient}
                    setGameIds={setGameIds}
                    wageringTemplateList={wageringTemplateList}
                    setEnableSubmit={setEnableSubmit}
                    appliedBonusOptions={appliedBonusOptions}
                    data={data}
                    setData={setData}
                    languages={languages}
                    setCountryData={setCountryData}
                    setPrepaidIds={setPrepaidsData}
                  />
                </div>
              </Tab>
              {languages?.length > 0 &&
                <Tab disabled eventKey='languages' title='Languages'>
                  <div className='mt-5'>
                    <Languages
                      languages={languages}
                      handleSubmit={handleSubmit}
                      setSelectedTab={setSelectedTab}
                      selectedTab={selectedTab}
                      data={data}
                      setData={setData}
                      values={values}
                      create
                    />
                  </div>
                </Tab>}
              {!['balance', 'promotion', 'winBoost'].includes(values?.bonusType) && (
                <Tab disabled eventKey='currency' title='Currency'>
                  <div className='mt-5'>
                    <CurrenciesForm
                      touched={touched}
                      errors={errors}
                      values={values}
                      setSubmitting={setSubmitting}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      setCurr={setCurr}
                      setSelectedTab={setSelectedTab}
                      setActiveTab={setActiveTab}
                      enableSubmit={enableSubmit}
                      setEnableSubmit={setEnableSubmit}
                    />
                  </div>
                </Tab>
              )}

              {values.bonusType === 'match' && (
                <Tab
                  disabled
                  eventKey='payment'
                  title='Applies to Payment method'
                >
                  <div className='mt-3 hhhhh'>
                    <PaymentMethod
                      touched={touched}
                      errors={errors}
                      values={values}
                      setSubmitting={setSubmitting}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      setCurr={setCurr}
                      setSelectedTab={setSelectedTab}
                      setActiveTab={setActiveTab}
                      selectedTab={selectedTab}
                    />
                  </div>
                </Tab>
              )}
              {['deposit', 'wagering', 'winBoost'].includes(values.bonusType) && (
                <Tab
                  disabled
                  eventKey='loyalty'
                  title='Loyalty Settings'
                >
                  <div className='mt-3 hhhhh'>
                    <LoyaltySettings
                      values={values}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      handleBlur={handleBlur}
                      setSelectedTab={setSelectedTab}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      selectedTab={selectedTab}
                      loyaltyCount={loyaltyCount}
                      setLoyaltyCount={setLoyaltyCount}
                    />
                  </div>
                </Tab>
              )}
              {!['balance', 'cashfreespins', 'promotion', 'winBoost'].includes(values.bonusType) && (
                <Tab
                  disabled
                  eventKey='wageringContribution'
                  title='Wagering contribution'
                >
                  <div className='mt-5'>
                    <WageringContributionForm
                      state={state}
                      setState={setState}
                      setSelectedTab={setSelectedTab}
                      touched={touched}
                      errors={errors}
                      values={values}
                      setSubmitting={setSubmitting}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      preview={preview}
                      handleImagePreview={handleImagePreview}
                      navigate={navigate}
                      dispatch={dispatch}
                      wageringTemplateList={wageringTemplateList}
                      wageringTemplateDetail={wageringTemplateDetail}
                      setActiveTab={setActiveTab}
                      limit={limit}
                      pageNo={pageNo}
                      setLimit={setLimit}
                      setPageNo={setPageNo}
                      totalPages={totalPages}
                      selectedTab={selectedTab}
                      selectedClient={selectedClient}
                    />
                  </div>
                </Tab>
              )}
              {(values.bonusType === 'freespins' || values?.bonusType === 'cashfreespins') && (
                <Tab disabled eventKey='games' title='Games'>
                  <div className='mt-5'>
                    <Games
                      gameIds={gameIds}
                      setGameIds={setGameIds}
                      setLimit={setGamesLimit}
                      setPageNo={setGamesPageNo}
                      setSearch={setSearch}
                      search={search}
                      totalPages={totalPages}
                      limit={gamesLimit}
                      pageNo={gamesPageNo}
                      loading={loading}
                      handleSubmit={handleSubmit}
                      handleBlur={handleBlur}
                      casinoGamesData={casinoGamesData}
                      selectedProvider={selectedProvider}
                      setSelectedProvider={setSelectedProvider}
                      setSelectedTab={setSelectedTab}
                      values={values}
                      bonus
                      prepaidsData={prepaidsData}
                      setPrepaidsData={setPrepaidsData}
                    />
                  </div>
                </Tab>
              )}
              {
                values.bonusType !== 'cashfreespins' && values.bonusType !== 'balance' && values.bonusType !== 'promotion' &&
                  <Tab disabled eventKey='bannedGames' title='Banned Games'>
                    <div className='mt-5'>
                      <CreateBonusBannedGames
                        values={values}
                        setFieldValue={setFieldValue}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        handleSubmit={handleSubmit}
                      />
                    </div>
                  </Tab>
              }

              <Tab eventKey='countries' title='Countries'>
                <div className='mt-5'>
                  <BonusCountry
                    data={countryData}
                    setData={setCountryData}
                    values={values}
                    setSelectedTab={setSelectedTab}
                    handleSubmit={handleSubmit}
                    languages={languages}
                  />
                </div>
              </Tab>
            </Tabs>
          </BForm>
        )}
      </Formik>
    </>
  )
}

export default CreateBonus
