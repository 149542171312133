import React from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import {
  Col,
  Row,
  Form as BForm,
  Button,
  Tabs,
  Tab
} from '@themesberg/react-bootstrap'
import { createCmsSchema } from '../schema'
import useCreateCms from '../hooks/useCreateCms'
import ClientFilter from '../../../../components/ClientFilter'
import PortalFilter from '../../../../components/PortalFilter'
import useCheckPermission from '../../../../utils/checkPermission'
import Trigger from '../../../../components/OverlayTrigger'
import { faImages } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GalleryModal } from '../../../../components/ConfirmationModal'
import EditCMSTemplate from '../../../../components/EditCMSTemplate'
import { languageCode } from '../../../../pages/Tenant/TenantConfigurations/Languages/constants'
import { checkIsTenant } from '../../../../utils/constants'

const CreateCms = ({ cmsData, details }) => {
  const {
    navigate,
    createCms,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal,
    editCms,
    cmsPageId,
    setTemplate,
    galleryModal,
    setGalleryModal,
    getGalleryData,
    cmsKeys,
    selectedTab,
    setSelectedTab,
    languages,
    deleteCms
  } = useCreateCms(cmsData)

  const { isHidden } = useCheckPermission()

  return (
    <>
      <Row>
        <Col sm={8}>
          <h3>{cmsData ? `${!details ? 'Edit' : ''} CMS: ${cmsData?.title?.EN}` : 'Create CMS'}</h3>
        </Col>
        <Col className='d-flex justify-content-end'>
          <Trigger message='Gallery'>
            <Button
              hidden={details || isHidden({ module: { key: 'ImageGallery', value: 'R' } })}
              onClick={() => {
                getGalleryData()
                setGalleryModal(true)
              }}
              variant='outline-secondary'
            >
              <FontAwesomeIcon icon={faImages} />
            </Button>
          </Trigger>
        </Col>
      </Row>

      <Formik
        initialValues={{
          title: cmsData ? cmsData?.title?.EN : '',
          slug: cmsData ? cmsData?.slug : '',
          content: cmsData ? cmsData?.content?.EN : '',
          category: cmsData ? cmsData?.category : 1,
          isActive: cmsData ? (!!cmsData?.isActive) : true,
          language: selectedTab
        }}
        validationSchema={createCmsSchema}
        onSubmit={(formValues) => {
          !cmsData
            ? createCms({ cmsData: { ...formValues, tenantId: selectedPortal, adminUserId: selectedClient } })
            : editCms({ cmsData: { ...formValues, tenantId: selectedPortal, adminUserId: selectedClient, cmsPageId: parseInt(cmsPageId) } })
        }}
      >
        {({ values, errors, handleChange, handleSubmit, handleBlur, setFieldValue }) => (
          <Form>
            <Row className='mb-3 align-items-center'>
              <Col xs={6}>
                <Col>
                  <BForm.Label>
                    Slug <span className='text-danger'>*</span>
                  </BForm.Label>
                </Col>
                <Col>
                  <BForm.Control
                    type='text'
                    name='slug'
                    placeholder='Enter Slug'
                    value={values.slug}
                    disabled={details}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component='div'
                    name='slug'
                    className='text-danger'
                  />
                </Col>
              </Col>

              <Col>
                <BForm.Label>
                  Category <span className='text-danger'>*</span>
                </BForm.Label>

                <BForm.Select
                  type='text'
                  name='category'
                  style={{ maxWidth: '200px' }}
                  value={values.category}
                  disabled={details}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value={1}>Support Links</option>
                  <option value={2}>Other Links</option>
                  <option value={3}>Landing Page</option>
                </BForm.Select>
              </Col>

              <Col>
                <Col>
                  <BForm.Label>
                    Status <span className='text-danger'>*</span>
                  </BForm.Label>
                </Col>
                <Col className='mb-4'>
                  <BForm.Check
                    type='switch'
                    name='isActive'
                    disabled={details}
                    defaultChecked={values.isActive}
                    value={values.isActive}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>

              </Col>

            </Row>
            {!checkIsTenant() &&
              <Row className='mt-3 d-flex'>

                <Col>
                  <ClientFilter
                    setSelectedClient={setSelectedClient}
                    setSelectedPortal={setSelectedPortal}
                    selectedClient={selectedClient}
                    cmsData
                    cms={cmsData}
                  />
                </Col>

                {selectedClient && (
                  <Col>
                    <PortalFilter
                      setSelectedPortal={setSelectedPortal}
                      selectedPortal={selectedPortal}
                      selectedClient={selectedClient}
                      cmsData
                      cms={cmsData}
                    />
                  </Col>
                )}
              </Row>}
            <Tabs
              activeKey={selectedTab}
              onSelect={(tab) => setSelectedTab(tab)}
              className='nav-light mt-3'
            >
              <Tab
                eventKey='EN'
                title={
                  <Trigger message='English'>
                    <span>EN</span>
                  </Trigger>
                }
                mountOnEnter
                tabClassName={selectedTab !== 'EN' ? 'email' : 'email-active'}
              >
                <div className='mt-5'>
                  <EditCMSTemplate
                    values={cmsData}
                    cmsKeys={cmsKeys}
                    setFieldValue={setFieldValue}
                    isHidden={isHidden}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setTemp={setTemplate}
                    handleSubmit={handleSubmit}
                    navigate={navigate}
                    deleteCms={deleteCms}
                    details={details}
                    initValues={values}
                    errors={errors}
                  />
                </div>
              </Tab>
              {details
                ? cmsData && languages?.length && languages?.map((code) => {
                  return cmsData?.content?.[code] !== undefined && code !== 'EN' && (
                    <Tab
                      eventKey={code}
                      title={
                        <Trigger message={languageCode[code]}>
                          <span>{code}</span>
                        </Trigger>
                      }
                      key={code}
                      mountOnEnter
                      disabled={(!cmsData && cmsData?.content.EN === undefined)}
                      tabClassName={selectedTab !== code ? cmsData?.content?.[code] !== undefined ? 'email' : '' : 'email-active'}
                    >
                      <div className='mt-5'>
                        <EditCMSTemplate
                          values={cmsData}
                          cmsKeys={cmsKeys}
                          setFieldValue={setFieldValue}
                          isHidden={isHidden}
                          selectedTab={selectedTab}
                          setSelectedTab={setSelectedTab}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setTemp={setTemplate}
                          navigate={navigate}
                          handleSubmit={handleSubmit}
                          deleteCms={deleteCms}
                          details={details}
                          initValues={values}
                          errors={errors}
                        />
                      </div>
                    </Tab>
                  )
                })
                : cmsData && languages?.length && languages?.map((code) => {
                  return code !== 'EN' && (
                    <Tab
                      eventKey={code}
                      title={
                        <Trigger message={languageCode[code]}>
                          <span>{code}</span>
                        </Trigger>
                      }
                      key={code}
                      mountOnEnter
                      disabled={(!cmsData && cmsData?.content.EN === undefined)}
                      tabClassName={selectedTab !== code ? cmsData?.content?.[code] !== undefined ? 'email' : '' : 'email-active'}
                    >
                      <div className='mt-5'>
                        <EditCMSTemplate
                          values={cmsData}
                          cmsKeys={cmsKeys}
                          setFieldValue={setFieldValue}
                          isHidden={isHidden}
                          selectedTab={selectedTab}
                          setSelectedTab={setSelectedTab}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setTemp={setTemplate}
                          navigate={navigate}
                          handleSubmit={handleSubmit}
                          deleteCms={deleteCms}
                          details={details}
                          initValues={values}
                          errors={errors}
                        />
                      </div>
                    </Tab>
                  )
                })}
            </Tabs>

          </Form>
        )}
      </Formik>
      {galleryModal &&
        <GalleryModal
          galleryModal={galleryModal}
          setGalleryModal={setGalleryModal}
        />}
    </>
  )
}

export default CreateCms
