import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Table, Modal, Form } from '@themesberg/react-bootstrap'
import { tableHeaders } from './constants'
import { getDateTime } from '../../utils/dateFormatter'
import Trigger from '../OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowCircleUp,
  faCheckSquare,
  faWindowClose
} from '@fortawesome/pro-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { Card } from 'react-bootstrap'
import useCheckPermission from '../../utils/checkPermission'
import { getKycLabelsStart, updateKycLabelsStart } from '../../store/redux-slices/fetchData'
import { useParams } from 'react-router-dom'
import { toast } from '../Toast'

const UserDocsList = ({
  userDocuments,
  documentLabels,
  updateDocument,
  handleVerify,
  isTenant = false,
  docLabels,
  handleReRequest,
  handleImagePreview,
  userDetails
}) => {
  const { kycLabels: kycLabelsData } = useSelector((state) => state.fetch)
  const { isHidden } = useCheckPermission()
  const [open, setOpen] = useState(false)
  const [kycLabels, setKycLabels] = useState('')
  const [allKycLabels, setAllKycLabels] = useState([])
  const dispatch = useDispatch()
  const { userId } = useParams()

  useEffect(() => {
    const newData = kycLabelsData?.filter(item => item?.name !== userDetails?.other?.kycLevelName)
    setAllKycLabels(newData)
  }, [kycLabelsData])

  return (
    <>
      <div className='d-flex justify-content-between'>
        <div className='d-flex justify-content-center'>
          <Card body className='w-100 text-start' style={{ maxWidth: '500px', margin: 'auto auto 2rem 1rem' }}>
            <div>
              SumSub Applicant Id:
              {userDetails?.applicantId
                ? (
                  <span
                    onClick={() => window.open(`https://cockpit.sumsub.com/checkus#/applicant/${userDetails.applicantId}`, '_blank')}
                    style={{ color: '#0948b3' }}
                    className='text-link text-truncate cursor-pointer'
                  > {userDetails?.applicantId}
                  </span>
                  )
                : (<span className='header-font-weight'> NA </span>)}
            </div>
            <div>
              KYC Method: <span className='header-font-weight'> {userDetails?.kycMethod === 1 ? 'Sumsub' : 'System KYC' || 'NA'} </span>
            </div>
            <div>
              KYC Status: <span className='header-font-weight'> {userDetails?.kycMethod === 1 ? userDetails?.sumsubKycStatus?.toUpperCase() : userDetails?.kycStatus || 'NA'} </span>
            </div>
            <div>
              KYC Label Name: <span className='header-font-weight'> {userDetails?.other?.kycLevelName || 'NA'} </span>
            </div>
          </Card>
        </div>
        {userDetails?.applicantId &&
          <div className='m-4'>
            <Button
              variant='outline-success'
              onClick={() => {
                setOpen(true)
                dispatch(getKycLabelsStart({ userId, isTenant, type: 'getLabels', tenantId: userDetails?.tenantId }))
              }}
              className='actionButton'
            >
              Change label
            </Button>
          </div>}
      </div>

      <Modal show={open} onHide={() => setOpen(false)} size='md'>
        <Modal.Header closeButton>
          <Modal.Title className='fs-2'>Change KYC labels</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <center>
            <span className='d-flex justify-content-center'>
              <Form.Label style={{ marginBottom: '0', marginTop: '5px', minWidth: '92px' }}>
                Kyc labels
              </Form.Label>
              <Form.Select
                onChange={(e) => setKycLabels(e.target.value)}
                value={kycLabels}
                style={{ maxWidth: '180px', marginRight: '10px', height: '36px' }}
                size='sm'
              >
                <option value=''>
                  Select KYC Label
                </option>
                {allKycLabels?.map((item) => {
                  return (
                    <option value={item.name} key={item.name}>
                      {item.name}
                    </option>
                  )
                })}
              </Form.Select>
            </span>
          </center>
        </Modal.Body>
        <div className='mt-4 '>
          <Modal.Footer className='d-flex justify-content-between align-items-center'>
            <Button
              variant='outline-warning'
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant='outline-success'
              onClick={() => {
                if (!kycLabels) return toast('Please Select label', 'error')
                dispatch(updateKycLabelsStart({
                  userId: parseInt(userId),
                  isTenant,
                  type: 'updateKycLabels',
                  applicationId: userDetails.applicantId,
                  kycLabels,
                  setOpen,
                  setKycLabels,
                  tenantId: parseInt(userDetails?.tenantId)
                }))
              }}
              className='ml-2'
            >
              Submit
            </Button>
          </Modal.Footer>
        </div>
      </Modal>

      <Table
        bordered
        striped
        responsive
        hover
        size='sm'
        className='text-center mt-4'
      >
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((c) =>
              <th key={c}>{c}</th>
            )}
          </tr>
        </thead>

        <tbody>
          {userDocuments &&
            userDocuments?.map(
              ({
                userDocumentId,
                documentUrl,
                documentName,
                status,
                reason,
                updatedAt,
                actionPerformedAt,
                actionee
              }) => {
                return (
                  <tr key={`user-docs-list ${userDocumentId}`}>
                    <td>{userDocumentId}</td>

                    <td>
                      {documentName}
                    </td>

                    <td>
                      <span
                        onClick={() => handleImagePreview(documentUrl, documentName)}
                        className='text-link'
                        style={{ cursor: 'pointer' }}
                      >
                        View Here
                      </span>
                    </td>

                    <td>{reason || 'N/A'}</td>
                    <td>{updatedAt ? getDateTime(updatedAt) : 'NA'}</td>
                    <td>
                      {actionee || 'NA'}
                    </td>
                    <td>{actionPerformedAt ? getDateTime(actionPerformedAt) : 'NA'}</td>

                    {status === 0
                      ? (
                        <td>Pending</td>
                        )
                      : status === 1
                        ? (
                          <td>Approved</td>
                          )
                        : status === 4
                          ? <td>Re-Requested</td>
                          : (
                            <td>Rejected</td>
                            )}

                    {!status
                      ? (
                        <td>
                          {isHidden({ module: { key: 'Users', value: 'U' } }) && 'Pending'}
                          {!isHidden({ module: { key: 'Users', value: 'U' } }) &&
                            <ButtonGroup>
                              <Trigger message='Approve'>
                                <Button
                                  variant='success'
                                  className='m-1'
                                  size='sm'
                                  onClick={() =>
                                    handleVerify('approved', userDocumentId, `${documentName} for ${userDetails?.firstName} ${userDetails?.lastName}`)}
                                >
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </Button>
                              </Trigger>

                              <Trigger message='Reject'>
                                <Button
                                  variant='danger'
                                  className='m-1'
                                  size='sm'
                                  onClick={() =>
                                    handleVerify('rejected', userDocumentId, `${documentName} for ${userDetails?.firstName} ${userDetails?.lastName}`)}
                                >
                                  <FontAwesomeIcon icon={faWindowClose} />
                                </Button>
                              </Trigger>
                            </ButtonGroup>}
                        </td>
                        )
                      : status === 1
                        ? (
                          <td>
                            {!isHidden({ module: { key: 'Users', value: 'U' } }) &&
                              <Trigger message='Re-Request Document'>
                                <Button
                                  variant='success'
                                  className='m-1'
                                  size='sm'
                                  onClick={() => handleReRequest(userDocumentId, 'request', `${documentName} for ${userDetails?.firstName} ${userDetails?.lastName}`, documentName)}
                                >
                                  <FontAwesomeIcon icon={faArrowCircleUp} />
                                </Button>
                              </Trigger>}
                          </td>
                          )
                        : status === 4 &&
                          (
                            <td>
                              {!isHidden({ module: { key: 'Users', value: 'U' } }) &&
                                <Trigger message='Cancel Re-Request Document'>
                                  <Button
                                    variant='danger'
                                    className='m-1'
                                    size='sm'
                                    onClick={() => handleReRequest(userDocumentId, 'cancel', `${documentName} for ${userDetails?.firstName} ${userDetails?.lastName}`, documentName)}
                                  >
                                    <FontAwesomeIcon icon={faWindowClose} />
                                  </Button>
                                </Trigger>}
                            </td>
                          )}
                  </tr>
                )
              }
            )}

          {userDocuments?.length < 1 && (
            <tr>
              <td colSpan={9} className='text-danger text-center'>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {!isTenant && !isHidden({ module: { key: 'Users', value: 'U' } }) && (
        <>
          <h3 className='mt-4'>Request Documents</h3>
          <Table
            bordered
            striped
            responsive
            hover
            size='sm'
            className='text-center'
          >
            <thead className='thead-dark'>
              <tr>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {documentLabels &&
                documentLabels.map(({ name, documentLabelId, isRequired }) => {
                  return (
                    (
                      <tr key={documentLabelId}>
                        <td>{name?.EN}</td>
                        <td>
                          {!userDetails?.documentLabels?.includes(documentLabelId)
                            ? (
                              <ButtonGroup>
                                <Trigger message='Mark As Required'>
                                  <Button
                                    variant='success'
                                    className='m-1'
                                    size='sm'
                                    onClick={() =>
                                      updateDocument({
                                        documentLabelId,
                                        isRequested: true,
                                        labelName: name?.EN
                                      })}
                                  >
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </Button>
                                </Trigger>
                              </ButtonGroup>
                              )
                            : (
                              <ButtonGroup>
                                <Trigger message='Mark As Unrequired'>
                                  <Button
                                    variant='danger'
                                    className='m-1'
                                    size='sm'
                                    onClick={() =>
                                      updateDocument({
                                        documentLabelId,
                                        isRequested: false
                                      })}
                                  >
                                    <FontAwesomeIcon icon={faWindowClose} />
                                  </Button>
                                </Trigger>
                              </ButtonGroup>
                              )}
                        </td>
                      </tr>
                    )
                  )
                })}
              {userDetails?.requestedDocuments && docLabels &&
                docLabels.map(({ name, documentLabelId, isRequired }) => {
                  return userDetails.requestedDocuments?.includes(documentLabelId) &&
                    (
                      <tr key={`reqDocs${name?.EN}`}>
                        <td>{name?.EN}</td>
                        <td>
                          <ButtonGroup>
                            <Trigger message='Mark As UnRequired'>
                              <Button
                                variant='danger'
                                className='m-1'
                                size='sm'
                                onClick={() =>
                                  updateDocument({
                                    documentLabelId,
                                    isRequested: false
                                  })}
                              >
                                <FontAwesomeIcon icon={faWindowClose} />
                              </Button>
                            </Trigger>
                          </ButtonGroup>
                        </td>
                      </tr>
                    )
                })}
              {(userDetails?.requestedDocuments === null || userDetails?.requestedDocuments?.length < 1) && documentLabels?.length < 1 && (
                <tr>
                  <td colSpan={2} className='text-danger text-center'>
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </>
      )}
    </>
  )
}

export default UserDocsList
