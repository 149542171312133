import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { cancelBonusStart, getUserBonusStart } from '../../../../store/redux-slices/bonus'
import useDidMountEffect from '../../../../utils/useDidMountEffect'

const useYourBonuses = ({ limit, page, status, bonusType, setPage, isTenant }) => {
  const dispatch = useDispatch()
  const { userId } = useParams()
  const isInitialRender = useDidMountEffect()
  const [bonusData, setBonusData] = useState({})
  const [infoModal, setInfoModal] = useState(false)
  const { userBonus, loading } = useSelector(state => state.bonus)
  const totalPages = Math.ceil(userBonus?.count / limit)
  const [searchByBonusId, setSearchByBonusId] = useState('')

  const fetchData = () => {
    dispatch(getUserBonusStart({
      limit,
      pageNo: page,
      bonusType,
      status,
      userId,
      bonusId: searchByBonusId,
      isTenant
    }))
  }

  useEffect(() => {
    fetchData()
  }, [limit, page, bonusType, status])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          fetchData()
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchByBonusId])

  const cancelBonusHandler = (userBonusId) => {
    dispatch(cancelBonusStart({ data: { userBonusId, userId }, limit, pageNo: page, bonusType, status, userId, isTenant }))
  }

  return {
    loading,
    userBonus,
    totalPages,
    cancelBonusHandler,
    infoModal,
    setInfoModal,
    bonusData,
    setBonusData,
    searchByBonusId,
    setSearchByBonusId
  }
}

export default useYourBonuses
