import React from 'react'
import { Col, Form, Row } from '@themesberg/react-bootstrap'
import useBannedGameDetail from './hooks/useBannedGameDetail'
import BannedGamesDetailsListing from '../../pages/Super-Admin/BannedGames/components/BannedGamesDetailsListing'

const BannedGameDetail = ({ bonusDetail, isTenant = false }) => {
  const {
    limit,
    setLimit,
    pageNo,
    setPageNo,
    search,
    setSearch,
    totalPages,
    loading,
    listBannedGames,
    setListBannedGames,
    getBannedGamesListDetail
  } = useBannedGameDetail({ isTenant, bonusDetail })

  return (
    <>
      <Row className='d-flex'>
        <Col><h5>Banned Games List Name:{' '}{getBannedGamesListDetail?.bannedGamesListName} </h5></Col>
        <Col sm='auto'>
          <Form.Control
            type='search'
            name='search'
            size='sm'
            value={search}
            style={{ maxWidth: '230px' }}
            placeholder='Search Game Name'
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
      </Row>

      <BannedGamesDetailsListing
        pageNo={pageNo}
        setPageNo={setPageNo}
        totalPages={totalPages}
        limit={limit}
        setLimit={setLimit}
        getBannedGamesListDetail={getBannedGamesListDetail.gameDetail}
        isTenant={isTenant}
        listBannedGames={listBannedGames}
        setListBannedGames={setListBannedGames}
        loading={loading}
      />
    </>
  )
}

export default BannedGameDetail
