import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBannedGamesStart, getBannedGamesListDetailStart } from '../../../../../../../store/redux-slices/superAdminTransactions'

const useCreateBonusBannedGames = (bannedGamesListId, values) => {
  const { loading, getBannedGamesListDetail, bannedGames } = useSelector((state) => state.superAdminTransactions)

  const [limit, setLimit] = useState(15)

  const [pageNo, setPageNo] = useState(1)

  const [listBannedGames, setListBannedGames] = useState([])

  const dispatch = useDispatch()

  const url = window.location.href

  const isTenant = url.includes('/tenant/')

  const totalPages = Math.ceil(getBannedGamesListDetail?.gameDetail?.count / limit)

  const [selectedPortal, setSelectedPortal] = useState('')

  const [selectedBannedGameListId, setSelectedBannedGameListId] = useState(bannedGamesListId || '')

  const [bannedGamesSetting, setBannedGamesSetting] = useState()

  const { tenantDetails: loginTenantDetails } = useSelector((state) => state.login)

  useEffect(() => {
    if (bannedGamesListId && values.tenantIds?.length > 0) {
      setSelectedPortal(values.tenantIds[0].value)
    }
  }, [])

  useEffect(() => {
    if (values.bannedGamesSetting) {
      // Extract the first key from the bannedGamesSetting object
      const keys = Object.keys(values.bannedGamesSetting)
      if (keys.length > 0) {
        // Set selectedPortal with the first key in number format
        setSelectedPortal(Number(keys[0]))
      }
    }
  }, [])

  useEffect(() => {
    if (values.tenantIds && values.tenantIds.length > 0 && !bannedGamesListId) {
      const bannedGamesConfig = values.tenantIds.reduce((acc, { value }) => {
        acc[value] = {
          bannedGamesListId: '',
          restrictBannedGame: false
        }
        return acc
      }, {})
      setBannedGamesSetting(bannedGamesConfig)
    }

    if (values.tenantIds && values.tenantIds.length > 0 && bannedGamesListId) {
      const bannedGamesConfig = values.tenantIds.reduce((acc, { value }) => {
        acc[value] = {
          bannedGamesListId,
          restrictBannedGame: values.restrictBannedGame
        }
        return acc
      }, {})
      setBannedGamesSetting(bannedGamesConfig)
    }
  }, [values.tenantIds])

  // Initial useEffect to fetch all banned games when the component mounts
  useEffect(() => {
    if (selectedPortal) {
      dispatch(
        getAllBannedGamesStart({
          isTenant,
          adminId: isTenant ? loginTenantDetails?.adminUserId : values?.adminId,
          search: '',
          tenantId: isTenant ? loginTenantDetails?.tenantId : selectedPortal
        })
      )
    }
  }, [selectedPortal])

  // Function to fetch details of a specific banned game list based on selected banned game list ID
  const fetchDetails = (selectedBannedGameListId) => {
    dispatch(getBannedGamesListDetailStart({
      isTenant,
      adminId: isTenant ? loginTenantDetails?.adminUserId : values?.adminId,
      bannedGamesListId: selectedBannedGameListId,
      limit,
      pageNo,
      providerId: '',
      search: ''
    }))
  }

  // useEffect to fetch details whenever selectedBannedGameListId, pageNo, or limit changes
  useEffect(() => {
    if (selectedBannedGameListId) {
      fetchDetails(selectedBannedGameListId)
    }
  }, [selectedBannedGameListId, pageNo, limit])

  // Update the local state with the list of banned games whenever the fetched details change
  useEffect(() => {
    if (getBannedGamesListDetail?.gameDetail?.rows) {
      setListBannedGames(getBannedGamesListDetail.gameDetail.rows)
    } else {
      setListBannedGames([])
    }
  }, [getBannedGamesListDetail])

  const handlePortalSelection = (portalId) => {
    setSelectedPortal(portalId)
    setSelectedBannedGameListId('')
    setLimit(15)
    setPageNo(1)
  }

  // Handler to select a banned game list, update form value, and reset pagination
  const handleBannedGamesListSelection = (value, setFieldValue, key) => {
    // Create a copy of bannedGamesSetting for immutability
    const updatedBannedGamesSetting = { ...bannedGamesSetting }

    // Check if the current portalId exists in the copied object
    if (updatedBannedGamesSetting[selectedPortal]) {
      if (key === 'bannedGamesListId') {
        // Update the bannedGamesListId
        const numericValue = Number(value)
        updatedBannedGamesSetting[selectedPortal].bannedGamesListId = numericValue
        setSelectedBannedGameListId(numericValue)
        setFieldValue('bannedGamesListId', numericValue)
      } else if (key === 'restrictBannedGame') {
        // Update restrictBannedGame field
        const BooleanValue = Boolean(value)

        updatedBannedGamesSetting[selectedPortal].restrictBannedGame = BooleanValue
        setFieldValue('restrictBannedGame', BooleanValue)
      }

      // Update the bannedGamesSetting state
      setBannedGamesSetting(updatedBannedGamesSetting)

      // Update form field value
      setFieldValue('bannedGamesSetting', updatedBannedGamesSetting)

      // Reset pagination
      setLimit(15)
      setPageNo(1)
    }
  }

  const getBannedGamesSettingValue = (selectedPortal, key) => {
    // Check if bannedGamesSetting and selectedPortal are defined

    if (bannedGamesSetting && bannedGamesSetting[selectedPortal]) {
      // Check if the key exists in the object
      return bannedGamesSetting[selectedPortal][key] !== undefined
        ? bannedGamesSetting[selectedPortal][key]
        : key === 'bannedGamesListId' ? '' : false // Return default based on key
    }

    // Return default value if bannedGamesSetting or selectedPortal is not found
    return key === 'bannedGamesListId' ? '' : false
  }

  return {
    loading,
    getBannedGamesListDetail,
    bannedGames,
    limit,
    pageNo,
    setLimit,
    setPageNo,
    totalPages,
    isTenant,
    listBannedGames,
    setListBannedGames,
    handleBannedGamesListSelection,
    selectedPortal,
    handlePortalSelection,
    bannedGamesSetting,
    getBannedGamesSettingValue
  }
}

export default useCreateBonusBannedGames
