import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getWhiteListedDomainsStart, updateWhiteListDomainsStart } from '../../../../store/redux-slices/tenantcredentials'

/**
 * @typedef {Object} UseWhiteListDomainsReturnType
 *
 * @property {Boolean}  editing
 * @property {Boolean}  loading
 * @property {Function} onSubmit
 * @property {Function} setEditing
 * @property {Object[]} whiteListedDomains
 */

/**
 * @returns {UseWhiteListDomainsReturnType}
 */
const useWhiteListDomains = () => {
  const dispatch = useDispatch()
  const { whiteListedDomains = [], loading = false } = useSelector(state => state?.tenantcredentials || {})
  /**
   * @type {[Boolean, Function]}
   */
  const [editing, setEditing] = useState(false)

  /**
   * @type {Function}
   *
   * @param {{ domains: String[] }} formValues
   */
  const onSubmit = (formValues) => {
    setEditing(false)
    dispatch(updateWhiteListDomainsStart({ data: formValues, isUpdate: Boolean(whiteListedDomains?.length) }))
  }

  useEffect(() => {
    dispatch(getWhiteListedDomainsStart())
  }, [])

  return {
    editing,
    loading,
    onSubmit,
    setEditing,
    whiteListedDomains
  }
}

export default useWhiteListDomains
