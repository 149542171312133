import { Row, Col, Button } from '@themesberg/react-bootstrap'
import React from 'react'
import TACurrencies from './components/TACurrencies'
import useConfigurations from './useConfigurations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear } from '@fortawesome/pro-solid-svg-icons'
import { TenantRoutes } from '../../../routes'

export default () => {
  const {
    loading,
    isHidden,
    navigate,
    enableEdit,
    pointsObject,
    setEnableEdit,
    tenantDetails,
    updateLoyaltyLimit
  } = useConfigurations()

  return (
    <>
      <Row>
        <Col>
          <h3><FontAwesomeIcon icon={faGear} />&nbsp;Currencies</h3>
        </Col>
        <Col className='d-flex justify-content-end align-items-center'>
          <Button
            variant='outline-success'
            size='sm'
            hidden={isHidden({ module: { key: 'Currencies', value: 'C' } })}
            onClick={() => {
              navigate(TenantRoutes.TenantConfigCurrency)
            }}
          >Create
          </Button>&nbsp;
          <Button
            variant='outline-warning'
            size='sm'
            hidden={isHidden({ module: { key: 'Currencies', value: 'U' } })}
            onClick={() => {
              setEnableEdit(true)
            }}
          >Edit
          </Button>
        </Col>
      </Row>

      <TACurrencies
        pointsObject={pointsObject}
        updateLoyaltyLimit={updateLoyaltyLimit}
        tenantDetails={tenantDetails}
        enableEdit={enableEdit}
        loading={loading}
      />

    </>
  )
}
