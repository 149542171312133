import React from 'react'
import { Col, Row, Container } from '@themesberg/react-bootstrap'

export default () => {
  return (
    <main>
      <section className='vh-100 d-flex align-items-center justify-content-center'>
        <Container>
          <Row>
            <Col
              xs={12}
              className='text-center d-flex align-items-center justify-content-center'
            >
              <div>
                <h1 className='text-primary mt-5'>
                  Tenant not <span className='fw-bolder'>found</span>
                </h1>
                <p className='lead my-4'>
                  This Tenant Owner has no Tenants at the moment. Please contact <a style={{ textDecoration: 'none' }} href='email:support@amgaming.com'>support@amgaming.com</a> to add a tenant for you.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  )
}
