export const tableHeaders = (userDetail = false) => {
  if (userDetail) {
    return [
      'ID',
      // 'User Email',
      'Game Name',
      'Provider Name',
      'Action',
      'Cash',
      'Bonus',
      'Amount Type',
      'Before Money',
      'After Money',
      'Jackpot Contribution',
      'Status',
      'Created At'
    ]
  } else {
    return [
      'ID',
      'User Id',
      'Game Name',
      'Provider Name',
      'Action Type',
      'Cash',
      'Bonus',
      'Amount Type',
      'Before Money',
      'After Money',
      'Jackpot Contribution',
      'Status',
      'Created At'
    ]
  }
}

export const transactionType = [
  { label: 'All', value: 'all' },
  { label: 'Bet', value: 'bet' },
  { label: 'Win', value: 'win' },
  { label: 'RollBack', value: 'rollback' },
  { label: 'Pre RollBack', value: 'rollbackbeforebetwin' },
  { label: 'Free Spin', value: 'freespins' }
]

export const statusType = [
  { label: 'All', value: 'all' },
  { label: 'Pending', value: 'pending' },
  { label: 'Complete', value: 'completed' },
  { label: 'Failed', value: 'failed' },
  { label: 'RollBack', value: 'rollback' }
]

export const AMOUNT_TYPE = [
  { label: 'Cash', value: '0' },
  { label: 'Bonus', value: '1' },
  { label: 'Cash + Bonus', value: '2' }
]
