import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getAllTournamentsStart,
    getAllTournamentsSuccess,
    getAllTournamentsFailure,
    getTournamentDetailsStart,
    getTournamentDetailsSuccess,
    getTournamentDetailsFailure,
    updateTournamentStart,
    updateTournamentComplete,
    updateTournamentStatusStart,
    updateTournamentStatusComplete,
    deleteTournamentStart,
    deleteTournamentComplete
  },
  reducer
} = createSlice({
  name: 'tournaments',
  initialState: {
    error: null,
    loading: false,
    allTournaments: null,
    tournamentDetails: null
  },
  reducers: {
    getAllTournamentsStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getAllTournamentsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      allTournaments: payload,
      user: null
    }),
    getAllTournamentsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    updateTournamentStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    updateTournamentComplete: (state) => ({
      ...state,
      loading: false
    }),
    getTournamentDetailsStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getTournamentDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      tournamentDetails: payload
    }),
    getTournamentDetailsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    updateTournamentStatusStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    updateTournamentStatusComplete: (state) => ({
      ...state,
      loading: false
    }),
    deleteTournamentStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    deleteTournamentComplete: (state) => ({
      ...state,
      loading: false
    })
  }
})

export default reducer

export {
  getAllTournamentsStart,
  getAllTournamentsSuccess,
  getAllTournamentsFailure,
  getTournamentDetailsStart,
  getTournamentDetailsSuccess,
  getTournamentDetailsFailure,
  updateTournamentStart,
  updateTournamentComplete,
  updateTournamentStatusStart,
  updateTournamentStatusComplete,
  deleteTournamentStart,
  deleteTournamentComplete
}
