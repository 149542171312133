import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    updateTenantAffiliateStart,
    updateTenantAffiliateSuccess,
    updateTenantAffiliateFailure,
    createTenantAffiliateStart,
    createTenantAffiliateSuccess,
    createTenantAffiliateFailure,
    updateTenantAffiliateStatusStart,
    updateTenantAffiliateStatusSuccess,
    updateTenantAffiliateStatusFailure
  },
  reducer
} = createSlice({
  name: 'credentials',
  initialState: {
    loading: false,
    error: null,
    success: false,
    data: null
  },
  reducers: {
    updateTenantAffiliateStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTenantAffiliateSuccess: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    updateTenantAffiliateFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    updateTenantAffiliateStatusStart: (state) => ({
      ...state,
      loading: true,
      error: null,
      success: false
    }),
    updateTenantAffiliateStatusSuccess: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    updateTenantAffiliateStatusFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    }),
    createTenantAffiliateStart: (state) => ({
      ...state,
      loading: true
    }),
    createTenantAffiliateSuccess: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: true
    }),
    createTenantAffiliateFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
      success: false
    })
  }
})

export default reducer

export {
  updateTenantAffiliateStart,
  updateTenantAffiliateSuccess,
  updateTenantAffiliateFailure,
  createTenantAffiliateStart,
  createTenantAffiliateSuccess,
  createTenantAffiliateFailure,
  updateTenantAffiliateStatusStart,
  updateTenantAffiliateStatusSuccess,
  updateTenantAffiliateStatusFailure
}
