import * as Yup from 'yup'

export const createSubCategorySchema = (name) => Yup.object().shape({
  subCategoryName: validateName(name),
  tenantGameCategoryId: Yup.string().required('Category is required'),
  imageUrl: Yup.mixed().required('A file is required!').test('File Size',
    'File Size Should be Less Than 1MB', (value) => !value || (value && value.size <= 1024 * 1024))
    .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
      (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'].includes(value.type)))
})

export const editSubCategorySchema = (name) => Yup.object().shape({
  subCategoryName: validateName(name),
  tenantGameCategoryId: Yup.string().required('Category is required'),
  imageUrl: Yup.mixed()
    .test('File Size',
      'File Size Should be Less Than 1MB',
      (value) => !value || (value && value.size <= 1024 * 1024))
    .test('FILE_FORMAT', 'Uploaded file has unsupported format.',
      (value) => !value || (value && ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'].includes(value.type)))
})

const validateName = (name) => {
  const validationObject = {}
  for (const file in name) {
    validationObject[file] = Yup
      .string()
      .required('Sub Category Name Required').nullable()
  }
  return Yup.object(validationObject)
}
