import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { createBonusStart, getBonusStart } from '../../../../../store/redux-slices/bonus'
import {
  getAllClientsStart,
  getAllPortalsStart
} from '../../../../../store/redux-slices/tenants'
import { formatDateYMD } from '../../../../../utils/dateFormatter'
import { toast } from '../../../../../components/Toast'
import { safeStringify } from '../../../../../utils/helpers'
import { updateSelectedTab } from '../../../../../utils/bonus'

const useCloneBonus = ({ prepaidsData }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { bonusId } = useParams()
  const [set, setSet] = useState(false)
  const [countryData, setCountryData] = useState([])
  const [selectedTab, setSelectedTab] = useState('general')
  const { clientsFilterData, portalsFilterData } = useSelector(
    (state) => state.tenants
  )

  const { bonusDetail, loading } = useSelector((state) => state.bonus)
  const [selectedClient, setSelectedClient] = useState(bonusDetail?.adminId || null)
  const [portalOptions, setPortalOptions] = useState([])
  const [loyaltyCount, setLoyaltyCount] = useState(1)
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  const [data, setData] = useState({
    promoTitle: { EN: '' },
    desc: { EN: '' },
    promoOverview: { EN: '' },
    terms: { EN: '' }
  })

  useEffect(() => {
    dispatch(
      getAllClientsStart({
        search: '',
        limit: 100,
        pageNo: 1,
        orderBy: 'email',
        sort: 'desc'
      })
    )
  }, [])

  useEffect(() => {
    if (bonusDetail) {
      setCountryData(bonusDetail?.other?.countries)
      setData({
        promoTitle: bonusDetail?.promotionTitle,
        promoOverview: bonusDetail?.promotionalOverview,
        desc: bonusDetail?.description,
        terms: bonusDetail?.termCondition
      })
      setSet(true)
    }
  }, [bonusDetail])

  useEffect(() => {
    if (portalsFilterData && selectedClient) {
      const options = portalsFilterData.rows.map((portal) => {
        return { label: portal.name, value: portal.tenantId }
      })
      setPortalOptions(options)
    }
  }, [portalsFilterData, selectedClient])

  useEffect(() => {
    if (selectedClient) {
      dispatch(getAllPortalsStart({ adminId: selectedClient }))
    }
  }, [selectedClient])

  useEffect(() => {
    if (bonusId) {
      dispatch(getBonusStart({ bonusId, userBonusId: '', isTenant: false }))
    }
  }, [bonusId])

  const handelCreateBonus = ({ formValues, gameIds, languages, selectedTab, setSelectedTab }) => {
    Object.keys(formValues).forEach((key) => {
      if (formValues[key] === null || formValues[key] === '') {
        delete formValues[key]
      }
    })

    if (formValues.bonusType === 'promotion') {
      if (selectedTab === 'countries') {
        const myShowBonusValidity = formValues?.showBonusValidity
        delete formValues?.showBonusValidity
        formValues.promotionTitle = safeStringify(data?.promoTitle)
        formValues.promotionalOverview = safeStringify(data?.promoOverview)
        formValues.description = safeStringify(data?.desc)
        formValues.termCondition = safeStringify(data?.terms)
        delete formValues?.loyaltyLevel
        delete formValues?.paymentMethods
        delete formValues.bannedGamesSetting

        dispatch(
          createBonusStart({
            data: {
              ...formValues,
              validFrom: formatDateYMD(state.map((a) => a.startDate)),
              validTo: formatDateYMD(state.map((a) => a.endDate)),
              tenantIds: formValues.tenantIds.map((id) => id.value),
              other: safeStringify({ countries: countryData, showBonusValidity: myShowBonusValidity })
            },
            navigate
          })
        )
      } else {
        const nextTabValue = updateSelectedTab(formValues.bonusType, selectedTab, formValues, languages)
        setSelectedTab(nextTabValue)
      }
    } else if (formValues.bonusType === 'freespins' || formValues?.bonusType === 'cashfreespins') {
      const checkOther = (data) => {
        const other = typeof data === 'object' ? safeStringify(data) : data
        if (safeStringify(bonusDetail?.other) === other) {
          return {}
        } else {
          return data
        }
      }
      if (selectedTab === 'countries') {
        const myShowBonusValidity = formValues?.showBonusValidity
        delete formValues?.showBonusValidity
        delete formValues?.loyaltyLevel
        delete formValues?.paymentMethods
        formValues?.appliedBonusId && delete formValues?.appliedBonusId
        formValues.gameIds = prepaidsData?.length ? Array.from(new Set(prepaidsData?.map(({ gameId }) => gameId))) : gameIds
        formValues.promotionTitle = safeStringify(data?.promoTitle)
        formValues.promotionalOverview = safeStringify(data?.promoOverview)
        formValues.description = safeStringify(data?.desc)
        formValues.termCondition = safeStringify(data?.terms)

        if (formValues.bonusType === 'freespins') {
          formValues.bannedGamesSetting = safeStringify(formValues.bannedGamesSetting)
        } else {
          delete formValues.bannedGamesSetting
        }

        gameIds && gameIds?.length
          ? dispatch(
            createBonusStart({
              data: {
                ...formValues,
                bonusType: 'freespins',
                validFrom: formatDateYMD(state.map((a) => a.startDate)),
                validTo: formatDateYMD(state.map((a) => a.endDate)),
                tenantIds: formValues.tenantIds.map((id) => id.value),
                other: checkOther(safeStringify({
                  betLevel: formValues?.betLevel,
                  countries: countryData,
                  showBonusValidity: myShowBonusValidity,
                  hub88PrepaidDetails: prepaidsData?.length
                    ? prepaidsData
                    : null
                }))
              },
              navigate
            })
          )
          : toast('Select At least One Game.', 'error')
      } else {
        const nextTabValue = updateSelectedTab(formValues.bonusType, selectedTab, formValues, languages)
        setSelectedTab(nextTabValue)
      }
    } else if (formValues.bonusType === 'balance') {
      if (selectedTab === 'countries') {
        const myShowBonusValidity = formValues?.showBonusValidity
        delete formValues?.showBonusValidity
        delete formValues?.paymentMethods
        formValues.promotionTitle = safeStringify(data?.promoTitle)
        formValues.promotionalOverview = safeStringify(data?.promoOverview)
        formValues.description = safeStringify(data?.desc)
        formValues.termCondition = safeStringify(data?.terms)
        delete formValues?.paymentMethods
        delete formValues.bannedGamesSetting

        dispatch(
          createBonusStart({
            data: {
              ...formValues,
              validFrom: formatDateYMD(state.map((a) => a.startDate)),
              validTo: formatDateYMD(state.map((a) => a.endDate)),
              tenantIds: formValues.tenantIds.map((id) => id.value),
              appliedBonusId: formValues.appliedBonusVal,
              other: safeStringify({ countries: countryData, showBonusValidity: myShowBonusValidity })
            },
            navigate
          })
        )
      } else {
        const nextTabValue = updateSelectedTab(formValues.bonusType, selectedTab, formValues, languages)
        setSelectedTab(nextTabValue)
      }
    } else {
      if (selectedTab === 'countries') {
        const myShowBonusValidity = formValues?.showBonusValidity
        if (['deposit', 'wagering', 'winBoost'].includes(formValues?.bonusType)) {
          for (let i = 0, len = formValues?.loyaltyLevel?.length; i < len; i++) {
            delete formValues?.loyaltyLevel?.[i].startPoint
            delete formValues?.loyaltyLevel?.[i].endPoint
            formValues.loyaltyLevel[i].bonusPercentage = formValues?.loyaltyLevel?.[i].bonusPercentage / 100
          }
        }
        delete formValues?.showBonusValidity
        const timePeriod = formValues?.timePeriod
        delete formValues?.timePeriod
        formValues.promotionTitle = safeStringify(data?.promoTitle)
        formValues.promotionalOverview = safeStringify(data?.promoTitle)
        formValues.description = safeStringify(data?.desc)
        formValues.termCondition = safeStringify(data?.terms)
        formValues.paymentMethods = formValues?.paymentMethods ? safeStringify(formValues?.paymentMethods) : {}
        formValues.bannedGamesSetting = safeStringify(formValues.bannedGamesSetting)

        dispatch(
          createBonusStart({
            data: {
              ...formValues,
              validFrom: formatDateYMD(state.map((a) => a.startDate)),
              validTo: formatDateYMD(state.map((a) => a.endDate)),
              tenantIds: formValues.tenantIds.map((id) => id.value),
              other: safeStringify({ loyaltyLevel: formValues?.loyaltyLevel, timePeriod, countries: countryData, showBonusValidity: myShowBonusValidity }),
              loyaltyLevel: ''
            },
            navigate
          })
        )
      } else {
        const nextTabValue = updateSelectedTab(formValues.bonusType, selectedTab, formValues, languages)
        setSelectedTab(nextTabValue)
      }
    }
  }

  return {
    selectedTab,
    setSelectedTab,
    clientsFilterData,
    setSelectedClient,
    portalOptions,
    state,
    setState,
    dispatch,
    handelCreateBonus,
    bonusDetail,
    navigate,
    loading,
    bonusId,
    data,
    setData,
    set,
    loyaltyCount,
    setLoyaltyCount,
    countryData,
    setCountryData
  }
}

export default useCloneBonus
