import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isTenant } from '../../../utils/constants'
import useDidMountEffect from '../../../utils/useDidMountEffect'
import { getAllAmlPlayerListingStart } from '../../../store/redux-slices/amlModule'

const useAmlListing = () => {
  const heading = 'Flagged Players'
  const tabs = [
    { label: 'Total Deposits', value: 'totalTransaction' },
    { label: 'Single Transaction', value: 'singleTransaction' },
    { label: 'Rolling Period', value: 'rollingPeriodTransaction' }
  ]

  const dispatch = useDispatch()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const [search, setSearch] = useState('')
  const [userId, setUserId] = useState('')
  const { amlPlayersDetails, loading, amlThresholdValue } = useSelector(state => state.amlModule)
  const [selectedTab, setSelectedTab] = useState('totalTransaction')
  const totalPages = Math.ceil(amlPlayersDetails?.count / limit)
  const isInitialRender = useDidMountEffect()

  const fetchUserListing = () => {
    let data = {
      limit,
      page,
      isTenant,
      selectedTab,
      search,
      userId
    }
    if (!isTenant) { data = { ...data, tenantId: selectedPortal } }

    dispatch(getAllAmlPlayerListingStart({ ...data }))
  }

  useEffect(() => {
    (isTenant || selectedPortal) && fetchUserListing()
  }, [selectedPortal, limit, selectedTab, userId])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          (isTenant || selectedPortal) && fetchUserListing()
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    !isInitialRender && fetchUserListing()
  }, [page])

  return {
    page,
    limit,
    setPage,
    setLimit,
    amlPlayersDetails,
    loading,
    totalPages,
    setSelectedClient,
    setSelectedPortal,
    selectedClient,
    selectedPortal,
    selectedTab,
    setSelectedTab,
    search,
    setSearch,
    userId,
    setUserId,
    heading,
    tabs,
    amlThresholdValue
  }
}

export default useAmlListing
