import React from 'react'
import {
  Button,
  Form,
  Row,
  Col,
  Table
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faEdit, faEye, faWindowClose } from '@fortawesome/pro-solid-svg-icons'
import PaginationComponent from '../../../components/Pagination'
import useCmsListing from './hooks/useCmsListing'
import Trigger from '../../../components/OverlayTrigger'
import ClientFilter from '../../../components/ClientFilter'
import PortalFilter from '../../../components/PortalFilter'
import { SuperAdminRoutes, TenantRoutes } from '../../../routes'
import useCheckPermission from '../../../utils/checkPermission'
import ConfirmationModal from '../../../components/ConfirmationModal'

export default () => {
  const {
    page,
    limit,
    setPage,
    setLimit,
    setSearch,
    search,
    setSelectedClient,
    navigate,
    cmsData,
    totalPages,
    selectedClient,
    selectedPortal,
    setSelectedPortal,
    loading,
    handleStatusShow,
    statusShow,
    setStatusShow,
    handleYes,
    status,
    active,
    setActive,
    name,
    isTenant
  } = useCmsListing()
  const { isHidden } = useCheckPermission()

  return (
    <>
      <Row>
        <Col>
          <h3>CMS</h3>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center'>
            {!isTenant &&
              <ClientFilter
                setSelectedClient={setSelectedClient}
                setSelectedPortal={setSelectedPortal}
                selectedClient={selectedClient}
              />}

            {selectedClient && !isTenant && (
              <PortalFilter
                setSelectedPortal={setSelectedPortal}
                selectedPortal={selectedPortal}
                selectedClient={selectedClient}
              />
            )}

            <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}>
              Search
            </Form.Label>

            <Form.Control
              type='search'
              size='sm'
              style={{ maxWidth: '230px', marginRight: '10px' }}
              value={search}
              placeholder='Search title, slug'
              onChange={(event) =>
                setSearch(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                )}
            />

            <Form.Label style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}>
              Status
            </Form.Label>

            <Form.Select
              size='sm'
              style={{ maxWidth: '200px', marginRight: '10px' }}
              value={active}
              onChange={(event) =>
                setActive(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                )}
            >
              <option key='' value=''>All</option>
              <option key='true' value>Active</option>
              <option key='false' value={false}>In-Active</option>
            </Form.Select>

            <Button
              variant='outline-success'
              className='f-right'
              size='sm'
              onClick={() => navigate(isTenant ? TenantRoutes.CreateCMS : SuperAdminRoutes.CreateCMS)}
              hidden={isHidden({ module: { key: 'CMS', value: 'C' } })}
            >
              Create
            </Button>
          </div>
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {['Id', 'Title', 'Slug', 'Portal', 'Status', 'Action'].map((h) => (
              <th key={h}>{h}</th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && Boolean(cmsData) &&
                    cmsData?.rows?.map(
                      ({ cmsPageId, title, slug, isActive, tenant, tenantId, global }) => {
                        return (
                          <tr key={cmsPageId}>
                            <td>{cmsPageId}</td>

                            <td>
                              <Trigger message={title?.EN}>
                                <span
                                  style={{
                                    width: '150px',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() =>
                                    navigate(
                                      `/${isTenant ? 'tenant' : 'super-admin'}/CMS/details/${cmsPageId}`
                                    )}
                                  className='text-link d-inline-block text-truncate'
                                >
                                  {title?.EN}
                                </span>
                              </Trigger>
                            </td>

                            <td>{slug}</td>

                            <td>
                              <Trigger message={tenant?.name ? `${tenant?.name} (${tenant?.domain})` : 'All'}>
                                <span
                                  style={{
                                    width: '150px',
                                    cursor: 'pointer'
                                  }}
                                  className='d-inline-block text-truncate'
                                >
                                  {tenant?.name ? `${tenant?.name}(${tenant?.domain})` : 'All'}
                                </span>
                              </Trigger>
                            </td>

                            <td>
                              {isActive
                                ? (
                                  <span className='text-success'>Active</span>
                                  )
                                : (
                                  <span className='text-danger'>In Active</span>
                                  )}
                            </td>

                            <td>
                              {(isTenant
                                ? !global
                                : true) &&
                                  <Trigger message='Edit'>
                                    <Button
                                      className='m-1'
                                      size='sm'
                                      variant='warning'
                                      onClick={() =>
                                        navigate(
                                      `/${isTenant ? 'tenant' : 'super-admin'}/CMS/edit/${cmsPageId}`
                                        )}
                                      hidden={isHidden({ module: { key: 'CMS', value: 'U' } })}
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                  </Trigger>}
                              <Trigger message='View Details'>
                                <Button
                                  className='m-1'
                                  size='sm'
                                  variant='info'
                                  onClick={() =>
                                    navigate(
                                      `/${isTenant ? 'tenant' : 'super-admin'}/CMS/details/${cmsPageId}`
                                    )}
                                  hidden={isHidden({ module: { key: 'CMS', value: 'R' } })}
                                >
                                  <FontAwesomeIcon icon={faEye} />
                                </Button>
                              </Trigger>

                              {(isTenant
                                ? !global
                                : true) && (!isActive
                                ? (
                                  <Trigger message='Set Status Active'>
                                    <Button
                                      className='m-1'
                                      size='sm'
                                      variant='success'
                                      onClick={() =>
                                        handleStatusShow(cmsPageId, isActive, tenantId, title?.EN)}
                                      hidden={isHidden({ module: { key: 'CMS', value: 'T' } })}
                                    >
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </Button>
                                  </Trigger>
                                  )
                                : (
                                  <Trigger message='Set Status In-Active'>
                                    <Button
                                      className='m-1'
                                      size='sm'
                                      variant='danger'
                                      onClick={() =>
                                        handleStatusShow(cmsPageId, isActive, tenantId, title?.EN)}
                                      hidden={isHidden({ module: { key: 'CMS', value: 'T' } })}
                                    >
                                      <FontAwesomeIcon icon={faWindowClose} />
                                    </Button>
                                  </Trigger>
                                  ))}
                            </td>
                          </tr>
                        )
                      }
                    )}

          {cmsData?.count === 0 && !loading && (
            <tr>
              <td colSpan={6} className='text-danger text-center'>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {cmsData?.count !== 0 && !loading && (
        <PaginationComponent
          page={cmsData?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}
      <ConfirmationModal
        setShow={setStatusShow}
        show={statusShow}
        handleYes={handleYes}
        active={status}
        name={name}
      />
    </>
  )
}
