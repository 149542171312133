import { Col, Container, InputGroup, Row, Form as BForm, Spinner, Tooltip, OverlayTrigger } from '@themesberg/react-bootstrap'
import BgImage from '../../../../assets/img/illustrations/signin.svg'

import React from 'react'
import { ErrorMessage, Form, Formik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faUnlockAlt, faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons'
import { resetPasswordSchema } from '../schema'

const ResetPassword = ({
  resetPassLoading,
  isSuperAdmin,
  handleResetPassword,
  showPassword,
  setShowPassword
}) => {
  return (
    <>
      <main>
        <section className='d-flex align-items-center my-5 mt-lg-6 mb-lg-5'>
          <Container>
            <Row
              className='justify-content-center form-bg-image'
              style={{ backgroundImage: `url(${BgImage})` }}
            >
              <Col
                xs={12}
                className='d-flex align-items-center justify-content-center'
              >
                <div className='bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500'>
                  <div className='text-center text-md-center mb-4 mt-md-0'>
                    <h2 className='mb-3'>Set a new password</h2>
                    <p>Enter a new password below to change your password</p>
                  </div>

                  <Formik
                    initialValues={{ password: '', confirmPassword: '' }}
                    validationSchema={resetPasswordSchema}
                    onSubmit={({ email, password }) =>
                      handleResetPassword({ password })}
                  >
                    {({
                      touched,
                      errors,
                      values,
                      handleChange,
                      handleSubmit,
                      handleBlur
                    }) => (
                      <div>
                        <Form>
                          <div className='form-group'>
                            <label
                              htmlFor='password'
                              className={`mt-3 ${
                              touched.password && errors.password
                                ? 'text-danger'
                                : ''
                            }`}
                            >
                              Password
                            </label>
                            &nbsp;
                            <OverlayTrigger
                              trigger='click'
                              overlay={(props) => (
                                <Tooltip
                                  id='tooltip' {...props}
                                >
                                  Password must have at least eight characters with at least one uppercase letter, one lowercase letter, one number and one special character
                                </Tooltip>
                              )}
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </OverlayTrigger>

                            <InputGroup
                              className={
                              touched.password && errors.password
                                ? 'border border-danger'
                                : ''
                            }
                            >
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faUnlockAlt} />
                              </InputGroup.Text>

                              <BForm.Control
                                name='password'
                                data-for='tip'
                                data-tip='Password must have at least eight characters with at least one uppercase letter, one lowercase letter, one number and one special character'
                                required
                                type={showPassword?.password ? 'text' : 'password'}
                                placeholder='qwerty'
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <InputGroup.Text>
                                {showPassword?.password
                                  ? <FontAwesomeIcon icon={faEye} style={{ cursor: 'pointer' }} onClick={() => { setShowPassword(prev => ({ ...prev, password: !prev?.password })) }} />
                                  : <FontAwesomeIcon icon={faEyeSlash} style={{ cursor: 'pointer' }} onClick={() => { setShowPassword(prev => ({ ...prev, password: !prev?.password })) }} />}
                              </InputGroup.Text>
                            </InputGroup>

                            <ErrorMessage
                              component='div'
                              name='password'
                              className='text-danger'
                            />
                          </div>

                          <div className='form-group'>
                            <label
                              htmlFor='confirmPassword'
                              className={`mt-3 ${
                              touched.confirmPassword && errors.confirmPassword
                                ? 'text-danger'
                                : ''
                            }`}
                            >
                              Confirm Password
                            </label>

                            <InputGroup
                              className={
                              touched.confirmPassword && errors.confirmPassword
                                ? 'border border-danger'
                                : ''
                            }
                            >
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faUnlockAlt} />
                              </InputGroup.Text>

                              <BForm.Control
                                name='confirmPassword'
                                required
                                type={showPassword?.confirmPassword ? 'text' : 'password'}
                                placeholder='qwerty'
                                value={values.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              <InputGroup.Text>
                                {showPassword?.confirmPassword
                                  ? <FontAwesomeIcon icon={faEye} style={{ cursor: 'pointer' }} onClick={() => { setShowPassword(prev => ({ ...prev, confirmPassword: !prev?.confirmPassword })) }} />
                                  : <FontAwesomeIcon icon={faEyeSlash} style={{ cursor: 'pointer' }} onClick={() => { setShowPassword(prev => ({ ...prev, confirmPassword: !prev?.confirmPassword })) }} />}
                              </InputGroup.Text>
                            </InputGroup>

                            <ErrorMessage
                              component='div'
                              name='confirmPassword'
                              className='text-danger'
                            />
                          </div>

                          <button
                            type='submit'
                            className='btn btn-primary btn-block mt-4'
                            onClick={handleSubmit}
                          >
                            {resetPassLoading && (
                              <Spinner
                                as='span'
                                animation='border'
                                size='sm'
                                role='status'
                                aria-hidden='true'
                              />
                            )}
                            Reset Password
                          </button>
                        </Form>
                      </div>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}

export default ResetPassword
