import { Button, Modal, Row, Col, Form as BForm } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ActionTypeSelect from '../../../../components/ActionTypeSelect'
import { volatilityRating } from '../constants'
import { getSuperAdminTAGameSubCategoryStart, getUniqueGameIdsStart, getUniqueGameIdsSuccess } from '../../../../store/redux-slices/superAdminCasinoManagement'
import CopyToClipboard from 'react-copy-to-clipboard'
import { capitalizeMyText } from '../../../../utils/helpers'
import ClientFilter from '../../../../components/ClientFilter'
import PortalFilter from '../../../../components/PortalFilter'
import { getIdsSchema } from './schema'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/pro-regular-svg-icons'
import { getItem } from '../../../../utils/storageUtils'

const GameIdsModal = ({
  show,
  setShow,
  isTenant
}) => {
  const dispatch = useDispatch()
  const [selectedClient, setSelectedClient] = useState('')
  const [copied, setCopied] = useState(false)
  const [selectedPortal, setSelectedPortal] = useState('')
  const { loading, casinoThemes, uniqueGameIds, allProviders: SAProviders, tenantGameSubCategories: SASubCategories } = useSelector((state) => state.superAdminCasino)
  const { subCategories, allProviders: tenantProviders } = useSelector((state) => state.tenantCasino)
  const allProviders = isTenant ? tenantProviders : SAProviders
  const tenantGameSubCategories = isTenant ? subCategories : SASubCategories

  const { countries: SACountries, tenantCountries } = useSelector((state) => state.fetch)

  const countries = isTenant ? tenantCountries?.rows : SACountries

  useEffect(() => {
    selectedPortal && dispatch(getSuperAdminTAGameSubCategoryStart({
      limit: '',
      pageNo: '',
      gameCategoryId: '',
      search: '',
      isActive: '',
      tenantId: selectedPortal,
      isSuperAdmin: true
    }))
  }, [selectedPortal])

  return (
    <Modal show={show} onHide={() => setShow(false)} size='lg'>
      <Formik
        enableReinitialize
        initialValues={{
          provider: '[]',
          themes: '[]',
          rating: '[]',
          search: '',
          countries: '',
          subCategory: '[]',
          status: '',
          deviceType: '',
          tenantId: ''
        }}
        validationSchema={isTenant ? null : getIdsSchema}
        onSubmit={(formValues) => {
          dispatch(getUniqueGameIdsStart({ ...formValues, tenantId: formValues.tenantId || getItem('tenant-id') || '', isTenant }))
          setCopied(false)
        }}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          resetForm
        }) => (
          <Form>
            <Modal.Body>
              <h3>
                Get Game IDS
              </h3>
              {!isTenant &&
                <Row>
                  <Col>
                    <ClientFilter
                      setSelectedClient={setSelectedClient}
                      setSelectedPortal={setSelectedPortal}
                      selectedClient={selectedClient}
                      hasAllOptions={false}
                      refetch={false}
                    />
                  </Col>

                  <Col>
                    <PortalFilter
                      setSelectedPortal={setSelectedPortal}
                      selectedPortal={selectedPortal}
                      selectedClient={selectedClient}
                      hasAllOptions={false}
                      setFieldValue={setFieldValue}
                    />
                    <ErrorMessage
                      component='div'
                      name='tenantId'
                      className='text-danger'
                    />
                  </Col>
                </Row>}

              <Row className='mt-3'>
                <Col>
                  <BForm.Label>
                    Providers
                  </BForm.Label>

                  <ActionTypeSelect
                    selectedAction={values.provider}
                    selectOptions={allProviders?.rows.reduce((result, currentValue) => {
                      result.push({ label: currentValue?.name, value: currentValue?.name })
                      return result
                    }, [])}
                    placeholder='Provider'
                    fieldName='provider'
                    setFieldValue={setFieldValue}
                  />
                </Col>

                <Col>
                  <BForm.Label>
                    Sub Category
                  </BForm.Label>

                  <ActionTypeSelect
                    selectedAction={values.subCategory}
                    selectOptions={tenantGameSubCategories?.rows.reduce((result, currentValue) => {
                      result.push({ label: currentValue?.name?.EN, value: currentValue?.tenantGameSubCategoryId })
                      return result
                    }, [])}
                    placeholder='Sub Category'
                    fieldName='subCategory'
                    setFieldValue={setFieldValue}
                    disabled={isTenant ? false : (!tenantGameSubCategories || tenantGameSubCategories?.count === 0 || !selectedPortal)}
                  />
                </Col>

                <Col>
                  <BForm.Label>
                    Status
                  </BForm.Label>

                  <BForm.Select
                    name='status'
                    onChange={(e) => setFieldValue('status', e.target.value)}
                    value={values.status}
                    size='sm'
                    style={{ maxWidth: '230px' }}
                  >
                    <option value=''>All</option>
                    <option value='true'>Active</option>
                    <option value='false'>In-Active</option>
                  </BForm.Select>
                </Col>
              </Row>

              <Row className='mt-3'>
                <Col>
                  <BForm.Label>
                    Device Type
                  </BForm.Label>

                  <BForm.Select
                    name='deviceType'
                    onChange={(e) => setFieldValue('deviceType', e.target.value)}
                    value={values.deviceType}
                    size='sm'
                    style={{ maxWidth: '230px' }}
                  >
                    <option value=''>All</option>
                    <option value='mobile'>Mobile</option>
                    <option value='desktop'>Desktop</option>
                  </BForm.Select>
                </Col>

                <Col>
                  <BForm.Label>
                    Volatility
                  </BForm.Label>

                  <ActionTypeSelect
                    selectedAction={values.rating}
                    selectOptions={volatilityRating}
                    placeholder='Volatility Rating'
                    fieldName='rating'
                    setFieldValue={setFieldValue}
                  />
                </Col>

                <Col>
                  <BForm.Label>
                    Themes
                  </BForm.Label>

                  <ActionTypeSelect
                    selectedAction={values.themes}
                    selectOptions={loading || !casinoThemes
                      ? []
                      : casinoThemes.reduce((result, currentValue) => {
                        currentValue !== null && result.push({ label: capitalizeMyText(currentValue, '_'), value: currentValue })
                        return result
                      }, [])}
                    placeholder='Themes'
                    fieldName='themes'
                    setFieldValue={setFieldValue}
                  />
                </Col>
              </Row>

              <Row className='mt-3'>
                <Col>
                  <BForm.Label>
                    Search
                  </BForm.Label>

                  <BForm.Control
                    type='search'
                    value={values.search}
                    placeholder='Search Name'
                    size='sm'
                    style={{ maxWidth: '230px' }}
                    onChange={(e) => setFieldValue('search', e.target.value)}
                  />
                </Col>

                <Col>
                  <BForm.Label>
                    Countries
                  </BForm.Label>

                  <BForm.Select
                    name='countries'
                    onChange={(e) => setFieldValue('countries', e.target.value)}
                    value={values.countries}
                    size='sm'
                    style={{ maxWidth: '230px' }}
                  >
                    <option value='' disabled>-----Select Country----</option>
                    {countries?.map(({ name, code }) => {
                      return (
                        <option value={code} key={code}>{name?.EN}</option>
                      )
                    })}
                  </BForm.Select>

                  <ErrorMessage
                    component='div'
                    name='countries'
                    className='text-danger'
                  />
                </Col>
              </Row>

              <Row className='mt-3'>
                <h5 className='d-flex'>
                  {isTenant ? 'Master' : ''} Game IDS:&nbsp;
                  {uniqueGameIds?.length > 0 &&
                    <CopyToClipboard
                      text={uniqueGameIds || ''}
                      onCopy={() => {
                        setCopied('Copied To ClipBoard', 'success')
                      }}
                    >
                      <Button
                        className='copy d-flex align-items-center'
                        variant='light'
                        size='sm'
                      >
                        <FontAwesomeIcon icon={faCopy} />&nbsp;
                        {copied && 'Copied'}
                      </Button>
                    </CopyToClipboard>}
                </h5>
                <h6>{uniqueGameIds ? uniqueGameIds?.toString()?.split(',')?.join(', ') : 'NA'}</h6>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Button variant='outline-success' onClick={handleSubmit}>
                Get Game IDS
              </Button>

              <Button
                variant='outline-primary' onClick={() => {
                  resetForm()
                  setCopied(false)
                  setSelectedClient('')
                  setSelectedPortal('')
                  dispatch(getUniqueGameIdsSuccess(null))
                  setShow(false)
                }}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Form>)}
      </Formik>
    </Modal>
  )
}

export default GameIdsModal
