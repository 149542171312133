import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getPaymentProviderStart, updatePaymentProviderStatusStart } from '../../../../store/redux-slices/payment'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { isTenant } from '../../../../utils/constants'

const useCustomPayment = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()
  const [name, setName] = useState('')
  const [data, setData] = useState({})
  const isInitialRender = useDidMountEffect()
  const [active, setActive] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [type, setType] = useState(state?.type || '')
  const [reorderModal, setReorderModal] = useState(false)
  const [status, setStatus] = useState(state?.status || '')
  const [search, setSearch] = useState(state?.search || '')
  const [category, setCategory] = useState(state?.category || '')
  const { tenantDetails } = useSelector(state => state.login)
  const [newMethodModal, setNewMethodModal] = useState(false)
  const [selectedProviderId, setSelectedProviderId] = useState('')
  const [selectedClient, setSelectedClient] = useState(state?.adminId || '')
  const [selectedPortal, setSelectedPortal] = useState(state?.tenantId || '')
  const { providerLoading, paymentProviders } = useSelector(state => state.payment)

  useEffect(() => {
    if (isTenant || selectedPortal) {
      dispatch(getPaymentProviderStart({
        isTenant,
        paymentType: type,
        aggregator: '',
        tenant: '',
        group: '',
        status,
        tenantIds: [],
        category,
        adminId: selectedClient,
        tenantId: selectedPortal
      }))
    }
  }, [type, status, category, selectedClient, selectedPortal])

  useEffect(() => {
    setProvidersData()
  }, [paymentProviders])

  const setProvidersData = () => {
    const myData = {}
    if (!Object.keys(myData)?.length && paymentProviders && paymentProviders?.length) {
      paymentProviders.forEach(({ aggregator, group }) => {
        if (myData?.[aggregator]?.[group] === undefined) {
          const provDetails = paymentProviders?.filter((item) =>
            item.group === group && item.aggregator === aggregator && item?.name?.toLowerCase().includes(search.toLowerCase()))
          if (provDetails?.length) {
            if (myData[aggregator] === undefined) myData[aggregator] = []
            if (myData[aggregator][group] === undefined) myData[aggregator][group] = []
            myData[aggregator][group] = provDetails
          }
        }
      }
      )
      setData(myData)
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        setProvidersData()
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const handleShowModal = (id, act, myName) => {
    setSelectedProviderId(id)
    setActive(!act)
    setName(myName)
    setShowModal(true)
  }

  const handleYes = () => {
    dispatch(updatePaymentProviderStatusStart({
      isTenant,
      status,
      paymentType: type,
      category,
      adminId: selectedClient,
      tenantId: selectedPortal,
      data: {
        paymentProviderId: selectedProviderId,
        status: active,
        code: name?.includes('Deposit') ? 'PAYMENT_PROVIDER_DEPOSIT' : 'PAYMENT_PROVIDER_WITHDRAW',
        tenantId: selectedPortal || tenantDetails?.tenantId
      }
    }))
    setShowModal(false)
    setData({})
  }

  useEffect(() => { window.history.replaceState({}, document.title) }, [])

  return {
    name,
    data,
    type,
    active,
    status,
    search,
    setType,
    category,
    isTenant,
    navigate,
    handleYes,
    showModal,
    setStatus,
    setSearch,
    setCategory,
    reorderModal,
    setShowModal,
    tenantDetails,
    selectedClient,
    selectedPortal,
    newMethodModal,
    setReorderModal,
    handleShowModal,
    setSelectedClient,
    setSelectedPortal,
    setNewMethodModal,
    loading: providerLoading
  }
}

export default useCustomPayment
