import { Col, Row, Tab, Tabs } from '@themesberg/react-bootstrap'
import React from 'react'
import Preloader from '../../../../components/Preloader'
import useEditTenantAdmin from '../hooks/useEditTenantAdmin'
import Permissions from '../../AdminDetails/components/Permissions'

const OwnerDetails = () => {
  const {
    loading,
    adminUserDetails,
    selectedTab,
    setSelectedTab,
    ownerDetailConstants
  } = useEditTenantAdmin()

  return (
    <>
      {loading
        ? <Preloader />
        : (
          <>
            <Row>
              <Col className='d-flex'><h3>Tenant Owner: {adminUserDetails?.firstName} {adminUserDetails?.lastName}</h3></Col>
            </Row>
            <Tabs
              activeKey={selectedTab}
              onSelect={(tab) => setSelectedTab(tab)}
              className='nav-light'
            >
              <Tab eventKey='overview' title='Overview'>
                <div className='mt-5'>
                  <Row className='mt-3 '>
                    {adminUserDetails && Object.keys(adminUserDetails)?.length > 0 && ownerDetailConstants.map(({ key, value }, index) => {
                      return (
                        value !== 'password' &&
                          <Col lg={4} md={4} sm={6} className='my-2' key={index}>
                            <div className='bg-light py-2 px-3 rounded'>
                              <label className='fw-bold'>{key}</label>
                              <p className='mb-0'>
                                {adminUserDetails[value] || 'NA'}
                              </p>
                            </div>
                          </Col>
                      )
                    })}
                  </Row>
                </div>
              </Tab>

              {Object.keys(adminUserDetails)?.length > 0 &&
                <Tab eventKey='permissions' title='Permissions'>
                  <div className='mt-5'>
                    <Row className='mt-3 d-flex text-left'>
                      <Permissions adminDetails={adminUserDetails} />
                    </Row>
                  </div>
                </Tab>}
            </Tabs>
          </>
          )}
    </>
  )
}

export default OwnerDetails
