import React, { useEffect } from 'react'
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import TenantPages from '../components/TenantPages'
import SuperAdminPages from '../components/SuperAdminPages'
import { SuperAdminRoutes, CommonRoutes, TenantRoutes } from '../../../routes'
import NotFound from '../../NotFound'
import SuperAdminSignIn from '../../Super-Admin/SuperAdminSignin'
import TenantSignIn from '../../Tenant/TenantSignin'
import { getItem, removeLoginToken } from '../../../utils/storageUtils'
import TenantNotFound from '../../TenantNotFound'
import ForgotPassword from '../ForgotPassword'
import { IdleTimer } from '../../../utils/idealBot'
import { resetState } from '../../../store/redux-slices'

export default () => {
  let { role } = useSelector((state) => state.login)
  const token = getItem('access-token')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  if (!role) {
    role = getItem('role')
  }
  const isSuperAdmin = window.location.href?.includes(process.env.REACT_APP_SA_URL)
  const isTenant = window.location.href?.includes(process.env.REACT_APP_TA_URL)

  useEffect(() => {
    if (!token && !window.location.pathname.includes('reset-password') && window.location.pathname !== '/406') {
      isTenant ? navigate(TenantRoutes.TenantSignin) : isSuperAdmin && navigate(SuperAdminRoutes.SuperAdminSignin)
    }
  }, [token])

  const logout = () => {
    removeLoginToken()
    dispatch(resetState())
    if (role === 'Admin') {
      navigate(TenantRoutes.TenantSignin)
    } else if (role === 'Super Admin') {
      navigate(SuperAdminRoutes.SuperAdminSignin)
    }
  }

  const idletimer = new IdleTimer(logout, 3600000)

  useEffect(() => {
    if (token) {
      idletimer.deactivate()
      setTimeout(() => {
        idletimer.activate()
      }, 1000)
    }
  }, [token])

  return (
    <Routes>
      {/* Public Routes without sidebar */}
      <Route
        path={SuperAdminRoutes.SuperAdminSignin}
        element={<SuperAdminSignIn />}
      />

      <Route path={CommonRoutes.TenantNotFound} element={<TenantNotFound />} />

      <Route path={TenantRoutes.TenantSignin} element={<TenantSignIn />} />

      <Route path={CommonRoutes.NotFound} element={<NotFound />} />

      <Route path={CommonRoutes?.ForgotPassword} element={<ForgotPassword />} />

      <Route path={CommonRoutes?.ResetPassword} element={<ForgotPassword />} />

      {role === 'Super Admin' && SuperAdminPages()}

      {role === 'Admin' && TenantPages()}

      <Route
        path='*'
        element={<Navigate replace to={CommonRoutes.NotFound} />}
      />
    </Routes>
  )
}
