import React from 'react'
import { Tabs, Tab, Row, Col, Card, Button } from '@themesberg/react-bootstrap'
import Overview from './components/Overview'
// import AdminWallet from './components/AdminWallet'
import Permissions from './components/Permissions'
import Preloader from '../../../components/Preloader'
import useAdminDetails from './hooks/useAdminDetails'

const AdminUserDetails = () => {
  const {
    selectedTab,
    setSelectedTab,
    adminUserDetails,
    loading,
    adminUserId,
    navigate
  } = useAdminDetails()

  return (
    <>
      {loading
        ? (
          <Preloader />
          )
        : (
          <>
            <Row>
              <Col className='d-flex'>
                <h3>Manager: </h3>
                <h3>
                  <div
                    style={{
                      width: '350px',
                      whitespace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden'
                    }}
                  >
                    &nbsp;{adminUserDetails &&
                    `${adminUserDetails?.firstName} ${adminUserDetails?.lastName}`}
                  </div>
                </h3>
              </Col>

              <Col xs lg='1'>
                <Button
                  className='d-flex mb-2'
                  variant='warning'
                  onClick={() => navigate(`/tenant/admins/edit/${adminUserId}`)}
                >
                  Edit
                </Button>
              </Col>
            </Row>
            <Card body>
              <Tabs
                activeKey={selectedTab}
                onSelect={(tab) => setSelectedTab(tab)}
                className='nav-light'
              >
                <Tab eventKey='overview' title='Overview'>
                  <div className='mt-5'>
                    <Row className='mt-3'>
                      <Overview adminUserDetails={adminUserDetails} />
                    </Row>
                  </div>
                </Tab>

                <Tab eventKey='permissions' title='Permissions'>
                  <div className='mt-5'>
                    <Row className='mt-3'>
                      <Permissions adminUserDetails={adminUserDetails} />
                    </Row>
                  </div>
                </Tab>
              </Tabs>
            </Card>
          </>
          )}
    </>
  )
}

export default AdminUserDetails
