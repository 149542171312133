import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getBannedPlayersStart,
    getBannedPlayersCompleted,
    getBannedPlayersFrailer,
    addBannedPlayerStart,
    addBannedPlayerCompleted,
    addBannedPlayerFailed,
    editBannedPlayerStart,
    editBannedPlayerCompleted,
    editBannedPlayerFailed,
    deleteBannedPlayerStart,
    deleteBannedPlayerCompleted,
    deleteBannedPlayerFailed,
    bannedPlayerSettingStart,
    bannedPlayerSettingCompleted,
    bannedPlayerSettingFailed
  },
  reducer
} = createSlice({
  name: 'bannedPlayers',
  initialState: {
    loading: false,
    bannedPlayers: [],
    banPlayer: false
  },
  reducers: {
    getBannedPlayersStart: (state) => ({
      ...state,
      loading: true
    }),
    getBannedPlayersCompleted: (state, { payload }) => ({
      ...state,
      loading: false,
      bannedPlayers: payload
    }),
    getBannedPlayersFrailer: (state) => ({
      ...state,
      loading: false,
      bannedPlayers: []
    }),

    // Add Banned Player
    addBannedPlayerStart: (state) => ({
      ...state
    }),
    addBannedPlayerCompleted: (state, { payload }) => ({
      ...state,
      bannedPlayers: [...state.bannedPlayers, payload]
    }),
    addBannedPlayerFailed: (state) => ({
      ...state
    }),

    // Edit Banned Player
    editBannedPlayerStart: (state) => ({
      ...state
    }),
    editBannedPlayerCompleted: (state, { payload }) => ({
      ...state,
      bannedPlayers: state.bannedPlayers.map(player =>
        player.id === payload.id ? payload : player
      )
    }),
    editBannedPlayerFailed: (state) => ({
      ...state
    }),

    // Delete Banned Player
    deleteBannedPlayerStart: (state) => ({
      ...state
    }),
    deleteBannedPlayerCompleted: (state, { payload }) => ({
      ...state,
      bannedPlayers: state.bannedPlayers.filter(player => player.id !== payload)
    }),
    deleteBannedPlayerFailed: (state) => ({
      ...state
    }),

    bannedPlayerSettingStart: (state) => ({
      ...state
    }),
    bannedPlayerSettingCompleted: (state, { payload }) => ({
      ...state,
      banPlayer: payload
    }),
    bannedPlayerSettingFailed: (state) => ({
      ...state
    })
  }
})

export default reducer

export {
  getBannedPlayersStart,
  getBannedPlayersCompleted,
  getBannedPlayersFrailer,
  addBannedPlayerStart,
  addBannedPlayerCompleted,
  addBannedPlayerFailed,
  editBannedPlayerStart,
  editBannedPlayerCompleted,
  editBannedPlayerFailed,
  deleteBannedPlayerStart,
  deleteBannedPlayerCompleted,
  deleteBannedPlayerFailed,
  bannedPlayerSettingStart,
  bannedPlayerSettingCompleted,
  bannedPlayerSettingFailed
}
