import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createTenantAdminStart, getOwnerPermissionStart, updateTenantAdminStart } from '../../../../store/redux-slices/tenants'
import { useEffect, useState } from 'react'
import { permissionLabel } from '../../../../components/ProfilePage/constants'

const useCreateTenantAdmin = (tenantData) => {
  const { adminUserId } = useParams()
  const [type, setType] = useState('password')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading, tenantDetails, ownerPermission } = useSelector((state) => state.tenants)
  // const { tenantDetails } = useSelector((state) => state.tenants)
  const { wallets } = useSelector((state) => state.login)
  const allowedCurrency = []
  wallets &&
    wallets.rows.forEach(function (element) {
      allowedCurrency.push({ value: element.currencyCode, label: element.currencyCode })
    })

  const [currencies, setCurrencies] = useState([])

  useEffect(() => {
    const tenantCurrencies = []
    const currData = tenantData?.allowedCurrencies
    if (currData &&
      adminUserId) {
      currData.forEach(function (element) {
        tenantCurrencies.push({ label: element, value: element })
      })
      setCurrencies(tenantCurrencies)
    }
  }, [adminUserId, tenantData?.allowedCurrencies])

  useEffect(() => {
    dispatch(getOwnerPermissionStart())
  }, [])

  const createTenantAdmin = (createTenantAdminData) => dispatch(createTenantAdminStart({ createTenantAdminData, navigate }))
  const updateTenantAdmin = ({ createTenantAdminData, adminUserId }) => dispatch(updateTenantAdminStart({ createTenantAdminData, adminUserId, navigate }))

  return {
    wallets,
    createTenantAdmin,
    navigate,
    loading,
    tenantDetails,
    allowedCurrency,
    updateTenantAdmin,
    tenantData,
    adminUserId,
    currencies,
    setCurrencies,
    type,
    setType,
    ownerPermission,
    permissionLabel
  }
}

export default useCreateTenantAdmin
