import { takeLatest, put } from 'redux-saga/effects'
import {
  cancelDocumentRequest,
  getDocumentLabel,
  updateDocumentLabel,
  createDocumentLabel,
  requestDocument,
  getloyaltyLevel,
  updateloyaltyLevel,
  getAllSABanners,
  uploadSABanner,
  updateSABanner,
  updateSAUserStatus,
  tenantViewToggleStatus
} from '../../utils/apiCalls'
import {
  getDocumentLabelStart,
  getDocumentLabelSuccess,
  getDocumentLabelFailure,
  updateDocumentLabelStart,
  updateDocumentLabelComplete,
  createDocumentLabelStart,
  createDocumentLabelComplete,
  updateDocumentStart,
  updateDocumentComplete,
  getloyaltyLevelStart,
  getloyaltyLevelSuccess,
  getloyaltyLevelFailure,
  updateloyaltyLevelStart,
  updateloyaltyLevelComplete,
  getAllSABannersStart,
  getAllSABannersSuccess,
  getAllSABannersFailure,
  uploadSABannerStart,
  uploadSABannerComplete,
  updateSABannerStart,
  updateSABannerComplete,
  updateSAUserStatusStart,
  updateSAUserStatusCompleted,
  updateSAUserStatusFailure
} from '../redux-slices/superAdminSettings'
import { toast } from '../../components/Toast'
import { serialize } from 'object-to-formdata'
import { isTenant } from '../../utils/constants'
import { getTenantStart } from '../redux-slices/tenants'
import { getSAUserDocumentStart, getUserStart } from '../redux-slices/fetchData'

export default function * superAdminSettingsWatcher () {
  yield takeLatest(getDocumentLabelStart.type, getDocumentLabelWorker)
  yield takeLatest(updateDocumentLabelStart.type, updateDocumentLabelWorker)
  yield takeLatest(createDocumentLabelStart.type, createDocumentLabelWorker)
  yield takeLatest(updateDocumentStart.type, updateDocumentWorker)
  yield takeLatest(getloyaltyLevelStart.type, getloyaltyLevelWorker)
  yield takeLatest(updateloyaltyLevelStart.type, updateloyaltyLevelWorker)
  yield takeLatest(getAllSABannersStart.type, getAllSABannersWorker)
  yield takeLatest(updateSABannerStart.type, updateSABannerWorker)
  yield takeLatest(uploadSABannerStart.type, uploadSABannerWorker)
  yield takeLatest(updateSAUserStatusStart.type, updateSAUserUpdateWorker)
}

function * getDocumentLabelWorker (action) {
  try {
    const { userId, isTenant = false } = action && action.payload

    const { data } = yield getDocumentLabel(userId, isTenant)

    yield put(getDocumentLabelSuccess(data?.data?.documentLabel))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getDocumentLabelFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateDocumentLabelWorker (action) {
  try {
    const { data } = action && action.payload

    yield updateDocumentLabel(data)

    yield put(updateDocumentLabelComplete())
    yield toast('Labels Updated SuccessFully', 'success')
    yield put(getDocumentLabelStart({ userId: '' }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateDocumentLabelComplete())
  }
}

function * createDocumentLabelWorker (action) {
  try {
    const { data } = action && action.payload

    yield createDocumentLabel(data)

    yield put(createDocumentLabelComplete())
    yield toast('Label Created SuccessFully', 'success')
    yield put(getDocumentLabelStart({ userId: '' }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(createDocumentLabelComplete())
  }
}

function * updateDocumentWorker (action) {
  try {
    const { data, isRequested, isTenant = false } = action && action.payload

    isRequested ? yield requestDocument(data, isTenant) : yield cancelDocumentRequest(data, isTenant)

    yield updateDocumentComplete()
    yield put(getUserStart({ userId: data?.userId, isTenant }))
    yield put(getSAUserDocumentStart({ userId: data?.userId, isTenant }))
    yield put(getDocumentLabelStart({ userId: data?.userId, isTenant }))

    yield toast(`Document ${isRequested ? 'Requested' : 'UnRequested'} Successfully`, 'success')
  } catch (e) {
    yield updateDocumentComplete()

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getloyaltyLevelWorker (action) {
  try {
    const { isTenant, tenantId } = action && action.payload

    const { data } = yield getloyaltyLevel({ isTenant, tenantId: tenantId || '' })

    yield put(getloyaltyLevelSuccess(data?.data?.loyaltyLevel))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getloyaltyLevelFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateloyaltyLevelWorker (action) {
  try {
    const { loyaltyLevel, isTenant, tenant = false, tenantId = '' } = action && action.payload

    yield updateloyaltyLevel({ isTenant, data: { loyaltyLevel: loyaltyLevel?.loyaltyLevel, tenantId } })

    yield put(updateloyaltyLevelComplete())

    yield toast('Data Updated SuccessFully', 'success')
    if (tenant) yield put(getTenantStart({ tenantId }))
    else yield put(getloyaltyLevelStart({ isTenant }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateloyaltyLevelComplete())
  }
}

function * getAllSABannersWorker (action) {
  try {
    const { adminId, tenantId } = action && action.payload

    const { data } = yield getAllSABanners({ adminId, tenantId })

    yield put(getAllSABannersSuccess(isTenant ? data?.data : data?.data?.banners))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllSABannersFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateSABannerWorker (action) {
  try {
    const { data: banner, adminId, tenantId } = action && action.payload
    const data = serialize(banner, { indices: true })

    yield updateSABanner(data)

    yield put(updateSABannerComplete())

    yield toast('Banner Updated SuccessFully', 'success')
    yield put(getAllSABannersStart({
      adminId,
      tenantId
    }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateSABannerComplete())
  }
}

function * uploadSABannerWorker (action) {
  try {
    const { data: banner } = action && action.payload
    const data = serialize(banner)

    yield uploadSABanner(data)

    yield put(uploadSABannerComplete())

    yield toast('Banner Uploaded SuccessFully', 'success')
    yield put(getAllSABannersStart({
      adminId: '',
      tenantId: ''
    }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(uploadSABannerComplete())
  }
}

function * updateSAUserUpdateWorker (action) {
  try {
    const { data, isTenant } = action && action.payload

    isTenant
      ? yield tenantViewToggleStatus(data)
      : yield updateSAUserStatus({ ...data }, isTenant)
    yield toast('User Status Updated SuccessFully', 'success')
    yield put(updateSAUserStatusCompleted())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(updateSAUserStatusFailure(e?.response?.data?.errors[0]?.description))
  }
}
