import React from 'react'
import sortArrowIcon from '../SortArrowsIcon'
import { Table } from '@themesberg/react-bootstrap'
import { getTextColor } from '../../utils/dashboardEffects'
import { internationalNumberFormatter } from '../../utils/helpers'

const JackpotContributionTable = ({ tableData, sortBy, setSortBy, hover, setHover }) => {
  return (
    <div style={{ overflow: 'auto', maxHeight: '385px' }}>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {[
              'Id',
              'Identifier',
              'Jackpot Contribution',
              'Jackpot Win',
              'Jackpot Revenue'
            ].map((h) => (
              <th key={h}>
                {h}
                {sortArrowIcon(h, sortBy, setSortBy, hover, setHover, 'jackpotReport')}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {tableData && tableData.length > 0
            ? tableData.map(({ id, identifier, jackpotContribution, jackpotRevenue, jackpotWin }, idx) => {
              return (
                <tr key={`jackpotContribution${idx}`}>
                  <td>{id}</td>
                  <td>{identifier}</td>
                  <td className={`${getTextColor(jackpotContribution)}`}>€ {internationalNumberFormatter(jackpotContribution) || '0'}</td>
                  <td className={`${getTextColor(jackpotWin)}`}>€ {internationalNumberFormatter(jackpotWin) || '0'}</td>
                  <td className={`${getTextColor(jackpotRevenue)}`}>€ {internationalNumberFormatter(jackpotRevenue) || '0'}</td>
                </tr>
              )
            })
            : (
              <tr>
                <td colSpan={8} className='text-danger text-center'>
                  No data found
                </td>
              </tr>
              )}
        </tbody>
      </Table>
    </div>
  )
}

export default JackpotContributionTable
