import * as Yup from 'yup'

export const addForm = Yup.object().shape({
  userName: Yup.string()
    .max(50, 'User Name must be less than 50 characters')
    .matches(/^[A-Za-z0-9 ]+$/, 'Only Alpha-Numeric Values Allowed')
    .required('UserName Required'),
  description: Yup.string()
    .required('Description Required'),
  rating: Yup.number().required().positive(),
  status: Yup.boolean().required()
})

export const editForm = Yup.object().shape({
  userName: Yup.string()
    .max(50, 'User Name must be less than 50 characters')
    .matches(/^[A-Za-z0-9 ]+$/, 'Only Alpha-Numeric Values Allowed')
    .required('UserName Required'),
  description: Yup.string()
    .required('Description Required'),
  rating: Yup.number().required().positive(),
  status: Yup.boolean().required()

})
