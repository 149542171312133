import React from 'react'
import {
  Row,
  Col,
  Table,
  Form
} from '@themesberg/react-bootstrap'
import PaginationComponent from '../../../components/Pagination'
import Trigger from '../../../components/OverlayTrigger'
import useSubCategoryListing from './useSubCategoryListing'
import { tableHeaders } from './constants'

export default () => {
  const {
    limit,
    setLimit,
    page,
    setPage,
    loading,
    totalPages,
    gameSubCategory,
    getGameName,
    search,
    setSearch,
    active,
    setActive,
    gameCategory,
    categoryId,
    setCategoryId
  } = useSubCategoryListing()

  return (
    <>
      <Row>
        <Col sm={8}>
          <h3>Master Casino Sub Categories</h3>
        </Col>
      </Row>

      <Row className='mt-2 mb-3'>
        <Col xs='auto'>
          <div className='d-flex justify-content-start align-items-center w-100'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              Category
            </Form.Label>

            <Form.Select
              onChange={(e) => setCategoryId(e.target.value)}
              value={categoryId}
              size='sm'
              style={{ maxWidth: '230px', marginBottom: '5px' }}
            >
              <option value=''>All</option>
              {gameCategory && gameCategory?.rows.map(({ masterGameCategoryId, name }) => {
                return <option key={masterGameCategoryId} value={masterGameCategoryId}>{name?.EN}</option>
              })}
            </Form.Select>
          </div>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-start align-items-center w-100'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              Search
            </Form.Label>

            <Form.Control
              type='search'
              value={search}
              placeholder='Search Name'
              size='sm'
              style={{ maxWidth: '230px' }}
              onChange={(event) => setSearch(event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
            />
          </div>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-start align-items-center w-100'>
            <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
              Status
            </Form.Label>

            <Form.Select
              onChange={(e) => setActive(e.target.value)}
              value={active}
              size='sm'
              style={{ maxWidth: '230px' }}
            >
              <option value=''>All</option>
              <option value='true'>Active</option>
              <option value='false'>In-Active</option>
            </Form.Select>
          </div>
        </Col>

      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, idx) => (
              <th
                key={idx}
              >
                {h.label}
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {gameSubCategory && !loading &&
                      gameSubCategory?.rows?.map(
                        (
                          {
                            masterGameSubCategoryId,
                            name,
                            isActive,
                            imageUrl,
                            masterGameCategoryId
                          }
                        ) => {
                          return (
                            <tr key={masterGameSubCategoryId}>

                              <td>{masterGameSubCategoryId}</td>

                              <td>
                                <Trigger message={name?.EN}>
                                  <span
                                    style={{
                                      width: '100px',
                                      cursor: 'pointer'
                                    }}
                                    className='d-inline-block text-truncate'
                                  >
                                    {name?.EN}
                                  </span>
                                </Trigger>
                              </td>

                              <td>{getGameName(masterGameCategoryId)}</td>

                              <td>
                                <Trigger message={imageUrl}>
                                  <span
                                    style={{
                                      width: '230px',
                                      cursor: 'pointer'
                                    }}
                                    onClick={() => window.open(imageUrl)}
                                    className='d-inline-block text-truncate text-link'
                                  >
                                    {imageUrl}
                                  </span>
                                </Trigger>
                              </td>

                              <td>
                                {isActive
                                  ? (
                                    <span className='text-success'>Active</span>
                                    )
                                  : (
                                    <span className='text-danger'>In Active</span>
                                    )}
                              </td>

                            </tr>
                          )
                        }
                      )}

          {gameSubCategory?.count === 0 && !loading &&
                      (
                        <tr>
                          <td
                            colSpan={5}
                            className='text-danger text-center'
                          >
                            No data found
                          </td>
                        </tr>
                      )}
        </tbody>
      </Table>

      {gameSubCategory?.count !== 0 && !loading &&
              (
                <PaginationComponent
                  page={gameSubCategory?.count < page ? setPage(1) : page}
                  totalPages={totalPages}
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                />
              )}

    </>
  )
}
