import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBonusStart } from '../../store/redux-slices/admins'
import { getAllTABonusStart, getBonusStart, getSegmentsStart, getTABonusStart, issueBonusStart } from '../../store/redux-slices/bonus'
import { getAllCasinoGamesStart, getSuperAdminAggregatorsStart } from '../../store/redux-slices/superAdminCasinoManagement'
import { getAllMasterGamesStart } from '../../store/redux-slices/tenantCasino'
import { safeStringify } from '../../utils/helpers'
import { getItem } from '../../utils/storageUtils'
import { checkIsTenant } from '../../utils/constants'
import { getTenantStart } from '../../store/redux-slices/tenants'
import useDidMountEffect from '../../utils/useDidMountEffect'

const useBonusIssue = (isTenant, issueType, setShowModal) => {
  const dispatch = useDispatch()
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const [selectedBonus, setSelectedBonus] = useState('')
  const { tenantDetails } = useSelector((state) => state.login)
  const { tenantDetails: SATenantDetails } = useSelector((state) => state.tenants)
  const { bonusList, loading: bonusLoading } = useSelector((state) => state.admins)
  const [segmentsOptions, setSegmentOptions] = useState([])
  const [prepaidsData, setPrepaidsData] = useState([])
  const [selectedAggregator, setSelectedAggregator] = useState('')
  const [excludeSegmentsOptions, setExcludeSegmentOptions] = useState([])
  const [search, setSearch] = useState('')
  const [gameIds, setGameIds] = useState([])
  const [gamesData, setGamesData] = useState([])
  const isInitialRender = useDidMountEffect()
  const { masterGames, loading: tenantLoading } = useSelector(state => state.tenantCasino)
  const { casinoGamesData, loading: superAdminLoading, aggregators } = useSelector(state => state.superAdminCasino)
  const { bonusList: TABonusList, loading: TABonusLoading, bonusDetail, segments } = useSelector((state) => state.bonus)

  const aggregatorId = useMemo(() => aggregators?.rows?.find(({ name }) => name === selectedAggregator)?.masterGameAggregatorId, [selectedAggregator])

  useEffect(() => {
    if (issueType === 'bonus') {
      !checkIsTenant()
        ? selectedPortal && dispatch(getAllBonusStart({
          adminId: selectedClient,
          tenantId: selectedPortal,
          bonusType: safeStringify(['match', 'balance', 'wagering', 'winBoost', 'zero_deposit'])
        }))
        : dispatch(getAllTABonusStart({
          limit: '',
          pageNo: '',
          search: '',
          isActive: '',
          bonusType: safeStringify(['match', 'balance', 'wagering', 'winBoost', 'zero_deposit'])
        }))
    } else {
      (selectedPortal || checkIsTenant()) && dispatch(getSegmentsStart({ isTenant: checkIsTenant(), tenantId: checkIsTenant() ? getItem('tenant-id') : selectedPortal }))
      if (checkIsTenant()) {
        dispatch(getAllTABonusStart({
          limit: '',
          pageNo: '',
          search: '',
          isActive: '',
          bonusType: safeStringify(['freespins']),
          aggregators,
          selectedAggregator,
          setSelectedAggregator,
          setGameIds
        }))
      } else {
        selectedPortal && dispatch(getAllBonusStart({
          adminId: selectedClient,
          tenantId: selectedPortal,
          limit: '',
          pageNo: '',
          search: '',
          bonusType: safeStringify(['freespins']),
          isActive: '',
          userId: ''
        }))
      }
    }
    setSelectedBonus('')
  }, [selectedPortal])

  useEffect(() => {
    (selectedPortal || checkIsTenant()) && dispatch(getSuperAdminAggregatorsStart({ limit: '', pageNo: '', search: '' }))
    if (selectedPortal) dispatch(getTenantStart({ tenantId: selectedPortal }))
    setSelectedAggregator('')
  }, [selectedPortal])

  useEffect(() => {
    if (selectedBonus?.bonusId && issueType === 'bonus') {
      isTenant
        ? dispatch(getTABonusStart({ bonusId: selectedBonus?.bonusId, userBonusId: '' }))
        : dispatch(getBonusStart({ bonusId: selectedBonus?.bonusId, isTenant: false, userBonusId: '' }))
    }
  }, [selectedBonus])

  useEffect(() => {
    if (issueType === 'bonus') {
      const BList = isTenant ? TABonusList : bonusList
      if (BList?.length) {
        setSelectedBonus(BList?.[0])
        dispatch(getSegmentsStart({ isTenant: checkIsTenant(), tenantId: checkIsTenant() ? getItem('tenant-id') : selectedPortal }))
      } else {
        setSelectedBonus('')
      }
    } else {
      updateBonusOptions()
    }
  }, [bonusList, TABonusList])

  useEffect(() => {
    let options = []
    if (segments?.length) {
      options = segments.map((item) => { return ({ label: `${item.name} (${item.id})`, value: item.id }) })
      setSegmentOptions(options)
      setExcludeSegmentOptions(options)
    }
  }, [segments])

  const issueBonusHandler = (data) => {
    dispatch(issueBonusStart({ data, isTenant, handleIssue, user: false }))
  }

  const handleIssue = () => {
    setShowModal(false)
    setSelectedBonus('')
    setSelectedClient('')
    setSelectedPortal('')
    setGameIds([])
    setGamesData([])
    setSearch('')
  }

  const getTAGames = () => {
    dispatch(
      getAllMasterGamesStart({
        limit: '',
        pageNo: '',
        search,
        casinoCategoryId: '',
        providerId: '',
        freespins: true,
        aggregatorId,
        setGamesData: isInitialRender && setGamesData
      })
    )
  }

  const getSAGames = () => {
    dispatch(
      getAllCasinoGamesStart({
        limit: '',
        pageNo: '',
        casinoCategoryId: '',
        search,
        isActive: '',
        tenantId: '',
        selectedProvider: '',
        freespins: true,
        bonusId: '',
        aggregatorId,
        setGamesData: isInitialRender && setGamesData
      })
    )
  }

  useEffect(() => {
    if (!isInitialRender) {
      const delayDebounceFn = setTimeout(() => {
        isTenant
          ? getTAGames()
          : getSAGames()
      }, 1000)

      return () => clearTimeout(delayDebounceFn)
    }
  }, [search, selectedAggregator])

  const handleSelect = (e, id) => {
    if (!e.target.checked) {
      setGameIds(gameIds.filter(gameId => gameId !== id))
    } else {
      const data = [...gameIds]
      data.unshift(id)
      setGameIds(data)
    }
  }

  const availableGames = casinoGamesData || masterGames
  const getData = (type, gameId) => {
    const data = gamesData?.find((game) => game.masterCasinoGameId === gameId)
    if (type === 'name') {
      return data?.name
    } else {
      return data?.masterCasinoGameId
    }
  }

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const data = [...gameIds]
      for (const index in availableGames) {
        const id = availableGames?.[index]?.masterCasinoGameId
        !data.includes(id) && data.push(id)
      }
      setGameIds(data)
    } else {
      let data = [...gameIds]
      for (const index in availableGames) {
        const gameId = availableGames?.[index]?.masterCasinoGameId
        data = data.filter(id => id !== gameId)
      }
      setGameIds(data)
    }
  }

  const [bonusOptions, setBonusOptions] = useState([])
  const updateBonusOptions = () => {
    if (!isTenant && bonusList?.length > 0) {
      const bonusOpt = []
      bonusList.forEach(function (element) {
        bonusOpt.push({ label: `${element.promotionTitle?.EN} (${element.bonusId})`, value: element.bonusId })
      })
      setBonusOptions(bonusOpt)
    } else if (TABonusList?.length > 0) {
      const bonusOpt = []
      TABonusList.forEach(function (element) {
        bonusOpt.push({ label: `${element.promotionTitle?.EN} (${element.bonusId})`, value: element.bonusId })
      })
      setBonusOptions(bonusOpt)
    }
  }

  return {
    selectedBonus,
    setSelectedBonus,
    bonusList: isTenant ? TABonusList : bonusList,
    bonusDetail,
    issueBonusHandler,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal,
    TABonusLoading,
    bonusLoading,
    segmentsOptions,
    search,
    setSearch,
    gameIds,
    availableGames,
    handleSelectAll,
    handleSelect,
    getData,
    loading: isTenant ? tenantLoading : superAdminLoading,
    bonusOptions,
    excludeSegmentsOptions,
    setExcludeSegmentOptions,
    aggregators,
    selectedAggregator,
    setSelectedAggregator,
    setGameIds,
    tenantDetails: checkIsTenant() ? tenantDetails : SATenantDetails,
    prepaidsData,
    setPrepaidsData
  }
}

export default useBonusIssue
