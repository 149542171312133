import { Tab, Tabs } from '@themesberg/react-bootstrap'
import React from 'react'
import Trigger from '../../../../components/OverlayTrigger'
import { languageCode } from '../../../Tenant/TenantConfigurations/Languages/constants'
import MultiLangDetail from './MultiLangDetail'

const LanguageDetails = ({
  languages,
  bonusDetail,
  selectedLang,
  setSelectedLang,
  isTenant = false
}) => {
  return (
    <>
      <Tabs
        activeKey={selectedLang}
        onSelect={(tab) => setSelectedLang(tab)}
        className='nav-light mt-3'
      >
        {isTenant
          ? languages?.length && languages?.map((code) => {
            return code !== 'EN' && Object.keys(bonusDetail?.promotionTitle)?.includes(code) && (
              <Tab
                eventKey={code}
                title={
                  <Trigger message={languageCode[code]}>
                    <span>{code}</span>
                  </Trigger>
                    }
                key={code}
              >
                <div className='mt-5'>
                  <MultiLangDetail
                    selectedLang={selectedLang}
                    bonusDetail={bonusDetail}
                  />
                </div>
              </Tab>
            )
          })
          : languages?.count && languages?.rows?.map(({ code }) => {
            return code !== 'EN' && Object.keys(bonusDetail?.promotionTitle)?.includes(code) && (
              <Tab
                eventKey={code}
                title={
                  <Trigger message={languageCode[code]}>
                    <span>{code}</span>
                  </Trigger>
                  }
                key={code}
              >
                <div className='mt-5'>
                  <MultiLangDetail
                    selectedLang={selectedLang}
                    bonusDetail={bonusDetail}
                  />
                </div>
              </Tab>
            )
          })}
      </Tabs>
    </>
  )
}

export default LanguageDetails
