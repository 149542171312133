import React from 'react'
import useCheckPermission from '../../../utils/checkPermission'
import ReactUploadGallery from '../../../components/ReactUploadGallery'

const ImageGallery = ({ isTenant = false }) => {
  const { isHidden } = useCheckPermission()

  return (
    <>
      {!isHidden({ module: { key: 'ImageGallery', value: 'R' } }) && <ReactUploadGallery isTenant={isTenant} />}
    </>
  )
}

export default ImageGallery
