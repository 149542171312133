import React from 'react'
import RestrictProviderCountries from '../RestrictProviderCountries'

const RestrictGamesCountries = () => {
  return (
    <RestrictProviderCountries game />
  )
}

export default RestrictGamesCountries
