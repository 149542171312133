import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  createBannedGamesStart,
  getBannedAffiliatesStart,
  getBannedExcludedAffiliatesStart,
  updateBannedGamesAffiliatesStart,
  updateBannedGamesMaxBetAllowed
} from '../../../../store/redux-slices/superAdminTransactions'

/**
 * @typedef {Object} UseBannedGamesAffiliatesReturnType
 *
 * @property {String}                                       search
 * @property {import('react').SetStateAction}               setSearch
 * @property {String}                                       url
 * @property {Number}                                       maxBet
 * @property {import('@reduxjs/toolkit').Dispatch}          dispatch
 * @property {import('react-router-dom').NavigateFunction}  navigate
 * @property {AffiliateType[]}                              affiliates
 * @property {AffiliateType[]}                              bannedAffiliates
 * @property {AffiliateType[]}                              localBannedAffiliates
 * @property {Function}                                     handleSubmit
 * @property {Function}                                     onChangeAffiliateSelect
 * @property {Function}                                     onChangeAffiliateSelectAll
 */

/**
 * @typedef {Object} AffiliateType
 *
 * @property {String}   username
 * @property {Number}   affiliateId
 * @property {String}   firstName
 * @property {String}   lastName
 * @property {String}   email
 * @property {Boolean}  isActive
 * @property {String}   tenantId
 * @property {Number}   userIdAffiliate
 */

/**
 * @returns {UseBannedGamesAffiliatesReturnType}
 */
const useBannedGamesAffiliates = (create = false) => {
  const { bannedGamesListId } = useParams()
  const [searchParams] = useSearchParams()
  const tenantId = searchParams.get('tenantId')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [search, setSearch] = useState('')

  const url = window.location.href
  const isTenant = url.includes('/tenant/')
  /**
   * @type {AffiliateType[]}
   */
  const affiliates = useSelector((state) => state.superAdminTransactions.bannedExcludedAffiliates)
  /**
   * @type {AffiliateType[]}
   */
  const bannedAffiliates = useSelector((state) => state.superAdminTransactions.bannedAffiliates)
  /**
   * @type {Number}
   */
  const maxBet = useSelector(state => state.superAdminTransactions.maxBetAllowed)
  /**
   * @type {AffiliateType[]}
   */
  const [localBannedAffiliates, setLocalBannedAffiliates] = useState(bannedAffiliates)

  /**
   * @param {Number} affiliateId
   */
  const onChangeAffiliateSelect = (affiliateId) => {
    const isSelected = Boolean(
      localBannedAffiliates.find((bannedAffiliate) => affiliateId === bannedAffiliate.affiliateId)
    )

    if (isSelected) {
      const updatedBannedAffiliates =
        localBannedAffiliates.filter((bannedAffiliate) => bannedAffiliate.affiliateId !== affiliateId)

      setLocalBannedAffiliates(updatedBannedAffiliates)
    } else {
      setLocalBannedAffiliates(n => [...n, affiliates.find(affiliate => affiliate.affiliateId === affiliateId)])
    }
  }

  const onChangeAffiliateSelectAll = () => {
    if (localBannedAffiliates.length === affiliates.length) {
      setLocalBannedAffiliates([])
    } else {
      setLocalBannedAffiliates(affiliates)
    }
  }

  const fetchTenantAffiliates = useCallback(() => {
    if (tenantId) {
      dispatch(getBannedExcludedAffiliatesStart({ tenantId, search, isTenant }))
    }
  }, [getBannedExcludedAffiliatesStart, search, isTenant, tenantId])

  const fetchBannedAffiliates = useCallback(() => {
    if (tenantId && !create) {
      dispatch(getBannedAffiliatesStart({ bannedGamesListId, isTenant, create }))
    }
  }, [getBannedAffiliatesStart, bannedGamesListId, isTenant, tenantId])

  const handleSubmit = useCallback(({ create, gameData }) => {
    if (create) {
      dispatch(
        createBannedGamesStart({
          isTenant,
          gameData: {
            ...gameData,
            maxBetAllowed: maxBet,
            affiliateIds: localBannedAffiliates.map((localBannedAffiliate) => localBannedAffiliate.affiliateId)
          },
          navigate
        })
      )
    } else {
      dispatch(updateBannedGamesAffiliatesStart({
        isTenant,
        bannedGamesListId: Number(bannedGamesListId),
        maxBetAllowed: maxBet,
        affiliateIds: localBannedAffiliates.map((localBannedAffiliate) => localBannedAffiliate.affiliateId)
      }))
    }
  }, [bannedGamesListId, localBannedAffiliates, maxBet, isTenant])

  useEffect(() => {
    setLocalBannedAffiliates(bannedAffiliates)
  }, [bannedAffiliates])

  useEffect(() => {
    fetchTenantAffiliates()
  }, [fetchTenantAffiliates])

  useEffect(() => {
    fetchBannedAffiliates()
  }, [fetchBannedAffiliates])

  return {
    url,
    dispatch,
    navigate,
    search,
    maxBet,
    setSearch,
    setMaxBet: updateBannedGamesMaxBetAllowed,
    affiliates,
    bannedAffiliates,
    localBannedAffiliates,
    handleSubmit,
    onChangeAffiliateSelect,
    onChangeAffiliateSelectAll
  }
}

export default useBannedGamesAffiliates
