import { Row, Form as BForm, Button, Col, Modal, InputGroup } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { useSelector } from 'react-redux'
import { gamePlayLimitSchema, limitsSchema, setDisableUserlimitsSchema } from './schema'

const DailyLimit = ({
  limit,
  show,
  setShow,
  updateLimit,
  currencyCode
}) => {
  const labelArray = limit?.label?.split(' ')
  const label = '' + (labelArray?.[0] === 'Weekly' ? 'Daily ' : 'Weekly ') + labelArray?.[1] + ' ' + labelArray?.[2]
  const { userData } = useSelector(state => state.fetch)
  const { user } = useSelector(state => state.tenantUsers)

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <h5>Set {limit?.label} {user ? `for '${user?.firstName} ${user?.lastName}'` : userData ? `for '${userData?.firstName} ${userData?.lastName}'` : ''}</h5>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{ limit: limit?.value !== null ? limit?.value : '' }}
          validationSchema={
            (['Take A Break', 'Session Limit'].includes(limit?.label))
              ? setDisableUserlimitsSchema
              : ['Game Play Limit'].includes(limit?.label)
                  ? gamePlayLimitSchema
                  : limitsSchema({ minimum: limit?.minimum, currLabel: limit?.label, label })
}
          onSubmit={(formValues) => {
            updateLimit({ formValues, label: limit?.label, type: limit?.label === 'Take A Break' ? 'TAKE_A_BREAK' : limit?.label === 'Take A Break' ? 'GAME_PLAY_LIMIT' : null })
            setShow(false)
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur
          }) => (
            <Form className='m-3'>
              <div>
                <Row>
                  <Col className='d-flex justify-content-between align-items-center'>
                    <Col>
                      <BForm.Label>{(limit?.label === 'Take A Break' || limit?.label === 'Session Limit') ? 'Time Period' : limit?.label}</BForm.Label>
                    </Col>

                    <Col>
                      <InputGroup>
                        {(limit?.label !== 'Take A Break' && limit?.label !== 'Session Limit' && limit?.label !== 'Game Play Limit') && <InputGroup.Text>{currencyCode}</InputGroup.Text>}
                        <BForm.Control
                          type='number'
                          name='limit'
                          placeholder={`Enter ${limit?.label === 'Take A Break' ? 'Days' : limit?.label === 'Session Limit' ? 'Hours' : limit?.label === 'Game Play Limit' ? 'Time in Minutes' : 'Limit'}`}
                          value={values.limit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </InputGroup>
                      <ErrorMessage
                        component='div'
                        name='limit'
                        className='text-danger'
                      />

                    </Col>
                  </Col>
                </Row>

              </div>

              <div className='mt-3 d-flex justify-content-between align-items-center'>
                <Button
                  variant='outline-warning'
                  onClick={() => {
                    setShow(false)
                  }}
                  className='ml-2'
                >
                  Cancel
                </Button>

                <Button
                  variant='outline-success'
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  Set
                </Button>

              </div>

            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default DailyLimit
