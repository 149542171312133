import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleDown, faArrowCircleUp } from '@fortawesome/pro-solid-svg-icons'

const sortArrowIcon = (label, sortBy, setSortBy, hover, setHover, tableName) => {
  const createSortIcon = (field, sortOrder, descValue, ascValue, isSortingField) => {
    return (
      <FontAwesomeIcon
        className={hover[field] ? 'red-color ms-2' : 'ms-2'}
        icon={sortOrder === 'asc' ? faArrowCircleUp : faArrowCircleDown}
        onClick={() => {
          setSortBy({ ...Object.fromEntries(Object.keys(sortBy).map(key => [key, ''])) })
          setSortBy({ [field]: sortOrder === 'asc' ? descValue : ascValue, isSortingField })
          setHover(Object.fromEntries(Object.keys(hover).map(key => [key, false])))
          setHover({ [field]: true })
        }}
        onMouseOver={() => setHover({ ...Object.fromEntries(Object.keys(hover).map(key => [key, false])), [field]: true })}
        onMouseLeave={() => setHover({ ...Object.fromEntries(Object.keys(hover).map(key => [key, false])) })}
      />
    )
  }

  return (
    <>
      {tableName === 'Transactions' && (
        <>
          {label === 'ID' && createSortIcon('id', sortBy.id, 'desc', 'asc', 0)}
          {/* {label === 'Transaction ID' && createSortIcon('paymentId', sortBy.paymentId, 'desc', 'asc', 1)} */}
          {label === 'Target ID' && createSortIcon('targetId', sortBy.targetId, 'desc', 'asc', 2)}
          {label === 'TRX Success' && createSortIcon('trxSuccess', sortBy.trxSuccess, 'desc', 'asc', 3)}
          {label === 'TRX Decline' && createSortIcon('trxDecline', sortBy.trxDecline, 'desc', 'asc', 4)}
          {label === 'MDR%' && createSortIcon('mdr', sortBy.mdr, 'desc', 'asc', 5)}
          {label === 'PSP Fee' && createSortIcon('pspFee', sortBy.pspFee, 'desc', 'asc', 6)}
          {label === 'Amount in EUR' && createSortIcon('primaryCurrencyAmount', sortBy.primaryCurrencyAmount, 'desc', 'asc', 7)}
        </>
      )}

      {tableName === 'casinoTransaction' && (
        <>
          {label === 'ID' && createSortIcon('id', sortBy.id, 'desc', 'asc', 1)}
          {label === 'Cash' && createSortIcon('cashAmount', sortBy.cashAmount, 'desc', 'asc', 2)}
          {label === 'Bonus' && createSortIcon('bonusAmount', sortBy.bonusAmount, 'desc', 'asc', 3)}
          {label === 'After Money' && createSortIcon('afterBalance', sortBy.afterBalance, 'desc', 'asc', 4)}
          {label === 'Before Money' && createSortIcon('beforeBalance', sortBy.beforeBalance, 'desc', 'asc', 5)}
          {label === 'User Id' && createSortIcon('userId', sortBy.userId, 'desc', 'asc', 6)}
        </>
      )}

      {tableName === 'gameReport' && (
        <>
          {label === 'Id' && createSortIcon('id', sortBy.id, 'desc', 'asc', 1)}
          {label === 'Number of Rounds' && createSortIcon('noOfRounds', sortBy.noOfRounds, 'desc', 'asc', 2)}
          {label === 'Number of Player' && createSortIcon('noOfPlayer', sortBy.noOfPlayer, 'desc', 'asc', 3)}
          {label === 'Total Bets' && createSortIcon('totalBets', sortBy.totalBets, 'desc', 'asc', 4)}
          {label === 'Total Wins' && createSortIcon('totalWins', sortBy.totalWins, 'desc', 'asc', 5)}
          {label === 'Game Revenue' && createSortIcon('gameRevenue', sortBy.gameRevenue, 'desc', 'asc', 6)}
          {label === 'Payout' && createSortIcon('payout', sortBy.payout, 'desc', 'asc', 7)}
        </>
      )}

      {tableName === 'kpiReport' && (
        <>
          {label === 'GGR' && createSortIcon('ggr', sortBy.ggr, 'desc', 'asc', 1)}
          {label === 'Delta GGR' && createSortIcon('deltaGgr', sortBy.deltaGgr, 'desc', 'asc', 2)}
          {label === 'Real Bet' && createSortIcon('realBet', sortBy.realBet, 'desc', 'asc', 3)}
          {label === 'Real Win' && createSortIcon('realWin', sortBy.realWin, 'desc', 'asc', 4)}
          {label === 'Bonus Bet' && createSortIcon('bonusBet', sortBy.bonusBet, 'desc', 'asc', 5)}
          {label === 'Bonus Win' && createSortIcon('bonusWin', sortBy.bonusWin, 'desc', 'asc', 6)}
          {label === 'Bonus GGR' && createSortIcon('bonusGgr', sortBy.bonusGgr, 'desc', 'asc', 7)}
          {label === 'Total GGR' && createSortIcon('totalGgr', sortBy.totalGgr, 'desc', 'asc', 8)}
          {label === 'Total Bets' && createSortIcon('totalBets', sortBy.totalBets, 'desc', 'asc', 9)}
          {label === 'Delta Total Bets' && createSortIcon('deltaTotalGgr', sortBy.deltaTotalGgr, 'desc', 'asc', 10)}
        </>
      )}

      {tableName === 'demographicReport' && (
        <>
          {label === 'Sign Ups' && createSortIcon('signUpCount', sortBy.signUpCount, 'desc', 'asc', 1)}
          {label === 'New Depositors' && createSortIcon('newDepositors', sortBy.newDepositors, 'desc', 'asc', 2)}
          {label === 'Acquisition %' && createSortIcon('acquisition', sortBy.acquisition, 'desc', 'asc', 3)}
          {label === 'Re-Depositors' && createSortIcon('reDepositors', sortBy.reDepositors, 'desc', 'asc', 4)}
          {label === 'Total Depositors' && createSortIcon('totalDepositors', sortBy.totalDepositors, 'desc', 'asc', 5)}
          {label === 'Total Deposit' && createSortIcon('totalDeposit', sortBy.totalDeposit, 'desc', 'asc', 6)}
        </>
      )}

      {tableName === 'jackpotReport' && (
        <>
          {label === 'Id' && createSortIcon('id', sortBy.id, 'desc', 'asc', 1)}
          {label === 'Jackpot Contribution' && createSortIcon('jackpotContribution', sortBy.jackpotContribution, 'desc', 'asc', 2)}
          {label === 'Jackpot Win' && createSortIcon('jackpotWin', sortBy.jackpotWin, 'desc', 'asc', 3)}
          {label === 'Jackpot Revenue' && createSortIcon('jackpotRevenue', sortBy.jackpotRevenue, 'desc', 'asc', 4)}
        </>
      )}
    </>
  )
}

export default sortArrowIcon
