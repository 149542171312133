import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { formatDateYMD } from '../../../../utils/dateFormatter'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { userSessionLogsStart } from '../../../../store/redux-slices/superAdminTransactions'
import { getLoginToken } from '../../../../utils/storageUtils'

const useSessionData = ({ limit, page, search, state }) => {
  const dispatch = useDispatch()

  const isInitialRender = useDidMountEffect()
  const { userId } = useParams()
  const url = window.location.href
  const isTenant = url.includes('/tenant/')

  const { userSessionLogsLoading, userSessionLogs } = useSelector((state) => state.superAdminTransactions)

  const totalPages = Math.ceil(userSessionLogs?.count / limit)

  const fetchSessionDetails = () => {
    dispatch(userSessionLogsStart({
      search,
      userId,
      isTenant,
      createdStartDate: formatDateYMD(state.map(a => a.startDate)),
      createdEndDate: formatDateYMD(state.map(a => a.endDate)),
      limit,
      pageNo: page
    }))
  }

  useEffect(() => {
    fetchSessionDetails()
  }, [limit, page, state])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        fetchSessionDetails()
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const getCsvDownloadUrl = () =>
  `${process.env.REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/user/session?userId=${userId}&limit=${limit}&pageNo=${page}&createdStartDate=${formatDateYMD(state.map(a => a.startDate))}&createdEndDate=${formatDateYMD(state.map(a => a.endDate))}&csvDownload=true&token=${getLoginToken()}`

  return {
    totalPages,
    getCsvDownloadUrl,
    loading: userSessionLogsLoading,
    sessionLogs: userSessionLogs
  }
}

export default useSessionData
