import { Col, Form, Row, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import PaginationComponent from '../../components/Pagination'

export default ({
  wageringTemplateDetail,
  pageNo,
  setPageNo,
  limit,
  setLimit,
  totalPages,
  search,
  setSearch,
  bonus = false
}) => {
  return (
    <>
      {!bonus &&
        <Row className='d-flex'>
          <Col><h5>Wagering Template : {wageringTemplateDetail?.name}</h5></Col>
          <Col sm='auto'>
            <Form.Control
              type='search'
              name='search'
              size='sm'
              value={search}
              style={{ maxWidth: '230px' }}
              placeholder='Search Game Name'
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
        </Row>}
      <Table bordered striped responsive hover size='sm' className='text-center mt-4 scrollable'>
        <thead className='thead-dark'>
          <tr>
            {[
              'Name',
              'RTP',
              'Wagering Contribution'
            ].map((h) => (
              <th key={h}>{h}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {wageringTemplateDetail && wageringTemplateDetail.gameDetail && wageringTemplateDetail.gameDetail?.rows.length > 0
            ? wageringTemplateDetail.gameDetail?.rows.map((game, idx) => {
              return (
                <tr key={`wagering-template ${game.masterCasinoGameId}`}>
                  <td>{game.name}</td>

                  <td>{game?.returnToPlayer || '-'} %</td>
                  <td>{wageringTemplateDetail?.gameContribution?.[
                    game?.masterCasinoGameId
                  ] || game.wageringContribution}
                    %
                  </td>

                </tr>
              )
            })
            : (
              <tr>
                <td colSpan={7} className='text-danger text-center'>
                  No data found
                </td>
              </tr>
              )}
        </tbody>
      </Table>
      {wageringTemplateDetail && wageringTemplateDetail.gameDetail && wageringTemplateDetail?.gameDetail?.count !== 0 && (
        <PaginationComponent
          page={wageringTemplateDetail?.gameDetail?.count < pageNo ? setPageNo(1) : pageNo}
          totalPages={totalPages}
          setPage={setPageNo}
          limit={limit}
          setLimit={setLimit}
        />
      )}
    </>
  )
}
