export const tableHeaders = [
  { label: 'ID', value: 'categoryGameId' },
  { label: 'Name', value: 'name' },
  { label: 'Thumbnail', value: 'Thumbnail' },
  { label: 'RTP', value: 'returnToPlayer' },
  { label: 'Device Type', value: 'devices' },
  { label: 'Status', value: 'status' },
  { label: 'Provider', value: 'masterCasinoProviderId' },
  { label: 'Master ID', value: 'masterCasinoGameId' },
  { label: 'Sub Category', value: 'subcategory' },
  { label: 'Category', value: 'category' },
  { label: 'Action', value: 'action' }
]

export const allowedKeysforOrder = [
  'categoryGameId',
  'name'
]
