import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSuperAdminAggregatorStart, getSuperAdminAggregatorsStart, updateSuperAdminAggregatorStatusStart } from '../../../store/redux-slices/superAdminCasinoManagement'
import useDidMountEffect from '../../../utils/useDidMountEffect'

const useAggregatorListing = () => {
  const dispatch = useDispatch()
  const [id, setId] = useState()
  const [name, setName] = useState()
  const [data, setData] = useState({})
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(15)
  const [status, setStatus] = useState()
  const [show, setShow] = useState(false)
  const [search, setSearch] = useState('')
  const isInitialRender = useDidMountEffect()
  const [statusShow, setStatusShow] = useState(false)
  const { loading, aggregators } = useSelector((state) => state.superAdminCasino)
  const { tenantDetails } = useSelector((state) => state.login)
  const totalPages = Math.ceil(aggregators?.count / limit)

  const handleStatusShow = (id, status, myName) => {
    setId(id)
    setStatus(!status)
    setStatusShow(true)
    setName(myName)
  }

  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  const handleYes = () => {
    dispatch(
      updateSuperAdminAggregatorStatusStart({
        data: {
          masterGameAggregatorId: id,
          isActive: status
        },
        limit,
        pageNo: page,
        search
      })
    )
    setTimeout(() => {
      setStatusShow(false)
    }, 500)
  }

  const getAggregators = () => dispatch(getSuperAdminAggregatorsStart({ limit, pageNo: page, search }))

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          getAggregators()
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    !isInitialRender && getAggregators()
  }, [page])

  useEffect(() => {
    setPage(1)
    getAggregators()
  }, [limit])

  const createAggregator = (data, edit) =>
    dispatch(createSuperAdminAggregatorStart({ data, limit, pageNo: page, edit, search }))

  // Add Aggregator
  const { allAggregators } = useSelector(state => state.tenantCasino)

  const [aggregatorOptions, setAggregatorOptions] = useState([])
  useEffect(() => {
    const aggOpt = []
    allAggregators &&
    allAggregators.forEach(function (element) {
      aggOpt.push({ label: element.name, value: element.masterGameAggregatorId })
    })
    setAggregatorOptions(aggOpt)
  }, [allAggregators])

  useEffect(() => {
    if (aggregators?.count && !show) {
      const aggIds = aggregators?.rows?.map(({ masterGameAggregatorId }) => masterGameAggregatorId)
      setData({ aggregatorIds: aggIds, providerIds: tenantDetails?.tenantConfig?.gameProviders })
    }
  }, [aggregators, show])

  return {
    name,
    data,
    page,
    show,
    limit,
    status,
    search,
    loading,
    setPage,
    setData,
    setLimit,
    handleYes,
    setSearch,
    totalPages,
    handleShow,
    statusShow,
    aggregators,
    handleClose,
    setStatusShow,
    createAggregator,
    handleStatusShow,
    aggregatorOptions
  }
}

export default useAggregatorListing
