import { Row, Col } from '@themesberg/react-bootstrap'
import { ErrorMessage } from 'formik'
import React from 'react'
import ReactQuill from 'react-quill'

const ReactQuillField = ({
  touched,
  errors,
  errDesc,
  setFieldValue,
  setData,
  data,
  values,
  setErrDesc,
  fieldName,
  label,
  dataKey,
  disabled = false
}) => {
  return (
    <Row className='mb-3'>
      <Col>
        <label
          htmlFor={fieldName}
          className={
          ((touched?.[fieldName] && (errDesc || errors?.[fieldName]))) ? 'text-danger' : ''
        }
        >
          {label}<span className='text-danger'> *</span>
        </label>
        <ReactQuill
          name={fieldName}
          readOnly={disabled}
          placeholder={`Enter ${label}`}
          value={values[fieldName] || data?.[dataKey]?.EN || ''}
          onChange={(e) => {
            setFieldValue(fieldName, e.replace(/\s/g, '&nbsp;'))
            setData({
              ...data,
              [dataKey]: {
                ...data?.[dataKey],
                EN: e.replace(/\s/g, '&nbsp;')
              }
            })
          }}
          onKeyDown={() => setErrDesc('')}
        />

        {errDesc &&
          <div className='text-danger'>{errDesc}</div>}

        {!errDesc &&
          <ErrorMessage
            component='div'
            name={fieldName}
            className='text-danger'
          />}
      </Col>
    </Row>
  )
}

export default ReactQuillField
