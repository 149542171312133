import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getTenantStart } from '../../../../store/redux-slices/tenants'

const useTenantDetails = () => {
  const [selectedTab, setSelectedTab] = useState('tenant')
  const [show, setShow] = useState(false)
  const [edit, setEdit] = useState(false)
  const [allowedcurrencies, setAllowedCurrencies] = useState([])
  const { tenantDetails, loading } = useSelector((state) => state.tenants)
  const { tenantId, adminId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleClose = () => setShow(false)
  const handleShow = (id, allowedcurrencies) => {
    setAllowedCurrencies(allowedcurrencies)
    setShow(true)
  }

  useEffect(() => {
    dispatch(getTenantStart({ tenantId }))
  }, [])

  return {
    selectedTab,
    tenantDetails,
    tenantId,
    navigate,
    setSelectedTab,
    loading,
    show,
    handleClose,
    handleShow,
    adminId,
    allowedcurrencies,
    edit,
    setEdit
  }
}

export default useTenantDetails
