import { Col, Form, Row, Tab, Tabs } from '@themesberg/react-bootstrap'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import ProviderFilter from '../../../../components/ProviderFilter'
import './bannedGamesStyle.css'
import useBannedGamesDetails from '../hooks/useBannedGamesDetails'
import BannedGamesDetailsListing from './BannedGamesDetailsListing'
import BannedGamesAffiliates from './BannedGamesAffiliates'

const BannedGamesDetails = () => {
  const [searchParams] = useSearchParams()
  const {
    loading,
    getBannedGamesListDetail,
    selectedProvider,
    setSelectedProvider,
    totalPages,
    selectedTab,
    setSelectedTab,
    limit,
    pageNo,
    setLimit,
    setPageNo,
    search,
    setSearch,
    isTenant,
    isClone,
    listBannedGames,
    setListBannedGames
  } = useBannedGamesDetails()

  const tenantId = searchParams.get('tenantId')

  return (
    <Tabs
      activeKey={selectedTab}
      onSelect={(tab) => setSelectedTab(tab)}
      className='nav-light'
    >
      <Tab eventKey='games' title='Games'>
        <div className='mt-4'>
          <Row>
            <Col>
              <h3>Banned Games: {loading ? '' : getBannedGamesListDetail?.bannedGamesListName} </h3>
            </Col>
            <Col>
              <div className='d-flex justify-content-end align-items-center'>
                <ProviderFilter
                  selectedProvider={selectedProvider}
                  setSelectedProvider={setSelectedProvider}
                  isTenantRoute={isTenant}
                />
              </div>
            </Col>
            <Col xs='auto'>
              <div className='d-flex justify-content-end align-items-center'>
                <Form.Label style={{ marginRight: '15px', marginBottom: '0px' }}>
                  Search
                </Form.Label>

                <Form.Control
                  type='search'
                  size='sm'
                  style={{ maxWidth: '230px' }}
                  placeholder='Search Game Name'
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </Col>
          </Row>

          <BannedGamesDetailsListing
            pageNo={pageNo}
            setPageNo={setPageNo}
            totalPages={totalPages}
            limit={limit}
            setLimit={setLimit}
            getBannedGamesListDetail={getBannedGamesListDetail.gameDetail}
            isTenant={isTenant}
            isClone={isClone}
            listBannedGames={listBannedGames}
            setListBannedGames={setListBannedGames}
            loading={loading}
          />
        </div>
      </Tab>

      {Boolean(tenantId) && (
        <Tab eventKey='affiliates' title='Settings'>
          <div className='mt-4'>
            <BannedGamesAffiliates readonly />
          </div>
        </Tab>
      )}
    </Tabs>
  )
}

export default BannedGamesDetails
