
import React from 'react'
import Chartist from 'react-chartist'
import ChartistGraph from 'react-chartist'
import ChartistTooltip from 'chartist-plugin-tooltips-updated'

const plugins = [
  ChartistTooltip()
]

export const CircleChart = (props) => {
  const { series = [], donutWidth = 20 } = props

  const sum = (a, b) => a + b

  const options = {
    low: 0,
    high: 8,
    donutWidth,
    donut: true,
    donutSolid: true,
    fullWidth: false,
    showLabel: false,
    labelInterpolationFnc: value => `${Math.round(value / series.reduce(sum) * 100)}%`
  }

  return (
    <Chartist data={{ series }} options={{ ...options, plugins }} type='Pie' className='ct-golden-section' />
  )
}

export const BarChart = (props) => {
  const { series = [] } = props

  const options = {
    axisX: {
      labelInterpolationFnc (value, index) {
        return index % 2 === 0 ? value : null
      }
    },
    title: {
      text: 'Hello'
    },
    series: [
      {
        name: 'Signups Report',
        data: series,
        tooltip: {
          pointFormat: 'Users'
        }
      }
    ]
  }

  const data = {
    series: [series.filter(item => Number(item.depositAmount) && Number(item.depositAmount))?.flatMap((item) => {
      return { meta: item.countryName, value: item.depositAmount }
    })]
  }

  return (
    <ChartistGraph data={data} options={{ ...options, plugins }} type='Line' className='ct-golden-section' />
  )
}
