import { Modal } from '@themesberg/react-bootstrap'
import React from 'react'
import Overview from '../../pages/Super-Admin/UserDetails/components/Overview'
import UserWallet from '../../pages/Super-Admin/UserDetails/components/UserWallet'
import { isTenant } from '../../utils/constants'
import useUserData from '../../pages/Super-Admin/UserDetails/useUserData'
import useCheckPermission from '../../utils/checkPermission'

const UserDataModal = ({ showModal, userData }) => {
  const {
    moreInfo,
    basicInfo,
    adminPermissions,
    userCurrentStatus,
    userStatusLoading
  } = useUserData({ userData })
  const { isHidden } = useCheckPermission()

  return (
    <Modal size='xl' show={showModal}>
      <Modal.Header className='d-flex justify-content-center'><h5>Download Preview</h5></Modal.Header>
      <Modal.Body>
        <div id='container' className='w-100'>
          <div className='d-flex'>
            <Overview
              pdfDownload
              user={userData}
              isHidden={isHidden}
              moreInfo={moreInfo}
              isTenant={isTenant}
              basicInfo={basicInfo}
              loading={userStatusLoading}
              userLimits={userData?.userLimit}
              adminPermissions={adminPermissions}
              userCurrentStatus={userCurrentStatus}
            />
          </div>
          <div className='wallet d-flex'>
            <UserWallet isTenant={isTenant} myUserData={userData} pdfDownload />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default UserDataModal
