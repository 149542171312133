import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createTABonusStart, getWageringTemplateStart } from '../../../../../store/redux-slices/bonus'
import { formatDateYMD } from '../../../../../utils/dateFormatter'
import { getTABalanceBonuses } from '../../../../../utils/apiCalls'
import { toast } from '../../../../../components/Toast'
import { safeStringify } from '../../../../../utils/helpers'
import { updateSelectedTab } from '../../../../../utils/bonus'

const useCreateBonus = (bonusDetail, isClone) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState('general')
  const { wageringTemplateList, loading, languages } = useSelector(state => state.bonus)
  const { masterGames: casinoGamesData } = useSelector(state => state.tenantCasino)
  const { allCurrencies } = useSelector(state => state.currencies)
  const { wageringTemplateDetail } = useSelector(state => state.wageringTemplate)
  const [activeTab, setActiveTab] = useState(0)
  const [portalOptions, setPortalOptions] = useState([])
  const [enableSubmit, setEnableSubmit] = useState(false)
  const [balanceBonusOptions, setBalanceBonusOptions] = useState(null)
  const [appliedBonusOptions, setAppliedBonusOptions] = useState({ label: '', value: '', country: [] })
  const { tenantsList } = useSelector((state) => state.tenants)
  const [pageNo, setPageNo] = useState(1)
  const [limit, setLimit] = useState(15)
  const [search, setSearch] = useState('')
  const [gameIds, setGameIds] = useState(bonusDetail?.gameIds || [])
  const [selectedProvider, setSelectedProvider] = useState('')
  const totalPages = Math.ceil(wageringTemplateDetail?.gameDetail?.count / limit)
  const [gamesPageNo, setGamesPageNo] = useState(1)
  const [gamesLimit, setGamesLimit] = useState(15)
  const [loyaltyCount, setLoyaltyCount] = useState(1)
  const [countryData, setCountryData] = useState([])

  const [data, setData] = useState({
    promoTitle: bonusDetail?.promotionTitle || { EN: '' },
    desc: bonusDetail?.description || { EN: '' },
    promoOverview: bonusDetail?.promotionalOverview || { EN: '' },
    terms: bonusDetail?.termCondition || { EN: '' }
  })

  const [prepaidsData, setPrepaidsData] = useState([])

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  useEffect(() => {
    if (bonusDetail?.gameIds && !bonusDetail?.other?.hub88PrepaidDetails?.length) {
      setGameIds(bonusDetail?.gameIds)
    }
  }, [bonusDetail])

  const [curr, setCurr] = useState({
    EUR: {
      maxBonusThreshold: '',
      minDeposit: '',
      maxWinAmount: ''
    }
  })

  useEffect(() => {
    if (!wageringTemplateList && bonusDetail) {
      dispatch(getWageringTemplateStart({ isTenant: true }))
    }
  }, [])

  const getBalanceBonusesOptions = async (tenantId) => {
    await getTABalanceBonuses({ tenantId }).then((data) => {
      setBalanceBonusOptions(data?.data?.data?.bonusDetails)
    })
  }

  const handelCreateBonus = (formValues) => {
    Object.keys(formValues).forEach((key) => {
      if (formValues[key] === null || formValues[key] === '') {
        delete formValues[key]
      }
    })

    const checkOther = (data) => {
      const other = typeof data === 'object' ? safeStringify(data) : data
      if (safeStringify(bonusDetail?.other) === other) {
        return {}
      } else {
        return data
      }
    }
    if (formValues.bonusType === 'promotion') {
      if (selectedTab === 'countries') {
        const myShowBonusValidity = formValues?.showBonusValidity
        delete formValues?.showBonusValidity
        formValues?.appliedBonusId && delete formValues?.appliedBonusId
        formValues.promotionTitle = safeStringify(data?.promoTitle)
        formValues.description = safeStringify(data?.desc)
        formValues.promotionalOverview = safeStringify(data?.promoOverview)
        formValues.termCondition = safeStringify(data?.terms)
        delete formValues?.loyaltyLevel
        delete formValues?.paymentMethods

        const other = checkOther(safeStringify({ countries: countryData, showBonusValidity: myShowBonusValidity }))
        delete formValues.bannedGamesSetting
        dispatch(
          createTABonusStart({
            data: {
              ...formValues,
              validFrom: formatDateYMD(state.map((a) => a.startDate)),
              validTo: formatDateYMD(state.map((a) => a.endDate)),
              tenantIds: formValues.tenantIds.map((id) => id.value),
              other
            },
            navigate
          })
        )
      } else {
        const nextTabValue = updateSelectedTab(formValues.bonusType, selectedTab, formValues, languages)
        setSelectedTab(nextTabValue)
      }
    } else if (formValues.bonusType === 'freespins' || formValues?.bonusType === 'cashfreespins') {
      if (selectedTab === 'countries') {
        const myShowBonusValidity = formValues?.showBonusValidity
        delete formValues?.showBonusValidity
        formValues?.appliedBonusId && delete formValues?.appliedBonusId
        delete formValues?.loyaltyLevel
        delete formValues?.paymentMethods
        formValues.gameIds = prepaidsData?.length ? Array.from(new Set(prepaidsData?.map(({ gameId }) => gameId))) : gameIds
        formValues.promotionTitle = safeStringify(data?.promoTitle)
        formValues.description = safeStringify(data?.desc)
        formValues.promotionalOverview = safeStringify(data?.promoOverview)
        formValues.termCondition = safeStringify(data?.terms)
        if (formValues.bonusType === 'freespins') {
          formValues.bannedGamesSetting = safeStringify(formValues.bannedGamesSetting)
        } else {
          delete formValues.bannedGamesSetting
        }
        const other = checkOther(safeStringify({
          betLevel: formValues?.betLevel,
          countries: countryData,
          showBonusValidity: myShowBonusValidity,
          hub88PrepaidDetails: prepaidsData?.length ? prepaidsData : null,
          ...(formValues.bonusType === 'freespins' && {

          })
        }))
        gameIds && gameIds?.length
          ? dispatch(
            createTABonusStart({
              data: {
                ...formValues,
                bonusType: 'freespins',
                validFrom: formatDateYMD(state.map((a) => a.startDate)),
                validTo: formatDateYMD(state.map((a) => a.endDate)),
                tenantIds: formValues.tenantIds.map((id) => id.value),
                other
              },
              navigate
            })
          )
          : toast('Select At least One Game.', 'error')
      } else {
        const nextTabValue = updateSelectedTab(formValues.bonusType, selectedTab, formValues, languages)
        setSelectedTab(nextTabValue)
      }
    } else if (formValues.bonusType === 'balance') {
      if (selectedTab === 'countries') {
        const myShowBonusValidity = formValues?.showBonusValidity
        delete formValues?.showBonusValidity
        formValues.promotionTitle = safeStringify(data?.promoTitle)
        formValues.description = safeStringify(data?.desc)
        formValues.promotionalOverview = safeStringify(data?.promoOverview)
        formValues.termCondition = safeStringify(data?.terms)
        delete formValues?.paymentMethods
        const other = checkOther(safeStringify({ countries: countryData, showBonusValidity: myShowBonusValidity }))
        delete formValues.bannedGamesSetting

        dispatch(
          createTABonusStart({
            data: {
              ...formValues,
              validFrom: formatDateYMD(state.map((a) => a.startDate)),
              validTo: formatDateYMD(state.map((a) => a.endDate)),
              tenantIds: formValues.tenantIds.map((id) => id.value),
              appliedBonusId: formValues.appliedBonusVal,
              other
            },
            navigate
          })
        )
      } else {
        const nextTabValue = updateSelectedTab(formValues.bonusType, selectedTab, formValues, languages)
        setSelectedTab(nextTabValue)
      }
    } else {
      if (selectedTab === 'countries') {
        const myShowBonusValidity = formValues?.showBonusValidity
        if (['deposit', 'wagering', 'winBoost'].includes(formValues?.bonusType)) {
          for (let i = 0, len = formValues?.loyaltyLevel?.length; i < len; i++) {
            delete formValues?.loyaltyLevel?.[i].startPoint
            delete formValues?.loyaltyLevel?.[i].endPoint
            formValues.loyaltyLevel[i].bonusPercentage = formValues?.loyaltyLevel?.[i].bonusPercentage / 100
          }
        }
        delete formValues?.showBonusValidity
        formValues?.appliedBonusId && delete formValues?.appliedBonusId
        const timePeriod = formValues?.timePeriod
        delete formValues?.timePeriod
        formValues.promotionTitle = safeStringify(data?.promoTitle)
        formValues.description = safeStringify(data?.desc)
        formValues.promotionalOverview = safeStringify(data?.promoOverview)
        formValues.termCondition = safeStringify(data?.terms)
        formValues.paymentMethods = formValues?.paymentMethods ? safeStringify(formValues?.paymentMethods) : {}
        formValues.bannedGamesSetting = safeStringify(formValues.bannedGamesSetting)

        dispatch(
          createTABonusStart({
            data: {
              ...formValues,
              validFrom: formatDateYMD(state.map((a) => a.startDate)),
              validTo: formatDateYMD(state.map((a) => a.endDate)),
              tenantIds: formValues.tenantIds.map((id) => id.value),
              other: checkOther(safeStringify({ loyaltyLevel: formValues?.loyaltyLevel, timePeriod, countries: countryData, showBonusValidity: myShowBonusValidity })),
              loyaltyLevel: ''
            },
            navigate
          })
        )
      } else {
        const nextTabValue = updateSelectedTab(formValues.bonusType, selectedTab, formValues, languages)
        setSelectedTab(nextTabValue)
      }
    }
  }

  const [preview, setPreview] = useState({
    image_preview: !isClone ? bonusDetail?.imageUrl : null,
    image_file: !isClone ? bonusDetail?.imageUrl : null
  })

  useEffect(() => {
    if (bonusDetail?.imageUrl && !isClone) {
      setPreview({
        image_preview: bonusDetail?.imageUrl,
        image_file: bonusDetail?.imageUrl
      })
    }
  }, [bonusDetail])

  const handleImagePreview = (e) => {
    if (e.target.files[0]) {
      const imageAsBase64 = URL.createObjectURL(e.target.files[0])
      const imageAsFiles = e.target.files[0]
      setPreview({
        image_preview: imageAsBase64,
        image_file: imageAsFiles
      })
    }
  }

  useEffect(() => {
    if (!wageringTemplateList) dispatch(getWageringTemplateStart({ isTenant: true }))
  }, [])

  useEffect(() => {
    if (balanceBonusOptions) {
      const options = balanceBonusOptions.map((opt) => { return ({ label: opt.promotionTitle?.EN, value: opt.bonusId, country: opt?.other?.countries }) })
      setAppliedBonusOptions(options)
    }
  }, [balanceBonusOptions])

  useEffect(() => {
    if (tenantsList) {
      const options = tenantsList.rows.map((portal) => { return ({ label: portal.name, value: portal.tenantId }) })
      setPortalOptions(options)
    }
  }, [tenantsList])

  return {
    selectedTab,
    setSelectedTab,
    portalOptions,
    allCurrencies,
    state,
    setState,
    dispatch,
    navigate,
    preview,
    handleImagePreview,
    activeTab,
    setActiveTab,
    curr,
    setCurr,
    wageringTemplateList,
    wageringTemplateDetail,
    enableSubmit,
    setEnableSubmit,
    loading,
    handelCreateBonus,
    getBalanceBonusesOptions,
    balanceBonusOptions,
    limit,
    setLimit,
    pageNo,
    setPageNo,
    totalPages,
    gameIds,
    setGameIds,
    search,
    setSearch,
    selectedProvider,
    setSelectedProvider,
    casinoGamesData,
    gamesLimit,
    gamesPageNo,
    setGamesLimit,
    setGamesPageNo,
    appliedBonusOptions,
    data,
    setData,
    languages,
    loyaltyCount,
    setLoyaltyCount,
    countryData,
    setCountryData,
    prepaidsData,
    setPrepaidsData
  }
}

export default useCreateBonus
