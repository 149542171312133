import React from 'react'

import {
  Row,
  Col,
  Form as BForm,
  Button,
  InputGroup
} from '@themesberg/react-bootstrap'
import { ErrorMessage } from 'formik'
import { convertAmountOptions } from './constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getSAConvertAmount, getTAConvertAmount } from '../../../../../utils/apiCalls'
import Trigger from '../../../../../components/OverlayTrigger'
import { toast } from '../../../../../components/Toast'
import { faExchangeAlt } from '@fortawesome/pro-solid-svg-icons'
import { safeStringify } from '../../../../../utils/helpers'

const CurrenciesForm = ({
  values,
  handleChange,
  handleSubmit,
  handleBlur,
  setFieldValue,
  setCurr,
  bonusDetail,
  setSelectedTab,
  isEdit,
  enableSubmit,
  setEnableSubmit,
  isTenant
}) => {
  async function fetchData () {
    const code = Object.keys(values?.currency)?.[0]
    const maxBonusThreshold = values?.currency?.[code]?.maxBonusThreshold
    const minDeposit = values?.currency?.[code]?.minDeposit
    const maxWinAmount = values?.currency?.[code]?.maxWinAmount
    const zeroOutThreshold = values?.currency?.[code]?.zeroOutThreshold
    const minBalance = values?.currency?.[code]?.minBalance
    const maxBetAllowed = values?.currency?.[code]?.maxBetAllowed
    const bonusAmount = values?.currency?.[code]?.bonusAmount

    if (values?.bonusType !== 'freespins' && values?.bonusType !== 'cashfreespins') {
      if (maxBonusThreshold === '' && !['balance', 'deposit', 'wagering', 'winBoost', 'zero_deposit'].includes(values.bonusType)) {
        toast('Enter Max Bonus Claimed', 'error')
      } else if (minBalance === '' && (['deposit', 'wagering', 'zero_deposit'].includes(values?.bonusType))) {
        toast('Enter Min Balance', 'error')
      } else if (minDeposit === '' && !['balance', 'deposit', 'wagering', 'winBoost', 'zero_deposit'].includes(values?.bonusType)) {
        toast('Enter Min Deposit', 'error')
      } else if (maxWinAmount === '' && !['balance', 'deposit', 'wagering', 'winBoost', 'zero_deposit'].includes(values?.bonusType)) {
        toast('Enter Max Win Amount', 'error')
      } else if (zeroOutThreshold === '' && (values.bonusType !== 'balance')) {
        toast('Enter Zero Out Threshold', 'error')
      } else if (maxBetAllowed === '') {
        toast('Enter Max bet Amount', 'error')
      } else {
        !isTenant
          ? await getSAConvertAmount({
            currencyFields: {
              maxBonusThreshold: maxBonusThreshold || 0,
              minDeposit: minDeposit || 0,
              maxWinAmount: maxWinAmount || 0,
              zeroOutThreshold: zeroOutThreshold || 0,
              minBalance: minBalance || 0,
              maxBetAllowed: maxBetAllowed || 0,
              bonusAmount: bonusAmount || 0
            },
            currencyCode: code,
            tenantIds: safeStringify(values?.tenantIds.map((id) => id?.value))
          }).then((res) => {
            setFieldValue('currency', res?.data?.data?.currenciesAmount)
            setCurr(res?.data?.data?.currenciesAmount)
            setEnableSubmit(true)
          })
          : await getTAConvertAmount({
            currencyFields: {
              maxBonusThreshold: maxBonusThreshold || 0,
              minDeposit: minDeposit || 0,
              maxWinAmount: maxWinAmount || 0,
              zeroOutThreshold: zeroOutThreshold || 0,
              minBalance: minBalance || 0,
              maxBetAllowed: maxBetAllowed || 0,
              bonusAmount: bonusAmount || 0
            },
            currencyCode: code,
            tenantIds: safeStringify(values?.tenantIds.map((id) => id?.value))
          }).then((res) => {
            setFieldValue('currency', res?.data?.data?.currenciesAmount)
            setCurr(res?.data?.data?.currenciesAmount)
            setEnableSubmit(true)
          })
      }
    } else {
      if (maxWinAmount === '') {
        toast('Enter Max Win Amount', 'error')
      } else if (zeroOutThreshold === '' && (values?.isSticky === 'true' || values?.isSticky)) {
        toast('Enter Zero Out Threshold', 'error')
      } if (minDeposit === '') {
        toast('Enter Min Deposit', 'error')
      } else if (maxBetAllowed === '') {
        toast('Enter Max bet Amount', 'error')
      } else {
        !isTenant
          ? await getSAConvertAmount({
            currencyFields: {
              maxBonusThreshold: maxBonusThreshold || 0,
              minDeposit: minDeposit || 0,
              maxWinAmount: maxWinAmount || 0,
              zeroOutThreshold: zeroOutThreshold || 0,
              minBalance: minBalance || 0,
              maxBetAllowed: maxBetAllowed || 0,
              bonusAmount: bonusAmount || 0
            },
            currencyCode: code,
            tenantIds: safeStringify(values?.tenantIds.map((id) => id?.value))
          }).then((res) => {
            setFieldValue('currency', res?.data?.data?.currenciesAmount)
            setCurr(res?.data?.data?.currenciesAmount)
            setEnableSubmit(true)
          })
          : await getTAConvertAmount({
            currencyFields: {
              maxBonusThreshold: maxBonusThreshold || 0,
              minDeposit: minDeposit || 0,
              maxWinAmount: maxWinAmount || 0,
              zeroOutThreshold: zeroOutThreshold || 0,
              minBalance: minBalance || 0,
              maxBetAllowed: maxBetAllowed || 0,
              bonusAmount: bonusAmount || 0
            },
            currencyCode: code,
            tenantIds: safeStringify(values?.tenantIds.map((id) => id?.value))
          }).then((res) => {
            setFieldValue('currency', res?.data?.data?.currenciesAmount)
            setCurr(res?.data?.data?.currenciesAmount)
            setEnableSubmit(true)
          })
      }
    }
  }

  return (
    <>
      {bonusDetail && !enableSubmit && setEnableSubmit(true)}
      <Col className='d-flex justify-content-end'>
        <Trigger message='Fetch according to primary currency'>
          <Button onClick={fetchData}>
            <FontAwesomeIcon icon={faExchangeAlt} />
          </Button>
        </Trigger>
      </Col>
      {values.currency &&
        Object.keys(values.currency).length > 0 &&
        Object.keys(values.currency).map((key, index) => (
          <Row key={index} className='g-2 mb-2'>
            <Col sm='auto' className='d-flex'>
              <Col className='px-1 text-center'>
                {index < 1 && <label style={{ fontSize: '14px' }} htmlFor='currency'>Currency</label>}
                <InputGroup>
                  <BForm.Control
                    name={`currency[${key}]`}
                    value={key}
                    onInput={handleChange}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled
                  />
                </InputGroup>
              </Col>
              {Object.keys(values.currency[key]).map((currKey, currIndex) => {
                const hide = (['deposit', 'wagering'].includes(values?.bonusType)
                  ? (['minDeposit', 'maxBonusThreshold', 'maxWinAmount', 'bonusAmount'].includes(currKey))
                  : ((values?.bonusType === 'freespins' || values?.bonusType === 'cashfreespins'))
                      ? ((values?.isSticky === 'true' || values?.isSticky)
                          ? (currKey !== 'minDeposit' && currKey !== 'maxWinAmount' && currKey !== 'zeroOutThreshold' && currKey !== 'maxBetAllowed')
                          : (currKey !== 'minDeposit' && currKey !== 'maxWinAmount' && currKey !== 'maxBetAllowed'))
                      : currKey === 'minBalance')
                return (
                  currKey !== 'minBonusThreshold' &&
                    <Col className='px-1 text-center' key={currIndex} hidden={hide}>
                      {index < 1 && !hide && (
                        <label htmlFor={currKey} style={{ fontSize: '14px' }}>
                          {
                          ['deposit', 'wagering'].includes(values?.bonusType)
                            ? convertAmountOptions?.find(
                              (val) => currKey === 'minBalance' ? val.value === 'minBalanceCash' : val.value === currKey
                            )?.label
                            : convertAmountOptions?.find(
                              (val) => val.value === currKey
                            )?.label
                        }
                          {currKey !== 'bonusAmount' && <span className='text-danger'> *</span>}
                        </label>
                      )}
                      <InputGroup>
                        <BForm.Control
                          name={`currency[${key}][${currKey}]`}
                          value={values.currency[key][currKey]}
                          onInput={handleChange}
                          hidden={hide}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </InputGroup>
                      <ErrorMessage
                        component='div'
                        hidden={hide}
                        name={`currency[${key}][${currKey}]`}
                        className='text-danger'
                      />
                    </Col>
                )
              })}
            </Col>
          </Row>
        ))}

      <div className='mt-4 d-flex justify-content-between align-items-center'>
        <Button
          variant='outline-warning'
          onClick={() => {
            setSelectedTab('general')
          }}
          className='ml-2'
        >
          Previous
        </Button>
        {(enableSubmit || isEdit) && (
          <Button
            variant='outline-success'
            onClick={handleSubmit}
            className='ml-2'
          >
            Next
          </Button>
        )}
      </div>
    </>
  )
}
export default CurrenciesForm
