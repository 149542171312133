import React from 'react'
import { useSetThresholds } from './useSetThresholds'
import SetThresholds from '../../../components/SetThresholds'

const SetRGThresholds = () => {
  const {
    setThresholdHandler,
    setSelectedClient,
    setSelectedPortal,
    selectedClient,
    selectedPortal,
    thresholdData,
    rgForms
  } = useSetThresholds()

  return (
    <SetThresholds
      setThresholdHandler={setThresholdHandler}
      setSelectedClient={setSelectedClient}
      setSelectedPortal={setSelectedPortal}
      selectedClient={selectedClient}
      selectedPortal={selectedPortal}
      thresholdData={thresholdData}
      type='rgModule'
      forms={rgForms}
    />
  )
}

export default SetRGThresholds
