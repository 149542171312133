import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getAdminUserDetailsStart } from '../../../../store/redux-slices/adminUser'
import { editTenantStart, getAllCredentialsStart } from '../../../../store/redux-slices/tenants'

const useCreateTenant = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState('tenant')
  const { adminId } = useParams()
  const { allCredentialsKey, loading } = useSelector((state) => state.tenants)
  const { adminUserDetails } = useSelector((state) => state.adminUser)
  const [createTenantData, setCreateTenantData] = useState({
    name: '',
    domain: '',
    primaryCurrency: null,
    allowedCurrencies: ['EUR'],
    allowedLanguages: ['EN'],
    themeId: null,
    logoUrl: null,
    logo: null,
    primaryColor: null,
    secondaryColor: null,
    code: 'jap',
    languageName: 'English',
    themeMode: null,
    value: null,
    description: null,
    adminId
    // providerIds: [],
    // aggregatorIds: []
  })

  useEffect(() => {
    dispatch(getAdminUserDetailsStart({ adminUserId: adminId, isAggregatorDetail: true }))
  }, [adminId])

  useEffect(() => {
    dispatch(getAllCredentialsStart())
  }, [])

  useEffect(() => {
    if (allCredentialsKey && Object.keys(allCredentialsKey)?.length > 0) {
      const keys = allCredentialsKey.map((key) => key.name)
      const data = Object.fromEntries(keys.map(k => [k, '']))
      setCreateTenantData({
        ...createTenantData,
        value: { ...data },
        description: { ...data }
      })
    }
  }, [allCredentialsKey])

  const editTenant = ({
    createTenantData,
    val,
    tenantId
  }) => dispatch(
    editTenantStart({
      createTenantData,
      navigate,
      val,
      tenantId
    })
  )

  return {
    selectedTab,
    setSelectedTab,
    allCredentialsKey,
    createTenantData,
    setCreateTenantData,
    dispatch,
    adminUserDetails,
    loading,
    editTenant
  }
}

export default useCreateTenant
