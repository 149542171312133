import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getCMSLanguagesStart, getLanguageSupportKeysStart, uploadLanguageCSVStart } from '../../../store/redux-slices/languages'
import { getItem, getLoginToken } from '../../../utils/storageUtils'
import { useDidMountEffect } from '../../../utils/useDidMountEffect'
import { keysNotToBeShown } from './constant'

const useLanguageMgmt = ({ isTenant, errorCodes = false }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()
  const tenantId = getItem('tenant-id')
  const [selectedClient, setSelectedClient] = useState(state?.myAdminId || '')
  const [selectedPortal, setSelectedPortal] = useState(state?.myTenantId || '')
  const { languageKeys, tenantLanguages } = useSelector(state => state.languages)
  const { tenantDetails } = useSelector(state => state.login)
  const [myKeyData, setMyKeyData] = useState({})
  const [selectedLang, setSelectedLang] = useState('')
  const [uploadModal, setUploadModal] = useState(false)
  const [search, setSearch] = useState('')
  const [keySearch, setKeySearch] = useState('')
  const [availableLanguages, setAvailableLanguages] = useState([])
  const [myLanguages, setMyLanguages] = useState(new Set())
  const isInitialRender = useDidMountEffect()
  const [myKeys, setMyKeys] = useState({})

  useEffect(() => {
    !isTenant && dispatch(getCMSLanguagesStart({ adminId: selectedClient, tenantId: selectedPortal }))
    dispatch(getLanguageSupportKeysStart({ isTenant, tenantId: isTenant ? tenantId : selectedPortal, language: '', errorCodes }))
  }, [errorCodes, selectedPortal])

  useEffect(() => {
    window.history.replaceState({}, '')
  }, [selectedClient, setSelectedPortal])

  useEffect(() => {
    if (languageKeys && languageKeys?.length) {
      const set = new Set()
      const data = {}
      const data2 = {}
      if (errorCodes) {
        for (const key in languageKeys) {
          const myKey = languageKeys[key].key
          const value = typeof languageKeys[key].value === 'string' ? JSON.parse(languageKeys[key].value) : {}
          const keyData = {}
          data2[myKey] = ''
          for (const lang in value) {
            if (lang !== 'PIQ') {
              keyData[lang] = value[lang]
              set.add(lang)
            }
          }
          data[myKey] = keyData
        }
      } else {
        for (const key in languageKeys[0]) {
          if (!keysNotToBeShown.includes(key)) data2[key] = ''
          const keyData = {}
          for (const i in languageKeys) {
            keyData[languageKeys[i].language] = languageKeys[i][key]
            set.add(languageKeys[i].language)
          }
          data[key] = keyData
        }
      }
      setMyKeyData(data)
      setMyKeys(data2)
      setMyLanguages(set)
    }
  }, [languageKeys])

  const updateKeysForSearch = () => {
    if (languageKeys && languageKeys?.length) {
      const set = new Set()
      const data = {}
      if (errorCodes) {
        for (const key in languageKeys) {
          const myKey = languageKeys[key].key
          const value = typeof languageKeys[key].value === 'string' ? JSON.parse(languageKeys[key].value) : {}
          const keyData = {}
          if (myKey?.includes(keySearch)) {
            for (const lang in value) {
              if (String(value[lang]).toLowerCase()?.includes(search.toLowerCase()) && lang !== 'PIQ') {
                keyData[lang] = value[lang]
                set.add(lang)
              }
            }
          }
          let count = 0
          for (const i in keyData) {
            if (keyData[i] !== '') {
              count++
            }
          }
          if (count) {
            data[myKey] = keyData
          }
        }
      } else {
        for (const key in languageKeys[0]) {
          const keyData = {}
          if (key.includes(keySearch)) {
            for (const i in languageKeys) {
              if (String(languageKeys[i][key]).toLowerCase()?.includes(search.toLowerCase())) {
                keyData[languageKeys[i].language] = languageKeys[i][key]
                set.add(languageKeys[i].language)
              }
            }
          }
          let count = 0
          for (const i in keyData) {
            if (keyData[i] !== '') {
              count++
            }
          }
          if (count) {
            data[key] = keyData
          }
        }
      }
      for (const key in data) {
        const currData = data[key]
        data[key] = {}
        for (const lang of set) {
          data[key][lang] = currData[lang] || ''
        }
      }
      setMyKeyData(data)
      setMyLanguages(set)
    }
  }

  const setData = () => {
    const lang = []
    if (isTenant && tenantDetails?.tenantConfig?.allowedLanguages) {
      tenantDetails.tenantConfig.allowedLanguages.forEach(code => {
        if (errorCodes) {
          if (!myLanguages.has(code)) {
            lang.push(code)
          }
        } else { if (!languageKeys?.find(({ language }) => code === language)) lang.push(code) }
      })
    } else if (tenantLanguages?.length) {
      tenantLanguages.forEach(code => {
        if (errorCodes) {
          if (!myLanguages.has(code)) {
            lang.push(code)
          }
        } else { if (!languageKeys?.find(({ language }) => code === language)) lang.push(code) }
      })
    }
    setAvailableLanguages(lang)
  }

  useEffect(() => setData(), [tenantDetails, tenantLanguages, languageKeys, myLanguages])

  const keyOptions = [{ label: 'All', value: '' }]
  myKeys &&
  Object.keys(myKeys).map(element =>
    errorCodes ? keyOptions.push({ label: element, value: element }) : !keysNotToBeShown.includes(element) && keyOptions.push({ label: element, value: element })
  )

  useEffect(() => {
    if (!isInitialRender) {
      const delayDebounceFn = setTimeout(() => {
        updateKeysForSearch()
      }, 1000)

      return () => clearTimeout(delayDebounceFn)
    }
  }, [search, keySearch, languageKeys])

  const handleDownload = () =>
  `${process.env.REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/language/support-keys?tenantId=${isTenant ? tenantId : selectedPortal}&errorCodes=${errorCodes}&csvDownload=true&token=${getLoginToken()}`

  const handleUpload = (data) =>
    dispatch(uploadLanguageCSVStart({ isTenant, data: { ...data, tenantId: isTenant ? tenantId : selectedPortal }, language: selectedLang, tenantId: isTenant ? tenantId : selectedPortal, errorCodes }))

  return {
    languageKeys,
    myKeyData,
    navigate,
    selectedLang,
    setSelectedLang,
    handleDownload,
    handleUpload,
    uploadModal,
    setUploadModal,
    availableLanguages,
    tenantLanguages: isTenant ? tenantDetails?.tenantConfig?.allowedLanguages : tenantLanguages,
    search,
    setSearch,
    keyOptions,
    myKeys,
    setKeySearch,
    myLanguages,
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal
  }
}

export default useLanguageMgmt
