import React from 'react'
import Trigger from '../OverlayTrigger'
import { useSelector } from 'react-redux'
import useCheckPermission from '../../utils/checkPermission'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, Button, ButtonGroup, Table } from '@themesberg/react-bootstrap'
import { faCheckSquare, faEdit, faEye, faWindowClose } from '@fortawesome/pro-solid-svg-icons'
import { providerCategory } from '../../pages/Super-Admin/CustomPayment/components/constants'

export default ({
  data,
  state,
  navigate,
  selectedPortal,
  selectedClient,
  handleShowModal,
  isTenant = false
}) => {
  const { isHidden } = useCheckPermission()
  const { tenantDetails } = useSelector(state => state.login)
  const togglePermission = isHidden({ module: { key: 'CashierManagement', value: 'T' } })

  return (
    <>
      {(isTenant || selectedPortal) && data && Object.keys(data)?.length
        ? (
          <Accordion defaultActiveKey={0} className='nested-accordions'>
            {Object.keys(data)?.map((item, i) => {
              return (
                <Accordion.Item
                  key={`pay-aggregators-${i}`}
                  eventKey={i}
                >
                  <Accordion.Header>{item.toUpperCase()}</Accordion.Header>
                  <Accordion.Body>
                    <Accordion defaultActiveKey={0} className='inner-accordion'>
                      {Object.keys(data[item]).map((agg, index) => {
                        return (
                          <Accordion.Item
                            key={`pay-aggregators-${agg}`}
                            eventKey={index}
                          >
                            <Accordion.Header>{agg}</Accordion.Header>
                            <Accordion.Body>
                              <div style={{ overflow: 'auto', maxHeight: '500px' }}>
                                <Table
                                  bordered
                                  striped
                                  hover
                                  size='sm'
                                  className='text-center'
                                >
                                  <thead className='thead-dark'>
                                    <tr>
                                      <th>Provider</th>
                                      <th>Display Name</th>
                                      <th>Category</th>
                                      <th>Closed Loop</th>
                                      <th>Action</th>
                                      {!togglePermission &&
                                        <>
                                          <th>Deposit Action</th>
                                          <th>Withdraw Action</th>
                                        </>}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data[item][agg]?.map(({
                                      paymentProviderId, name, depositIsActive, withdrawIsActive,
                                      category, displayName, supportsDeposit, supportsWithdrawal, closedLoop
                                    }) => {
                                      return (
                                        <tr key={paymentProviderId}>
                                          <td>{name}</td>
                                          <td>{displayName || 'NA'}</td>
                                          <td>{providerCategory[category || 'OTHER']}</td>
                                          <td>{closedLoop ? 'Yes' : 'No'}</td>
                                          <td>
                                            <ButtonGroup>
                                              <Trigger message='Edit'>
                                                <Button
                                                  variant='warning'
                                                  size='sm'
                                                  className='m-1'
                                                  hidden={isHidden({ module: { key: 'CashierManagement', value: 'U' } })}
                                                  onClick={() => navigate && navigate(`/${isTenant ? 'tenant' : 'super-admin'}/cashier-management/edit/${isTenant ? tenantDetails?.adminUserId : selectedClient}/${isTenant ? tenantDetails?.tenantId : selectedPortal}/${paymentProviderId}`, { state })}
                                                >
                                                  <FontAwesomeIcon icon={faEdit} />
                                                </Button>
                                              </Trigger>
                                              <Trigger message='View'>
                                                <Button
                                                  variant='info'
                                                  size='sm'
                                                  className='m-1'
                                                  onClick={() => navigate(`/${isTenant ? 'tenant' : 'super-admin'}/cashier-management/view/${isTenant ? tenantDetails?.adminUserId : selectedClient}/${isTenant ? tenantDetails?.tenantId : selectedPortal}/${paymentProviderId}`, { state })}
                                                >
                                                  <FontAwesomeIcon icon={faEye} />
                                                </Button>
                                              </Trigger>
                                            </ButtonGroup>
                                          </td>
                                          {!togglePermission &&
                                            <>
                                              <td>
                                                {supportsDeposit
                                                  ? (!depositIsActive
                                                      ? (
                                                        <Trigger message='Set Status Active'>
                                                          <Button
                                                            className='m-1'
                                                            size='sm'
                                                            variant='success'
                                                            hidden={togglePermission}
                                                            onClick={() =>
                                                              handleShowModal &&
                                                              handleShowModal(paymentProviderId, depositIsActive, `${name} (${item}) (${agg}) for Deposit`)}
                                                          >
                                                            <FontAwesomeIcon icon={faCheckSquare} />
                                                          </Button>
                                                        </Trigger>
                                                        )
                                                      : (
                                                        <Trigger message='Set Status In-Active'>
                                                          <Button
                                                            className='m-1'
                                                            size='sm'
                                                            variant='danger'
                                                            hidden={togglePermission}
                                                            onClick={() =>
                                                              handleShowModal &&
                                                              handleShowModal(paymentProviderId, depositIsActive, `${name} (${item}) (${agg}) for Deposit`)}
                                                          >
                                                            <FontAwesomeIcon icon={faWindowClose} />
                                                          </Button>
                                                        </Trigger>
                                                        ))
                                                  : '-'}
                                              </td>
                                              <td>
                                                {supportsWithdrawal
                                                  ? (!withdrawIsActive
                                                      ? (
                                                        <Trigger message='Set Status Active'>
                                                          <Button
                                                            className='m-1'
                                                            size='sm'
                                                            variant='success'
                                                            hidden={togglePermission}
                                                            onClick={() =>
                                                              handleShowModal &&
                                                              handleShowModal(paymentProviderId, withdrawIsActive, `${name} (${item}) (${agg}) for Withdraw`)}
                                                          >
                                                            <FontAwesomeIcon icon={faCheckSquare} />
                                                          </Button>
                                                        </Trigger>
                                                        )
                                                      : (
                                                        <Trigger message='Set Status In-Active'>
                                                          <Button
                                                            className='m-1'
                                                            size='sm'
                                                            variant='danger'
                                                            hidden={togglePermission}
                                                            onClick={() =>
                                                              handleShowModal &&
                                                              handleShowModal(paymentProviderId, withdrawIsActive, `${name} (${item}) (${agg}) for Withdraw`)}
                                                          >
                                                            <FontAwesomeIcon icon={faWindowClose} />
                                                          </Button>
                                                        </Trigger>
                                                        ))
                                                  : '-'}
                                              </td>
                                            </>}
                                        </tr>
                                      )
                                    })}

                                    {data[item][agg]?.length === 0 && (
                                      <tr>
                                        <td colSpan={2} className='text-danger text-center'>
                                          No data found
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        )
                      })}
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              )
            })}
          </Accordion>)
        : !isTenant && !selectedPortal ? <p className='text-center text-danger'>Please Select Portal First</p> : <p className='text-center text-danger'>No Data Found</p>}
    </>
  )
}
