import React from 'react'
import { ErrorMessage, Formik } from 'formik'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import CustomReactSelect from '../../../../components/Custom/CustomReactSelect'
import { banOptions, personalInfoFields } from '../BannedUserConstant'
import useBannedPlayerForm from './useBannedPlayerForm'
import { addBannedUserSchema, editBannedUserSchema } from '../schema'
const BannedPlayerForm = (props) => {
  const {
    isBannedPlayerFormVisible,
    closeBannedPlayerForm,
    selectedBannedPlayer,
    handleBannedPlayerSubmit,
    isTenant,
    isEdit,
    adminId,
    tenantId
  } = props

  const {
    selectedOwner,
    setSelectedOwner,
    selectedPortal,
    setSelectedPortal,
    selectedBanOption,
    setSelectedBanOption,
    addBannedUserInitialValues,
    updateBannedUserInitialValues,
    ownerOptions,
    portalOptions
  } = useBannedPlayerForm(selectedBannedPlayer, isTenant)

  return (
    <Formik
      initialValues={
        isEdit
          ? updateBannedUserInitialValues
          : addBannedUserInitialValues
      }
      validationSchema={isEdit ? editBannedUserSchema() : addBannedUserSchema(!!isTenant)}
      onSubmit={(values) => {
        handleBannedPlayerSubmit(values)
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        errors
      }) => (
        <Modal
          show={isBannedPlayerFormVisible}
          onHide={closeBannedPlayerForm}
          backdrop='static'
          keyboard={false}
        >

          <Modal.Header closeButton>
            <h5> Banned Player</h5>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {!isTenant && !isEdit && (
                <Col xs={12} className='mt-3'>
                  <CustomReactSelect
                    labelText='Select Ban Type'
                    formFieldName='bannedType'
                    selectOptions={banOptions}
                    selectedValues={selectedBanOption}
                    setSelectedValues={setSelectedBanOption}
                    selectClassName='basic-select'
                    selectClassPrefix='select'
                    setFieldValue={setFieldValue}
                    showError
                    isDisabled={isEdit}
                  />
                </Col>
              )}

              {!isTenant && selectedBanOption && (
                <Col xs={12} className='mt-3'>
                  <CustomReactSelect
                    labelText='Owner'
                    formFieldName='ownerId'
                    selectOptions={ownerOptions}
                    selectedValues={selectedOwner}
                    setSelectedValues={setSelectedOwner}
                    selectClassName='basic-select'
                    selectClassPrefix='select'
                    setFieldValue={setFieldValue}
                    showError
                    isDisabled={isEdit}
                  />
                </Col>
              )}

              {(!isEdit && selectedBanOption === 'ban_across_multiple_brands' && selectedOwner)
                ? (
                  <Col xs={12} className='mt-3'>
                    <CustomReactSelect
                      labelText='Portal'
                      formFieldName='tenantIds'
                      selectOptions={portalOptions}
                      selectedValues={selectedPortal}
                      setSelectedValues={setSelectedPortal}
                      selectClassName='basic-select'
                      selectClassPrefix='select'
                      setFieldValue={setFieldValue}
                      showError
                      isDisabled={isEdit}
                      allowMultiple
                    />
                  </Col>
                  )
                : null}

              {!adminId && tenantId && isEdit &&
                <Col xs={12} className='mt-3'>
                  <Form.Label>Portal</Form.Label>
                  <Form.Control
                    name='tenant'
                    type='text'
                    value={selectedPortal}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                  />
                </Col>}

              {personalInfoFields?.map(({ label, value }) => {
                return (
                  <Col xs={12} key={value} className='mt-3'>
                    <Form.Label>{label}{' '}<span className='text-danger'> *</span></Form.Label>
                    <Form.Control
                      name={value}
                      type='text'
                      placeholder={`Enter ${label}`}
                      value={values[value]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <ErrorMessage
                      component='div'
                      name={value}
                      className='text-danger'
                    />
                  </Col>
                )
              })}
              <Col xs={12} className='mt-3'>
                <Form.Label>Date of Birth{' '}<span className='text-danger'> *</span></Form.Label>
                <Form.Control
                  name='dateOfBirth'
                  type='date'
                  value={values.dateOfBirth}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component='div'
                  name='dateOfBirth'
                  className='text-danger'
                />
              </Col>
            </Row>
          </Modal.Body>
          <div className='mt-4 '>
            <Modal.Footer className='d-flex justify-content-between align-items-center'>
              <Button
                variant='outline-warning'
                onClick={closeBannedPlayerForm}
              >
                Cancel
              </Button>
              <Button
                variant='outline-success'
                onClick={handleSubmit}
                className='ml-2'
              >
                Submit
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      )}
    </Formik>
  )
}

export default BannedPlayerForm
