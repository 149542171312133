import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getLoginToken } from '../../../../utils/storageUtils'
import { useDidMountEffect } from '../../../../utils/useDidMountEffect'
import { getCasinoTransactionSortedValue } from '../../../../utils/constants'
import { formatDateYMD, getDateDaysAgo } from '../../../../utils/dateFormatter'
import { getAllTAProvidersStart } from '../../../../store/redux-slices/tenantCasino'
import { getAllSAProvidersStart } from '../../../../store/redux-slices/superAdminCasinoManagement'
import { getBannedGamesDetailsStart, getSuperAdminCasinoTransactionsStart } from '../../../../store/redux-slices/superAdminTransactions'

const useCasinoTransactionsList = ({ isTenant = false }) => {
  const dispatch = useDispatch()
  const { userId } = useParams()

  const [selectedAction, setSelectedAction] = useState('all')
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [status, setStatus] = useState('all')
  const [showBannedGameAmount, setShowBannedGameAmount] = useState(false)
  const [amountType, setAmountType] = useState('')
  const [sortBy, setSortBy] = useState({
    bonusAmount: '',
    cashAmount: '',
    id: '',
    afterBalance: '',
    beforeBalance: '',
    userId: userId || '',
    isSortingField: null
  })
  const [over, setOver] = useState({
    bonusAmount: false,
    cashAmount: false,
    afterBalance: false,
    beforeBalance: false,
    userId: false,
    id: false
  })
  const { loading, casinoTransactions: casinoBetTransaction, bannedGames } = useSelector((state) => state.superAdminTransactions)
  const { userData } = useSelector((state) => state.fetch)
  const { allProviders } = useSelector((state) => isTenant ? state.tenantCasino : state.superAdminCasino)
  const [gameSearch, setGameSearch] = useState('')
  const [providers, setProviders] = useState('')
  const isInitialRender = useDidMountEffect()
  const casinoTransactions = (typeof bannedGames?.totalWageringAmount === 'number' && !loading && showBannedGameAmount)
    ? bannedGames?.casinoDetail
    : casinoBetTransaction

  const totalPages = Math.ceil(casinoTransactions?.count / limit)

  const [state, setState] = useState([
    {
      startDate: getDateDaysAgo(10),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  const fetchTxData = () => {
    const orderBy = getCasinoTransactionSortedValue[sortBy.isSortingField] || ''
    const { bonusAmount, cashAmount, id, afterBalance, beforeBalance, userId: userIdOrder } = sortBy
    const sort = bonusAmount || cashAmount || id || afterBalance || beforeBalance || userIdOrder || ''

    dispatch(getSuperAdminCasinoTransactionsStart({
      limit,
      pageNo: page,
      userId: userId || '',
      startDate: formatDateYMD(state.map(a => a.startDate)),
      endDate: formatDateYMD(state.map(a => a.endDate)),
      currencyCode: '',
      transactionType: selectedAction,
      gameSearch: gameSearch.replace(/\s/g, ''),
      tenantId: '',
      adminId: '',
      status,
      searchByUserId: userId,
      amountType,
      orderType: sort,
      orderBy,
      isTenant,
      providers
    }))
  }

  useEffect(() => {
    isTenant ? dispatch(getAllTAProvidersStart()) : dispatch(getAllSAProvidersStart({ tenantId: userData?.tenantId || '' }))
  }, [])

  const fetchBannedGames = () => {
    const orderBy = getCasinoTransactionSortedValue[sortBy.isSortingField] || ''
    const { bonusAmount, cashAmount, id, afterBalance, beforeBalance, userId: userIdOrder } = sortBy
    const sort = bonusAmount || cashAmount || id || afterBalance || beforeBalance || userIdOrder || ''
    dispatch(getBannedGamesDetailsStart({
      startDate: formatDateYMD(state.map(a => a.startDate)),
      endDate: formatDateYMD(state.map(a => a.endDate)),
      userId,
      gameSearch: gameSearch.replace(/\s/g, ''),
      tenantId: userData?.tenantId,
      limit,
      pageNo: page,
      orderType: sort,
      orderBy,
      isTenant,
      providers
    }))
  }

  useEffect(() => {
    if (showBannedGameAmount) fetchBannedGames()
  }, [state, limit, page, showBannedGameAmount, providers])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          if (!showBannedGameAmount) {
            fetchTxData()
          } else {
            fetchBannedGames()
          }
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [gameSearch])

  useEffect(() => {
    if (!showBannedGameAmount) fetchTxData()
  }, [sortBy, providers, showBannedGameAmount, limit, page, selectedAction, state, status, userId, amountType])

  const getCsvDownloadUrl = () =>
    `${process.env.REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/casino/${showBannedGameAmount ? 'banned-games' : 'transactions'}?csvDownload=true&limit=${limit}&pageNo=${page}&startDate=${formatDateYMD(state.map(a => a.startDate))}&endDate=${formatDateYMD(state.map(a => a.endDate))}&currencyCode=&transactionType=${selectedAction}&tenantId=${showBannedGameAmount ? userData?.tenantId : ''}&status=${status}&email=&adminId=&token=${getLoginToken()}&userId=${userId || ''}`

  return {
    page,
    over,
    limit,
    state,
    sortBy,
    status,
    loading,
    setPage,
    setOver,
    setState,
    setLimit,
    userData,
    setSortBy,
    setStatus,
    totalPages,
    amountType,
    gameSearch,
    setGameSearch,
    bannedGames,
    setAmountType,
    selectedAction,
    getCsvDownloadUrl,
    setSelectedAction,
    showBannedGameAmount,
    setShowBannedGameAmount,
    casinoTransactions,
    allProviders,
    providers,
    setProviders
  }
}

export default useCasinoTransactionsList
