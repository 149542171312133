import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const Trigger = ({ message, children, placement = 'bottom' }) => {
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 100, hide: 200 }}
      overlay={<Tooltip style={{ margin: 0 }}>{message}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  )
}

export default Trigger
