import { Button, Spinner, Form as BForm, Table } from '@themesberg/react-bootstrap'
import { ErrorMessage, Formik, Form } from 'formik'
import React from 'react'
import { loyaltyPointsSchema } from '../schema'

const TACurrencies = ({
  pointsObject,
  updateLoyaltyLimit,
  tenantDetails,
  enableEdit,
  loading
}) => {
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
        { updateLimit: pointsObject }
      }
        validationSchema={loyaltyPointsSchema()}
        onSubmit={(formValues) => updateLoyaltyLimit(formValues)}
      >
        {({ values, handleChange, handleSubmit, handleBlur }) => (
          <Form className='mt-4'>
            {/* <h4>Currencies</h4> */}
            <Table bordered responsive hover>
              <thead className='thead-dark'>
                <tr>
                  <th>Currency</th>
                  <th>Exchange Rates</th>
                  <th>Loyalty Points</th>
                </tr>
              </thead>
              <tbody>
                {values?.updateLimit &&
                       values?.updateLimit?.map((key, index) => (
                         <tr key={index}>
                           <td>{key?.code}</td>
                           <td className='overflow-auto'>
                             {enableEdit
                               ? (
                                 <div style={{ width: '150px' }}>
                                   <BForm.Control
                                     type='number'
                                     name={`updateLimit[${index}][exchangeRates]`}
                                     min={0}
                                     size='sm'
                                     value={values?.updateLimit?.[index]?.exchangeRates}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                   />

                                   <ErrorMessage
                                     component='div'
                                     name={`updateLimit[${index}][exchangeRates]`}
                                     className='text-danger'
                                   />
                                 </div>
                                 )
                               : values?.updateLimit?.[index]?.exchangeRates}
                           </td>
                           <td className='overflow-auto'>
                             {enableEdit
                               ? (
                                 <div style={{ width: '150px' }}>
                                   <BForm.Control
                                     type='number'
                                     name={`updateLimit[${index}][loyaltyPoint]`}
                                     min={0}
                                     size='sm'
                                     value={values?.updateLimit?.[index]?.loyaltyPoint}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                   />

                                   <ErrorMessage
                                     component='div'
                                     name={`updateLimit[${index}][loyaltyPoint]`}
                                     className='text-danger'
                                   />
                                 </div>
                                 )
                               : values?.updateLimit?.[index]?.loyaltyPoint}
                           </td>
                         </tr>
                       ))}
              </tbody>
            </Table>
            <div className='mt-4 d-flex justify-content-end'>
              <Button
                variant='outline-success'
                onClick={handleSubmit}
                hidden={!enableEdit}
                className='ml-2'
              >
                Submit
                {loading && (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default TACurrencies
