import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAllTenantAdminsStart } from '../../../../store/redux-slices/tenantsFetchData'
import { useDispatch, useSelector } from 'react-redux'
import { updateTenantAdminStatusStart } from '../../../../store/redux-slices/admins'
import useDidMountEffect from '../../../../utils/useDidMountEffect'

const useAdminsListing = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [orderBy, setOrderBy] = useState('adminUserId')
  const [sort, setSort] = useState('desc')
  const [over, setOver] = useState(false)
  const [adminId, setAdminId] = useState()
  const [active, setActive] = useState()
  const [show, setShow] = useState(false)
  const [search, setSearch] = useState('')
  const { tenantAdminsData, loading } = useSelector((state) => state.tenantsFetch)
  const totalPages = Math.ceil(tenantAdminsData?.count / limit)
  const { tenantAdminRole } = useSelector((state) => state.login)
  const isInitialRender = useDidMountEffect()
  const [name, setName] = useState()
  const [status, setStatus] = useState(true)

  const getRole = (id) =>
    tenantAdminRole &&
    tenantAdminRole.find((obj) => obj.adminRoleId === id).name

  const selected = (h) =>
    orderBy === h.value &&
    h.label !== 'Phone Number' &&
    h.label !== 'Role' &&
    h.label !== 'Status' &&
    h.label !== 'Action' &&
    h.label !== 'Group'

  const handleShow = (id, active, myName) => {
    setAdminId(id)
    setActive(!active)
    setShow(true)
    setName(myName)
  }

  const handleYes = () => {
    dispatch(
      updateTenantAdminStatusStart({
        limit,
        pageNo: page,
        orderBy,
        sort,
        adminId,
        status: active,
        isTenant: true,
        active: status
      })
    )
    setShow(false)
  }

  useEffect(() => {
    dispatch(getAllTenantAdminsStart({ limit, pageNo: page, sort, orderBy, search: search.replace('+', '%2B'), status }))
  }, [page])

  useEffect(() => {
    setPage(1)
    dispatch(getAllTenantAdminsStart({ limit, pageNo: 1, sort, orderBy, search: search.replace('+', '%2B'), status }))
  }, [orderBy, limit, sort, status])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(getAllTenantAdminsStart({ limit, pageNo: page, sort, orderBy, search: search.replace('+', '%2B'), status }))
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  return {
    navigate,
    limit,
    page,
    sort,
    over,
    setLimit,
    setPage,
    setOrderBy,
    setSort,
    setOver,
    tenantAdminsData,
    totalPages,
    getRole,
    selected,
    show,
    handleShow,
    handleYes,
    setShow,
    active,
    search,
    setSearch,
    name,
    loading,
    status,
    setStatus
  }
}

export default useAdminsListing
