export const dateConstants = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Month To Date', value: 'monthtodate' },
  { label: 'Custom', value: 'custom' },
  { label: 'Last 7 Days', value: 'last7days' },
  { label: 'Last 30 Days', value: 'last30days' },
  { label: 'Last 90 Days', value: 'last90days' },
  { label: 'Week To Date', value: 'weektodate' },
  { label: 'Year To Date', value: 'yeartodate' },
  { label: 'Previous Month', value: 'previousmonth' },
  { label: 'Previous Year', value: 'previousyear' }
]

export const limitConstants = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '15', value: 15 },
  { label: '20', value: 20 },
  { label: '25', value: 25 },
  { label: '30', value: 30 },
  { label: '35', value: 35 },
  { label: '40', value: 40 },
  { label: '45', value: 45 },
  { label: '50', value: 50 }
]
