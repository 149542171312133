import * as Yup from 'yup'

export const addBannedUserSchema = (isTenantLogin) => {
  return Yup.object().shape({
    email: Yup.string()
      .email('Invalid Email')
      .max(50, 'Maximum 50 Characters Allowed')
      .required('Email Required'),
    firstName: Yup.string()
      .min(3, 'Minimum 3 Characters Required')
      .max(50, 'Maximum 50 Characters Allowed')
      .matches(/^[A-Za-z]+$/, 'First Name cannot contain numbers or special characters')
      .required('First Name Required'),
    lastName: Yup.string()
      .min(3, 'Minimum 3 Characters Required')
      .max(50, 'Maximum 50 Characters Allowed')
      .matches(/^[A-Za-z]+$/, 'Last Name cannot contain numbers or special characters')
      .required('Last Name Required'),
    dateOfBirth: Yup.date()
      .max(new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate() - 1), 'Must Be 18 Years Old')
      .required('DOB Required'),

    bannedType: isTenantLogin
      ? Yup.string().notRequired() // Not required when tenant logs in
      : Yup.string().required('Ban Type is required'),

    ownerId: isTenantLogin
      ? Yup.string().notRequired() // Not required when tenant logs in
      : Yup.string().required('Owner ID is required'),

    tenantIds: isTenantLogin
      ? Yup.array().min(1, 'At least one tenant is required').required('Tenant IDs are required')
      : Yup.array().of(Yup.string()).when('bannedType', {
        is: (bannedType) => bannedType === 'ban_across_multiple_brands' || isTenantLogin,
        then: Yup.array().of(Yup.string()).min(1, 'At least one tenant is required').required('Tenant IDs are required'),
        otherwise: Yup.array().of(Yup.string()).notRequired()
      })
  })
}
export const editBannedUserSchema = () => Yup.object().shape({
  email: Yup.string()
    .email('Invalid Email')
    .max(50, 'Maximum 50 Characters Allowed')
    .required('Email Required'),
  firstName: Yup.string()
    .min(3, 'Minimum 3 Characters Required')
    .max(50, 'Maximum 50 Characters Allowed')
    .matches(/^[A-Za-z]+$/, 'First Name cannot contain numbers or special characters')
    .required('First Name Required'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 Characters Required')
    .max(50, 'Maximum 50 Characters Allowed')
    .matches(/^[A-Za-z]+$/, 'Last Name cannot contain numbers or special characters')
    .required('Last Name Required'),
  dateOfBirth: Yup.date()
    .max(new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate() - 1), 'Must Be 18 Years Old')
    .required('DOB Required')
})
