import React from 'react'
import useGroups from './hooks/useGroups'
import { Row, Col, Button, Table, ButtonGroup } from '@themesberg/react-bootstrap'
import { SuperAdminRoutes, TenantRoutes } from '../../routes'
import Trigger from '../OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faEye } from '@fortawesome/pro-solid-svg-icons'
import ParentGroupForm from './components/ParentGroupForm'
import { Accordion } from 'react-bootstrap'

const GroupManagement = () => {
  const {
    groupsList,
    isHidden,
    groupLoading,
    navigate,
    isTenant,
    parentGroupFormVisible,
    setParentGroupFormVisible,
    selectedParentGroup,
    setSelectedParentGroup
  } = useGroups()

  const closeParentGroupFormModal = () => {
    setParentGroupFormVisible(false)
  }

  return (
    <>
      <Row>
        <Col>
          <h3>Group Management</h3>
        </Col>

        <Col>
          <div className='d-flex justify-content-end align-items-center'>
            {/* <Form.Label style={{ marginRight: '15px', marginTop: '8px' }}>
              Search
            </Form.Label>

            <Form.Control
              type='search'
              placeholder='Search by Group Name'
              value={groupName}
              size='sm'
              style={{ maxWidth: '230px', marginRight: '10px', maxHeight: '15px' }}
              onChange={(event) => {
                const mySearch = event.target.value.replace(/[^\w\s+@.]/gi, '')
                setGroupName(mySearch)
              }}
            /> */}

            {/* Button to create new parent group */}
            <Button
              variant='outline-success'
              className='mb-2 m-1'
              size='sm'
              onClick={() => {
                setSelectedParentGroup(null)
                setParentGroupFormVisible(true)
              }}
              hidden={isHidden({ module: { key: 'GroupPermission', value: 'C' } })}
            >
              Create Parent Group
            </Button>
            {/* Button to create new group */}
            <Button
              variant='success'
              className='mb-2 m-1'
              size='sm'
              onClick={() =>
                navigate(isTenant ? TenantRoutes.CreateGroup : SuperAdminRoutes.CreateGroup)}
              hidden={isHidden({ module: { key: 'GroupPermission', value: 'C' } })}
            >
              Create Group
            </Button>
          </div>
        </Col>
      </Row>

      {parentGroupFormVisible && (
        <ParentGroupForm parentGroup={selectedParentGroup} closeModal={closeParentGroupFormModal} />
      )}

      <Accordion defaultActiveKey={0}>
        {
          !groupLoading && groupsList?.rows?.map((parentGroup, index) => (
            <Accordion.Item key={parentGroup.id} eventKey={index}>
              <Accordion.Header>
                <div className='d-flex justify-content-between w-100'>
                  <span>{parentGroup.parentGroupName}</span>
                  {parentGroup.id && (
                    <Trigger message='Edit Parent Group'>
                      <Button
                        className='mx-2'
                        size='xs'
                        variant='warning'
                        onClick={(e) => {
                          e.stopPropagation()
                          setSelectedParentGroup(parentGroup)
                          setParentGroupFormVisible(true)
                        }}
                        hidden={isHidden({ module: { key: 'GroupPermission', value: 'U' } })}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                    </Trigger>
                  )}

                </div>
              </Accordion.Header>
              <Accordion.Body>
                <Table bordered striped responsive hover size='sm' className='text-center'>
                  <thead className='thead-dark'>
                    <tr>
                      {['ID', 'Group Name', 'Action'].map((h, idx) => (
                        <th key={idx}>
                          {h}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody id={groupLoading ? 'cover-spin' : ''}>
                    {parentGroup?.groupDetails?.map(
                      ({
                        groupId,
                        groupName
                      }) => {
                        return (
                          <tr key={groupId}>
                            <td>{groupId}</td>

                            <td>{groupName || '-'}</td>

                            <td>
                              <ButtonGroup>
                                <Trigger message='Edit'>
                                  <Button
                                    className='m-1'
                                    size='sm'
                                    variant='warning'
                                    onClick={() =>
                                      navigate(
                                        `/${isTenant ? 'tenant' : 'super-admin'}/group-management/edit/${groupId}`
                                      )}
                                    hidden={isHidden({ module: { key: 'GroupPermission', value: 'U' } })}
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </Button>
                                </Trigger>

                                <Trigger message='View Details'>
                                  <Button
                                    className='m-1'
                                    size='sm'
                                    variant='info'
                                    onClick={() =>
                                      navigate(
                                        `/${isTenant ? 'tenant' : 'super-admin'}/group-management/details/${groupId}`
                                      )}
                                    hidden={isHidden({ module: { key: 'GroupPermission', value: 'R' } })}
                                  >
                                    <FontAwesomeIcon icon={faEye} />
                                  </Button>
                                </Trigger>
                              </ButtonGroup>
                            </td>
                          </tr>
                        )
                      }
                    )}

                    {!parentGroup?.groupDetails?.length &&
                      (
                        <tr>
                          <td
                            colSpan={6}
                            className='text-danger text-center'
                          >
                            No data found
                          </td>
                        </tr>
                      )}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          ))
        }
        {
          !groupLoading && !groupsList?.rows?.length && (
            <div className='text-center text-danger'>
              <p>No data found</p>
            </div>
          )
        }
      </Accordion>
    </>
  )
}

export default GroupManagement
