import { Col, InputGroup, Row, Form as BForm, Button, Spinner } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { adminProfileSchema } from './schema'

const Overview = ({
  details,
  isTenant,
  setEditable,
  editable,
  updateData,
  constant,
  loading
}) => {
  return (
    <>
      <Row className='my-n2 pt-3'>
        <Col sm={12} className='my-2'>
          <div className='text-right m-n1'>
            <button
              type='button' className='m-1 btn btn-warning'
              onClick={() => {
                setEditable(true)
              }}
            >Edit
            </button>
          </div>
        </Col>

        {details &&
          <Formik
            enableReinitialize
            initialValues={{
              firstName: details?.firstName,
              lastName: details?.lastName,
              email: details?.email,
              superAdminUsername: details?.superAdminUsername || '',
              phone: isTenant ? details?.phone : '',
              role: isTenant ? details?.AdminRole?.name : details?.SuperadminRole?.name,
              agentName: details?.agentName || '',
              group: details?.group || ''
            }}
            validationSchema={adminProfileSchema}
            onSubmit={(
              formValues
            ) => {
              updateData({
                ...formValues,
                superAdminId: isTenant ? '' : details?.superAdminUserId,
                adminUserId: isTenant ? details?.adminUserId : ''
              })
            }}
          >
            {({ values, handleChange, handleSubmit, handleBlur }) => {
              return (
                <Form>
                  <Row lg={4} md={4} sm={6}>
                    {details && constant.map(({ key, value, subValue, edit }, index) => {
                      return (
                        <Col lg={4} md={4} sm={6} className='my-2' key={index} hidden={(details?.adminRoleId === 1 || details?.superRoleId === 1) ? key === 'Group' : false}>
                          <div className='bg-light py-2 px-3 rounded'>
                            <label className='fw-bold'>{key || 'N/A'}</label>
                            <span className='mb-0'>
                              {key === 'Status'
                                ? (details[value] ? 'Active' : 'In-Active')
                                : subValue
                                  ? <p>{details?.[value]?.[subValue]}</p>
                                  : (
                                    <>
                                      <InputGroup>
                                        <BForm.Control
                                          type='text'
                                          name={value}
                                          disabled={!edit || !editable}
                                          value={values?.[value]}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                      </InputGroup>

                                      <ErrorMessage
                                        component='div'
                                        name={value}
                                        className='text-danger'
                                      />
                                    </>
                                    )}
                            </span>
                          </div>
                        </Col>
                      )
                    })}
                  </Row>
                  <div className='mt-4 mb-3'>
                    <Button
                      variant='outline-success'
                      onClick={handleSubmit}
                      className='ml-2'
                      hidden={!editable}
                    >
                      Submit
                      {loading && (
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      )}
                    </Button>
                  </div>
                </Form>
              )
            }}
          </Formik>}

      </Row>
    </>
  )
}

export default Overview
