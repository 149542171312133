import { Col, Form, Row } from '@themesberg/react-bootstrap'
import React from 'react'
import ReactQuill from 'react-quill'

const MultiLangDetail = ({
  bonusDetail,
  selectedLang
}) => {
  return (
    <>
      <Row className=' mt-3 mb-3'>
        <Col sm={4} className='mb-3'>
          <label>
            Promotion Title
          </label>
          <Form.Control
            type='text'
            name='promotionTitle'
            placeholder='Enter Promotion Title'
            value={bonusDetail?.promotionTitle?.[selectedLang] || ''}
            disabled
          />
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <label>
            Promotion Overview
          </label>
          <ReactQuill
            name='termCondition'
            placeholder='Enter Terms and Conditions'
            value={bonusDetail?.promotionalOverview?.[selectedLang] || ''}
            readOnly
          />
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <label>
            Terms & Conditions
          </label>
          <ReactQuill
            name='termCondition'
            placeholder='Enter Terms and Conditions'
            value={bonusDetail?.termCondition?.[selectedLang] || ''}
            readOnly
          />
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col>
          <label>
            Description
          </label>
          <ReactQuill
            name='termCondition'
            placeholder='Enter Terms and Conditions'
            value={bonusDetail?.description?.[selectedLang] || ''}
            readOnly
          />
        </Col>
      </Row>
    </>
  )
}

export default MultiLangDetail
