import { takeLatest, put } from 'redux-saga/effects'
import { addBannedPlayer, bannedPlayerSetting, deleteBannedPlayer, getBannedGamesList, updateBannedPlayer } from '../../utils/apiCalls'
import { toast } from '../../components/Toast'
import {
  getBannedPlayersStart,
  getBannedPlayersCompleted,
  getBannedPlayersFrailer,
  addBannedPlayerStart, addBannedPlayerFailed,
  editBannedPlayerStart, editBannedPlayerFailed,
  deleteBannedPlayerStart, deleteBannedPlayerFailed,
  bannedPlayerSettingStart,
  bannedPlayerSettingFailed
} from '../redux-slices/bannedPlayer'

export default function * bannedPlayerWatcher () {
  yield takeLatest(getBannedPlayersStart.type, getBannedPlayers)
  yield takeLatest(addBannedPlayerStart.type, createBannedPlayer)
  yield takeLatest(editBannedPlayerStart.type, handleEditBannedPlayer)
  yield takeLatest(deleteBannedPlayerStart.type, handleDeleteBannedPlayer)
  yield takeLatest(bannedPlayerSettingStart.type, handleBannedPlayerSetting)
}

function * getBannedPlayers (action) {
  try {
    const { ownerId, tenantId, limit, pageNo, search } = action && action.payload
    const { data } = yield getBannedGamesList({ ownerId, tenantId, limit, pageNo, search })
    yield put(getBannedPlayersCompleted(data?.data?.bannedPlayers))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getBannedPlayersFrailer(e?.response?.data?.errors[0]?.description))
  }
}

function * createBannedPlayer (action) {
  try {
    const { formValues, setIsBannedPlayerFormVisible, getBannedPlayer, setSelectedBannedPlayer } = action && action.payload
    const response = yield addBannedPlayer(formValues)
    yield toast(response?.data?.data?.message, 'success')
    getBannedPlayer()
    setIsBannedPlayerFormVisible(false)
    setSelectedBannedPlayer('')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(addBannedPlayerFailed(e?.response?.data?.errors[0]?.description))
  }
}

function * handleEditBannedPlayer (action) {
  try {
    const { formValues, setIsBannedPlayerFormVisible, getBannedPlayer, setSelectedBannedPlayer } = action && action.payload
    const response = yield updateBannedPlayer(formValues)
    yield toast(response?.data?.data?.message, 'success')
    getBannedPlayer()
    setIsBannedPlayerFormVisible(false)
    setSelectedBannedPlayer('')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(editBannedPlayerFailed(e?.response?.data?.errors[0]?.description))
  }
}

function * handleDeleteBannedPlayer (action) {
  try {
    const { formValues, setShowBannedPlayerDeleteModal, getBannedPlayer } = action && action.payload
    const response = yield deleteBannedPlayer(formValues)
    yield toast(response?.data?.data?.message, 'success')
    getBannedPlayer()
    setShowBannedPlayerDeleteModal(false)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(deleteBannedPlayerFailed(e?.response?.data?.errors[0]?.description))
  }
}

function * handleBannedPlayerSetting (action) {
  try {
    const { data } = action && action.payload
    if (data && data.tenantId) {
      data.tenantId = Number(data.tenantId) // or parseInt(data.tenantId, 10)
    }
    const response = yield bannedPlayerSetting(data)
    yield toast(response?.data?.data?.message, 'success')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(bannedPlayerSettingFailed(e?.response?.data?.errors[0]?.description))
  }
}
