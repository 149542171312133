import { Col, Row, Form as BForm, Button, Spinner } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import ProviderFilter from '../../../../components/ProviderFilter'
import { editWageringTemplate } from './wageringTemplateSchema'
import useEditWageringTemplate from '../hooks/useEditWageringTemplate'
import WageringListEdit from './WageringListEdit'
import { toast } from '../../../../components/Toast'

const EditWageringTemplate = () => {
  const {
    loading,
    navigate,
    selectedProvider,
    setSelectedProvider,
    gameContribution,
    casinoGamesData,
    setGameContribution,
    customValue,
    customValueHandler,
    wageringTemplateDetail,
    formSubmitHandler,
    search,
    setSearch,
    setContributionDefaultValue,
    contributionDefaultValue,
    page,
    limit,
    setLimit,
    setPage,
    totalPages
  } = useEditWageringTemplate()

  return (
    <>
      <Row>
        <Col>
          <h3>Edit Wagering Template: {wageringTemplateDetail?.name || ''}</h3>
        </Col>
        <Col xs='auto'>
          <Button
            size='sm'
            variant='outline-success'
            onClick={() => {
              if (Object.keys(gameContribution).length < 1) {
                toast('select at least one game', 'error')
              } else if (!customValue) {
                toast('enter custom value before save', 'error')
              } else {
                toast('Contribution Saved Successfully', 'success')
                setContributionDefaultValue((oldValue) => {
                  return { ...oldValue, ...gameContribution }
                })
                setGameContribution({})
              }
            }}
          >
            Save
          </Button>
        </Col>
      </Row>

      {wageringTemplateDetail &&
        <Formik
          initialValues={{
            name: wageringTemplateDetail?.name,
            customValue: ''
          }}
          validationSchema={editWageringTemplate}
          onSubmit={(formValues) => {
            formSubmitHandler(formValues)
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur
          }) => (
            <Form>
              <Row>
                <Col xs='auto'>
                  <BForm.Label>
                    Template Name <span className='text-danger'>*</span>
                  </BForm.Label>

                  <BForm.Control
                    type='text'
                    name='name'
                    size='sm'
                    style={{ maxWidth: '400px' }}
                    placeholder='Enter Name'
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component='div'
                    name='name'
                    className='text-danger small-size-error'
                  />
                </Col>
                <Col xs='auto'>
                  <BForm.Label>
                    Custom Value
                  </BForm.Label>

                  <BForm.Control
                    type='number'
                    name='customValue'
                    size='sm'
                    min='0'
                    style={{ maxWidth: '400px' }}
                    placeholder='Custom Value'
                    value={customValue}
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        handleChange(e)
                        customValueHandler(e.target.value)
                      }
                    }}
                    onBlur={handleBlur}
                  />
                </Col>
                <Col xs='auto' className='align-items-center provider'>
                  <ProviderFilter
                    selectedProvider={selectedProvider}
                    setSelectedProvider={setSelectedProvider}
                    isTenantRoute
                  />
                </Col>
                <Col xs='auto'>
                  <BForm.Label>
                    Search
                  </BForm.Label>

                  <BForm.Control
                    type='search'
                    size='sm'
                    style={{ maxWidth: '400px' }}
                    placeholder='Search Game Name'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onBlur={handleBlur}
                  />
                </Col>
              </Row>

              <Row>
                {casinoGamesData &&
                  <WageringListEdit
                    gameContribution={gameContribution}
                    casinoGamesData={casinoGamesData || []}
                    setGameContribution={setGameContribution}
                    customValue={customValue}
                    defaultGames={contributionDefaultValue || {}}
                    page={page}
                    limit={limit}
                    setLimit={setLimit}
                    setPage={setPage}
                    totalPages={totalPages}
                    loading={loading}
                  />}
              </Row>

              <div
                className='mt-4 d-flex justify-content-between align-items-center'
              >
                <Button
                  variant='outline-warning'
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>

                <div>
                  <Button
                    variant='outline-success'
                    onClick={handleSubmit}
                  >
                    Submit
                    {loading && (
                      <Spinner
                        as='span'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>}
    </>
  )
}

export default EditWageringTemplate
