import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Form as BForm, InputGroup, Button } from '@themesberg/react-bootstrap'
import { ErrorMessage } from 'formik'
import React, { useEffect, useState } from 'react'
import { myLabels } from './constants'
import Trigger from '../OverlayTrigger'
import { useDispatch, useSelector } from 'react-redux'
import { getloyaltyLevelStart } from '../../store/redux-slices/superAdminSettings'
import useCheckPermission from '../../utils/checkPermission'
import Preloader, { InlineLoader } from '../Preloader'
import { isTenant } from '../../utils/constants'

const LoyaltyPoint = ({
  values,
  handleBlur,
  handleChange,
  bonus,
  details,
  deleteLevel,
  touched,
  errors,
  loading = false,
  tenantLoyalty = false
}) => {
  const { loyaltyLevel } = useSelector(state => state.superAdminSettings)
  const { bonusList } = useSelector(state => state.admins)
  const { bonusDetail, bonusList: TABonusList } = useSelector(state => state.bonus)
  const [myValues, setMyValues] = useState()
  const dispatch = useDispatch()
  const { isHidden } = useCheckPermission()

  const actualBonuses = isTenant ? TABonusList : bonusList

  useEffect(() => {
    details && !bonus && dispatch(getloyaltyLevelStart({ isTenant, tenantId: bonusDetail?.tenantId }))
  }, [])

  useEffect(() => {
    if (!bonus && details) {
      const newLoyaltyLevel = []
      for (const level in loyaltyLevel) {
        const obj = loyaltyLevel[level]
        let data = values?.bonusType === 'winBoost'
          ? {
              level: obj.level,
              startPoint: obj.startPoint,
              endPoint: obj.endPoint,
              bonusPercentage: (values?.loyaltyLevel?.[level]?.bonusPercentage * 100).toFixed(2)
            }
          : {
              level: obj.level,
              startPoint: obj.startPoint,
              endPoint: obj.endPoint,
              bonusPercentage: (values?.loyaltyLevel?.[level]?.bonusPercentage * 100).toFixed(2),
              cashback_multiplier: values?.loyaltyLevel?.[level]?.cashback_multiplier
            }
        if (values?.loyaltyLevel?.[level]?.maxBonusThreshold) {
          data = values?.bonusType === 'winBoost'
            ? { ...data, maxWinAmount: values?.loyaltyLevel?.[level]?.maxWinAmount }
            : { ...data, maxBonusThreshold: values?.loyaltyLevel?.[level]?.maxBonusThreshold, maxWinAmount: values?.loyaltyLevel?.[level]?.maxWinAmount }
        }
        newLoyaltyLevel.push(data)
      }
      setMyValues(newLoyaltyLevel)
    }
  }, [loyaltyLevel])

  return (
    <>
      {!myValues
        ? (
          <>
            {loading
              ? bonus ? <InlineLoader /> : <Preloader />
              : values?.loyaltyLevel?.map((key, index) => (
                <Row key={index} className='g-2 mb-2'>
                  <Col className='d-flex justify-content-around' md={14}>
                    {Object.keys(values?.loyaltyLevel[index])?.map((labels, labelsIndex) => {
                      return labels !== 'bonusId' && (
                        <Col className='px-1 align-items-center' key={labelsIndex}>
                          {index < 1 && (
                            <>
                              <label htmlFor={labels} style={{ fontSize: '0.8rem' }}>
                                {(!bonus && !details) && labels === 'cashback_multiplier' ? 'Percentage' : myLabels.find(val => val.value === labels)?.label}
                                <span className='text-danger'> *</span>
                              </label>
                              <br />
                            </>
                          )}

                          <InputGroup>
                            {labels === 'startPoint' &&
                              <InputGroup.Text
                                style={{ backgroundColor: '#f5f8fb' }}
                                className={
                              touched?.loyaltyLevel?.[index]?.[labels] && errors?.loyaltyLevel?.[index]?.[labels]
                                ? 'border border-danger '
                                : ''
}
                              >{'>'}
                              </InputGroup.Text>}

                            <BForm.Control
                              name={`loyaltyLevel[${index}][${labels}]`}
                              type='number'
                              className={
                       touched?.loyaltyLevel?.[index]?.[labels] && errors?.loyaltyLevel?.[index]?.[labels]
                         ? 'border border-danger '
                         : ''
                      }
                              style={{ maxWidth: labels === 'level' ? '80px' : '170px' }}
                              disabled={labels === 'level' || (bonus && (labels !== 'cashback_multiplier' && labels !== 'bonusPercentage' && labels !== 'maxBonusThreshold' && labels !== 'maxWinAmount')) || details}
                              min={index > 0 && labels === 'startPoint' ? values?.loyaltyLevel[index - 1]?.endPoint + 1 : 0}
                              max={9999999}
                              value={values.loyaltyLevel[index][labels]}
                              onInput={handleChange}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </InputGroup>
                          {!details &&
                            <ErrorMessage
                              component='div'
                              name={`loyaltyLevel[${index}][${labels}]`}
                              className='text-danger'
                            />}

                        </Col>
                      )
                    }
                    )}

                    {tenantLoyalty &&
                      <Col className='px-1 align-items-center'>
                        {index < 1 && (
                          <>
                            <label htmlFor='bonus' style={{ fontSize: '0.8rem' }}>
                              Bonus
                              <span className='text-danger'> *</span>
                            </label>
                            <br />
                          </>
                        )}
                        <BForm.Select
                          type='text'
                          size='sm'
                          style={{ width: '220px', height: 'auto' }}
                          name={`loyaltyLevel[${index}][bonusId]`}
                          value={values.loyaltyLevel?.[index]?.bonusId ? parseInt(values.loyaltyLevel?.[index]?.bonusId) : ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value='' disabled>--Select Bonus--</option>
                          {actualBonuses?.length > 0 && actualBonuses?.map(({ bonusId, promotionTitle }) => {
                            return (
                              <option key={bonusId} value={bonusId}>{`${promotionTitle?.EN} (${bonusId})`}</option>
                            )
                          })}
                        </BForm.Select>
                      </Col>}

                    <Col md={2} className='d-flex align-items-center'>{!bonus && !details &&
                      (index === values?.loyaltyLevel?.length - 1) &&
                        <Trigger message='Delete this Level'>
                          <Button
                            variant='danger'
                            size='sm'
                            hidden={isHidden({ module: { key: 'LoyaltyManagement', value: 'U' } })}
                            onClick={() => {
                              deleteLevel(values?.loyaltyLevel)
                            }}
                          ><FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </Trigger>}
                    </Col>
                  </Col>

                </Row>
              ))}
          </>
          )
        : (
          <>
            {myValues?.map((key, index) => (
              <Row key={index} className='g-2 mb-2'>
                <Col className='d-flex justify-content-around' md={14}>
                  {Object.keys(myValues[index])?.map((labels, labelsIndex) => {
                    return (
                      <Col className='px-1 align-items-center' key={labelsIndex}>
                        {index < 1 && (
                          <>
                            <label htmlFor={labels} style={{ fontSize: '0.9rem' }}>
                              {(!bonus && !details) && labels === 'cashback_multiplier' ? 'Percentage' : myLabels.find(val => val.value === labels)?.label}
                              <span className='text-danger'> *</span>
                            </label>
                            <br />
                          </>
                        )}

                        <InputGroup className='justify-content-between'>
                          <BForm.Control
                            name={`loyaltyLevel[${index}][${labels}]`}
                            type='number'
                            style={{ maxWidth: labels === 'level' ? '80px' : '170px' }}
                            disabled
                            min={index > 0 && labels === 'startPoint' ? values?.loyaltyLevel[index - 1]?.endPoint + 1 : 0}
                            max={9999999}
                            value={myValues[index][labels]}
                            onInput={handleChange}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </InputGroup>

                      </Col>
                    )
                  }
                  )}
                </Col>

              </Row>
            ))}
          </>
          )}
    </>
  )
}

export default LoyaltyPoint
