import React from 'react'
import { ErrorMessage, Form, Formik } from 'formik'
import ClientFilter from '../ClientFilter'
import useRealityCheck from './useRealityCheck'
import { isTenant } from '../../utils/constants'
import { Col, Row, Form as BForm, Button } from '@themesberg/react-bootstrap'
import { realityCheckSchema } from './schema'
import { InlineLoader } from '../Preloader'

const RealityCheck = () => {
  const {
    isHidden,
    adminDetails,
    selectedClient,
    realityCheckData,
    setSelectedClient,
    updateRealityCheck,
    realityCheckLoading
  } = useRealityCheck()
  return (
    <>
      <Row>
        <Col>
          <h3>Reality Check</h3>
        </Col>

        {!isTenant &&
          <Col xs='auto'>
            <div className='d-flex justify-content-end align-items-center'>
              <ClientFilter
                hasAllOptions={false}
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
              />
            </div>
          </Col>}
      </Row>

      <Formik
        enableReinitialize
        initialValues={{
          time: realityCheckData?.time || '',
          isActive: realityCheckData?.enableRealityCheck || false
        }}
        validationSchema={realityCheckSchema}
        onSubmit={(formValues) => {
          updateRealityCheck({ ...formValues, time: formValues?.time || 0, adminId: isTenant ? parseInt(adminDetails?.adminUserId) : parseInt(selectedClient) })
        }}
      >
        {({ values, handleChange, handleSubmit, handleBlur, handleReset, setFieldValue }) =>
          realityCheckLoading
            ? <InlineLoader />
            : (
                (isTenant ? true : selectedClient)
                  ? (
                    <Form>
                      <Row className='mt-3'>
                        <Col className='d-flex align-items-center'>
                          <BForm.Label className='me-3'>
                            Enable Check<span className='text-danger'> *</span>
                          </BForm.Label>

                          <BForm.Check
                            type='switch'
                            name='isActive'
                            disabled={isHidden({ module: { key: 'RealityCheck', value: 'U' } })}
                            checked={values.isActive}
                            value={values.isActive}
                            onChange={(e) => {
                              setFieldValue('time', '')
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                          />
                        </Col>
                      </Row>

                      {values.isActive &&
                        <Row className='mt-3'>
                          <Col>
                            <BForm.Label>
                              Time (in minutes)
                            </BForm.Label>

                            <BForm.Control
                              type='number'
                              name='time'
                              className='w-50'
                              disabled={isHidden({ module: { key: 'RealityCheck', value: 'U' } })}
                              placeholder='Enter time in minutes'
                              value={values.time}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />

                            <ErrorMessage
                              component='div'
                              name='time'
                              className='text-danger'
                            />
                          </Col>
                        </Row>}

                      <div className='mt-4 d-flex justify-content-between'>
                        <Button
                          variant='outline-warning'
                          hidden={isHidden({ module: { key: 'RealityCheck', value: 'U' } })}
                          onClick={() => {
                            handleReset()
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant='outline-success'
                          onClick={handleSubmit}
                          hidden={isHidden({ module: { key: 'RealityCheck', value: 'U' } })}
                          className='ml-2'
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                    )
                  : (<div className='text-danger d-flex justify-content-center'>Please Select Client</div>)
              )}
      </Formik>
    </>
  )
}

export default RealityCheck
