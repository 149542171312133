import { Button, Col, Form, Row, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import Preloader from '../../../../components/Preloader'
import { languageCode } from './constants'
import useLanguages from './hooks/useLanguages'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import { CreateLanguageModal, DeleteConfirmationModal } from '../../../../components/ConfirmationModal'

const Languages = () => {
  const {
    name,
    setName,
    isHidden,
    showCreate,
    handleCreate,
    tenantDetails,
    setShowCreate,
    languageLoading,
    showDeleteModal,
    languageOptions,
    handleDeleteYes,
    setShowDeleteModal
  } = useLanguages()

  return (
    <>
      {languageLoading
        ? <Preloader />
        : (
          <>
            <Row>
              <Col>
                <h3>Languages</h3>
              </Col>
              <Col className='text-right'>
                <Button
                  variant='outline-success'
                  size='sm'
                  style={{ marginRight: '10px' }}
                  onClick={() => {
                    setShowCreate(true)
                  }}
                  hidden={isHidden({ module: { key: 'MultiLanguage', value: 'C' } })}
                >Create
                </Button>
              </Col>
            </Row>

            <Table bordered responsive hover className='text-center mt-4'>
              <thead className='thead-dark'>
                <tr>
                  <th>Code</th>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {tenantDetails?.tenantConfig?.allowedLanguages?.map((item, i) => (
                  <tr key={`tenant-language-listing ${i}`}>
                    <td>{item}</td>
                    <td>{languageCode?.[item]}</td>
                    <td>
                      {!isHidden({ module: { key: 'MultiLanguage', value: 'U' } })
                        ? (
                          <Button
                            variant='danger'
                            size='sm'
                            onClick={() => {
                              setName(item)
                              setShowDeleteModal(true)
                            }}
                            hidden={isHidden({ module: { key: 'MultiLanguage', value: 'U' } })}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>)
                        : '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {tenantDetails?.tenantConfig?.allowedLanguages?.length < 1 && (
            // If no data found
              <Form.Label className='text-danger d-flex justify-content-center'>
                No data found.
              </Form.Label>
            )}
          </>)}

      {showCreate &&
        <CreateLanguageModal
          show={showCreate}
          setShow={setShowCreate}
          handleCreate={handleCreate}
          languageOptions={languageOptions}
        />}

      {showDeleteModal &&
        <DeleteConfirmationModal
          handleDeleteYes={handleDeleteYes}
          setDeleteModalShow={setShowDeleteModal}
          deleteModalShow={showDeleteModal}
          name={name + ' language'}
        />}
    </>
  )
}

export default Languages
