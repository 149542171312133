import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getAllRGPlayerListingStart,
    getAllRGPlayerListingSuccess,
    getAllRGPlayerListingFailure,
    getRgThresholdDataStart,
    getRgThresholdDataSuccess,
    getRgThresholdDataFailure,
    setRgThresholdDataStart,
    setRgThresholdDataSuccess,
    setRgThresholdDataFailure,
    getExcludedPortalDetailsStart,
    getExcludedPortalDetailsSuccess,
    getExcludedPortalDetailsFailure
  },
  reducer
} = createSlice({
  name: 'RGModule',
  initialState: {
    error: null,
    loading: false,
    RGPlayersDetails: null,
    thresholdData: null,
    rgThresholdValue: null,
    excludedPortalDetails: null
  },
  reducers: {
    getAllRGPlayerListingStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getAllRGPlayerListingSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      RGPlayersDetails: payload?.userDetail,
      rgThresholdValue: payload.threshold
    }),
    getAllRGPlayerListingFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getRgThresholdDataStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getRgThresholdDataSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      thresholdData: payload
    }),
    getRgThresholdDataFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    setRgThresholdDataStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    setRgThresholdDataSuccess: (state, { payload }) => ({
      ...state,
      thresholdData: payload,
      loading: false
    }),
    setRgThresholdDataFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getExcludedPortalDetailsStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getExcludedPortalDetailsSuccess: (state, { payload }) => ({
      ...state,
      excludedPortalDetails: payload,
      loading: false
    }),
    getExcludedPortalDetailsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    })
  }
})

export default reducer

export {
  getAllRGPlayerListingStart,
  getAllRGPlayerListingSuccess,
  getAllRGPlayerListingFailure,
  getRgThresholdDataStart,
  getRgThresholdDataSuccess,
  getRgThresholdDataFailure,
  setRgThresholdDataStart,
  setRgThresholdDataSuccess,
  setRgThresholdDataFailure,
  getExcludedPortalDetailsStart,
  getExcludedPortalDetailsSuccess,
  getExcludedPortalDetailsFailure
}
