import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getAllTenantUsersStart,
    getAllTenantUsersSuccess,
    getAllTenantUsersFailure,
    updateTenantUserStatusStart,
    updateTenantUserStatusSuccess,
    updateTenantUserStatusFailure,
    getUserFieldsStart,
    getUserFieldsSuccess,
    getUserFieldsFailure,
    updateUserFieldsStart,
    updateUserFieldsSuccess,
    updateUserFieldsFailure,
    updateTagsStart,
    updateTagsComplete,
    getDuplicateUsersStart,
    getDuplicateUsersSuccess,
    getDuplicateUsersFailure,
    updateLimitsStart,
    updateLimitsComplete,
    disableUserStart,
    disableUserComplete,
    updateUserAffiliateStart,
    updateUserAffiliateComplete,
    addAffiliateUserStart,
    addAffiliateUserComplete,
    verifyUserEmailStart,
    verifyUserEmailComplete,
    resetPasswordEmailStart,
    resetPasswordEmailComplete,
    resetUserPasswordStart,
    resetUserPasswordComplete,
    updateUserStart,
    updateUserComplete,
    getCashbackDataStart,
    getCashbackDataComplete,
    getCashbackDataFailure,
    getCurrentCashbackDataStart,
    getCurrentCashbackDataComplete,
    getCurrentCashbackDataFailure,
    sendUserEmailStart,
    sendUserEmailComplete,
    getEmailLogsStart,
    getEmailLogsComplete,
    getEmailLogsFailure,
    updateUserCurrPermissionStart,
    updateUserCurrPermissionComplete,
    updateUserCurrPermissionFailure,
    managePaymentStart,
    managePaymentComplete,
    getUserAffiliateOldDataStart,
    getUserAffiliateOldDataComplete,
    getUserAffiliateNewDataStart,
    getUserAffiliateNewDataComplete,
    updateUserAffiliateDataStart,
    updateUserAffiliateDataComplete,
    clearAffiliateData
  },
  reducer
} = createSlice({
  name: 'tenantUsers',
  initialState: {
    error: null,
    loading: false,
    allUsers: null,
    user: null,
    userFields: null,
    tags: [],
    duplicateUsers: [],
    duplicateLoading: false,
    limitsLoading: false,
    cashBackData: {},
    cashBackLoading: false,
    currentCashBackData: {},
    currentCashBackLoading: false,
    emailLoading: false,
    emailLogs: {},
    userCurrUpdatePermission: false,
    managePaymentLoading: false,
    affiliateNewLoading: false,
    affiliateOldLoading: false,
    affiliateOldData: null,
    affiliateNewData: null
  },
  reducers: {
    getAllTenantUsersStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getAllTenantUsersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      allUsers: payload,
      user: null
    }),
    getAllTenantUsersFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    updateTenantUserStatusStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    updateTenantUserStatusSuccess: (state) => ({
      ...state,
      loading: false
    }),
    updateTenantUserStatusFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    getUserFieldsStart: (state) => ({
      ...state,
      loading: true
    }),
    getUserFieldsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userFields: payload
    }),
    getUserFieldsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    updateUserFieldsStart: (state) => ({
      ...state,
      loading: true
    }),
    updateUserFieldsSuccess: (state) => ({
      ...state,
      loading: false
    }),
    updateUserFieldsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    updateTagsStart: (state) => ({
      ...state,
      loading: true
    }),
    updateTagsComplete: (state) => ({
      ...state,
      loading: false
    }),
    getDuplicateUsersStart: (state) => ({
      ...state,
      duplicateLoading: true
    }),
    getDuplicateUsersSuccess: (state, { payload }) => ({
      ...state,
      duplicateLoading: false,
      duplicateUsers: payload
    }),
    getDuplicateUsersFailure: (state) => ({
      ...state,
      duplicateLoading: false
    }),
    updateLimitsStart: (state) => ({
      ...state,
      limitsLoading: true
    }),
    updateLimitsComplete: (state) => ({
      ...state,
      limitsLoading: false
    }),
    disableUserStart: (state) => ({
      ...state,
      limitsLoading: true
    }),
    disableUserComplete: (state) => ({
      ...state,
      limitsLoading: false
    }),
    updateUserAffiliateStart: (state) => ({
      ...state,
      updateUserAffiliateLoading: true
    }),
    updateUserAffiliateComplete: (state) => ({
      ...state,
      updateUserAffiliateLoading: false
    }),
    addAffiliateUserStart: (state) => ({
      ...state,
      addUserAffiliateLoading: true
    }),
    addAffiliateUserComplete: (state) => ({
      ...state,
      addUserAffiliateLoading: false
    }),
    verifyUserEmailStart: (state) => ({
      ...state,
      loading: true
    }),
    verifyUserEmailComplete: (state) => ({
      ...state,
      loading: false
    }),
    resetPasswordEmailStart: (state) => ({
      ...state,
      loading: true
    }),
    resetPasswordEmailComplete: (state) => ({
      ...state,
      loading: false
    }),
    resetUserPasswordStart: (state) => ({
      ...state,
      loading: true
    }),
    resetUserPasswordComplete: (state) => ({
      ...state,
      loading: false
    }),
    updateUserStart: (state) => ({
      ...state,
      loading: true
    }),
    updateUserComplete: (state) => ({
      ...state,
      loading: false
    }),
    getCashbackDataStart: (state) => ({
      ...state,
      cashBackLoading: true
    }),
    getCashbackDataComplete: (state, { payload }) => ({
      ...state,
      cashBackLoading: false,
      cashBackData: payload
    }),
    getCashbackDataFailure: (state) => ({
      ...state,
      cashBackLoading: false
    }),
    getCurrentCashbackDataStart: (state) => ({
      ...state,
      currentCashBackLoading: true
    }),
    getCurrentCashbackDataComplete: (state, { payload }) => ({
      ...state,
      currentCashBackLoading: false,
      currentCashBackData: payload
    }),
    getCurrentCashbackDataFailure: (state) => ({
      ...state,
      currentCashBackLoading: false
    }),
    sendUserEmailStart: (state) => ({
      ...state,
      emailLoading: true
    }),
    sendUserEmailComplete: (state) => ({
      ...state,
      emailLoading: false
    }),
    getEmailLogsStart: (state) => ({
      ...state,
      emailLoading: true
    }),
    getEmailLogsComplete: (state, { payload }) => ({
      ...state,
      emailLoading: false,
      emailLogs: payload
    }),
    getEmailLogsFailure: (state) => ({
      ...state,
      emailLoading: false
    }),
    updateUserCurrPermissionStart: (state) => ({
      ...state,
      userCurrUpdatePermission: false
    }),
    updateUserCurrPermissionComplete: (state, { payload }) => ({
      ...state,
      userCurrUpdatePermission: payload
    }),
    updateUserCurrPermissionFailure: (state) => ({
      ...state,
      userCurrUpdatePermission: false
    }),
    managePaymentStart: (state) => ({
      ...state,
      managePaymentLoading: true
    }),
    managePaymentComplete: (state) => ({
      ...state,
      managePaymentLoading: false
    }),
    getUserAffiliateOldDataStart: (state) => ({
      ...state,
      affiliateOldLoading: true
    }),
    getUserAffiliateOldDataComplete: (state, { payload }) => ({
      ...state,
      affiliateOldLoading: false,
      affiliateOldData: payload
    }),
    getUserAffiliateNewDataStart: (state) => ({
      ...state,
      affiliateNewLoading: true
    }),
    getUserAffiliateNewDataComplete: (state, { payload }) => ({
      ...state,
      affiliateNewLoading: false,
      affiliateNewData: payload
    }),
    updateUserAffiliateDataStart: (state) => ({
      ...state,
      affiliateNewLoading: true
    }),
    updateUserAffiliateDataComplete: (state) => ({
      ...state,
      affiliateNewLoading: false
    }),
    clearAffiliateData: (state) => ({
      ...state,
      affiliateNewData: null,
      affiliateOldData: null
    })

  }
})

export default reducer

export {
  getAllTenantUsersStart,
  getAllTenantUsersSuccess,
  getAllTenantUsersFailure,
  updateTenantUserStatusStart,
  updateTenantUserStatusSuccess,
  updateTenantUserStatusFailure,
  getUserFieldsStart,
  getUserFieldsSuccess,
  getUserFieldsFailure,
  updateUserFieldsStart,
  updateUserFieldsSuccess,
  updateUserFieldsFailure,
  updateTagsStart,
  updateTagsComplete,
  getDuplicateUsersStart,
  getDuplicateUsersSuccess,
  getDuplicateUsersFailure,
  updateLimitsStart,
  updateLimitsComplete,
  disableUserStart,
  disableUserComplete,
  updateUserAffiliateStart,
  updateUserAffiliateComplete,
  addAffiliateUserStart,
  addAffiliateUserComplete,
  verifyUserEmailStart,
  verifyUserEmailComplete,
  resetPasswordEmailStart,
  resetPasswordEmailComplete,
  resetUserPasswordStart,
  resetUserPasswordComplete,
  updateUserStart,
  updateUserComplete,
  getCashbackDataStart,
  getCashbackDataComplete,
  getCashbackDataFailure,
  sendUserEmailStart,
  sendUserEmailComplete,
  getEmailLogsStart,
  getEmailLogsComplete,
  getEmailLogsFailure,
  updateUserCurrPermissionStart,
  updateUserCurrPermissionComplete,
  updateUserCurrPermissionFailure,
  getCurrentCashbackDataStart,
  getCurrentCashbackDataComplete,
  getCurrentCashbackDataFailure,
  managePaymentStart,
  managePaymentComplete,
  getUserAffiliateOldDataStart,
  getUserAffiliateOldDataComplete,
  getUserAffiliateNewDataStart,
  getUserAffiliateNewDataComplete,
  updateUserAffiliateDataStart,
  updateUserAffiliateDataComplete,
  clearAffiliateData
}
