import React from 'react'
import Trigger from '../../../../components/OverlayTrigger'
import { Button } from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'

const LabelData = ({ label, subValue, value, desc, inActive = false, adminPermissions }) => (
  <div key={label} className='d-flex justify-content-between m-1'>
    <h6 style={{ fontSize: '14px' }}>{label}</h6>
    <div>
      <span style={{ fontSize: '14px' }} className={`${subValue} `}>
        {value || ''}
      </span>
      {(inActive
        ? value !== '-' && true
        : !adminPermissions?.Users?.includes('RUI') && label === 'Email') && desc && (
          <Trigger message={desc}>
            <Button
              variant='outline-warning'
              size='xs'
              className='m-1'
              color='warning'
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </Button>
          </Trigger>
      )}
    </div>
  </div>
)
export default LabelData
