import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateAffiliateSystemStart } from '../../../../store/redux-slices/tenantcredentials'
import { checkIsTenant } from '../../../../utils/constants'

const useAffiliateSystem = (details) => {
  const dispatch = useDispatch()
  const [myKeys, setMyKeys] = useState('')
  const [affiliateSystem, setAffiliateSystem] = useState('')
  const { tenantDetails } = useSelector(state => state.login)
  const { affiliateKeys } = useSelector(state => state.tenantcredentials)

  useEffect(() => {
    setMyKeys(checkIsTenant() ? affiliateKeys?.[affiliateSystem || tenantDetails?.tenantConfig?.affiliateIntegrated] : details?.tenantConfig?.affiliateKeys ? Object.keys(details?.tenantConfig?.affiliateKeys) : {})
  }, [affiliateSystem, tenantDetails, details])

  const updateData = (data) => dispatch(updateAffiliateSystemStart({ data }))

  return {
    myKeys,
    updateData,
    affiliateKeys,
    affiliateSystem,
    setAffiliateSystem,
    configData: tenantDetails?.tenantConfig
  }
}

export default useAffiliateSystem
