import React from 'react'
import { Row, Col, Button, Form, Tab, Tabs } from '@themesberg/react-bootstrap'
import { SpinnerLoader } from '../../../../../components/Preloader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo, faCloudArrowDown } from '@fortawesome/pro-solid-svg-icons'
import usePlayerLiability from '../../hooks/usePlayerLiability'
import { downloadFile } from '../../../../../utils/fileDownloader'
import PortalLiabilityTable from '../../../../../components/PlayerLiabilityTable'
import PendingWithdrawalsTable from '../../../../../components/PendingWithdrawlsTable'

export default ({ selectedPortal = '', selectedClient = '', accordionState, isHidden }) => {
  const {
    state,
    loading,
    setState,
    selectedTab,
    setSelectedTab,
    getCsvDownloadUrl,
    playerLiabilityData,
    withdrawalTotalAmount,
    loadPlayerLiabilities,
    currencyWiseWithdrawal
  } = usePlayerLiability({ selectedPortal, selectedClient, accordionState })

  const sortedPlayerLiabilityData = []
  let totalSummedAmount = 0

  if (playerLiabilityData) {
    for (const tenantName in playerLiabilityData.totalSummedAmount) {
      const tenantData = playerLiabilityData.totalSummedAmount[tenantName]
      for (const currencyCode in tenantData) {
        const liability = tenantData[currencyCode]
        if (currencyCode !== 'totalSummedAmount' && !currencyCode.includes('toEUR')) {
          sortedPlayerLiabilityData.push({
            currencyCode,
            name: tenantName,
            liability
          })
        }
      }
      totalSummedAmount += Number(playerLiabilityData.totalSummedAmount[tenantName]?.totalSummedAmount) || 0
    }
  }

  return (
    <>
      <Row className='border-bottom d-flex align-items-center justify-content-between mb-2 pb-2'>
        <Col>
          <button onClick={() => loadPlayerLiabilities()} className='btn btn-sm btn-secondary d-flex align-items-center gap-1'>
            <span className='fs-7'>Refresh</span>
            {loading ? <SpinnerLoader /> : <FontAwesomeIcon style={{ fontSize: '15px' }} icon={faRedo} />}
          </button>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center'>
            <Form.Control
              size='sm'
              min={selectedTab === 'pendingWithdrawals' ? '2023-12-26' : '2023-10-04'}
              type='date'
              value={state}
              onChange={(e) => setState(e.target.value)}
              max={new Date().toISOString().split('T')[0]}
            />

            <Button
              variant='success'
              size='sm'
              style={{ width: '250px', marginLeft: '5px' }}
              disabled={selectedTab === 'playerLiability' ? sortedPlayerLiabilityData?.length < 1 : currencyWiseWithdrawal?.length < 1}
              hidden={isHidden({ module: { key: 'PlayerLiabilityReport', value: 'DR' } })}
              onClick={() => { downloadFile(getCsvDownloadUrl()) }}
            >
              Export&nbsp;<FontAwesomeIcon icon={faCloudArrowDown} />
            </Button>
          </div>
        </Col>
      </Row>

      <Tabs
        activeKey={selectedTab}
        onSelect={(tab) => setSelectedTab(tab)}
        className='nav-light dashboard'
      >
        <Tab eventKey='playerLiability' title='Player Account Balances'>
          <div className='mt-2'>
            <Row className='mt-3 d-flex flex-row-reverse text-right'>
              <PortalLiabilityTable playerLiabilityData={playerLiabilityData?.totalSummedAmount} totalSummedAmount={totalSummedAmount} sortedPlayerLiabilityData={sortedPlayerLiabilityData} />
            </Row>
          </div>
        </Tab>

        <Tab eventKey='pendingWithdrawals' title='Pending Withdrawals'>
          <div className='mt-2'>
            <Row className='mt-3 d-flex flex-row-reverse text-right'>
              <PendingWithdrawalsTable withdrawalTotalAmount={withdrawalTotalAmount} currencyWiseWithdrawal={currencyWiseWithdrawal} />
            </Row>
          </div>
        </Tab>
      </Tabs>
    </>
  )
}
