import { takeLatest, put } from 'redux-saga/effects'
import {
  updateTenantAffiliate,
  superAdminViewToggleStatus
} from '../../utils/apiCalls'

import {
  updateTenantAffiliateStart,
  updateTenantAffiliateSuccess,
  updateTenantAffiliateFailure,
  updateTenantAffiliateStatusStart,
  updateTenantAffiliateStatusSuccess,
  updateTenantAffiliateStatusFailure
} from '../redux-slices/tenantAffiliates'

import { getAllTenantAffiliatesStart } from '../redux-slices/tenantsFetchData'

import { toast } from '../../components/Toast'
import { TenantRoutes } from '../../routes'

export default function * credWatcher () {
  yield takeLatest(
    updateTenantAffiliateStart.type,
    updateTenantAffiliateWatcher
  )
  yield takeLatest(
    updateTenantAffiliateStatusStart.type,
    updateTenantAffiliateStatusWatcher
  )
}

function * updateTenantAffiliateWatcher (action) {
  try {
    const {
      affiliateId,
      affiliateCode,
      firstName,
      lastName,
      email,
      phone,
      username,
      payoutPercentage,
      password,
      isActive,
      navigate
    } = action && action?.payload

    yield updateTenantAffiliate({
      affiliateId,
      affiliateCode,
      firstName,
      lastName,
      email,
      phone,
      username,
      payoutPercentage,
      isActive,
      password
    })

    yield put(updateTenantAffiliateSuccess())
    yield toast('Affiliate Updated Successfully', 'success')
    navigate(TenantRoutes.Affiliates)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(updateTenantAffiliateFailure(e?.response?.data?.errors[0].description))
  }
}

function * updateTenantAffiliateStatusWatcher (action) {
  try {
    const { affiliateId, status, limit, page, search, tenantId, navigate } =
      action && action.payload

    yield superAdminViewToggleStatus({ affiliateId, status, code: 'AFFILIATE' })

    yield put(updateTenantAffiliateStatusSuccess())

    yield put(
      getAllTenantAffiliatesStart({ limit, pageNo: page, search, tenantId })
    )

    yield toast('Tenant Status Updated Successfully', 'success')
    navigate(TenantRoutes.Affiliates)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0].description, 'error')

    yield put(updateTenantAffiliateStatusFailure(e?.response?.data?.errors[0].description))
  }
}
