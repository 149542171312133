import React, { useState } from 'react'
import { Tabs, Tab, Row, Col, Form } from '@themesberg/react-bootstrap'
import useProfilePage from './useProfilePage'
import { permissionLabel, profileConstants, profileConstantsTA } from './constants'
import Preloader from '../Preloader'
import Hierarchy from '../Hierarchy'
import Overview from './Overview'
import Permission from './Permission'
import Credentials from './Credentials'
import Password from './Password'
import { useDispatch, useSelector } from 'react-redux'
import { disableAuthenticatorStart, getAuthQrCodeStart, qrModalCloseHandler, qrModalOpenHandler, verifyAuthCodeStart } from '../../store/redux-slices/auth'
import QRCode from 'qrcode'
import { InputGroup, Modal } from 'react-bootstrap'

export default ({ isTenant }) => {
  const {
    details,
    selectedTab,
    setSelectedTab,
    editable,
    setEditable,
    updateData,
    loading,
    adminDetails,
    updateCredentials,
    editableCreds,
    setEditableCreds,
    resetPassLoading,
    fetchData
  } = useProfilePage(isTenant)

  const constant = isTenant ? profileConstantsTA : profileConstants
  const { data, qrModal } = useSelector((state) => state.auth)
  const [qrcodeImageLink, setQrcodeImageLink] = useState('')
  const [authCode, setAuthCode] = useState(null) // default null
  const dispatch = useDispatch()

  const generateQrCode = async () => {
    dispatch(getAuthQrCodeStart({
      userType: isTenant ? 'tenant' : 'superadmin',
      generateAuthQrImage,
      isTenant
    }))
  }

  const generateAuthQrImage = async ({ authUrl }) => {
    try {
      setQrcodeImageLink(await QRCode.toDataURL(authUrl))
      dispatch(qrModalOpenHandler())
    } catch (err) {
      console.error(err)
    }
  }

  const submitAuthCode = () => {
    dispatch(verifyAuthCodeStart({ token: authCode.toString(), fetchData, isTenant }))
  }

  const disableAuthenticator = () => {
    dispatch(disableAuthenticatorStart({ isTenant, fetchData }))
  }

  return (
    <>
      {loading
        ? <Preloader />
        : (
          <>
            <Row>
              <Col className='d-flex'>
                <h3>Profile</h3>
              </Col>
            </Row>

            <Tabs
              activeKey={selectedTab}
              onSelect={(tab) => {
                setEditable(false)
                setEditableCreds(false)
                setSelectedTab(tab)
              }}
              mountOnEnter
              unmountOnExit
              className='nav-light'
            >
              <Tab eventKey='overview' title='Overview'>
                <div className='mt-3'>
                  <Overview
                    details={details}
                    isTenant={isTenant}
                    setEditable={setEditable}
                    editable={editable}
                    updateData={updateData}
                    constant={constant}
                    loading={loading}
                  />
                </div>
              </Tab>

              <Tab eventKey='password' title='Reset Password'>
                <div className='mt-3'>
                  <Password
                    isTenant={isTenant}
                    loading={resetPassLoading}
                  />
                </div>
              </Tab>

              {adminDetails?.superRoleId === 1 &&
                <Tab eventKey='credentials' title='Credentials'>
                  <div className='mt-3'>
                    <Credentials
                      details={details}
                      updateCreds={updateCredentials}
                      loading={loading}
                      editableCreds={editableCreds}
                      setEditableCreds={setEditableCreds}
                    />
                  </div>
                </Tab>}

              <Tab eventKey='permissions' title='Permissions'>
                <div className='mt-3'>
                  <Permission details={details} permissionLabel={permissionLabel} />
                </div>
              </Tab>

              {!isTenant &&
                <Tab eventKey='usersTree' title='Tree'>
                  <div className='mt-5'>
                    <Row className='mt-3 d-flex flex-row-reverse text-right'>
                      {details && details?.superAdminUserId && (
                        <Hierarchy
                          isTenant={isTenant}
                          adminDetails={{
                            name: `${details?.firstName} ${details?.lastName}`,
                            id: details?.superAdminUserId,
                            children: [],
                            isInitial: true,
                            data: { superRoleId: details?.superRoleId }
                          }}
                        />
                      )}
                    </Row>
                  </div>
                </Tab>}
            </Tabs>
            <div className='mt-3'>
              {details &&
                <button
                  className='m-1 btn btn-warning' onClick={() => {
                    if (details?.authEnable) {
                      disableAuthenticator()
                    } else {
                      generateQrCode()
                      setAuthCode(null)
                    }
                  }}
                >
                  {details?.authEnable ? 'Disable 2 FA' : 'Set-up 2 FA'}
                </button>}
              <Modal
                show={qrModal}
                onHide={() => {
                  dispatch(qrModalCloseHandler())
                }}
                dialogClassName='modal-90w'
                size='xl'
                aria-labelledby='example-custom-modal-styling-title'
              >
                <Modal.Header className='justify-content-center'>
                  <h2>Two-Factor Authentication (2FA)</h2>
                </Modal.Header>
                <Modal.Body className='m-2' style={{ marginLeft: '2rem' }}>
                  <h3>Confguring Google Authenticator or Authy</h3>
                  <h5>
                    1. Install Google Authenticator (IOS - Android) or Authy (IOS - Android). <br />
                    2. In the authenticator app, select "+" icon. <br />
                    3. Select "Scan a barcode (or QR code)" and use the phone's camera to scan this barcode.<br />
                  </h5>
                  <h3><b>Scan QR</b></h3>
                  <div className='justify-content-center'>
                    <img
                      className='rounded mx-auto d-block'
                      src={qrcodeImageLink}
                      alt='qr-code'
                      style={{ height: '20rem', width: '20rem' }}
                    />
                  </div>
                  <h3>
                    <b>Verify Code</b> <br />
                    For changing the setting, please verify the authentication code:
                  </h3>
                  <h3>
                    Or Enter Code Into Your App <br />
                    SecretKey: <b>{data?.authSecret}</b> (Base32 encoded)
                  </h3>
                  <InputGroup>
                    <Form.Control
                      name='AuthenticationCode'
                      type='text'
                      step='any'
                      style={{ maxWidth: '38rem' }}
                      placeholder='Enter Authentication Code'
                      value={authCode}
                      onChange={e => setAuthCode(e.target.value)}
                      required
                    />
                  </InputGroup>
                  <button
                    className='m-2 mt-3 btn'
                    onClick={() => {
                      dispatch(qrModalCloseHandler())
                    }}
                  >
                    Cancel
                  </button>
                  <button className='m-2 mt-3 btn btn-warning' disabled={!authCode || loading} onClick={() => submitAuthCode()}>
                    Verfiy & Activate
                  </button>
                </Modal.Body>
              </Modal>
            </div>
          </>)}
    </>
  )
}
