import {
  Button,
  ButtonGroup,
  Table,
  Form as BForm
} from '@themesberg/react-bootstrap'
import React, { useRef } from 'react'
import { Form } from 'react-bootstrap'
import { InlineLoader } from '../../../../components/Preloader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faEye,
  faRemove,
  faTrash
} from '@fortawesome/pro-solid-svg-icons'
import { handleImagePreview } from '../../../../utils/helpers'
import Trigger from '../../../../components/OverlayTrigger'

const CountryForm = ({
  data,
  setData,
  search,
  setSearch,
  myCountries,
  loading = false,
  details = false,
  cashier = false,
  kycCountries,
  setKycCountries,
  recommendedDeposit,
  recommendedWithdraw,
  setRecommendedDeposit,
  setRecommendedWithdraw,
  setFieldValue,
  supportsDeposit = false,
  supportsWithdrawal = false,
  values = {},
  providerDetails,
  depositCount,
  setDepositCount,
  nameCountryWise,
  setNameCountryWise
}) => {
  const fileInputRefs = useRef([])

  const CustomSelectAllInput = ({ countryData, setCountryData }) => {
    return (
      <input
        type='checkbox'
        name='selectAll'
        hidden={details}
        checked={myCountries.every((v) => countryData?.includes(v.code))}
        onChange={(e) => {
          const newData = []
          if (e.target.checked) {
            for (const index in myCountries) {
              newData.push(myCountries?.[index]?.code)
            }
            setCountryData(newData)
          } else {
            setCountryData([])
          }
        }}
      />
    )
  }

  const CustomCheckInput = ({ code, countryData, setCountryData }) =>
    details
      ? (
          countryData?.includes(code)
            ? (
                'Yes'
              )
            : (
                '-'
              )
        )
      : (
        <input
          type='checkbox'
          name={code}
          value={code}
          disabled={details}
          checked={countryData?.includes(code)}
          onChange={(e) => {
            if (e.target.checked) {
              countryData?.length
                ? setCountryData([...countryData, code])
                : setCountryData([code])
            } else {
              setCountryData(countryData?.filter((c) => c !== code))
            }
          }}
        />
        )

  const CustomInput = ({ code, countryData, setCountryData }) =>
    <BForm.Control
      type='number'
      name={code}
      step={1}
      defaultValue={countryData?.[code]}
      disabled={details}
      onKeyUp={e => {
        if (e.keyCode === 13) {
          setCountryData({
            ...countryData,
            [code]: e.target.value < 0 ? 0 : e.target.value
          })
        }
      }}
      onBlur={(e) =>
        setCountryData({
          ...countryData,
          [code]: e.target.value < 0 ? 0 : e.target.value
        })}
    />

  /**
   * @typedef {Object}  CustomNameInputProps
   * @property {String} code
   * @property {String} countryData
   * @property {Function} setCountryData
   */

  /**
   * Custom Display Name Input for Country
   *
   * @param {CustomNameInputProps} props
   * @returns
   */
  const CustomNameInput = ({ code, countryData = {}, setCountryData }) => {
    return (
      <BForm.Control
        type='text'
        name={code}
        defaultValue={(countryData && countryData[code]) || ''}
        disabled={details}
        onKeyUp={e => {
          if (e.keyCode === 13) {
            setCountryData({
              ...countryData,
              [code]: e?.target?.value
            })
          }
        }}
        onBlur={(e) => {
          setCountryData({
            ...countryData,
            [code]: e?.target?.value
          })
        }}
      />
    )
  }

  const CustomBtnGroup = ({ withdraw = false, code, footer = false }) => {
    const name = `${code}_${withdraw ? 'withdraw' : footer ? 'footer' : 'deposit'}`
    const detailsData =
      providerDetails?.regions?.countryIcons?.[code]?.[
        withdraw ? 'withdraw' : footer ? 'footer' : 'deposit'
      ]
    const formKey = withdraw ? 'removeWithdrawImages' : footer ? 'removeFooterImages' : 'removeDepositImages'

    return (
      <>
        <BForm.Control
          type='file'
          ref={(el) => (fileInputRefs.current[name] = el)}
          hidden
          name={`images[${name}]`}
          onChange={(e) => {
            setFieldValue(`images[${name}]`, e.target.files[0])
            setFieldValue(
              [formKey],
              values?.[formKey]?.filter((key) => key !== code)
            )
          }}
        />
        {details &&
        providerDetails &&
        !providerDetails?.regions?.countryIcons?.[code]?.[
          withdraw ? 'withdraw' : footer ? 'footer' : 'deposit'
        ]
          ? (
              '-'
            )
          : (
            <ButtonGroup>
              {/* Edit */}
              <Trigger message='Edit image'>
                <Button
                  variant='warning'
                  hidden={details}
                  onClick={() => fileInputRefs.current[name].click()}
                  size='sm'
                  className='mx-1'
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              </Trigger>

              {/* View */}
              {(values?.images?.[name] || detailsData) && (
                <Trigger message='View image'>
                  <Button
                    variant='primary'
                    size='sm'
                    className='mx-1'
                    onClick={() => {
                      values?.images?.[name]
                        ? handleImagePreview(values?.images?.[name], false, true)
                        : detailsData && window.open(detailsData, '_blank')
                    }}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </Button>
                </Trigger>
              )}

              {/* Clear */}
              {values?.images?.[name] && (
                <Trigger message='Clear current uploaded image'>
                  <Button
                    variant='danger'
                    size='sm'
                    className='mx-1'
                    onClick={(e) => setFieldValue(`images[${name}]`, '')}
                  >
                    <FontAwesomeIcon icon={faRemove} />
                  </Button>
                </Trigger>
              )}

              {/* Delete */}
              {detailsData && !values?.[formKey]?.includes(code) && (
                <Trigger message='Delete the uploaded image'>
                  <Button
                    variant='danger'
                    size='sm'
                    className='mx-1'
                    hidden={details}
                    onClick={() => {
                      values?.images?.[name] &&
                      setFieldValue(`images[${name}]`, '')
                      setFieldValue([formKey], [code, ...values?.[formKey]])
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </Trigger>
              )}
            </ButtonGroup>
            )}
      </>
    )
  }

  const renderCountry = (code, name) => {
    return (
      (details ? (cashier ? (data?.includes(code) || kycCountries?.includes(code) || recommendedDeposit.includes(code) || recommendedWithdraw.includes(code) || (providerDetails?.regions?.countryIcons && Object.keys(providerDetails?.regions?.countryIcons)?.includes(code))) || (depositCount && Object.keys(depositCount)?.includes(code)) || (nameCountryWise && Object.keys(nameCountryWise)?.includes(code)) : (data?.includes(code))) : true) && (
        <tr key={code}>
          <td>
            {name} ({code})
          </td>

          {cashier && (
            <td>
              <CustomNameInput
                code={code}
                countryData={nameCountryWise}
                setCountryData={setNameCountryWise}
              />
            </td>
          )}

          <td>
            <CustomCheckInput
              code={code}
              countryData={data}
              setCountryData={setData}
            />
          </td>

          {cashier && (
            <td>
              <CustomCheckInput
                code={code}
                countryData={kycCountries}
                setCountryData={setKycCountries}
              />
            </td>
          )}

          {cashier && supportsDeposit && (
            <td>
              <CustomCheckInput
                code={code}
                countryData={recommendedDeposit}
                setCountryData={setRecommendedDeposit}
              />
            </td>
          )}

          {cashier && supportsWithdrawal && (
            <td>
              <CustomCheckInput
                code={code}
                countryData={recommendedWithdraw}
                setCountryData={setRecommendedWithdraw}
              />
            </td>
          )}

          {cashier && (
            <td>
              <CustomInput
                code={code}
                countryData={depositCount}
                setCountryData={setDepositCount}
              />
            </td>
          )}

          {cashier && supportsDeposit && (
            <td>
              <CustomBtnGroup code={code} />
            </td>
          )}

          {cashier && supportsWithdrawal && (
            <td>
              <CustomBtnGroup code={code} withdraw />
            </td>
          )}

          {cashier && (
            <td>
              <CustomBtnGroup code={code} footer />
            </td>
          )}
        </tr>
      )
    )
  }

  return (
    <>
      {!details && (
        <div className='d-flex justify-content-end align-items-center mt-5 mb-3'>
          <Form.Control
            type='text'
            placeholder='Search Country Name'
            size='sm'
            style={{ maxWidth: '230px' }}
            value={search}
            onChange={(event) => {
              setSearch(event.target.value.replace(/[^\w\s\n]/gi, ''))
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') e.preventDefault()
            }}
          />
        </div>
      )}
      <div style={{ overflowY: 'auto', maxHeight: '700px' }}>
        {loading
          ? (
            <InlineLoader />
            )
          : (
            <Table
              bordered
              striped
              hover
              size='sm'
              className='text-center mt-3 fixTableHead'
            >
              <thead className='thead-dark'>
                <tr style={{ zIndex: '1000', position: 'relative' }}>
                  <th>Country</th>
                  {cashier && (
                    <th>Display Name</th>
                  )}
                  <th>
                    {!details &&
                      <CustomSelectAllInput
                        countryData={data}
                        setCountryData={setData}
                      />}
                  &nbsp; Block
                  </th>

                  {cashier && (
                    <th>
                      {!details &&
                        <CustomSelectAllInput
                          countryData={kycCountries}
                          setCountryData={setKycCountries}
                        />}
                    &nbsp; KYC Required
                    </th>
                  )}
                  {cashier && supportsDeposit && (
                    <th>
                      {!details &&
                        <CustomSelectAllInput
                          countryData={recommendedDeposit}
                          setCountryData={setRecommendedDeposit}
                        />}
                    &nbsp; Recommended Deposit
                    </th>
                  )}
                  {cashier && supportsWithdrawal && (
                    <th>
                      {!details &&
                        <CustomSelectAllInput
                          countryData={recommendedWithdraw}
                          setCountryData={setRecommendedWithdraw}
                        />}
                    &nbsp; Recommended Withdraw
                    </th>
                  )}
                  {cashier && <th>Deposit Count</th>}
                  {cashier && supportsDeposit && <th>Deposit Image</th>}
                  {cashier && supportsWithdrawal && <th>Withdraw Image</th>}
                  {cashier && <th>Footer Image</th>}
                </tr>
              </thead>
              <tbody>
                {!loading &&
                myCountries?.map(({ name, code }) => {
                  return renderCountry(code, name)
                })}
                {!loading &&
                (myCountries?.length === 0 ||
                  (details &&
                    (!cashier
                      ? !data?.length
                      : ((!(providerDetails?.regions?.countryIcons && Object.keys(providerDetails?.regions?.countryIcons)?.length)) &&
                        !data?.length &&
                        !kycCountries?.length &&
                        !recommendedDeposit?.length &&
                        !recommendedWithdraw?.length
                        )))) && (
                          <tr>
                            <td colSpan={9} className='text-danger text-center'>
                              No data found
                            </td>
                          </tr>
                )}
              </tbody>
            </Table>
            )}
      </div>
    </>
  )
}

export default CountryForm
