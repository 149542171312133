import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getBonusStart } from '../../../../../store/redux-slices/bonus'
import {
  getAllClientsStart,
  getAllPortalsStart
} from '../../../../../store/redux-slices/tenants'

const useEditBonus = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { bonusId } = useParams()
  const [selectedTab, setSelectedTab] = useState('general')
  const [countryData, setCountryData] = useState([])
  const [selectedClient, setSelectedClient] = useState(null)
  const { clientsFilterData, portalsFilterData } = useSelector(
    (state) => state.tenants
  )
  const { loading, bonusDetail } = useSelector((state) => state.bonus)
  const [portalOptions, setPortalOptions] = useState([])
  const [state, setState] = useState([
    {
      startDate: bonusDetail ? bonusDetail?.validFrom : new Date(),
      endDate: bonusDetail ? bonusDetail?.validTo : new Date(),
      key: 'selection'
    }
  ])
  useEffect(() => {
    dispatch(
      getAllClientsStart({
        search: '',
        limit: 100,
        pageNo: 1,
        orderBy: 'email',
        sort: 'desc'
      })
    )
  }, [])

  useEffect(() => {
    if (portalsFilterData && selectedClient) {
      const options = portalsFilterData.rows.map((portal) => {
        return { label: portal.name, value: portal.tenantId }
      })
      setPortalOptions(options)
    }
  }, [portalsFilterData, selectedClient])

  useEffect(() => {
    if (selectedClient) {
      dispatch(getAllPortalsStart({ adminId: selectedClient }))
    }
  }, [selectedClient])

  useEffect(() => {
    if (bonusId) {
      dispatch(getBonusStart({ bonusId, isTenant: false, userBonusId: '' }))
    }
  }, [bonusId])

  useEffect(() => {
    if (bonusDetail) {
      setCountryData(bonusDetail?.other?.countries)
      setSelectedClient(bonusDetail?.adminId)
      setState(([
        {
          startDate: bonusDetail ? new Date(bonusDetail?.validFrom) : new Date(),
          endDate: bonusDetail ? new Date(bonusDetail?.validTo) : new Date(),
          key: 'selection'
        }
      ]))
    }
  }, [bonusDetail])

  return {
    selectedTab,
    setSelectedTab,
    clientsFilterData,
    setSelectedClient,
    portalOptions,
    state,
    setState,
    dispatch,
    bonusDetail,
    loading,
    bonusId,
    navigate,
    countryData,
    setCountryData
  }
}

export default useEditBonus
