import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getAllTenantTransactionsStart,
    getAllTenantTransactionsSuccess,
    getAllTenantTransactionsFailure,
    getTenantTransactionUsersStart,
    getTenantTransactionUsersSuccess,
    getTenantTransactionUsersFailure,
    getAllTenantCasinoTransactionsStart,
    getAllTenantCasinoTransactionsSuccess,
    getAllTenantCasinoTransactionsFailure
  },
  reducer
} = createSlice({
  name: 'transactions',
  initialState: {
    loading: false,
    tenantTransactions: {},
    tenantCasinoTransactions: {},
    tenantTransactionUsers: []
  },
  reducers: {
    getAllTenantTransactionsStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getAllTenantTransactionsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      tenantTransactions: payload
    }),
    getAllTenantTransactionsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getTenantTransactionUsersStart: (state) => ({
      ...state,
      loading: true,
      error: null
    }),
    getTenantTransactionUsersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      tenantTransactionUsers: payload
    }),
    getTenantTransactionUsersFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAllTenantCasinoTransactionsStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllTenantCasinoTransactionsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      tenantCasinoTransactions: payload
    }),
    getAllTenantCasinoTransactionsFailure: (state) => ({
      ...state,
      loading: false
    })
  }
})

export default reducer

export {
  getAllTenantTransactionsStart,
  getAllTenantTransactionsSuccess,
  getTenantTransactionUsersStart,
  getTenantTransactionUsersSuccess,
  getAllTenantTransactionsFailure,
  getTenantTransactionUsersFailure,
  getAllTenantCasinoTransactionsStart,
  getAllTenantCasinoTransactionsSuccess,
  getAllTenantCasinoTransactionsFailure
}
