import { Card, Col, Row, Form as BForm, Spinner, Button } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { sendEmailSchema } from './schema'
import { sendUserEmailStart } from '../../../../store/redux-slices/tenantUsers'
import { useDispatch } from 'react-redux'
import ReactQuill from 'react-quill'

const SendEmail = ({
  userData,
  isTenant = false,
  loading
}) => {
  const dispatch = useDispatch()

  return (
    <Card className='p-3'>
      <Row>
        <Col className='d-flex'>
          <h3>Send Email</h3>
        </Col>
      </Row>

      <Formik
        initialValues={{
          subject: '',
          content: ''
        }}
        validationSchema={sendEmailSchema}
        onSubmit={(formValues, { resetForm }) => {
          dispatch(sendUserEmailStart({ data: { ...formValues, userId: userData?.userId }, isTenant, resetForm }))
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue
        }) => (
          <Form>

            <Row className='mt-3'>
              <Col>
                <BForm.Label>Subject
                  <span className='text-danger'> *</span>
                </BForm.Label>

                <BForm.Control
                  type='text'
                  name='subject'
                  placeholder='Enter Subject'
                  value={values.subject}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <ErrorMessage
                  component='div'
                  name='subject'
                  className='text-danger'
                />
              </Col>
              <Col />
            </Row>

            <Row className='mt-3 mb-4'>
              <Col className='mb-4'>
                <BForm.Label>Content
                  <span className='text-danger'> *</span>
                </BForm.Label>

                <ReactQuill
                  name='content'
                  value={values?.content}
                  style={{ height: '200px' }}
                  onChange={(e) => setFieldValue('content', e)}
                  preserveWhitespace
                />

                <ErrorMessage
                  component='div'
                  name='content'
                  className='text-danger'
                />
              </Col>
            </Row>

            <div className='mt-4 d-flex justify-content-end align-items-center'>
              <Button
                variant='outline-success'
                onClick={handleSubmit}
                className='ml-2'
              >
                Submit
                {loading && (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  )
}

export default React.memo(SendEmail)
