import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getTournamentDetailsStart, updateTournamentStart } from '../../../store/redux-slices/tournaments'
import { getAllClientsStart, getAllPortalsStart } from '../../../store/redux-slices/tenants'
import { getAllCurrenciesStart } from '../../../store/redux-slices/currencies'
import { getCountriesStart, getTenantCountriesStart } from '../../../store/redux-slices/fetchData'
import Papa from 'papaparse'
import { getDateTimeDaysAfter } from '../../../utils/dateFormatter'
import { safeStringify } from '../../../utils/helpers'

const useCreateTournament = ({ type }) => {
  const dispatch = useDispatch()
  const isTenant = window.location.href.includes('tenant')
  const { tournamentId } = useParams()
  const { tournamentDetails } = useSelector(state => state.tournaments)
  const { languages } = useSelector(state => state.bonus)
  const { tenantDetails } = useSelector(state => state.login)
  const [selectedClient, setSelectedClient] = useState('')
  const [JSONData, setJSONData] = useState('')
  const [portalOptions, setPortalOptions] = useState([])
  const { clientsFilterData, portalsFilterData, tenantsList } = useSelector(state => state.tenants)
  const { allCurrencies } = useSelector((state) => state.currencies)
  const { tenantCountries, countries } = useSelector((state) => state.fetch)

  const navigate = useNavigate()
  const csvRef = useRef(null)
  const jsonRef = useRef(null)
  const [initialValues, setInitialValues] = useState({
    adminId: '',
    tenantIds: [],
    title: '',
    description: '',
    startAt: getDateTimeDaysAfter(0),
    endAt: getDateTimeDaysAfter(10),
    isActive: false,
    bannerUrl: '',
    leaderBoardId: '',
    currency: 'EUR',
    currencies: safeStringify(['EUR']),
    minBetAmount: '',
    payouts: 'AUTOMATIC',
    tournamentMechanics: '',
    countries: JSON.stringify([]),
    games: [],
    prizes: {},
    rules: '',
    amount: 0,
    freespins: 0,
    prizePlaces: 0
  })

  useEffect(() => {
    if (type !== 'create' && tournamentDetails?.tournamentId && portalOptions) {
      setSelectedClient(tournamentDetails?.adminId)
      setData({
        desc: JSON.parse(tournamentDetails?.description),
        rules: JSON.parse(tournamentDetails?.rules)
      })
      setInitialValues({
        ...initialValues,
        ...tournamentDetails,
        tenantIds: portalOptions?.filter(
          (tenant) => tenant.value === tournamentDetails?.tenantId
        ),
        description: JSON.parse(tournamentDetails?.description)?.EN,
        startAt: tournamentDetails?.startAt ? getDateTimeDaysAfter(0, tournamentDetails?.startAt) : getDateTimeDaysAfter(1),
        endAt: tournamentDetails?.endAt ? getDateTimeDaysAfter(0, tournamentDetails?.endAt) : getDateTimeDaysAfter(2),
        currencies: safeStringify(tournamentDetails?.acceptedCurrencies),
        countries: safeStringify(tournamentDetails?.countries),
        games: safeStringify(tournamentDetails?.games),
        rules: JSON.parse(tournamentDetails?.rules)?.EN,
        amount: JSON.parse(tournamentDetails?.prizePool)?.amount,
        freespins: JSON.parse(tournamentDetails?.prizePool)?.freespins,
        bannerUrl: ''
      })
    }
  }, [tournamentDetails, type, portalOptions])

  const [preview, setPreview] = useState({
    image_preview: type !== 'clone' ? tournamentDetails?.bannerUrl : null,
    image_file: type !== 'clone' ? tournamentDetails?.bannerUrl : null
  })
  const [selectedTab, setSelectedTab] = useState('general')
  const [data, setData] = useState({
    desc: tournamentDetails?.description || { EN: '' },
    rules: tournamentDetails?.rules || { EN: '' }
  })

  const handleCSV = (e) => {
    if (e.target.files) {
      Papa.parse(e.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const result = results?.data?.[0]
          setInitialValues({
            ...initialValues,
            title: result?.Tournament || initialValues?.title,
            // description: '',
            // startAt: result?.['Start date and time'] ? getDateTimeDaysAfter(0, result?.['Start date and time']) : getDateTimeDaysAfter(1),
            // endAt: result?.['Finish date and time'] ? getDateTimeDaysAfter(0, result?.['Finish date and time']) : getDateTimeDaysAfter(2),
            isActive: false,
            leaderBoardId: result?.LeaderBoardId || initialValues?.leaderBoardId,
            currency: 'EUR',
            currencies: safeStringify(['EUR']),
            minBetAmount: result?.minBetAmount || initialValues?.minBetAmount,
            payouts: result?.Payout || initialValues?.payouts,
            tournamentMechanics: result?.Mechanics || initialValues?.tournamentMechanics,
            countries: result?.Countries?.split(/['‘",’]/).filter(ele => ele.length > 1)?.length
              ? JSON.stringify(result?.Countries.split(/['‘",’]/).filter(ele => ele.length > 1))
              : initialValues?.countries,
            games: result?.Games?.split(/['‘",’]/).filter(ele => ele.length > 1)?.length
              ? JSON.stringify(result?.Games.split(/['‘",’]/).filter(ele => ele.length > 1))
              : initialValues?.games,
            prizes: [],
            // rules: initialValues.rules,
            amount: result?.prizePool?.amount || initialValues?.amount,
            freespins: result?.prizePool?.freespins || initialValues?.freespins,
            prizePlaces: result?.PrizePlaces || initialValues?.prizePlaces
          })
        }
      })
      csvRef.current.value = ''
    }
  }

  const handleJSON = (JSONFile) => {
    const reader = new FileReader()

    // Setup the callback event to run when the file is read
    reader.onload = setFile

    // Read the file
    reader.readAsText(JSONFile.target.files[0])
  }

  function setFile (event) {
    const str = event.target.result
    const json = JSON.parse(str)
    setJSONData(json)
    jsonRef.current.value = ''
  }

  useEffect(() => {
    if (JSONData) {
      setInitialValues({
        ...initialValues,
        adminId: JSONData?.adminId || initialValues?.adminId,
        title: JSONData?.Tournament || initialValues?.title,
        startAt: JSONData?.['Start date and time'] ? getDateTimeDaysAfter(0, JSONData?.['Start date and time']) : getDateTimeDaysAfter(0),
        endAt: JSONData?.['Finish date and time'] ? getDateTimeDaysAfter(0, JSONData?.['Finish date and time']) : getDateTimeDaysAfter(10),
        isActive: false,
        leaderBoardId: JSONData?.LeaderBoardId || initialValues?.leaderBoardId,
        currency: 'EUR',
        currencies: safeStringify(['EUR']),
        minBetAmount: JSONData?.minBetAmount || initialValues?.minBetAmount,
        payouts: JSONData?.Payout || initialValues?.payouts,
        tournamentMechanics: JSONData?.Mechanics || initialValues?.tournamentMechanics,
        countries: JSONData?.Countries?.length
          ? JSON.stringify(JSONData?.Countries)
          : initialValues?.countries,
        games: JSONData?.Games?.length
          ? JSON.stringify(JSONData?.Games)
          : initialValues?.games,
        prizes: [],
        freespins: JSONData?.prizePool?.freespins || initialValues?.freespins,
        amount: JSONData?.PrizePool?.amount || initialValues?.amount,
        prizePlaces: JSONData?.PrizePlaces || initialValues?.prizePlaces
      })
    }
  }, [JSONData])

  const handleTournaments = (formData) => {
    if (selectedTab === 'languages') {
      const prizePool = { amount: formData?.amount, freespins: formData?.freespins }
      dispatch(updateTournamentStart({
        data: {
          ...formData,
          tenantIds: safeStringify(formData?.tenantIds.map((id) => id?.value)),
          description: data?.desc,
          rules: data?.rules,
          prizePool,
          acceptedCurrencies: formData?.currencies,
          tournamentId
        },
        isTenant,
        create: ['create', 'clone'].includes(type),
        navigate
      }))
    } else {
      setSelectedTab('languages')
    }
  }

  const handleImagePreview = (e) => {
    if (e.target.files[0]) {
      const imageAsBase64 = URL.createObjectURL(e.target.files[0])
      const imageAsFiles = e.target.files[0]
      setPreview({
        image_preview: imageAsBase64,
        image_file: imageAsFiles
      })
    }
  }

  useEffect(() => {
    !isTenant && dispatch(getAllClientsStart({
      search: '',
      limit: 100,
      pageNo: 1,
      orderBy: 'email',
      sort: 'desc'
    }))

    tournamentId && dispatch(getTournamentDetailsStart({ tournamentId, isTenant }))

    !isTenant && dispatch(getAllCurrenciesStart({ limit: '', pageNo: '' }))

    isTenant
      ? dispatch(getTenantCountriesStart())
      : dispatch(getCountriesStart({ limit: '', name: '', pageNo: '' }))
  }, [])

  useEffect(() => {
    if (selectedClient && !isTenant) {
      dispatch(getAllPortalsStart({ adminId: selectedClient }))
    }
  }, [selectedClient])

  useEffect(() => {
    let options = []
    if (isTenant) {
      if (tenantsList) {
        const options = tenantsList.rows.map((portal) => { return ({ label: portal.name, value: portal.tenantId }) })
        setPortalOptions(options)
      }
    } else if (portalsFilterData && selectedClient) {
      options = portalsFilterData.rows.map((portal) => { return ({ label: portal.name, value: portal.tenantId }) })
      setPortalOptions(options)
    }
  }, [portalsFilterData, selectedClient, tenantsList])

  return {
    handleCSV,
    isTenant,
    tournamentDetails,
    handleTournaments,
    selectedTab,
    setSelectedTab,
    languages: isTenant ? tenantDetails?.tenantConfig?.allowedLanguages : languages,
    dispatch,
    data,
    setData,
    clientsFilterData,
    portalOptions,
    setSelectedClient,
    preview,
    handleImagePreview,
    allCurrencies,
    countries: isTenant ? tenantCountries?.rows : countries,
    initialValues,
    navigate,
    handleJSON,
    jsonRef,
    csvRef,
    tenantDetails
  }
}

export default useCreateTournament
