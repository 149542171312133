import React from 'react'

import { Table, Button } from '@themesberg/react-bootstrap'
import { useNavigate } from 'react-router-dom'
import useCheckPermission from '../../../../utils/checkPermission'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/pro-solid-svg-icons'
import { credKeyNames } from '../../../../utils/constants'

const Credentials = ({ tenantData, adminId }) => {
  const navigate = useNavigate()
  const { isHidden } = useCheckPermission()

  return (
    <>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {['Id', 'Key', 'Value', 'Description', 'Action'].map((c) => (
              <th key={c}>{c}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {tenantData &&
            tenantData?.credentials?.map((field, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{credKeyNames?.[field.key] || field.key}</td>
                <td>{field.value || 'NA'}</td>
                <td>{field.description || 'NA'}</td>

                <td>
                  {!isHidden({ module: { key: 'TenantCredentials', value: 'U' } })
                    ? (
                      <Button
                        variant='warning'
                        size='sm'
                        onClick={() =>
                          navigate(
                        `/super-admin/tenant/${tenantData.tenantId}/credential`,
                        {
                          state: {
                            key: field.key,
                            value: field.value,
                            description: field.description
                          }
                        }
                          )}
                        hidden={isHidden({ module: { key: 'TenantCredentials', value: 'U' } })}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>)
                    : '-'}
                </td>

              </tr>
            ))}
          {tenantData?.credentials.length < 1 && (
            <tr>
              <td colSpan={5} className='text-danger text-center'>
                No Credentials found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  )
}
export default Credentials
