import React from 'react'
import Select from 'react-select'
import { ErrorMessage, Form, Formik } from 'formik'
import { toast } from '../../../../components/Toast'
import useAddAggregator from '../hooks/useAddAggregator'
import { Button, Col, Modal, Row, Form as BForm, Table } from '@themesberg/react-bootstrap'
import { addAggregatorsStart } from '../../../../store/redux-slices/superAdminCasinoManagement'

const AddAggregator = ({
  show,
  data,
  setData,
  handleClose,
  aggregatorOptions
}) => {
  const {
    dispatch,
    aggregators,
    tenantDetails,
    myAggregators,
    filteredProviders,
    getAggregatorName,
    allSystemProviders
  } = useAddAggregator(data, setData)

  return (
    <Modal
      show={show}
      size='lg'
      keyboard={false}
      backdrop='static'
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Aggregator</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            aggregatorIds: data?.aggregatorIds
              ? aggregators
                ? aggregators?.rows?.reduce((acc, { masterGameAggregatorId, name }) => {
                  if (data?.aggregatorIds?.includes(masterGameAggregatorId)) {
                    acc.push({ label: name, value: masterGameAggregatorId })
                  }
                  return acc
                }, [])
                : []
              : [],
            providerIds: data?.providerIds || []
          }}
                // validationSchema={providerSchema}
          onSubmit={(formValues) => {
            setData({
              ...data,
              providerIds: formValues?.providerIds
            })

            const set = new Set()
            const providerSet = new Set(formValues?.providerIds)

            for (const val of filteredProviders) {
              if (providerSet.has(val.masterCasinoProviderId)) {
                set.add(val.masterGameAggregatorId)
              }
            }

            if (set.size === formValues?.aggregatorIds.length) {
              dispatch(
                addAggregatorsStart({
                  data: {
                    ...data,
                    aggregatorIds: tenantDetails?.tenantConfig?.aggregatorIds
                      ? data?.aggregatorIds?.filter(aggId => !tenantDetails?.tenantConfig?.aggregatorIds?.includes(String(aggId)))
                      : data?.aggregatorIds,
                    providerIds: tenantDetails?.tenantConfig?.gameProviders
                      ? formValues?.providerIds?.filter(provID => !tenantDetails?.tenantConfig?.gameProviders?.includes(provID))
                      : formValues?.providerIds
                  }
                })
              )
            } else {
              toast('Please select at least 1 new provider for each aggregator', 'error')
            }
          }}
        >
          {({
            values,
            handleSubmit,
            setFieldValue
          }) => (
            <Form>
              <Row>
                <Col>
                  <BForm.Label>Aggregators</BForm.Label>
                  <Select
                    isMulti
                    isClearable={false}
                    name='aggregatorIds'
                    options={aggregatorOptions}
                    value={values?.aggregatorIds}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    onChange={(option, opt) => {
                      if (opt?.removedValue) {
                        if (!myAggregators?.includes(parseInt(opt?.removedValue?.value))) {
                          const filteredProviderIds = allSystemProviders?.reduce((acc, { masterGameAggregatorId, masterCasinoProviderId }) => {
                            if (parseInt(opt?.removedValue?.value) !== parseInt(masterGameAggregatorId) && values?.providerIds?.includes(parseInt(masterCasinoProviderId))) {
                              acc.push(parseInt(masterCasinoProviderId))
                            }
                            return acc
                          }, [])

                          setFieldValue('aggregatorIds', option)
                          setFieldValue('providerIds', filteredProviderIds)
                          setData({ ...data, aggregatorIds: option?.map(({ value }) => parseInt(value)), providerIds: filteredProviderIds })
                        }
                      } else {
                        setFieldValue('aggregatorIds', option)
                        setData({ ...data, aggregatorIds: option?.map(({ value }) => parseInt(value)) })
                      }
                    }}
                  />
                  <ErrorMessage
                    component='div'
                    name='aggregatorIds'
                    className='text-danger'
                  />
                </Col>
              </Row>

              {!!values?.aggregatorIds?.length &&
                <div style={{ overflow: 'auto', maxHeight: '550px' }} className='mt-4'>
                  <Table bordered striped responsive hover size='sm' className='text-center'>
                    <thead className='thead-dark'>
                      <tr>
                        {[
                          'Provider Id',
                          'Name',
                          'Aggregator',
                          'Select'
                        ].map((h) => (
                          <th key={h}>
                            {h}&nbsp;
                            {h === 'Select' &&
                              <input
                                type='checkbox'
                                name='selectAll'
                                className='cursor-pointer'
                                checked={filteredProviders?.length === values?.providerIds?.length}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    const myProvData = allSystemProviders?.filter(({ masterGameAggregatorId }) => data?.aggregatorIds?.includes(masterGameAggregatorId))?.map(({ masterCasinoProviderId }) => masterCasinoProviderId)
                                    setFieldValue('providerIds', myProvData)
                                    setData({ ...data, providerIds: myProvData })
                                  } else {
                                    setFieldValue('providerIds', tenantDetails?.tenantConfig?.gameProviders)
                                    setData({ ...data, providerIds: tenantDetails?.tenantConfig?.gameProviders })
                                  }
                                }}
                              />}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {filteredProviders && filteredProviders?.length > 0
                        ? filteredProviders?.map(({ masterCasinoProviderId, name, masterGameAggregatorId }, idx) => {
                          return (
                            <tr key={`jackpotContribution${idx}`}>
                              <td>{masterCasinoProviderId}</td>
                              <td>{name}</td>
                              <td>{getAggregatorName(masterGameAggregatorId)}</td>
                              <td>
                                <input
                                  type='checkbox'
                                  className='cursor-pointer'
                                  disabled={tenantDetails?.tenantConfig?.gameProviders?.includes(masterCasinoProviderId)}
                                  checked={values?.providerIds?.includes(parseInt(masterCasinoProviderId))}
                                  name={masterCasinoProviderId}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      const ids = [...values.providerIds]
                                      ids.push(masterCasinoProviderId)
                                      setFieldValue('providerIds', ids)
                                      setData({ ...data, providerIds: ids })
                                    } else {
                                      setFieldValue('providerIds', values?.providerIds?.filter(id => id !== parseInt(masterCasinoProviderId)))
                                      setData({ ...data, providerIds: values?.providerIds?.filter(id => id !== parseInt(masterCasinoProviderId)) })
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                          )
                        })
                        : (
                          <tr>
                            <td colSpan={8} className='text-danger text-center'>
                              No data found
                            </td>
                          </tr>
                          )}
                    </tbody>
                  </Table>
                </div>}

              <div className='d-flex justify-content-between mt-3'>
                <Button
                  onClick={() => {
                    handleClose()
                  }}
                  variant='outline-warning'
                >
                  Cancel
                </Button>

                <Button
                  onClick={handleSubmit}
                  hidden={!data?.aggregatorIds?.length}
                  variant='outline-success'
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default React.memo(AddAggregator)
