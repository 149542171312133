import { Form } from '@themesberg/react-bootstrap'
import { dateConstants } from './dateConstants'
import React from 'react'

export default ({
  dateOptions,
  setDateOptions
}) => {
  return (
    <>
      <Form.Select
        onChange={(e) => setDateOptions(e.target.value)}
        value={dateOptions}
        style={{ width: 'auto', marginRight: '10px' }}
        size='sm'
      >
        {dateConstants.map(({ label, value }) =>
          <option value={value} key={value}>{label}</option>
        )}
      </Form.Select>
    </>
  )
}
