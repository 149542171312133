import React from 'react'
import TABannerTable from './TABannerTable'
import { Accordion } from '@themesberg/react-bootstrap'
import Preloader from '../../../../components/Preloader'

const SABannerTable = ({
  banners,
  loading,
  isHidden
}) => {
  return (
    <Accordion className='mt-3'>
      {loading
        ? (
          <Preloader />
          )
        : (banners?.map((item, i) => {
            return (
              <Accordion.Item
                key={`banner-portal-listing-${i}`}
                eventKey={String(i)}
              >
                <Accordion.Header>
                  {item?.['tenant.name']} ({item?.['tenant.domain']})
                </Accordion.Header>
                <Accordion.Body>
                  <TABannerTable
                    item={typeof item?.banners === 'string' ? JSON.parse(item?.banners) : item?.banners}
                    loading={loading}
                    isHidden={isHidden}
                    selectedClient={item['tenant.adminUserId']}
                    selectedPortal={item?.tenantId}
                  />
                </Accordion.Body>
              </Accordion.Item>
            )
          })
          )}
    </Accordion>
  )
}

export default SABannerTable
