import { Table } from '@themesberg/react-bootstrap'
import React from 'react'
import { formatDate } from '../../utils/dateFormatter'

const BonusDetailTable = ({
  bonusDetail,
  selectedBonus
}) => {
  return (
    <Table striped bordered className='text-start'>
      <thead>
        <tr className='thead-dark text-center'>
          <th colSpan={2}>Bonus Details</th>
        </tr>
      </thead>
      {bonusDetail && bonusDetail?.bonusId === selectedBonus?.bonusId &&
        <tbody>
          <tr>
            <td>Promotion Title</td>
            <td>{bonusDetail?.promotionTitle?.EN}</td>
          </tr>
          <tr>
            <td>Bonus Type</td>
            <td>{bonusDetail?.bonusType}</td>
          </tr>
          {['match', 'deposit', 'wagering'].includes(bonusDetail?.bonusType) &&
            <tr>
              <td>Bonus Percentage</td>
              <td>{bonusDetail?.depositBonusPercent}%</td>
            </tr>}
          {['match', 'deposit', 'wagering'].includes(bonusDetail?.bonusType) &&
            <tr>
              <td>Sticky</td>
              <td>{bonusDetail?.isSticky ? 'Yes' : 'No'}</td>
            </tr>}
          {['match', 'deposit', 'wagering'].includes(bonusDetail?.bonusType) &&
            <tr>
              <td>Bonus Bet Only</td>
              <td>{bonusDetail?.bonusBetOnly ? 'Yes' : 'No'}</td>
            </tr>}
          {bonusDetail?.bonusType === 'freespins' &&
            <tr>
              <td>Quantity</td>
              <td>{bonusDetail?.quantity}</td>
            </tr>}
          {bonusDetail?.validFrom && bonusDetail?.bonusType !== 'winBoost' &&
            <tr>
              <td>Valid From</td>
              <td>{formatDate(bonusDetail?.validFrom)}</td>
            </tr>}
          {bonusDetail?.validTo && bonusDetail?.bonusType !== 'winBoost' &&
            <tr>
              <td>Valid To</td>
              <td>{formatDate(bonusDetail?.validTo)}</td>
            </tr>}
          <tr>
            <td>Days To Clear</td>
            <td>{bonusDetail?.daysToClear}</td>
          </tr>
          {(bonusDetail?.wageringMultiplier !== 0) &&
            <tr>
              <td>Wagering Type</td>
              <td>{bonusDetail?.wageringRequirementType}</td>
            </tr>}
          {(bonusDetail?.wageringMultiplier !== 0) &&
            <tr>
              <td>Wagering Multiplier</td>
              <td>{bonusDetail?.wageringMultiplier}</td>
            </tr>}
          {bonusDetail?.bonusType === 'winBoost' &&
            <tr>
              <td>Time Period</td>
              <td>{parseInt(bonusDetail?.other?.timePeriod) === 1 ? 'Daily' : (parseInt(bonusDetail?.other?.timePeriod) === 7 ? 'Weekly' : 'Monthly')}</td>
            </tr>}
        </tbody>}
    </Table>
  )
}

export default BonusDetailTable
