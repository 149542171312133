import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getWageringTemplateDetailsStart } from '../../../../store/redux-slices/bonus'
import { useDidMountEffect } from '../../../../utils/useDidMountEffect'

const useWageringTepltateDetails = () => {
  const { loading, wageringTemplateDetail } = useSelector((state) => state.bonus)
  const [selectedProvider, setSelectedProvider] = useState('')
  const [limit, setLimit] = useState(15)
  const [pageNo, setPageNo] = useState(1)
  const { wageringTemplateId } = useParams()
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const isInitialRender = useDidMountEffect()

  const totalPages = Math.ceil(wageringTemplateDetail?.gameDetail?.count / limit)

  const fetchDetails = () => {
    dispatch(getWageringTemplateDetailsStart({
      isTenant: false,
      wageringTemplateId,
      limit,
      pageNo,
      providerId: selectedProvider,
      search
    }))
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (pageNo === 1) {
          dispatch(getWageringTemplateDetailsStart({
            isTenant: false,
            wageringTemplateId,
            limit,
            pageNo,
            providerId: selectedProvider,
            search
          }))
        } else {
          setPageNo(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    fetchDetails()
  }, [selectedProvider, pageNo, limit])

  return {
    loading,
    wageringTemplateId,
    wageringTemplateDetail,
    selectedProvider,
    setSelectedProvider,
    setLimit,
    setPageNo,
    totalPages,
    limit,
    pageNo,
    search,
    setSearch
  }
}

export default useWageringTepltateDetails
