import React from 'react'
import { toast } from 'react-toastify'
import CreatableSelect from 'react-select/creatable'

const index = ({
  options,
  fieldName,
  setOptions,
  setFieldValue,
  selectedOption,
  setSelectedOption
}) => {
  return (
    <CreatableSelect
      isClearable={false}
      isMulti
      name={fieldName}
      onCreateOption={(option) => {
        if (option && option.match(/^[A-Za-z0-9_-]*$/)) {
          if (options) {
            setFieldValue(fieldName, [
              ...selectedOption,
              { label: option, value: option }
            ])
            setSelectedOption([
              ...selectedOption,
              { label: option, value: option }
            ])
            setOptions([...options, { label: option, value: option }])
          } else {
            setFieldValue(fieldName, [{ label: option, value: option }])
            setSelectedOption([{ label: option, value: option }])
            setOptions([{ label: option, value: option }])
          }
        } else {
          toast('Only Alphabets and Numbers Allowed', 'error')
        }
      }}
      options={options}
      value={selectedOption}
      classNamePrefix='select'
      onChange={(option, e) => {
        if (e?.removedValue?.value) {
          const val = selectedOption.filter(
            (opt) => opt?.value !== e?.removedValue?.value
          )
          setSelectedOption(val)
        } else {
          setSelectedOption(option)
        }
        setFieldValue(fieldName, option)
      }}
    />
  )
}

export default React.memo(index)
