import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEmailLogsStart } from '../../../../store/redux-slices/tenantUsers'
import { useParams } from 'react-router-dom'
import useDidMountEffect from '../../../../utils/useDidMountEffect'

const useEmailLogs = ({ isTenant, limit, pageNo, role, search, setPageNo }) => {
  const dispatch = useDispatch()

  const { userId } = useParams()
  const isInitialRender = useDidMountEffect()
  const [emailLogDetails, setEmailLogDetails] = useState({})
  const { emailLogs, emailLoading } = useSelector(state => state.tenantUsers)
  const totalPages = Math.ceil(emailLogs?.count / limit)
  const [isEmailDetailsPopupShown, setEmailDetailsPopupStatus] = useState(false)

  const fetchData = () => {
    dispatch(getEmailLogsStart({
      limit,
      pageNo,
      search,
      userId,
      role,
      isTenant
    }))
  }

  useEffect(() => {
    fetchData()
  }, [limit, pageNo, userId, role])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (pageNo === 1) {
          fetchData()
        } else {
          setPageNo(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  return {
    userId,
    emailLogs,
    totalPages,
    emailLoading,
    emailLogDetails,
    setEmailLogDetails,
    isEmailDetailsPopupShown,
    setEmailDetailsPopupStatus
  }
}

export default useEmailLogs
