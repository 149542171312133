import React from 'react'
import {
  Card,
  Row,
  Col,
  Form as BForm,
  Button,
  InputGroup
} from '@themesberg/react-bootstrap'
import LogoUpload from './UploadLogo'
import { Formik, Form, ErrorMessage } from 'formik'
import { tenantSchema, editTenantSchema } from '../schema'
import useTenant from '../hooks/useTenant'
import Preloader from '../../../../components/Preloader'
import { isDomainExist } from '../../../../utils/apiCalls'
import { toast } from '../../../../components/Toast'

export default ({
  createTenantData, setCreateTenantData, setSelectedTab, isEdit = false, setFieldValidation, setMyData
}) => {
  const {
    tenantId,
    navigate,
    loading,
    editTenant
  } = useTenant({ createTenantData, setCreateTenantData })

  const validateFields = (errors) => {
    setFieldValidation && setFieldValidation(!Object.keys(errors)?.length)
  }

  return (
    <>
      {loading
        ? <Preloader />
        : (
          <Card>
            <Card.Body>
              <Formik
                enableReinitialize
                initialValues={{
                  name: createTenantData.name,
                  domain: createTenantData.domain,
                  // allowedCurrencies: createTenantData.allowedCurrencies,
                  primaryCurrency: 'EUR',
                  logo: createTenantData?.logo
                  // allowedLanguages: createTenantData.allowedLanguages
                }}
                validationSchema={tenantId ? editTenantSchema(createTenantData?.logo) : tenantSchema}
                onSubmit={(formValues) => {
                  setCreateTenantData({
                    ...createTenantData,
                    ...formValues
                  })

                  if (tenantId) {
                    editTenant({
                      createTenantData: {
                        ...createTenantData,
                        ...formValues
                      },
                      val: false,
                      tenantId
                    })
                    setFieldValidation && setFieldValidation(true)
                  }
                  if (!tenantId) setSelectedTab('credentials')
                }}
              >
                {({
                  touched,
                  errors,
                  values,
                  setSubmitting,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  setFieldValue,
                  resetForm
                }) => (
                  <Form>
                    <Row className='g-2 mt-3'>
                      {tenantId && validateFields(errors)}
                      <Col>
                        <label
                          htmlFor='name'
                          className={
                            touched.name && errors.name ? 'text-danger' : ''
                          }
                        >
                          Name<span className='text-danger'> *</span>
                        </label>
                        <InputGroup
                          className={
                            touched.name && errors.name
                              ? 'border border-danger'
                              : ''
                          }
                        >
                          <BForm.Control
                            name='name'
                            required
                            type='text'
                            disabled={isEdit}
                            placeholder='Enter Name'
                            value={values.name}
                            onInput={handleChange}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setCreateTenantData({ ...createTenantData, name: e.target.value })
                              handleChange(e)
                            }}
                          />
                        </InputGroup>
                        <ErrorMessage
                          component='div'
                          name='name'
                          className='text-danger'
                        />
                      </Col>

                      {/* Col for Domain */}
                      <Col>
                        <label
                          htmlFor='domain'
                          className={
                            touched.domain && errors.domain ? 'text-danger' : ''
                          }
                        >
                          {' '}
                          Domain<span className='text-danger'> *</span>
                        </label>
                        <InputGroup
                          className={
                            touched.domain && errors.domain
                              ? 'border border-danger'
                              : ''
                          }
                        >
                          <BForm.Control
                            name='domain'
                            required
                            type='url'
                            placeholder='Enter Domain'
                            value={values.domain}
                            onInput={handleChange}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setCreateTenantData({ ...createTenantData, domain: e.target.value })
                              handleChange(e)
                            }}
                          />
                        </InputGroup>
                        <ErrorMessage
                          component='div'
                          name='domain'
                          className='text-danger'
                        />
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col>
                        <BForm.Group className='mb-3'>
                          <label
                            className={
                                      touched.primaryCurrency && errors.primaryCurrency ? 'text-danger' : ''
                                    }
                          >
                            Primary Currency
                            <span className='text-danger'> *</span>
                          </label>

                          <BForm.Select
                            name='primaryCurrency'
                            onChange={(e) => {
                              setCreateTenantData({ ...createTenantData, primaryCurrency: e.target.value })
                              handleChange(e)
                            }}
                            value={values.primaryCurrency}
                            disabled
                            arial-label='Select Primary Currency'
                          >
                            <option disabled>Select Primary Currency</option>
                            <option
                              value='EUR'
                            >
                              EUR
                            </option>
                          </BForm.Select>

                          <ErrorMessage
                            component='div'
                            name='primaryCurrency'
                            className='text-danger'
                          />
                        </BForm.Group>
                      </Col>
                      {/* Col for Logo */}
                      <Col>
                        <BForm.Group className='mb-3' controlId='formBasicEmail'>
                          <label>
                            Logo<span className='text-danger'> *</span>
                          </label>

                          <BForm.Text>
                            <LogoUpload
                              createTenantData={createTenantData}
                              setFieldValue={setFieldValue}
                              setCreateTenantData={setCreateTenantData}
                            />
                          </BForm.Text>
                          <ErrorMessage
                            component='div'
                            name='logo'
                            className='text-danger'
                          />
                        </BForm.Group>
                      </Col>
                    </Row>

                    <div className='d-flex justify-content-between mt-3'>
                      <Button
                        onClick={() => {
                          if (!tenantId) navigate(-1)
                          else {
                            resetForm()
                            setMyData()
                          }
                        }}
                        variant='outline-warning'
                      >
                        Cancel
                      </Button>

                      <Button
                        onClick={() => {
                          if (createTenantData.domain !== values.domain) {
                            isDomainExist({ domain: values.domain, tenantId: tenantId || '' })
                              .then(() => handleSubmit())
                              .catch(
                                err => {
                                  setSubmitting(false)
                                  toast(err.response.data.message, 'error')
                                }
                              )
                          } else {
                            handleSubmit()
                          }
                        }}
                        variant='outline-success'
                      >
                        {tenantId ? 'Submit' : 'Next'}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>)}
    </>
  )
}
