import React from 'react'
import useRGModule from './useRGModule'
import FlaggedPlayerListing from '../../../components/FlaggedPlayerListing'

export default ({ selfExcluded = false }) => {
  const {
    page,
    limit,
    userId,
    search,
    loading,
    setPage,
    setLimit,
    setUserId,
    setSearch,
    totalPages,
    RGPlayersDetails,
    setSelectedClient,
    setSelectedPortal,
    selectedClient,
    selectedPortal,
    selectedTab,
    setSelectedTab,
    heading,
    tabs,
    rgThresholdValue
  } = useRGModule({ selfExcluded })

  return (
    <FlaggedPlayerListing
      selfExcluded={selfExcluded}
      heading={heading}
      setSelectedClient={setSelectedClient}
      setSelectedPortal={setSelectedPortal}
      selectedClient={selectedClient}
      selectedPortal={selectedPortal}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={tabs}
      search={search}
      setSearch={setSearch}
      userId={userId}
      setUserId={setUserId}
      playersDetails={RGPlayersDetails}
      loading={loading}
      totalPages={totalPages}
      setPage={setPage}
      limit={limit}
      setLimit={setLimit}
      page={page}
      type='rg'
      rgThresholdValue={rgThresholdValue}
    />
  )
}
